import { MainLayout } from 'core/layouts';
import { Storages } from 'features/Storages';

import styles from './Storages.module.scss';

const StoragesPage = () => {
  return (
    <MainLayout title="Хранилища" classNameRoot={styles.storages}>
      <Storages />
    </MainLayout>
  );
};

export default StoragesPage;
