import { Organization } from './organization';
import { WorkGroup } from './workGroup';

export enum AccessType {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

export type Article = {
  id?: string;
  title: string;
  text: string;
  organizations: Organization[];
  workgroups: WorkGroup[];
  userId: string;
  userFullName: string;
  categoryId: string;
  categoryTitle: string;
  parentCategoryTitle?: string;
  parentCategoryId?: string;
  accessType: AccessType;
  systemId?: string;
  systemVersion?: string;
  systemTitle?: string;
  created: Date;
  updated: Date;
};

export type CategoryArticle = {
  id?: string;
  title: string;
  parentId?: string;
  parentTitle?: string;
  organizations?: Organization[];
  children?: Array<CategoryArticle>;
  articles?: Article[];
  accessType?: AccessType;
};
