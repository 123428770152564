import { RenderTypes, SelectOption, TableColumns, ValueType } from 'components';
import { AccessType } from 'core/types/knowledgeBase';

import { ArticleCreateFormData } from './types';

export const TABLE_ARTICLE_FIELDS: TableColumns = [
  {
    title: 'Название',
    sortField: 'title',
    fieldName: 'title',
  },
  { title: 'Подкатегория', fieldName: 'categoryTitle' },
  {
    title: 'Создатель',
    fieldName: 'userFullName',
    sortField: 'USERFULLNAME',
  },
  {
    title: 'Редактирован',
    fieldName: 'updated',
    sortField: 'dateupdate',
    renderType: RenderTypes.ICON_BLOCK,
  },
  {
    title: 'Организация',
    fieldName: 'organizationTitle',
    sortField: 'organizationTitle',
  },
];

const PUBLIC_ACCESS: SelectOption<AccessType> = {
  title: 'Отправить всем',
  value: AccessType.PUBLIC,
};

const PRIVATE_ACCESS: SelectOption<AccessType> = {
  title: 'Выборочно',
  value: AccessType.PRIVATE,
};

export const SELECT_ACCESS_TYPE_LIST: SelectOption<AccessType>[] = [
  PUBLIC_ACCESS,
  PRIVATE_ACCESS,
];

export const AccessTypeMap: Record<AccessType, ValueType<AccessType>> = {
  [AccessType.PUBLIC]: PUBLIC_ACCESS,
  [AccessType.PRIVATE]: PRIVATE_ACCESS,
};

export const DEFAULT_CREATE_ARTICLE_FORM_VALUES: ArticleCreateFormData = {
  organizationIds: [],
  workgroupIds: [],
  category: null,
  parentCategory: null,
  accessType: null,
  text: '',
  title: '',
};
