import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { TablesLayout } from 'core/layouts';
import { TableResponsibilitiesTypes } from 'core/types/responsibility';
import { TableRolesTypes } from 'core/types/roles';
import { resetFilterOrganizationIdState } from 'features/Organizations';
import {
  resetResponsibilitiesState,
  ResponsibilitiesTableContainer,
} from 'features/Responsibilities';

import { RolesTableContainer } from './components';
import { fetchRolesRequest, resetRolesState } from './ducks/actions';
import styles from './Roles.module.scss';

const SubTableFirst = () => {
  return (
    <ResponsibilitiesTableContainer
      tableType={TableResponsibilitiesTypes.FROM_ROLES}
    />
  );
};

const MainTable = () => {
  return <RolesTableContainer tableType={TableRolesTypes.FULL} />;
};

export const Roles = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRolesRequest());
    return () => {
      dispatch(resetRolesState());
      dispatch(resetResponsibilitiesState());
      dispatch(resetFilterOrganizationIdState());
    };
  }, []);

  return (
    <TablesLayout
      MainTable={[MainTable]}
      SubTable={[SubTableFirst]}
      classNameRoot={styles.roles}
    />
  );
};
