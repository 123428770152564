import cn from 'clsx';
import { forwardRef, HTMLAttributes, TransitionEvent, useState } from 'react';

import { Portal } from 'components/Portal';
import { ApproveOrCancel, ApproveOrCancelProps } from 'core/modals';

import styles from './Drawer.module.scss';

interface DrawerProps extends HTMLAttributes<HTMLDivElement> {
  isOpen?: boolean;
  onClose?: () => void;
  approveOrCancelProps?: ApproveOrCancelProps;
  elevated?: boolean;
}

export const Drawer = forwardRef<HTMLDivElement, DrawerProps>((props, ref) => {
  const {
    children,
    isOpen,
    className,
    onClose,
    approveOrCancelProps,
    elevated,
  } = props;

  const [panelOpened, setPanelOpened] = useState(false);

  const isOpened = isOpen && panelOpened;

  const handleClose = () => {
    if (isOpened && onClose) {
      onClose();
      setPanelOpened(false);
    }
  };

  const approveHandleClose = () => {
    if (isOpened && approveOrCancelProps) {
      approveOrCancelProps.toggleModal();
    }
  };

  const transitionHandler = (event: TransitionEvent<HTMLDivElement>) => {
    if (event.propertyName === 'transform') {
      setPanelOpened(!!(isOpen && !panelOpened));
    }
  };

  const approveOrCancel = approveOrCancelProps && (
    <ApproveOrCancel {...approveOrCancelProps} />
  );

  return (
    <Portal>
      <div
        role="dialog"
        ref={ref}
        className={cn(
          styles.drawer,
          { [styles.drawer_elevated]: elevated },
          className,
          {
            [styles.drawer_active]: isOpen,
          }
        )}
        onMouseDown={approveOrCancelProps ? approveHandleClose : handleClose}
        aria-hidden="true"
        onTransitionEnd={transitionHandler}
      >
        <div
          className={cn(styles.drawer__content, {
            [styles.drawer__content_active]: isOpen,
          })}
          onMouseDown={(e) => e.stopPropagation()}
          aria-hidden="true"
        >
          {children}
        </div>
        {approveOrCancel}
      </div>
    </Portal>
  );
});
