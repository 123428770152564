import { Edge, Node } from 'reactflow';
import { v4 as uuidv4 } from 'uuid';

import { REOPEN_STATUS_SETTINGS } from 'core/constants';
import { EdgeType, NodeType } from 'core/types/workflow';

type Args = {
  nodeStatusClose: Node<NodeType>;
  nodeStatusWork: Node<NodeType>;
  nodeStatusPendingClosure: Node<NodeType>;
};

export const getDataReopenStatus = ({
  nodeStatusClose,
  nodeStatusWork,
  nodeStatusPendingClosure,
}: Args) => {
  const id = uuidv4();

  const node: Node<NodeType> = {
    id,
    data: {
      ...REOPEN_STATUS_SETTINGS,
    },
    position: {
      x: nodeStatusPendingClosure.position.x + 20,
      y: nodeStatusPendingClosure.position.y + 160,
    },
  };

  const edgeTo: Edge<EdgeType> = {
    id: uuidv4(),
    target: nodeStatusWork?.id,
    source: id,
    sourceHandle: 'b',
    data: { protected: true },
  };

  const edgeFrom: Edge<EdgeType> = {
    id: uuidv4(),
    target: id,
    targetHandle: 'b',
    source: nodeStatusClose?.id,
    sourceHandle: 'b',
    data: { protected: true },
  };

  return {
    node,
    edgeTo,
    edgeFrom,
  };
};
