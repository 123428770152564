import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Ticket } from 'core/types/ticket';
import { ResponseWithMeta } from 'store/types';

import { TicketsStatistic } from '../../types';

interface CurrentState {
  tickets?: Ticket[];
  totalTickets?: number;
  ticketsStatistic?: TicketsStatistic;
  isTicketsLoading?: boolean;
  sort?: string;
  pageNum?: number;
  pageSize?: number;
}

const initialState: CurrentState = {
  tickets: [],
  sort: 'datecreate_DESC',
  pageNum: 0,
  pageSize: 10,
  isTicketsLoading: false,
};

const currentStateSlice = createSlice({
  name: 'desktopCurrentState',
  initialState,
  reducers: {
    fetchTicketsSuccess: (
      state,
      action: PayloadAction<ResponseWithMeta<Ticket[]>>
    ) => {
      state.tickets = action.payload.content;
      state.totalTickets = action.payload.totalElements;
    },
    fetchTicketsRequest: (_state) => {},
    fetchTicketsStatisticSuccess: (
      state,
      action: PayloadAction<TicketsStatistic>
    ) => {
      state.ticketsStatistic = action.payload;
    },
    fetchTicketsStatisticRequest: (_state) => {},
    setIsTicketsLoading: (state, action: PayloadAction<boolean>) => {
      state.isTicketsLoading = action.payload;
    },
    setPageNumCurrentState: (
      state,
      action: PayloadAction<number | undefined>
    ) => {
      state.pageNum = action.payload;
    },
    setPageSizeCurrentState: (
      state,
      action: PayloadAction<number | undefined>
    ) => {
      state.pageSize = action.payload;
    },
    setSortCurrentState: (state, action: PayloadAction<string | undefined>) => {
      state.sort = action.payload;
    },
    resetCurrentState: () => initialState,
  },
});

export const {
  fetchTicketsStatisticSuccess,
  fetchTicketsRequest,
  fetchTicketsStatisticRequest,
  fetchTicketsSuccess,
  setIsTicketsLoading,
  setPageNumCurrentState,
  setPageSizeCurrentState,
  setSortCurrentState,
  resetCurrentState,
} = currentStateSlice.actions;
export const desktopCurrentState = currentStateSlice.reducer;
