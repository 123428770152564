import { createSelector } from 'reselect';

import { SelectOption } from 'components';
import { Organization } from 'core/types/organization';
import { System } from 'core/types/system';
import { RootState } from 'store/rootReducer';

export const getDesktopFilters = (state: RootState) => state.desktop.filters;

export const getOrganizations = (state: RootState) =>
  state.desktop.organizations;
export const getOrganizationsOptions = createSelector<
  RootState,
  Organization[] | undefined,
  SelectOption[]
>([getOrganizations], (organizations): SelectOption[] => {
  if (organizations) {
    return organizations.map(
      (organization: Organization): SelectOption => ({
        title: organization.title,
        value: organization.id || '',
      })
    );
  }
  return [];
});

export const getSystems = (state: RootState) => state.desktop.systems;
export const getSystemsOptions = createSelector<
  RootState,
  System[] | undefined,
  SelectOption[]
>([getSystems], (systemsOrganization): SelectOption[] => {
  if (systemsOrganization) {
    return systemsOrganization.map(
      (system: System): SelectOption => ({
        title: system.title,
        value: system.id,
      })
    );
  }
  return [];
});

export const getIsDesktopTicketsLoading = (state: RootState) =>
  state.desktop.isTicketsLoading;
