import { TicketsFilterForRender } from 'core/types/tickets';

import { DEFAULT_TICKETS_FILTER_VALUES } from '../constants';
import { DefaultFilterValuesProps } from '../types';

export const getDefaultFilterValues = ({
  userId,
  userWorkGroupsIds,
  isMyTicketsPage,
  isAllTicketsPage,
  contractIds,
  userManagerWorkGroupsIds,
  isGroupsTicketsPage,
}: DefaultFilterValuesProps): TicketsFilterForRender => {
  const currentUserIdArray = userId ? [userId] : [];

  if (isMyTicketsPage) {
    return {
      ...DEFAULT_TICKETS_FILTER_VALUES,
      memberIds: currentUserIdArray,
    };
  }
  if (isAllTicketsPage) {
    return {
      ...DEFAULT_TICKETS_FILTER_VALUES,
      workGroupIds: userWorkGroupsIds,
    };
  }
  if (isGroupsTicketsPage) {
    return {
      ...DEFAULT_TICKETS_FILTER_VALUES,
      workGroupIds: userManagerWorkGroupsIds,
      onlyActive: true,
    };
  }
  return {
    ...DEFAULT_TICKETS_FILTER_VALUES,
    contractIds,
  };
};
