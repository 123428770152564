import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const withScrollWindow = <Props,>(Component: FC<Props>) => {
  return (props: Props) => {
    const location = useLocation();

    useEffect(() => {
      window.scrollTo({ top: 0, left: 0 });
    }, [location]);

    return <Component {...props} />;
  };
};
