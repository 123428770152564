import { RenderTypes, TableColumns } from 'components';
import { Priority, SupportType } from 'core/types';

import { CheckedPriorityType } from './components/SlaTable/types';

export const TABLE_HEADER_FROM_SUPPLEMENTARY_AGREEMENT: TableColumns = [
  {
    title: 'Название',
    fieldName: 'title',
    sortField: 'title',
    renderType: RenderTypes.NOTIFICATION_LINK,
  },
  {
    title: 'Система',
    fieldName: 'systemTitle',
  },
  {
    title: 'Срок поддержки',
    fieldName: 'supportPeriod',
  },
];

export const DEFAULT_CONTRACT_FORM_VALUES = {
  openDate: '',
  endDate: '',
  supportType: SupportType.EXTENDED,
  notificationConfig: {
    contractExpireIsEnable: false,
    notifyBeforeExpire: 0,
  },
};

export const initialCheckedPriority: CheckedPriorityType = {
  [Priority.HIGH]: false,
  [Priority.LOW]: false,
  [Priority.MIDDLE]: false,
  [Priority.NONE]: false,
  [Priority.VERY_HIGH]: false,
};
