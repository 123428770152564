import cn from 'clsx';
import { FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  BigBell,
  CloseIcon,
  DoubleCheckIcon,
  ExitIcon,
  MailIcon,
  PhoneIcon,
  SettingIcon,
} from 'assets/icons';
import {
  Avatar,
  AvatarColor,
  Button,
  ConditionBlock,
  IconButton,
  InfoBlock,
  Size,
  Tooltip,
  Typography,
  TypographyVariants,
} from 'components';
import { NotificationContent } from 'core/types/notification';
import {
  getIsClient,
  getUserEmail,
  getUserFirstName,
  getUserFullName,
  getUserLastName,
  getUserMiddleName,
  getUserOrganizationTitle,
  getUserPhoneNumber,
} from 'features/Auth/ducks/selectors';
import { Notification } from 'features/Notifications';
import { getAllNotifications } from 'features/Notifications/ducks/selectors';
import {
  setAllCheckedNotifications,
  setCheckedNotificationRequest,
} from 'features/Profile/ducks/actions';
import { RouterHref } from 'routes/routerHref';
import { resetStore } from 'store/actions';
import { AuthService } from 'utils';

import styles from './NotificationsBlock.module.scss';

interface NotificationsBlockProps {
  className?: string;
  classNameHeader?: string;
  classNameContentWrapper?: string;
  classNameNotifications?: string;
  onClose(): void;
}

export const NotificationsBlock: FC<NotificationsBlockProps> = ({
  className,
  classNameHeader,
  classNameContentWrapper,
  classNameNotifications,
  onClose,
}) => {
  const { t } = useTranslation();

  const notifications = useSelector(getAllNotifications);
  const isClient = useSelector(getIsClient);
  const userFullName = useSelector(getUserFullName);
  const userFirstName = useSelector(getUserFirstName);
  const userLastName = useSelector(getUserLastName);
  const userMiddleName = useSelector(getUserMiddleName);
  const userOrganizationTitle = useSelector(getUserOrganizationTitle);
  const userEmail = useSelector(getUserEmail);
  const userPhoneNumber = useSelector(getUserPhoneNumber);

  const { push } = useHistory();

  const dispatch = useDispatch();

  const unAuthorization = () => {
    AuthService.doLogout();
    dispatch(resetStore());
  };

  const handleSettingsClick = () => {
    push(RouterHref.ProfileSettings);
  };

  const handleSeeAll = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    push(`${RouterHref.ProfileNotifications}/all`);
  };

  const handleNotificationClick = (data: NotificationContent) => {
    if (data?.id) {
      dispatch(setCheckedNotificationRequest(data?.id));
    }
  };

  const handleSetAllChecked = () => {
    dispatch(setAllCheckedNotifications());
  };

  const buttonSeeAll = (
    <div className={styles.notificationsBlock__buttonWrapper}>
      <Button
        appearance="outline"
        onClick={handleSeeAll}
        className={styles.notificationsBlock__button}
      >
        Смотреть все
      </Button>
    </div>
  );

  const notificationList = notifications?.length ? (
    <div
      className={cn(
        styles.notificationsBlock__notifications,
        classNameNotifications
      )}
    >
      {notifications?.map((notification) => (
        <div
          key={notification.id}
          className={styles.notificationsBlock__notificationWrapper}
        >
          <Notification
            notification={notification}
            className={styles.notificationsBlock__notification}
            classNameTitle={styles.notificationsBlock__notificationTitle}
            classNameText={styles.notificationsBlock__notificationTitle}
            onClick={handleNotificationClick}
          />
        </div>
      ))}
      {buttonSeeAll}
    </div>
  ) : (
    <div className={styles.notificationsBlock__emptyContent}>
      <BigBell />
      <Typography
        variant={TypographyVariants.b2}
        className={styles.notificationsBlock__emptyContentText}
      >
        Нет непрочитанных
        <br />
        уведомлений
      </Typography>
      {buttonSeeAll}
    </div>
  );

  return (
    <div className={cn(styles.notificationsBlock, className)}>
      <div className={cn(styles.notificationsBlock__header, classNameHeader)}>
        <button
          onClick={onClose}
          className={styles.notificationsBlock__closeButton}
        >
          <CloseIcon />
        </button>
        <div className={styles.notificationsBlock__subHeader}>
          <Typography
            variant={TypographyVariants.h2}
            className={styles.notificationsBlock__subHeaderTitle}
          >
            {t('notifications.title')}
          </Typography>
          <IconButton
            data-tip
            data-for="clear-button"
            icon={<DoubleCheckIcon />}
            appearance="flat"
            onClick={handleSetAllChecked}
          />
          <Tooltip id="clear-button">Отметить все как прочитанные</Tooltip>
        </div>
      </div>
      <div
        className={cn(
          styles.notificationsBlock__contentWrapper,
          classNameContentWrapper
        )}
      >
        {notificationList}
        <div className={styles.notificationsBlock__userWrapper}>
          <Avatar
            size={Size.s}
            color={isClient ? AvatarColor.purple : AvatarColor.cyan}
            initialsUser={userFullName}
            className={styles.notificationsBlock__initialUser}
          />
          <div className={styles.notificationsBlock__userHeader}>
            <Typography
              variant={TypographyVariants.h4}
              className={styles.notificationsBlock__userName}
            >
              {`${userLastName} ${userFirstName} ${userMiddleName || ''}`}
            </Typography>
            <ConditionBlock
              text={userOrganizationTitle}
              className={cn(styles.notificationsBlock__userOrganization, {
                [styles.notificationsBlock__userOrganization_client]: isClient,
              })}
            />
          </div>
          <div className={styles.notificationsBlock__userButtons}>
            <InfoBlock
              data-tip
              data-for="email"
              classNameIconWrapper={styles.notificationsBlock__userButton}
              icon={
                <MailIcon
                  className={styles.notificationsBlock__userButtonIcon}
                />
              }
            />
            <Tooltip
              id="email"
              place="top"
              className={styles.notificationsBlock__tooltip}
            >
              {userEmail}
            </Tooltip>
            <InfoBlock
              data-tip
              data-for="phone-number"
              classNameIconWrapper={styles.notificationsBlock__userButton}
              icon={
                <PhoneIcon
                  className={styles.notificationsBlock__userButtonIcon}
                />
              }
            />
            <Tooltip id="phone-number" place="top">
              {userPhoneNumber}
            </Tooltip>
            <IconButton
              icon={
                <SettingIcon
                  className={styles.notificationsBlock__userButtonIcon}
                />
              }
              appearance="flat"
              onClick={handleSettingsClick}
              className={styles.notificationsBlock__userButton}
            />
            <IconButton
              icon={
                <ExitIcon
                  className={styles.notificationsBlock__userButtonIcon}
                />
              }
              appearance="flat"
              onClick={unAuthorization}
              className={styles.notificationsBlock__userButton}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
