import { createSelector } from 'reselect';

import { NotificationContent } from 'core/types/notification';
import { RootState } from 'store/rootReducer';

export const getAllNotifications = (
  state: RootState
): NotificationContent[] | undefined => state.notifications.allNotifications;
export const getCurrentNotifications = (
  state: RootState
): NotificationContent[] | undefined =>
  state.notifications.currentNotifications;
export const getNotificationsMyTicketsIds = (
  state: RootState
): string[] | undefined => state.notifications.notificationsMyTicketsIds;
export const getNotificationsNewTicketsIds = (
  state: RootState
): string[] | undefined => state.notifications.notificationsNewTicketsIds;
export const getActiveNotificationId = (state: RootState) =>
  state.notifications.activeNotificationId;
export const getIsNotCheckedNotifications = createSelector<
  RootState,
  NotificationContent[] | undefined,
  boolean
>([getAllNotifications], (allNotifications): boolean => {
  if (allNotifications && allNotifications.length) {
    return (
      allNotifications.findIndex((notification) => !notification?.checked) >= 0
    );
  }
  return false;
});
