import { FC } from 'react';

import { WarningIcon } from 'assets/icons';
import { Tooltip } from 'components';

import styles from './InfoTooltip.module.scss';

interface InfoTooltipProps {
  isUpdated?: boolean;
  updatedFrom?: string | null;
}

export const InfoTooltip: FC<InfoTooltipProps> = ({
  isUpdated,
  updatedFrom,
}) => {
  return isUpdated && updatedFrom ? (
    <>
      <WarningIcon data-tip data-for={updatedFrom} />
      <Tooltip id={updatedFrom} place="bottom" className={styles.infoTooltip}>
        {updatedFrom}
      </Tooltip>
    </>
  ) : null;
};
