import { UsersFilter, UsersFilterToRequest } from 'core/types/user';

export const getFilterUsersToRequest = (
  filter: UsersFilter
): UsersFilterToRequest => {
  const { organizationId } = filter;
  return {
    ...filter,
    organizationId:
      organizationId && !Array.isArray(organizationId)
        ? organizationId.value
        : undefined,
  };
};
