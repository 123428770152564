import { debounce } from 'lodash';
import { FC, memo, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { Input, Select, Size, TableBodyCell, TableFilter } from 'components';
import { DEFAULT_DEBOUNCE_DELAY } from 'constants/meta';
import { getOrganizationsSelectList } from 'features/StatusesBuilder/ducks/selectors';
import { checkObjectIdentity } from 'utils';

import { INITIAL_SYSTEMS_FILTER } from '../../constants';
import {
  fetchOrganizationsRequest,
  fetchSystemsRequest,
  setCurrentSystemsPage,
  setSystemsFilter,
} from '../../ducks/actions';
import { SystemsFilter as SystemsFilterData } from '../../types';

import styles from './SystemsFilter.module.scss';

export const SystemsFilter: FC = memo(() => {
  const dispatch = useDispatch();

  const organizationOptions = useSelector(getOrganizationsSelectList);

  const { register, handleSubmit, reset, watch, control } =
    useForm<SystemsFilterData>({
      mode: 'onChange',
      defaultValues: INITIAL_SYSTEMS_FILTER,
    });

  const filterValues = watch();

  const resetFilter = () => {
    dispatch(setSystemsFilter({}));
    dispatch(fetchSystemsRequest());
    reset();
  };

  const formSubmitHandler = handleSubmit((data) => {
    const { title, organizationId } = data;
    const preparedData = {
      title: title || undefined,
      organizationId: organizationId || undefined,
    };

    dispatch(setSystemsFilter(preparedData));
    dispatch(setCurrentSystemsPage(0));
    dispatch(fetchSystemsRequest());
  });

  const formSubmitHandlerDebounced = useMemo(
    () => debounce(formSubmitHandler, DEFAULT_DEBOUNCE_DELAY),
    []
  );

  const titleToInput = register('title', {
    onChange: formSubmitHandlerDebounced,
  });

  useEffect(() => {
    dispatch(fetchOrganizationsRequest());
  }, []);

  const tableBodyWrapper = (elements: JSX.Element[]) =>
    elements.map((item) => (
      <TableBodyCell className={styles.systemsFilter__cell} key={item.key}>
        {item}
      </TableBodyCell>
    ));

  const filterElements = [
    <Input size={Size.xs} type="title" key="title" {...titleToInput} />,
    <Controller
      control={control}
      name="organizationId"
      key="organizationId"
      render={({ field }) => {
        return (
          <Select<string>
            size={Size.xs}
            mobileModalTitle="организацию"
            options={organizationOptions}
            onChange={(value) => {
              field.onChange(value);
              formSubmitHandler();
            }}
            value={field.value}
          />
        );
      }}
    />,
  ];

  const filter = <>{tableBodyWrapper(filterElements)}</>;

  const disableReset = checkObjectIdentity(filterValues, {});

  return (
    <TableFilter
      filterComponent={filter}
      onReset={resetFilter}
      disableReset={disableReset}
    />
  );
});
