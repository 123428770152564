import _ from 'lodash';

import { StatusForm } from '../types';

export const getNormalizedData = (data?: StatusForm) => {
  return _.omit(
    data,
    'defaultStatus',
    'hideBottomHandle',
    'hideLeftHandle',
    'hideRightHandle',
    'hideTopHandle'
  );
};
