import { FC } from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';

import { InputDatePicker, Select, SelectOption } from 'components';

import { HistoryFormData } from '../../types';

import styles from './HistoryForm.module.scss';

interface HistoryFormProps {
  control: Control<HistoryFormData>;
  setValue: UseFormSetValue<HistoryFormData>;
  organizationsOptions: SelectOption[];
  systemsOptions: SelectOption[];
}

export const HistoryForm: FC<HistoryFormProps> = ({
  control,
  setValue,
  organizationsOptions,
  systemsOptions,
}) => {
  return (
    <>
      <Controller
        control={control}
        name="date"
        render={({ field }) => {
          return (
            <InputDatePicker
              type="datePicker"
              placeholder="Период"
              value={field.value}
              onChange={field.onChange}
              className={styles.historyForm__select}
              showRange
            />
          );
        }}
      />
      <Controller
        control={control}
        name="organizationId"
        render={({ field }) => {
          return (
            <Select<string>
              label="Организация"
              mobileModalTitle="организацию"
              value={field.value}
              options={organizationsOptions}
              className={styles.historyForm__select}
              onChange={(value) => {
                setValue('systemId', null);
                field.onChange(value);
              }}
            />
          );
        }}
      />
      <Controller
        control={control}
        name="systemId"
        render={({ field }) => {
          return (
            <Select<string>
              label="Система"
              mobileModalTitle="систему"
              value={field.value}
              className={styles.historyForm__select}
              options={systemsOptions}
              onChange={field.onChange}
            />
          );
        }}
      />
    </>
  );
};
