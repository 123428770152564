import Highcharts from 'highcharts';

const getLabelName = (name: string) => {
  return name.length > 10 ? `${name.slice(0, 10)}...` : name;
};

export const options: Highcharts.Options = {
  chart: {
    events: {
      redraw() {
        this.reflow();
      },
    },
    backgroundColor: 'var(--common2)',
  },
  credits: undefined,
  title: {
    text: '',
  },
  plotOptions: {
    pie: {
      borderColor: 'var(--common2)',
      allowPointSelect: true,
      innerSize: '65%',
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
        // eslint-disable-next-line object-shorthand
        formatter: function format() {
          return `<span style='text-transform: uppercase; letter-spacing: 1px'>${getLabelName(
            this.point.name
          )}</span><br /><span style='color: ${
            this.point?.color
          }; font-size: 24px; font-weight: 500'>${this.point.y}</span>`;
        },
        alignTo: 'straight',
        connectorShape: 'crookedLine',
        distance: 25,
        verticalAlign: 'middle',
        style: {
          color: 'var(--grey120)',
          fontFamily: 'Roboto',
          fontSize: '10px',
          fontWeight: '500',
          left: '0',
          top: '0',
        },
        useHTML: true,
      },
    },
  },
};
