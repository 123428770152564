import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { useCallback, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { convertFromDateFormat } from 'components';
import { BottomButtonsBlock } from 'core/components';
import { ApproveOrCancel } from 'core/modals';
import { CreateContractData } from 'core/types/contract';
import { SLADataType, SLAType } from 'core/types/sla';
import { createSupplementaryAgreementSADetail } from 'features/SupplementaryAgreement/ducks/actions';
import { getTicketTypesSupAgDetail } from 'features/SupplementaryAgreement/ducks/selectors';
import { ParamsProps } from 'features/SupplementaryAgreement/SupplementaryAgreement';

import { SupplementaryAgreementForm } from '../SupplementaryAgreementForm';
import { SystemsTableSupAg } from '../SystemsTableSupAg';

import styles from './CreateSupAgWrapper.module.scss';

export interface FormDataSupAgType {
  data?: Pick<CreateContractData, 'description' | 'endDate' | 'openDate'>;
  isValid?: boolean;
}

export const CreateSupAgWrapper = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const { contractId, systemId: systemFromIdUrl } = useParams<ParamsProps>();
  const isCreateMode = Boolean(contractId && systemFromIdUrl);
  const mainLayoutSticky = document.getElementById('mainLayoutSticky');

  const ticketTypes = useSelector(getTicketTypesSupAgDetail);

  const [formData, setFormData] = useState<FormDataSupAgType>({});
  const [SLAData, setSLAData] = useState<SLADataType>({});
  const [isValidSlaTicketTypes, setIsValidSlaTicketTypes] = useState(false);
  const [isModal, setIsModal] = useReducer((prev) => !prev, false);

  const isValidData = Boolean(formData?.isValid && SLAData?.isValid);

  const setIsValidCurrentSlaTicketTypes = (data: boolean) => {
    setIsValidSlaTicketTypes(data);
  };

  const onChangeFormData = useCallback(
    (data: FormDataSupAgType) => {
      setFormData({ ...data });
    },
    [setFormData]
  );

  const onChangeSLAData = useCallback(
    (data: SLADataType) => {
      setSLAData({ ...data });
    },
    [setSLAData]
  );

  const onCancelCreateSupAg = () => {
    push(`/admin/contracts/${contractId}`);
  };

  const handleSubmit = () => {
    if (SLAData.isValid && formData.isValid && formData?.data) {
      const { openDate, endDate, description } = formData.data;

      const openingDate = convertFromDateFormat(openDate);
      const closingDate = convertFromDateFormat(endDate);

      if (openingDate && closingDate) {
        dispatch(
          createSupplementaryAgreementSADetail({
            contractId,
            systemId: systemFromIdUrl,
            openDate: format(openingDate, 'yyyy-MM-dd', {
              locale: ru,
            }),
            endDate: format(closingDate, 'yyyy-MM-dd', {
              locale: ru,
            }),
            description,
            sla: SLAData.data as SLAType,
          })
        );
      }
    }
  };

  return (
    <form className={styles.createSupAgWrapper}>
      <SupplementaryAgreementForm
        isCreateMode={isCreateMode}
        contractId={contractId}
        onChange={onChangeFormData}
      />
      <SystemsTableSupAg
        onChange={onChangeSLAData}
        setIsValidSlaTicketTypes={setIsValidCurrentSlaTicketTypes}
        ticketTypes={ticketTypes}
      />
      {isCreateMode && (
        <BottomButtonsBlock
          isOpen={isValidData}
          parentNode={mainLayoutSticky}
          onSave={handleSubmit}
          onCancel={setIsModal}
          disabledSubmit={!isValidData || !isValidSlaTicketTypes}
        />
      )}
      <ApproveOrCancel
        onApprove={onCancelCreateSupAg}
        isModal={isModal}
        toggleModal={setIsModal}
        text="Вы уверены, что хотите отменить создание допсоглашения?"
      />
    </form>
  );
};
