const between = (x: number, max: number, min = 0) => {
  return x >= min && x <= max;
};

export const getNotificationTitle = (count: number) => {
  const numToString = count.toLocaleString();
  const lastNumber = Number(numToString.slice(-2));
  const numToChoseTitle = between(lastNumber, 19, 11)
    ? lastNumber
    : Number(numToString.slice(-1));
  if (numToChoseTitle === 1) {
    return 'уведомление';
  }
  if (between(numToChoseTitle, 4, 2)) {
    return 'уведомления';
  }
  return 'уведомлений';
};
