import { EnvironmentSetting } from 'core/types';

import { ENVIRONMENT_MAINS } from '../components/EnvironmentTableContainer/constants';

export const sortedEnvironmentSettings = (
  environmentSettings: EnvironmentSetting[] | undefined
) =>
  environmentSettings?.sort((a, b) => {
    const aIsMain = ENVIRONMENT_MAINS.includes(a.name);
    const bIsMain = ENVIRONMENT_MAINS.includes(b.name);

    if (aIsMain === bIsMain) {
      return 0;
    }

    if (aIsMain) {
      return -1;
    }

    return 1;
  });
