import { CustomFieldType, SelectOption } from 'components';
import { mapListToSelectOptions } from 'utils';

export const getListOptions = (
  type: CustomFieldType | null,
  values: string[] = []
): SelectOption[] => {
  return type === CustomFieldType.FIELD_LIST
    ? mapListToSelectOptions(values)
    : [];
};
