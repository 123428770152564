import cn from 'clsx';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { TableUsersTypes } from 'core/types/user';
import { EmployeesSLA, TicketsDashboard } from 'features/Desktop/components';
import { UsersTableContainer } from 'features/Users';

import {
  getEmployeesStatistic,
  getTicketsStatistic,
  getTotalEmployees,
} from '../../ducks/Employees/selectors';

import styles from './DesktopEmployees.module.scss';

interface DesktopEmployeesProps {
  className?: string;
}

export const DesktopEmployees: FC<DesktopEmployeesProps> = ({ className }) => {
  const employeesStatistic = useSelector(getEmployeesStatistic);
  const ticketsStatistic = useSelector(getTicketsStatistic);
  const totalEmployees = useSelector(getTotalEmployees);

  return (
    <div className={cn(styles.desktopEmployees, className)}>
      <div className={styles.desktopEmployees__leftContainer}>
        <TicketsDashboard
          classNameInfoProgressBars={styles.desktopEmployees__progressBars}
          classNameVl={styles.desktopEmployees__vl}
          ticketsStatistic={ticketsStatistic}
        />
        <EmployeesSLA />
      </div>
      <UsersTableContainer
        tableType={TableUsersTypes.FROM_DESKTOP}
        title="Сотрудники"
        usersList={employeesStatistic?.content}
        totalUsers={totalEmployees}
        withFilter={false}
        className={styles.desktopEmployees__table}
      />
    </div>
  );
};
