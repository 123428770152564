import {
  Contract,
  Environment,
  EnvironmentSetting,
  OrganizationType,
  TicketType,
  TicketTypeSettings,
} from 'core/types';
import {
  ContractDetailPayload,
  ContractIdSystemIdArgs,
  SystemsByContractIdPayload,
} from 'core/types/contract';
import {
  FetchOrganizations,
  Organization,
  OrganizationsFilter,
} from 'core/types/organization';
import { SLAType } from 'core/types/sla';
import { System, SystemsFilter } from 'core/types/system';
import { User, UsersFilter } from 'core/types/user';
import { WorkGroup, WorkGroupFilter } from 'core/types/workGroup';
import {
  ProlongationContractPayload,
  UpdateEnvironmentSettings,
} from 'features/Contract/types';
import { ResponseWithMeta } from 'store/types';
import { Attachment } from 'types/models/attachment';

import { ProgressStatus } from '../types';

export enum ContractDetail {
  // сам контракт
  CONTRACT_LOADING_SHOW = 'CONTRACT/CONTRACT_LOADING_SHOW',
  CONTRACT_LOADING_HIDE = 'CONTRACT/CONTRACT_LOADING_HIDE',
  FETCH_CONTRACT_REQUEST = 'CONTRACT/FETCH_CONTRACT_REQUEST',
  FETCH_CONTRACT_SUCCESS = 'CONTRACT/FETCH_CONTRACT_SUCCESS',
  // подписать контракт
  CONTRACT_SIGN_REQUEST = 'CONTRACT/CONTRACT_SIGN_REQUEST',
  // таблица допсоглашений
  SUPPLEMENTARY_AGREEMENTS_LOADING_SHOW = 'CONTRACT/SUPPLEMENTARY_AGREEMENTS_LOADING_SHOW',
  SUPPLEMENTARY_AGREEMENTS_LOADING_HIDE = 'CONTRACT/SUPPLEMENTARY_AGREEMENTS_LOADING_HIDE',
  FETCH_SUPPLEMENTARY_AGREEMENTS_SUCCESS = 'CONTRACT/FETCH_SUPPLEMENTARY_AGREEMENTS_SUCCESS',
  FETCH_SUPPLEMENTARY_AGREEMENTS_REQUEST = 'CONTRACT/FETCH_SUPPLEMENTARY_AGREEMENTS_REQUEST',
  SET_SUPPLEMENTARY_AGREEMENTS_PAGE = 'CONTRACT/SET_SUPPLEMENTARY_AGREEMENTS_PAGE',
  SET_SIZE_PAGE_SUPPLEMENTARY_AGREEMENTS = 'CONTRACT/SET_SIZE_PAGE_SUPPLEMENTARY_AGREEMENTS',
  SET_SORT_SUPPLEMENTARY_AGREEMENTS = 'CONTRACT/SET_SORT_SUPPLEMENTARY_AGREEMENTS',
  // запрос на создание
  CREATE_CONTRACT = 'CONTRACT/CREATE_CONTRACT',
  // выбранная система
  SET_SELECTED_SYSTEM_ID = 'CONTRACT/SET_SELECTED_SYSTEM_ID',
  // редактирование
  EDIT_CONTRACT = 'CONTRACT/EDIT_CONTRACT',
  UPDATE_CONTRACT = 'CONTRACT/UPDATE_CONTRACT',
  // уведомления
  UPDATE_CONTRACT_NOTIFICATIONS = 'CONTRACT/UPDATE_CONTRACT_NOTIFICATIONS',
  UPDATE_CONTRACT_NOTIFICATIONS_SUCCESS = 'CONTRACT/UPDATE_CONTRACT_NOTIFICATIONS_SUCCESS',
  // доступ
  SET_CONTRACT_PERMISSION = 'CONTRACT/SET_CONTRACT_PERMISSION',
  UPDATE_CONTRACT_PERMISSION = 'CONTRACT/UPDATE_CONTRACT_PERMISSION',
  UPDATE_CONTRACT_PERMISSION_SUCCESS = 'CONTRACT/UPDATE_CONTRACT_PERMISSION_SUCCESS',
  // системы уже добавленные к существующему контракту
  FETCH_SYSTEMS_BY_CONTRACT_ID_REQUEST = 'CONTRACT/FETCH_SYSTEMS_BY_CONTRACT_ID_REQUEST',
  FETCH_SYSTEMS_BY_CONTRACT_ID_SUCCESS = 'CONTRACT/FETCH_SYSTEMS_BY_CONTRACT_ID_SUCCESS',
  LOADING_SHOW_SYSTEMS_BY_CONTRACT_ID = 'CONTRACT/LOADING_SHOW_SYSTEMS_BY_CONTRACT_ID',
  LOADING_HIDE_SYSTEMS_BY_CONTRACT_ID = 'CONTRACT/LOADING_HIDE_SYSTEMS_BY_CONTRACT_ID',
  SET_SYSTEMS_BY_CONTRACT_ID_PAGE = 'CONTRACT/SET_SYSTEMS_BY_CONTRACT_ID_PAGE',
  SET_SIZE_PAGE_SYSTEMS_BY_CONTRACT_ID = 'CONTRACT/SET_SIZE_PAGE_SYSTEMS_BY_CONTRACT_ID',
  SET_SYSTEMS_BY_CONTRACT_ID_FILTER = 'CONTRACT/SET_SYSTEMS_BY_CONTRACT_ID_FILTER',
  SET_SYSTEMS_BY_CONTRACT_ID_SORT = 'CONTRACT/SET_SYSTEMS_BY_CONTRACT_ID_SORT',
  SET_ACTIVE_SYSTEM_BY_CONTRACT_ID = 'CONTRACT/SET_ACTIVE_SYSTEM_BY_CONTRACT_ID',
  RESET_ONLY_SYSTEMS_BY_CONTRACT_ID = 'CONTRACT/RESET_ONLY_SYSTEMS_BY_CONTRACT_ID',
  SET_SELECT_SYSTEM_BY_CONTRACT = 'CONTRACT/SET_SELECT_SYSTEM_BY_CONTRACT',
  // трансфер
  SYSTEM_TRANSFER = 'CONTRACT/SYSTEM_TRANSFER',
  SET_CHECKED_SYSTEM_FOR_TRANSFER = 'CONTRACT/SET_CHECKED_SYSTEM_FOR_TRANSFER',
  SET_ALL_CHECKED_SYSTEMS_FOR_TRANSFER = 'CONTRACT/SET_ALL_CHECKED_SYSTEMS_FOR_TRANSFER',
  SET_TRANSFER_STATUS = 'CONTRACT/SET_TRANSFER_STATUS',
  SET_CONTRACT_ID_FOR_TRANSFER = 'CONTRACT/SET_CONTRACT_ID_FOR_TRANSFER',
  RESET_PARTLY_CONTRACT_DETAIL_STATE_FOR_TRANSFER = 'CONTRACT/RESET_PARTLY_CONTRACT_DETAIL_STATE_FOR_TRANSFER',
  // список систем, которые можно добавить к контракту
  LOADING_SHOW_SYSTEMS = 'CONTRACT/LOADING_SHOW_SYSTEMS',
  LOADING_HIDE_SYSTEMS = 'CONTRACT/LOADING_HIDE_SYSTEMS',
  FETCH_SYSTEMS_REQUEST = 'CONTRACT/FETCH_SYSTEMS_REQUEST',
  FETCH_SYSTEMS_SUCCESS = 'CONTRACT/FETCH_SYSTEMS_SUCCESS',
  // список систем, к которым можно добавить допсоглашение
  FETCH_SYSTEMS_FOR_ADDING_SUPPLEMENTARY_AGREEMENT_REQUEST = 'CONTRACT/FETCH_SYSTEMS_FOR_ADDING_SUPPLEMENTARY_AGREEMENT_REQUEST',
  FETCH_SYSTEMS_FOR_ADDING_SUPPLEMENTARY_AGREEMENT_SUCCESS = 'CONTRACT/FETCH_SYSTEMS_FOR_ADDING_SUPPLEMENTARY_AGREEMENT_SUCCESS',
  LOADING_SHOW_SYSTEMS_FOR_ADDING_SUPPLEMENTARY_AGREEMENT = 'CONTRACT/LOADING_SHOW_SYSTEMS_FOR_ADDING_SUPPLEMENTARY_AGREEMENT',
  LOADING_HIDE_SYSTEMS_FOR_ADDING_SUPPLEMENTARY_AGREEMENT = 'CONTRACT/LOADING_HIDE_SYSTEMS_FOR_ADDING_SUPPLEMENTARY_AGREEMENT',

  // удаление
  DELETE_CONTRACT_REQUEST = 'CONTRACT/DELETE_CONTRACT_REQUEST',

  RESET_CONTRACT_DETAIL_STATE = 'CONTRACT/RESET_CONTRACT_DETAIL_STATE',
  // организации для селекта при создании
  FETCH_ORGANIZATIONS_REQUEST = 'CONTRACT/FETCH_ORGANIZATIONS_REQUEST',
  FETCH_ORGANIZATIONS_UPDATE_SUCCESS = 'CONTRACT/FETCH_ORGANIZATIONS_UPDATE_SUCCESS',
  FETCH_ORGANIZATIONS_JOIN_SUCCESS = 'CONTRACT/FETCH_ORGANIZATIONS_JOIN_SUCCESS',
  ORGANIZATIONS_LOADING_SHOW = 'CONTRACT/ORGANIZATIONS_LOADING_SHOW',
  ORGANIZATIONS_LOADING_HIDE = 'CONTRACT/ORGANIZATIONS_LOADING_HIDE',
  SET_CURRENT_ORGANIZATIONS_PAGE = 'CONTRACT/SET_CURRENT_ORGANIZATIONS_PAGE',
  SET_ORGANIZATIONS_SORT = 'CONTRACT/SET_ORGANIZATION_SORT',
  SET_ORGANIZATIONS_FILTER = 'CONTRACT/SET_ORGANIZATIONS_FILTER',
  SET_ORGANIZATION_SELECTED = 'CONTRACT/SET_ORGANIZATION_SELECTED',
  RESET_ORGANIZATION_ONLY_STATE = 'CONTRACT/RESET_ORGANIZATION_ONLY_STATE',
  // партнерские организации при создании
  FETCH_PARTNER_ORGANIZATIONS_REQUEST = 'CONTRACT/FETCH_PARTNER_ORGANIZATIONS_REQUEST',
  FETCH_PARTNER_ORGANIZATIONS_SUCCESS = 'CONTRACT/FETCH_PARTNER_ORGANIZATIONS_SUCCESS',
  PARTNER_ORGANIZATIONS_LOADING_SHOW = 'CONTRACT/PARTNER_ORGANIZATIONS_LOADING_SHOW',
  PARTNER_ORGANIZATIONS_LOADING_HIDE = 'CONTRACT/PARTNER_ORGANIZATIONS_LOADING_HIDE',

  // ответственные уже добавленные в контракт (грузятся прямо в контракте), state не нужен

  // ответственные, которых можно добавить в контракт
  FETCH_USERS_ALLOWED_TO_ADD_TO_CONTRACT_REQUEST = 'CONTRACT/FETCH_USERS_ALLOWED_TO_ADD_TO_CONTRACT_REQUEST',
  FETCH_USERS_ALLOWED_TO_ADD_TO_SUCCESS_REQUEST = 'CONTRACT/FETCH_USERS_ALLOWED_TO_ADD_TO_SUCCESS_REQUEST',
  USERS_LOADING_SHOW = 'CONTRACT/USERS_LOADING_SHOW',
  USERS_LOADING_HIDE = 'CONTRACT/USERS_LOADING_HIDE',
  SET_USERS_PAGE = 'CONTRACT/SET_USERS_PAGE',
  SET_USERS_FILTER = 'CONTRACT/SET_USERS_FILTER',
  // обновление спика ответственных = обновление контракта
  RESET_USERS_ALLOWED_TO_ADD_TO_CONTRACT_ONLY_STATE = 'CONTRACT/RESET_USERS_ALLOWED_TO_ADD_TO_CONTRACT_ONLY_STATE',
  // вложения контракта
  ATTACHMENTS_LOADING_SHOW = 'CONTRACT/ATTACHMENTS_LOADING_SHOW',
  ATTACHMENTS_LOADING_HIDE = 'CONTRACT/ATTACHMENTS_LOADING_HIDE',
  FETCH_CONTRACT_ATTACHMENTS_REQUEST = 'CONTRACT/FETCH_CONTRACT_ATTACHMENTS_REQUEST',
  FETCH_CONTRACT_ATTACHMENTS_SUCCESS = 'CONTRACT/FETCH_CONTRACT_ATTACHMENTS_SUCCESS',
  ADD_ATTACHMENT_TO_CONTRACT_REQUEST = 'CONTRACT/ADD_ATTACHMENT_TO_CONTRACT_REQUEST',
  RESET_ONLY_ATTACHMENTS_STATE = 'CONTRACT/RESET_ONLY_ATTACHMENTS_STATE',
  // рабочие группы по выбранной системе
  FETCH_WORK_GROUPS_BY_CONTRACT_BY_SYSTEM_REQUEST = 'CONTRACT/FETCH_WORK_GROUPS_BY_CONTRACT_BY_SYSTEM_REQUEST',
  FETCH_WORK_GROUPS_BY_CONTRACT_BY_SYSTEM_SUCCESS = 'CONTRACT/FETCH_WORK_GROUPS_BY_CONTRACT_BY_SYSTEM_SUCCESS',
  LOADING_SHOW_WORK_GROUPS_BY_CONTRACT_BY_SYSTEM = 'CONTRACT/LOADING_SHOW_WORK_GROUPS_BY_CONTRACT_BY_SYSTEM',
  LOADING_HIDE_WORK_GROUPS_BY_CONTRACT_BY_SYSTEM = 'CONTRACT/LOADING_HIDE_WORK_GROUPS_BY_CONTRACT_BY_SYSTEM',
  SET_WORK_GROUPS_PAGE_BY_CONTRACT_BY_SYSTEM = 'CONTRACT/SET_WORK_GROUPS_PAGE_BY_CONTRACT_BY_SYSTEM',
  SET_WORK_GROUPS_FILTER_BY_CONTRACT_BY_SYSTEM = 'CONTRACT/SET_WORK_GROUPS_FILTER_BY_CONTRACT_BY_SYSTEM',
  SET_SIZE_PAGE_WORK_GROUPS_BY_CONTRACT_BY_SYSTEM = 'CONTRACT/SET_SIZE_PAGE_WORK_GROUPS_BY_CONTRACT_BY_SYSTEM',
  SET_WORK_GROUPS_SORT_BY_CONTRACT_BY_SYSTEM = 'CONTRACT/SET_WORK_GROUPS_SORT_BY_CONTRACT_BY_SYSTEM',
  RESET_ONLY_WORK_GROUPS_BY_CONTRACT_BY_SYSTEM = 'CONTRACT/RESET_ONLY_WORK_GROUPS_BY_CONTRACT_BY_SYSTEM',
  // рабочие группы, разрешенные для добавления в связку система-контракт
  FETCH_WORK_GROUPS_BY_CONTRACT_REQUEST = 'CONTRACT/FETCH_WORK_GROUPS_BY_CONTRACT_REQUEST',
  FETCH_WORK_GROUPS_BY_CONTRACT_SUCCESS = 'CONTRACT/FETCH_WORK_GROUPS_BY_CONTRACT_SUCCESS',
  LOADING_SHOW_WORK_GROUPS_BY_CONTRACT = 'CONTRACT/LOADING_SHOW_WORK_GROUPS_BY_CONTRACT',
  LOADING_HIDE_WORK_GROUPS_BY_CONTRACT = 'CONTRACT/LOADING_HIDE_WORK_GROUPS_BY_CONTRACT',
  SET_WORK_GROUPS_PAGE_BY_CONTRACT = 'CONTRACT/SET_WORK_GROUPS_PAGE_BY_CONTRACT',
  SET_WORK_GROUPS_SIZE_PAGE_BY_CONTRACT = 'CONTRACT/SET_WORK_GROUPS_SIZE_PAGE_BY_CONTRACT',
  SET_WORK_GROUPS_FILTER_BY_CONTRACT = 'CONTRACT/SET_WORK_GROUPS_FILTER_BY_CONTRACT',
  RESET_ONLY_WORK_GROUPS_BY_CONTRACT = 'CONTRACT/RESET_ONLY_WORK_GROUPS_BY_CONTRACT',
  // обновить список групп у связки контракт-система
  UPDATE_WORK_GROUPS_IN_CONTRACT_IN_SYSTEM = 'CONTRACT/UPDATE_WORK_GROUPS_IN_CONTRACT_IN_SYSTEM',

  // сохраненные пользователем шаблоны sla
  LOADING_SHOW_SLA_PATTERNS_REQUEST = 'CONTRACT/LOADING_SHOW_SLA_PATTERNS_REQUEST',
  LOADING_HIDE_SLA_PATTERNS_REQUEST = 'CONTRACT/LOADING_HIDE_SLA_PATTERNS_REQUEST',
  FETCH_SLA_PATTERNS_REQUEST = 'CONTRACT/FETCH_SLA_PATTERNS_REQUEST',
  FETCH_SLA_PATTERNS_SUCCESS = 'CONTRACT/FETCH_SLA_PATTERNS_SUCCESS',
  RESET_SLA_PATTERNS = 'CONTRACT/RESET_SLA_PATTERNS',
  CHECK_IS_EXIST_PATTERN_TITLE_REQUEST = 'CONTRACT/CHECK_IS_EXIST_PATTERN_TITLE_REQUEST',
  CHECK_IS_EXIST_PATTERN_TITLE_SUCCESS = 'CONTRACT/CHECK_IS_EXIST_PATTERN_TITLE_SUCCESS',
  FETCH_PATTERN_BY_ID_REQUEST = 'CONTRACT/FETCH_PATTERN_BY_ID_REQUEST',
  FETCH_PATTERN_BY_ID_SUCCESS = 'CONTRACT/FETCH_PATTERN_BY_ID_SUCCESS',
  CREATE_SLA_PATTERN_REQUEST = 'CONTRACT/CREATE_SLA_PATTERN_REQUEST',

  // подгрузка sla для связки система-контракт
  LOADING_SHOW_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST = 'CONTRACT/LOADING_SHOW_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST',
  LOADING_HIDE_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST = 'CONTRACT/LOADING_HIDE_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST',
  FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST = 'CONTRACT/FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST',
  FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_SUCCESS = 'CONTRACT/FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_SUCCESS',

  // подгрузка типов тикета для связки система-контракт
  FETCH_TICKET_TYPE_SETTINGS_REQUEST = 'CONTRACT/FETCH_TICKET_TYPE_SETTINGS_REQUEST',
  FETCH_TICKET_TYPE_SETTINGS_SUCCESS = 'CONTRACT/FETCH_TICKET_TYPE_SETTINGS_SUCCESS',
  SET_LOADING_TICKET_TYPE_SETTINGS = 'CONTRACT/SET_LOADING_TICKET_TYPE_SETTINGS',
  FETCH_TICKET_TYPES_REQUEST = 'CONTRACT/FETCH_TICKET_TYPES_REQUEST',
  FETCH_TICKET_TYPES_SUCCESS = 'CONTRACT/FETCH_TICKET_TYPES_SUCCESS',

  SET_SELECTED_SYSTEM_SLA = 'CONTRACT/SET_SELECTED_SYSTEM_SLA',

  // привязка системы к контракту и создание сла
  ATTACH_SYSTEM_AND_SLA_TO_CONTRACT = 'CONTRACT/ATTACH_SYSTEM_AND_SLA_TO_CONTRACT',

  // обновить sla
  UPDATE_CONTRACT_SLA_REQUEST = 'CONTRACT/UPDATE_CONTRACT_SLA_REQUEST',

  // среды
  FETCH_ENVIRONMENTS_BY_SYSTEM_REQUEST = 'CONTRACT/FETCH_ENVIRONMENTS_BY_SYSTEM_REQUEST',
  FETCH_ENVIRONMENTS_BY_SYSTEM_SUCCESS = 'CONTRACT/FETCH_ENVIRONMENTS_BY_SYSTEM_SUCCESS',
  SET_ENVIRONMENTS_BY_SYSTEM_FILTER = 'CONTRACT/SET_ENVIRONMENTS_BY_SYSTEM_FILTER',
  SET_PAGE_ENVIRONMENTS_BY_SYSTEM = 'CONTRACT/SET_PAGE_ENVIRONMENTS_BY_SYSTEM',
  SET_SIZE_ENVIRONMENTS_BY_SYSTEM = 'CONTRACT/SET_SIZE_ENVIRONMENTS_BY_SYSTEM',
  SET_SORT_ENVIRONMENTS_BY_SYSTEM = 'CONTRACT/SET_SORT_ENVIRONMENTS_BY_SYSTEM',
  LOADING_SHOW_ENVIRONMENTS_BY_SYSTEM = 'CONTRACT/LOADING_SHOW_ENVIRONMENTS_BY_SYSTEM',
  LOADING_HIDE_ENVIRONMENTS_BY_SYSTEM = 'CONTRACT/LOADING_HIDE_ENVIRONMENTS_BY_SYSTEM',
  FETCH_ENVIRONMENT_SETTINGS_BY_SYSTEM_REQUEST = 'CONTRACT/FETCH_ENVIRONMENT_SETTINGS_BY_SYSTEM_REQUEST',
  FETCH_ENVIRONMENT_SETTINGS_BY_SYSTEM_SUCCESS = 'CONTRACT/FETCH_ENVIRONMENT_SETTINGS_BY_SYSTEM_SUCCESS',
  FETCH_UPDATE_ENVIRONMENT_SETTINGS_REQUEST = 'CONTRACT/FETCH_UPDATE_ENVIRONMENT_SETTINGS_REQUEST',

  // продлить контракт
  SET_PROLONGATION_CONTRACT = 'CONTRACT/SET_PROLONGATION_CONTRACT',
  PROLONGATION_CONTRACT = 'CONTRACT/PROLONGATION_CONTRACT',
  RESET_PARTLY_CONTRACT_DETAIL_STATE_FOR_PROLONGATION = 'CONTRACT/RESET_PARTLY_CONTRACT_DETAIL_STATE_FOR_PROLONGATION',
}

interface EditContractPayload {
  contract: Contract;
  systemId?: string;
}
export type AttachSystemAndSLAToContractType = ContractDetailPayload & {
  sla: SLAType;
};

export type UpdateContractPermissionsPayload = {
  contractId: string;
  isEnable?: boolean;
};

export type UpdateContractNotificationsPayload =
  UpdateContractPermissionsPayload & {
    notifyBefore?: number;
  };

export type SetContractPermissionPayload = Pick<
  UpdateContractPermissionsPayload,
  'isEnable'
>;

export interface ShowContractDetailLoadingAction {
  type: ContractDetail.CONTRACT_LOADING_SHOW;
}

export interface HideContractDetailLoadingAction {
  type: ContractDetail.CONTRACT_LOADING_HIDE;
}

export interface FetchContractDetailRequestAction {
  type: ContractDetail.FETCH_CONTRACT_REQUEST;
  payload: string;
}

export interface FetchContractDetailSuccessAction {
  type: ContractDetail.FETCH_CONTRACT_SUCCESS;
  payload: Contract;
}

export interface ContractSignRequestContractDetailAction {
  type: ContractDetail.CONTRACT_SIGN_REQUEST;
  payload: string;
}

export interface ShowSupplementaryAgreementsLoadingAction {
  type: ContractDetail.SUPPLEMENTARY_AGREEMENTS_LOADING_SHOW;
}

export interface HideSupplementaryAgreementsLoadingAction {
  type: ContractDetail.SUPPLEMENTARY_AGREEMENTS_LOADING_HIDE;
}

export interface FetchSupplementaryAgreementsSuccessAction {
  type: ContractDetail.FETCH_SUPPLEMENTARY_AGREEMENTS_SUCCESS;
  payload: ResponseWithMeta<Contract[]>;
}

export interface FetchSupplementaryAgreementsRequestAction {
  type: ContractDetail.FETCH_SUPPLEMENTARY_AGREEMENTS_REQUEST;
  payload: string;
}

export interface SetPageSupplementaryAgreementsContractDetailAction {
  type: ContractDetail.SET_SUPPLEMENTARY_AGREEMENTS_PAGE;
  payload: number;
}

export interface SetPageSizeSupplementaryAgreementsContractDetailAction {
  type: ContractDetail.SET_SIZE_PAGE_SUPPLEMENTARY_AGREEMENTS;
  payload: number;
}

export interface SetSortSupplementaryAgreementsContractDetailAction {
  type: ContractDetail.SET_SORT_SUPPLEMENTARY_AGREEMENTS;
  payload: string;
}

export interface CreateContractDetailAction {
  type: ContractDetail.CREATE_CONTRACT;
  payload: Contract;
}

export interface SetSelectedSystemIdContractDetailAction {
  type: ContractDetail.SET_SELECTED_SYSTEM_ID;
  payload?: string;
}

export interface EditContractDetailAction {
  type: ContractDetail.EDIT_CONTRACT;
  payload: EditContractPayload;
}

export interface UpdateContractDetailAction {
  type: ContractDetail.UPDATE_CONTRACT;
  payload: Contract;
}

export interface SetContractPermissionAction {
  type: ContractDetail.SET_CONTRACT_PERMISSION;
  payload: SetContractPermissionPayload;
}

export interface UpdateContractNotificationsAction {
  type: ContractDetail.UPDATE_CONTRACT_NOTIFICATIONS;
  payload: UpdateContractNotificationsPayload;
}

export interface UpdateContractNotificationsSuccessAction {
  type: ContractDetail.UPDATE_CONTRACT_NOTIFICATIONS_SUCCESS;
  payload: Contract;
}

export interface ProlongationContractAction {
  type: ContractDetail.SET_PROLONGATION_CONTRACT;
  payload: ProgressStatus;
}

export interface UpdateContractPermissionsAction {
  type: ContractDetail.UPDATE_CONTRACT_PERMISSION;
  payload: UpdateContractPermissionsPayload;
}

export interface UpdateContractPermissionsSuccessAction {
  type: ContractDetail.UPDATE_CONTRACT_PERMISSION_SUCCESS;
  payload: Contract;
}

export interface DeleteContractDetailRequestAction {
  type: ContractDetail.DELETE_CONTRACT_REQUEST;
  payload: string;
}

export interface FetchSystemsByContractIdRequestContractDetailAction {
  type: ContractDetail.FETCH_SYSTEMS_BY_CONTRACT_ID_REQUEST;
  payload: SystemsByContractIdPayload;
}

export interface FetchSystemsByContractIdSuccessContractDetailAction {
  type: ContractDetail.FETCH_SYSTEMS_BY_CONTRACT_ID_SUCCESS;
  payload: ResponseWithMeta<System[]>;
}

export interface LoadingShowSystemsByContractIdContractDetailAction {
  type: ContractDetail.LOADING_SHOW_SYSTEMS_BY_CONTRACT_ID;
}

export interface LoadingHideSystemsByContractIdContractDetailAction {
  type: ContractDetail.LOADING_HIDE_SYSTEMS_BY_CONTRACT_ID;
}

export interface SetPageSystemsByContractIdContractDetailAction {
  type: ContractDetail.SET_SYSTEMS_BY_CONTRACT_ID_PAGE;
  payload: number;
}

export interface SetPageSizeSystemsByContractIdContractDetailAction {
  type: ContractDetail.SET_SIZE_PAGE_SYSTEMS_BY_CONTRACT_ID;
  payload: number;
}

export interface SetFilterSystemsByContractIdContractDetailAction {
  type: ContractDetail.SET_SYSTEMS_BY_CONTRACT_ID_FILTER;
  payload: SystemsFilter;
}

export interface SetActiveSystemByContractIdContractDetailAction {
  type: ContractDetail.SET_ACTIVE_SYSTEM_BY_CONTRACT_ID;
  payload?: string;
}

export interface SetSortSystemsByContractIdContractDetailAction {
  type: ContractDetail.SET_SYSTEMS_BY_CONTRACT_ID_SORT;
  payload: string;
}

export interface ResetSystemsByContractIdOnlyContractDetailAction {
  type: ContractDetail.RESET_ONLY_SYSTEMS_BY_CONTRACT_ID;
}

export interface SystemTransferAction {
  type: ContractDetail.SYSTEM_TRANSFER;
  payload: Contract;
}

export interface SetCheckedSystemForSystemTransferAction {
  type: ContractDetail.SET_CHECKED_SYSTEM_FOR_TRANSFER;
  payload: System;
}

export interface SetAllCheckedSystemForSystemTransferAction {
  type: ContractDetail.SET_ALL_CHECKED_SYSTEMS_FOR_TRANSFER;
  payload: System[];
}

export interface SetTransferStatusAction {
  type: ContractDetail.SET_TRANSFER_STATUS;
  payload: ProgressStatus;
}

export interface SetContractIdForSystemTransferAction {
  type: ContractDetail.SET_CONTRACT_ID_FOR_TRANSFER;
  payload: string;
}

export interface LoadingShowSystemsForAddingToContractContractDetailAction {
  type: ContractDetail.LOADING_SHOW_SYSTEMS;
}

export interface LoadingHideSystemsForAddingToContractContractDetailAction {
  type: ContractDetail.LOADING_HIDE_SYSTEMS;
}

export interface FetchSystemsForAddingToContractRequestContractDetailAction {
  type: ContractDetail.FETCH_SYSTEMS_REQUEST;
  payload?: string;
}

export interface FetchSystemsForAddingToContractSuccessContractDetailAction {
  type: ContractDetail.FETCH_SYSTEMS_SUCCESS;
  payload: System[];
}

export interface FetchSystemsForAddingSupplementaryAgreementRequestContractDetailAction {
  type: ContractDetail.FETCH_SYSTEMS_FOR_ADDING_SUPPLEMENTARY_AGREEMENT_REQUEST;
  payload?: string;
}

export interface FetchSystemsForAddingSupplementaryAgreementSuccessContractDetailAction {
  type: ContractDetail.FETCH_SYSTEMS_FOR_ADDING_SUPPLEMENTARY_AGREEMENT_SUCCESS;
  payload: System[];
}

export interface LoadingShowSystemsForAddingSupplementaryAgreementContractDetailAction {
  type: ContractDetail.LOADING_SHOW_SYSTEMS_FOR_ADDING_SUPPLEMENTARY_AGREEMENT;
}

export interface LoadingHideSystemsForAddingSupplementaryAgreementContractDetailAction {
  type: ContractDetail.LOADING_HIDE_SYSTEMS_FOR_ADDING_SUPPLEMENTARY_AGREEMENT;
}

export interface SetSelectSystemByContractAction {
  type: ContractDetail.SET_SELECT_SYSTEM_BY_CONTRACT;
  payload?: System;
}

export interface ResetContractDetailStateAction {
  type: ContractDetail.RESET_CONTRACT_DETAIL_STATE;
}

export interface ShowOrganizationsLoadingContractDetailAction {
  type: ContractDetail.ORGANIZATIONS_LOADING_SHOW;
}

export interface HideOrganizationsLoadingContractDetailAction {
  type: ContractDetail.ORGANIZATIONS_LOADING_HIDE;
}

export interface FetchOrganizationsSuccessUpdateContractDetailAction {
  type: ContractDetail.FETCH_ORGANIZATIONS_UPDATE_SUCCESS;
  payload: ResponseWithMeta<Organization[]>;
}

export interface FetchOrganizationsRequestContractDetailAction {
  type: ContractDetail.FETCH_ORGANIZATIONS_REQUEST;
  payload?: FetchOrganizations;
}

export interface FetchOrganizationsJoinSuccessContractDetailAction {
  type: ContractDetail.FETCH_ORGANIZATIONS_JOIN_SUCCESS;
  payload: ResponseWithMeta<Organization[]>;
}

export interface ShowPartnerOrganizationsLoadingContractDetailAction {
  type: ContractDetail.PARTNER_ORGANIZATIONS_LOADING_SHOW;
}

export interface HidePartnerOrganizationsLoadingContractDetailAction {
  type: ContractDetail.PARTNER_ORGANIZATIONS_LOADING_HIDE;
}

export interface FetchPartnerOrganizationsRequestContractDetailAction {
  type: ContractDetail.FETCH_PARTNER_ORGANIZATIONS_REQUEST;
  payload: OrganizationType[];
}

export interface FetchPartnerOrganizationsSuccessContractDetailAction {
  type: ContractDetail.FETCH_PARTNER_ORGANIZATIONS_SUCCESS;
  payload: ResponseWithMeta<Organization[]>;
}

export interface ResetOrganizationOnlyStateContractDetailAction {
  type: ContractDetail.RESET_ORGANIZATION_ONLY_STATE;
}

export interface SetCurrentOrganizationIdContractDetailAction {
  type: ContractDetail.SET_ORGANIZATION_SELECTED;
  payload?: string;
}

export interface SetOrganizationsFilterContractDetailAction {
  type: ContractDetail.SET_ORGANIZATIONS_FILTER;
  payload: OrganizationsFilter;
}

export interface SetOrganizationsPageContractDetailAction {
  type: ContractDetail.SET_CURRENT_ORGANIZATIONS_PAGE;
  payload: number;
}

export interface SetOrganizationsSortContractDetailAction {
  type: ContractDetail.SET_ORGANIZATIONS_SORT;
  payload: string;
}
// users
export interface ShowUsersAllowedToAddToContractDetailLoadingAction {
  type: ContractDetail.USERS_LOADING_SHOW;
}

export interface HideUsersAllowedToAddToContractDetailLoadingAction {
  type: ContractDetail.USERS_LOADING_HIDE;
}

export interface FetchUsersAllowedToAddToContractDetailRequestAction {
  type: ContractDetail.FETCH_USERS_ALLOWED_TO_ADD_TO_CONTRACT_REQUEST;
}

export interface FetchUsersAllowedToAddToContractDetailSuccessAction {
  type: ContractDetail.FETCH_USERS_ALLOWED_TO_ADD_TO_SUCCESS_REQUEST;
  payload: ResponseWithMeta<User[]>;
}

export interface SetUsersPageContractDetailAction {
  type: ContractDetail.SET_USERS_PAGE;
  payload: number;
}

export interface SetUsersFilterContractDetailAction {
  type: ContractDetail.SET_USERS_FILTER;
  payload: UsersFilter;
}

export interface ResetUsersOnlyStateContractDetailAction {
  type: ContractDetail.RESET_USERS_ALLOWED_TO_ADD_TO_CONTRACT_ONLY_STATE;
}

// attachments
export interface ShowAttachmentsLoadingContractDetailAction {
  type: ContractDetail.ATTACHMENTS_LOADING_SHOW;
}

export interface HideAttachmentsLoadingContractDetailAction {
  type: ContractDetail.ATTACHMENTS_LOADING_HIDE;
}

export interface FetchContractAttachmentsRequestContractDetailAction {
  type: ContractDetail.FETCH_CONTRACT_ATTACHMENTS_REQUEST;
  payload: string;
}

export interface FetchContractAttachmentsSuccessContractDetailAction {
  type: ContractDetail.FETCH_CONTRACT_ATTACHMENTS_SUCCESS;
  payload: Attachment[];
}

export interface AddAttachmentToContractDetailRequestAction {
  type: ContractDetail.ADD_ATTACHMENT_TO_CONTRACT_REQUEST;
  payload: FormData;
}

export interface ResetOnlyAttachmentsStateContractDetailAction {
  type: ContractDetail.RESET_ONLY_ATTACHMENTS_STATE;
}
// environments
export interface FetchEnvironmentsBySystemRequestAction {
  type: ContractDetail.FETCH_ENVIRONMENTS_BY_SYSTEM_REQUEST;
  payload: ContractDetailPayload;
}

export interface FetchEnvironmentsBySystemSuccessAction {
  type: ContractDetail.FETCH_ENVIRONMENTS_BY_SYSTEM_SUCCESS;
  payload: ResponseWithMeta<Environment[]>;
}

export interface SetFilterEnvironmentsBySystemAction {
  type: ContractDetail.SET_ENVIRONMENTS_BY_SYSTEM_FILTER;
  payload: WorkGroupFilter;
}

export interface SetPageEnvironmentsBySystemAction {
  type: ContractDetail.SET_PAGE_ENVIRONMENTS_BY_SYSTEM;
  payload: number;
}

export interface SetSizeEnvironmentsBySystemAction {
  type: ContractDetail.SET_SIZE_ENVIRONMENTS_BY_SYSTEM;
  payload: number;
}

export interface SetSortEnvironmentsBySystemAction {
  type: ContractDetail.SET_SORT_ENVIRONMENTS_BY_SYSTEM;
  payload: string;
}

export interface FetchEnvironmentSettingsBySystemRequestAction {
  type: ContractDetail.FETCH_ENVIRONMENT_SETTINGS_BY_SYSTEM_REQUEST;
  payload?: ContractIdSystemIdArgs;
}

export interface FetchEnvironmentSettingsBySystemSuccessAction {
  type: ContractDetail.FETCH_ENVIRONMENT_SETTINGS_BY_SYSTEM_SUCCESS;
  payload?: EnvironmentSetting[];
}

export interface FetchUpdateEnvironmentSettingsRequestAction {
  type: ContractDetail.FETCH_UPDATE_ENVIRONMENT_SETTINGS_REQUEST;
  payload: UpdateEnvironmentSettings;
}

// work groups

export interface FetchWorkGroupsByContractBySystemRequestContractDetailAction {
  type: ContractDetail.FETCH_WORK_GROUPS_BY_CONTRACT_BY_SYSTEM_REQUEST;
  payload: ContractDetailPayload;
}

export interface FetchWorkGroupsByContractBySystemSuccessContractDetailAction {
  type: ContractDetail.FETCH_WORK_GROUPS_BY_CONTRACT_BY_SYSTEM_SUCCESS;
  payload: ResponseWithMeta<WorkGroup[]>;
}

export interface LoadingShowWorkGroupsByContractBySystemContractDetailAction {
  type: ContractDetail.LOADING_SHOW_WORK_GROUPS_BY_CONTRACT_BY_SYSTEM;
}

export interface LoadingHideWorkGroupsByContractBySystemContractDetailAction {
  type: ContractDetail.LOADING_HIDE_WORK_GROUPS_BY_CONTRACT_BY_SYSTEM;
}

export interface SetPageWorkGroupsByContractBySystemContractDetailAction {
  type: ContractDetail.SET_WORK_GROUPS_PAGE_BY_CONTRACT_BY_SYSTEM;
  payload: number;
}

export interface SetFilterWorkGroupsByContractBySystemContractDetailAction {
  type: ContractDetail.SET_WORK_GROUPS_FILTER_BY_CONTRACT_BY_SYSTEM;
  payload: WorkGroupFilter;
}

export interface SetSizePageWorkGroupsByContractBySystemContractDetailAction {
  type: ContractDetail.SET_SIZE_PAGE_WORK_GROUPS_BY_CONTRACT_BY_SYSTEM;
  payload: number;
}

export interface SetSortWorkGroupsByContractBySystemContractDetailAction {
  type: ContractDetail.SET_WORK_GROUPS_SORT_BY_CONTRACT_BY_SYSTEM;
  payload: string;
}

export interface ResetWorkGroupsByContractBySystemContractDetailAction {
  type: ContractDetail.RESET_ONLY_WORK_GROUPS_BY_CONTRACT_BY_SYSTEM;
}

// work groups access

export interface FetchWorkGroupsByContractRequestContractDetailAction {
  type: ContractDetail.FETCH_WORK_GROUPS_BY_CONTRACT_REQUEST;
  payload: string;
}

export interface FetchWorkGroupsByContractSuccessContractDetailAction {
  type: ContractDetail.FETCH_WORK_GROUPS_BY_CONTRACT_SUCCESS;
  payload: ResponseWithMeta<WorkGroup[]>;
}

export interface LoadingShowWorkGroupsByContractContractDetailAction {
  type: ContractDetail.LOADING_SHOW_WORK_GROUPS_BY_CONTRACT;
}

export interface LoadingHideWorkGroupsByContractContractDetailAction {
  type: ContractDetail.LOADING_HIDE_WORK_GROUPS_BY_CONTRACT;
}

export interface SetPageWorkGroupsByContractContractDetailAction {
  type: ContractDetail.SET_WORK_GROUPS_PAGE_BY_CONTRACT;
  payload: number;
}

export interface SetSizePageWorkGroupsByContractContractDetailAction {
  type: ContractDetail.SET_WORK_GROUPS_SIZE_PAGE_BY_CONTRACT;
  payload: number;
}

export interface SetFilterWorkGroupsByContractContractDetailAction {
  type: ContractDetail.SET_WORK_GROUPS_FILTER_BY_CONTRACT;
  payload: WorkGroupFilter;
}

export interface ResetWorkGroupsByContractContractDetailAction {
  type: ContractDetail.RESET_ONLY_WORK_GROUPS_BY_CONTRACT;
}
// update work groups
export interface UpdateWorkGroupsInContractInSystemContractDetailAction {
  type: ContractDetail.UPDATE_WORK_GROUPS_IN_CONTRACT_IN_SYSTEM;
  payload: string[];
}

// patterns
export interface LoadingShowSLAPatternsContractDetailAction {
  type: ContractDetail.LOADING_SHOW_SLA_PATTERNS_REQUEST;
}
export interface LoadingHideSLAPatternsContractDetailAction {
  type: ContractDetail.LOADING_HIDE_SLA_PATTERNS_REQUEST;
}
export interface FetchSLAPatternsRequestContractDetailAction {
  type: ContractDetail.FETCH_SLA_PATTERNS_REQUEST;
  payload?: string;
}

export interface FetchSLAPatternsSuccessContractDetailAction {
  type: ContractDetail.FETCH_SLA_PATTERNS_SUCCESS;
  payload?: SLAType[];
}

export interface ResetSLAPatternsContractDetailAction {
  type: ContractDetail.RESET_SLA_PATTERNS;
}

export interface CheckIsExistPatternTitleRequestContractDetailAction {
  type: ContractDetail.CHECK_IS_EXIST_PATTERN_TITLE_REQUEST;
  payload: string;
}

export interface CheckIsExistPatternTitleSuccessContractDetailAction {
  type: ContractDetail.CHECK_IS_EXIST_PATTERN_TITLE_SUCCESS;
  payload: boolean;
}

export interface FetchPatternByIdRequestContractDetailAction {
  type: ContractDetail.FETCH_PATTERN_BY_ID_REQUEST;
  payload: string;
}

export interface FetchPatternByIdSuccessContractDetailAction {
  type: ContractDetail.FETCH_PATTERN_BY_ID_SUCCESS;
  payload: SLAType;
}

export interface CreateSLAPatternRequestContractDetailAction {
  type: ContractDetail.CREATE_SLA_PATTERN_REQUEST;
  payload: SLAType;
}
// sla
export interface FetchSlaByContractIdBySystemIdRequestContractDetailAction {
  type: ContractDetail.FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST;
  payload: ContractDetailPayload;
}

export interface FetchSlaByContractIdBySystemIdSuccessContractDetailAction {
  type: ContractDetail.FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_SUCCESS;
  payload: SLAType;
}

export interface FetchTicketTypesSettingsRequestContractDetailAction {
  type: ContractDetail.FETCH_TICKET_TYPE_SETTINGS_REQUEST;
  payload: ContractIdSystemIdArgs;
}

export interface FetchTicketTypesSettingsSuccessContractDetailAction {
  type: ContractDetail.FETCH_TICKET_TYPE_SETTINGS_SUCCESS;
  payload: TicketTypeSettings[];
}

export interface SetLoadingTicketTypesSettingsContractDetailAction {
  type: ContractDetail.SET_LOADING_TICKET_TYPE_SETTINGS;
  payload: boolean;
}

export interface FetchTicketTypesRequestContractDetailAction {
  type: ContractDetail.FETCH_TICKET_TYPES_REQUEST;
  payload: string;
}

export interface FetchTicketTypesSuccessContractDetailAction {
  type: ContractDetail.FETCH_TICKET_TYPES_SUCCESS;
  payload: TicketType[];
}

export interface SetSelectedSystemSlaContractDetailAction {
  type: ContractDetail.SET_SELECTED_SYSTEM_SLA;
  payload: string;
}

export interface LoadingShowSlaByContractIdBySystemIdRequestContractDetailAction {
  type: ContractDetail.LOADING_SHOW_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST;
}

export interface LoadingHideSlaByContractIdBySystemIdRequestContractDetailAction {
  type: ContractDetail.LOADING_HIDE_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST;
}

export interface AttachSystemAndSLAToContractDetailAction {
  type: ContractDetail.ATTACH_SYSTEM_AND_SLA_TO_CONTRACT;
  payload: AttachSystemAndSLAToContractType;
}

export interface UpdateContractSLARequestContractDetailAction {
  type: ContractDetail.UPDATE_CONTRACT_SLA_REQUEST;
  payload: AttachSystemAndSLAToContractType;
}

export interface ProlongationRequestContractDetailAction {
  type: ContractDetail.PROLONGATION_CONTRACT;
  payload: ProlongationContractPayload;
}

export type ContractDetailActionsTypes =
  | ShowContractDetailLoadingAction
  | HideContractDetailLoadingAction
  | FetchContractDetailRequestAction
  | FetchContractDetailSuccessAction
  | ContractSignRequestContractDetailAction
  | ShowSupplementaryAgreementsLoadingAction
  | HideSupplementaryAgreementsLoadingAction
  | FetchSupplementaryAgreementsSuccessAction
  | FetchSupplementaryAgreementsRequestAction
  | SetPageSupplementaryAgreementsContractDetailAction
  | SetPageSizeSupplementaryAgreementsContractDetailAction
  | SetSortSupplementaryAgreementsContractDetailAction
  | CreateContractDetailAction
  | SetSelectedSystemIdContractDetailAction
  | EditContractDetailAction
  | UpdateContractDetailAction
  | SetContractPermissionAction
  | UpdateContractNotificationsAction
  | UpdateContractNotificationsSuccessAction
  | ProlongationContractAction
  | UpdateContractPermissionsAction
  | UpdateContractPermissionsSuccessAction
  | DeleteContractDetailRequestAction
  | FetchSystemsByContractIdRequestContractDetailAction
  | FetchSystemsByContractIdSuccessContractDetailAction
  | LoadingShowSystemsByContractIdContractDetailAction
  | LoadingHideSystemsByContractIdContractDetailAction
  | SetPageSystemsByContractIdContractDetailAction
  | SetPageSizeSystemsByContractIdContractDetailAction
  | SetFilterSystemsByContractIdContractDetailAction
  | SetActiveSystemByContractIdContractDetailAction
  | SetSortSystemsByContractIdContractDetailAction
  | ResetSystemsByContractIdOnlyContractDetailAction
  | SystemTransferAction
  | SetCheckedSystemForSystemTransferAction
  | SetAllCheckedSystemForSystemTransferAction
  | SetTransferStatusAction
  | SetContractIdForSystemTransferAction
  | LoadingShowSystemsForAddingToContractContractDetailAction
  | LoadingHideSystemsForAddingToContractContractDetailAction
  | FetchSystemsForAddingToContractRequestContractDetailAction
  | FetchSystemsForAddingToContractSuccessContractDetailAction
  | FetchSystemsForAddingSupplementaryAgreementRequestContractDetailAction
  | FetchSystemsForAddingSupplementaryAgreementSuccessContractDetailAction
  | LoadingShowSystemsForAddingSupplementaryAgreementContractDetailAction
  | LoadingHideSystemsForAddingSupplementaryAgreementContractDetailAction
  | ResetContractDetailStateAction
  | ShowOrganizationsLoadingContractDetailAction
  | HideOrganizationsLoadingContractDetailAction
  | FetchOrganizationsSuccessUpdateContractDetailAction
  | FetchOrganizationsRequestContractDetailAction
  | FetchOrganizationsJoinSuccessContractDetailAction
  | FetchPartnerOrganizationsSuccessContractDetailAction
  | ResetOrganizationOnlyStateContractDetailAction
  | SetCurrentOrganizationIdContractDetailAction
  | SetOrganizationsFilterContractDetailAction
  | SetOrganizationsPageContractDetailAction
  | SetOrganizationsSortContractDetailAction
  | ShowUsersAllowedToAddToContractDetailLoadingAction
  | HideUsersAllowedToAddToContractDetailLoadingAction
  | FetchUsersAllowedToAddToContractDetailRequestAction
  | FetchUsersAllowedToAddToContractDetailSuccessAction
  | SetUsersPageContractDetailAction
  | SetUsersFilterContractDetailAction
  | ResetUsersOnlyStateContractDetailAction
  | ShowAttachmentsLoadingContractDetailAction
  | HideAttachmentsLoadingContractDetailAction
  | FetchContractAttachmentsRequestContractDetailAction
  | FetchContractAttachmentsSuccessContractDetailAction
  | AddAttachmentToContractDetailRequestAction
  | ResetOnlyAttachmentsStateContractDetailAction
  | FetchWorkGroupsByContractBySystemRequestContractDetailAction
  | FetchWorkGroupsByContractBySystemSuccessContractDetailAction
  | LoadingShowWorkGroupsByContractBySystemContractDetailAction
  | LoadingHideWorkGroupsByContractBySystemContractDetailAction
  | SetPageWorkGroupsByContractBySystemContractDetailAction
  | SetFilterWorkGroupsByContractBySystemContractDetailAction
  | SetSizePageWorkGroupsByContractBySystemContractDetailAction
  | SetSortWorkGroupsByContractBySystemContractDetailAction
  | ResetWorkGroupsByContractBySystemContractDetailAction
  | FetchWorkGroupsByContractRequestContractDetailAction
  | FetchWorkGroupsByContractSuccessContractDetailAction
  | LoadingShowWorkGroupsByContractContractDetailAction
  | LoadingHideWorkGroupsByContractContractDetailAction
  | SetPageWorkGroupsByContractContractDetailAction
  | SetFilterWorkGroupsByContractContractDetailAction
  | ResetWorkGroupsByContractContractDetailAction
  | UpdateWorkGroupsInContractInSystemContractDetailAction
  | LoadingShowSLAPatternsContractDetailAction
  | LoadingHideSLAPatternsContractDetailAction
  | FetchSLAPatternsRequestContractDetailAction
  | FetchSLAPatternsSuccessContractDetailAction
  | ResetSLAPatternsContractDetailAction
  | CheckIsExistPatternTitleRequestContractDetailAction
  | CheckIsExistPatternTitleSuccessContractDetailAction
  | FetchPatternByIdRequestContractDetailAction
  | FetchPatternByIdSuccessContractDetailAction
  | CreateSLAPatternRequestContractDetailAction
  | FetchSlaByContractIdBySystemIdRequestContractDetailAction
  | FetchSlaByContractIdBySystemIdSuccessContractDetailAction
  | FetchTicketTypesSettingsSuccessContractDetailAction
  | SetLoadingTicketTypesSettingsContractDetailAction
  | FetchTicketTypesSuccessContractDetailAction
  | SetSelectedSystemSlaContractDetailAction
  | LoadingShowSlaByContractIdBySystemIdRequestContractDetailAction
  | LoadingHideSlaByContractIdBySystemIdRequestContractDetailAction
  | AttachSystemAndSLAToContractDetailAction
  | UpdateContractSLARequestContractDetailAction
  | SetSizePageWorkGroupsByContractContractDetailAction
  | FetchEnvironmentsBySystemSuccessAction
  | SetFilterEnvironmentsBySystemAction
  | SetPageEnvironmentsBySystemAction
  | SetSizeEnvironmentsBySystemAction
  | SetSortEnvironmentsBySystemAction
  | SetSelectSystemByContractAction
  | FetchEnvironmentSettingsBySystemSuccessAction;
