import { Edge, MarkerType } from 'reactflow';

import { Theme } from 'core/types';

import { MARKER_COLOR_MAP, SELECTED_MARKER_COLOR_MAP } from '../constants';

interface CustomEdgesArgs {
  edges: Edge[];
  theme?: Theme;
}

export const getCustomEdges = ({ edges, theme }: CustomEdgesArgs) => {
  return edges.map((edge) => {
    return {
      ...edge,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        color: !edge.selected
          ? MARKER_COLOR_MAP[theme || Theme.LIGHT]
          : SELECTED_MARKER_COLOR_MAP[theme || Theme.LIGHT],
        strokeWidth: 2,
      },
      type: 'smoothstep',
      deletable: true,
    };
  });
};
