import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ActionList } from 'core/types/auth';
import { TableContractsTypes } from 'core/types/contract';
import { getIsAccessedAction } from 'features/Auth';
import { resetContractDetailState } from 'features/Contract';
import {
  getCurrentOrganization,
  getCurrentOrganizationId,
} from 'features/Organizations';
import {
  fetchSLAByContractIdBySystemIdRequestSystems,
  fetchSystemsByContractId,
  getSystemId,
  resetSystemsState,
} from 'features/Systems';
import { RouterHref } from 'routes/routerHref';
import { checkObjectIdentity } from 'utils';

import { DEFAULT_FILTER } from '../constants';
import {
  deleteContractRequest,
  fetchContractRequest,
  fetchContractsByOrganizationsRequest,
  fetchContractsBySystemIdRequest,
  fetchContractsRequest,
  resetSLAByContractIdBySystemIdContracts,
  setCurrentContractId,
  setCurrentContractsPage,
  setSizePage,
  setSortContracts,
} from '../ducks/actions';
import {
  getContractFilter,
  getContracts,
  getCurrentContract,
  getCurrentContractId,
  getPropsContracts,
} from '../ducks/selectors';

export const fetchFunctions = {
  [TableContractsTypes.FULL]: fetchContractsRequest,
  [TableContractsTypes.FROM_ORGANIZATIONS]:
    fetchContractsByOrganizationsRequest,
  [TableContractsTypes.FROM_SYSTEMS]: fetchContractsBySystemIdRequest,
};

export const useContractsTableContainer = (tableType: TableContractsTypes) => {
  const dispatch = useDispatch();

  const { push } = useHistory();

  const currentContractId = useSelector(getCurrentContractId);
  const contracts = useSelector(getContracts);
  const organization = useSelector(getCurrentOrganization);
  const filterValues = useSelector(getContractFilter);
  const currentOrganizationId = useSelector(getCurrentOrganizationId);
  const systemId = useSelector(getSystemId);
  const { pageNum, pageSize, totalElements, sortContracts, loadingContracts } =
    useSelector(getPropsContracts);

  const [tableOpened, setTableOpened] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showFilterRow, setShowFilterRow] = useState(false);
  const contract = useSelector(getCurrentContract);
  const isAccessToCreateContract = useSelector(
    getIsAccessedAction(ActionList.CreateContract)
  );
  const isAccessToDeleteContract = useSelector(
    getIsAccessedAction(ActionList.DeleteEntries)
  );

  const canCreateContract: boolean =
    tableType === TableContractsTypes.FROM_ORGANIZATIONS ||
    tableType === TableContractsTypes.FULL;

  const isFilterActive = !checkObjectIdentity(filterValues, DEFAULT_FILTER);
  const searchDisable = !contracts?.length || (showFilterRow && isFilterActive);

  const getActiveId = () => {
    const keys = {
      [TableContractsTypes.FROM_ORGANIZATIONS]: currentOrganizationId,
      [TableContractsTypes.FROM_SYSTEMS]: systemId,
      [TableContractsTypes.FULL]: '',
    };
    return keys[tableType];
  };

  const toggleDeleteModal = () => {
    setShowDeleteModal((prev) => !prev);
  };

  const handleShowModalDelete = () => {
    if (contract?.id) {
      setShowDeleteModal(true);
    }
  };

  const toggleFilterRow = () => {
    setShowFilterRow((prevState) => !prevState);
  };

  const handleDelete = () => {
    if (contract?.id) {
      dispatch(setCurrentContractId(''));
      dispatch(deleteContractRequest({ id: contract.id }));
    }
  };

  const handleSort = (value: string) => {
    dispatch(setSortContracts(value));
    dispatch(fetchFunctions[tableType]());
  };

  const handleChangePage = (page: number) => {
    dispatch(setCurrentContractsPage(page));
    dispatch(fetchFunctions[tableType]());
  };

  const onRowClickFunctions = {
    [TableContractsTypes.FULL]: (value: string) => {
      if (currentContractId !== value) {
        dispatch(setCurrentContractId(value));
        dispatch(fetchContractRequest(value));
        dispatch(resetSystemsState());
        dispatch(resetSLAByContractIdBySystemIdContracts());
        dispatch(fetchSystemsByContractId());
      }
    },
    [TableContractsTypes.FROM_SYSTEMS]: (value: string) => {
      if (currentContractId !== value) {
        dispatch(setCurrentContractId(value));
        dispatch(
          fetchSLAByContractIdBySystemIdRequestSystems({
            contractId: value,
            systemId,
          })
        );
      }
    },
    [TableContractsTypes.FROM_ORGANIZATIONS]: undefined,
  };

  const handleChangePageSize = (arg: number) => {
    dispatch(setSizePage(arg));
  };

  const handleCreate = () => {
    dispatch(resetContractDetailState());
    push(RouterHref.AdminCreateContract, {
      organizationId: organization?.id || currentOrganizationId,
      organizationTitle: organization?.title,
    });
  };

  useEffect(() => {
    if (showFilterRow) {
      setShowFilterRow(false);
    }
  }, [getActiveId()]);

  return {
    methods: {
      handleSort,
      toggleFilterRow,
      setTableOpened,
      handleShowModalDelete,
      handleDelete,
      toggleDeleteModal,
      handleChangePage,
      handleChangePageSize,
      handleCreate,
      handleRowClick: onRowClickFunctions[tableType],
    },
    state: {
      showFilterRow,
      pageNum,
      pageSize,
      totalElements,
      sortContracts,
      canCreateContract,
      isFilterActive,
      contract,
      contracts,
      currentOrganizationId,
      tableOpened,
      searchDisable,
      showDeleteModal,
      currentContractId,
      isAccessToCreateContract,
      isAccessToDeleteContract,
      loadingContracts,
    },
  };
};
