import { v4 as uuidv4 } from 'uuid';

import { CustomFieldType, SelectOption, TableColumns } from 'components';
import { SELECT_ATTRIBUTES_LIST } from 'core/constants';
import { TicketStatus as TicketStatusType } from 'core/types';

import {
  CustomConditionType,
  DefaultConditionType,
  TriggerAttribute,
  TriggerFieldsType,
} from './types';

export const STATUS = 'Статус';
const ORGANIZATION = 'Организация';
const SYSTEM = 'Система';
const TICKET_TYPES = 'Тип тикета';
const TICKET_PRIORITIES = 'Приоритет';
const ENVIRONMENTS = 'Среда';
const CLIENTS = 'Клиент';

const DATE_IS_NOW = 'Дата в кастомном поле совпадает';

export const TABLE_HEADER_TRIGGERS: TableColumns = [
  {
    title: 'Название',
    sortField: 'title',
    fieldName: 'title',
  },
  {
    title: 'Активно',
    sortField: 'isActive',
    fieldName: 'isActive',
  },
  {
    title: ORGANIZATION,
    sortField: 'organization',
    fieldName: 'organization',
  },
  {
    title: SYSTEM,
    sortField: 'system',
    fieldName: 'system',
  },
  {
    title: STATUS,
    sortField: 'status',
    fieldName: 'status',
  },
  {
    title: TICKET_TYPES,
    fieldName: 'ticketTypes',
  },
  {
    title: TICKET_PRIORITIES,
    fieldName: 'ticketPriorities',
  },
  {
    title: ENVIRONMENTS,
    fieldName: 'environments',
  },
  {
    title: CLIENTS,
    fieldName: 'clients',
  },
  {
    title: 'Кастомные поля',
    fieldName: 'customFields',
  },
];

export const SELECT_ATTRIBUTES_LIST_TRIGGERS: SelectOption[] = [
  { title: TICKET_TYPES, value: 'ticketTypes' },
  { title: TICKET_PRIORITIES, value: 'ticketPriorities' },
  { title: ENVIRONMENTS, value: 'environments' },
  { title: CLIENTS, value: 'clients' },
];

export const SELECT_DATE_CONDITION_LIST: SelectOption[] = [
  {
    title: DATE_IS_NOW,
    value: CustomConditionType.DATE_IS_NOW,
  },
  {
    title: 'До даты в кастомном поле осталось',
    value: CustomConditionType.MINUTES_BEFORE_DATE,
  },
  {
    title: 'После даты прошло',
    value: CustomConditionType.MINUTES_AFTER_DATE,
  },
];

export const SELECT_CONDITION_LIST: SelectOption[] = [
  {
    title: 'Если с момента взятия в работу прошло',
    value: DefaultConditionType.IN_WORK_STATUS,
  },
  {
    title: 'Если находится в выбранном выше статусе',
    value: DefaultConditionType.IN_STATUS_FROM_ATTRIBUTE,
  },
];
export const CUSTOM_FIELD_TYPE_CONDITION_MAP: Record<
  CustomFieldType,
  SelectOption
> = {
  [CustomFieldType.FIELD_TEXT]: {
    title: 'Текст в кастомном поле совпадает',
    value: CustomConditionType.TEXT_IS_EQUAL,
  },
  [CustomFieldType.FIELD_DATE]: {
    title: DATE_IS_NOW,
    value: CustomConditionType.DATE_IS_NOW,
  },
  [CustomFieldType.FIELD_FLAG]: {
    title: 'Флаг в кастомном поле',
    value: CustomConditionType.FLAG_IS_EQUAL,
  },
  [CustomFieldType.FIELD_LIST]: {
    title: 'Список в кастомном поле',
    value: CustomConditionType.LIST_IS_EQUAL,
  },
};

export const CUSTOM_CONDITION_MAP: Record<CustomConditionType, string> = {
  [CustomConditionType.TEXT_IS_EQUAL]: 'Текст в кастомном поле совпадает',
  [CustomConditionType.DATE_IS_NOW]: DATE_IS_NOW,
  [CustomConditionType.FLAG_IS_EQUAL]: 'Флаг в кастомном поле',
  [CustomConditionType.MINUTES_BEFORE_DATE]:
    'До даты в кастомном поле осталось',
  [CustomConditionType.MINUTES_AFTER_DATE]: 'После даты прошло',
  [CustomConditionType.LIST_IS_EQUAL]: 'Список в кастомном поле',
};

export const CONDITION_MAP = {
  [DefaultConditionType.IN_WORK_STATUS]:
    'Если с момента взятия в работу прошло',
  [DefaultConditionType.IN_STATUS_FROM_ATTRIBUTE]:
    'Если находится в выбранном выше статусе',
};

export const SELECT_ATTRIBUTES_MAP_TRIGGERS = {
  [TriggerFieldsType.organizations]: ORGANIZATION,
  [TriggerFieldsType.systems]: SYSTEM,
  [TriggerFieldsType.status]: STATUS,
  [TriggerFieldsType.ticketTypes]: TICKET_TYPES,
  [TriggerFieldsType.ticketPriorities]: TICKET_PRIORITIES,
  [TriggerFieldsType.environments]: ENVIRONMENTS,
  [TriggerFieldsType.clients]: CLIENTS,
};

export const INITIAL_ATTRIBUTES_VALUES = {
  organizations: [],
  systems: [],
  status: [],
  environments: [],
  ticketTypes: [],
  ticketPriorities: [],
  clients: [],
};

export const DEFAULT_VALUES_TRIGGER_FORM = {
  title: '',
  isEnable: false,
  customConditionRequests: [],
  ...INITIAL_ATTRIBUTES_VALUES,
  targetStatus: null,
};

export const INITIAL_TRIGGERS_FILTER = {
  title: '',
  isActive: null,
  ...INITIAL_ATTRIBUTES_VALUES,
};

export const INITIAL_ATTRIBUTES: TriggerAttribute[] = [
  {
    id: uuidv4(),
    isActive: true,
    required: true,
    attribute: SELECT_ATTRIBUTES_LIST[0],
    value: [],
  },
  {
    id: uuidv4(),
    isActive: true,
    required: true,
    attribute: SELECT_ATTRIBUTES_LIST[1],
    value: [],
  },
  {
    id: uuidv4(),
    isActive: true,
    required: true,
    attribute: SELECT_ATTRIBUTES_LIST[2],
    value: [],
  },
  {
    id: uuidv4(),
    isActive: true,
    required: true,
    attribute: { title: STATUS, value: 'status' },
    value: [],
  },
];

export const TICKET_STATUS = [
  TicketStatusType.NEW,
  TicketStatusType.PENDING_CLOSURE,
  TicketStatusType.CLOSE,
];
