import { debounce } from 'lodash';
import { FC, memo, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { Input, Select, Size, TableBodyCell, TableFilter } from 'components';
import { DEFAULT_DEBOUNCE_DELAY } from 'constants/meta';
import { TYPE_OPTIONS_FOR_FILTER } from 'core/constants';
import { OrganizationType } from 'core/types';
import { OrganizationsFilter as OrganizationsFilterData } from 'core/types/organization';
import { checkObjectIdentity } from 'utils';

import { INITIAL_FILTER_VALUES } from '../../constants';
import {
  fetchOrganizationsRequest,
  setCurrentOrganizationsPage,
  setOrganizationsFilter,
} from '../../ducks/actions';

import styles from './OrganizationsFilter.module.scss';

export const OrganizationsFilter: FC = memo(() => {
  const dispatch = useDispatch();

  const { register, handleSubmit, reset, watch, control } =
    useForm<OrganizationsFilterData>({
      mode: 'onChange',
      defaultValues: INITIAL_FILTER_VALUES,
    });

  const resetFilter = () => {
    dispatch(setOrganizationsFilter({}));
    dispatch(fetchOrganizationsRequest());
    reset();
  };
  const filterValues = watch();

  const formSubmitHandler = handleSubmit((data) => {
    const { title, description, organizationTypes, domain, address } = data;
    const preparedData = {
      title: title || undefined,
      description: description || undefined,
      organizationTypes: organizationTypes || undefined,
      domain: domain || undefined,
      address: address || undefined,
    };
    dispatch(setOrganizationsFilter(preparedData));
    dispatch(setCurrentOrganizationsPage(0));
    dispatch(fetchOrganizationsRequest());
  });

  const formSubmitHandlerDebounced = useMemo(
    () => debounce(formSubmitHandler, DEFAULT_DEBOUNCE_DELAY),
    []
  );

  const titleToInput = register('title', {
    onChange: formSubmitHandlerDebounced,
  });

  const domainToInput = register('domain', {
    onChange: formSubmitHandlerDebounced,
  });
  const descriptionToInput = register('description', {
    onChange: formSubmitHandlerDebounced,
  });
  const addressToInput = register('address', {
    onChange: formSubmitHandlerDebounced,
  });

  const tableBodyWrapper = (elements: JSX.Element[]) =>
    elements.map((item) => (
      <TableBodyCell
        className={styles.organizationsFilter__cell}
        key={item.key}
      >
        {item}
      </TableBodyCell>
    ));

  const filterElements = [
    <Input size={Size.xs} type="title" key="title" {...titleToInput} />,
    <Controller
      control={control}
      name="organizationTypes"
      key="organizationTypes"
      render={({ field }) => {
        return (
          <Select<OrganizationType>
            size={Size.xs}
            mobileModalTitle="тип"
            onChange={(value) => {
              field.onChange(value);
              formSubmitHandler();
            }}
            options={TYPE_OPTIONS_FOR_FILTER}
            value={Array.isArray(field.value) ? null : field.value}
            isTooltip={false}
          />
        );
      }}
    />,
    <Input size={Size.xs} type="domain" key="domain" {...domainToInput} />,
    <Input
      size={Size.xs}
      type="description"
      key="description"
      {...descriptionToInput}
    />,
    <Input size={Size.xs} type="address" key="address" {...addressToInput} />,
  ];
  const filter = <>{tableBodyWrapper(filterElements)}</>;

  return (
    <TableFilter
      filterComponent={filter}
      onReset={resetFilter}
      disableReset={checkObjectIdentity(filterValues, {})}
    />
  );
});
