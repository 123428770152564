import { Reducer } from 'redux';

import {
  DEFAULT_PAGINATION_PARAMS,
  PAGE_SIZE_SEARCH_SELECT,
} from 'constants/meta';
import { Environment } from 'core/types';
import { SLAType } from 'core/types/sla';
import { System, SystemPriority, SystemsFilter } from 'core/types/system';
import { ReducerMap } from 'store/types';
import { Pagination } from 'types/models/meta';

import {
  FetchEnvironmentsSuccessAction,
  FetchFiltersSystemsRequestAction,
  FetchSLAByContractIdBySystemIdSuccessSystemsAction,
  FetchSystemPrioritiesSuccessAction,
  FetchSystemsOrganizationSuccessAction,
  FetchSystemsSuccessJoinAction,
  FetchSystemsSuccessUpdateAction,
  SetCurrentOrganizationSystemIdAction,
  SetCurrentSystemIdAction,
  SetCurrentSystemsPageAction,
  SetCurrentSystemSuccessAction,
  SetIsErrorNoDataAction,
  SetSelectedSystemSuccessAction,
  SetSizePageAction,
  SetSortSystemsAction,
  SetSystemsByContractAction,
  SetSystemsFilterAction,
  Systems,
  SystemsActionsTypes,
  SystemTicketPayload,
} from './types';

export interface SystemsReducerState {
  systems?: System[];
  systemsOrganization?: System[];
  systemsTickets?: System[];
  loading?: boolean;
  pagination?: Pagination;
  paginationTicketsFilter?: Pagination;
  sort?: string;
  sortSystemTickets?: string;
  systemId?: string;
  organizationSystemId?: string;
  systemByContract?: System[];
  systemsFilter?: SystemsFilter;
  systemsTicketsFilter?: SystemTicketPayload;
  mySystems?: System[];
  selectedSystem?: System;
  systemPriorities?: SystemPriority[];
  isErrorNoData?: boolean;
  system?: System;
  sla?: SLAType;
  environments?: Environment[];
}

const initialState: SystemsReducerState = {
  systems: [],
  loading: false,
  pagination: DEFAULT_PAGINATION_PARAMS,
  paginationTicketsFilter: {
    pageSize: PAGE_SIZE_SEARCH_SELECT,
  },
  sort: 'date_create_DESC',
  systemByContract: [],
  systemsFilter: {},
  mySystems: [],
  systemsOrganization: [],
  systemPriorities: [],
  isErrorNoData: false,
};

const systemsReducerMap: ReducerMap<SystemsReducerState, SystemsActionsTypes> =
  {
    [Systems.SYSTEMS_LOADING_SHOW]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [Systems.SYSTEMS_LOADING_HIDE]: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    [Systems.FETCH_FILTERS_SYSTEMS_REQUEST]: (state, action) => {
      const { payload } = action as FetchFiltersSystemsRequestAction;
      return {
        ...state,
        systemsTicketsFilter: payload,
      };
    },
    [Systems.FETCH_SYSTEMS_SUCCESS_UPDATE]: (state, action) => {
      const { payload } = action as FetchSystemsSuccessUpdateAction;
      const { content, page, totalElements } = payload;
      return {
        ...state,
        systems: content,
        pagination: {
          ...state?.pagination,
          pageNum: page,
          totalElements,
        },
      };
    },
    [Systems.FETCH_SYSTEMS_SUCCESS_JOIN]: (state, action) => {
      const { payload } = action as FetchSystemsSuccessJoinAction;
      const { content, page, totalElements } = payload;
      return {
        ...state,
        systems: [...(state?.systems || []), ...content],
        pagination: {
          ...state?.pagination,
          pageNum: page,
          totalElements,
        },
      };
    },
    [Systems.FETCH_FILTERS_SYSTEMS_SUCCESS_UPDATE]: (state, action) => {
      const { payload } = action as FetchSystemsSuccessUpdateAction;
      const { content, page, totalElements } = payload;
      return {
        ...state,
        systemsTickets: content,
        paginationTicketsFilter: {
          ...state?.paginationTicketsFilter,
          pageNum: page,
          totalElements,
        },
      };
    },
    [Systems.FETCH_FILTERS_SYSTEMS_SUCCESS_JOIN]: (state, action) => {
      const { payload } = action as FetchSystemsSuccessJoinAction;
      const { content, page, totalElements } = payload;
      return {
        ...state,
        systemsTickets: [...(state?.systemsTickets || []), ...content],
        paginationTicketsFilter: {
          ...state?.paginationTicketsFilter,
          pageNum: page,
          totalElements,
        },
      };
    },
    [Systems.FETCH_SYSTEMS_ORGANIZATION_SUCCESS]: (state, action) => {
      const { payload } = action as FetchSystemsOrganizationSuccessAction;
      return {
        ...state,
        systemsOrganization: payload,
      };
    },
    [Systems.SET_SYSTEMS_BY_CONTRACT]: (state, action) => {
      const { payload } = action as SetSystemsByContractAction;
      const system = state?.systemByContract as System[];
      return {
        ...state,
        systemByContract: system && [...system, payload],
      };
    },
    [Systems.SET_CURRENT_SYSTEMS_PAGE]: (state, action) => {
      const { payload } = action as SetCurrentSystemsPageAction;
      return {
        ...state,
        pagination: {
          ...state?.pagination,
          pageNum: payload,
        },
      };
    },
    [Systems.SET_SIZE_PAGE_SYSTEMS]: (state, action) => {
      const { payload } = action as SetSizePageAction;
      return {
        ...state,
        pagination: {
          ...state?.pagination,
          pageSize: payload,
        },
      };
    },
    [Systems.SET_SORT_SYSTEMS]: (state, action) => {
      const { payload } = action as SetSortSystemsAction;
      return {
        ...state,
        sort: payload,
      };
    },
    [Systems.SET_CURRENT_SYSTEM_SUCCESS]: (state, action) => {
      const { payload } = action as SetCurrentSystemSuccessAction;
      return {
        ...state,
        system: payload,
      };
    },
    [Systems.RESET_SYSTEMS_STATE]: () => {
      return {
        ...initialState,
      };
    },
    [Systems.RESET_SYSTEM_ID_STATE]: (state) => {
      return {
        ...state,
        systemId: initialState.systemId,
      };
    },
    [Systems.SET_CURRENT_SYSTEM_ID]: (state, action) => {
      const { payload } = action as SetCurrentSystemIdAction;
      return {
        ...state,
        systemId: payload,
      };
    },
    [Systems.SET_CURRENT_ORGANIZATION_SYSTEM_ID]: (state, action) => {
      const { payload } = action as SetCurrentOrganizationSystemIdAction;
      return {
        ...state,
        organizationSystemId: payload,
      };
    },
    [Systems.SET_SYSTEMS_FILTER]: (state, action) => {
      const { payload } = action as SetSystemsFilterAction;
      return {
        ...state,
        systemsFilter: payload,
      };
    },
    [Systems.SET_SELECTED_SYSTEM_SUCCESS]: (state, action) => {
      const { payload } = action as SetSelectedSystemSuccessAction;
      return {
        ...state,
        selectedSystem: payload,
      };
    },
    [Systems.FETCH_SYSTEM_PRIORITIES_SUCCESS]: (state, action) => {
      const { payload } = action as FetchSystemPrioritiesSuccessAction;
      return {
        ...state,
        systemPriorities: payload,
      };
    },
    [Systems.RESET_SYSTEM_PRIORITIES]: (state) => {
      return {
        ...state,
        systemPriorities: initialState.systemPriorities,
      };
    },
    [Systems.SET_IS_ERROR_NO_DATA]: (state, action) => {
      const { payload } = action as SetIsErrorNoDataAction;
      return {
        ...state,
        isErrorNoData: payload,
      };
    },
    [Systems.RESET_CURRENT_SYSTEM]: (state) => {
      return {
        ...state,
        system: initialState.system,
        systemId: initialState.systemId,
        pagination: initialState.pagination,
      };
    },
    [Systems.FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_SUCCESS]: (
      state,
      action
    ) => {
      const { payload } =
        action as FetchSLAByContractIdBySystemIdSuccessSystemsAction;
      return {
        ...state,
        sla: payload,
      };
    },
    [Systems.RESET_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID]: (state) => {
      return {
        ...state,
        sla: undefined,
      };
    },
    [Systems.FETCH_ENVIRONMENTS_SUCCESS]: (state, action) => {
      const { payload } = action as FetchEnvironmentsSuccessAction;
      return {
        ...state,
        environments: payload.content,
      };
    },
  };

export const systems: Reducer<SystemsReducerState, SystemsActionsTypes> = (
  state = initialState,
  action
) => {
  const reducer = systemsReducerMap[action.type];
  if (!reducer) {
    return state;
  }
  return reducer(state, action);
};
