import cn from 'clsx';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';

import { CheckMarkIcon, CloseIcon, PenIcon } from 'assets/icons';
import {
  Input,
  Popover,
  PopoverButton,
  PopoverButtonProps,
  PopoverContainer,
  PopoverLine,
  Size,
} from 'components';

import styles from './KBChangeFieldDrop.module.scss';

interface KBChangeFieldDropProps {
  onChange?: (title: string) => void;
  initialValue?: string;
  customDropButtonProps?: PopoverButtonProps;
}

export const KBChangeFieldDrop: FC<KBChangeFieldDropProps> = ({
  onChange,
  initialValue = '',
  customDropButtonProps = {},
}) => {
  const { children, ...other } = customDropButtonProps;
  const [isCreatePopoverOpen, setIsCreatePopoverOpen] =
    useState<boolean>(false);

  const toggleCreatePopover = () => {
    setIsCreatePopoverOpen((prevState) => !prevState);
  };

  const {
    handleSubmit,
    register,
    reset,
    watch,
    formState: { isValid },
  } = useForm<{ title?: string }>({
    mode: 'onChange',
    defaultValues: {
      title: initialValue,
    },
  });

  const disabledCreateButton = !isValid || watch('title') === initialValue;

  const titleInputOptions = register('title', {
    required: true,
    maxLength: {
      value: 100,
      message: 'Название не может быть длиннее 100 символов.',
    },
  });

  const formSubmitHandler = handleSubmit((data) => {
    const { title } = data;
    if (onChange && title) {
      onChange(title);
    }
    toggleCreatePopover();
  });

  const handleCancelCreate = () => {
    reset();
    toggleCreatePopover();
  };

  const createCategoryForm = (
    <PopoverContainer>
      <form className={styles.KBChangeFieldDrop}>
        <div className={styles.KBChangeFieldDrop__inner}>
          <Input
            {...titleInputOptions}
            size={Size.xs}
            className={styles.KBChangeFieldDrop__input}
            label="Название"
            name="title"
          />
        </div>
        <PopoverLine />
        <div
          className={cn(
            styles.KBChangeFieldDrop__inner,
            styles.KBChangeFieldDrop__buttons
          )}
        >
          <PopoverButton
            icon={<CheckMarkIcon className={styles.KBChangeFieldDrop__icon} />}
            onClick={formSubmitHandler}
            disabled={disabledCreateButton}
          >
            {initialValue ? 'Сохранить' : 'Создать'}
          </PopoverButton>
          <PopoverButton
            icon={<CloseIcon className={styles.KBChangeFieldDrop__icon} />}
            onClick={handleCancelCreate}
          >
            Отмена
          </PopoverButton>
        </div>
      </form>
    </PopoverContainer>
  );

  return (
    <Popover
      content={createCategoryForm}
      isOpen={isCreatePopoverOpen}
      togglePopover={toggleCreatePopover}
      positions={['left', 'top', 'right', 'bottom']}
      align="center"
      elevated
      popoverClassName={styles.KBChangeFieldDrop__popover}
    >
      <PopoverButton
        icon={<PenIcon className={styles.KBChangeFieldDrop__icon} />}
        onClick={toggleCreatePopover}
        {...other}
      >
        {children || 'Изменить название'}
      </PopoverButton>
    </Popover>
  );
};
