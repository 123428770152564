import { CustomFieldType, SelectOption, ValueType } from 'components';
import { Attribute, Attributes, OrganizationType } from 'core/types';
import { KeyValueOption } from 'types/models/meta';

export enum CustomConditionType {
  TEXT_IS_EQUAL = 'TEXT_IS_EQUAL',
  FLAG_IS_EQUAL = 'FLAG_IS_EQUAL',
  MINUTES_BEFORE_DATE = 'MINUTES_BEFORE_DATE',
  DATE_IS_NOW = 'DATE_IS_NOW',
  MINUTES_AFTER_DATE = 'MINUTES_AFTER_DATE',
  LIST_IS_EQUAL = 'LIST_IS_EQUAL',
}

export enum DefaultConditionType {
  IN_WORK_STATUS = 'IN_WORK_STATUS',
  IN_STATUS_FROM_ATTRIBUTE = 'IN_STATUS_FROM_ATTRIBUTE',
}

export enum TriggerFieldsType {
  organizations = 'organization',
  systems = 'system',
  status = 'status',
  ticketTypes = 'ticketTypes',
  ticketPriorities = 'ticketPriorities',
  environments = 'environments',
  clients = 'clients',
}

export type TriggerAttribute = {
  required?: boolean;
} & Attribute;

type ConditionCustomField = {
  title: string;
  value: string;
  values?: string[];
};

export type CustomCondition = {
  id: string;
  isActive: boolean;
  customField: ConditionCustomField;
  type: CustomFieldType | null;
  condition: ValueType<string>;
  value: string | number | boolean | null;
};

export type TriggerCondition = {
  id: string;
  isActive: boolean;
  condition: ValueType<string>;
  value: string | number;
};

type TriggerConditionCustomField = KeyValueOption & {
  values?: string[];
};

export type CustomConditions = {
  conditionType: CustomConditionType;
  customField: TriggerConditionCustomField;
  customFieldType: CustomFieldType;
  flagValue: boolean | null;
  minutes: number | null;
  textValue: string | null;
  listValue: string | null;
};

export type CreateCustomConditions = {
  customFieldId: string;
} & Omit<CustomConditions, 'customField' | 'customFieldType'>;

export type DefaultConditions = {
  conditionType?: DefaultConditionType;
  minutes: number;
};

export type TriggerAttributes = {
  status: KeyValueOption[];
} & Attributes;

export type Trigger = {
  id: string;
  title: string;
  isEnable: boolean;

  organization: KeyValueOption;
  system: KeyValueOption;
  status: KeyValueOption;

  customConditions: CustomConditions[];
  defaultConditions: DefaultConditions[];

  targetStatus: KeyValueOption;
} & Omit<TriggerAttributes, 'organizations' | 'systems' | 'status'>;

export type CreateTrigger = {
  organization?: string;
  system?: string;
  status?: string;
  environments?: string[];
  ticketTypes?: string[];
  ticketPriorities?: string[];
  clients?: string[];
  targetStatus: string;
  customConditionRequests: CreateCustomConditions[];
} & Pick<Trigger, 'title' | 'isEnable' | 'defaultConditions'>;

export type FiltersFields = {
  customField?: KeyValueOption[];
} & TriggerAttributes;

export type TriggersAttributeToRequest = {
  organizations?: string[];
  systems?: string[];
} & Partial<
  Pick<
    CreateTrigger,
    'environments' | 'ticketTypes' | 'ticketPriorities' | 'clients'
  >
>;

export type TriggersFilter = {
  isActive?: boolean | null;
  statusName?: string;
} & Partial<Pick<Trigger, 'title'>>;

export interface TriggersFilterData extends Partial<Pick<Trigger, 'title'>> {
  isActive: ValueType<boolean>;
  organizations: SelectOption[];
  systems: SelectOption[];
  ticketTypes: SelectOption[];
  ticketPriorities: SelectOption[];
  environments: SelectOption[];
  status: SelectOption[];
  clients: SelectOption[];
}

export type TriggersFilterToRequest = TriggersAttributeToRequest &
  Pick<TriggersFilter, 'title' | 'isActive'>;

export type CreateTriggerData = {
  targetStatus: ValueType<string>;
} & Pick<Trigger, 'isEnable' | 'title'>;

export interface EditTriggerData extends CreateTriggerData {
  executor: SelectOption[];
}

export type InitialTriggersFields = Partial<Pick<Trigger, 'title'>> &
  TriggersAttributeToRequest;

export type AttributesForFilter = Omit<
  TriggersFilterData,
  'title' | 'isActive'
>;

export type CustomField = {
  id: string;
  title: string;
  customFieldType: CustomFieldType;
  organization: {
    id: string;
    title: string;
  };
  system: {
    id: string;
    title: string;
    description: string;
  };
  created: null;
  accessType: OrganizationType;
  values: string[];
};

export type TriggerAttributeIds = Record<string, string[]>;
