import { FC } from 'react';
import { Control, Controller } from 'react-hook-form';

import { ArrowHorizontalIcon } from 'assets/icons';
import { Accordion, Select, SelectOption, Size, ValueType } from 'components';

import { JiraIntegrationEditData } from '../../types';

import styles from './JiraIntegrationsTicketTypes.module.scss';

interface JiraIntegrationsTicketTypesProps {
  ticketTypeId: ValueType<string> | undefined;
  jiraTicketTypesOptions: SelectOption<number>[];
  control: Control<JiraIntegrationEditData>;
  isEditMode: boolean;
  resetState: () => void;
}

export const JiraIntegrationsTicketTypes: FC<
  JiraIntegrationsTicketTypesProps
> = ({
  ticketTypeId,
  jiraTicketTypesOptions,
  control,
  isEditMode,
  resetState,
}) => {
  return (
    <Accordion title="Маппинг типов тикета" defaultHidden>
      <div className={styles.jiraIntegrationsTicketTypes}>
        <div className={styles.jiraIntegrationsTicketTypes__content}>
          <Select
            size={Size.xs}
            value={ticketTypeId}
            options={[]}
            disabled
            className={styles.jiraIntegrationsTicketTypes__selectType}
          />
          <div className={styles.jiraIntegrationsTicketTypes__selectWrapper}>
            <ArrowHorizontalIcon
              className={styles.jiraIntegrationsTicketTypes__icon}
            />
            <Controller
              control={control}
              rules={{ required: true }}
              name="jiraTicketType"
              render={({ field }) => {
                return (
                  <Select<number>
                    size={Size.xs}
                    mobileModalTitle="тип тикета"
                    options={jiraTicketTypesOptions}
                    disabled={!isEditMode}
                    onChange={(value) => {
                      field.onChange(value);
                      resetState();
                    }}
                    value={field.value}
                    isTooltip={false}
                    className={styles.jiraIntegrationsTicketTypes__select}
                  />
                );
              }}
            />
          </div>
        </div>
      </div>
    </Accordion>
  );
};
