import { getUrl } from 'utils';

export const endpoints = {
  getArticleCreate: () => getUrl(`v1/article`),
  getArticleEdit: () => getUrl(`v1/article`),
  getArticleDelete: (id: string) => getUrl(`v1/article/${id}`),
  getCategoryCreate: () => getUrl(`v1/category`),
  changeCategory: () => getUrl('v1/category'),
  deleteCategory: (id?: string) => getUrl(`v1/category/${id}`),
  getCategory: (id: string) => getUrl(`v1/category/${id}`),
  getArticle: (id: string) => getUrl(`v1/article/${id}`),
};
