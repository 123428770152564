import { getKeyValueFromIdTitle } from 'components';
import { Organization } from 'core/types/organization';
import { getMoreTag } from 'utils';

type Args = {
  canCreateArticle: boolean;
  organizations?: Organization[];
  userOrganization?: Organization;
};

export const getOrgByUserOrgId = ({
  canCreateArticle,
  organizations,
  userOrganization,
}: Args) => {
  if (canCreateArticle) {
    return getMoreTag(getKeyValueFromIdTitle(organizations));
  }

  if (!userOrganization?.id) {
    return '-';
  }

  return (
    organizations?.find(
      (organization) => organization?.id === userOrganization?.id
    )?.title || '-'
  );
};
