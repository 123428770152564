import { FC } from 'react';
import { useSelector } from 'react-redux';

import { UsersGroupIcon } from 'assets/icons';
import { InfoBlock } from 'components';
import { Info } from 'core/modals';
import { OrganizationType } from 'core/types';
import { getIsClient } from 'features/Auth';
import { getTicket } from 'features/Ticket/ducks/ticket/selectors';

import styles from './WorkGroupModal.module.scss';

interface WorkGroupModalProps {
  isModal: boolean;
  toggleModal: () => void;
}

export const WorkGroupModal: FC<WorkGroupModalProps> = ({
  isModal,
  toggleModal,
}) => {
  const ticket = useSelector(getTicket);
  const isClient = useSelector(getIsClient);

  const groupsWork =
    ticket?.workGroups.filter((item) =>
      !isClient
        ? item.organizationType === OrganizationType.SERVICE
        : item.organizationType === OrganizationType.CUSTOMER
    ) || [];

  return (
    <Info
      isModal={isModal}
      toggleModal={toggleModal}
      title="Группа исполнителей"
      classNameTitle={styles.workGroupModal__modalTitle}
    >
      <div className={styles.workGroupModal__formInfo}>
        <div className={styles.workGroupModal__listInfo}>
          {groupsWork.map((item) => (
            <InfoBlock
              key={item.id}
              mainTitle={item.title}
              icon={<UsersGroupIcon />}
              className={styles.workGroupModal__listInfo}
              classNameIconWrapper={styles.workGroupModal__infoIcon}
              classNameTitle={styles.workGroupModal__titleBlock}
            />
          ))}
        </div>
      </div>
    </Info>
  );
};
