import cn from 'clsx';
import { ElementType, forwardRef, HTMLAttributes, useMemo } from 'react';

import styles from './Typography.module.scss';

export enum TypographyVariants {
  d1 = 'd1',
  d2 = 'd2',
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
  b1 = 'b1',
  b2 = 'b2',
  b3 = 'b3',
  s2 = 's2',
  o = 'o',
  other = 'other',
}

enum VariantMapping {
  d1 = 'h1',
  d2 = 'h4',
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
  b1 = 'p',
  b2 = 'p',
  b3 = 'p',
  s2 = 'h6',
  o = 'span',
  other = 'span',
}

interface TypographyProps extends HTMLAttributes<HTMLElement> {
  component?: ElementType;
  variant?: TypographyVariants;
}

export const Typography = forwardRef<HTMLElement, TypographyProps>(
  (
    {
      component,
      variant = TypographyVariants.b3,
      className,
      children,
      ...other
    },
    ref
  ) => {
    const Component = component || VariantMapping[variant];

    const classes = useMemo(
      () => cn(styles.typography, styles[`typography_${variant}`], className),
      [variant, className]
    );

    return (
      <Component className={classes} {...other} ref={ref}>
        {children}
      </Component>
    );
  }
);
