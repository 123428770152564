import { Priority, TicketTypeSettings } from 'core/types';
import { SLARowType, SLASettings, SLATableDataType } from 'core/types/sla';

import { getActiveTicketTypes } from '../../../utils';
import { CheckedPriorityType } from '../../SlaTable/types';

const checkValidSettingsValue = (value?: string | number) => {
  return Number.isNaN(Number(value))
    ? !(value?.toString().includes('_') || value?.toString() === '00:00')
    : Boolean(value);
};

const checkValidSettings = (data: SLASettings) =>
  checkValidSettingsValue(data?.decisionTime) &&
  checkValidSettingsValue(data?.reactionTime);

const checkValidRow = (data: SLARowType) => {
  const ticketTypes = Object.keys(data);
  return ticketTypes.reduce((isValidRow, type) => {
    return isValidRow && checkValidSettings(data[type]);
  }, true);
};

type Args = {
  data: SLATableDataType;
  checkedRows: CheckedPriorityType;
  ticketTypes?: TicketTypeSettings[];
};

export const checkValidTableSLA = ({
  data,
  checkedRows,
  ticketTypes,
}: Args) => {
  const activeTicketTypes = getActiveTicketTypes(ticketTypes);
  const priorities = Object.keys(data);
  const checkedKeys = Object.entries(checkedRows)
    .filter(([, isChecked]) => isChecked)
    ?.map(([priority]) => priority);
  const checkedAndFilled = checkedKeys.every((key) => priorities.includes(key));

  if (!priorities.length || !checkedKeys.length || !checkedAndFilled) {
    return false;
  }

  return checkedKeys.every((key: string) => {
    const checkedPriority = data[key as Priority];
    const existTicketType = activeTicketTypes?.every((item) => {
      const activeTicketType = checkedPriority[item];
      return activeTicketType?.decisionTime && activeTicketType?.reactionTime;
    });

    if (!existTicketType) {
      return false;
    }

    if (checkedPriority && priorities.includes(key)) {
      return checkValidRow(checkedPriority);
    }

    return true;
  });
};
