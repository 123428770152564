import { createSelector } from 'reselect';

import { SelectOption } from 'components';
import { RootState } from 'store/rootReducer';

import { OrganizationItem } from '../../types';

export const getKpiData = (state: RootState) => state.desktopKpi.kpiData;

const getOrganizations = (state: RootState) =>
  state.desktopKpi.kpiData?.filters.organizations;
const getWorkGroups = (state: RootState) =>
  state.desktopKpi.kpiData?.filters.workgroups;
const getSystems = (state: RootState) =>
  state.desktopKpi.kpiData?.filters.systems;
const getTicketTypes = (state: RootState) =>
  state.desktopKpi.kpiData?.filters.types;

export const getOrganizationsOptions = createSelector<
  RootState,
  OrganizationItem[] | undefined,
  SelectOption[]
>([getOrganizations], (organizations): SelectOption[] => {
  if (organizations) {
    return organizations.map(
      (organization): SelectOption => ({
        title: organization.value,
        value: organization.key || '',
      })
    );
  }
  return [];
});
export const getWorkGroupsOptions = createSelector<
  RootState,
  OrganizationItem[] | undefined,
  SelectOption[]
>([getWorkGroups], (workGroups): SelectOption[] => {
  if (Array.isArray(workGroups)) {
    return workGroups.map(
      (workGroup): SelectOption => ({
        title: workGroup.value,
        value: workGroup.key,
      })
    );
  }
  return [];
});
export const getSystemsOptions = createSelector<
  RootState,
  OrganizationItem[] | undefined,
  SelectOption[]
>([getSystems], (systems): SelectOption[] => {
  if (systems) {
    return systems.map(
      (system): SelectOption => ({
        title: system.value,
        value: system.key,
      })
    );
  }
  return [];
});

export const getTicketTypesOptions = createSelector<
  RootState,
  OrganizationItem[] | undefined,
  SelectOption[]
>([getTicketTypes], (types): SelectOption[] => {
  if (types) {
    return types.map(
      (type): SelectOption => ({
        title: type.value,
        value: type.key,
      })
    );
  }
  return [];
});
