type FuncValidator = (file: File) => boolean;

export const validatorFile =
  (validator: FuncValidator) => (file?: File | File[]) => {
    if (!file) {
      return false;
    }

    if (Array.isArray(file)) {
      return file.every(validator);
    }

    return validator(file);
  };
