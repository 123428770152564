import cn from 'clsx';
import { ChangeEvent, FC, MouseEvent, useState } from 'react';
import { UseFormGetValues, UseFormRegister } from 'react-hook-form';

import {
  Delete2Icon,
  DocumentIcon,
  InfoQuestionMarkIcon,
  StarIcon,
} from 'assets/icons';
import {
  Checkbox,
  ConditionBlock,
  getValueFromFieldDate,
  IconButton,
  Input,
  InputDatePicker,
  Tooltip,
  Typography,
  TypographyVariants,
} from 'components';
import { CustomFieldTypeStorage } from 'core/constants/storage';
import { ApproveOrCancel } from 'core/modals';
import { StorageCustomField } from 'core/types/storage';

import { AddFilesForm, StorageFile } from '../../types';

import styles from './StorageDownloadedFile.module.scss';

type CustomFieldProps = {
  field: StorageCustomField;
} & Pick<
  StorageDownloadedFileProps,
  'addTextToCustomFields' | 'addFlagToCustomFields' | 'addDateToCustomFields'
>;

const getCustomField = ({
  field,
  addTextToCustomFields,
  addFlagToCustomFields,
  addDateToCustomFields,
}: CustomFieldProps) => {
  const { id, title, description, type, text, flag, date } = field;

  const props = {
    key: id,
    label: title,
  };

  const tooltip = <Tooltip id={description}>{description}</Tooltip>;
  const icon = (
    <InfoQuestionMarkIcon
      data-tip
      data-for={description}
      className={styles.storageDownloadedFile__customFieldIcon}
    />
  );

  const customFieldMap: Record<CustomFieldTypeStorage, JSX.Element> = {
    [CustomFieldTypeStorage.FIELD_TEXT]: (
      <>
        <Input
          {...props}
          rightIcon={icon}
          value={text || undefined}
          onChange={addTextToCustomFields(id)}
        />
        {tooltip}
      </>
    ),
    [CustomFieldTypeStorage.FIELD_FLAG]: (
      <div className={styles.storageDownloadedFile__flag}>
        <Checkbox
          {...props}
          checked={!!flag}
          onChange={addFlagToCustomFields(id)}
        />
        {icon}
        {tooltip}
      </div>
    ),
    [CustomFieldTypeStorage.FIELD_DATE]: (
      <>
        <InputDatePicker
          {...props}
          label={title}
          type="datePicker"
          placeholder={title}
          value={getValueFromFieldDate(date)}
          onChange={addDateToCustomFields(id)}
          icon={icon}
        />
        {tooltip}
      </>
    ),
  };

  return customFieldMap[type];
};

interface StorageDownloadedFileProps extends StorageFile {
  currentFileId: string;
  mainFileId?: string;
  changeMainFile: (id: string) => void;
  changeCurrentFile: (id: string) => void;
  removeField: (id: string) => void;
  register: UseFormRegister<AddFilesForm>;
  getValues: UseFormGetValues<AddFilesForm>;
  index: number;
  customFields?: StorageCustomField[];
  addTextToCustomFields: (
    id?: string
  ) => (event: ChangeEvent<HTMLInputElement>) => void;
  addFlagToCustomFields: (
    id?: string
  ) => (event: ChangeEvent<HTMLInputElement>) => void;
  addDateToCustomFields: (id?: string) => (value: string) => void;
}

export const StorageDownloadedFile: FC<StorageDownloadedFileProps> = ({
  id,
  index,
  mainFileId,
  currentFileId,
  size,
  changeMainFile,
  changeCurrentFile,
  register,
  getValues,
  removeField,
  fileName,
  customFields,
  addTextToCustomFields,
  addFlagToCustomFields,
  addDateToCustomFields,
}) => {
  const mainFile = id === mainFileId;
  const currentFile = id === currentFileId;

  const [isDeleteModal, setIsOpenDeleteModal] = useState(false);

  const handleClickStarIcon = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();

    changeMainFile(id ?? '');

    if (mainFile) {
      changeMainFile('');
    }
  };

  const handleClickFile = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    changeCurrentFile(id ?? '');

    if (currentFile) {
      changeCurrentFile('');
    }
  };

  const toggleDeleteModal = () => {
    setIsOpenDeleteModal((prevState) => !prevState);
  };

  const deleteFile = () => {
    if (id) {
      removeField(id);
    }
    if (mainFile) {
      changeMainFile('');
    }
  };

  const handleClickDelete = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();

    if (mainFile) {
      toggleDeleteModal();
      return;
    }
    deleteFile();
  };

  const onApproveDeleteModal = () => {
    deleteFile();
    toggleDeleteModal();
  };

  const currentInputs = currentFile && !!mainFileId && (
    <>
      {!mainFile ? (
        <Input
          {...register(`files.${index}.fileName`)}
          label="Название"
          required
          maxLength={100}
        />
      ) : (
        <div className={styles.storageDownloadedFile__inputsContainer}>
          {customFields &&
            customFields.map((field) =>
              getCustomField({
                field,
                addTextToCustomFields,
                addFlagToCustomFields,
                addDateToCustomFields,
              })
            )}
          <Input
            {...register(`files.${index}.description`)}
            label="Описание"
            required={false}
            maxLength={100}
          />
        </div>
      )}
    </>
  );

  return (
    <div
      className={cn(styles.storageDownloadedFile__container, {
        [styles.storageDownloadedFile__container_active]: currentFile,
      })}
    >
      <button
        className={styles.storageDownloadedFile__content}
        onClick={handleClickFile}
      >
        <div className={styles.storageDownloadedFile__block}>
          <IconButton
            appearance="outline"
            onClick={handleClickStarIcon}
            className={styles.storageDownloadedFile__button}
            icon={
              <StarIcon
                className={cn(styles.storageDownloadedFile__iconStar, {
                  [styles.storageDownloadedFile__iconStar_noActive]: !mainFile,
                  [styles.storageDownloadedFile__iconStar_active]: mainFile,
                })}
              />
            }
          />
          <div className={styles.storageDownloadedFile__titleContainer}>
            <Typography
              className={styles.storageDownloadedFile__title}
              variant={TypographyVariants.h5}
            >
              <DocumentIcon className={styles.storageDownloadedFile__icon} />
              {getValues(`files.${index}.fileName`)}
            </Typography>
            {mainFileId && (
              <Typography
                className={styles.storageDownloadedFile__fileType}
                variant={TypographyVariants.s2}
              >
                {mainFile ? 'Основной' : 'Дополнительный'}
              </Typography>
            )}
          </div>
        </div>
        <div className={styles.storageDownloadedFile__block}>
          <ConditionBlock text={size} />
          <IconButton
            appearance="outline"
            onClick={handleClickDelete}
            className={styles.storageDownloadedFile__button}
            icon={
              <Delete2Icon
                className={styles.storageDownloadedFile__iconDelete}
              />
            }
          />
        </div>
      </button>
      {currentInputs}

      <ApproveOrCancel
        onApprove={onApproveDeleteModal}
        isModal={isDeleteModal}
        toggleModal={toggleDeleteModal}
        text={`Вы уверены, что хотите удалить файл ${fileName}?`}
      />
    </div>
  );
};
