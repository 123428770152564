import cn from 'clsx';
import { debounce } from 'lodash';
import { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { CheckMarkIcon, CloseIcon } from 'assets/icons';
import {
  Input,
  PopoverButton,
  PopoverContainer,
  PopoverLine,
  Size,
} from 'components';
import { DEFAULT_DEBOUNCE_DELAY } from 'constants/meta';

import styles from './PopoverInner.module.scss';

export interface PopoverInnerProps {
  disable?: boolean;
  formSubmitHandler: (title: string) => void;
  onChange: (title: string) => void;
  isError?: boolean;
  errorText?: string;
  label?: string;
}

type CreateType = {
  title: string;
};

export const PopoverInner: FC<PopoverInnerProps> = ({
  disable = false,
  formSubmitHandler,
  onChange,
  isError,
  errorText,
  label = 'Название',
}) => {
  const {
    register,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<CreateType>({
    mode: 'onChange',
    defaultValues: {
      title: '',
    },
  });

  const { title: titleData } = watch();

  const disabledSaveBtn = disable || !titleData || isError;

  const onSaveButton = handleSubmit((data: CreateType) => {
    const { title } = data;
    if (!disabledSaveBtn) {
      formSubmitHandler(title);
      reset();
    }
  });

  const handleChangeTitle = useMemo(
    () =>
      debounce((e) => {
        if (onChange) {
          onChange(String(e.target.value));
        }
      }, DEFAULT_DEBOUNCE_DELAY),
    [onChange]
  );

  const titleInput = register('title', {
    required: true,
    maxLength: {
      value: 100,
      message: 'Название может быть длиннее 100 символов.',
    },
    minLength: {
      value: 1,
      message: 'Название не может быть пустым',
    },
    onChange: handleChangeTitle,
  });

  const textError = isError && errorText ? errorText : '';

  const handleReset = () => {
    reset();
    onChange('');
  };

  return (
    <PopoverContainer className={styles.popoverContent}>
      <div className={styles.popoverContent__input}>
        <Input
          size={Size.xs}
          inputClassName={cn({
            [styles.popoverContent__inputError]: isError,
          })}
          label={label}
          {...titleInput}
          error={!!errors.title || isError}
          errorMessage={errors.title?.message || (isError ? textError : '')}
          classNameErrorMessage={styles.popoverContent__inputErrorMessage}
        />
      </div>
      <PopoverLine className={styles.popoverContent__line} />
      <div className={styles.popoverContent__buttons}>
        <PopoverButton
          className={styles.popoverContent__buttonCheckMark}
          onClick={onSaveButton}
          disabled={disabledSaveBtn}
          type="submit"
          icon={
            <CheckMarkIcon
              className={cn(styles.popoverContent__iconCheckMark, {
                [styles.popoverContent__iconCheckMark_disabled]: disable,
              })}
            />
          }
        >
          Сохранить
        </PopoverButton>
        <PopoverButton
          type="button"
          onClick={handleReset}
          icon={<CloseIcon className={styles.popoverContent__iconClose} />}
        >
          Удалить
        </PopoverButton>
      </div>
    </PopoverContainer>
  );
};
