import { FC, MouseEvent } from 'react';
import { useSelector } from 'react-redux';

import { NotificationLink, Table } from 'components';
import { CustomField, OrganizationType } from 'core/types';
import { getPropsCustomFieldType } from 'features/CustomFields/utils';

import {
  CUSTOM_FIELD_ACCESS_TABLE,
  TABLE_HEADER_CUSTOM_FIELDS,
} from '../../constants';
import { getLoading } from '../../ducks/selectors';
import { CustomFieldsFilter } from '../CustomFieldsFilter';

import styles from './CustomFieldsFullTable.module.scss';

const getDataTable = (
  customFields?: CustomField[],
  customFieldClick?: (event: MouseEvent<HTMLButtonElement>) => void
) =>
  customFields?.map((customField) => {
    const { title, customFieldType, id, organization, system, accessType } =
      customField;
    return {
      id,
      title: (
        <button onClick={customFieldClick} className={styles.utils__title}>
          {title}
        </button>
      ),
      type: customFieldType
        ? getPropsCustomFieldType(customFieldType)
        : undefined,
      accessType: accessType
        ? {
            text: CUSTOM_FIELD_ACCESS_TABLE[accessType],
            className:
              accessType === OrganizationType.CUSTOMER
                ? styles.utils_customer
                : styles.utils_service,
          }
        : '-',
      organization: (
        <NotificationLink
          to={`/admin/organizations/${organization?.id}`}
          title={organization?.title}
        />
      ),
      system: { text: system?.title },
    };
  }) || [];

interface CustomFieldsFullTableProps {
  customFields?: CustomField[];
  sort?: string;
  handleSort?: (value: string) => void;
  handleClickCustomField?: (value: string) => void;
  activeRowId?: string;
  showFilterRow?: boolean;
  isFilterActive?: boolean;
  handleClickCustomFieldEdit?: () => void;
}

export const CustomFieldsFullTable: FC<CustomFieldsFullTableProps> = ({
  customFields,
  sort,
  handleSort,
  handleClickCustomField,
  activeRowId,
  showFilterRow,
  isFilterActive,
  handleClickCustomFieldEdit,
}) => {
  const onCustomFieldClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (handleClickCustomFieldEdit) {
      handleClickCustomFieldEdit();
    }
  };

  const data = getDataTable(customFields, onCustomFieldClick);

  const loading = useSelector(getLoading);

  return (
    <Table
      data={data}
      columns={TABLE_HEADER_CUSTOM_FIELDS}
      sort={sort}
      onRowClick={handleClickCustomField}
      onSort={handleSort}
      activeRowId={activeRowId}
      dataLoading={loading}
      isFilterActive={isFilterActive}
      valueFieldName="id"
      filterComponent={showFilterRow && <CustomFieldsFilter />}
      disabledSort={loading}
    />
  );
};
