import {
  CreateOrganizationToRequest,
  Organization,
  OrganizationsFilterToRequest,
} from 'core/types/organization';
import { ApiClient, getEnv } from 'utils';

import { endpoints } from './endpoints';

export const request = {
  fetchOrganizations: (
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter: OrganizationsFilterToRequest = {}
  ) =>
    ApiClient.post<Organization[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getOrganizations(),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: {
        ...filter,
      },
    }),
  fetchCurrentOrganization: (id: string) =>
    ApiClient.get<Organization>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getCurrentOrganization(id),
      data: {},
    }),
  createOrganization: (body: CreateOrganizationToRequest) =>
    ApiClient.post<Organization>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.createOrganization(),
      data: body,
    }),
  editOrganization: (body: CreateOrganizationToRequest) =>
    ApiClient.put<Organization>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.editOrganization(),
      data: body,
    }),
  deleteOrganization: (id: string) =>
    ApiClient.delete<boolean>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.deleteOrganization(id),
      data: {},
    }),
  fetchOrganizationsForReport: () =>
    ApiClient.get<Organization[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getOrganizationForReport(),
    }),
  hasActiveContract: (id: string) =>
    ApiClient.get<boolean>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.hasActiveContract(id),
      data: {},
    }),
};
