import { OrganizationItem } from '../types';

export const getCurrentOrganization = (
  organizationList?: Array<OrganizationItem>
) => {
  return organizationList
    ?.map((el) => {
      return {
        name: el.key,
        y: el.value,
      };
    })
    .filter((data) => data.y);
};
