import {
  all,
  call,
  put,
  select,
  StrictEffect,
  takeEvery,
} from 'redux-saga/effects';

import { setAlert } from 'core/ducks/actions';
import { getErrorAlert, getSuccessAlert } from 'core/layouts';
import { ActionForAlertTypes, RoleType } from 'core/types';
import { Role } from 'core/types/roles';
import {
  getFilterOrganizationId,
  setFilterOrganizationId,
} from 'features/Organizations';
import { resetResponsibilitiesState } from 'features/Responsibilities';
import { showSystemsLoading } from 'features/Systems';
import { getCurrentUserId } from 'features/Users';
import { getCurrentGroupId } from 'features/WorkGroups';
import { ResponseWithMeta } from 'store/types';
import { createError } from 'utils';

import {
  CreateRoleData,
  RoleCreateToRequest,
  RolesFilter,
  RolesFilterToRequest,
} from '../types';

import {
  checkIsExistSuccess,
  fetchRoleByIdRequest,
  fetchRoleByIdSuccess,
  fetchRolesAddSuccess,
  fetchRolesSuccess,
  hideRolesAddLoading,
  hideRolesLoading,
  resetCurrentRole,
  resetRolesState,
  setCurrentRoleId,
  showRolesAddLoading,
  showRolesLoading,
} from './actions';
import { request } from './api/requests';
import {
  getPropsRoles,
  getPropsRolesAdd,
  getRolesAddFilter,
  getRolesFilter,
} from './selectors';
import {
  CheckIsExistRequestAction,
  CreateRoleAction,
  DeleteRoleRequestAction,
  FetchRoleByIdRequestAction,
  Roles,
  UpdateRoleAction,
} from './types';

const ENTITY_ROLE = 'Роль';

export const getFilterRolesToRequest = (
  filter: RolesFilter
): RolesFilterToRequest => {
  const { organizationId, manager, type } = filter;
  const filterManager = {
    isManager: true,
    notManager: false,
  };
  return {
    ...filter,
    organizationId:
      organizationId && !Array.isArray(organizationId)
        ? organizationId.value
        : undefined,
    manager:
      manager && !Array.isArray(manager)
        ? filterManager[manager.value as keyof typeof filterManager]
        : undefined,
    type: type && !Array.isArray(type) ? type.value : undefined,
  };
};

export const getCreateRoleData = (
  data: CreateRoleData
): RoleCreateToRequest => {
  const { organizationId } = data;
  const prepareData = {
    ...data,
    organization:
      organizationId && !Array.isArray(organizationId)
        ? { id: organizationId.value }
        : undefined,
  };
  delete prepareData.organizationId;
  return prepareData;
};

function* rolesFetch() {
  try {
    const { pageNum, pageSize, sortRoles }: ReturnType<typeof getPropsRoles> =
      yield select(getPropsRoles);
    const filter: ReturnType<typeof getRolesFilter> = yield select(
      getRolesFilter
    );

    yield put(showRolesLoading());
    const roles: ResponseWithMeta<Role[]> = yield call(
      request.fetchRoles,
      pageNum,
      pageSize,
      sortRoles,
      getFilterRolesToRequest(filter)
    );
    yield put(fetchRolesSuccess(roles));
    yield put(hideRolesLoading());
  } catch (e) {
    createError(e);
    yield put(hideRolesLoading());
  }
}

function* rolesFetchByUserId() {
  try {
    const { pageNum, pageSize, sortRoles }: ReturnType<typeof getPropsRoles> =
      yield select(getPropsRoles);
    const filter: ReturnType<typeof getRolesFilter> = yield select(
      getRolesFilter
    );
    const userId: ReturnType<typeof getCurrentUserId> = yield select(
      getCurrentUserId
    );
    if (userId) {
      yield put(showRolesLoading());
      const roles: ResponseWithMeta<Role[]> = yield call(
        request.fetchRolesByUserId,
        userId,
        pageNum,
        pageSize,
        sortRoles,
        getFilterRolesToRequest(filter)
      );
      yield put(fetchRolesSuccess(roles));
    }
    yield put(hideRolesLoading());
  } catch (e) {
    createError(e);
    yield put(hideRolesLoading());
  }
}

function* roleCreate({ payload }: CreateRoleAction) {
  try {
    const { pageNum, pageSize, sortRoles }: ReturnType<typeof getPropsRoles> =
      yield select(getPropsRoles);
    yield put(showSystemsLoading());
    const newRole: Role = yield call(
      request.createRole,
      getCreateRoleData(payload)
    );
    yield put(
      setAlert(getSuccessAlert(ENTITY_ROLE, ActionForAlertTypes.CREATE))
    );
    const roles: ResponseWithMeta<Role[]> = yield call(
      request.fetchRoles,
      pageNum,
      pageSize,
      sortRoles
    );
    yield put(fetchRolesSuccess(roles));
    if (newRole.id) {
      yield all([
        put(setCurrentRoleId(newRole.id)),
        put(fetchRoleByIdRequest(newRole.id)),
      ]);
    }
    yield put(hideRolesLoading());
  } catch (e) {
    yield put(setAlert(getErrorAlert(ENTITY_ROLE, ActionForAlertTypes.CREATE)));
    createError(e);
    yield put(hideRolesLoading());
  }
}

function* groupRolesFetchByUserId() {
  try {
    const { pageNum, pageSize, sortRoles }: ReturnType<typeof getPropsRoles> =
      yield select(getPropsRoles);
    const userId: ReturnType<typeof getCurrentUserId> = yield select(
      getCurrentUserId
    );
    const groupId: ReturnType<typeof getCurrentGroupId> = yield select(
      getCurrentGroupId
    );
    const filter: ReturnType<typeof getRolesFilter> = yield select(
      getRolesFilter
    );
    if (!groupId || !userId) {
      throw new Error('не хватает данных');
    }
    yield put(showRolesLoading());
    const roles: ResponseWithMeta<Role[]> = yield call(
      request.fetchUserRolesByGroupId,
      groupId,
      userId,
      pageNum,
      pageSize,
      sortRoles,
      getFilterRolesToRequest(filter)
    );
    yield put(fetchRolesSuccess(roles));
    yield put(hideRolesLoading());
  } catch (e) {
    createError(e);
    yield put(hideRolesLoading());
  }
}

function* rolesFetchByGroupId() {
  try {
    const { pageNum, pageSize, sortRoles }: ReturnType<typeof getPropsRoles> =
      yield select(getPropsRoles);
    const groupId: ReturnType<typeof getCurrentGroupId> = yield select(
      getCurrentGroupId
    );
    const filter: ReturnType<typeof getRolesFilter> = yield select(
      getRolesFilter
    );
    yield put(showRolesLoading());
    const roles: ResponseWithMeta<Role[]> = yield call(
      request.fetchRolesByGroupId,
      groupId,
      pageNum,
      pageSize,
      sortRoles,
      getFilterRolesToRequest(filter)
    );
    yield put(fetchRolesSuccess(roles));
    yield put(hideRolesLoading());
  } catch (e) {
    createError(e);
    yield put(hideRolesLoading());
  }
}

function* roleUpdate({ payload }: UpdateRoleAction) {
  try {
    yield call(request.updateRole, payload);
    yield put(setAlert(getSuccessAlert(ENTITY_ROLE, ActionForAlertTypes.EDIT)));
    yield put(resetRolesState());
    yield put(resetResponsibilitiesState());
    yield call(rolesFetch);
  } catch (e) {
    yield put(setAlert(getErrorAlert(ENTITY_ROLE, ActionForAlertTypes.EDIT)));
    createError(e);
  }
}

function* fetchRoleById({ payload }: FetchRoleByIdRequestAction) {
  try {
    const role: Role = yield call(request.fetchRoleById, payload);
    yield put(fetchRoleByIdSuccess(role));
    if (role.organization?.id) {
      yield put(setFilterOrganizationId(role.organization?.id));
    }
  } catch (e) {
    createError(e);
  }
}

function* rolesInSystemAddFetchByOrgId() {
  try {
    const filter: ReturnType<typeof getRolesAddFilter> = yield select(
      getRolesAddFilter
    );
    const orgId: ReturnType<typeof getFilterOrganizationId> = yield select(
      getFilterOrganizationId
    );
    const { pageNum, pageSize } = yield select(getPropsRolesAdd);
    if (orgId) {
      yield put(showRolesAddLoading());
      const roles: ResponseWithMeta<Role[]> = yield call(
        request.fetchRoles,
        pageNum,
        pageSize,
        'NAME_ASC',
        {
          ...getFilterRolesToRequest(filter),
          type: RoleType.ROLE_IN_SYSTEM,
          organizationId: orgId,
        }
      );
      yield put(fetchRolesAddSuccess(roles));
    }
    yield put(hideRolesAddLoading());
  } catch (e) {
    createError(e);
    yield put(hideRolesAddLoading());
  }
}

function* rolesInGroupAddFetchByOrgId() {
  try {
    const filter: ReturnType<typeof getRolesAddFilter> = yield select(
      getRolesAddFilter
    );
    const orgId: ReturnType<typeof getFilterOrganizationId> = yield select(
      getFilterOrganizationId
    );
    const { pageNum, pageSize } = yield select(getPropsRolesAdd);
    if (orgId) {
      yield put(showRolesAddLoading());
      const roles: ResponseWithMeta<Role[]> = yield call(
        request.fetchRoles,
        pageNum,
        pageSize,
        'NAME_ASC',
        {
          ...getFilterRolesToRequest(filter),
          type: RoleType.ROLE_IN_GROUP,
          organizationId: orgId,
        }
      );
      yield put(fetchRolesAddSuccess(roles));
    }
    yield put(hideRolesAddLoading());
  } catch (e) {
    createError(e);
    yield put(hideRolesAddLoading());
  }
}

function* checkIsExist({ payload }: CheckIsExistRequestAction) {
  try {
    const isExist: boolean = yield call(request.checkIsExist, payload);
    yield put(checkIsExistSuccess(isExist));
  } catch (e) {
    createError(e);
  }
}

function* deleteRole({ payload }: DeleteRoleRequestAction) {
  try {
    yield call(request.deleteRole, payload);
    yield put(
      setAlert(getSuccessAlert(ENTITY_ROLE, ActionForAlertTypes.DELETE))
    );
    yield put(resetCurrentRole());
    yield put(resetResponsibilitiesState());
    yield call(rolesFetch);
  } catch (e) {
    yield put(setAlert(getErrorAlert(ENTITY_ROLE, ActionForAlertTypes.DELETE)));
    createError(e);
  }
}

export function* rolesSaga(): Generator<StrictEffect> {
  yield takeEvery(Roles.FETCH_ROLES_REQUEST, rolesFetch);
  yield takeEvery(Roles.FETCH_ROLES_BY_USERID_REQUEST, rolesFetchByUserId);
  yield takeEvery(Roles.CREATE_ROLE, roleCreate);
  yield takeEvery(
    Roles.FETCH_ROLES_IN_GROUP_BY_USERID_REQUEST,
    groupRolesFetchByUserId
  );
  yield takeEvery(Roles.FETCH_ROLES_BY_GROUP_ID, rolesFetchByGroupId);
  yield takeEvery(Roles.UPDATE_ROLE, roleUpdate);
  yield takeEvery(Roles.FETCH_ROLE_BY_ID_REQUEST, fetchRoleById);
  yield takeEvery(
    Roles.FETCH_ROLES_ADD_IN_SYSTEM_BY_ORG_ID_REQUEST,
    rolesInSystemAddFetchByOrgId
  );
  yield takeEvery(
    Roles.FETCH_ROLES_ADD_IN_GROUP_BY_ORG_ID_REQUEST,
    rolesInGroupAddFetchByOrgId
  );
  yield takeEvery(Roles.CHECK_IS_EXIST_REQUEST, checkIsExist);
  yield takeEvery(Roles.DELETE_ROLE_BY_ID_REQUEST, deleteRole);
}
