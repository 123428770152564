import { useState } from 'react';
import { useSelector } from 'react-redux';

import {
  CloseIcon,
  InfoQuestionMarkBigIcon,
  InfoQuestionMarkIcon,
} from 'assets/icons';
import {
  Button,
  Drawer,
  IconButtonWrapper,
  Ripple,
  Typography,
  TypographyVariants,
} from 'components';
import {
  getStorageDescription,
  getStorageTitle,
} from 'features/Storage/ducks/selectors';

import styles from './StorageInfoBlock.module.scss';

export const StorageInfoBlock = () => {
  const title = useSelector(getStorageTitle);
  const description = useSelector(getStorageDescription);

  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <div>
      <button
        className={styles.storageInfoBlock__infoButton}
        onClick={toggleOpen}
      >
        <InfoQuestionMarkIcon />
        <Ripple appearance="grey" />
      </button>
      <Drawer isOpen={isOpen} onClose={toggleOpen}>
        <div className={styles.storageInfoBlock}>
          <div className={styles.storageInfoBlock__header}>
            <Typography variant={TypographyVariants.h3}>{title}</Typography>
            <IconButtonWrapper
              className={styles.storageInfoBlock__headerCloseButton}
              onClick={toggleOpen}
              icon={
                <CloseIcon
                  className={styles.storageInfoBlock__headerCloseIcon}
                />
              }
            />
          </div>
          <div className={styles.storageInfoBlock__container}>
            <div className={styles.storageInfoBlock__content}>
              <InfoQuestionMarkBigIcon
                className={styles.storageInfoBlock__contentIcon}
              />
              <Typography variant={TypographyVariants.h2}>
                Информация
              </Typography>
              <Typography>{description}</Typography>
              <Button onClick={toggleOpen}>Назад</Button>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};
