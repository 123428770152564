type Bites = number | string;

const UNITS = ['Б', 'КБ', 'МБ', 'ГБ', 'ТБ', 'ПБ'];

export const formatFileSize = (originBytes: Bites, precision = 2) => {
  let bites = +originBytes;
  let unit = 0;

  if (Number.isNaN(bites)) {
    return '';
  }

  while (bites >= 1024) {
    bites /= 1024;
    unit += 1;
  }

  return `${Number(bites.toFixed(precision))} ${UNITS[unit]}`;
};
