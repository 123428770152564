import { Node } from 'reactflow';

import { NodeType } from 'core/types/workflow';

export const getCustomNodes = (nodes: Node<NodeType>[]) => {
  return nodes.map((node) => {
    return {
      ...node,
      type: 'custom',
    };
  });
};
