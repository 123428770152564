import { without } from 'lodash';

export const getEditorConfig = (imageUrl?: string, token?: string) => {
  return {
    toolbar: {
      items: [
        'bold',
        'italic',
        'underline',
        '|',
        'heading',
        'fontSize',
        'fontColor',
        'fontFamily',
        '|',
        'alignment',
        'outdent',
        'indent',
        '|',
        'numberedList',
        'bulletedList',
        'insertTable',
        '|',
        'link',
        'imageUpload',
        'blockQuote',
        'removeFormat',
        'undo',
        'redo',
      ],
    },
    language: 'ru',
    image: {
      toolbar: [
        'imageTextAlternative',
        'imageStyle:inline',
        'imageStyle:block',
        'imageStyle:side',
        'linkImage',
      ],
      name: 'file',
    },
    simpleUpload: {
      uploadUrl: imageUrl,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  };
};

export const getEditorConfigWithoutUpload = () => {
  const { toolbar, language } = getEditorConfig();
  const items = without(toolbar.items, 'imageUpload');
  return { toolbar: { items }, language };
};
