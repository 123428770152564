import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { GoBackButton, Select, SelectOption, Size } from 'components';
import { useStorageId } from 'features/Storage/hooks';
import { getStoragesOptions } from 'features/Storages';
import {
  fetchStoragesRequest,
  resetStoragesContent,
} from 'features/Storages/ducks';

import { fetchStorageRequest } from '../../ducks';
import { getStorage } from '../../ducks/selectors';

import styles from './StorageHeader.module.scss';

export const StorageHeader = () => {
  const dispatch = useDispatch();

  const storageId = useStorageId();
  const storage = useSelector(getStorage);
  const storages = useSelector(getStoragesOptions);

  const [params, setParams] = useState(storageId);

  const onChange = ({ value }: SelectOption) => {
    setParams(value);
  };

  useEffect(() => {
    dispatch(fetchStorageRequest(storageId));
  }, [storageId]);

  useEffect(() => {
    dispatch(fetchStoragesRequest({}));
    return () => {
      dispatch(resetStoragesContent());
    };
  }, []);

  return (
    <div className={styles.storageHeader}>
      <GoBackButton withLabel={false} />
      <Select
        className={styles.storageHeader__select}
        value={{
          title: storage?.title ?? '',
          value: storage?.id ?? '',
        }}
        size={Size.s}
        options={storages}
        onChange={onChange}
      />
      <Redirect to={`/storages/${params}`} />
    </div>
  );
};
