import { ObjDataType } from '../types';

export const getCheckboxList = (data: ObjDataType[]) => {
  const ids = data.map((item) => item?.id?.toString() || '');

  return ids.reduce<Record<string, boolean>>((acc, id) => {
    return {
      ...acc,
      [id]: false,
    };
  }, {});
};
