import { FC } from 'react';

import { SendToIcon } from 'assets/icons';
import { PopoverButton, PopoverContainer, Typography } from 'components';

import styles from './SystemsWithSLADropContent.module.scss';

interface SystemsWithSLADropContentProps {
  handleExpand: () => void;
  disabled: boolean;
}

export const SystemsWithSLADropContent: FC<SystemsWithSLADropContentProps> = ({
  handleExpand,
  disabled,
}) => {
  return (
    <PopoverContainer>
      <PopoverButton
        icon={<SendToIcon className={styles.systemsWithSlaDropContent__icon} />}
        disabled={disabled}
        onClick={handleExpand}
      >
        <Typography>Расширить поддержку</Typography>
      </PopoverButton>
    </PopoverContainer>
  );
};
