import cn from 'clsx';
import {
  type ElementType,
  forwardRef,
  type ReactElement,
  useMemo,
} from 'react';

import { createBemClass } from 'components-new/helpers/createBemClass';

import {
  type PolymorphicComponentPropWithRef,
  type PolymorphicRef,
} from '../types';

import styles from './Typography.module.scss';

export enum TypographyVariants {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
  h7 = 'h7',
  h8 = 'h8',
  b1 = 'b1',
  b2 = 'b2',
  b3 = 'b3',
  b4 = 'b4',
  s2 = 's2',
  o1 = 'o1',
  mono = 'mono',
  menu = 'menu',
}

enum VariantMapping {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
  h7 = 'h6',
  h8 = 'h6',
  b1 = 'p',
  b2 = 'p',
  b3 = 'p',
  b4 = 'p',
  s2 = 'p',
  o1 = 'p',
  mono = 'p',
  menu = 'p',
}

export type TypographyProps<C extends ElementType> =
  PolymorphicComponentPropWithRef<
    C,
    {
      variant?: keyof typeof TypographyVariants;
    }
  >;

type TypographyComponent = <C extends ElementType>(
  props: TypographyProps<C>
) => ReactElement | null;

const rootClassName = createBemClass('typography');

export const Typography: TypographyComponent = forwardRef(
  <C extends ElementType>(
    {
      variant = TypographyVariants.b3,
      className,
      children,
      as,
      ...other
    }: TypographyProps<C>,
    ref?: PolymorphicRef<C>
  ) => {
    const Component = as ?? VariantMapping[variant];

    const classes = useMemo(
      () =>
        cn(
          styles[rootClassName()],
          styles[rootClassName({ modName: variant })],
          className
        ),
      [variant, className]
    );

    return (
      <Component {...other} ref={ref} className={classes}>
        {children}
      </Component>
    );
  }
);
