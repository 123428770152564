import {
  getCountryCallingCode,
  isValidPhoneNumber,
  parsePhoneNumber,
} from 'react-phone-number-input';

export const getNormalizedPhoneNumber = (phoneNumber?: string) => {
  const country = parsePhoneNumber(phoneNumber || '')?.country;

  if (!country) {
    return phoneNumber;
  }

  const code = `+${getCountryCallingCode(country)} `;
  const newPhoneNumber = code + phoneNumber;
  const isValid = isValidPhoneNumber(newPhoneNumber, country);

  return isValid ? newPhoneNumber : phoneNumber;
};
