import { Reducer } from 'redux';

import { SelectOption } from 'components';
import { DEFAULT_PAGINATION_PARAMS } from 'constants/meta';
import { Article, CategoryArticle } from 'core/types/knowledgeBase';
import { Organization } from 'core/types/organization';
import { KBArticleTableFilterToRequest } from 'features/KnowledgeBase/types';
import { ReducerMap } from 'store/types';
import { Pagination } from 'types/models/meta';

import {
  ArticlesMain,
  ArticlesMainActionsTypes,
  FetchArticlesMainJoinSuccessAction,
  FetchArticlesMainSuccessAction,
  FetchCategoriesSuccessArticlesMainAction,
  FetchDefaultArticlesMainSuccessAction,
  FetchOrganizationsArticlesMainSuccessAction,
  SetArticlesMainFilterAction,
  SetArticlesMainPageAction,
  SetSelectedOrganizationArticlesMainAction,
} from './types';

export interface ArticlesMainReducerState {
  articlesFilter?: KBArticleTableFilterToRequest;
  articles?: Article[];
  loadingArticles?: boolean;
  loadingOrganizations?: boolean;
  articlesPagination?: Pagination;
  organizations?: Organization[];
  organizationSelected?: SelectOption | null;
  defaultArticles?: Article[];
  categories?: CategoryArticle[];
  categoriesLoading?: boolean;
}

const initialState: ArticlesMainReducerState = {
  organizations: [],
  loadingArticles: false,
  loadingOrganizations: false,
  articles: [],
  articlesPagination: DEFAULT_PAGINATION_PARAMS,
  defaultArticles: [],
  organizationSelected: null,
  categories: [],
  categoriesLoading: false,
};

const articlesMainCreateReducerMap: ReducerMap<
  ArticlesMainReducerState,
  ArticlesMainActionsTypes
> = {
  [ArticlesMain.SET_FILTER_ARTICLES_MAIN]: (state, action) => {
    const { payload } = action as SetArticlesMainFilterAction;
    return {
      ...state,
      articlesFilter: payload,
    };
  },
  [ArticlesMain.FETCH_SUCCESS_ARTICLES_MAIN]: (state, action) => {
    const { payload } = action as FetchArticlesMainSuccessAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      articles: content,
      articlesPagination: {
        ...state?.articlesPagination,
        pageNum: page,
        totalElements,
      },
    };
  },
  [ArticlesMain.FETCH_ARTICLES_MAIN_JOIN_SUCCESS]: (state, action) => {
    const { payload } = action as FetchArticlesMainJoinSuccessAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      articles: [...(state?.articles || []), ...content],
      articlesPagination: {
        ...state?.articlesPagination,
        pageNum: page,
        totalElements,
      },
    };
  },
  [ArticlesMain.SET_ARTICLES_MAIN_PAGE]: (state, action) => {
    const { payload } = action as SetArticlesMainPageAction;
    return {
      ...state,
      articlesPagination: {
        ...state?.articlesPagination,
        pageNum: payload,
      },
    };
  },
  [ArticlesMain.LOADING_SHOW_ARTICLES_MAIN]: (state) => {
    return {
      ...state,
      loadingArticles: true,
    };
  },
  [ArticlesMain.LOADING_HIDE_ARTICLES_MAIN]: (state) => {
    return {
      ...state,
      loadingArticles: false,
    };
  },
  [ArticlesMain.FETCH_DEFAULT_ARTICLES_MAIN_SUCCESS]: (state, action) => {
    const { payload } = action as FetchDefaultArticlesMainSuccessAction;
    return {
      ...state,
      defaultArticles: payload,
    };
  },
  [ArticlesMain.FETCH_ORGANIZATIONS_SUCCESS_ARTICLES_MAIN]: (state, action) => {
    const { payload } = action as FetchOrganizationsArticlesMainSuccessAction;
    return {
      ...state,
      organizations: payload,
    };
  },
  [ArticlesMain.ORGANIZATIONS_LOADING_SHOW_ARTICLES_MAIN]: (state) => {
    return {
      ...state,
      loadingOrganizations: true,
    };
  },
  [ArticlesMain.ORGANIZATIONS_LOADING_HIDE_ARTICLES_MAIN]: (state) => {
    return {
      ...state,
      loadingOrganizations: false,
    };
  },
  [ArticlesMain.CATEGORIES_LOADING_SHOW_ARTICLES_MAIN]: (state) => {
    return {
      ...state,
      categoriesLoading: true,
    };
  },
  [ArticlesMain.CATEGORIES_LOADING_HIDE_ARTICLES_MAIN]: (state) => {
    return {
      ...state,
      categoriesLoading: false,
    };
  },
  [ArticlesMain.FETCH_CATEGORIES_SUCCESS_ARTICLES_MAIN]: (state, action) => {
    const { payload } = action as FetchCategoriesSuccessArticlesMainAction;
    return {
      ...state,
      categories: payload,
    };
  },
  [ArticlesMain.RESET_ARTICLES_STATE_ARTICLES_MAIN]: (state) => {
    const {
      articles,
      articlesPagination,
      articlesFilter,
      organizationSelected,
    } = initialState;
    return {
      ...state,
      articles,
      articlesPagination,
      organizationSelected,
      articlesFilter,
    };
  },
  [ArticlesMain.SET_ORGANIZATION_SELECTED_ARTICLES_MAIN]: (state, action) => {
    const { payload } = action as SetSelectedOrganizationArticlesMainAction;
    return {
      ...state,
      organizationSelected: payload,
    };
  },
  [ArticlesMain.RESET_CATEGORIES_STATE_ARTICLES_MAIN]: (state) => {
    const { categories, organizationSelected, organizations } = initialState;
    return {
      ...state,
      categories,
      organizationSelected,
      organizations,
    };
  },
  [ArticlesMain.RESET_ARTICLES_MAIN_STATE]: () => {
    return {
      ...initialState,
    };
  },
};

export const articlesMain: Reducer<
  ArticlesMainReducerState,
  ArticlesMainActionsTypes
> = (state = initialState, action) => {
  const reducer = articlesMainCreateReducerMap[action.type];
  if (!reducer) {
    return state;
  }
  return reducer(state, action);
};
