import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { TablesLayout } from 'core/layouts';
import { TableRolesTypes } from 'core/types/roles';
import { TableUsersTypes } from 'core/types/user';
import { TableWorkGroupsTypes } from 'core/types/workGroup';
import { resetOrganizationState } from 'features/Organizations';
import { resetRolesState, RolesTableContainer } from 'features/Roles';
import {
  resetWorkGroupsState,
  WorkGroupsTableContainer,
} from 'features/WorkGroups';

import { UsersTableContainer } from './components';
import { fetchUsersRequest, resetUsersState } from './ducks/actions';
import styles from './Users.module.scss';

const SubContractsTable = () => {
  return (
    <WorkGroupsTableContainer tableType={TableWorkGroupsTypes.FROM_USERS} />
  );
};

const SubUsersTable = () => {
  return <RolesTableContainer tableType={TableRolesTypes.FROM_USERS} />;
};

const MainTable = () => {
  return <UsersTableContainer tableType={TableUsersTypes.FULL} />;
};

export const Users = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUsersRequest());
    return () => {
      dispatch(resetUsersState());
      dispatch(resetWorkGroupsState());
      dispatch(resetRolesState());
      dispatch(resetOrganizationState());
    };
  }, []);

  return (
    <TablesLayout
      MainTable={[MainTable]}
      SubTable={[SubContractsTable, SubUsersTable]}
      classNameRoot={styles.users}
    />
  );
};
