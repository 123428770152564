import { SelectOption } from 'components';
import { Organization } from 'core/types/organization';

export const convertOrganizationToSelectOption = (
  organizations?: Organization[]
) => {
  return (
    organizations?.map(
      (organization): SelectOption => ({
        title: organization.title,
        value: organization.id || '',
      })
    ) || []
  );
};
