import { CustomFieldTypeFilter } from 'components';
import { Priority, TicketsTabType } from 'core/types';
import { TicketsFilterForRender } from 'core/types/tickets';
import { KeyValueOption } from 'types/models/meta';

export type TicketsFilterForRequest = {
  theme?: string;
  number?: string;
  statuses?: string[];
  priorities?: Priority[];
  specialistIds?: string[];
  dateSolveFrom?: Date;
  dateSolveTo?: Date;
  systemIds?: string[];
  clientIds?: string[];
  memberIds?: string[];
  workGroupIds?: string[];
  contractIds?: string[];
  tagName?: string;
  onlyActive?: boolean;
  dateCreateFrom?: Date;
  dateCreateTo?: Date;
  typeIds?: string[];
  customFields?: CustomFieldTypeFilter[];
  name?: string;
};

export interface Filter {
  theme?: string;
  number?: string;
  onlyActive?: boolean;
  contractIds?: string[];
  statuses?: string[];
  priorities?: KeyValueOption[];
  specialists?: KeyValueOption[];
  workGroupIds?: string[];
  systems?: KeyValueOption[];
  clients?: KeyValueOption[];
  tagName?: string;
  dateSolveFrom: Date;
  dateSolveTo: Date;
  dateCreateFrom: Date;
  dateCreateTo: Date;
  types?: KeyValueOption[];
}

export interface FilterData {
  id: string;
  userId: string;
  title: string;
  filter: Filter;
}

export interface DefaultFilterValuesProps {
  userId?: string;
  userWorkGroupsIds?: string[];
  isClient?: boolean;
  contractIds?: string[];
  userManagerWorkGroupsIds?: string[];
  isMyTicketsPage?: boolean;
  isAllTicketsPage?: boolean;
  isGroupsTicketsPage?: boolean;
}

export interface CreateFilterRequest {
  title: string;
  ticketTab: TicketsTabType;
  filter: TicketsFilterForRequest;
}

export interface DeleteFilterRequest {
  id: string;
  ticketTab: TicketsTabType;
}

export interface SetCurrentFilterTitleRequest {
  title: string;
  id: string;
  ticketTab?: TicketsTabType;
}

export interface FetchTickets {
  withFirstTicketFetch?: boolean;
  ticketTab?: TicketsTabType;
}

export interface FetchStatuses {
  filter: TicketsFilterForRender;
}

export enum TicketsTableFields {
  TICKET_NUMBER = 'Номер тикета',
  SYSTEM = 'Система',
  SOURCE = 'Источник',
}
