import cn from 'clsx';
import { nanoid } from 'nanoid';
import { forwardRef, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import {
  AttachmentIcon,
  CloseIcon,
  // MoreHorizontal,
  SendIcon,
} from 'assets/icons';
import {
  Card,
  FileChip,
  FileInput,
  IconButton,
  Loader,
  MenuItem,
  Popover,
  RenderMenuItemProps,
  RoundButton,
  Select,
  Size,
  ToggleButton,
  Typography,
  TypographyVariants,
} from 'components';
// import { Spin } from 'components-new/Spin';
// import { Typography as TypographyNew } from 'components-new/Typography';
import { DataEmpty } from 'core/components';
import { MAX_FILE_SIZE } from 'core/constants';
import { AttachmentDeleteType } from 'core/types/ticket';
import { getUserId } from 'features/Auth';
import {
  useAutosizeTextArea,
  useTicketComments,
  useTicketCommentsForm,
} from 'features/Ticket/hooks';

import { AssessmentInteractionProcess } from '../AssessmentInteractionProcess';
import { PreviewImageModal } from '../PreviewImageModal';
import { TicketComment } from '../TicketComment';
import { TicketCommentsDropContent } from '../TicketCommentsDropContent';
import { TicketAttachments } from '../TicketInfo/components';
import { getAttachmentFilesComment } from '../TicketInfo/utils';
import { TicketSpoiler } from '../TicketSpoiler';

import styles from './TicketComments.module.scss';

const renderMenuItem = ({
  onChange,
  option,
  selected,
}: RenderMenuItemProps) => {
  return (
    <MenuItem
      onClick={onChange}
      selected={selected}
      className={styles.createTicket__option}
    >
      <span className={styles.createTicket__optionTitle}>#{option.title}</span>
      <div className={styles.createTicket__optionTheme}>{option.meta}</div>
    </MenuItem>
  );
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const TicketComments = forwardRef<HTMLDivElement>((_props, ref) => {
  const currentUserId = useSelector(getUserId);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const content = document.querySelector(`.${styles.ticketComments__content}`);

  const [isImageModal, setIsImageModal] = useState<boolean>(false);
  const [initialImageIndex, setInitialImageIndex] = useState(0);

  const {
    state: {
      isAttachments,
      isFileUploadOpen,
      messageHistory,
      myFiles,
      isAccessToChangeStatus,
      ticketCommentsAttachments,
      isSolutionAccepted,
      isAssessmentInteractionProcess,
      spoilerMessage,
      loadingComments,
      isPopoverOpen,
      status,
    },
    methods: {
      toggleAttachments,
      toggleFileUpload,
      setFileHandler,
      removeFile,
      setMyFiles,
      onErrorMaxFileSize,
      togglePopover,
    },
  } = useTicketComments({ content });

  const {
    state: {
      control,
      isValid,
      isDirty,
      currentMessage,
      ticketsOptions,
      pageNum,
      totalPagesTickets,
      loadingTickets,
      inputValue,
      isTicketsIds,
      isTicketDeleted,
    },
    methods: {
      handleKeyDown,
      formSubmitHandler,
      toggleIsTicketsIds,
      handleInput,
      hideInputRow,
      setNextPage,
    },
  } = useTicketCommentsForm({ status });

  const { imageFiles, documentFiles } = getAttachmentFilesComment(
    ticketCommentsAttachments
  );

  const toggleIsImageModal = () => {
    setIsImageModal((prevState) => !prevState);
  };

  const handleInitialImageIndex = (index: number) => {
    setInitialImageIndex(index);
  };

  const isMessageDisabled =
    isSolutionAccepted || loadingComments || isTicketDeleted;
  // TODO hotfix/tsp-2573
  // const isStatusClose = status.closing || status.closed;

  useAutosizeTextArea(textAreaRef.current, buttonRef.current, currentMessage);

  const commentsList = messageHistory?.map((comment) => {
    const { message, created, userDto, spoiler } = comment;

    const isMyTicket = currentUserId === userDto?.id;

    if (message && !spoiler) {
      return (
        <CSSTransition
          key={created}
          timeout={1000}
          classNames={styles.ticketComments__comment}
          className={cn(styles.ticketComments__comment, {
            [styles.ticketComments__comment_my]: isMyTicket,
          })}
        >
          <TicketComment
            comment={comment}
            isAccessToChangeStatus={isAccessToChangeStatus}
            isMyTicket={isMyTicket}
          />
        </CSSTransition>
      );
    }
    return null;
  });

  const commentFileList = Boolean(myFiles.length) && (
    <div className={styles.ticketComments__fileNamesWrapper}>
      {myFiles.map((file: File) => {
        return (
          <FileChip
            key={nanoid()}
            title={file.name}
            onDelete={removeFile(file)}
            className={styles.ticketComments__fileBlock}
          />
        );
      })}
    </div>
  );

  const commentsAttachments = ticketCommentsAttachments?.length ? (
    <TicketAttachments
      documentFiles={documentFiles}
      imageFiles={imageFiles}
      toggleIsModal={toggleIsImageModal}
      handleInitialImageIndex={handleInitialImageIndex}
      size={Size.s}
      classNameAttachmentsColumn={styles.ticketComments__attachmentFiles}
      attachmentDeleteType={AttachmentDeleteType.COMMENTS}
    />
  ) : (
    <DataEmpty />
  );

  const imageModal = isImageModal && (
    <PreviewImageModal
      toggleModal={toggleIsImageModal}
      initialImageIndex={initialImageIndex}
      imageFiles={imageFiles}
    />
  );

  const fileUpload = isFileUploadOpen && !loadingComments && (
    <div className={styles.ticketComments__fileLoadWrapper}>
      <Card className={styles.ticketComments__fileLoadCard}>
        <FileInput
          fileHandler={setFileHandler}
          myFiles={myFiles}
          setMyFiles={setMyFiles}
          showFiles={false}
          maxFileSize={MAX_FILE_SIZE}
          onErrorMaxFileSize={onErrorMaxFileSize}
          classNameWrapper={styles.ticketComments__fileUpload}
          classNameIcon={styles.ticketComments__fileUploadIcon}
          classNameTitle={styles.ticketComments__fileUploadTitle}
          classNameSubTitle={styles.ticketComments__fileUploadSubTitle}
          classNameButton={styles.ticketComments__fileUploadButton}
        />
      </Card>
    </div>
  );

  const assessmentInteractionProcess = isAssessmentInteractionProcess && (
    <CSSTransition
      timeout={1000}
      classNames={styles.ticketComments__comment}
      className={cn(
        styles.ticketComments__comment,
        styles.ticketComments__comment_my
      )}
    >
      <AssessmentInteractionProcess />
    </CSSTransition>
  );

  const spoiler = spoilerMessage && <TicketSpoiler comment={spoilerMessage} />;

  // TODO hotfix/tsp-2573
  // const commentsContent = !isFileUploadOpen &&
  //   !loadingComments &&
  //   !status.connecting &&
  //   !isStatusClose && (
  //     <TransitionGroup
  //       enter
  //       exit
  //       appear
  //       className={styles.ticketComments__content}
  //     >
  //       {spoiler}
  //       {assessmentInteractionProcess}
  //       {commentsList}
  //     </TransitionGroup>
  //   );

  const commentsContent = !isFileUploadOpen && !loadingComments && (
    <TransitionGroup
      enter
      exit
      appear
      className={styles.ticketComments__content}
    >
      {spoiler}
      {assessmentInteractionProcess}
      {commentsList}
    </TransitionGroup>
  );

  const loader = loadingComments && (
    <div className={styles.ticketComments__loader}>
      <Loader />
    </div>
  );

  // const loader = (loadingComments || status.connecting) && (
  //   <div className={styles.ticketComments__loader}>
  //     {status.connecting ? (
  //       <div className={styles.ticketComments__chatLoader}>
  //         <Spin size="xs" />
  //         <TypographyNew className={styles.ticketComments__chatLoaderTitle}>
  //           Подключение...
  //         </TypographyNew>
  //       </div>
  //     ) : (
  //       <Loader />
  //     )}
  //   </div>
  // );

  // const errorIcon = isStatusClose && (
  //   <div className={styles.ticketComments__loader}>
  //     <MoreHorizontal className={styles.ticketComments__moreHorizontal} />
  //   </div>
  // );

  return (
    <div className={styles.ticketComments} ref={ref}>
      <Card className={styles.ticketComments__head}>
        <Typography variant={TypographyVariants.h5}>Комментарии</Typography>
        <ToggleButton
          icon={<AttachmentIcon />}
          onClick={toggleAttachments}
          isActive={isAttachments}
        />
      </Card>
      <div className={styles.ticketComments__allContent}>
        <div
          className={cn(styles.ticketComments__contentWrapper, {
            [styles.ticketComments__contentWrapper_hidden]: isAttachments,
          })}
        >
          {fileUpload}
          {commentsContent}
          {loader}
          {/* {errorIcon} */}
          <Card
            className={cn(styles.ticketComments__inputWrapper, {
              [styles.ticketComments__inputWrapper_attachmentsOpen]:
                isAttachments,
            })}
          >
            <div
              className={cn(styles.ticketComments__inputField)}
              // TODO hotfix/tsp-2573
              // className={cn(styles.ticketComments__inputField, {
              //   [styles.ticketComments__inputField_disabled]: !status.open,
              // })}
            >
              <button
                ref={buttonRef}
                onClick={toggleFileUpload}
                disabled={isTicketDeleted || !status.open}
                className={cn(styles.ticketComments__attachmentInput, {
                  [styles.ticketComments__attachmentInput_open]:
                    isFileUploadOpen && status.open,
                  [styles.ticketComments__attachmentInput_disabled]:
                    !status.open,
                })}
              >
                <AttachmentIcon />
              </button>
              <div className={styles.ticketComments__inputBlock}>
                <Controller
                  control={control}
                  name="message"
                  key="message"
                  rules={{
                    required: true,
                    maxLength: {
                      value: 1024,
                      message: 'Текст сообщения не может быть пустым',
                    },
                  }}
                  render={({ field }) => {
                    return (
                      <textarea
                        ref={textAreaRef}
                        className={styles.ticketComments__input}
                        placeholder="Начать беседу"
                        onKeyDown={(event) => {
                          handleKeyDown(event);
                          if (
                            event.key === 'Enter' &&
                            (event.ctrlKey || event.metaKey)
                          ) {
                            field.onChange(`${currentMessage}\n`);
                          }
                        }}
                        onChange={(value) => {
                          field.onChange(value);
                        }}
                        value={field.value}
                        disabled={isMessageDisabled}
                      />
                    );
                  }}
                />
                <form
                  className={styles.ticketComments__sendButtons}
                  onSubmit={formSubmitHandler}
                >
                  <Popover
                    isOpen={isPopoverOpen}
                    togglePopover={togglePopover}
                    disabled={isTicketDeleted || !status.open}
                    content={
                      <TicketCommentsDropContent
                        toggleIsTicketsIds={toggleIsTicketsIds}
                        togglePopover={togglePopover}
                        isTicketsIds={isTicketsIds}
                      />
                    }
                    positions={['top', 'left', 'bottom', 'right']}
                    align="end"
                  />
                  <IconButton
                    disabled={
                      !isDirty ||
                      !isValid ||
                      isSolutionAccepted ||
                      !currentMessage?.trim()?.length ||
                      loadingComments ||
                      !status.open
                    }
                    icon={<SendIcon />}
                    appearance="flat"
                    className={styles.ticketComments__sendButtonsSend}
                  />
                </form>
              </div>
            </div>
            {isTicketsIds && (
              <div className={styles.ticketComments__multiSelectWrapper}>
                <Controller
                  control={control}
                  name="ticketsIds"
                  render={({ field }) => {
                    return (
                      <Select<string>
                        options={ticketsOptions}
                        onChangeInput={handleInput}
                        currentPage={pageNum}
                        totalPage={totalPagesTickets}
                        setNextPage={setNextPage}
                        inputValue={inputValue}
                        value={field.value}
                        onChange={field.onChange}
                        mobileModalTitle="тикеты"
                        loading={loadingTickets}
                        isSearchable
                        infiniteScrollable
                        isMulti
                        isChip
                        renderMenuItem={renderMenuItem}
                        dropdownContentClassName={
                          styles.ticketComments__selectMenu
                        }
                      />
                    );
                  }}
                />
                <div className={styles.ticketComments__editButtons}>
                  <RoundButton
                    icon={<CloseIcon />}
                    onClick={hideInputRow}
                    type="button"
                    className={styles.ticketComments__editClose}
                  />
                </div>
              </div>
            )}
            {commentFileList}
          </Card>
        </div>
        <Card
          className={cn(styles.ticketComments__attachments, {
            [styles.ticketComments__attachments_closed]: !isAttachments,
          })}
        >
          <Typography
            variant={TypographyVariants.o}
            className={styles.ticketComments__attachmentsTitle}
          >
            Вложения
          </Typography>
          <div className={styles.ticketComments__attachmentsContainer}>
            {commentsAttachments}
          </div>
        </Card>
      </div>
      {imageModal}
    </div>
  );
});
