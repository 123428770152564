import { RootState } from 'store/rootReducer';

export const getHistoryTickets = (state: RootState) =>
  state.desktopHistory.tickets;
export const getHistoryTotalTickets = (state: RootState) =>
  state.desktopHistory.totalTickets;
export const getTicketsStatistic = (state: RootState) =>
  state.desktopHistory.ticketsStatistic;
export const getHistoryPageNumber = (state: RootState) =>
  state.desktopHistory.pageNum;
export const getHistoryPageSize = (state: RootState) =>
  state.desktopHistory.pageSize;
export const getHistorySort = (state: RootState) => state.desktopHistory.sort;
export const getIsHistoryTicketsLoading = (state: RootState) =>
  state.desktopHistory.isTicketsLoading;
