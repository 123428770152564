import cn from 'clsx';
import { FC } from 'react';
import { Controller } from 'react-hook-form';

import {
  ArrowHorizontalIcon,
  CheckMarkIcon,
  CloseIcon,
  JiraLogoIcon,
  RefreshIcon,
} from 'assets/icons';
import {
  Button,
  getValueFromValueType,
  Input,
  PriorityBlock,
  Select,
  Size,
  Typography,
  TypographyVariants,
} from 'components';
import { DataEmpty, StatusBlock } from 'core/components';
import { Create } from 'core/modals';
import { useJiraIntegrationCreate } from 'features/JiraIntegrations/hooks';
import { JiraConnectionStatus } from 'features/JiraIntegrations/types';

import { JIRA_CONNECTION_STATUS_TEXT_MAP } from '../../constants';

import styles from './JiraIntegrationCreate.module.scss';

const jiraConnectionStatusIconMap = {
  [JiraConnectionStatus.DEFAULT]: (
    <RefreshIcon
      className={styles.jiraIntegrationCreate__checkConnectionIcon}
    />
  ),
  [JiraConnectionStatus.SUCCESS]: (
    <CheckMarkIcon
      className={styles.jiraIntegrationCreate__checkConnectionIcon_success}
      style={{ color: 'var(--green120)' }}
    />
  ),
  [JiraConnectionStatus.ERROR]: (
    <CloseIcon
      className={styles.jiraIntegrationCreate__checkConnectionIcon}
      style={{ color: 'var(--red70)' }}
    />
  ),
};

interface JiraIntegrationCreateProps {
  isModal: boolean;
  toggleModal(): void;
}

export const JiraIntegrationCreate: FC<JiraIntegrationCreateProps> = ({
  isModal,
  toggleModal,
}) => {
  const { state, methods } = useJiraIntegrationCreate({ toggleModal });

  const {
    organizationsOptions,
    systemsOptions,
    jiraPrioritiesOptions,
    jiraStatusesOptions,
    jiraConnectionStatus,
    isSuccessConnect,
    isErrorConnect,
    control,
    errors,
    nameInputOptions,
    loginInputOptions,
    passwordInputOptions,
    jiraUrlInputOptions,
    projectKeyInputOptions,
    isCheckConnectionDisabled,
    spPriorities,
    spStatuses,
    ticketTypeId,
    isTicketTypeDisabled,
    isJiraTicketTypeDisabled,
    jiraTicketTypesOptions,
    ticketTypesOptions,
    statuses,
    priorities,
    disabledSubmit,
  } = state;

  const {
    checkConnectionHandler,
    createJiraIntegrationHandler,
    getIsPriorityAvailable,
    fetchSPStatuses,
    fetchJiraStatuses,
    onChangeStatuses,
    onChangePriorities,
  } = methods;

  const statusMapList = spStatuses?.map(
    ({ id, defaultStatus, name, color }) => {
      const status = statuses.find((item) => item.ticketStatusIds.includes(id));
      const value = { title: status?.name || '', value: status?.idStatus || 0 };

      return (
        <div className={styles.jiraIntegrationCreate__mapItem} key={id}>
          <StatusBlock
            label={name}
            status={defaultStatus}
            color={color}
            className={styles.jiraIntegrationCreate__mapStatus}
          />
          <div className={styles.jiraIntegrationCreate__mapSelectWrapper}>
            <ArrowHorizontalIcon
              className={styles.jiraIntegrationCreate__mapIcon}
            />
            <Select<number>
              size={Size.xs}
              className={styles.jiraIntegrationCreate__mapSelect}
              disabled={!isSuccessConnect}
              options={jiraStatusesOptions}
              onChange={onChangeStatuses(id)}
              mobileModalTitle="статус"
              isTooltip={false}
              value={value}
            />
          </div>
        </div>
      );
    }
  );

  const priorityMapList = spPriorities.map(({ priority }) => {
    const isPriorityAvailable = getIsPriorityAvailable(priority);
    const priorite = priorities.find((item) =>
      item?.ticketPriorities?.includes(priority)
    );
    const value = {
      title: priorite?.name || '',
      value: priorite?.idPriority || 0,
    };

    return (
      <div className={styles.jiraIntegrationCreate__mapItem} key={priority}>
        <PriorityBlock
          priority={priority}
          className={styles.jiraIntegrationCreate__mapPriority}
        />
        <div className={styles.jiraIntegrationCreate__mapSelectWrapper}>
          <ArrowHorizontalIcon
            className={styles.jiraIntegrationCreate__mapIcon}
          />
          <Select<number>
            size={Size.xs}
            className={styles.jiraIntegrationCreate__mapSelect}
            disabled={
              !isSuccessConnect || !isPriorityAvailable || !spStatuses?.length
            }
            options={jiraPrioritiesOptions}
            onChange={onChangePriorities(priority)}
            mobileModalTitle="приоритет"
            isTooltip={false}
            value={value}
          />
        </div>
      </div>
    );
  });

  const statusesList = statusMapList?.length ? statusMapList : <DataEmpty />;

  return (
    <Create
      toggleModal={toggleModal}
      isModal={isModal}
      title="Интеграция"
      createTitle="Создать"
      disabledSubmit={disabledSubmit}
      subModalText="создание интеграции"
      className={styles.jiraIntegrationCreate}
      onSubmit={createJiraIntegrationHandler}
    >
      <div className={styles.jiraIntegrationCreate__wrapper}>
        <div className={styles.jiraIntegrationCreate__leftContainer}>
          <JiraLogoIcon />
          <div className={styles.jiraIntegrationCreate__leftWrapper}>
            <div className={styles.jiraIntegrationCreate__connectionForm}>
              <Input
                label="Название"
                disabled={!isSuccessConnect}
                {...nameInputOptions}
                className={cn(styles.jiraIntegrationCreate__element_mb)}
              />
              <Input
                label="Сервер"
                disabled={isSuccessConnect}
                error={isErrorConnect || !!errors.jiraUrl}
                {...jiraUrlInputOptions}
                className={cn(styles.jiraIntegrationCreate__element_mb)}
              />
              <div className={styles.jiraIntegrationCreate__loginBlock}>
                <Input
                  label="Логин"
                  disabled={isSuccessConnect}
                  error={isErrorConnect || !!errors.login}
                  {...loginInputOptions}
                  className={cn(
                    styles.jiraIntegrationCreate__element_mb,
                    styles.jiraIntegrationCreate__element_oneOfTwo
                  )}
                />
                <Input
                  label="Пароль"
                  disabled={isSuccessConnect}
                  error={isErrorConnect || !!errors.password}
                  {...passwordInputOptions}
                  type="password"
                  isPassword
                  className={cn(
                    styles.jiraIntegrationCreate__element_mb,
                    styles.jiraIntegrationCreate__element_oneOfTwo
                  )}
                />
              </div>
              <Input
                label="Ключ"
                disabled={isSuccessConnect}
                error={isErrorConnect || !!errors.projectKey}
                {...projectKeyInputOptions}
                className={cn(styles.jiraIntegrationCreate__element_mb)}
              />
              <div
                className={styles.jiraIntegrationCreate__checkConnectionBlock}
              >
                <Button
                  appearance="outline"
                  type="button"
                  disabled={isCheckConnectionDisabled}
                  className={
                    styles.jiraIntegrationCreate__checkConnectionButton
                  }
                  onClick={checkConnectionHandler}
                >
                  Проверить подключение
                </Button>
                <div
                  className={
                    styles.jiraIntegrationCreate__checkConnectionTitleBlock
                  }
                >
                  {jiraConnectionStatusIconMap[jiraConnectionStatus]}
                  <Typography
                    variant={TypographyVariants.b3}
                    className={
                      styles.jiraIntegrationCreate__checkConnectionText
                    }
                  >
                    {JIRA_CONNECTION_STATUS_TEXT_MAP[jiraConnectionStatus]}
                  </Typography>
                </div>
              </div>
            </div>
            <div
              className={styles.jiraIntegrationCreate__selectConnectionBlock}
            >
              <Typography
                variant={TypographyVariants.h4}
                className={cn(
                  styles.jiraIntegrationCreate__orgConnectionBlockTitle,
                  styles.jiraIntegrationCreate__element_mb
                )}
              >
                Связь с организацией
              </Typography>
              <Controller
                control={control}
                rules={{ required: true }}
                name="organizationId"
                key="organizationId"
                render={({ field }) => {
                  return (
                    <Select<string>
                      label="Организация"
                      mobileModalTitle="организацию"
                      onChange={field.onChange}
                      value={field.value}
                      options={organizationsOptions}
                      disabled={!isSuccessConnect}
                      className={styles.jiraIntegrationCreate__element_mb}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                rules={{ required: true }}
                name="systemId"
                key="systemId"
                render={({ field }) => {
                  return (
                    <Select<string>
                      label="Система"
                      mobileModalTitle="cистему"
                      onChange={field.onChange}
                      value={field.value}
                      options={systemsOptions}
                      disabled={!isSuccessConnect}
                    />
                  );
                }}
              />
            </div>
            <div
              className={styles.jiraIntegrationCreate__selectConnectionBlock}
            >
              <Typography
                variant={TypographyVariants.h4}
                className={cn(
                  styles.jiraIntegrationCreate__orgConnectionBlockTitle,
                  styles.jiraIntegrationCreate__element_mb
                )}
              >
                Выберите тип тикета для настройки маппинга
              </Typography>
              <Controller
                control={control}
                rules={{ required: true }}
                name="ticketTypeId"
                render={({ field }) => {
                  return (
                    <Select<string>
                      label="Тип тикета"
                      mobileModalTitle="тип тикета"
                      onChange={(value) => {
                        fetchSPStatuses(getValueFromValueType(value) || '');
                        field.onChange(value);
                      }}
                      value={field.value}
                      options={ticketTypesOptions}
                      disabled={isTicketTypeDisabled}
                      className={styles.jiraIntegrationCreate__element_mb}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>

        <div className={styles.jiraIntegrationCreate__rightContainer}>
          <div className={styles.jiraIntegrationCreate__titleContainer}>
            <Typography
              variant={TypographyVariants.h4}
              className={styles.jiraIntegrationCreate__title}
            >
              SupPortal
            </Typography>
            <Typography
              variant={TypographyVariants.h4}
              className={styles.jiraIntegrationCreate__title}
            >
              Jira
            </Typography>
          </div>
          <div className={styles.jiraIntegrationCreate__mapBlock}>
            <Typography
              variant={TypographyVariants.h4}
              className={styles.jiraIntegrationCreate__mapTitle}
            >
              Маппинг типов тикета
            </Typography>

            <div className={styles.jiraIntegrationCreate__mapItem}>
              <Select
                size={Size.xs}
                value={ticketTypeId}
                options={[]}
                disabled
                className={styles.jiraIntegrationCreate__selectType}
              />
              <div className={styles.jiraIntegrationCreate__mapSelectWrapper}>
                <ArrowHorizontalIcon
                  className={styles.jiraIntegrationCreate__mapIcon}
                />
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="jiraTicketType"
                  render={({ field }) => {
                    return (
                      <Select<string>
                        size={Size.xs}
                        mobileModalTitle="тип тикета"
                        className={styles.jiraIntegrationCreate__mapSelect}
                        options={jiraTicketTypesOptions}
                        disabled={isJiraTicketTypeDisabled}
                        onChange={(value) => {
                          fetchJiraStatuses(getValueFromValueType(value) || '');
                          field.onChange(value);
                        }}
                        value={field.value}
                        isTooltip={false}
                      />
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className={styles.jiraIntegrationCreate__mapBlock}>
            <Typography
              variant={TypographyVariants.h4}
              className={styles.jiraIntegrationCreate__mapTitle}
            >
              Маппинг статуса
            </Typography>
            <div
              className={cn(styles.jiraIntegrationCreate__mapWrapper, {
                [styles.jiraIntegrationCreate__mapWrapper_empty]:
                  !statusMapList?.length,
              })}
            >
              {statusesList}
            </div>
          </div>
          <div className={styles.jiraIntegrationCreate__mapBlock}>
            <Typography
              variant={TypographyVariants.h4}
              className={styles.jiraIntegrationCreate__mapTitle}
            >
              Маппинг приоритета
            </Typography>
            <div className={styles.jiraIntegrationCreate__mapWrapper}>
              {priorityMapList}
            </div>
          </div>
        </div>
      </div>
    </Create>
  );
};
