import cn from 'clsx';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import {
  Popover as PopoverComponent,
  PopoverProps as PopoverComponentProps,
} from 'react-tiny-popover';

import { More } from 'assets/icons';
import { callbackWithStopPropagation } from 'components';
import { getIsMobile, getIsMobileSmall } from 'core/ducks/selectors';
import { DoubleModal } from 'core/modals';

import { IconButton } from '../IconButton';

import styles from './Popover.module.scss';

interface PopoverProps
  extends Pick<
    PopoverComponentProps,
    'align' | 'padding' | 'positions' | 'containerClassName'
  > {
  isOpen?: boolean;
  children?: JSX.Element;
  content: JSX.Element;
  topModalContent?: JSX.Element;
  className?: string;
  popoverClassName?: string;
  togglePopover(): void;
  disabled?: boolean;
  classNameTopContent?: string;
  withOutsideClick?: boolean;
  elevated?: boolean;
}

export const Popover: FC<PopoverProps> = (props) => {
  const {
    disabled,
    togglePopover,
    popoverClassName,
    className,
    children = (
      <IconButton
        icon={<More />}
        appearance="flat"
        disabled={disabled}
        type="button"
        className={className}
        onClick={(e) => callbackWithStopPropagation(e, togglePopover)}
      />
    ),
    isOpen = false,
    content,
    topModalContent,
    containerClassName,
    padding = 20,
    align,
    positions,
    withOutsideClick = true,
    elevated,
  } = props;

  const isMobile = useSelector(getIsMobile);
  const isMobileSmall = useSelector(getIsMobileSmall);
  const isMobileAll = isMobile || isMobileSmall;

  if (isMobileAll) {
    return (
      <>
        {children}
        <DoubleModal
          {...props}
          isModal={isOpen}
          toggleModal={togglePopover}
          topContent={topModalContent}
          bottomContent={content}
        />
      </>
    );
  }

  return (
    <div className={cn(styles.popover, popoverClassName)}>
      <PopoverComponent
        isOpen={isOpen}
        positions={positions}
        padding={padding}
        align={align}
        containerClassName={cn(
          styles.popover__container,
          { [styles.popover__container_elevated]: elevated },
          containerClassName
        )}
        content={content}
        onClickOutside={withOutsideClick ? togglePopover : undefined}
      >
        <div>{children}</div>
      </PopoverComponent>
    </div>
  );
};
