import { EntityTypes } from 'core/types/notification';

export const getTitleNotification = (type: EntityTypes, count?: number) => {
  const titleByEntityType = {
    [EntityTypes.TICKET]: 'тикетов',
    [EntityTypes.SYSTEM]: 'систем',
    [EntityTypes.STORAGE]: 'хранилищ',
  };
  const titleBySingleCountType = {
    [EntityTypes.TICKET]: 'тикета',
    [EntityTypes.SYSTEM]: 'системы',
    [EntityTypes.STORAGE]: 'хранилища',
  };
  return count === 1 ? titleBySingleCountType[type] : titleByEntityType[type];
};
