import {
  AttachmentsRequest,
  DeleteFileAttachmentsTicketRequest,
} from 'features/Ticket/types';
import { Attachment, AttachmentsFiles } from 'types/models/attachment';

import {
  AddAttachmentsRequestAction,
  AddMessageAttachmentsAction,
  AddMessageAttachmentsPayload,
  Attachments,
  FetchContractAttachmentsRequestAction,
  FetchContractAttachmentsSuccessAction,
  FetchTicketAttachmentsRequestAction,
  FetchTicketAttachmentsSuccessAction,
  FetchTicketCommentsAttachmentsRequestAction,
  FetchTicketCommentsAttachmentsSuccessAction,
  HideAttachmentsLoadingAction,
  ResetAttachmentsStateAction,
  ShowAttachmentsLoadingAction,
} from './types';

export const showAttachmentsLoading = (): ShowAttachmentsLoadingAction => {
  return {
    type: Attachments.ATTACHMENTS_LOADING_SHOW,
  };
};

export const hideAttachmentsLoading = (): HideAttachmentsLoadingAction => {
  return {
    type: Attachments.ATTACHMENTS_LOADING_HIDE,
  };
};

export const fetchTicketAttachmentsRequest = (
  ticketId: string
): FetchTicketAttachmentsRequestAction => {
  return {
    type: Attachments.FETCH_TICKET_ATTACHMENTS_REQUEST,
    payload: ticketId,
  };
};

export const fetchContractAttachmentsSuccess = (
  attachments: Attachment[]
): FetchContractAttachmentsSuccessAction => {
  return {
    type: Attachments.FETCH_CONTRACT_ATTACHMENTS_SUCCESS,
    payload: attachments,
  };
};

export const fetchContractAttachmentsRequest =
  (): FetchContractAttachmentsRequestAction => {
    return {
      type: Attachments.FETCH_CONTRACT_ATTACHMENTS_REQUEST,
    };
  };

export const fetchTicketAttachmentsSuccess = (
  attachments: Attachment[]
): FetchTicketAttachmentsSuccessAction => {
  return {
    type: Attachments.FETCH_TICKET_ATTACHMENTS_SUCCESS,
    payload: attachments,
  };
};

export const addMessageAttachments = (
  payload: AddMessageAttachmentsPayload
): AddMessageAttachmentsAction => {
  return {
    type: Attachments.ADD_MESSAGE_ATTACHMENT_REQUEST,
    payload,
  };
};

export const fetchTicketCommentsAttachmentsRequest = (
  ticketId: string
): FetchTicketCommentsAttachmentsRequestAction => {
  return {
    type: Attachments.FETCH_TICKET_COMMENTS_ATTACHMENTS_REQUEST,
    payload: ticketId,
  };
};

export const fetchTicketCommentsAttachmentsSuccess = (
  attachments: AttachmentsFiles[]
): FetchTicketCommentsAttachmentsSuccessAction => {
  return {
    type: Attachments.FETCH_TICKET_COMMENTS_ATTACHMENTS_SUCCESS,
    payload: attachments,
  };
};

export const addAttachmentsRequest = (
  attachment: AttachmentsRequest
): AddAttachmentsRequestAction => {
  return {
    type: Attachments.ADD_ATTACHMENTS_REQUEST,
    payload: attachment,
  };
};

export const resetAttachmentsState = (): ResetAttachmentsStateAction => {
  return {
    type: Attachments.RESET_ATTACHMENTS_STATE,
  };
};

export const deleteFileAttachmentsTicketRequest = (
  payload: DeleteFileAttachmentsTicketRequest
) => {
  return {
    type: Attachments.DELETE_FILE_ATTACHMENT_TICKET_REQUEST,
    payload,
  };
};
