import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ActionList } from 'core/types/auth';
import { TableSystemsTypes } from 'core/types/system';
import { getIsAccessedAction } from 'features/Auth';
import {
  fetchContractsBySystemIdRequest,
  fetchSLAByContractIdBySystemIdRequestContracts,
  getCurrentContractId,
  getSystemsForContract,
  resetContractsState,
} from 'features/Contracts';
import { RouterHref } from 'routes/routerHref';
import { checkObjectIdentity } from 'utils';

import {
  deleteSystemRequest,
  fetchCurrentSystemRequest,
  fetchSystemsByContractId,
  fetchSystemsRequest,
  resetSLAByContractIdBySystemIdSystems,
  setCurrentOrganizationSystemId,
  setCurrentSystemId,
  setCurrentSystemsPage,
  setSelectedSystemSuccess,
  setSizePage,
  setSortSystems,
} from '../ducks/actions';
import {
  getPropsSystems,
  getSystem,
  getSystemId,
  getSystems,
  getSystemsFilter,
  getSystemsOrganization,
} from '../ducks/selectors';

export const fetchFunctions = {
  [TableSystemsTypes.FULL]: fetchSystemsRequest,
  [TableSystemsTypes.FROM_CONTRACTS]: fetchSystemsByContractId,
};

export const useSystemsTableContainer = (tableType: TableSystemsTypes) => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const { pageNum, pageSize, sort, totalElements, loadingSystem } =
    useSelector(getPropsSystems);
  const systemsOrganization = useSelector(getSystemsOrganization);
  const systems = useSelector(getSystems);
  const system = useSelector(getSystem);

  const currentSystemId = useSelector(getSystemId);
  const filterValues = useSelector(getSystemsFilter);
  const isAccessToCreateSystem = useSelector(
    getIsAccessedAction(ActionList.CreateSystem)
  );
  const isAccessToDeleteSystem = useSelector(
    getIsAccessedAction(ActionList.DeleteEntries)
  );
  const currentContractId = useSelector(getCurrentContractId);
  const systemsForContract = useSelector(getSystemsForContract);

  const [showFilterRow, setShowFilterRow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const toggleDeleteModal = () => {
    setShowDeleteModal((prev) => !prev);
  };

  const isFilterActive = !checkObjectIdentity(filterValues, {});

  const searchDisabled = !systems?.length || (showFilterRow && isFilterActive);

  const canCreateSystem: boolean = tableType === TableSystemsTypes.FULL;

  const getActiveId = () => {
    const keys = {
      [TableSystemsTypes.FULL]: '',
      [TableSystemsTypes.FROM_CONTRACTS]: currentContractId,
    };
    return keys[tableType];
  };

  useEffect(() => {
    if (showFilterRow) {
      setShowFilterRow(false);
    }
  }, [getActiveId()]);

  const toggleFilterRow = () => {
    setShowFilterRow((prevState) => !prevState);
  };

  const selectionSystemContract = (id: string) => {
    return systemsOrganization?.filter((item) =>
      item.id === id ? dispatch(setSelectedSystemSuccess(item)) : null
    );
  };

  const handleClickSystemAddContract = (value: string) => {
    dispatch(setCurrentOrganizationSystemId(value));
    selectionSystemContract(value);
  };

  const onRowClickFunctions = {
    [TableSystemsTypes.FULL]: (value: string) => {
      if (currentSystemId !== value) {
        dispatch(resetContractsState());
        dispatch(resetSLAByContractIdBySystemIdSystems());
        dispatch(setCurrentSystemId(value));
        dispatch(fetchCurrentSystemRequest(value));
        dispatch(fetchContractsBySystemIdRequest());
      }
    },
    [TableSystemsTypes.FROM_CONTRACTS]: (value: string) => {
      dispatch(setCurrentSystemId(value));
      dispatch(
        fetchSLAByContractIdBySystemIdRequestContracts({
          contractId: currentContractId,
          systemId: value,
        })
      );
      dispatch(fetchCurrentSystemRequest(value));
    },
  };

  const handleChangePage = (page: number) => {
    dispatch(setCurrentSystemsPage(page));
    dispatch(fetchFunctions[tableType]());
  };

  const handleChangePageSize = (arg: number) => {
    dispatch(setSizePage(arg));
  };

  const handleSort = (value: string) => {
    dispatch(setSortSystems(value));
    dispatch(fetchFunctions[tableType]());
  };

  const getCreateTitle = () => {
    const obj = {
      [TableSystemsTypes.FROM_CONTRACTS]: undefined,
      [TableSystemsTypes.FULL]: 'Создать',
    };
    return obj[tableType];
  };

  const handleCreate = () => {
    push(RouterHref.AdminCreateSystem);
  };

  const handleShowModalDelete = () => {
    if (system?.id) {
      setShowDeleteModal(true);
    }
  };

  const handleDelete = () => {
    if (system?.id) {
      dispatch(deleteSystemRequest(system?.id));
    }
  };
  return {
    methods: {
      handleDelete,
      toggleDeleteModal,
      toggleFilterRow,
      handleClickSystem: onRowClickFunctions[tableType],
      handleChangePage,
      handleChangePageSize,
      handleSort,
      handleShowModalDelete,
      handleClickSystemAddContract,
      getCreateTitle,
      handleCreate,
    },
    state: {
      pageNum,
      pageSize,
      sort,
      totalElements,
      systems,
      system,
      currentSystemId,
      isAccessToCreateSystem,
      isAccessToDeleteSystem,
      showFilterRow,
      showDeleteModal,
      isFilterActive,
      searchDisabled,
      canCreateSystem,
      systemsForContract,
      loadingSystem,
    },
  };
};
