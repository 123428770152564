import { User } from 'core/types/user';
import {
  DesktopFilters,
  SlaStatistic,
  TicketsStatistic,
} from 'features/Desktop/types';
import { ResponseWithMeta } from 'store/types';
import { ApiClient, getEnv } from 'utils';

import { endpoints } from './endpoints';

export const requests = {
  fetchSlaStatistic: (filterValues?: DesktopFilters) =>
    ApiClient.post<SlaStatistic>({
      baseURL: getEnv('REACT_APP_SLA_URL'),
      url: endpoints.getSlaStatistic(),
      data: filterValues,
    }),
  fetchEmployeesStatistic: (
    pageNum = 0,
    pageSize?: number,
    filterValues?: DesktopFilters
  ) =>
    ApiClient.post<ResponseWithMeta<User[]>>({
      url: endpoints.getEmployeesStatistic(),
      params: { pageNum, pageSize },
      data: filterValues,
    }),
  fetchTicketsStatistic: (filterValues?: DesktopFilters) =>
    ApiClient.post<TicketsStatistic>({
      baseURL: getEnv('REACT_APP_TICKET_REPORT_URL'),
      url: endpoints.getTicketsStatistic(),
      data: filterValues,
    }),
};
