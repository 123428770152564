import { convertFromDateFormat } from './convertFromDateFormat';
import { convertToDateFormat } from './convertToDateFormat';

export const checkInvalidDate = (value: string) => {
  const dateFrom = convertFromDateFormat(value);
  const dateTo = convertToDateFormat(value);

  if (!dateFrom || !dateTo) {
    return false;
  }

  return dateFrom > dateTo;
};
