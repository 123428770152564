import { CalendarIcon } from 'assets/icons';
import { IconBlock } from 'components';
import { CONTRACT_TYPE_TITLE_MAP } from 'core/constants';
import { Contract } from 'core/types';
import { ContractStatusBlock } from 'features/Contract';
import { getFormatDateWithoutTime } from 'utils';

export const getDataTable = (contracts?: Contract[]) =>
  contracts?.map((contract) => {
    const {
      title,
      type,
      status,
      id,
      organizationId,
      organizationTitle,
      description,
      number,
      endDate,
    } = contract;
    return {
      id,
      title: { title, to: `/admin/contracts/${id}` },
      organizationId: {
        title: organizationTitle,
        to: `/admin/organizations/${organizationId}`,
      },
      description,
      number,
      type: { text: CONTRACT_TYPE_TITLE_MAP[type] },
      status: <ContractStatusBlock status={status} />,
      endDate: (
        <IconBlock
          icon={
            <CalendarIcon
              style={{ color: 'var(--grey80)', minWidth: '14px' }}
            />
          }
          text={getFormatDateWithoutTime(endDate)}
        />
      ),
    };
  }) || [];
