import cn from 'clsx';
import DOMPurify from 'dompurify';
import { useSelector } from 'react-redux';

import { getArticle } from '../../ducks/selectors';

import styles from './SimilarArticleContent.module.scss';

export const SimilarArticleContent = () => {
  const article = useSelector(getArticle);

  return (
    <div
      className={cn('ck-content', styles.similarArticleContent)}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(article?.text || ''),
      }}
    />
  );
};
