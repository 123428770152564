import { createSelector } from 'reselect';

import { Article } from 'core/types/knowledgeBase';
import { RootState } from 'store/rootReducer';

export const getIsModal = (state: RootState) => state.userAssistance.isModal;
export const getArticles = (state: RootState) => state.userAssistance.articles;
export const getArticlesLoading = (state: RootState) =>
  state.userAssistance.articlesLoading;
export const getArticle = (state: RootState) => state.userAssistance.article;
export const getArticlesAll = (state: RootState) =>
  state.userAssistance.articlesAll;
export const getArticlesAllLoading = (state: RootState) =>
  state.userAssistance.articlesAllLoading;
export const getArticlesAllUniq = createSelector<
  RootState,
  Article[],
  Article[],
  Article[]
>([getArticles, getArticlesAll], (articles, articlesAll) => {
  return articlesAll.filter(
    (articleAll) => !articles?.some((article) => articleAll.id === article.id)
  );
});
export const getSelectedArticlesIds = (state: RootState) =>
  state.userAssistance.selectedArticlesIds;
export const getArticleLoading = (state: RootState) =>
  state.userAssistance.articleLoading;
