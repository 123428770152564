import { User } from 'features/User';
import { UserLayout } from 'features/User/layouts';

import styles from './User.module.scss';

const UserPage = () => {
  return (
    <UserLayout>
      <User className={styles.user__content} />
    </UserLayout>
  );
};

export default UserPage;
