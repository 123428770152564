import {
  HasUncheckedNotificationsType,
  ProfileTabSettings,
} from 'features/Profile/types';

export const getTabsWithStatusesProfile = (
  tabs: ProfileTabSettings[],
  statuses: HasUncheckedNotificationsType[]
) => {
  return tabs.map((item) => {
    const statusForUnite = statuses.find(
      (status: HasUncheckedNotificationsType) =>
        status.notificationType === item.titleNotification ||
        (status.notificationType === null &&
          item.titleNotification?.toLocaleLowerCase() === 'all')
    );
    return {
      ...item,
      ...statusForUnite,
    };
  });
};
