import { MainLayout } from 'core/layouts';
import { Header } from 'features/Desktop/components';
import { DesktopHistory as DesktopHistoryContent } from 'features/Desktop/views';

import styles from './DesktopHistory.module.scss';

const DesktopHistory = () => {
  return (
    <MainLayout customHeader={<Header />}>
      <DesktopHistoryContent className={styles.desktopHistory__content} />
    </MainLayout>
  );
};

export default DesktopHistory;
