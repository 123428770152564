import {
  call,
  put,
  select,
  StrictEffect,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { ISettingFields } from 'components';
import { setAlert } from 'core/ducks/actions';
import { getIsMobile, getIsMobileSmall } from 'core/ducks/selectors';
import { createErrorAlert, createSuccessAlert } from 'core/layouts';
import { TicketType } from 'core/types';
import { Action } from 'core/types/action';
import { Ticket } from 'core/types/ticket';
import { getIsAdmin, getUserId } from 'features/Auth';
import {
  getFilterValues,
  getPageNum,
  getPageSize,
  getSort,
  getTickets,
} from 'features/Tickets';
import { ResponseWithMeta } from 'store/types';
import { createError } from 'utils';

import {
  fetchTicketStatusSuccessMap,
  fetchTicketTypeSuccessMap,
} from '../constants';
import { FilterData } from '../types';
import { getPrepareFilterTicketsToRequest } from '../utils';

import {
  createTicketsSettingFieldSuccess,
  fetchActionsForCurrentTicketSuccess,
  fetchFiltersDataSuccess,
  fetchTicketsCountByContractIdSuccess,
  fetchTicketsRequest,
  fetchTicketsSettingFieldSuccess,
  fetchTicketsSuccess,
  hideTicketLoading,
  hideTicketsLoading,
  resetCurrentTicket,
  setCurrentFilterId,
  setCurrentTicket,
  setCurrentTicketId,
  setIsUsedFilter,
  showTicketLoading,
  showTicketsLoading,
  updateTicketByIdSuccess,
} from './actions';
import { request } from './api/requests';
import {
  AddSpecAndSetToWorkAction,
  CreateFilterRequestAction,
  CreateTicketsSettingFieldsRequestAction,
  DeleteFilterRequestAction,
  DeleteTicketRequestAction,
  FetchActionsForCurrentTicketRequestAction,
  FetchFiltersDataRequestAction,
  FetchOpenTicketsCountByContactIdRequestAction,
  FetchTicketRequestAction,
  FetchTicketsRequestAction,
  FetchTicketStatusRequestAction,
  FetchTicketTypesRequestAction,
  SetCurrentFilterTitleRequestAction,
  Tickets,
  UpdateTicketByIdRequestAction,
} from './types';

function* fetchCurrentTicketActions({
  payload,
}: FetchActionsForCurrentTicketRequestAction) {
  try {
    const ticketActionsList: Action[] = yield call(
      request.fetchCurrentTicketActions,
      payload
    );
    yield put(fetchActionsForCurrentTicketSuccess(ticketActionsList));
  } catch (e) {
    createError(e);
  }
}

function* ticketFetch({ payload }: FetchTicketRequestAction) {
  try {
    yield put(showTicketLoading());
    const ticket: Ticket = yield call(request.fetchTicket, payload);
    const ticketWorkGroups = ticket?.workGroups.map(
      (workGroup) => workGroup.id
    );
    yield call(fetchCurrentTicketActions, {
      payload: ticketWorkGroups,
      type: Tickets.FETCH_ACTIONS_FOR_CURRENT_TICKET_REQUEST,
    });
    yield put(setCurrentTicket(ticket));
    yield put(hideTicketLoading());
  } catch (e) {
    yield put(hideTicketLoading());
    createError(e);
  }
}

function* ticketsFetch({ payload }: FetchTicketsRequestAction) {
  try {
    const { withFirstTicketFetch, ticketTab } = payload;

    const currentPage: ReturnType<typeof getPageNum> = yield select(getPageNum);
    const pageSize: ReturnType<typeof getPageSize> = yield select(getPageSize);
    const sort: ReturnType<typeof getSort> = yield select(getSort);
    const filter: ReturnType<typeof getFilterValues> = yield select(
      getFilterValues
    );
    const isMobile: ReturnType<typeof getIsMobile> = yield select(getIsMobile);
    const isMobileSmall: ReturnType<typeof getIsMobileSmall> = yield select(
      getIsMobileSmall
    );
    const isAdmin: ReturnType<typeof getIsAdmin> = yield select(getIsAdmin);
    const userId: ReturnType<typeof getUserId> = yield select(getUserId);

    const isMobileAll = isMobile || isMobileSmall;

    yield put(showTicketsLoading());
    const tickets: ResponseWithMeta<Ticket[]> = yield call(
      request.fetchTickets,
      {
        pageNum: currentPage,
        pageSize,
        sort,
        filterValues: getPrepareFilterTicketsToRequest({
          filter,
          isAdmin,
          ticketTab,
          userId,
        }),
        ticketTab,
      }
    );
    yield put(fetchTicketsSuccess(tickets));

    yield put(hideTicketsLoading());

    if (tickets.content?.length && !isMobileAll && withFirstTicketFetch) {
      const firstTicketId = tickets?.content[0]?.id;
      if (firstTicketId) {
        yield put(setCurrentTicketId(firstTicketId));
        yield call(ticketFetch, {
          payload: firstTicketId,
          type: Tickets.FETCH_TICKET_REQUEST,
        });
      }
    }
  } catch (e) {
    createError(e);
    yield put(hideTicketsLoading());
  }
}

function* addSpecAndSetToWork({
  payload: {
    ticketId,
    specialistId,
    withTicketsUpdate = false,
    isTicketNew = true,
  },
}: AddSpecAndSetToWorkAction) {
  try {
    if (specialistId) {
      yield call(request.addSpecialist, ticketId, specialistId);
    }
    if (isTicketNew) {
      yield call(request.setTicketToWork, ticketId);
    }
    yield call(ticketFetch, {
      payload: ticketId[0],
      type: Tickets.FETCH_TICKET_REQUEST,
    });
    if (withTicketsUpdate) {
      yield put(fetchTicketsRequest({}));
    }
  } catch (e) {
    createError(e);
  }
}

function* openTicketsCountByContractIdFetch({
  payload,
}: FetchOpenTicketsCountByContactIdRequestAction) {
  try {
    const openTicketsCount: number = yield call(
      request.fetchOpenTicketsCountByContractId,
      payload
    );
    yield put(fetchTicketsCountByContractIdSuccess(openTicketsCount));
  } catch (e) {
    createError(e);
  }
}

function* updateTicketById({ payload }: UpdateTicketByIdRequestAction) {
  try {
    const tickets: ReturnType<typeof getTickets> = yield select(getTickets);

    const outdatedTicket = tickets?.find((ticket) => ticket.id === payload);

    if (outdatedTicket) {
      const ticket: Ticket = yield call(request.fetchTicket, payload);
      yield put(updateTicketByIdSuccess(ticket));
    }
  } catch (e) {
    createError(e);
  }
}

function* deleteTicket({ payload }: DeleteTicketRequestAction) {
  try {
    if (payload?.id) {
      yield call(request.deleteTicket, payload.id);
      yield put(
        setAlert(
          createSuccessAlert(
            `Тикет по системе "${payload.systemId?.value}"  успешно удален`
          )
        )
      );
      yield put(resetCurrentTicket());
      yield call(ticketsFetch, {
        type: Tickets.FETCH_TICKETS_REQUEST,
        payload: { withFirstTicketFetch: true },
      });
    }
  } catch (e) {
    yield put(
      setAlert(
        createErrorAlert(
          `Произошла ошибка при удалении тикета по системе "${payload.systemId?.value}"`
        )
      )
    );
    createError(e);
  }
}

function* statusesFetch({ payload }: FetchTicketStatusRequestAction) {
  try {
    const { pageNum, pageSize, sort, filter, searchValue, updateType } =
      payload;
    const metaStatuses: ResponseWithMeta<string[]> = yield call(
      request.fetchFilterStatuses,
      {
        pageNum,
        pageSize,
        sort,
        ticketTab: filter?.ticketTab,
        filter: { name: searchValue },
      }
    );
    if (updateType) {
      const fetchStatusSuccess = fetchTicketStatusSuccessMap[updateType];
      yield put(fetchStatusSuccess(metaStatuses));
    }
  } catch (e) {
    createError(e);
  }
}

function* filtersDataFetch({ payload }: FetchFiltersDataRequestAction) {
  try {
    const data: FilterData[] = yield call(request.fetchFiltersData, payload);
    yield put(fetchFiltersDataSuccess(data));
  } catch (e) {
    createError(e);
  }
}

function* createFilter({ payload }: CreateFilterRequestAction) {
  try {
    const { ticketTab } = payload;

    yield call(request.createFilter, payload);

    const data: FilterData[] = yield call(request.fetchFiltersData, ticketTab);
    yield put(fetchFiltersDataSuccess(data));

    yield put(setIsUsedFilter(false));

    const { id } = data[data.length - 1];

    yield put(setCurrentFilterId(id));
  } catch (error) {
    createError(error);
  }
}

function* deleteFilter({ payload }: DeleteFilterRequestAction) {
  try {
    const { id, ticketTab } = payload;

    yield call(request.deleteFilter, id);

    const data: FilterData[] = yield call(request.fetchFiltersData, ticketTab);
    yield put(fetchFiltersDataSuccess(data));
  } catch (error) {
    createError(error);
  }
}

function* setFilterTitle({ payload }: SetCurrentFilterTitleRequestAction) {
  try {
    const { id, title, ticketTab } = payload;
    yield call(request.setFilterTitle, id, title);

    const data: FilterData[] = yield call(request.fetchFiltersData, ticketTab);
    yield put(fetchFiltersDataSuccess(data));
  } catch (error) {
    createError(error);
  }
}

function* fetchActualTicketsTableConfig() {
  try {
    const config: ISettingFields = yield call(
      request.fetchActualTicketsTableConfig
    );
    yield put(fetchTicketsSettingFieldSuccess(config));
  } catch (e) {
    createError(e);
  }
}

function* fetchTicketsSettingFields() {
  try {
    const config: ISettingFields = yield call(request.fetchTicketsTableConfig);
    yield put(fetchTicketsSettingFieldSuccess(config));
    yield call(fetchActualTicketsTableConfig);
  } catch (e) {
    createError(e);
  }
}

function* createTicketsSettingFields({
  payload,
}: CreateTicketsSettingFieldsRequestAction) {
  try {
    yield put(showTicketsLoading());
    const config: ISettingFields = yield call(
      request.createTicketsTableConfig,
      payload
    );
    yield put(createTicketsSettingFieldSuccess(config));
  } catch (e) {
    createError(e);
  } finally {
    yield put(hideTicketsLoading());
  }
}

function* fetchFilterTypes({ payload }: FetchTicketTypesRequestAction) {
  const { pageNum, pageSize, sort, filter, searchValue, updateType } = payload;
  try {
    const metaTypes: ResponseWithMeta<TicketType[]> = yield call(
      request.fetchFilterTypes,
      {
        pageNum,
        pageSize,
        sort,
        ticketTab: filter?.ticketTab,
        filter: { title: searchValue },
      }
    );
    if (updateType) {
      const fetchTypeSuccess = fetchTicketTypeSuccessMap[updateType];
      yield put(fetchTypeSuccess(metaTypes));
    }
  } catch (e) {
    createError(e);
  }
}

export function* ticketsSaga(): Generator<StrictEffect> {
  yield takeEvery(Tickets.FETCH_TICKETS_REQUEST, ticketsFetch);
  yield takeLatest(Tickets.FETCH_TICKET_REQUEST, ticketFetch);
  yield takeEvery(Tickets.ADD_SPEC_AND_SET_TO_WORK, addSpecAndSetToWork);
  yield takeEvery(
    Tickets.FETCH_OPEN_TICKETS_COUNT_BY_CONTRACT_ID_REQUEST,
    openTicketsCountByContractIdFetch
  );
  yield takeEvery(Tickets.UPDATE_TICKET_BY_ID_REQUEST, updateTicketById);
  yield takeEvery(
    Tickets.FETCH_ACTIONS_FOR_CURRENT_TICKET_REQUEST,
    fetchCurrentTicketActions
  );
  yield takeEvery(Tickets.DELETE_TICKET_REQUEST, deleteTicket);
  yield takeEvery(Tickets.FETCH_TICKET_STATUS_REQUEST, statusesFetch);
  yield takeEvery(Tickets.FETCH_FILTERS_DATA_REQUEST, filtersDataFetch);
  yield takeEvery(Tickets.CREATE_FILTER_REQUEST, createFilter);
  yield takeEvery(Tickets.DELETE_FILTER_REQUEST, deleteFilter);
  yield takeEvery(Tickets.SET_CURRENT_FILTER_TITLE_REQUEST, setFilterTitle);
  yield takeEvery(
    Tickets.FETCH_TICKETS_SETTING_FIELDS_REQUEST,
    fetchTicketsSettingFields
  );
  yield takeEvery(
    Tickets.FETCH_ACTUAL_TICKETS_SETTING_FIELDS_REQUEST,
    fetchActualTicketsTableConfig
  );
  yield takeEvery(
    Tickets.CREATE_TICKETS_SETTING_FIELDS_REQUEST,
    createTicketsSettingFields
  );
  yield takeEvery(Tickets.FETCH_TICKET_TYPES_REQUEST, fetchFilterTypes);
}
