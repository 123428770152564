import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  EmployeesStatistic,
  SlaStatistic,
  TicketsStatistic,
} from '../../types';

interface EmployeesState {
  slaStatistic?: SlaStatistic;
  ticketsStatistic?: TicketsStatistic;
  employeesStatistic?: EmployeesStatistic;
  totalEmployees?: number;
  isLoading?: boolean;
  pageNum?: number;
  pageSize?: number;
}

const initialState: EmployeesState = {
  pageNum: 0,
  pageSize: 10,
  isLoading: false,
};

const employeesSlice = createSlice({
  name: 'desktopEmployees',
  initialState,
  reducers: {
    fetchSlaStatisticSuccess: (state, action: PayloadAction<SlaStatistic>) => {
      state.slaStatistic = action.payload;
    },
    fetchSlaStatisticRequest: (_state) => {},
    fetchEmployeesStatisticSuccess: (
      state,
      action: PayloadAction<EmployeesStatistic>
    ) => {
      state.employeesStatistic = action.payload;
      state.totalEmployees = action.payload.totalElements;
    },
    fetchEmployeesStatisticRequest: (_state) => {},
    fetchTicketsStatisticSuccess: (
      state,
      action: PayloadAction<TicketsStatistic>
    ) => {
      state.ticketsStatistic = action.payload;
    },
    fetchTicketsStatisticRequest: (_state) => {},
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setPageNumEmployees: (state, action: PayloadAction<number | undefined>) => {
      state.pageNum = action.payload;
    },
    setPageSizeEmployees: (
      state,
      action: PayloadAction<number | undefined>
    ) => {
      state.pageSize = action.payload;
    },
    resetEmployeesState: () => initialState,
  },
});

export const {
  fetchSlaStatisticSuccess,
  fetchEmployeesStatisticRequest,
  fetchSlaStatisticRequest,
  fetchTicketsStatisticRequest,
  fetchEmployeesStatisticSuccess,
  fetchTicketsStatisticSuccess,
  resetEmployeesState,
  setPageNumEmployees,
  setPageSizeEmployees,
  setIsLoading,
} = employeesSlice.actions;
export const desktopEmployees = employeesSlice.reducer;
