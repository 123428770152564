import { Article } from 'core/types/knowledgeBase';
import { ResponseWithMeta } from 'store/types';
import { ApiClient, getEnv } from 'utils';

import { endpoints } from './endpoints';

export const requests = {
  fetchArticles: (routeTitle?: string) =>
    ApiClient.get<ResponseWithMeta<Article[]>>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.getArticles(),
      params: { pageNum: 0, pageSize: 999, routeTitle },
    }),
  fetchArticle: (id: string) =>
    ApiClient.get<Article>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.getArticle(id),
    }),
  manageArticles: (show: boolean, data: string[], routeTitle?: string) =>
    ApiClient.patch<boolean>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.manageArticles(),
      data,
      params: { show, routeTitle },
    }),
};
