import { ValueType } from 'components';
import { Contract, ContractNotificationConfig, ContractType } from 'core/types';

export enum TableContractsTypes {
  FULL = 'FULL',
  FROM_ORGANIZATIONS = 'FROM_ORGANIZATIONS',
  FROM_SYSTEMS = 'FROM_SYSTEMS',
}

export type ContractDetailPayload = {
  contractId?: string;
  systemId?: string;
  name?: string;
};

export interface CreateContractData
  extends Pick<
    Contract,
    | 'id'
    | 'title'
    | 'number'
    | 'description'
    | 'openDate'
    | 'endDate'
    | 'status'
    | 'supportType'
  > {
  organizationId?: ValueType<string>;
  type?: ValueType<ContractType>;
  partnerOrganizationId?: ValueType<string>;
  notificationConfig: ContractNotificationConfig;
}

export type ContractIdSystemIdArgs = {
  contractId: string;
  systemId: string;
};

export enum ValidDateType {
  NO_DATA = 'NO_DATA',
  BEYOND_CONTRACT_START_DATE = 'VALID_BEYOND_CONTRACT_START_DATE',
  BEYOND_CONTRACT_END_DATE = 'VALID_BEYOND_CONTRACT_END_DATE',
  END_AFTER_START_DATE = 'END_AFTER_START_DATE',
}

export const validSupplementaryAgreementMap = {
  [ValidDateType.NO_DATA]: '',
  [ValidDateType.BEYOND_CONTRACT_START_DATE]:
    'Выходит за рамки сроков контракта',
  [ValidDateType.BEYOND_CONTRACT_END_DATE]: 'Выходит за рамки сроков контракта',
  [ValidDateType.END_AFTER_START_DATE]:
    'Дата окончания не может быть раньше даты начала',
};

export interface SystemsByContractIdPayload {
  id?: string;
  withDeletedSystems?: boolean;
}
