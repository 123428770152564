import { ChangeEvent, FC } from 'react';
import { Link } from 'react-router-dom';

import { StorageIcon } from 'assets/icons';
import {
  Checkbox,
  ConditionBlock,
  Tooltip,
  Typography,
  TypographyVariants,
} from 'components';
import { Storage as StorageItem } from 'core/types/storage';
import { SLAProgressBar } from 'features/SLA';

import { getUsedMemoryPercents } from '../../utils';

import styles from './Storage.module.scss';

interface StorageProps extends StorageItem {
  handleChangeCheckbox: (event: ChangeEvent<HTMLInputElement>) => void;
  selectedStorages: string[];
}

export const Storage: FC<StorageProps> = ({
  id,
  title,
  organizations,
  size,
  usedSpace,
  handleChangeCheckbox,
  selectedStorages,
}) => {
  return (
    <div className={styles.storage__container}>
      <div className={styles.storage__checkboxContainer}>
        <Checkbox
          name={title}
          id={id}
          onChange={handleChangeCheckbox}
          checked={selectedStorages.includes(id)}
          className={styles.storage__checkbox}
        />
      </div>
      <Link to={`/storages/${id}`} className={styles.storage}>
        <StorageIcon className={styles.storage__icon} />
        <div className={styles.storage__infoContainer}>
          <Typography
            variant={TypographyVariants.h4}
            className={styles.storage__infoNumber}
          >
            {title}
          </Typography>
          <div className={styles.storage__infoOrganizationContainer}>
            <Typography
              variant={TypographyVariants.b3}
              className={styles.storage__infoOrganization}
            >
              {organizations[0].value}
            </Typography>
            <div data-tip data-for={id}>
              {organizations.length >= 2 && (
                <ConditionBlock
                  text={`+${organizations ? organizations?.length - 1 : 0}`}
                />
              )}
              <Tooltip id={id} place="right">
                {organizations?.slice(1).map(({ value }) => (
                  <Typography variant={TypographyVariants.b3}>
                    {value}
                  </Typography>
                ))}
              </Tooltip>
            </div>
          </div>
        </div>
        <SLAProgressBar
          expirePercents={getUsedMemoryPercents(size, Number(usedSpace))}
          className={styles.storage__progressBar}
          classNameLoaded={styles.storage__progressBarLoader}
        />
        <Typography
          variant={TypographyVariants.h3}
          className={styles.storage__infoUsed}
        >
          {usedSpace} из {size}гб
        </Typography>
      </Link>
    </div>
  );
};
