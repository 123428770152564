import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { checkObjectIdentity } from 'utils';

import {
  deleteJiraIntegration,
  fetchJiraIntegrationRequest,
  fetchJiraIntegrationsRequest,
  setCurrentJiraIntegrationId,
  setCurrentJiraIntegrationsPage,
  setCurrentSystemId,
  setSizeJiraIntegrationsPage,
  setSortJiraIntegrations,
} from '../ducks/actions';
import {
  getCurrentJiraIntegrationId,
  getJiraIntegration,
  getJiraIntegrations,
  getJiraIntegrationsFilter,
  getPropsJiraIntegrations,
} from '../ducks/selectors';

export const useJiraIntegrationsTableContainer = () => {
  const dispatch = useDispatch();

  const { pageNum, pageSize, sort, totalElements, loading } = useSelector(
    getPropsJiraIntegrations
  );
  const jiraIntegrations = useSelector(getJiraIntegrations);
  const jiraIntegration = useSelector(getJiraIntegration);
  const filterValues = useSelector(getJiraIntegrationsFilter);
  const currentJiraIntegrationId = useSelector(getCurrentJiraIntegrationId);

  const [showFilterRow, setShowFilterRow] = useState(false);
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const toggleDeleteModal = () => {
    setShowDeleteModal((prev) => !prev);
  };

  const handleShowModalDelete = () => {
    if (currentJiraIntegrationId) {
      setShowDeleteModal(true);
    }
  };

  const toggleIsOpenCreateModal = () => {
    setIsOpenCreateModal((prevState) => !prevState);
  };

  const isFilterActive = !checkObjectIdentity(filterValues, {});

  const searchDisabled =
    !jiraIntegrations?.length || (showFilterRow && isFilterActive);

  const toggleFilterRow = () => {
    setShowFilterRow((prevState) => !prevState);
  };

  const handleDelete = () => {
    if (currentJiraIntegrationId) {
      dispatch(deleteJiraIntegration(currentJiraIntegrationId));
    }
  };

  const handleClickRow = (value?: string) => {
    dispatch(setCurrentJiraIntegrationId(value));
    const systemId = jiraIntegrations?.find(
      (integration) => integration.id === value
    )?.systemId;
    dispatch(setCurrentSystemId(systemId));
    if (value) {
      dispatch(fetchJiraIntegrationRequest(value));
    }
  };

  const handleChangePage = (page: number) => {
    dispatch(setCurrentJiraIntegrationsPage(page));
    dispatch(fetchJiraIntegrationsRequest());
  };

  const handleChangePageSize = (arg: number) => {
    dispatch(setSizeJiraIntegrationsPage(arg));
  };

  const handleSort = (value: string) => {
    dispatch(setSortJiraIntegrations(value));
    dispatch(fetchJiraIntegrationsRequest());
  };

  return {
    methods: {
      toggleFilterRow,
      handleChangePage,
      handleChangePageSize,
      handleSort,
      toggleIsOpenCreateModal,
      handleClickRow,
      toggleDeleteModal,
      handleDelete,
      handleShowModalDelete,
    },
    state: {
      showFilterRow,
      isFilterActive,
      searchDisabled,
      pageNum,
      pageSize,
      sort,
      totalElements,
      jiraIntegrations,
      isOpenCreateModal,
      currentJiraIntegrationId,
      jiraIntegration,
      showDeleteModal,
      loading,
    },
  };
};
