import { Article } from 'core/types/knowledgeBase';
import { Ticket } from 'core/types/ticket';
import { ResponseWithMeta } from 'store/types';
import { Attachment, ParentType } from 'types/models/attachment';
import { ApiClient, getEnv } from 'utils';

import { SearchData } from '../../types';

import { endpoints } from './endpoints';

export const request = {
  fetchTickets: (searchData: SearchData) =>
    ApiClient.post<ResponseWithMeta<Ticket[]>>({
      url: endpoints.getTickets(),
      params: { pageNum: 0, pageSize: 1000 },
      data: searchData,
    }),
  fetchArticles: (searchData: SearchData) =>
    ApiClient.post<ResponseWithMeta<Article[]>>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.getArticles(),
      params: { pageNum: 0, pageSize: 1000 },
      data: searchData,
    }),
  fetchTicket: (id: string) =>
    ApiClient.get<Ticket>({
      url: endpoints.getTicket(id),
    }),
  fetchArticle: (id: string) =>
    ApiClient.get<Article>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.getArticle(id),
    }),
  fetchTicketAttachments: (ticketId: string) =>
    ApiClient.get<Attachment[]>({
      baseURL: getEnv('REACT_APP_ATTACHMENT_URL'),
      url: endpoints.getTicketAttachments(),
      params: {
        parent: ParentType.TICKET,
        id: ticketId,
      },
    }),
};
