import cn from 'clsx';
import { FC, HTMLAttributes, SVGProps } from 'react';

import { Typography, TypographyVariants } from 'components';

import styles from './IconBlock.module.scss';

export interface IconBlockProps extends HTMLAttributes<HTMLDivElement> {
  icon?: SVGProps<SVGElement>;
  text?: string;
  classNameText?: string;
}

export const IconBlock: FC<IconBlockProps> = ({
  icon,
  className,
  text,
  children,
  classNameText,
  ...otherProps
}) => {
  return (
    <div className={cn(styles.iconBlock, className)} {...otherProps}>
      {icon}
      <Typography
        variant={TypographyVariants.b3}
        className={cn(styles.iconBlock__title, classNameText)}
      >
        {text || children}
      </Typography>
    </div>
  );
};
