import { Selector } from 'react-redux';
import { createSelector } from 'reselect';

import { CustomFieldType, getReverseDateFromCustomField } from 'components';
import { CustomFieldToRequest, TicketType } from 'core/types';
import { Action } from 'core/types/action';
import { ActionList } from 'core/types/auth';
import { createSelectOption } from 'core/utils';
import { CustomFieldTicket, TicketHistory } from 'features/Ticket';
import { RootState } from 'store/rootReducer';

export const getLoading = (state: RootState) => state.ticket?.loading;
export const getTicketHistoryLoading = (state: RootState) =>
  state.ticket?.historyLoading;
export const getTicket = (state: RootState) => state.ticket?.ticket;
export const getTicketSystem = (state: RootState) => state.ticket?.ticketSystem;
export const getTicketWorkGroups = (state: RootState) =>
  state.ticket.ticket?.workGroups;
export const getSystemId = (state: RootState) => state.ticket.ticket?.systemId;
export const getHistory = (state: RootState): TicketHistory[] | undefined =>
  state.ticket?.history;
export const getIsError = (state: RootState) => state.ticket.isError;
export const getIsSuccess = (state: RootState) => state.ticket.isSuccess;
export const getTicketActionsList = (state: RootState) =>
  state.ticket.ticketActionsList;
export const getIsSystemIntegratedWithJira = (state: RootState) =>
  state.ticket.isSystemIntegratedWithJira;
export const getJiraUrl = (state: RootState) => state.ticket.jiraUrl;
export const getSpecialistTicketCustomFields = (state: RootState) =>
  state.ticket.specialistCustomFields || [];
export const getClientTicketCustomFields = (state: RootState) =>
  state.ticket.clientCustomFields || [];
export const getNextStatuses = (state: RootState) => state.ticket.nextStatuses;
export const getTicketDeleted = (state: RootState) =>
  state.ticket.ticket?.isDeleted;
export const getClientInfoOrgTypeFromTicket = (state: RootState) =>
  state.ticket.ticket?.clientInfo?.organizationType;
export const getClientInfo = (state: RootState) =>
  state.ticket.ticket?.clientInfo;
export const getTicketTypes = (state: RootState): TicketType[] =>
  state.ticket.ticketTypes || [];
export const getStatuses = (state: RootState) => state.ticket.statuses;
export const getToastMessage = (state: RootState) => state.ticket.toastMessage;
export const getCustomFields = (state: RootState) =>
  state.ticket.customFields ?? [];

export const getIsAccessedTicketAction = (action: ActionList) =>
  createSelector<RootState, Action[] | undefined, boolean>(
    [getTicketActionsList],
    (ticketActions): boolean => {
      return !!ticketActions?.find(
        (ticketAction) => ticketAction.title === action
      );
    }
  );

export const getClientCustomFields = createSelector<
  RootState,
  CustomFieldToRequest[],
  CustomFieldToRequest[]
>([getClientTicketCustomFields], (customFields) => {
  return customFields.map((customField) => {
    if (
      customField.customFieldType === CustomFieldType.FIELD_DATE &&
      customField.date
    ) {
      getReverseDateFromCustomField(customField.date);
    }
    return customField;
  });
});

export const createCustomTicketInfoFieldsSelector = (
  getFieldsSelector: Selector<RootState, CustomFieldToRequest[]>
) => {
  return createSelector<
    RootState,
    CustomFieldToRequest[],
    CustomFieldToRequest[],
    CustomFieldTicket[]
  >(
    [getFieldsSelector, getCustomFields],
    (customFieldsBySystem, customFields) => {
      return customFieldsBySystem.map((customField) => {
        const { customFieldId, listValue, customFieldType } = customField;
        const customFieldBySystem = customFields.find(
          (field) => field.id === customFieldId
        );

        const listValueOption =
          customFieldType === CustomFieldType.FIELD_LIST
            ? createSelectOption(listValue)
            : null;

        return {
          ...customField,
          listValue: listValueOption,
          values: customFieldBySystem?.values,
        };
      });
    }
  );
};

export const getClientTicketInfoCustomFields =
  createCustomTicketInfoFieldsSelector(getClientTicketCustomFields);

export const getSpecialistTicketInfoCustomFields =
  createCustomTicketInfoFieldsSelector(getSpecialistTicketCustomFields);
