import cn from 'clsx';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { CloseIcon } from 'assets/icons';
import { deleteAlert } from 'core/ducks/actions';
import { Alert, AlertTypes } from 'core/types';

import styles from './AlertItem.module.scss';

interface AlertItemProps {
  className?: string;
  classNameText?: string;
  alert: Alert;
  onClose?: () => void;
  timeout?: number;
}

const classNames = {
  [AlertTypes.SUCCESS]: {
    className: styles.alertItem_success,
    classNameBorder: styles.alertItem__border_success,
    classNameIcon: styles.alertItem__icon_success,
  },
  [AlertTypes.ERROR]: {
    className: styles.alertItem_error,
    classNameBorder: styles.alertItem__border_error,
    classNameIcon: styles.alertItem__icon_error,
  },
  [AlertTypes.WARNING]: {
    className: styles.alertItem_warning,
    classNameBorder: styles.alertItem__border_warning,
    classNameIcon: styles.alertItem__icon_warning,
  },
};

export const AlertItem: FC<AlertItemProps> = ({
  className,
  classNameText,
  alert,
  onClose,
  timeout: time,
}) => {
  const dispatch = useDispatch();

  const handleDeleteAlert = () => {
    dispatch(deleteAlert(alert.id));

    onClose?.();
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (time) {
      timeout = setTimeout(handleDeleteAlert, time);
    }
    return () => clearTimeout(timeout);
  }, [time]);

  return (
    <div
      className={cn(
        styles.alertItem,
        classNames[alert.type].className,
        className
      )}
    >
      <div
        className={cn(
          styles.alertItem__border,
          classNames[alert.type].classNameBorder
        )}
      />
      <span className={cn(styles.alertItem__message, classNameText)}>
        {alert.message}
      </span>
      <CloseIcon
        className={cn(
          styles.alertItem__icon,
          classNames[alert.type].classNameIcon
        )}
        onClick={handleDeleteAlert}
      />
    </div>
  );
};
