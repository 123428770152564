import { ProfileTelegram } from 'features/Profile/components';
import { ProfileLayout } from 'features/Profile/layouts';

const ProfileTelegramPage = () => (
  <ProfileLayout>
    <ProfileTelegram />
  </ProfileLayout>
);

export default ProfileTelegramPage;
