import { Reducer } from 'redux';

import { DEFAULT_PAGINATION_PARAMS } from 'constants/meta';
import { NotificationContent } from 'core/types/notification';
import { ReducerMap } from 'store/types';
import { KeyValueOption, Pagination } from 'types/models/meta';

import {
  FilterNotificationsProfile,
  HasUncheckedNotificationsType,
  News,
  ProfileSettings,
  ProfileTabSettings,
} from '../types';

import {
  CheckHasUnpublishedNewsSuccessAction,
  CheckNewsSuccessAction,
  FetchCurrentNewsSuccessAction,
  FetchIsUncheckedNewsSuccessAction,
  FetchNewsJoinSuccessAction,
  FetchNewsSuccessAction,
  FetchNotificationSettingsSuccessAction,
  FetchNotificationsProfileJoinSuccessAction,
  FetchNotificationsProfileSuccessAction,
  FetchNotificationTabsSuccessAction,
  FetchOrganizationsSuccessAction,
  FetchWorkGroupsSuccessAction,
  NotificationsProfile,
  NotificationsProfileActionsTypes,
  SetCheckedNotificationSuccessAction,
  SetCurrentNewsPageAction,
  SetCurrentNotificationsProfilePageAction,
  SetFilterNotificationsProfileAction,
  SetIsEditNewsModeAction,
  SetIsNewsModalAction,
  SetIsUncheckedNotificationsSuccessAction,
  SetSizePageNewsAction,
  SetSizePageNotificationsProfileAction,
  SetSortNewsAction,
  SetSortNotificationsProfileAction,
} from './types';

export interface NotificationsProfileReducerState {
  loading?: boolean;
  pagination?: Pagination;
  sort?: string;
  filter?: FilterNotificationsProfile;
  notificationsProfile?: NotificationContent[];
  tabsUnchecked?: HasUncheckedNotificationsType[];
  tabs?: ProfileTabSettings[];
  tabsSettings?: ProfileSettings;
  loadingNews?: boolean;
  paginationNews?: Pagination;
  sortNews?: string;
  news?: News[];
  currentNews?: News;
  loadingCurrentNews?: boolean;
  isEditNewsMode?: boolean;
  isNewsModal?: boolean;
  isUncheckedNews?: boolean;
  loadingOptions?: boolean;
  organizations?: KeyValueOption[];
  workGroups?: KeyValueOption[];
  hasUnpublishedNews?: boolean;
}

const initialState: NotificationsProfileReducerState = {
  notificationsProfile: [],
  loading: false,
  pagination: DEFAULT_PAGINATION_PARAMS,
  sort: 'CREATED_WHEN_DESC',
  tabsUnchecked: [],
  tabs: [],
  loadingNews: false,
  paginationNews: DEFAULT_PAGINATION_PARAMS,
  sortNews: 'DATECREATE_DESC',
  news: [],
  loadingCurrentNews: false,
  isEditNewsMode: false,
  isNewsModal: false,
  isUncheckedNews: false,
  loadingOptions: false,
  organizations: [],
  workGroups: [],
  hasUnpublishedNews: false,
};

const notificationsProfileReducerMap: ReducerMap<
  NotificationsProfileReducerState,
  NotificationsProfileActionsTypes
> = {
  [NotificationsProfile.LOADING_SHOW_NOTIFICATIONS_PROFILE]: (state) => {
    return {
      ...state,
      loading: true,
    };
  },
  [NotificationsProfile.LOADING_HIDE_NOTIFICATIONS_PROFILE]: (state) => {
    return {
      ...state,
      loading: false,
    };
  },
  [NotificationsProfile.FETCH_NOTIFICATIONS_PROFILE_SUCCESS]: (
    state,
    action
  ) => {
    const { payload } = action as FetchNotificationsProfileSuccessAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      notificationsProfile: content,
      pagination: {
        ...state?.pagination,
        pageNum: page,
        totalElements,
      },
    };
  },
  [NotificationsProfile.FETCH_NOTIFICATIONS_PROFILE_SUCCESS_JOIN]: (
    state,
    action
  ) => {
    const { payload } = action as FetchNotificationsProfileJoinSuccessAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      notificationsProfile: [
        ...(state?.notificationsProfile || []),
        ...content,
      ],
      pagination: {
        ...state?.pagination,
        pageNum: page,
        totalElements,
      },
    };
  },
  [NotificationsProfile.SET_CURRENT_PAGE_NOTIFICATIONS_PROFILE]: (
    state,
    action
  ) => {
    const { payload } = action as SetCurrentNotificationsProfilePageAction;
    return {
      ...state,
      pagination: {
        ...state?.pagination,
        pageNum: payload,
      },
    };
  },
  [NotificationsProfile.SET_SIZE_PAGE_NOTIFICATIONS_PROFILE]: (
    state,
    action
  ) => {
    const { payload } = action as SetSizePageNotificationsProfileAction;
    return {
      ...state,
      pagination: {
        ...state?.pagination,
        pageSize: payload,
      },
    };
  },
  [NotificationsProfile.SET_SORT_NOTIFICATIONS_PROFILE]: (state, action) => {
    const { payload } = action as SetSortNotificationsProfileAction;
    return {
      ...state,
      sort: payload,
    };
  },
  [NotificationsProfile.RESET_STATE_NOTIFICATIONS_PROFILE]: (state) => {
    return {
      ...initialState,
      isUncheckedNews: state?.isUncheckedNews,
    };
  },
  [NotificationsProfile.SET_FILTER_NOTIFICATIONS_PROFILE]: (state, action) => {
    const { payload } = action as SetFilterNotificationsProfileAction;
    return {
      ...state,
      filter: payload,
    };
  },
  [NotificationsProfile.SET_IS_UNCHECKED_STATUSES_SUCCESS]: (state, action) => {
    const { payload } = action as SetIsUncheckedNotificationsSuccessAction;
    return {
      ...state,
      tabsUnchecked: payload,
    };
  },
  [NotificationsProfile.SET_CHECKED_NOTIFICATION_SUCCESS]: (state, action) => {
    const { payload } = action as SetCheckedNotificationSuccessAction;

    const newNotificationsList = state?.notificationsProfile?.map((item) => {
      if (item.id === payload) {
        return {
          ...item,
          checked: true,
        };
      }
      return item;
    });
    return {
      ...state,
      notificationsProfile: newNotificationsList,
    };
  },
  [NotificationsProfile.FETCH_NOTIFICATION_TABS_SUCCESS]: (state, action) => {
    const { payload } = action as FetchNotificationTabsSuccessAction;
    return {
      ...state,
      tabs: payload,
    };
  },
  [NotificationsProfile.FETCH_NOTIFICATION_SETTINGS_SUCCESS]: (
    state,
    action
  ) => {
    const { payload } = action as FetchNotificationSettingsSuccessAction;
    return {
      ...state,
      tabsSettings: payload,
    };
  },
  [NotificationsProfile.RESET_NOTIFICATIONS_LIST]: (state) => {
    const { notificationsProfile, pagination, sort, filter } = initialState;
    return {
      ...state,
      notificationsProfile,
      pagination,
      sort,
      filter,
    };
  },
  [NotificationsProfile.LOADING_SHOW_NEWS]: (state) => {
    return {
      ...state,
      loadingNews: true,
    };
  },
  [NotificationsProfile.LOADING_HIDE_NEWS]: (state) => {
    return {
      ...state,
      loadingNews: false,
    };
  },
  [NotificationsProfile.FETCH_NEWS_SUCCESS]: (state, action) => {
    const { payload } = action as FetchNewsSuccessAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      news: content,
      paginationNews: {
        ...state?.pagination,
        pageNum: page,
        totalElements,
      },
    };
  },
  [NotificationsProfile.FETCH_NEWS_SUCCESS_JOIN]: (state, action) => {
    const { payload } = action as FetchNewsJoinSuccessAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      news: [...(state?.news || []), ...content],
      paginationNews: {
        ...state?.pagination,
        pageNum: page,
        totalElements,
      },
    };
  },
  [NotificationsProfile.SET_CURRENT_PAGE_NEWS]: (state, action) => {
    const { payload } = action as SetCurrentNewsPageAction;
    return {
      ...state,
      paginationNews: {
        ...state?.pagination,
        pageNum: payload,
      },
    };
  },
  [NotificationsProfile.SET_SIZE_PAGE_NEWS]: (state, action) => {
    const { payload } = action as SetSizePageNewsAction;
    return {
      ...state,
      paginationNews: {
        ...state?.pagination,
        pageSize: payload,
      },
    };
  },
  [NotificationsProfile.SET_SORT_NEWS]: (state, action) => {
    const { payload } = action as SetSortNewsAction;
    return {
      ...state,
      sortNews: payload,
    };
  },
  [NotificationsProfile.RESET_STATE_NEWS]: (state) => {
    const { news, paginationNews, sortNews, currentNews } = initialState;
    return {
      ...state,
      news,
      paginationNews,
      sortNews,
      currentNews,
    };
  },
  [NotificationsProfile.CURRENT_NEWS_FETCH_SUCCESS]: (state, action) => {
    const { payload } = action as FetchCurrentNewsSuccessAction;
    return {
      ...state,
      currentNews: payload,
    };
  },
  [NotificationsProfile.LOADING_SHOW_CURRENT_NEWS]: (state) => {
    return {
      ...state,
      loadingCurrentNews: true,
    };
  },
  [NotificationsProfile.LOADING_HIDE_CURRENT_NEWS]: (state) => {
    return {
      ...state,
      loadingCurrentNews: false,
    };
  },
  [NotificationsProfile.SET_IS_EDIT_NEWS_MODE]: (state, action) => {
    const { payload } = action as SetIsEditNewsModeAction;
    return {
      ...state,
      isEditNewsMode: payload,
    };
  },
  [NotificationsProfile.SET_IS_NEWS_MODAL]: (state, action) => {
    const { payload } = action as SetIsNewsModalAction;
    return {
      ...state,
      isNewsModal: payload,
    };
  },
  [NotificationsProfile.FETCH_IS_UNCHECKED_NEWS_SUCCESS]: (state, action) => {
    const { payload } = action as FetchIsUncheckedNewsSuccessAction;
    return {
      ...state,
      isUncheckedNews: payload,
    };
  },
  [NotificationsProfile.CHECK_NEWS_SUCCESS]: (state, action) => {
    const { payload } = action as CheckNewsSuccessAction;

    // eslint-disable-next-line sonarjs/no-identical-functions
    const updatedNews = state?.news?.map((item) => {
      if (item.id === payload) {
        return {
          ...item,
          checked: true,
        };
      }
      return item;
    });

    return {
      ...state,
      news: updatedNews,
    };
  },
  [NotificationsProfile.FETCH_ORGANIZATIONS_SUCCESS]: (state, action) => {
    const { payload } = action as FetchOrganizationsSuccessAction;
    return {
      ...state,
      organizations: payload,
    };
  },
  [NotificationsProfile.FETCH_WORK_GROUPS]: (state) => {
    return {
      ...state,
      loadingOptions: true,
    };
  },
  [NotificationsProfile.FETCH_WORK_GROUPS_SUCCESS]: (state, action) => {
    const { payload } = action as FetchWorkGroupsSuccessAction;
    return {
      ...state,
      workGroups: payload,
      loadingOptions: false,
    };
  },
  [NotificationsProfile.CHECK_HAS_UNPUBLISHED_NEWS_SUCCESS]: (
    state,
    action
  ) => {
    const { payload } = action as CheckHasUnpublishedNewsSuccessAction;
    return {
      ...state,
      hasUnpublishedNews: payload,
    };
  },
};

export const notificationsProfile: Reducer<
  NotificationsProfileReducerState,
  NotificationsProfileActionsTypes
> = (state = initialState, action) => {
  const reducer = notificationsProfileReducerMap[action.type];
  if (!reducer) {
    return state;
  }
  return reducer(state, action);
};
