import { ValueType } from 'components';

export const createSelectOption = (
  value: string | number | boolean | null | undefined
): ValueType<string> => {
  if (value === null || value === undefined) {
    return null;
  }
  const option = String(value);
  return { value: option, title: option };
};
