import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { AdminsLayout, TablesLayout } from 'core/layouts';

import { CustomFieldsTableContainer } from './components';
import styles from './CustomFields.module.scss';
import {
  fetchCustomFieldsRequest,
  resetCustomFieldState,
  setCurrentCustomFieldId,
} from './ducks/actions';

const MainTable = () => {
  return <CustomFieldsTableContainer />;
};

const CustomFields = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCustomFieldsRequest());
    return () => {
      dispatch(setCurrentCustomFieldId(''));
      dispatch(resetCustomFieldState());
    };
  }, []);

  return (
    <AdminsLayout>
      <TablesLayout
        MainTable={[MainTable]}
        classNameRoot={styles.customField}
        classNameSubTables={styles.customField__subTable}
        classNameMainTables={styles.customField__mainTable}
        SubTable={[]}
      />
    </AdminsLayout>
  );
};

export default CustomFields;
