import { ApiClient } from 'utils';

export const downloadFileWithToken = async (
  title: string,
  filePath?: string
) => {
  const anchor = document.createElement('a');
  document.body.appendChild(anchor);

  const file = await ApiClient.get<Blob>({
    baseURL: '',
    url: filePath || '',
    responseType: 'blob',
  });

  const objectUrl = window.URL.createObjectURL(file);

  anchor.href = objectUrl;
  anchor.download = title;
  anchor.target = '_blank';
  anchor.click();

  window.URL.revokeObjectURL(objectUrl);
  anchor.remove();
};
