export const convertFromDateFormat = (date?: string) => {
  if (date) {
    return new Date(
      date
        .split(' - ', 1)
        .join()
        .replace(/(\d+).(\d+).(\d+)/, '$3/$2/$1')
    );
  }
  return undefined;
};
