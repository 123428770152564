import { RouteComponentProps } from 'react-router-dom';

import { BreadCrumb } from 'components';
import { RouterConfig } from 'routes/types';

export const getBreadCrumbsConfig = (
  routes: RouterConfig[],
  props: RouteComponentProps<{ [p: string]: string | undefined }>
): BreadCrumb[] => {
  const crumbs = routes
    ?.filter(({ href }) => props.match.path.includes(href))
    ?.map(({ href, ...rest }) => ({
      path: Object.keys(props.match.params).length
        ? Object.keys(props.match.params).reduce((path, param) => {
            const replaceValue = props.match.params[param];
            return path.replace(`:${param}`, replaceValue || '');
          }, href)
        : href,
      ...rest,
    }));
  return crumbs
    .map(({ breadCrumbTitle, path }) => ({
      title: breadCrumbTitle,
      path,
    }))
    .filter(({ title }) => title);
};
