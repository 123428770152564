import cn from 'clsx';
import DOMPurify from 'dompurify';
import { FC } from 'react';

import { BookIcon, LabelIcon } from 'assets/icons';
import { Typography, TypographyVariants } from 'components';
import { SolutionType } from 'features/SimilarSolutions/types';

import styles from './SimilarSolutionsListElement.module.scss';

const getHighlightText = (text?: string, keyWord?: string) => {
  if (keyWord?.length && keyWord?.length >= 3 && text?.length) {
    const regEx = new RegExp(
      keyWord.replace(/([.?*+^$[\]\\(){}|-])/g, ''),
      'ig'
    );

    return text.replace(
      regEx,
      (value) =>
        `<span class="${styles.similarSolutionsListElement__highlight}">${value}</span>`
    );
  }
  return text;
};

const iconStylesMap = {
  [SolutionType.TICKET]: styles.similarSolutionsListElement__iconWrapper_ticket,
  [SolutionType.ARTICLE]:
    styles.similarSolutionsListElement__iconWrapper_article,
};

const iconsMap = {
  [SolutionType.TICKET]: (
    <LabelIcon className={styles.similarSolutionsListElement__icon} />
  ),
  [SolutionType.ARTICLE]: (
    <BookIcon className={styles.similarSolutionsListElement__icon} />
  ),
};

interface SimilarSolutionsListElementProps {
  type?: SolutionType;
  title?: string;
  subTitle?: string | null;
  description?: string;
  className?: string;
  keyWord?: string;
  isOnlyTitles?: boolean;
  fetchSimilarSolution(): void;
}

export const SimilarSolutionsListElement: FC<
  SimilarSolutionsListElementProps
> = ({
  type = SolutionType.TICKET,
  title,
  subTitle,
  description,
  className,
  keyWord,
  isOnlyTitles,
  fetchSimilarSolution,
}) => {
  const highlightTitle = getHighlightText(title, keyWord);
  const highlightDescription = getHighlightText(description, keyWord);

  const isArticle = type === SolutionType.ARTICLE;

  const getDescription = () => {
    if (!isOnlyTitles || isArticle) {
      return (
        <p
          className={styles.similarSolutionsListElement__text}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              !isOnlyTitles ? highlightDescription || '' : description || ''
            ),
          }}
        />
      );
    }
    return (
      <p className={styles.similarSolutionsListElement__text}>{description}</p>
    );
  };

  return (
    <div
      className={cn(styles.similarSolutionsListElement, className)}
      onClick={fetchSimilarSolution}
      onKeyDown={fetchSimilarSolution}
      role="button"
      tabIndex={-1}
    >
      <div
        className={cn(
          styles.similarSolutionsListElement__iconWrapper,
          iconStylesMap[type]
        )}
      >
        {iconsMap[type]}
      </div>
      <div className={styles.similarSolutionsListElement__infoWrapper}>
        <Typography
          variant={TypographyVariants.b2}
          className={styles.similarSolutionsListElement__title}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(highlightTitle || ''),
          }}
        />
        {subTitle && (
          <Typography
            variant={TypographyVariants.o}
            className={styles.similarSolutionsListElement__subTitle}
          >
            {subTitle}
          </Typography>
        )}
        {getDescription()}
      </div>
    </div>
  );
};
