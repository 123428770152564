import { WorkGroup } from 'features/WorkGroup';
import { WorkGroupLayout } from 'features/WorkGroup/layouts';

import styles from './WorkGroup.module.scss';

const WorkGroupPage = () => {
  return (
    <WorkGroupLayout>
      <WorkGroup className={styles.workGroup__content} />
    </WorkGroupLayout>
  );
};

export default WorkGroupPage;
