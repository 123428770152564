import {
  CustomField,
  Environment,
  EnvironmentForSystem,
  TicketType,
} from 'core/types';
import { Ticket } from 'core/types/ticket';
import { ResponseWithMeta } from 'store/types';
import { Pagination, UpdateContentType } from 'types/models/meta';

import { CreateTicketDataToRequest, TicketsFilter } from '../types';

export enum CreateTicket {
  CREATE_TICKET = 'CREATE_TICKET/CREATE_TICKET',
  FETCH_ENVIRONMENTS_SUCCESS = 'CREATE_TICKET/FETCH_ENVIRONMENTS_SUCCESS',
  FETCH_ENVIRONMENTS_REQUEST = 'CREATE_TICKET/FETCH_ENVIRONMENTS_REQUEST',
  ENVIRONMENTS_LOADING_SHOW = 'CREATE_TICKET/ENVIRONMENTS_LOADING_SHOW',
  ENVIRONMENTS_LOADING_HIDE = 'CREATE_TICKET/ENVIRONMENTS_LOADING_HIDE',
  CREATE_LOADING_SHOW = 'CREATE_TICKET/CREATE_LOADING_SHOW',
  CREATE_LOADING_HIDE = 'CREATE_TICKET/CREATE_LOADING_HIDE',
  FETCH_CREATE_TICKET_TYPES_REQUEST = 'CREATE_TICKET/FETCH_CREATE_TICKET_TYPES_REQUEST',
  FETCH_CREATE_TICKET_TYPES_SUCCESS = 'CREATE_TICKET/FETCH_CREATE_TICKET_TYPES_SUCCESS',
  RESET_CREATE_TICKET_STATE = 'CREATE_TICKET/RESET_CREATE_TICKET_STATE',
  FETCH_TICKETS_REQUEST = 'CREATE_TICKET/FETCH_TICKETS_REQUEST',
  TICKETS_LOADING_SHOW = 'CREATE_TICKET/TICKETS_LOADING_SHOW',
  TICKETS_LOADING_HIDE = 'CREATE_TICKET/TICKETS_LOADING_HIDE',
  FETCH_TICKETS_SUCCESS_UPDATE = 'CREATE_TICKET/FETCH_TICKETS_SUCCESS_UPDATE',
  FETCH_TICKETS_SUCCESS_JOIN = 'CREATE_TICKET/FETCH_TICKETS_SUCCESS_JOIN',
  RESET_TICKETS_STATE = 'CREATE_TICKET/RESET_TICKETS_STATE',
  SET_FILTER_TICKETS = 'CREATE_TICKET/SET_FILTER_TICKETS',
  SET_CURRENT_PAGE_TICKETS = 'CREATE_TICKET/SET_CURRENT_PAGE_TICKETS',
  FETCH_REQUIRED_CUSTOM_FIELDS_REQUEST = 'CREATE_TICKET/FETCH_REQUIRED_CUSTOM_FIELDS_REQUEST',
  FETCH_REQUIRED_CUSTOM_FIELDS_SUCCESS = 'CREATE_TICKET/FETCH_REQUIRED_CUSTOM_FIELDS_SUCCESS',
}

export interface ShowEnvironmentsLoadingAction {
  type: CreateTicket.ENVIRONMENTS_LOADING_SHOW;
}

export interface HideEnvironmentsLoadingAction {
  type: CreateTicket.ENVIRONMENTS_LOADING_HIDE;
}

export interface FetchEnvironmentsRequestAction {
  type: CreateTicket.FETCH_ENVIRONMENTS_REQUEST;
  payload: EnvironmentForSystem;
}

export interface FetchEnvironmentsSuccessAction {
  type: CreateTicket.FETCH_ENVIRONMENTS_SUCCESS;
  payload: ResponseWithMeta<Environment[]>;
}

export interface ShowCreateLoadingAction {
  type: CreateTicket.CREATE_LOADING_SHOW;
}

export interface HideCreateLoadingAction {
  type: CreateTicket.CREATE_LOADING_HIDE;
}

export interface CreateTicketPayload {
  ticket: CreateTicketDataToRequest;
  attachments: FormData | null;
}

export interface CreateTicketAction {
  type: CreateTicket.CREATE_TICKET;
  payload: CreateTicketPayload;
}

export interface FetchTicketTypesRequestAction {
  type: CreateTicket.FETCH_CREATE_TICKET_TYPES_REQUEST;
  payload: string;
}

export interface FetchTicketTypesSuccessAction {
  type: CreateTicket.FETCH_CREATE_TICKET_TYPES_SUCCESS;
  payload: TicketType[];
}

export interface ResetCreateTicketStateAction {
  type: CreateTicket.RESET_CREATE_TICKET_STATE;
}

export interface FetchTicketsRequestAction {
  type: CreateTicket.FETCH_TICKETS_REQUEST;
  payload: UpdateContentType;
}

export interface TicketsLoadingShowAction {
  type: CreateTicket.TICKETS_LOADING_SHOW;
}

export interface TicketsLoadingHideAction {
  type: CreateTicket.TICKETS_LOADING_HIDE;
}

export interface FetchTicketsSuccessUpdateAction {
  type: CreateTicket.FETCH_TICKETS_SUCCESS_UPDATE;
  payload: ResponseWithMeta<Ticket[]>;
}

export interface FetchTicketsSuccessJoinAction {
  type: CreateTicket.FETCH_TICKETS_SUCCESS_JOIN;
  payload: ResponseWithMeta<Ticket[]>;
}

export interface ResetTicketsStateAction {
  type: CreateTicket.RESET_TICKETS_STATE;
}

export interface SetFilterTicketsAction {
  type: CreateTicket.SET_FILTER_TICKETS;
  payload: TicketsFilter;
}

export interface SetCurrentPageTicketsAction {
  type: CreateTicket.SET_CURRENT_PAGE_TICKETS;
  payload: number;
}

export interface FetchCreateTicketsPayload extends Pagination {
  filter?: TicketsFilter;
  workGroupIds?: string[];
}

export interface FetchRequiredCustomFieldsRequestAction {
  type: CreateTicket.FETCH_REQUIRED_CUSTOM_FIELDS_REQUEST;
  payload: string;
}

export interface FetchRequiredCustomFieldsSuccessAction {
  type: CreateTicket.FETCH_REQUIRED_CUSTOM_FIELDS_SUCCESS;
  payload: CustomField[];
}

export type CreateTicketActionsTypes =
  | FetchEnvironmentsSuccessAction
  | FetchTicketTypesSuccessAction
  | TicketsLoadingShowAction
  | TicketsLoadingHideAction
  | FetchTicketsSuccessUpdateAction
  | FetchTicketsSuccessJoinAction
  | ResetTicketsStateAction
  | SetFilterTicketsAction
  | SetCurrentPageTicketsAction
  | FetchRequiredCustomFieldsRequestAction
  | FetchRequiredCustomFieldsSuccessAction;
