import { type DragEvent, type DragEventHandler, useCallback } from 'react';

import { eventStop } from 'components-new/helpers/eventStop';

import { useBoolean } from './useBoolean';
import { useEvent } from './useEvent';

interface UseDragAndDropParams {
  onDrop?: DragEventHandler;
}

export const useDragAndDrop = ({
  onDrop = () => {
    /* pass */
  },
}: UseDragAndDropParams = {}) => {
  const onDropMemo = useEvent(onDrop);

  const {
    value: isDragActive,
    setTrue: setTrueDragActive,
    setFalse: setFalseDragActive,
  } = useBoolean();

  const onDragStart = useCallback(
    (e: DragEvent) => {
      eventStop(e);

      setTrueDragActive();
    },
    [setTrueDragActive]
  );

  const onDragFinish = useCallback(
    (e: DragEvent) => {
      eventStop(e);

      setFalseDragActive();
    },
    [setFalseDragActive]
  );

  const onDropEvent = useCallback(
    (e: DragEvent) => {
      eventStop(e);

      onDropMemo(e);

      setFalseDragActive();
    },
    [onDropMemo, setFalseDragActive]
  );

  return {
    isDragActive,
    rootEvents: {
      onDragEnter: onDragStart,
      onDragOver: onDragStart,
      onDragLeave: onDragFinish,
      onDragEnd: onDragFinish,
      onDrop: onDropEvent,
    },
  };
};
