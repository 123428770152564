import { FC } from 'react';

import { CloseIcon } from 'assets/icons';
import { PopoverButton, PopoverButtonProps } from 'components';

import styles from './DeleteButton.module.scss';

export const DeleteButton: FC<PopoverButtonProps> = (props) => {
  return (
    <PopoverButton
      icon={<CloseIcon className={styles.deleteButton__icon} />}
      {...props}
    >
      Удалить
    </PopoverButton>
  );
};
