import { call, put, select, takeEvery } from 'redux-saga/effects';

import { Ticket } from 'core/types/ticket';
import { ResponseWithMeta } from 'store/types';
import { createError } from 'utils';

import { DesktopFilters, TicketsStatistic } from '../../types';
import { getDesktopFilters } from '../Desktop/selectors';

import { requests } from './api/requests';
import {
  getCurrentStatePageNumber,
  getCurrentStatePageSize,
  getCurrentStateSort,
} from './selectors';

import {
  fetchTicketsRequest,
  fetchTicketsStatisticRequest,
  fetchTicketsStatisticSuccess,
  fetchTicketsSuccess,
  setIsTicketsLoading,
} from './index';

function* ticketsFetch() {
  try {
    yield put(setIsTicketsLoading(true));
    const currentPage: ReturnType<typeof getCurrentStatePageNumber> =
      yield select(getCurrentStatePageNumber);
    const pageSize: ReturnType<typeof getCurrentStatePageSize> = yield select(
      getCurrentStatePageSize
    );
    const sort: ReturnType<typeof getCurrentStateSort> = yield select(
      getCurrentStateSort
    );
    const filter: DesktopFilters = yield select(getDesktopFilters);
    const data: ResponseWithMeta<Ticket[]> = yield call(
      requests.fetchTickets,
      currentPage,
      pageSize,
      sort,
      filter
    );
    yield put(fetchTicketsSuccess(data));
    yield put(setIsTicketsLoading(false));
  } catch (error) {
    createError(error);
  }
}

function* ticketsStatisticCurrentStateFetch() {
  try {
    const filter: DesktopFilters = yield select(getDesktopFilters);
    const data: TicketsStatistic = yield call(
      requests.fetchTicketsStatistic,
      filter
    );
    yield put(fetchTicketsStatisticSuccess(data));
  } catch (error) {
    createError(error);
  }
}

export function* desktopCurrentStateSaga() {
  yield takeEvery(fetchTicketsRequest.type, ticketsFetch);
  yield takeEvery(
    fetchTicketsStatisticRequest.type,
    ticketsStatisticCurrentStateFetch
  );
}
