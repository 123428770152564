import {
  WorkGroupFilter,
  WorkGroupFilterToRequest,
} from 'core/types/workGroup';

export const getFilterGroupsToRequest = (
  filter?: WorkGroupFilter
): WorkGroupFilterToRequest => {
  const organizationId = filter?.organizationId;
  return {
    ...filter,
    organizationId:
      organizationId && !Array.isArray(organizationId)
        ? organizationId.value
        : undefined,
  };
};
