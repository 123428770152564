import cn from 'clsx';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { Button, Card, Portal } from 'components';
import { getIsMobile, getIsMobileSmall } from 'core/ducks/selectors';

import styles from './BottomButtonsBlock.module.scss';

interface BottomButtonsBlockProps {
  isOpen: boolean;
  onCancel(): void;
  onSave?: () => void;
  disabledSubmit: boolean;
  className?: string;
  parentNode?: HTMLElement | null;
}

export const BottomButtonsBlock: FC<BottomButtonsBlockProps> = ({
  isOpen,
  onCancel,
  onSave,
  disabledSubmit,
  className,
  parentNode,
}) => {
  const isMobile = useSelector(getIsMobile);
  const isMobileSmall = useSelector(getIsMobileSmall);
  const isMobileAll = isMobile || isMobileSmall;

  return (
    <Portal parentNode={parentNode}>
      <Card
        className={cn(
          styles.bottomButtonsBlock,
          {
            [styles.bottomButtonsBlock_open]: isOpen,
          },
          className
        )}
      >
        <Button
          appearance={isMobileAll ? 'flat' : 'outline'}
          type="button"
          onClick={onCancel}
        >
          Отмена
        </Button>
        <Button
          onClick={onSave}
          disabled={disabledSubmit}
          className={styles.bottomButtonsBlock__submitButton}
        >
          Сохранить
        </Button>
      </Card>
    </Portal>
  );
};
