import cn from 'clsx';
import { FC } from 'react';

import { Card, TableHeader } from 'components';
import { TicketTypeSettings } from 'core/types';
import { SLADataType } from 'core/types/sla';
import { CreateSLA, SlaTable, SlaTableHeader } from 'features/Contract';
import { useSystemsTableContainer } from 'features/SupplementaryAgreement/hooks';
import { SystemsFromContractTable } from 'features/Systems';

import styles from './SystemsTableSupAg.module.scss';

interface SystemsTableSupAgProps {
  onChange?: (data: SLADataType) => void;
  setIsValidSlaTicketTypes?: (data: boolean) => void;
  ticketTypes?: TicketTypeSettings[];
}

export const SystemsTableSupAg: FC<SystemsTableSupAgProps> = ({
  onChange,
  setIsValidSlaTicketTypes,
  ticketTypes: slaTicketTypes,
}) => {
  const { methods, state } = useSystemsTableContainer();

  const { setTableOpened, prepareDataConditionBlock } = methods;

  const {
    systems,
    tableOpened,
    sla,
    autoClosingTime,
    ticketTypes,
    currentSystemId,
    loading,
    isCreateMode,
  } = state;

  const getContent = () => {
    return (
      <SystemsFromContractTable
        systems={systems}
        activeRowId={currentSystemId}
        customLoading={loading}
      />
    );
  };

  const slaContent = isCreateMode ? (
    <CreateSLA
      sla={sla}
      isCreateMode
      canChooseSystem={false}
      showContentInModal={false}
      onChange={onChange}
      slaTicketTypes={slaTicketTypes}
      autoClosingProps={{
        classNameToggleContainer: styles.systemsTableSupAg__autoClosingToggle,
        className: styles.systemsTableSupAg__autoClosing,
      }}
      setIsValidSlaTicketTypes={setIsValidSlaTicketTypes}
    />
  ) : (
    <>
      <SlaTableHeader
        workTime={prepareDataConditionBlock()}
        autoClosing={autoClosingTime}
        className={cn({
          [styles.systemsTableSupAg__slaHeader_close]: !tableOpened,
        })}
      />
      <SlaTable slaTicketTypes={ticketTypes} slaSettings={sla?.settings} />
    </>
  );

  return (
    <div
      className={cn(styles.systemsTableSupAg, {
        [styles.systemsTableSupAg_short]: !isCreateMode,
        [styles.systemsTableSupAg_close]: !tableOpened,
      })}
    >
      <div className={styles.systemsTableSupAg__systems}>
        <TableHeader
          title="Системы"
          tableOpened={tableOpened}
          handleTableOpen={setTableOpened}
          className={cn(styles.systemsTableSupAg__header, {
            [styles.systemsTableSupAg__header_close]: !tableOpened,
          })}
        />
        <div className={styles.systemsTableSupAg__systemsContent}>
          {getContent()}
        </div>
      </div>
      <Card
        className={cn(styles.systemsTableSupAg__sla, {
          [styles.systemsTableSupAg__sla_close]: !tableOpened,
        })}
      >
        {slaContent}
      </Card>
    </div>
  );
};
