import { EvaluateSpecialistPayload } from 'features/Ticket/ducks/assessmentInteraction/types';
import { getUrl } from 'utils';

export const endpoints = {
  getImprovements: (rating: number) => getUrl(`v1/kpi/rating/${rating}`),
  evaluateSpecialist: ({ ticketId, rating }: EvaluateSpecialistPayload) =>
    getUrl(`v1/kpi/rating/${ticketId}/${rating}`),
  getTicketEvaluation: (ticketId: string) =>
    getUrl(`v1/kpi/ticket/rating/${ticketId}`),
};
