import cn from 'clsx';
import { forwardRef, HTMLAttributes } from 'react';

import styles from './Card.module.scss';

interface CardProps extends HTMLAttributes<HTMLDivElement> {}

export const Card = forwardRef<HTMLDivElement, CardProps>(
  ({ className, children, ...otherProps }, ref) => {
    return (
      <div className={cn(styles.card, className)} {...otherProps} ref={ref}>
        {children}
      </div>
    );
  }
);
