import { Contract } from 'core/types';
import { ContractDetailPayload } from 'core/types/contract';
import { DeleteDataType } from 'core/types/organizations';
import { SLAType } from 'core/types/sla';
import { System } from 'core/types/system';
import { ResponseWithMeta } from 'store/types';

import { ContractsFilter } from '../types';

export enum Contracts {
  FETCH_CONTRACTS_SUCCESS = 'CONTRACTS/FETCH_CONTRACTS_SUCCESS',
  FETCH_CONTRACTS_REQUEST = 'CONTRACTS/FETCH_CONTRACTS_REQUEST',
  CONTRACTS_LOADING_SHOW = 'CONTRACTS/CONTRACTS_LOADING_SHOW',
  CONTRACTS_LOADING_HIDE = 'CONTRACTS/CONTRACTS_LOADING_HIDE',
  CONTRACT_LOADING_SHOW = 'CONTRACTS/CONTRACT_LOADING_SHOW',
  CONTRACT_LOADING_HIDE = 'CONTRACTS/CONTRACT_LOADING_HIDE',
  SET_CURRENT_CONTRACTS_PAGE = 'CONTRACTS/SET_CURRENT_CONTRACTS_PAGE',
  SET_SORT_CONTRACTS = 'CONTRACTS/SET_SORT_CONTRACTS',
  SET_SIZE_PAGE_CONTRACTS = 'CONTRACTS/SET_SIZE_PAGE_CONTRACTS',
  FETCH_CONTRACT_REQUEST = 'CONTRACTS/FETCH_CONTRACT_REQUEST',
  FETCH_CONTRACT_SUCCESS = 'CONTRACTS/FETCH_CONTRACT_SUCCESS',
  SET_CURRENT_CONTRACT_ID = 'CONTRACTS/SET_CURRENT_CONTRACT_ID',
  RESET_CONTRACTS_STATE = 'CONTRACTS/RESET_CONTRACTS_STATE',
  FETCH_CONTRACTS_BY_ORGANIZATION_ID_REQUEST = 'CONTRACTS/FETCH_CONTRACTS_BY_ORGANIZATION_ID_REQUEST',
  FETCH_CONTRACTS_BY_SYSTEM_ID_REQUEST = 'CONTRACTS/FETCH_CONTRACTS_BY_SYSTEM_ID_REQUEST',
  SET_CONTRACT_SYSTEM = 'CONTRACTS/SET_CONTRACT_SYSTEM',
  SET_CONTRACT_FILTER = 'CONTRACTS/SET_CONTRACT_FILTER',
  UPDATE_CONTRACT = 'CONTRACTS/UPDATE_CONTRACT',
  SET_SYSTEMS_FOR_CONTRACT = 'CONTRACTS/SET_SYSTEMS_FOR_CONTRACT',
  FETCH_ORGANIZATION_ID_FOR_CONTRACT = 'CONTRACTS/FETCH_ORGANIZATION_ID_FOR_CONTRACT',
  DELETE_CONTRACT_REQUEST = 'CONTRACTS/DELETE_CONTRACT_REQUEST',
  RESET_CURRENT_CONTRACT = 'CONTRACTS/RESET_CURRENT_CONTRACT',

  FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST = 'CONTRACTS/FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST',
  FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_SUCCESS = 'CONTRACTS/FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_SUCCESS',
  RESET_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID = 'CONTRACTS/RESET_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID',
}

export interface ShowContractsLoadingAction {
  type: Contracts.CONTRACTS_LOADING_SHOW;
}

export interface HideContractsLoadingAction {
  type: Contracts.CONTRACTS_LOADING_HIDE;
}

export interface ShowContractLoadingAction {
  type: Contracts.CONTRACT_LOADING_SHOW;
}

export interface HideContractLoadingAction {
  type: Contracts.CONTRACT_LOADING_HIDE;
}

export interface FetchContractsRequestAction {
  type: Contracts.FETCH_CONTRACTS_REQUEST;
}

export interface FetchContractsSuccessAction {
  type: Contracts.FETCH_CONTRACTS_SUCCESS;
  payload: ResponseWithMeta<Contract[]>;
}

export interface SetCurrentContractsPageAction {
  type: Contracts.SET_CURRENT_CONTRACTS_PAGE;
  payload: number;
}

export interface SetSizePageAction {
  type: Contracts.SET_SIZE_PAGE_CONTRACTS;
  payload: number;
}

export interface SetSortContractsAction {
  type: Contracts.SET_SORT_CONTRACTS;
  payload: string;
}

export interface FetchContractRequestAction {
  type: Contracts.FETCH_CONTRACT_REQUEST;
  payload: string;
}

export interface FetchContractSuccessAction {
  type: Contracts.FETCH_CONTRACT_SUCCESS;
  payload?: Contract;
}

export interface ResetContractsStateAction {
  type: Contracts.RESET_CONTRACTS_STATE;
}

export interface SetCurrentContractIdAction {
  type: Contracts.SET_CURRENT_CONTRACT_ID;
  payload: string;
}

export interface FetchContractsByOrganizationsRequestAction {
  type: Contracts.FETCH_CONTRACTS_BY_ORGANIZATION_ID_REQUEST;
}

export interface FetchContractsBySystemIdRequestAction {
  type: Contracts.FETCH_CONTRACTS_BY_SYSTEM_ID_REQUEST;
}

export interface CreateContractPayload {
  contract: Contract;
  systemId?: string;
}

export interface CreateSupplementaryAgreementPayload {
  contractId: string;
  systemId: string;
  data?: Contract;
}

export interface EditContractPayload {
  contract: Contract;
  systemId?: string;
}

export interface AddSystemAction {
  type: Contracts.SET_CONTRACT_SYSTEM;
  payload: Contract;
}

export interface SetContractFilterAction {
  type: Contracts.SET_CONTRACT_FILTER;
  payload: ContractsFilter;
}

export interface UpdateContractAction {
  type: Contracts.UPDATE_CONTRACT;
  payload: Contract;
}

export interface SetSystemsForContractAction {
  type: Contracts.SET_SYSTEMS_FOR_CONTRACT;
  payload: System[];
}

export interface DeleteContractRequestAction {
  type: Contracts.DELETE_CONTRACT_REQUEST;
  payload: DeleteDataType;
}

export interface ResetCurrentContractAction {
  type: Contracts.RESET_CURRENT_CONTRACT;
}

export interface FetchSLAByContractIdBySystemIdRequestContractsAction {
  type: Contracts.FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST;
  payload: ContractDetailPayload;
}

export interface FetchSLAByContractIdBySystemIdSuccessContractsAction {
  type: Contracts.FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_SUCCESS;
  payload: SLAType;
}

export interface ResetSLAByContractIdBySystemIdContractsAction {
  type: Contracts.RESET_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID;
}

export type ContractsActionsTypes =
  | ShowContractsLoadingAction
  | HideContractsLoadingAction
  | FetchContractsSuccessAction
  | SetCurrentContractsPageAction
  | SetSizePageAction
  | SetSortContractsAction
  | FetchContractRequestAction
  | FetchContractSuccessAction
  | ResetContractsStateAction
  | SetCurrentContractIdAction
  | FetchContractsByOrganizationsRequestAction
  | FetchContractsBySystemIdRequestAction
  | AddSystemAction
  | SetContractFilterAction
  | SetSystemsForContractAction
  | ResetCurrentContractAction
  | FetchSLAByContractIdBySystemIdRequestContractsAction
  | FetchSLAByContractIdBySystemIdSuccessContractsAction
  | ResetSLAByContractIdBySystemIdContractsAction;
