import cn from 'clsx';
import { nanoid } from 'nanoid';
import { FC, MouseEvent, useEffect, useState } from 'react';

import { ButtonType } from '../types';

import styles from './Ripple.module.scss';
import { RippleItem } from './types';

const rippleStyles = {
  default: styles.ripple__item_default,
  flat: styles.ripple__item_flat,
  outline: styles.ripple__item_outline,
  grey: styles.ripple__item_grey,
};

interface RippleProps {
  appearance?: ButtonType | 'grey';
}

export const Ripple: FC<RippleProps> = ({ appearance = 'default' }) => {
  const [rippleArray, setRippleArray] = useState<RippleItem[]>([]);

  useEffect(() => {
    let bounce: number | undefined;

    if (rippleArray.length > 0) {
      window.clearTimeout(bounce);

      bounce = window.setTimeout(() => {
        setRippleArray([]);
        window.clearTimeout(bounce);
      }, 800 * 4);
    }

    return () => window.clearTimeout(bounce);
  }, [rippleArray.length]);

  const addRipple = (event: MouseEvent) => {
    const rippleContainer = event.currentTarget.getBoundingClientRect();
    const size =
      rippleContainer.width > rippleContainer.height
        ? rippleContainer.width
        : rippleContainer.height;
    const x = event.clientX - rippleContainer.left - size / 2;
    const y = event.clientY - rippleContainer.top - size / 2;
    const newRipple = {
      x,
      y,
      size,
      id: nanoid(),
    };

    setRippleArray([...rippleArray, newRipple]);
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div onMouseDown={addRipple} className={styles.ripple}>
      {rippleArray.length > 0 &&
        rippleArray.map((ripple) => {
          const { x, y, size, id } = ripple;
          return (
            <span
              key={id}
              style={{
                top: y,
                left: x,
                width: size,
                height: size,
              }}
              className={cn(styles.ripple__item, rippleStyles[appearance])}
            />
          );
        })}
    </div>
  );
};
