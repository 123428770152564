import { Responsibility } from 'core/types/responsibility';
import { RootState } from 'store/rootReducer';

export const getResponsibilities = (
  state: RootState
): Responsibility[] | undefined => state.responsibilities.responsibilities;
export const getLoading = (state: RootState): boolean | undefined =>
  state.responsibilities.loading;
export const getLoadingAdd = (state: RootState): boolean | undefined =>
  state.responsibilities.loadingAdd;

export const getPropsResponsibilities = (state: RootState) => ({
  pageNum: state.responsibilities.pagination?.pageNum,
  pageSize: state.responsibilities.pagination?.pageSize,
  sortResponsibilities: state.responsibilities?.sort,
  loadingResponsibilities: getLoading(state),
  totalElements: state.responsibilities.pagination?.totalElements,
});

export const getPropsResponsibilitiesAdd = (state: RootState) => ({
  pageNum: state.responsibilities.paginationAdd?.pageNum,
  pageSize: state.responsibilities.paginationAdd?.pageSize,
  totalElements: state.responsibilities.paginationAdd?.totalElements,
  loadingRespAdd: state.responsibilities.loadingAdd,
});

export const getCurrentResponsibilityId = (state: RootState) =>
  state.responsibilities.responsibilityId;
export const getResponsibilitiesAdd = (state: RootState) =>
  state.responsibilities.responsibilitiesAdd;
export const getResponsibilitiesAddFilter = (state: RootState) =>
  state.responsibilities.responsibilitiesAddFilter || {};
export const getResponsibilitiesFilter = (state: RootState) =>
  state.responsibilities.responsibilitiesFilter || {};
export const getCurrentResponsibility = (state: RootState) =>
  state.responsibilities.currentResponsibility;
