import Keycloak, {
  KeycloakConfig,
  KeycloakInitOptions,
  KeycloakInstance,
  KeycloakTokenParsed,
} from 'keycloak-js';

import { getEnv } from 'utils/getEnv';

export interface KeycloakTokenParsedUpdated extends KeycloakTokenParsed {
  business_user_id?: string;
  name?: string;
  given_name?: string;
  family_name?: string;
  middle_name?: string;
  phone_number?: string;
  email?: string;
  organization_title?: string;
}

interface KeycloakInstanceUpdated extends KeycloakInstance {
  tokenParsed?: KeycloakTokenParsedUpdated;
}

const keycloakConfig: KeycloakConfig = {
  realm: getEnv('REACT_APP_AUTH_REALM') || '',
  url: getEnv('REACT_APP_REMOTE_URL')
    ? `${getEnv('REACT_APP_REMOTE_URL')}/auth/`
    : '/auth/',
  clientId: getEnv('REACT_APP_AUTH_CLIENT_ID') || '',
};

export const keycloakInitConfig: KeycloakInitOptions = {
  onLoad: 'check-sso',
  silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
  pkceMethod: 'S256',
};

export const keycloak: KeycloakInstanceUpdated = Keycloak(keycloakConfig);

const kc = keycloak;

const doLogin = kc.login;

const initKeycloak = () =>
  kc.init(keycloakInitConfig).then((authenticated) => {
    if (!authenticated) {
      doLogin();
    }
  });

const doLogout = kc.logout;

const getToken = (): string | undefined => kc.token;

const getCurrentUserId = (): string | undefined => kc.subject;
const updateToken = (): Promise<boolean | void> =>
  kc.updateToken(5).catch(doLogin);

export {
  initKeycloak,
  doLogin,
  doLogout,
  getToken,
  updateToken,
  getCurrentUserId,
  kc,
};
