import { FC, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { Edge, Node } from 'reactflow';

import {
  ArrowInBoxRightIcon,
  CloseIcon,
  CommunicationIcon,
  DeleteIcon,
  QuestionIcon,
} from 'assets/icons';
import {
  Button,
  InfoBlock,
  Input,
  RadioSlide,
  Size,
  ToggleSwitch,
  Tooltip,
  Typography,
  TypographyVariants,
} from 'components';
import { DeleteApproveOrCancel } from 'core/modals';
import { EdgeType, NodeType } from 'core/types/workflow';
import { useStatusForm } from 'features/StatusesBuilder/hooks';

import { ACCESSIBILITY_TABS, COLORS_TABS } from '../../constants';
import { StatusForm } from '../../types';
import { RadioColors } from '../RadioColors';
import { StatusChain } from '../StatusChain';

import styles from './StatusDetail.module.scss';

export interface StatusDetailProps {
  onClose(): void;
  onCreateNodeHandler(node: Node<NodeType>): void;
  onCreateEdgeHandler(edge: Edge<EdgeType>): void;
  nodes: Node<NodeType>[];
  selectedNode?: Node<NodeType>;
  onResetSelectedNode(): void;
  onEditNodeHandler(id: string, data: NodeType): void;
  onDeleteNodeHandler(id?: string): void;
}

export const StatusDetail: FC<StatusDetailProps> = ({
  onClose,
  onCreateNodeHandler,
  onCreateEdgeHandler,
  nodes,
  selectedNode,
  onResetSelectedNode,
  onEditNodeHandler,
  onDeleteNodeHandler,
}) => {
  const { methods, state } = useStatusForm({
    onClose,
    onCreateNodeHandler,
    onCreateEdgeHandler,
    nodes,
    selectedNode,
    onResetSelectedNode,
    onEditNodeHandler,
  });

  const {
    control,
    formSubmitHandler,
    onCreateWaitingInfoStatus,
    onCreateReopenStatus,
    onCloseModalHandler,
    toggleModal,
  } = methods;

  const {
    errors,
    labelInputOptions,
    colorRadioOptions,
    isIdenticalLabel,
    isWaitingInfoStatus,
    isReopenStatus,
    isEditDisabled,
    isDeleteDisabled,
    modalTitle,
    isWaitingInfoLabel,
    isSubmitDisabled,
    isComment,
    isModal,
  } = state;

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);

  const getToggleSwitch = (name: keyof StatusForm, label: string) => (
    <Controller
      control={control}
      name={name}
      rules={{ deps: 'label' }}
      render={({ field }) => {
        return (
          <ToggleSwitch
            label={label}
            checked={!!field.value}
            onChange={field.onChange}
            disabled={isEditDisabled}
            className={styles.statusDetail__switch}
          />
        );
      }}
    />
  );

  const submitButtons = !isEditDisabled && (
    <div className={styles.statusDetail__buttonsWrapper}>
      <Button
        disabled={isSubmitDisabled}
        className={styles.statusDetail__button}
      >
        {selectedNode ? 'Сохранить' : 'Создать'}
      </Button>
      <Button
        onClick={onCloseModalHandler}
        className={styles.statusDetail__button}
        appearance="outline"
        type="button"
      >
        Отмена
      </Button>
    </div>
  );

  const deleteButton = !isDeleteDisabled && (
    <Button
      icon={<DeleteIcon />}
      appearance="flat"
      type="button"
      onClick={toggleModal}
      className={styles.statusDetail__deleteButton}
    >
      Удалить
    </Button>
  );

  const jumpConditions = !!selectedNode?.data?.jumpCondition?.length && (
    <InfoBlock
      icon={
        <ArrowInBoxRightIcon className={styles.statusDetail__infoBlockIcon} />
      }
      mainTitle="Условие перехода"
      info={selectedNode?.data?.jumpCondition?.join(', ')}
      className={styles.statusDetail__infoBlock}
      classNameInfo={styles.statusDetail__infoBlockInfo}
    />
  );

  const commentTypes = isComment &&
    !!selectedNode?.data?.commentTypes?.length && (
      <InfoBlock
        icon={
          <CommunicationIcon className={styles.statusDetail__infoBlockIcon} />
        }
        mainTitle="Тип комментария"
        info={selectedNode?.data?.commentTypes?.join(', ')}
        className={styles.statusDetail__infoBlock}
        classNameInfo={styles.statusDetail__infoBlockInfo}
      />
    );

  return (
    <div className={styles.statusDetail}>
      <div className={styles.statusDetail__header}>
        <h3 className={styles.statusDetail__headerTitle}>{modalTitle}</h3>
        <button
          onClick={onCloseModalHandler}
          className={styles.statusDetail__headerButton}
        >
          <CloseIcon className={styles.statusDetail__headerButtonIcon} />
        </button>
      </div>
      {!isWaitingInfoStatus && !selectedNode && (
        <StatusChain onCreateStatus={onCreateWaitingInfoStatus}>
          “В ожидании информации”
        </StatusChain>
      )}
      {!isReopenStatus && !selectedNode && (
        <StatusChain onCreateStatus={onCreateReopenStatus}>
          “Переоткрыт”
        </StatusChain>
      )}
      <form onSubmit={formSubmitHandler}>
        <Input
          {...labelInputOptions}
          label="Название"
          error={!!errors.label || isIdenticalLabel || isWaitingInfoLabel}
          errorMessage={
            errors.label?.message ||
            (isIdenticalLabel
              ? 'Статус с таким названием уже существует'
              : '') ||
            (isWaitingInfoLabel
              ? 'Статус с таким названием зарезервирован'
              : '')
          }
          disabled={isEditDisabled}
          className={styles.statusDetail__input}
        />
        {jumpConditions}
        <Typography
          variant={TypographyVariants.h5}
          className={styles.statusDetail__subTitle}
        >
          Цвет
        </Typography>
        <RadioColors
          {...colorRadioOptions}
          name="color"
          items={COLORS_TABS}
          disabled={isEditDisabled}
          className={styles.statusDetail__colorsRadio}
        />
        <Typography
          variant={TypographyVariants.h5}
          className={styles.statusDetail__subTitle}
        >
          Доступность
        </Typography>
        <Controller
          control={control}
          name="accessibility"
          rules={{
            required: true,
          }}
          render={({ field }) => {
            return (
              <RadioSlide
                size={Size.s}
                items={ACCESSIBILITY_TABS}
                value={field.value}
                onChange={field.onChange}
                disabled={isEditDisabled}
                className={styles.statusDetail__slideRadio}
              />
            );
          }}
        />
        <div className={styles.statusDetail__radioWrapper}>
          {getToggleSwitch('isNotifications', 'Уведомления')}
          <QuestionIcon
            data-tip
            data-for="isNotifications"
            className={styles.statusDetail__radioIcon}
          />
          <Tooltip id="isNotifications">
            Отправлять уведомления при переводе тикета в этот статус
          </Tooltip>
        </div>
        <div className={styles.statusDetail__radioWrapper}>
          {getToggleSwitch('isSLA', 'Учитывать SLA')}
          <QuestionIcon
            data-tip
            data-for="isSLA"
            className={styles.statusDetail__radioIcon}
          />
          <Tooltip id="isSLA">
            Продолжать отчет времени решения SLA по тикету
          </Tooltip>
        </div>
        <div className={styles.statusDetail__radioWrapper}>
          {getToggleSwitch('isComment', 'Комментарий')}
          <QuestionIcon
            data-tip
            data-for="isComment"
            className={styles.statusDetail__radioIcon}
          />
          <Tooltip id="isComment">
            Оставлять обязательный комментарий при переводе тикета в этот статус
          </Tooltip>
        </div>
        {commentTypes}
        <div className={styles.statusDetail__footer}>
          {submitButtons}
          {deleteButton}
        </div>
      </form>
      <DeleteApproveOrCancel
        isModal={isModal}
        toggleModal={toggleModal}
        entityTitle="статус"
        onApprove={() => {
          onDeleteNodeHandler(selectedNode?.id);
          onClose();
        }}
      />
    </div>
  );
};
