export const getTicketsTitle = (title?: string) => {
  if (title) {
    return `Тикеты - ${title}`;
  }
  return `Тикеты`;
};

export const getAdminsTitle = (title?: string) => {
  if (title) {
    return `Администрирование - ${title}`;
  }
  return `Администрирование`;
};

export const getProfileTitle = (title?: string) => {
  if (title) {
    return `Профиль - ${title}`;
  }
  return `Профиль`;
};

export const getDesktopTitle = (title?: string) => {
  if (title) {
    return `Рабочий стол - ${title}`;
  }
  return `Рабочий стол`;
};

export const getKnowledgeBaseTitle = (title?: string) => {
  if (title) {
    return `База знаний - ${title}`;
  }
  return `База знаний`;
};

export const getStorageTitle = (title?: string) => {
  if (title) {
    return `Хранилище - ${title}`;
  }
  return `Хранилище`;
};
