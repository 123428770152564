import { Access, AccessesFilter } from 'features/Accesses/types';
import { ResponseWithMeta } from 'store/types';

import {
  AccessesCategories,
  CreateAccessCategoryAction,
  FetchAccessesByRespIdAction,
  FetchAccessesRequestAction,
  FetchAccessesSuccessAction,
  HideAccessesLoadingAction,
  ResetAccessesStateAction,
  SetAccessesFilterAction,
  SetCurrentAccessesPageAction,
  SetSizePageAction,
  SetSortAccessesAction,
  ShowAccessesLoadingAction,
} from './types';

export const showAccessesLoading = (): ShowAccessesLoadingAction => {
  return {
    type: AccessesCategories.ACCESSES_LOADING_SHOW,
  };
};

export const hideAccessesLoading = (): HideAccessesLoadingAction => {
  return {
    type: AccessesCategories.ACCESSES_LOADING_HIDE,
  };
};

export const fetchAccessesRequest = (): FetchAccessesRequestAction => {
  return {
    type: AccessesCategories.FETCH_ACCESSES_REQUEST,
  };
};

export const fetchAccessesSuccess = (
  data: ResponseWithMeta<Access[]>
): FetchAccessesSuccessAction => {
  return {
    type: AccessesCategories.FETCH_ACCESSES_SUCCESS,
    payload: data,
  };
};

export const fetchAccessesByRespIdRequest = (): FetchAccessesByRespIdAction => {
  return {
    type: AccessesCategories.FETCH_ACCESS_BY_RESP_ID_REQUEST,
  };
};

export const resetAccessesState = (): ResetAccessesStateAction => {
  return {
    type: AccessesCategories.RESET_ACCESSES_STATE,
  };
};

export const createAccessCategory = (
  data: Access
): CreateAccessCategoryAction => {
  return {
    type: AccessesCategories.ACCESS_CATEGORY_CREATE,
    payload: data,
  };
};

export const setAccessesFilter = (
  data: AccessesFilter
): SetAccessesFilterAction => {
  return {
    type: AccessesCategories.SET_ACCESSES_FILTER,
    payload: data,
  };
};

export const setCurrentAccessesPage = (
  page: number
): SetCurrentAccessesPageAction => {
  return {
    type: AccessesCategories.SET_CURRENT_ACCESSES_PAGE,
    payload: page,
  };
};

export const setSizePage = (size: number): SetSizePageAction => {
  return {
    type: AccessesCategories.SET_SIZE_PAGE_ACCESSES,
    payload: size,
  };
};

export const setSortAccesses = (sort: string): SetSortAccessesAction => {
  return {
    type: AccessesCategories.SET_SORT_ACCESSES,
    payload: sort,
  };
};
