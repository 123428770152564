import cn from 'clsx';
import { FC } from 'react';

import { Card, CountRecords, Pagination, TableHeader } from 'components';
import { CreateSLA } from 'features/Contract/components';
import { useSystemsTableContainer } from 'features/Contract/hooks';
import { SystemsFromContractTable } from 'features/Systems';

import { SlaTable, SlaTableHeader } from '../SlaTable';

import {
  SystemsFilterContractDetail,
  SystemsWithSLADropContent,
} from './components';
import styles from './SystemsWithSLA.module.scss';

export const SystemsWithSLA: FC = () => {
  const { methods, state } = useSystemsTableContainer();

  const {
    setTableOpened,
    toggleModal,
    toggleFilterRow,
    handleClickSystem,
    handleChangePage,
    handleChangePageSize,
    handleSort,
    onChangeCheckbox,
    onSelectAllCheckbox,
    createFunction,
    prepareDataConditionBlock,
    onCreateSLA,
    setIsChangeSLAModal,
    onUpdateSLA,
    goToCreateContract,
  } = methods;

  const {
    pageNum,
    pageSize,
    sortSystems,
    totalElements,
    systems,
    tableOpened,
    isModal,
    showFilterRow,
    isFilterActive,
    searchDisabled,
    loading,
    currentSystemId,
    contractId,
    createTitle,
    sla,
    autoClosingTime,
    slaTicketTypes,
    canChangeSLA,
    isChangeSLAModal,
    popoverDisabled,
    transferStatus,
    ticketTypesSettingsLoading,
    ticketTypesSettings,
  } = state;

  const getContent = () => {
    return (
      <SystemsFromContractTable
        systems={systems}
        handleClickSystem={handleClickSystem}
        activeRowId={currentSystemId}
        handleSort={handleSort}
        onChangeCheckbox={onChangeCheckbox}
        onSelectAllCheckbox={onSelectAllCheckbox}
        sort={sortSystems}
        customLoading={loading}
        showFilterRow={showFilterRow}
        isFilterActive={isFilterActive}
        filterComponent={
          <SystemsFilterContractDetail contractId={contractId} />
        }
      />
    );
  };

  const addSystemWithSLAModal = isModal && (
    <CreateSLA
      isModal={isModal}
      toggleModal={toggleModal}
      onCreate={onCreateSLA}
      slaTicketTypes={slaTicketTypes}
    />
  );

  const changeSLAModal = isChangeSLAModal && (
    <CreateSLA
      isModal={isChangeSLAModal}
      toggleModal={setIsChangeSLAModal}
      onCreate={onUpdateSLA}
      canChooseSystem={false}
      sla={sla}
      slaTicketTypes={slaTicketTypes}
    />
  );

  return (
    <div
      className={cn(styles.systemsWithSla, {
        [styles.systemsWithSla_close]: !tableOpened,
      })}
    >
      <div
        className={cn(styles.systemsWithSla__systems, {
          [styles.systemsWithSla__systems_close]: !tableOpened,
        })}
      >
        <TableHeader
          title="Системы"
          createHandler={createFunction}
          createTitle={createTitle}
          tableOpened={tableOpened}
          handleTableOpen={setTableOpened}
          searchDisabled={searchDisabled}
          toggleSearch={toggleFilterRow}
          isSearchOpen={showFilterRow}
          dropContent={
            <SystemsWithSLADropContent
              handleExpand={goToCreateContract}
              disabled={popoverDisabled}
            />
          }
          className={cn(styles.systemsWithSla__header, {
            [styles.systemsWithSla__header_close]: !tableOpened,
          })}
        />
        <div className={styles.systemsWithSla__systemsContent}>
          {getContent()}
          {!transferStatus && (
            <div className={styles.systemsWithSla__systemsPagination}>
              <CountRecords
                records={totalElements}
                className={styles.systemsWithSla__countRecords}
              />
              <Pagination
                pageNum={pageNum}
                pageSize={pageSize}
                marginPagesDisplayed={0}
                elementsCount={totalElements}
                handleChangePage={handleChangePage}
                handleChangePageSize={handleChangePageSize}
              />
            </div>
          )}

          {addSystemWithSLAModal}
          {changeSLAModal}
        </div>
      </div>
      <Card className={styles.systemsWithSla__sla}>
        <SlaTableHeader
          workTime={prepareDataConditionBlock()}
          autoClosing={autoClosingTime}
          onChangeSla={canChangeSLA ? setIsChangeSLAModal : undefined}
          changeDisabled={!currentSystemId}
          updatedAutoClose={sla?.updatedAutoClose}
          updateAutoCloseNameAgreement={sla?.updateAutoCloseNameAgreement}
          updatedWorkingHours={sla?.updatedWorkingHours}
          updateWorkingHoursNameAgreement={sla?.updateWorkingHoursNameAgreement}
          className={
            !tableOpened ? styles.systemsWithSla__slaHeader_close : undefined
          }
          loading={ticketTypesSettingsLoading}
        />
        <div className={styles.systemsWithSla__slaTable}>
          <SlaTable
            slaSettings={sla?.settings}
            slaTicketTypes={ticketTypesSettings}
          />
        </div>
      </Card>
    </div>
  );
};
