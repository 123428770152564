import cn from 'clsx';
import { FC } from 'react';

import { PlusIcon } from 'assets/icons';
import { NumberBlock, TableContainer } from 'components';
import { DataEmpty } from 'core/components';
import { EmptyDataType } from 'core/components/DataEmpty/types';
import { TableTicketsTypes } from 'core/types/ticket';
import {
  useTicketsFilter,
  useTicketsTableContainer,
} from 'features/Tickets/hooks';
import { RouterHref } from 'routes/routerHref';

import { PAGINATION_RANGE_MAP, ticketTabEntityMap } from '../../constants';
import { TicketsFromDesktopHistoryTable } from '../TicketFromDesktopHistoryTable';
import { TicketsByContractIdsTable } from '../TicketsByContractIdsTable';
import { TicketsDropContent } from '../TicketsDropContent';
import { TicketsFromDesktopTable } from '../TicketsFromDesktopTable';
import { TicketsFullTable } from '../TicketsFullTable';

import styles from './TicketsTableContainer.module.scss';

export const ticketsTabs = {
  myTickets: (isRU: boolean, notificationsCount?: number) => ({
    to: RouterHref.Tickets,
    exact: true,
    title: isRU ? 'Мои тикеты' : 'My tickets',
    content: notificationsCount ? (
      <NumberBlock
        number={notificationsCount}
        isPurple
        isRound
        className={styles.ticketsTableContainer__numberEl}
      />
    ) : null,
  }),
  allTickets: (isRU: boolean, notificationsCount?: number) => ({
    to: RouterHref.TicketsAll,
    exact: false,
    title: isRU ? 'Все тикеты' : 'All tickets',
    content: notificationsCount ? (
      <NumberBlock
        number={notificationsCount}
        className={styles.ticketsTableContainer__numberEl}
      />
    ) : null,
  }),
  groupTickets: (isRU: boolean) => ({
    to: RouterHref.TicketsGroup,
    exact: false,
    title: isRU ? 'Тикеты моей группы' : 'Group tickets',
  }),
};

interface TicketsTableContainerProps {
  className?: string;
  withOutTabs?: boolean;
  title?: string | JSX.Element;
  tableType: TableTicketsTypes;
  isFilterActive?: boolean;
  withFilter?: boolean;
  dropContent?: JSX.Element;
}

export const TicketsTableContainer: FC<TicketsTableContainerProps> = ({
  className,
  withOutTabs,
  title,
  tableType,
  isFilterActive,
  withFilter = true,
  dropContent,
}) => {
  const { methods, state } = useTicketsTableContainer(tableType);

  const {
    state: { ticketTab },
  } = useTicketsFilter();

  const {
    toggleDeleteModal,
    handleDelete,
    handleShowModalDelete,
    toggleSearch,
    handleChangePage,
    handleChangePageSize,
    handleSort,
    toggleCurrentTicket,
    goToCreateTicketPage,
  } = methods;

  const {
    isTicketsByContractIdsTable,
    isTicketsFromDesktopTable,
    isTicketsFromDesktopHistoryTable,
    isTicketsFullTable,
    ticket,
    isSearchOpen,
    pageNum,
    pageSize,
    totalElements,
    tickets,
    loading,
    sort,
    isAccessToCreateTicket,
    isMyTicketsPage,
    tabs,
    showDeleteModal,
    isClient,
    isSpecialist,
    isTicketsPage,
  } = state;

  const getTableComponent: Record<TableTicketsTypes, () => JSX.Element> = {
    [TableTicketsTypes.FULL]: () => (
      <TicketsFullTable
        isSearchOpen={isSearchOpen}
        tickets={tickets}
        sort={sort}
        handleSort={handleSort}
        handleRowClick={toggleCurrentTicket}
        isFilterActive={isFilterActive}
      />
    ),
    [TableTicketsTypes.BY_CONTRACT_IDS]: () => (
      <TicketsByContractIdsTable
        isSearchOpen={isSearchOpen}
        tickets={tickets}
        sort={sort}
        handleSort={handleSort}
        isFilterActive={isFilterActive}
      />
    ),
    [TableTicketsTypes.FROM_DESKTOP]: () => (
      <TicketsFromDesktopTable
        tickets={tickets}
        sort={sort}
        handleSort={handleSort}
        loading={loading}
      />
    ),
    [TableTicketsTypes.FROM_DESKTOP_HISTORY]: () => (
      <TicketsFromDesktopHistoryTable
        tickets={tickets}
        sort={sort}
        handleSort={handleSort}
        loading={loading}
      />
    ),
  };

  const getContent = () => {
    if (!isFilterActive && !loading && !tickets?.length) {
      if (isMyTicketsPage) {
        if (isClient) {
          return <DataEmpty type={EmptyDataType.NO_DATA_CLIENT} />;
        }
        if (isSpecialist) {
          return <DataEmpty type={EmptyDataType.NO_DATA_SPECIALIST} />;
        }
      }
      if (isTicketsFromDesktopTable || isTicketsFromDesktopHistoryTable) {
        return <DataEmpty type={EmptyDataType.NO_EXPIRED_DATA} />;
      }
    }
    return getTableComponent[tableType]();
  };

  const createTicketButton = isAccessToCreateTicket &&
    !isTicketsByContractIdsTable &&
    !isTicketsFromDesktopTable &&
    !isTicketsFromDesktopHistoryTable && (
      <div className={styles.ticketsTableContainer__stickyWrapper}>
        <button
          onClick={goToCreateTicketPage}
          className={styles.ticketsTableContainer__createButton}
        >
          <PlusIcon />
        </button>
      </div>
    );

  const ticketsDropContent = isTicketsFullTable ? (
    <TicketsDropContent showDeleteModal={handleShowModalDelete} />
  ) : undefined;

  const currentDropContent =
    tableType === TableTicketsTypes.FROM_DESKTOP ||
    tableType === TableTicketsTypes.FROM_DESKTOP_HISTORY
      ? dropContent
      : ticketsDropContent;

  return (
    <TableContainer
      classNameRoot={cn(styles.ticketsTableContainer, className)}
      headerProps={{
        isSearchOpen,
        toggleSearch: withFilter ? toggleSearch : undefined,
        tabs: withOutTabs ? undefined : tabs,
        title,
        dropContent: currentDropContent,
        searchDisabled: !tickets?.length,
        tabsDisabled: loading,
        className: cn(styles.ticketsTableContainer__header, {
          [styles.ticketsTableContainer__header_fromOrg]:
            isTicketsByContractIdsTable,
          [styles.ticketsTableContainer__header_fromDesktop]:
            isTicketsFromDesktopTable || isTicketsFromDesktopHistoryTable,
        }),
        classNameTitle: cn({
          [styles.ticketsTableContainer__title_fromDesktop]:
            isTicketsFromDesktopTable || isTicketsFromDesktopHistoryTable,
        }),
        loading,
      }}
      contentTable={
        <>
          {getContent()}
          {createTicketButton}
        </>
      }
      countRecordsProps={{
        records: totalElements,
      }}
      paginationProps={{
        pageNum,
        pageSize,
        elementsCount: totalElements,
        marginPagesDisplayed: PAGINATION_RANGE_MAP[tableType],
        handleChangePage,
        handleChangePageSize,
        disabled: loading,
        entityName: ticketTabEntityMap[ticketTab],
      }}
      approveOrCancelProps={{
        onApprove: handleDelete,
        isModal: showDeleteModal,
        toggleModal: toggleDeleteModal,
        approveTitle: 'Удалить',
        approveBtnProps: {
          appearance: 'outline',
          className: styles.ticketsTableContainer__iconDelete,
        },
        cancelTitle: 'Отмена',
        text: `Вы уверены, что хотите удалить тикет ${ticket?.number}?`,
      }}
      isWithOutsideClick={isTicketsPage}
    />
  );
};
