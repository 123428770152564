import { getValueFromValueType } from 'components';

import { TriggerAttribute, TriggerAttributeIds } from '../types';

const NAME_MAP: Record<string, string> = {
  organizations: 'organization',
  systems: 'system',
};

export const prepareAttributeForRequest = (attributes: TriggerAttribute[]) => {
  return attributes.reduce<TriggerAttributeIds>((acc, attribute) => {
    const title = getValueFromValueType(attribute.attribute);
    const { value } = attribute;
    const result = { ...acc };

    const ids = Array.isArray(value)
      ? value.map((item) => item.value)
      : value && [value.value];

    if (title) {
      result[title] = ids || [];
    }

    return result;
  }, {});
};

const getIds = (attribute: TriggerAttribute) => {
  const { value } = attribute;

  const isTicketTypes =
    getValueFromValueType(attribute.attribute) === 'ticketTypes';

  if (Array.isArray(value)) {
    return value.map((item) => item.value);
  }

  if (isTicketTypes && value?.value) {
    return [value.value];
  }

  return value?.value;
};

export const prepareAttributeForSubmit = (attributes: TriggerAttribute[]) => {
  return attributes.reduce<Record<string, string[] | string>>(
    (acc, attribute) => {
      const attributeValue = getValueFromValueType(attribute.attribute) || '';
      const title = NAME_MAP[attributeValue] || attributeValue;
      const result = { ...acc };

      const ids = getIds(attribute);

      if (title) {
        result[title] = ids || [];
      }

      return result;
    },
    {}
  );
};
