import { FC } from 'react';
import { useSelector } from 'react-redux';

import { Table } from 'components';

import { ACCESS_FROM_RESP_FIELDS } from '../../constants';
import { getLoading } from '../../ducks/selectors';
import { Access } from '../../types';
import { AccessesFilter } from '../AccessesFilter';

import { getDataTable } from './utils';

interface AccessesFullTableProps {
  access?: Access[];
  sort?: string;
  handleSort?: (value: string) => void;
  handleRowClick?: (value: string) => void;
  activeRowId?: string;
  showFilterRow?: boolean;
  isFilterActive?: boolean;
}

export const AccessesFullTable: FC<AccessesFullTableProps> = ({
  access,
  sort,
  handleSort,
  handleRowClick,
  activeRowId,
  showFilterRow,
  isFilterActive,
}) => {
  const data = getDataTable(access);

  const loading = useSelector(getLoading);

  return (
    <Table
      data={data}
      columns={ACCESS_FROM_RESP_FIELDS}
      sort={sort}
      onRowClick={handleRowClick}
      onSort={handleSort}
      activeRowId={activeRowId}
      dataLoading={loading}
      isFilterActive={isFilterActive}
      filterComponent={showFilterRow && <AccessesFilter />}
      disabledSort={loading}
    />
  );
};
