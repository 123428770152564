import { FC } from 'react';

import { TicketNavIcon } from 'assets/icons';
import { RouterHref } from 'routes/routerHref';

import styles from './TicketChip.module.scss';

interface TicketChipProps {
  id?: string;
  number?: string;
}

export const TicketChip: FC<TicketChipProps> = ({ id, number }) => {
  return (
    <a
      href={`${RouterHref.Tickets}/${id}`}
      target="_blank"
      rel="noreferrer"
      tabIndex={-1}
      className={styles.ticketChip}
    >
      <TicketNavIcon className={styles.ticketChip__icon} />
      <span className={styles.ticketChip__number}>{number}</span>
    </a>
  );
};
