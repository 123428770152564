import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { PlusIcon } from 'assets/icons';
import { Button, IconButton } from 'components';
import { getScreenWidth } from 'core/ducks/selectors';
import { MainLayout } from 'core/layouts';
import { ScreenWidth } from 'core/types';
import { useCanCreateArticleOrCategory } from 'features/KnowledgeBase/hooks';
import { RouterHref } from 'routes/routerHref';

import styles from './KBLayout.module.scss';

interface KBLayoutProps {
  className?: string;
}

export const KBLayout: FC<KBLayoutProps> = ({ children, className }) => {
  const { push } = useHistory();
  const screenWidth = useSelector(getScreenWidth);

  const isMobile = screenWidth === ScreenWidth.MOBILE;
  const isMobileSmall = screenWidth === ScreenWidth.MOBILE_SMALL;
  const isMobileAll = isMobile || isMobileSmall;

  const canCreateArticle = useCanCreateArticleOrCategory();

  const showButtonCreateDesktop = canCreateArticle && !isMobileAll;
  const showButtonCreateMobile = canCreateArticle && isMobileAll;

  const onCreateLBArticle = () => {
    push(RouterHref.KnowledgeBaseArticleCreate);
  };

  const addButton = (
    <IconButton
      icon={<PlusIcon />}
      appearance="flat"
      className={styles.KBLayout__add}
      onClick={onCreateLBArticle}
    />
  );

  return (
    <MainLayout
      title="База знаний"
      classNameRoot={className}
      customComponent={showButtonCreateMobile ? addButton : undefined}
      slideSetting={
        showButtonCreateDesktop ? (
          <Button icon={<PlusIcon />} onClick={onCreateLBArticle}>
            Создать статью
          </Button>
        ) : undefined
      }
    >
      {children}
    </MainLayout>
  );
};
