import cn from 'clsx';
import { FC, HTMLAttributes, KeyboardEvent, memo, MouseEvent } from 'react';

import styles from './TableRow.module.scss';

interface TableRowProps extends HTMLAttributes<HTMLDivElement> {
  value?: unknown;
  onClick?(value?: unknown): void;
  isActive?: boolean;
  isFilter?: boolean;
  disabled?: boolean;
  deleted?: boolean;
}

export const TableRow: FC<TableRowProps> = memo(
  ({
    children,
    className,
    value,
    onClick,
    isActive,
    isFilter,
    disabled = isActive,
    deleted,
    ...other
    // eslint-disable-next-line sonarjs/cognitive-complexity
  }) => {
    const isClickableRow = !disabled && !deleted;
    // TODO: переписать ф-ю нормально
    const handleClick = (event: MouseEvent<HTMLDivElement>) => {
      const elem = event.target as HTMLElement;
      if (elem?.tagName !== 'INPUT') {
        event.preventDefault();
      }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter' && onClick && value) {
        onClick(value);
      }
    };

    const handleRowClick = () => {
      if (onClick && value) {
        onClick(value);
      }
    };

    return (
      <div
        {...other}
        aria-haspopup
        role="button"
        tabIndex={onClick && !isFilter && isClickableRow ? 0 : -1}
        onMouseDown={!isFilter ? handleClick : undefined}
        onKeyDown={isClickableRow ? handleKeyDown : undefined}
        onClick={isClickableRow ? handleRowClick : undefined}
        className={cn(
          styles.tableRow,
          {
            [styles.tableRow_active]: isActive,
            [styles.tableRow_focusable]: !isFilter,
            [styles.tableRow_clickable]: onClick,
            [styles.tableRow_disabled]: disabled || deleted,
            [styles.tableRow_deleted]: deleted,
          },
          className
        )}
      >
        {children}
      </div>
    );
  }
);
