import cn from 'clsx';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Avatar,
  Button,
  Loader,
  Size,
  Typography,
  TypographyVariants,
} from 'components';
import { Tag } from 'components-new/Tag';
import {
  COLOR_SCHEME_MAP,
  VISIBILITY_TYPE_MAP,
} from 'features/Ticket/constants';
import { getAttachmentDate, getShortName } from 'utils';

import { resetNotesState } from '../../ducks/notes/actions';
import { getNotesLoading, getTicketNotes } from '../../ducks/notes/selectors';
import { Note } from '../../types';
import { TicketNoteAttachments } from '../TicketNoteAttachments';

import styles from './TicketNotes.module.scss';

interface TicketNotesProps {
  onCreateNote: () => void;
  disabled?: boolean;
}

export const TicketNotes: FC<TicketNotesProps> = ({
  onCreateNote,
  disabled,
}) => {
  const dispatch = useDispatch();
  const loading = useSelector(getNotesLoading);
  const notes = useSelector(getTicketNotes);

  useEffect(() => {
    return () => {
      dispatch(resetNotesState());
    };
  }, []);

  const noNotesComponent = (
    <div className={styles.attachmentDetail__noNotes}>
      <Typography variant={TypographyVariants.b3}>
        Здесь еще нет ни одной заметки
      </Typography>
      <Button appearance="outline" onClick={onCreateNote} disabled={disabled}>
        Добавить заметку
      </Button>
    </div>
  );

  const contentNotes = notes?.map((note: Note) => {
    return (
      <div key={note.id} className={styles.attachmentDetail}>
        <div className={styles.attachmentDetail__inner}>
          <div className={styles.attachmentDetail__data}>
            <Typography
              variant={TypographyVariants.b3}
              component="div"
              className={styles.attachmentDetail__userData}
            >
              <Avatar
                size={Size.xs}
                initialsUser={`${note?.userFirstName} ${note?.userLastName}`}
              />
              {getShortName(note?.userFirstName, note?.userLastName)}
            </Typography>
            <Typography variant={TypographyVariants.b3}>
              {getAttachmentDate(note?.created?.toString() || '')}
            </Typography>
          </div>
          {note?.visibility && (
            <div className={styles.attachmentDetail__label}>
              <Tag size="xs" colorScheme={COLOR_SCHEME_MAP[note.visibility]}>
                {VISIBILITY_TYPE_MAP[note.visibility]}
              </Tag>
            </div>
          )}
          <div className={styles.attachmentDetail__info}>
            <span>{note.text}</span>
            <div className={styles.attachmentDetail__infoFiles}>
              <TicketNoteAttachments files={note?.files} />
            </div>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div
      className={cn(styles.attachmentDetail__wrapper, {
        [styles.attachmentDetail__wrapper_noNotes]: !notes?.length,
      })}
    >
      {loading && <Loader />}
      {!notes?.length && !loading && noNotesComponent}
      {contentNotes}
    </div>
  );
};
