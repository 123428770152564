import { DATE_FORMATS } from '../constants';
import { RangeDateType } from '../types';

import { addZeroIfOneDigit } from './addZeroIfOneDigit';

export const getFormattedDateRange = (
  dates?: RangeDateType,
  format?: typeof DATE_FORMATS[number]
) => {
  if (dates?.from) {
    const [yearFrom, monthFrom, dateFrom, hoursFrom, minutesFrom] = [
      new Date(dates.from).getFullYear(),
      new Date(dates.from).getMonth() + 1,
      new Date(dates.from).getDate(),
      new Date(dates.from).getHours(),
      new Date(dates.from).getMinutes(),
    ].map((value) => addZeroIfOneDigit(value));
    if (dates.to) {
      const [yearTo, monthTo, dateTo, hoursTo, minutesTo] = [
        new Date(dates.to).getFullYear(),
        new Date(dates.to).getMonth() + 1,
        new Date(dates.to).getDate(),
        new Date(dates.to).getHours(),
        new Date(dates.to).getMinutes(),
      ].map((value) => addZeroIfOneDigit(value));

      if (format === 'dd.mm.yyyy - dd.mm.yyyy') {
        return `${dateFrom}.${monthFrom}.${yearFrom} - ${dateTo}.${monthTo}.${yearTo}`;
      }
      if (format === 'dd.mm.yyyy  hh:mm - dd.mm.yyyy  hh:mm') {
        return `${dateFrom}.${monthFrom}.${yearFrom}  ${hoursFrom}:${minutesFrom} - ${dateTo}.${monthTo}.${yearTo}  ${hoursTo}:${minutesTo}`;
      }
      return `${dateFrom}.${monthFrom}.${yearFrom} - ${dateTo}.${monthTo}.${yearTo}`;
    }
  }
  return '';
};
