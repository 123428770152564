import cn from 'clsx';
import { nanoid } from 'nanoid';
import { type FC, type MouseEvent, useEffect, useState } from 'react';

import { createBemClass } from 'components-new/helpers/createBemClass';
import { Appearance } from 'components-new/types';

import styles from './Ripple.module.scss';
import { type RippleItem } from './types';

export interface RippleProps {
  appearance?: Appearance;
}

const rootClassName = createBemClass('ripple');

export const Ripple: FC<RippleProps> = ({ appearance = 'default' }) => {
  const [rippleArray, setRippleArray] = useState<RippleItem[]>([]);

  const rippleArrayLength = !!rippleArray.length;

  useEffect(() => {
    let bounce = 0;

    if (rippleArrayLength) {
      window.clearTimeout(bounce);

      bounce = window.setTimeout(() => {
        setRippleArray([]);
        window.clearTimeout(bounce);
      }, 800 * 4);
    }

    return () => {
      window.clearTimeout(bounce);
    };
  }, [rippleArrayLength]);

  const addRipple = (event: MouseEvent) => {
    const rippleContainer = event.currentTarget.getBoundingClientRect();

    const { width } = rippleContainer;
    const { height } = rippleContainer;

    const size = width > height ? width : height;

    const x = event.clientX - rippleContainer.left - size / 2;

    const y = event.clientY - rippleContainer.top - size / 2;

    const newRipple = {
      x,
      y,
      size,
      id: nanoid(),
    };

    setRippleArray((prevState) => [...prevState, newRipple]);
  };

  const ripples =
    rippleArrayLength &&
    rippleArray.map((ripple) => {
      const { x, y, size, id } = ripple;

      const style = {
        top: y,
        left: x,
        width: size,
        height: size,
      };

      return (
        <span
          key={id}
          className={cn(
            styles[rootClassName({ elementName: 'item' })],
            styles[
              rootClassName({
                elementName: 'item',
                modName: appearance,
              })
            ]
          )}
          style={style}
        />
      );
    });

  return (
    <div
      className={styles[rootClassName()]}
      onMouseDown={addRipple}
      role="presentation"
    >
      {ripples}
    </div>
  );
};
