import { RootState } from 'store/rootReducer';

export const getCurrentStateTickets = (state: RootState) =>
  state.desktopCurrentState.tickets;
export const getCurrentStateTotalTickets = (state: RootState) =>
  state.desktopCurrentState.totalTickets;
export const getTicketsStatistic = (state: RootState) =>
  state.desktopCurrentState.ticketsStatistic;
export const getCurrentStatePageNumber = (state: RootState) =>
  state.desktopCurrentState.pageNum;
export const getCurrentStatePageSize = (state: RootState) =>
  state.desktopCurrentState.pageSize;
export const getCurrentStateSort = (state: RootState) =>
  state.desktopCurrentState.sort;
export const getIsCurrentStateTicketsLoading = (state: RootState) =>
  state.desktopCurrentState.isTicketsLoading;
