import cn from 'clsx';
import { FC, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { ArrowPaginationIcon } from 'assets/icons';
import { IconButton, Tabs } from 'components';
import { getIsDesktop, getIsDesktopSmall } from 'core/ducks/selectors';
import { getActiveNotificationId } from 'features/Notifications';
import { ProfileDrop } from 'features/Profile/components';
import { CurrentTicket, getCurrentTicket } from 'features/Tickets';

import {
  getNotificationTabs,
  getProfileTabsUnchecked,
} from '../../ducks/selectors';
import {
  convertProfileTabToTab,
  getTabsWithStatusesProfile,
} from '../../utils';

import styles from './ProfileNotificationsLayout.module.scss';

export const ProfileNotificationsLayout: FC = ({ children }) => {
  const isDesktop = useSelector(getIsDesktop);
  const isDesktopSmall = useSelector(getIsDesktopSmall);
  const tabs = useSelector(getNotificationTabs);
  const ticket = useSelector(getCurrentTicket);
  const activeNotification = useSelector(getActiveNotificationId);
  const tabsWithStatuses = useSelector(getProfileTabsUnchecked);

  const layoutRef = useRef<HTMLDivElement>(null);
  const tabsRef = useRef<HTMLDivElement>(null);

  const tabsToRender = useMemo(
    () =>
      convertProfileTabToTab(
        getTabsWithStatusesProfile(tabs, tabsWithStatuses)
      ),
    [tabs, tabsWithStatuses]
  );

  const tabsList = tabsToRender && (
    <Tabs
      tabs={tabsToRender}
      tabsRef={tabsRef}
      className={styles.notificationsProfile__tabLine}
      classNameTab={styles.notificationsProfile__tabsItem}
    />
  );

  const handleScrollLeft = () => {
    if (tabsRef?.current) {
      tabsRef?.current.scrollBy({
        left: -150,
        behavior: 'smooth',
      });
    }
  };

  const handleScrollRight = () => {
    if (tabsRef?.current) {
      tabsRef?.current.scrollBy({
        left: 150,
        behavior: 'smooth',
      });
    }
  };

  const notificationsHeader = (
    <div className={styles.notificationsProfile__tabs}>
      {tabsList}
      <div className={styles.notificationsProfile__arrows}>
        <IconButton
          appearance="flat"
          icon={
            <ArrowPaginationIcon
              className={cn(
                styles.notificationsProfile__arrowsItem,
                styles.notificationsProfile__arrowsItem_left
              )}
            />
          }
          onClick={handleScrollLeft}
        />
        <IconButton
          appearance="flat"
          icon={
            <ArrowPaginationIcon
              className={styles.notificationsProfile__arrowsItem}
            />
          }
          onClick={handleScrollRight}
        />
      </div>
      <ProfileDrop withFilters withTabs />
    </div>
  );

  const currentTicket = (isDesktop || isDesktopSmall) && (
    <CurrentTicket
      className={styles.notificationsProfile__ticket}
      ticket={activeNotification ? ticket : undefined}
    />
  );

  return (
    <div className={styles.notificationsProfile} ref={layoutRef}>
      <div className={styles.notificationsProfile__content}>
        {notificationsHeader}
        {children}
      </div>
      {currentTicket}
    </div>
  );
};
