import { createSelector } from 'reselect';

import { RootState } from 'store/rootReducer';

import { ScreenWidth, Theme, ThemeActive } from '../types';

export const getScreenWidth = (state: RootState): ScreenWidth | undefined =>
  state.core.screenWidth;
export const getRedirectPath = (state: RootState): string | null | undefined =>
  state.core.redirectPath;
export const getTheme = (state: RootState): Theme | undefined =>
  state.core.theme;
export const getSystemTheme = (state: RootState): ThemeActive | undefined =>
  state.core.systemTheme;
export const getContrastTheme = (state: RootState): ThemeActive | undefined =>
  state.core.contrastTheme;
export const getAlerts = (state: RootState) => state.core.alerts;
export const getBreadCrumbsConfig = (state: RootState) =>
  state.core.breadCrumbsConfig;
export const getHref = (state: RootState) => state.core.href;
export const getPrevHref = (state: RootState) => state.core.prevHref;

export const getIsMobileSmall = createSelector<
  RootState,
  ScreenWidth | undefined,
  boolean
>(
  [getScreenWidth],
  (screenWidth): boolean => screenWidth === ScreenWidth.MOBILE_SMALL
);

export const getIsMobile = createSelector<
  RootState,
  ScreenWidth | undefined,
  boolean
>(
  [getScreenWidth],
  (screenWidth): boolean => screenWidth === ScreenWidth.MOBILE
);

export const getIsTabletSmall = createSelector<
  RootState,
  ScreenWidth | undefined,
  boolean
>(
  [getScreenWidth],
  (screenWidth): boolean => screenWidth === ScreenWidth.TABLET_SMALL
);

export const getIsTablet = createSelector<
  RootState,
  ScreenWidth | undefined,
  boolean
>(
  [getScreenWidth],
  (screenWidth): boolean => screenWidth === ScreenWidth.TABLET
);

export const getIsDesktopSmall = createSelector<
  RootState,
  ScreenWidth | undefined,
  boolean
>(
  [getScreenWidth],
  (screenWidth): boolean => screenWidth === ScreenWidth.DESKTOP_SMALL
);

export const getIsDesktop = createSelector<
  RootState,
  ScreenWidth | undefined,
  boolean
>(
  [getScreenWidth],
  (screenWidth): boolean => screenWidth === ScreenWidth.DESKTOP
);
