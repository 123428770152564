import { KeyValueOption } from 'types/models/meta';

export const getKeyValueFromIdTitle = <
  T extends Array<{ id?: string; title?: string }>
>(
  array?: T
) => {
  return (
    array?.map(
      (item): KeyValueOption => ({
        key: item?.id ?? '',
        value: item?.title ?? '',
      })
    ) || []
  );
};
