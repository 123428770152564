import { call, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';

import { Access } from 'features/Accesses/types';
import {
  fetchResponsibilitiesRequest,
  getCurrentResponsibilityId,
} from 'features/Responsibilities';
import { ResponseWithMeta } from 'store/types';
import { createError } from 'utils';

import {
  createAccessCategory,
  fetchAccessesSuccess,
  hideAccessesLoading,
  showAccessesLoading,
} from './actions';
import { request } from './api/requests';
import { getAccessesFilter, getPropsAccesses } from './selectors';
import { AccessesCategories } from './types';

function* accessesFetchByRespId() {
  try {
    const {
      pageNum,
      pageSize,
      sortAccesses,
    }: ReturnType<typeof getPropsAccesses> = yield select(getPropsAccesses);
    const filter: ReturnType<typeof getAccessesFilter> = yield select(
      getAccessesFilter
    );
    const respId: ReturnType<typeof getCurrentResponsibilityId> = yield select(
      getCurrentResponsibilityId
    );
    if (respId) {
      yield put(showAccessesLoading());
      const accesses: ResponseWithMeta<Access[]> = yield call(
        request.fetchAccessesByRespId,
        respId,
        pageNum,
        pageSize,
        sortAccesses,
        filter
      );
      yield put(fetchAccessesSuccess(accesses));
    }
    yield put(hideAccessesLoading());
  } catch (e) {
    createError(e);
    yield put(hideAccessesLoading());
  }
}

function* accessCreate({ payload }: ReturnType<typeof createAccessCategory>) {
  try {
    yield put(showAccessesLoading());
    yield call(request.createAccessCategory, payload);
    yield put(hideAccessesLoading());
    yield call(fetchResponsibilitiesRequest);
  } catch (e) {
    createError(e);
    yield put(hideAccessesLoading());
  }
}

export function* accessesSaga(): Generator<StrictEffect> {
  yield takeEvery(
    AccessesCategories.FETCH_ACCESS_BY_RESP_ID_REQUEST,
    accessesFetchByRespId
  );
  yield takeEvery(AccessesCategories.ACCESS_CATEGORY_CREATE, accessCreate);
}
