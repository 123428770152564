import { Action } from './action';
import { Organization } from './organization';

export type Responsibility = {
  id: string;
  title?: string;
  description?: string;
  actionList?: Action[];
  organization?: Organization;
  readOnly?: boolean;
};

export enum TableResponsibilitiesTypes {
  FULL = 'FULL',
  FROM_GROUPS = 'FROM_GROUPS',
  FROM_ROLES = 'FROM_ROLES',
  ADD_MODAL = 'ADD_MODAL',
}
