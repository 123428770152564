export const getPickedTimeValue = (
  pickedHoursValue?: string,
  pickedMinutesValue?: string
) => {
  if (pickedHoursValue && pickedMinutesValue) {
    return `${pickedHoursValue} : ${pickedMinutesValue}`.replaceAll('_', '');
  }

  return pickedHoursValue || pickedMinutesValue;
};
