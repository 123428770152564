import { FC, MouseEvent, useEffect, useRef } from 'react';

interface OutsideClickHandlerProps {
  onClickOutside: () => void;
  className?: string;
}

export const OutsideClickHandler: FC<OutsideClickHandlerProps> = ({
  onClickOutside,
  children,
  className,
}) => {
  const rootRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (rootRef.current && !rootRef.current.contains(event.target as Node)) {
        onClickOutside();
      }
    };
    document.addEventListener(
      'mousedown',
      handleClickOutside as unknown as EventListener,
      true
    );
    return () => {
      document.removeEventListener(
        'mousedown',
        handleClickOutside as unknown as EventListener,
        true
      );
    };
  }, [onClickOutside]);

  return (
    <div ref={rootRef} className={className} role="button">
      {children}
    </div>
  );
};
