import { FC } from 'react';

import { InfoIcon } from 'assets/icons';
import { Button, Typography, TypographyVariants } from 'components';

import styles from './StatusChain.module.scss';

interface StatusChainProps {
  onCreateStatus: () => void;
}

export const StatusChain: FC<StatusChainProps> = ({
  onCreateStatus,
  children,
}) => {
  return (
    <div className={styles.statusChain__wrapper}>
      <InfoIcon className={styles.statusChain__icon} />
      <Typography
        variant={TypographyVariants.b3}
        className={styles.statusChain__description}
      >
        <span className={styles.statusChain__description_text}>
          Попробуйте использовать статус
        </span>
        <Typography variant={TypographyVariants.h5} component="span">
          {children}
        </Typography>
      </Typography>
      <Button
        onClick={onCreateStatus}
        className={styles.statusChain__button}
        appearance="outline"
      >
        Применить
      </Button>
    </div>
  );
};
