import { Role } from 'core/types/roles';
import { RootState } from 'store/rootReducer';

export const getRoles = (state: RootState): Role[] | undefined =>
  state.roles.roles;
export const getLoading = (state: RootState): boolean | undefined =>
  state.roles.loading;
export const getLoadingAdd = (state: RootState): boolean | undefined =>
  state.roles.loadingAdd;
export const getCurrentRoleId = (state: RootState): string | undefined =>
  state.roles.roleId;
export const getPropsRoles = (state: RootState) => ({
  pageNum: state.roles.pagination?.pageNum,
  pageSize: state.roles.pagination?.pageSize,
  sortRoles: state.roles?.sort,
  loadingRoles: getLoading(state),
  totalElements: state.roles.pagination?.totalElements,
});
export const getPropsRolesAdd = (state: RootState) => ({
  pageNum: state.roles.paginationAdd?.pageNum,
  loadingAdd: getLoadingAdd(state),
  pageSize: state.roles.paginationAdd?.pageSize,
  totalElements: state.roles.paginationAdd?.totalElements,
});
export const getRolesAdd = (state: RootState) => state.roles.rolesAdd;
export const getRolesAddFilter = (state: RootState) =>
  state.roles.rolesAddFilter || {};
export const getRolesFilter = (state: RootState) =>
  state.roles.rolesFilter || {};
export const getCurrentRole = (state: RootState) => state.roles.currentRole;
export const getIsExistTitle = (state: RootState) => state.roles.isExistTitle;
