import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DEFAULT_PAGINATION_PARAMS } from 'constants/meta';
import { Attributes } from 'core/types';
import { ResponseWithMeta } from 'store/types';
import { KeyValueOption, Pagination } from 'types/models/meta';

import { INITIAL_ATTRIBUTES_VALUES, INITIAL_RULES_FILTER } from '../constants';
import {
  CreateRule,
  FiltersFields,
  Rule,
  RulesAttributeToRequest,
  RulesFilter,
} from '../types';

export interface RulesReducerState {
  rules?: Rule[];
  rule?: Rule;
  loading: boolean;
  pagination: Pagination;
  sort: string;
  ruleId?: string;
  filter: RulesFilter;
  attributesFields: Attributes;
  targetSpecialist?: KeyValueOption[];
  targetWorkgroup?: KeyValueOption[];
  filtersFields?: FiltersFields;
}

const initialState: RulesReducerState = {
  rules: [],
  loading: false,
  pagination: DEFAULT_PAGINATION_PARAMS,
  sort: 'CREATED_AT_DESC',
  filter: INITIAL_RULES_FILTER,
  attributesFields: INITIAL_ATTRIBUTES_VALUES,
};

const rulesSlice = createSlice({
  name: 'rules',
  initialState,
  reducers: {
    showRulesLoading: (state) => {
      state.loading = true;
    },
    hideRulesLoading: (state) => {
      state.loading = false;
    },
    fetchFiltersFieldsRequest: () => {},
    fetchFiltersFieldsSuccess: (
      state,
      action: PayloadAction<FiltersFields>
    ) => {
      state.filtersFields = action.payload;
    },
    fetchAttributesFieldsRequest: (
      _state,
      _action: PayloadAction<RulesAttributeToRequest>
    ) => {},
    fetchAttributesFieldsSuccess: (
      state,
      action: PayloadAction<Attributes>
    ) => {
      state.attributesFields = action.payload;
    },
    fetchSpecialistsRequest: () => {},
    fetchSpecialistsSuccess: (
      state,
      action: PayloadAction<KeyValueOption[]>
    ) => {
      state.targetSpecialist = action.payload;
    },
    fetchWorkGroupsRequest: () => {},
    fetchWorkGroupsSuccess: (
      state,
      action: PayloadAction<KeyValueOption[]>
    ) => {
      state.targetWorkgroup = action.payload;
    },
    fetchRulesSuccess: (
      state,
      action: PayloadAction<ResponseWithMeta<Rule[]>>
    ) => {
      const { content, page, totalElements } = action.payload;

      state.rules = content;
      state.pagination.pageNum = page;
      state.pagination.totalElements = totalElements;
    },
    setCurrentRulesPage: (state, action: PayloadAction<number>) => {
      state.pagination.pageNum = action.payload;
    },
    setSizePage: (state, action: PayloadAction<number>) => {
      state.pagination.pageSize = action.payload;
    },
    setSortRules: (state, action: PayloadAction<string>) => {
      state.sort = action.payload;
    },
    fetchCurrentRule: (_state, _action: PayloadAction<string>) => {},
    setCurrentRuleSuccess: (state, action: PayloadAction<Rule | undefined>) => {
      state.rule = action.payload;
    },
    createRule: (_state, _action: PayloadAction<CreateRule>) => {},
    updateRule: (_state, _action: PayloadAction<CreateRule>) => {},
    setCurrentRuleId: (state, action: PayloadAction<string | undefined>) => {
      state.ruleId = action.payload;
    },
    setRulesFilter: (state, action: PayloadAction<RulesFilter>) => {
      state.filter = action.payload;
    },
    fetchRulesRequest: () => {},
    deleteRuleRequest: (_state, _action: PayloadAction<string>) => {},
    resetCurrentRule: (state) => {
      state.rule = initialState.rule;
      state.ruleId = initialState.ruleId;
    },
    resetRulesState: () => initialState,
  },
});

export const {
  showRulesLoading,
  hideRulesLoading,
  fetchFiltersFieldsRequest,
  fetchFiltersFieldsSuccess,
  fetchAttributesFieldsRequest,
  fetchAttributesFieldsSuccess,
  fetchSpecialistsRequest,
  fetchSpecialistsSuccess,
  fetchWorkGroupsRequest,
  fetchWorkGroupsSuccess,
  updateRule,
  fetchRulesSuccess,
  setSizePage,
  setSortRules,
  fetchCurrentRule,
  setCurrentRuleSuccess,
  createRule,
  setCurrentRuleId,
  setRulesFilter,
  resetRulesState,
  fetchRulesRequest,
  deleteRuleRequest,
  resetCurrentRule,
  setCurrentRulesPage,
} = rulesSlice.actions;
export const rules = rulesSlice.reducer;
