import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { TablesLayout } from 'core/layouts';
import { TableResponsibilitiesTypes } from 'core/types/responsibility';
import { TableRolesTypes } from 'core/types/roles';
import { TableUsersTypes } from 'core/types/user';
import { TableWorkGroupsTypes } from 'core/types/workGroup';
import { resetOrganizationState } from 'features/Organizations';
import {
  resetResponsibilitiesState,
  ResponsibilitiesTableContainer,
} from 'features/Responsibilities';
import { resetRolesState, RolesTableContainer } from 'features/Roles';
import { resetUsersState, UsersTableContainer } from 'features/Users';

import { WorkGroupsTableContainer } from './components';
import { fetchWorkGroupsRequest, resetWorkGroupsState } from './ducks/actions';
import styles from './WorkGroups.module.scss';

const Main = () => {
  return (
    <div className={styles.workGroups__mainWrapper}>
      <WorkGroupsTableContainer tableType={TableWorkGroupsTypes.FULL} />
      <UsersTableContainer tableType={TableUsersTypes.FROM_GROUPS} />
    </div>
  );
};

const SubResponsibilitiesTable = () => {
  return (
    <ResponsibilitiesTableContainer
      tableType={TableResponsibilitiesTypes.FROM_GROUPS}
    />
  );
};

const SubRolesTable = () => {
  return <RolesTableContainer tableType={TableRolesTypes.FROM_GROUPS} />;
};

export const WorkGroups = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchWorkGroupsRequest());
    return () => {
      dispatch(resetWorkGroupsState());
      dispatch(resetUsersState());
      dispatch(resetResponsibilitiesState());
      dispatch(resetRolesState());
      dispatch(resetOrganizationState());
    };
  }, []);

  return (
    <TablesLayout
      MainTable={[Main]}
      SubTable={[SubResponsibilitiesTable, SubRolesTable]}
      classNameRoot={styles.workGroups}
      classNameSubTables={styles.workGroups__subWrapper}
    />
  );
};
