import { type LegacyRef, type MutableRefObject, useCallback } from 'react';

export const useMergeRefs = <T>(
  refs: Array<MutableRefObject<T> | LegacyRef<T>>
) => {
  return useCallback(
    (element: T) => {
      refs.forEach((ref) => {
        if (typeof ref === 'function') {
          ref(element);
        } else if (ref) {
          // eslint-disable-next-line no-param-reassign
          (ref as MutableRefObject<T | null>).current = element;
        }
      });
    },
    [refs]
  );
};
