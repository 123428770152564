import { CustomFieldType } from 'components/types';

import { SettingField } from '../../SettingFields/types';
import { CustomFieldTypeForFilter } from '../types';

export const getCustomFieldType = (customColumns?: SettingField[]) => {
  const result: Record<string, CustomFieldTypeForFilter> = {};

  if (!customColumns) {
    return {};
  }

  customColumns?.forEach(({ customFieldType, naturalKey, values }) => {
    if (customFieldType) {
      result[naturalKey] = {
        fieldId: naturalKey,
        type: customFieldType,
        ...(customFieldType === CustomFieldType.FIELD_LIST && {
          values,
        }),
      };
    }
  });

  return result;
};
