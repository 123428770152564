import { RenderTypes, TableColumns } from 'components';
import { OrganizationType } from 'core/types';
import { TableUsersTypes, UsersFilter } from 'core/types/user';

export const TABLE_HEADER_FULL_FIELDS: TableColumns = [
  {
    title: 'Логин',
    sortField: 'login',
    fieldName: 'login',
    renderType: RenderTypes.ICON_BLOCK,
  },
  { title: 'Фамилия', sortField: 'lastName', fieldName: 'lastName' },
  {
    title: 'Имя',
    sortField: 'firstName',
    fieldName: 'firstName',
  },
  {
    title: 'Отчество',
    sortField: 'middleName',
    fieldName: 'middleName',
  },
  {
    title: 'Организация',
    fieldName: 'organizationId',
    sortField: 'organization',
  },
  {
    title: 'E-mail',
    fieldName: 'email',
    sortField: 'email',
    renderType: RenderTypes.ICON_BLOCK,
  },
  {
    title: 'Телефон',
    sortField: 'phoneNumber',
    fieldName: 'phoneNumber',
    renderType: RenderTypes.ICON_BLOCK,
  },
];

export const TABLE_HEADER_FROM_GROUPS_FIELD: TableColumns = [
  {
    title: 'Логин',
    sortField: 'login',
    fieldName: 'login',
    renderType: RenderTypes.ICON_BLOCK,
  },
  { title: 'Фамилия', sortField: 'lastName', fieldName: 'lastName' },
  {
    title: 'Имя',
    sortField: 'firstName',
    fieldName: 'firstName',
  },
  {
    title: 'Отчество',
    sortField: 'middleName',
    fieldName: 'middleName',
  },
  {
    title: 'E-mail',
    fieldName: 'email',
    renderType: RenderTypes.ICON_BLOCK,
    sortField: 'email',
  },
  {
    title: 'Телефон',
    sortField: 'phoneNumber',
    fieldName: 'phoneNumber',
    renderType: RenderTypes.ICON_BLOCK,
  },
];

export const ADD_MODAL_FROM_GROUPS_FIELDS: TableColumns = [
  {
    title: 'Логин',
    fieldName: 'login',
    renderType: RenderTypes.ICON_BLOCK,
  },
  { title: 'Фамилия', fieldName: 'lastName' },
  {
    title: 'Имя',
    fieldName: 'firstName',
  },
  {
    title: 'Отчество',
    fieldName: 'middleName',
  },
  {
    title: 'Организация',
    fieldName: 'organizationId',
  },
  {
    title: 'E-mail',
    fieldName: 'email',
    renderType: RenderTypes.ICON_BLOCK,
  },
  {
    title: 'Телефон',
    fieldName: 'phoneNumber',
    renderType: RenderTypes.ICON_BLOCK,
  },
];

export const TABLE_HEADER_FROM_DESKTOP_EMPLOYEES_FIELDS: TableColumns = [
  {
    title: 'ФИО',
    fieldName: 'fio',
  },
  { title: 'Взято в работу', fieldName: 'inWork' },
  { title: 'Закрыто тикетов', fieldName: 'closed' },
  { title: 'Просрочено тикетов', fieldName: 'expired' },
  { title: 'Ср. время решения', fieldName: 'timeOfSolve' },
];

export const INITIAL_USERS_FILTER = {
  login: '',
  lastName: '',
  firstName: '',
  middleName: '',
  email: '',
  phoneNumber: '',
  organizationId: null,
};

export const PAGINATION_RANGE_MAP: Record<TableUsersTypes, number | undefined> =
  {
    [TableUsersTypes.FULL]: 1,
    [TableUsersTypes.FROM_GROUPS]: 1,
    [TableUsersTypes.ADD_MODAL]: 0,
    [TableUsersTypes.FROM_DESKTOP]: 1,
  };

export const CUSTOMER_USERS_FILTER: UsersFilter = {
  fullName: '',
  organizationType: OrganizationType.CUSTOMER,
};

export const SERVICE_USERS_FILTER: UsersFilter = {
  fullName: '',
  organizationType: OrganizationType.SERVICE,
};

export const DEFAULT_SORT_FILTER_EXECUTOR = 'FIRSTNAME_ASC';
