import cn from 'clsx';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { RollUpButton, Typography, TypographyVariants } from 'components';
import { TicketSLAType } from 'core/types/ticket';
import { getDurationTime } from 'utils';

import { getSlaStatistic } from '../../ducks/Employees/selectors';
import { EmployeesSLATimer } from '../EmployeesSlaTimer';

import styles from './EmployeesSla.module.scss';

export const EmployeesSLA = () => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const toggleOpen = () => setIsOpen((prev) => !prev);

  const slaStatistic = useSelector(getSlaStatistic);

  return (
    <div className={styles.employeesSla}>
      <div
        className={cn(styles.employeesSla__header, {
          [styles.employeesSla__header_close]: !isOpen,
        })}
      >
        <RollUpButton
          isOpen={isOpen}
          toggleButton={toggleOpen}
          className={styles.employeesSla__closeButton}
        />
        <Typography
          variant={TypographyVariants.h4}
          className={styles.employeesSla__title}
        >
          SLA
        </Typography>
      </div>
      <div
        className={cn(styles.employeesSla__content, {
          [styles.employeesSla__content_close]: !isOpen,
        })}
      >
        <EmployeesSLATimer
          type={TicketSLAType.REACTION}
          className={styles.employeesSla__slaTimer}
          avgTime={getDurationTime(slaStatistic?.avgReactionSeconds || 0)}
          avgExpireTime={getDurationTime(
            slaStatistic?.avgReactionExpireSeconds || 0
          )}
        />
        <EmployeesSLATimer
          type={TicketSLAType.DECISION}
          avgTime={getDurationTime(slaStatistic?.avgInWorkSeconds || 0)}
          avgExpireTime={getDurationTime(
            slaStatistic?.avgExpireDecisionSeconds || 0
          )}
        />
      </div>
    </div>
  );
};
