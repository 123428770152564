import cn from 'clsx';
import { type FC, type HTMLAttributes, useMemo } from 'react';

import {
  AttachmentsIcon,
  CloseClearIcon,
  DoneIcon,
  WarningNewIcon,
} from 'assets/icons';
import { createBemClass } from 'components-new/helpers/createBemClass';
import { formatFileSize } from 'components-new/helpers/formatFileSize';
import { IconButtonAlt } from 'components-new/IconButtonAlt';
import { Spin } from 'components-new/Spin';
import { Typography } from 'components-new/Typography';

import styles from './FileItem.module.scss';
import { type FileItemStatus } from './types';

export interface FileItemProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {
  name: string;
  size: number;
  onDelete?: VoidFunction;
  status?: FileItemStatus;
}

const rootClassName = createBemClass('file-item');

export const FileItem: FC<FileItemProps> = ({
  className,
  name,
  size,
  onDelete,
  status = 'default',
  ...other
}) => {
  const isStatusLoading = status === 'loading';
  const isStatusError = status === 'error';

  const statusElement = useMemo(() => {
    const classes = cn(
      styles[rootClassName({ elementName: 'status' })],
      styles[
        rootClassName({
          elementName: 'status',
          modName: status,
        })
      ]
    );

    switch (status) {
      case 'default':
        return null;
      case 'loading':
        return <Spin size="xxs" />;
      case 'success':
        return <DoneIcon className={classes} />;
      case 'error':
        return <WarningNewIcon className={classes} />;
      default:
        // eslint-disable-next-line
        const _: never = status;
        return null;
    }
  }, [status]);

  return (
    <div
      {...other}
      className={cn(
        styles[rootClassName()],
        {
          [styles[rootClassName({ modName: 'error' })]]: isStatusError,
        },
        className
      )}
    >
      <AttachmentsIcon
        className={styles[rootClassName({ elementName: 'icon' })]}
        height={16}
        width={16}
      />
      <Typography className={styles[rootClassName({ elementName: 'name' })]}>
        {name}
      </Typography>
      <Typography
        className={styles[rootClassName({ elementName: 'size' })]}
        variant="b4"
      >
        {formatFileSize(size)}
      </Typography>
      {statusElement}
      {onDelete && (
        <IconButtonAlt
          className={styles[rootClassName({ elementName: 'delete-button' })]}
          colorScheme={isStatusError ? 'red' : 'grey'}
          disabled={isStatusLoading}
          icon={<CloseClearIcon />}
          onClick={onDelete}
          type="button"
        />
      )}
    </div>
  );
};
