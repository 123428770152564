import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { KBCategoryMainBlock, KBMainWelcome } from './components';
import { resetArticlesMainState } from './ducks/actions';
import styles from './KBMain.module.scss';

export const KBMain = () => {
  const dispatch = useDispatch();

  useEffect(
    () => () => {
      dispatch(resetArticlesMainState());
    },
    []
  );
  return (
    <>
      <KBMainWelcome />
      <KBCategoryMainBlock className={styles.KBMain__categories} withHeader />
    </>
  );
};
