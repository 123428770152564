import cn from 'clsx';
import { FC, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  CommentFile,
  Delete2Icon,
  FlagIcon,
  LabelIcon,
  Refresh2Icon,
  TicketSmall,
} from 'assets/icons';
import {
  PopoverButton,
  PopoverContainer,
  Typography,
  TypographyVariants,
} from 'components';
import {
  CONTRACT_STATUS_TITLE_MAP,
  CONTRACT_TYPE_TITLE_MAP,
  HEADER_TYPE,
  NO_DATA,
} from 'core/constants';
import { getIsMobile, getIsMobileSmall } from 'core/ducks/selectors';
import { MainLayout } from 'core/layouts';
import { ApproveOrCancel } from 'core/modals';
import { ContractStatus } from 'core/types';
import { ActionList } from 'core/types/auth';
import { getIsAccessedAction } from 'features/Auth';
import { ContractSign } from 'features/Contract/components';
import {
  deleteContractRequest,
  resetPartlyContractDetailStateForProlongation,
  setProlongationContract,
} from 'features/Contract/ducks/actions';
import {
  getContractDetail,
  getLoadingContractDetail,
} from 'features/Contract/ducks/selectors';
import { useContractId } from 'features/Contract/hooks';
import { ProgressStatus } from 'features/Contract/types';
import { RouterHref } from 'routes/routerHref';
import { getFormatDateWithoutTime } from 'utils';

import styles from './ContractLayout.module.scss';

export const ContractLayout: FC = ({ children }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const contract = useSelector(getContractDetail);
  const loading = useSelector(getLoadingContractDetail);

  const isMobile = useSelector(getIsMobile);
  const isMobileSmall = useSelector(getIsMobileSmall);
  const isAccessToDeleteContract = useSelector(
    getIsAccessedAction(ActionList.DeleteEntries)
  );

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const contractId = useContractId();

  const isMobileAll = isMobile || isMobileSmall;

  const prepareDate = contract && getFormatDateWithoutTime(contract?.endDate);

  const status = contract && CONTRACT_STATUS_TITLE_MAP[contract.status];

  const prolongationDisabled = contract?.status !== ContractStatus.NOT_SIGNED;

  const contractStatusStylesMap = useMemo(() => {
    const color = {
      [ContractStatus.ACTIVE]: styles.contract__status_active,
      [ContractStatus.CLOSED]: styles.contract__status_closed,
      [ContractStatus.PLANNED]: styles.contract__status_planned,
      [ContractStatus.NOT_SIGNED]: styles.contract__status_notSigned,
    };
    return contract?.status && color[contract.status];
  }, [contract?.status]);

  const toggleDeleteModal = () => {
    setShowDeleteModal((prev) => !prev);
  };

  const handleDelete = () => {
    if (contractId) {
      dispatch(deleteContractRequest(contractId));
    }
  };

  const handleShowModalDelete = () => {
    if (contractId) {
      setShowDeleteModal(true);
    }
  };

  const infoBlocksConfig = useMemo(
    () => [
      {
        title: 'Статус',
        info: contractId ? status : NO_DATA,
        icon: (
          <div
            className={cn(styles.contract__iconStatus, contractStatusStylesMap)}
          >
            <LabelIcon />
          </div>
        ),
      },
      {
        title: 'Дата окончания',
        info: contractId ? prepareDate : NO_DATA,
        icon: <TicketSmall className={styles.contract__icon} />,
      },
      {
        title: 'Тип',
        info:
          contractId && contract?.type
            ? CONTRACT_TYPE_TITLE_MAP[contract?.type]
            : NO_DATA,
        icon: <FlagIcon className={styles.contract__icon} />,
      },
    ],
    [contractId, status, prepareDate, contract?.type]
  );

  const breadCrumbsConfig = useMemo(
    () => [
      { title: 'Контракты', path: RouterHref.AdminContracts },
      { title: `Контракт #${contract?.number}` },
    ],
    [contract?.number]
  );

  const goToCreateContract = () => {
    push(RouterHref.AdminCreateContract);
    dispatch(setProlongationContract(ProgressStatus.IN_PROGRESS));
    dispatch(resetPartlyContractDetailStateForProlongation());
  };

  const dropContent =
    isAccessToDeleteContract && contractId ? (
      <PopoverContainer className={styles.contract__buttons}>
        <PopoverButton
          className={styles.contract__button}
          icon={<Refresh2Icon className={styles.contract__button_refresh} />}
          onClick={goToCreateContract}
          disabled={prolongationDisabled}
        >
          <Typography>Продлить</Typography>
        </PopoverButton>

        <PopoverButton
          className={styles.contract__button}
          icon={<Delete2Icon className={styles.contract__button_delete} />}
          onClick={handleShowModalDelete}
          disabled={!contract}
        >
          <Typography>Удалить</Typography>
        </PopoverButton>
      </PopoverContainer>
    ) : undefined;

  const contractTitleComponent =
    contractId && contract?.number ? (
      <div className={styles.contract__title}>
        <Typography
          variant={TypographyVariants.h1}
        >{`Контракт #${contract?.number}`}</Typography>
        {!isMobileAll && (
          <ContractSign status={contract?.status} contractId={contractId} />
        )}
      </div>
    ) : (
      'Создать контракт'
    );

  return (
    <>
      <MainLayout
        headerType={HEADER_TYPE}
        title={contractTitleComponent}
        titleIcon={
          <CommentFile
            className={cn(styles.contract__icon, styles.contract__icon_main)}
          />
        }
        withGoBack={isMobileAll}
        breadCrumbsConfig={contractId ? breadCrumbsConfig : undefined}
        infoBlocksConfig={infoBlocksConfig}
        classNameTitleInfoBlock={styles.contract__iconWrapper}
        dropContent={dropContent}
        loading={loading && !contract}
      >
        {children}
      </MainLayout>
      <ApproveOrCancel
        onApprove={handleDelete}
        isModal={showDeleteModal}
        toggleModal={toggleDeleteModal}
        approveTitle="Удалить"
        approveBtnProps={{
          appearance: 'outline',
          className: styles.contract__iconDelete,
        }}
        cancelTitle="Отмена"
        text={`Вы уверены, что хотите удалить контракт ${contract?.title}?`}
      />
    </>
  );
};
