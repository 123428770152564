import cn from 'clsx';
import { ButtonHTMLAttributes, FC, MouseEvent, SVGProps } from 'react';

import { Ripple } from '../Ripple';

import styles from './EllipseIconButton.module.scss';

interface EllipseIconButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: SVGProps<SVGElement>;
}

export const EllipseIconButton: FC<EllipseIconButtonProps> = ({
  className,
  icon,
  disabled,
  onMouseDown,
  ...otherProps
}) => {
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (onMouseDown) {
      onMouseDown(event);
    }
  };

  return (
    <button
      className={cn(styles.ellipseIconButton, className)}
      onMouseDown={handleClick}
      disabled={disabled}
      {...otherProps}
    >
      {icon}
      {!disabled && <Ripple />}
    </button>
  );
};
