import { Ticket } from 'core/types/ticket';
import { DesktopFilters, TicketsStatistic } from 'features/Desktop/types';
import { ResponseWithMeta } from 'store/types';
import { ApiClient, getEnv } from 'utils';

import { endpoints } from './endpoints';

export const requests = {
  fetchTickets: (
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filterValues?: DesktopFilters
  ) =>
    ApiClient.post<ResponseWithMeta<Ticket[]>>({
      baseURL: getEnv('REACT_APP_SLA_URL'),
      url: endpoints.getTickets(),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: filterValues,
    }),
  fetchTicketsStatistic: (filterValues?: DesktopFilters) =>
    ApiClient.post<TicketsStatistic>({
      baseURL: getEnv('REACT_APP_TICKET_REPORT_URL'),
      url: endpoints.getTicketsStatistic(),
      data: filterValues,
    }),
};
