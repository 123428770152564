import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { SELECT_ATTRIBUTES_MAP } from 'core/constants';
import { KeyValueOption } from 'types/models/meta';

import { FieldsRulesType, Rule } from '../types';

export const getNormalizedAttributes = (rule: Rule) => {
  const pickedAttributes = _.omitBy(
    _.pick(rule, [
      'organizations',
      'systems',
      'environments',
      'clients',
      'ticketTypes',
      'ticketPriorities',
    ]),
    _.isEmpty
  );

  return Object.entries(pickedAttributes).map(([key, value]) => {
    const val = value as KeyValueOption[];
    const title = SELECT_ATTRIBUTES_MAP[key as FieldsRulesType];

    return {
      id: uuidv4(),
      isActive: true,
      attribute: {
        title,
        value: key,
      },
      value: val.map((item) => ({ title: item.value, value: item.key })),
    };
  });
};
