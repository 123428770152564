export enum SolutionType {
  TICKET = 'TICKET',
  ARTICLE = 'ARTICLE',
}

export interface SearchData {
  queryText: string;
  onlyTitleSearch: boolean;
}

export type Tab = 'Тикеты' | 'Статьи';
