import { PenIcon, PlusIcon } from 'assets/icons';

export const getChangeButtonProps = (recordsNumber: number) => {
  const icon = recordsNumber ? (
    <PenIcon style={{ width: '20px' }} />
  ) : (
    <PlusIcon style={{ width: '20px' }} />
  );

  const createTitle = recordsNumber ? 'Изменить' : 'Добавить';

  return {
    icon,
    createTitle,
  };
};
