import { Link } from 'react-router-dom';

import { CalendarIcon, CommentFile } from 'assets/icons';
import { Avatar, Size } from 'components';
import { Article } from 'core/types/knowledgeBase';
import { Organization } from 'core/types/organization';
import { getOrgByUserOrgId } from 'core/utils';
import { RouterHref } from 'routes/routerHref';
import { getFormatDateWithYear } from 'utils';

import styles from './getDataTable.module.scss';

interface DataTableConfig {
  articles?: Article[];
  userOrganization?: Organization;
  canCreateArticle: boolean;
}

export const getDataTable = ({
  articles,
  userOrganization,
  canCreateArticle,
}: DataTableConfig) =>
  articles?.map((article) => {
    const {
      title,
      organizations,
      updated,
      parentCategoryTitle,
      categoryTitle,
      userFullName,
      id,
    } = article;

    return {
      id,
      title: (
        <Link
          className={styles.dataTable__title}
          to={`${RouterHref.KnowledgeBase}/${id}`}
        >
          <CommentFile className={styles.dataTable__titleIcon} />
          <span>{title}</span>
        </Link>
      ),
      categoryTitle: parentCategoryTitle ? categoryTitle : ' ',
      userFullName: (
        <div className={styles.dataTable__creator}>
          <Avatar size={Size.xxs} initialsUser={userFullName} />
          <span>{userFullName}</span>
        </div>
      ),
      updated: {
        text: updated ? getFormatDateWithYear(updated?.toString()) : '',
        icon: <CalendarIcon className={styles.dataTable__calendar} />,
      },
      organizationTitle: getOrgByUserOrgId({
        canCreateArticle,
        organizations,
        userOrganization,
      }),
      articleTitle: title,
    };
  }) || [];
