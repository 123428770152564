import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Ticket } from 'core/types/ticket';
import { ResponseWithMeta } from 'store/types';

import { TicketsStatistic } from '../../types';

interface HistoryState {
  tickets?: Ticket[];
  totalTickets?: number;
  ticketsStatistic?: TicketsStatistic;
  isTicketsLoading?: boolean;
  sort?: string;
  pageNum?: number;
  pageSize?: number;
}

const initialState: HistoryState = {
  sort: 'datecreate_DESC',
  pageNum: 0,
  pageSize: 10,
  isTicketsLoading: false,
};

const historySlice = createSlice({
  name: 'desktopHistory',
  initialState,
  reducers: {
    fetchTicketsSuccess: (
      state,
      action: PayloadAction<ResponseWithMeta<Ticket[]>>
    ) => {
      state.tickets = action.payload.content;
      state.totalTickets = action.payload.totalElements;
    },
    fetchTicketsRequest: (_state) => {},
    fetchTicketsStatisticSuccess: (
      state,
      action: PayloadAction<TicketsStatistic>
    ) => {
      state.ticketsStatistic = action.payload;
    },
    fetchTicketsStatisticRequest: (_state) => {},
    setIsTicketsLoading: (state, action: PayloadAction<boolean>) => {
      state.isTicketsLoading = action.payload;
    },
    setPageNumHistory: (state, action: PayloadAction<number | undefined>) => {
      state.pageNum = action.payload;
    },
    setPageSizeHistory: (state, action: PayloadAction<number | undefined>) => {
      state.pageSize = action.payload;
    },
    setSortHistory: (state, action: PayloadAction<string | undefined>) => {
      state.sort = action.payload;
    },
    resetHistoryState: () => initialState,
  },
});

export const {
  fetchTicketsSuccess,
  fetchTicketsRequest,
  fetchTicketsStatisticRequest,
  fetchTicketsStatisticSuccess,
  setIsTicketsLoading,
  setPageNumHistory,
  setPageSizeHistory,
  setSortHistory,
  resetHistoryState,
} = historySlice.actions;
export const desktopHistory = historySlice.reducer;
