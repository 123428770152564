import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Table } from 'components';
import { Role, TableRolesTypes } from 'core/types/roles';
import { getFetchFunction } from 'features/Roles';

import { ROLES_FROM_USERS_FIELDS } from '../../constants';
import { setCurrentRolesPage, setRolesFilter } from '../../ducks/actions';
import { getLoading } from '../../ducks/selectors';
import { RolesFilter as RolesFilterData } from '../../types';
import { RolesFilter } from '../RolesFilter';

import { getDataTable } from './utils';

interface RolesFromUsersTableProps {
  roles?: Role[];
  sort?: string;
  handleSort?: (value: string) => void;
  handleRowClick?: (value: string) => void;
  activeRowId?: string;
  showFilterRow?: boolean;
  isFilterActive?: boolean;
}

export const RolesFromUsersTable: FC<RolesFromUsersTableProps> = ({
  roles,
  sort,
  handleSort,
  handleRowClick,
  activeRowId,
  showFilterRow,
  isFilterActive,
}) => {
  const dispatch = useDispatch();

  const loading = useSelector(getLoading);

  const data = getDataTable(roles);

  const handleFilter = (values: RolesFilterData) => {
    dispatch(setRolesFilter(values));
    dispatch(setCurrentRolesPage(0));
    dispatch(getFetchFunction(TableRolesTypes.FROM_USERS)());
  };

  return (
    <Table
      data={data}
      columns={ROLES_FROM_USERS_FIELDS}
      sort={sort}
      onRowClick={handleRowClick}
      onSort={handleSort}
      activeRowId={activeRowId}
      dataLoading={loading}
      isFilterActive={isFilterActive}
      valueFieldName="id"
      filterComponent={
        showFilterRow && (
          <RolesFilter
            tableType={TableRolesTypes.FROM_USERS}
            onFilter={handleFilter}
          />
        )
      }
      disabledSort={loading}
    />
  );
};
