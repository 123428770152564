import { useDispatch, useSelector } from 'react-redux';

import { setIsError, setIsSuccess } from '../ducks/ticket/actions';
import {
  getIsError,
  getIsSuccess,
  getToastMessage,
} from '../ducks/ticket/selectors';

export const useTicketToast = () => {
  const dispatch = useDispatch();

  const isSuccess = useSelector(getIsSuccess);
  const isError = useSelector(getIsError);
  const toastMessage = useSelector(getToastMessage);

  const handleCloseSuccessToast = () => {
    dispatch(setIsSuccess(false));
  };

  const handleCloseErrorToast = () => {
    dispatch(setIsError(false));
  };

  return {
    isSuccess,
    isError,
    toastMessage,
    handleCloseSuccessToast,
    handleCloseErrorToast,
  };
};
