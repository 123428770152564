import cn from 'clsx';
import { FC } from 'react';

import { DAYS } from '../../constants';

import styles from './Weekdays.module.scss';

interface WeekdaysProps {
  className?: string;
}

export const Weekdays: FC<WeekdaysProps> = ({ className }) => {
  const weekdays = DAYS.map((day) => (
    <div key={day} className={styles.weekdays__day}>
      <div className={styles.weekdays__typography}>{day}</div>
    </div>
  ));

  return <div className={cn(styles.weekdays, className)}>{weekdays}</div>;
};
