import { FC, useCallback, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';

import { BREAKPOINTS } from 'constants/breakpoints';
import { setScreenWidth } from 'core/ducks/actions';
import { ScreenWidth } from 'core/types';

export const withScreenWidth = <Props,>(Component: FC<Props>) => {
  // eslint-disable-next-line sonarjs/cognitive-complexity
  return (props: Props) => {
    const dispatch = useDispatch();

    const changeScreenWidth = useCallback(() => {
      const { innerWidth } = window;
      const { xs, sm, md, lg, xl } = BREAKPOINTS;

      if (innerWidth <= xs) {
        dispatch(setScreenWidth(ScreenWidth.MOBILE_SMALL));
        return;
      }
      if (innerWidth > xs && innerWidth <= sm) {
        dispatch(setScreenWidth(ScreenWidth.MOBILE));
        return;
      }
      if (innerWidth > sm && innerWidth <= md) {
        dispatch(setScreenWidth(ScreenWidth.TABLET_SMALL));
        return;
      }
      if (innerWidth > md && innerWidth <= lg) {
        dispatch(setScreenWidth(ScreenWidth.TABLET));
        return;
      }
      if (innerWidth > lg && innerWidth <= xl) {
        dispatch(setScreenWidth(ScreenWidth.DESKTOP_SMALL));
        return;
      }
      if (innerWidth > xl) {
        dispatch(setScreenWidth(ScreenWidth.DESKTOP));
      }
    }, [dispatch]);

    useLayoutEffect(() => {
      changeScreenWidth();
      window.addEventListener('resize', changeScreenWidth);
      return () => {
        window.removeEventListener('resize', changeScreenWidth);
      };
    }, [changeScreenWidth]);

    return <Component {...props} />;
  };
};
