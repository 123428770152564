import cn from 'clsx';
import { FC } from 'react';

import styles from './TableBody.module.scss';

interface TableBodyProps {
  className?: string;
}

export const TableBody: FC<TableBodyProps> = ({ children, className }) => {
  return <div className={cn(styles.tableBody, className)}>{children}</div>;
};
