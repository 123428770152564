import { CustomFieldType } from 'components';

export const FIELD_NAMES_BY_CUSTOM_FIELD_TYPE = {
  [CustomFieldType.FIELD_DATE]: 'date',
  [CustomFieldType.FIELD_FLAG]: 'flag',
  [CustomFieldType.FIELD_TEXT]: 'text',
  [CustomFieldType.FIELD_LIST]: 'listValue',
};

export const SYSTEM_TICKET_INFO = 'SYSTEM_TICKET_INFO';

export const STEP_ZOOM = 0.45;

export const CUSTOM_WRAPPER_STYLE = {
  height: '828px',
  width: '1386px',
};

export const CUSTOM_CONTENT_STYLE = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
};
