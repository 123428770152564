import { Priority } from 'core/types';

import { JiraPriority } from '../types';

type Args = {
  priorities?: JiraPriority[];
  priority: Priority;
};

export const getJiraPriority = ({ priorities, priority }: Args) => {
  return priorities?.find((item) => item.ticketPriorities?.includes(priority));
};
