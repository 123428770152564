import { Organization } from 'core/types/organization';
import { System } from 'core/types/system';
import { ApiClient, getEnv } from 'utils';

import { endpoints } from './endpoints';

export const requests = {
  fetchOrganizations: () =>
    ApiClient.get<Organization[]>({
      baseURL: getEnv('REACT_APP_TICKET_REPORT_URL'),
      url: endpoints.getOrganizations(),
    }),
  fetchSystems: (id: string) =>
    ApiClient.get<System[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getSystems(id),
    }),
};
