import { WorkGroup } from 'core/types/workGroup';

export const getDataTable = (workGroups?: WorkGroup[]) =>
  workGroups?.map((workGroup) => {
    const { title, description, id } = workGroup;
    return {
      id,
      title,
      description,
    };
  }) || [];
