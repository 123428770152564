import { SelectOption } from 'components';
import { OrganizationType } from 'core/types';
import { Organization } from 'core/types/organization';

import { META_ORGANIZATION } from '../constants';

const mapEntitiesToSelectOptions = <
  T extends { title: string; id?: string; type: OrganizationType }
>(
  entities: T[]
): SelectOption[] => {
  return entities.map(({ title, id, type }: T): SelectOption => {
    return {
      title,
      value: id || '',
      meta: META_ORGANIZATION[type],
    };
  });
};

export const mapOrganizationsToSelectOptions = (
  organizations: Organization[]
): SelectOption[] => {
  return mapEntitiesToSelectOptions(organizations);
};
