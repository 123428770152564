import { EmptyDataType } from './types';

export const TITLES_MAP = {
  [EmptyDataType.NO_DATA]: null,
  [EmptyDataType.NO_DATA_WITH_FILTER]: 'Ничего не найдено',
  [EmptyDataType.NO_DATA_CLIENT]: 'Добро пожаловать',
  [EmptyDataType.NO_DATA_SPECIALIST]: 'Нет активных тикетов',
  [EmptyDataType.NO_EXPIRED_DATA]: null,
  [EmptyDataType.NO_DATA_FILES]: null,
};

export const SUB_TITLES_MAP = {
  [EmptyDataType.NO_DATA]: 'Нет данных',
  [EmptyDataType.NO_DATA_WITH_FILTER]: 'Попробуйте изменить запрос',
  [EmptyDataType.NO_DATA_CLIENT]: 'Начните свое первое обращение, создав тикет',
  [EmptyDataType.NO_DATA_SPECIALIST]:
    'Перейдите во все тикеты и выберите тикет',
  [EmptyDataType.NO_EXPIRED_DATA]: 'Нет просроченых тикетов',
  [EmptyDataType.NO_DATA_FILES]: 'Здесь пока что пусто',
};
