import { SelectOption, ValueType } from 'components';
import { Priority, Status as TicketStatus } from 'core/types';

export interface FormData {
  spField?: ValueType<string>;
  jiraField?: ValueType<string>;
}

export type JiraIntegration = {
  id?: string;
  name?: string;
  isActive?: boolean;
  jiraUrl?: string;
  systemId?: string;
  systemTitle?: string;
  organizationId?: string;
  organizationTitle?: string;
  login?: string;
  projectKey?: string;
  ticketTypeId?: string;
};

export type JiraTicketType = {
  id?: string;
  idType: number;
  name: string;
  subtask: boolean;
  description: string;
  ticketType?: TicketType;
};

export type JiraStatus = {
  id?: string;
  idStatus?: number;
  description?: string;
  name?: string;
  systemId?: string;
  issueTypeId?: string;
  propertyId?: string;
  ticketStatusIds?: Status[];
};

export type JiraPriority = {
  id?: string;
  idPriority?: number;
  self?: string;
  statusColor?: string;
  description?: string;
  name?: string;
  systemId?: string;
  ticketPriorities?: Priority[];
};

export interface JiraIntegrationsFilter extends JiraIntegration {}
export interface JiraIntegrationsFormData
  extends Omit<
    JiraIntegrationsFilter,
    'isActive' | 'systemId' | 'organizationId' | 'ticketTypeId'
  > {
  isActive?: ValueType<boolean>;
  systemId?: ValueType<string>;
  organizationId?: ValueType<string>;
}

export interface JiraIntegrationCreateData extends JiraIntegrationsFormData {
  password?: string;
  ticketTypeId?: ValueType<string>;
  jiraTicketType?: ValueType<string>;
}

export interface JiraIntegrationEditData {
  ticketTypeId?: ValueType<string>;
  jiraTicketType?: ValueType<number>;
}

export type IssueStatusDtos = {
  ticketStatusIds: string[];
} & Pick<JiraStatus, 'idStatus' | 'description' | 'name'>;

export type CreateStatusMapping = {
  systemId?: string;
  ticketTypeId?: string;
  issueStatusCreateDtos: IssueStatusDtos[];
};

export type EditStatusMapping = {
  issueStatusUpdateDtos: IssueStatusDtos[];
} & Pick<CreateStatusMapping, 'systemId' | 'ticketTypeId'>;

export type IssuePriorityCreateDtos = Omit<JiraPriority, 'id' | 'systemId'>;

export type IssuePriorityEditDtos = Omit<JiraPriority, 'systemId'>;

export type CreatePriorityMapping = {
  systemId?: string;
  ticketTypeId?: string;
  issuePriorityCreateDtos: IssuePriorityCreateDtos[];
};

export type EditPriorityMapping = {
  issueTicketPriorityDtos: IssuePriorityEditDtos[];
} & Pick<CreatePriorityMapping, 'systemId' | 'ticketTypeId'>;

export interface JiraIntegrationCreateDataToRequest
  extends Pick<
    JiraIntegrationCreateData,
    'id' | 'name' | 'login' | 'password' | 'jiraUrl' | 'projectKey'
  > {
  ticketTypeId?: string;
  systemId?: string;
  organizationId?: string;
  idType?: string;
  statusMapping: CreateStatusMapping;
  priorityMapping: CreatePriorityMapping;
}

export interface JiraIntegrationEditDataToRequest {
  statusMapping?: EditStatusMapping;
  priorityMapping?: EditPriorityMapping;
  ticketTypeUpdateDto?: JiraTicketTypeMapping;
}

export interface JiraIntegrationCreate
  extends Pick<
    JiraIntegrationCreateData,
    'name' | 'login' | 'password' | 'jiraUrl' | 'projectKey'
  > {
  ticketTypeId?: string;
  systemId?: string;
  organizationId?: string;
}

export interface JiraIntegrationEdit extends JiraIntegrationCreate {
  issueKey: string;
}

export interface CheckConnectionToJiraData
  extends Pick<
    JiraIntegrationCreateData,
    'login' | 'password' | 'jiraUrl' | 'projectKey'
  > {}

export enum JiraConnectionStatus {
  DEFAULT = 'DEFAULT',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export type Status = Pick<
  TicketStatus,
  'id' | 'name' | 'defaultStatus' | 'color'
>;

export type JiraTicketTypeMapping = {
  propertyId: string;
  ticketTypeId: string;
} & Partial<Omit<JiraTicketType, 'ticketType'>>;

export enum FieldType {
  DATE = 'DATE',
  STRING = 'STRING',
}

export type Field = {
  title: string;
  value: string;
  fieldType: FieldType;
};

export type FieldsIntegration = {
  id?: string;
  ticketColumn?: string;
  ticketColumnTitle?: string;
  jiraFieldKey?: string;
  jiraFieldTitle?: string;
  fieldType?: FieldType;
  systemId?: string;
  typeId?: string;
};

export enum TableJiraIntegrationsTypes {
  FULL = 'FULL',
}

export type TicketType = {
  id: string;
  title: string;
};

export type TicketTypes = {
  id: string;
} & SelectOption;

export type JiraStatusesRequest = {
  idType: string;
} & CheckConnectionToJiraData;

export type FetchArgs = {
  systemId: string;
  typeId: string;
};

export interface FetchInfoForJiraIntegrationPayload {
  systemId: string;
  jiraIntegrationId: string;
  typeId: string;
}
