import { createSelector } from 'reselect';

import { getArraySelectOptions, SelectOption } from 'components';
import { CategoryArticle } from 'core/types/knowledgeBase';
import { Organization } from 'core/types/organization';
import { createOrganizationsSelectListSelector } from 'features/Organizations';
import { RootState } from 'store/rootReducer';

export const getArticlesTable = (state: RootState) =>
  state.articlesTable.articles;
export const getArticlesTableLoading = (state: RootState) =>
  state.articlesTable.loading;
export const getCurrentArticleTable = (state: RootState) =>
  state.articlesTable?.article;
export const getPropsArticlesTable = (state: RootState) => ({
  pageNum: state.articlesTable.pagination?.pageNum,
  pageSize: state.articlesTable.pagination?.pageSize,
  sortArticlesTable: state.articlesTable?.sort,
  loadingArticlesTable: getArticlesTableLoading(state),
  totalElements: state.articlesTable.pagination?.totalElements,
});
export const getArticlesTableFilter = (state: RootState) =>
  state.articlesTable.articlesFilter || {};
export const getArticlesTableCategory = (state: RootState) =>
  state.articlesTable.currentCategory;
export const getOrganizationsArticlesTable = (state: RootState) =>
  state.articlesTable.organizations || [];

export const getSubCategoriesSelectList = createSelector<
  RootState,
  CategoryArticle | undefined,
  SelectOption[]
>([getArticlesTableCategory], (category): SelectOption[] => {
  const subCategories = category?.children;
  return getArraySelectOptions(subCategories);
});

export const getOrganizationsArticleTableSelectList = createSelector<
  RootState,
  Organization[],
  SelectOption[]
>([getOrganizationsArticlesTable], (organizations): SelectOption[] => {
  return getArraySelectOptions(organizations);
});

export const getArticlesRightTable = (state: RootState) =>
  state.articlesTable.articlesRightTable || [];
export const getFilterArticlesRightTable = (state: RootState) =>
  state.articlesTable.articlesFilterRightTable || {};
export const getLoadingArticlesRightTable = (state: RootState) =>
  state.articlesTable.loadingArticlesRightTable || false;
export const getPaginationArticlesRightTable = (state: RootState) =>
  state.articlesTable.articlesPaginationRightTable || {};
export const getOrganizationsArticlesRightTable = (state: RootState) =>
  state.articlesTable.organizationsRightTable || [];

export const getCategoriesArticlesRightTable = (state: RootState) =>
  state.articlesTable.categoriesRightTable;
export const getLoadingCategoriesArticlesRightTable = (state: RootState) =>
  state.articlesTable.categoriesLoadingRightTable || false;
export const getSelectedOrganizationArticlesRightTable = (state: RootState) =>
  state.articlesTable.organizationSelected;
export const getSearchValueArticlesRightTable = (state: RootState) =>
  state.articlesTable.searchValue;
export const getOrganizationsArticlesRightTableSelectList =
  createOrganizationsSelectListSelector(getOrganizationsArticlesRightTable);

export const getSelectedOrgIdArticlesRightTable = createSelector<
  RootState,
  SelectOption | null | undefined,
  string[]
>([getSelectedOrganizationArticlesRightTable], (organization) => {
  return organization?.value ? [organization?.value] : [];
});
