import { getUrl } from 'utils';

export const endpoints = {
  getOrganizations: () => getUrl('organization/filter'),
  getCurrentOrganization: (id: string) => getUrl(`organization/${id}`),
  createOrganization: () => getUrl('organization'),
  editOrganization: () => getUrl('organization'),
  deleteOrganization: (id: string) => getUrl(`organization/${id}`),
  getOrganizationForReport: () =>
    'ticket-report/api/v1/report/organization/organization-list',
  hasActiveContract: (id: string) =>
    getUrl(`organization/exists-active-contract/${id}`),
};
