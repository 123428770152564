import { useState } from 'react';

export const usePopover = (): [boolean, () => void] => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const togglePopover = (event?: MouseEvent) => {
    event?.stopPropagation();
    setIsPopoverOpen((prev) => !prev);
  };

  return [isPopoverOpen, togglePopover];
};
