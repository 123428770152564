import { createSelector } from 'reselect';

import {
  EnabledFieldsList,
  ISettingFields,
  SelectOption,
  SettingField,
} from 'components';
import { OrganizationType, TicketType } from 'core/types';
import { Action } from 'core/types/action';
import { ActionList } from 'core/types/auth';
import { Organization } from 'core/types/organization';
import { Ticket } from 'core/types/ticket';
import { TicketsFilterForRender } from 'core/types/tickets';
import { RootState } from 'store/rootReducer';

export const getTickets = (state: RootState) => state.tickets?.tickets;
export const getDesktopTickets = (state: RootState) =>
  state.tickets?.desktopTickets;
export const getLoading = (state: RootState) => state.tickets?.loading;
export const getTicketLoading = (state: RootState) =>
  state.tickets?.ticketLoading;
export const getCurrentTicket = (state: RootState) => state.tickets?.ticket;
export const getIsSearchOpen = (state: RootState) =>
  state.tickets?.isSearchOpen;
export const getPageNum = (state: RootState) =>
  state.tickets.pagination?.pageNum;
export const getPageSize = (state: RootState) =>
  state.tickets.pagination?.pageSize;
export const getTotalElements = (state: RootState) =>
  state.tickets.totalElements;
export const getSort = (state: RootState) => state.tickets?.sort;
export const getFilterValues = (state: RootState): TicketsFilterForRender =>
  state.tickets?.filter || {};
export const getOpenTicketsCount = (state: RootState) =>
  state.tickets.openTicketsCount;
export const getCurrentTicketId = (state: RootState) =>
  state.tickets.currentTicketId;
export const getTicketActionsList = (state: RootState) =>
  state.tickets.ticketActionsList;
export const getStatuses = (state: RootState) => state.tickets.statuses;
export const getFiltersData = (state: RootState) => state.tickets.filtersData;
export const getCurrentFilterId = (state: RootState) =>
  state.tickets.currentFilterId;
export const getIsUsedFilter = (state: RootState) => state.tickets.isUsedFilter;
export const getIsSettingFieldsOpen = (state: RootState) =>
  state.tickets.isSettingFieldsOpen;
export const getSettingFields = (state: RootState) =>
  state.tickets.settingFields;
export const getTicketTypes = (state: RootState) => state.tickets.types;
export const getClientInfoOrgTypeFromTickets = (state: RootState) =>
  state.tickets.ticket?.clientInfo?.organizationType;
export const getIsModalPopoverInner = (state: RootState) =>
  state.tickets.isModalPopoverInner;
export const getValueTabItem = (state: RootState) => state.tickets.valueTabItem;
export const getPropsType = (state: RootState) => {
  return {
    pageNum: state.tickets.paginationType?.pageNum,
    pageSize: state.tickets.paginationType?.pageSize,
    totalElements: state.tickets.paginationType?.totalElements,
    loading: state.tickets.loadingType,
  };
};
export const getPropsStatus = (state: RootState) => {
  return {
    pageNum: state.tickets.paginationStatus?.pageNum,
    pageSize: state.tickets.paginationStatus?.pageSize,
    totalElements: state.tickets.paginationStatus?.totalElements,
    sort: state.tickets?.sortStatus,
    loading: state.tickets.loadingStatus,
  };
};
const getUserOrganization = (state: RootState) =>
  state.currentUser.user?.organization;
const getActionList = (state: RootState): string[] | undefined =>
  state.currentUser.actionList;
const getUserId = (state: RootState): string | undefined =>
  state.currentUser.user?.userId;

export const getIsAccessedTicketAction = (action: ActionList) =>
  createSelector<RootState, Action[] | undefined, boolean>(
    [getTicketActionsList],
    (ticketActions): boolean => {
      return !!ticketActions?.find(
        (ticketAction) => ticketAction.title === action
      );
    }
  );

export const getStatusesSelectList = createSelector<
  RootState,
  string[] | undefined,
  SelectOption[]
>([getStatuses], (statuses): SelectOption[] => {
  if (Array.isArray(statuses)) {
    return statuses.map(
      (status: string): SelectOption => ({
        title: status,
        value: status,
      })
    );
  }
  return [];
});

export const enabledFieldsList = createSelector<
  RootState,
  ISettingFields | undefined,
  EnabledFieldsList
>([getSettingFields], (field) => {
  const getKeys = (columns: SettingField[]): string[] => {
    return columns
      .filter((column) => !column.hidden)
      .map((column) => column.naturalKey);
  };

  const defaultColumns = field?.defaultColumns || [];
  const customColumns = field?.customColumns || [];

  return {
    defaultColumns: getKeys(defaultColumns),
    customColumns: getKeys(customColumns),
  };
});

export const getTicketTypesOptions = createSelector<
  RootState,
  TicketType[] | undefined,
  SelectOption[]
>([getTicketTypes], (types): SelectOption[] => {
  if (Array.isArray(types)) {
    return types.map(
      ({ id, title }): SelectOption => ({
        title,
        value: id,
      })
    );
  }
  return [];
});

export const getIsHideSLA = (orgType?: OrganizationType, ticket?: Ticket) =>
  createSelector<
    RootState,
    string[] | undefined,
    Organization | undefined,
    string | undefined,
    boolean
  >(
    [getActionList, getUserOrganization, getUserId],
    (actionList, organization, userId): boolean => {
      const isAccessToTakeTicketToWork = actionList?.includes(
        ActionList.TakeTicketToWork
      );
      const isAccessToCreateTicket = actionList?.includes(
        ActionList.CreateTicket
      );

      const isClientPartner =
        orgType === OrganizationType.PARTNER &&
        organization?.type === OrganizationType.PARTNER;

      const isMyTicket = userId === ticket?.clientInfo?.id;

      return !!(
        (isClientPartner &&
          isAccessToCreateTicket &&
          !isAccessToTakeTicketToWork) ||
        (isAccessToTakeTicketToWork && isMyTicket)
      );
    }
  );
