import cn from 'clsx';
import { FC } from 'react';

import styles from './Skeleton.module.scss';

interface SkeletonProps {
  borderRadius?: string | number;
  width?: string | number;
  height?: string | number;
  isAbsolute?: boolean;
  className?: string;
}

export const Skeleton: FC<SkeletonProps> = ({
  borderRadius,
  width,
  height,
  isAbsolute = false,
  className,
}) => (
  <div
    className={cn(
      styles.skeleton,
      { [styles.skeleton_absolute]: isAbsolute },
      className
    )}
    style={{ borderRadius, width, height }}
  />
);
