import { FC } from 'react';
import { compose } from 'redux';

import {
  withAuthAwaiter,
  withRedux,
  withScreenWidth,
  withTheme,
} from 'core/hocs';
import { RouterHistory } from 'routes/RouterHistory';
import { register } from 'serviceWorker';
import 'i18n/config';

const App: FC = () => {
  return <RouterHistory />;
};

const withAppData = (Component: FC): FC =>
  compose<FC>(
    withRedux,
    withScreenWidth,
    withTheme,
    withAuthAwaiter
  )(Component);

register();

export default withAppData(App);
