import { call, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';

import { setAlert, setRedirectPath } from 'core/ducks/actions';
import { getErrorAlert, getSuccessAlert } from 'core/layouts';
import { ActionForAlertTypes, Contract } from 'core/types';
import { SLAType } from 'core/types/sla';
import { System } from 'core/types/system';
import {
  getCurrentOrganizationId,
  setFilterOrganizationId,
} from 'features/Organizations';
import { getSystemId, resetSystemsState } from 'features/Systems';
import { fetchWorkGroupsByContractBySystemRequest } from 'features/WorkGroups';
import { RouterHref } from 'routes/routerHref';
import { ResponseWithMeta } from 'store/types';
import { createError } from 'utils';

import { ContractsFilter, ContractsFilterToRequest } from '../types';

import {
  fetchContractRequest,
  fetchContractsSuccess,
  fetchContractSuccess,
  fetchSLAByContractIdBySystemIdSuccessContracts,
  hideContractLoading,
  hideContractsLoading,
  resetCurrentContract,
  setSystemsForContract,
  showContractLoading,
  showContractsLoading,
} from './actions';
import { request } from './api/requests';
import { getContractFilter, getPropsContracts } from './selectors';
import {
  Contracts,
  DeleteContractRequestAction,
  FetchSLAByContractIdBySystemIdRequestContractsAction,
  UpdateContractAction,
} from './types';

const ENTITY_CONTRACT_TITLE = 'Контракт';
export const getFilterContractToRequest = (
  filter: ContractsFilter
): ContractsFilterToRequest => {
  const { organizationId, type, status } = filter;
  return {
    ...filter,
    status: status && !Array.isArray(status) ? status.value : undefined,
    type: type && !Array.isArray(type) ? type.value : undefined,
    organizationId:
      organizationId && !Array.isArray(organizationId)
        ? organizationId.value
        : undefined,
  };
};

function* contractsFetch() {
  try {
    const {
      pageNum,
      pageSize,
      sortContracts,
    }: ReturnType<typeof getPropsContracts> = yield select(getPropsContracts);

    const filter: ReturnType<typeof getContractFilter> = yield select(
      getContractFilter
    );
    yield put(showContractsLoading());
    const contracts: ResponseWithMeta<Contract[]> = yield call(
      request.fetchContracts,
      pageNum,
      pageSize,
      sortContracts,
      getFilterContractToRequest(filter)
    );
    yield put(fetchContractsSuccess(contracts));
    yield put(hideContractsLoading());
  } catch (e) {
    createError(e);
    yield put(hideContractsLoading());
  }
}

function* contractCurrentFetch({
  payload,
}: ReturnType<typeof fetchContractRequest>) {
  try {
    yield put(showContractLoading());
    const contract: Contract = yield call(
      request.fetchCurrentContract,
      payload
    );
    yield put(fetchContractSuccess(contract));
    if (contract?.organization?.id) {
      yield put(setFilterOrganizationId(contract.organization.id));
    }
    // TODO: поправить генератор
    const systemsForContract: System[] = yield call(
      request.fetchSystemListForContract,
      contract.id
    );
    yield put(setSystemsForContract(systemsForContract));
    yield put(hideContractLoading());
  } catch (e) {
    yield put(hideContractLoading());
    createError(e);
  }
}

function* contractsFetchByOrganizationId() {
  try {
    const {
      sortContracts,
      pageNum,
      pageSize,
    }: ReturnType<typeof getPropsContracts> = yield select(getPropsContracts);
    const filter: ReturnType<typeof getContractFilter> = yield select(
      getContractFilter
    );
    const orgId: ReturnType<typeof getCurrentOrganizationId> = yield select(
      getCurrentOrganizationId
    );

    if (orgId) {
      yield put(showContractsLoading());
      const contracts: ResponseWithMeta<Contract[]> = yield call(
        request.fetchContractsByOrganizationId,
        orgId,
        pageNum,
        pageSize,
        sortContracts,
        getFilterContractToRequest(filter)
      );
      yield put(fetchContractsSuccess(contracts));
    }

    yield put(hideContractsLoading());
  } catch (e) {
    createError(e);
    yield put(hideContractsLoading());
  }
}

function* contractsFetchBySystemId() {
  try {
    const {
      sortContracts,
      pageNum,
      pageSize,
    }: ReturnType<typeof getPropsContracts> = yield select(getPropsContracts);

    const filter: ReturnType<typeof getContractFilter> = yield select(
      getContractFilter
    );
    const systemId: ReturnType<typeof getSystemId> = yield select(getSystemId);
    if (systemId) {
      yield put(showContractsLoading());
      const contracts: ResponseWithMeta<Contract[]> = yield call(
        request.fetchContractsBySystemId,
        systemId,
        pageNum,
        pageSize,
        sortContracts,
        getFilterContractToRequest(filter)
      );
      yield put(fetchContractsSuccess(contracts));
    }

    yield put(hideContractsLoading());
  } catch (e) {
    createError(e);
    yield put(hideContractsLoading());
  }
}

function* updateContractRequest({ payload }: UpdateContractAction) {
  try {
    const data: Contract = yield call(request.updateContract, payload);
    if (data) {
      yield put(fetchWorkGroupsByContractBySystemRequest());
      yield put(fetchContractRequest(data.id));
    }
  } catch (e) {
    createError(e);
  }
}

function* deleteContract({ payload }: DeleteContractRequestAction) {
  try {
    const { id, withRedirect } = payload;
    yield call(request.deleteContract, id);
    yield put(
      setAlert(
        getSuccessAlert(ENTITY_CONTRACT_TITLE, ActionForAlertTypes.DELETE)
      )
    );
    if (withRedirect) {
      yield put(setRedirectPath(RouterHref.AdminContracts));
      return;
    }
    yield put(resetCurrentContract());
    yield put(resetSystemsState());
    yield call(contractsFetch);
  } catch (e) {
    yield put(
      setAlert(getErrorAlert(ENTITY_CONTRACT_TITLE, ActionForAlertTypes.DELETE))
    );
    createError(e);
  }
}

function* fetchSLA({
  payload,
}: FetchSLAByContractIdBySystemIdRequestContractsAction) {
  try {
    const { contractId, systemId } = payload;
    if (contractId && systemId) {
      const sla: SLAType = yield call(request.fetchSLA, contractId, systemId);
      yield put(fetchSLAByContractIdBySystemIdSuccessContracts(sla));
    }
  } catch (e) {
    createError(e);
  }
}

export function* contractsSaga(): Generator<StrictEffect> {
  yield takeEvery(Contracts.FETCH_CONTRACTS_REQUEST, contractsFetch);
  yield takeEvery(Contracts.FETCH_CONTRACT_REQUEST, contractCurrentFetch);
  yield takeEvery(
    Contracts.FETCH_CONTRACTS_BY_ORGANIZATION_ID_REQUEST,
    contractsFetchByOrganizationId
  );
  yield takeEvery(
    Contracts.FETCH_CONTRACTS_BY_SYSTEM_ID_REQUEST,
    contractsFetchBySystemId
  );
  yield takeEvery(Contracts.UPDATE_CONTRACT, updateContractRequest);
  yield takeEvery(Contracts.DELETE_CONTRACT_REQUEST, deleteContract);
  yield takeEvery(
    Contracts.FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST,
    fetchSLA
  );
}
