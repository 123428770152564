import { ChangeEvent, FC, useState } from 'react';

import { PenIcon, PlusIcon, SearchIcon } from 'assets/icons';
import {
  Button,
  Input,
  Popover,
  PopoverButton,
  PopoverContainer,
  SelectOption,
  Size,
  ValueType,
} from 'components';
import { DeleteButton } from 'core/components';

import { StorageOrganizationsSelect } from '../StorageOrganizationsSelect';

import styles from './StoragesHeader.module.scss';

interface StoragesHeaderProps {
  currentOrganization: ValueType<string>;
  handleChangeOrganization: (organizations: SelectOption[]) => void;
  toggleCreatePanel: () => void;
  toggleEditPanel: () => void;
  organizationsOptions: SelectOption[];
  handleChangeSearch: (event: ChangeEvent<HTMLInputElement>) => void;
  isAccessCreateStorage: boolean;
  toggleAddModal: () => void;
  addDisabled: boolean;
  toggleCancelModal: () => void;
  deleteStorageDisabled: boolean;
  storagesLoading?: boolean;
}

export const StoragesHeader: FC<StoragesHeaderProps> = ({
  currentOrganization,
  handleChangeOrganization,
  toggleCreatePanel,
  toggleEditPanel,
  organizationsOptions,
  handleChangeSearch,
  isAccessCreateStorage,
  toggleAddModal,
  addDisabled,
  toggleCancelModal,
  deleteStorageDisabled,
  storagesLoading,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  const togglePopover = () => {
    setIsPopoverOpen((prevState) => !prevState);
  };

  const handleClickAddButton = () => {
    toggleAddModal();
    togglePopover();
  };

  const handleClickEditButton = () => {
    toggleEditPanel();
    togglePopover();
  };

  const dropContent = (
    <PopoverContainer>
      <PopoverButton
        onClick={handleClickAddButton}
        icon={<PlusIcon className={styles.storagesHeader__icon} />}
        disabled={addDisabled}
      >
        Добавить файл
      </PopoverButton>
      <PopoverButton
        onClick={handleClickEditButton}
        icon={<PenIcon className={styles.storagesHeader__icon} />}
        disabled={deleteStorageDisabled}
      >
        Редактировать
      </PopoverButton>
      <DeleteButton
        disabled={deleteStorageDisabled}
        onClick={toggleCancelModal}
      />
    </PopoverContainer>
  );

  return (
    <div className={styles.storagesHeader}>
      <div className={styles.storagesHeader__leftBlock}>
        <StorageOrganizationsSelect
          size={Size.s}
          className={styles.storagesHeader__select}
          value={currentOrganization}
          placeholder="Все организации"
          onChange={handleChangeOrganization}
          options={organizationsOptions}
          disabled={storagesLoading}
        />
        <Input
          size={Size.s}
          className={styles.storagesHeader__input}
          label="Поиск"
          rightIcon={
            <SearchIcon className={styles.storagesHeader__iconSearch} />
          }
          disabled={storagesLoading}
          onChange={handleChangeSearch}
        />
      </div>
      {isAccessCreateStorage && (
        <div className={styles.storagesHeader__rightBlock}>
          <Button
            appearance="flat"
            size={Size.xs}
            onClick={toggleCreatePanel}
            icon={<PlusIcon />}
          >
            Создать
          </Button>
          <Popover
            content={dropContent}
            isOpen={isPopoverOpen}
            togglePopover={togglePopover}
          />
        </div>
      )}
    </div>
  );
};
