import cn from 'clsx';
import { FC, useMemo } from 'react';

import {
  checkDatesEqualityWithoutTime,
  getDates,
  getDay,
  resetDateTime,
} from 'components';

import { DAYS } from '../../constants';
import { DatesProps } from '../../types';
import { Date as DateComponent } from '../Date';

import styles from './Dates.module.scss';

export const Dates: FC<DatesProps> = ({
  className,
  selectedDate,
  pickedDate,
  onDateClick,
  pickedDateRange,
  approximateToDate,
  setApproximateToDate,
}) => {
  const [dates] = getDates(selectedDate);

  const onMouseLeave = () => {
    if (setApproximateToDate) {
      setApproximateToDate(undefined);
    }
  };

  const datesList = useMemo(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    () =>
      dates.map((date) => {
        const isPicked =
          checkDatesEqualityWithoutTime(date, pickedDate) ||
          checkDatesEqualityWithoutTime(date, pickedDateRange?.from) ||
          checkDatesEqualityWithoutTime(date, pickedDateRange?.to);

        const isNextDateFrom =
          !!pickedDateRange?.from &&
          resetDateTime(pickedDateRange?.from) <= resetDateTime(date);

        const isPrevDateFrom =
          !!pickedDateRange?.from &&
          resetDateTime(pickedDateRange?.from) >= resetDateTime(date);

        const isPrevDateTo =
          !!pickedDateRange?.to &&
          resetDateTime(pickedDateRange?.to) >= resetDateTime(date);

        const isInRange =
          pickedDateRange?.from?.getTime() !== pickedDateRange?.to?.getTime()
            ? isNextDateFrom && isPrevDateTo
            : false;

        const isFirstInRange = checkDatesEqualityWithoutTime(
          pickedDateRange?.from,
          date
        );

        const isLastInRange = checkDatesEqualityWithoutTime(
          pickedDateRange?.to,
          date
        );

        const isNextApproximateToDate =
          !!approximateToDate &&
          resetDateTime(date) <= resetDateTime(approximateToDate);

        const isPrevApproximateToDate =
          !!approximateToDate &&
          resetDateTime(date) >= resetDateTime(approximateToDate);

        const isInApproximateRange =
          ((isNextDateFrom &&
            isNextApproximateToDate &&
            (!isInRange || isLastInRange)) ||
            (isPrevDateFrom &&
              isPrevApproximateToDate &&
              (!isInRange || isFirstInRange)) ||
            (isPrevDateTo && isPrevApproximateToDate && !isInRange)) &&
          !!pickedDateRange?.from &&
          approximateToDate.getTime() !== pickedDateRange?.from.getTime() &&
          (pickedDateRange?.to
            ? approximateToDate.getTime() !== pickedDateRange?.to.getTime()
            : true);

        const isFirstInApproximateRange =
          (isNextDateFrom &&
            (checkDatesEqualityWithoutTime(pickedDateRange?.from, date) ||
              isLastInRange)) ||
          (isPrevDateFrom &&
            checkDatesEqualityWithoutTime(approximateToDate, date));

        const isLastInApproximateRange =
          (!!approximateToDate &&
            isNextDateFrom &&
            checkDatesEqualityWithoutTime(approximateToDate, date) &&
            (!isPicked || isFirstInRange)) ||
          (!!approximateToDate &&
            !!pickedDateRange?.from &&
            isPrevDateFrom &&
            checkDatesEqualityWithoutTime(pickedDateRange?.from, date) &&
            ((!!pickedDateRange.to && isLastInRange) ||
              (isFirstInApproximateRange &&
                resetDateTime(approximateToDate) <=
                  resetDateTime(pickedDateRange.from))));

        const isCurrentMonthDate = date.getMonth() === selectedDate.getMonth();

        const isWeekend =
          getDay(date) === DAYS.indexOf('Сб') ||
          getDay(date) === DAYS.indexOf('Вс');

        const isToday = checkDatesEqualityWithoutTime(date, new Date());

        const onMouseEnter = () => {
          if (setApproximateToDate) {
            setApproximateToDate(date);
          }
        };

        return (
          <DateComponent
            key={date.toString()}
            onClick={() => onDateClick(date)}
            date={date}
            isCurrentMonthDate={isCurrentMonthDate}
            isWeekend={isWeekend}
            isToday={isToday}
            isPicked={isPicked}
            isInRange={isInRange}
            isFirstInRange={isFirstInRange}
            isLastInRange={isLastInRange}
            isInApproximateRange={isInApproximateRange}
            isFirstInApproximateRange={isFirstInApproximateRange}
            isLastInApproximateRange={isLastInApproximateRange}
            onMouseEnter={onMouseEnter}
          />
        );
      }),
    [dates]
  );

  return (
    <div className={cn(styles.dates, className)} onMouseLeave={onMouseLeave}>
      {datesList}
    </div>
  );
};
