import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MainLayout } from 'core/layouts';
import { useArticleId } from 'features/KnowledgeBase/views/KBArticle/hooks';
import { KBCreateArticle } from 'features/KnowledgeBase/views/KBCreateArticle';
import { KBCreateArticleHeader } from 'features/KnowledgeBase/views/KBCreateArticle/components';
import { useCreateArticleForm } from 'features/KnowledgeBase/views/KBCreateArticle/hooks';

import {
  fetchCurrentArticleRequestArticleCreate,
  resetArticleCreateState,
} from '../../views/KBCreateArticle/ducks/actions';
import { getCurrentArticle } from '../../views/KBCreateArticle/ducks/selectors';

interface KBCreateArticlePageProps {
  isEditMode?: boolean;
}

const KBCreateArticlePage: FC<KBCreateArticlePageProps> = ({
  isEditMode = false,
}) => {
  const dispatch = useDispatch();
  const articleId = useArticleId();
  const article = useSelector(getCurrentArticle);

  useEffect(() => {
    if (articleId && isEditMode) {
      dispatch(fetchCurrentArticleRequestArticleCreate(articleId));
    }
  }, [articleId]);

  useEffect(
    () => () => {
      dispatch(resetArticleCreateState());
    },
    []
  );

  const data = useCreateArticleForm({ article, isEditMode });

  return (
    <MainLayout withHeader={false}>
      <KBCreateArticleHeader form={data} isEditMode={isEditMode} />
      <KBCreateArticle form={data} />
    </MainLayout>
  );
};

export default KBCreateArticlePage;
