import cn from 'clsx';
import { FC, SVGProps } from 'react';

import { Card, Typography, TypographyVariants } from 'components';
import { Status } from 'core/types';

import { HistoryType, MessageType } from '../../types';

import styles from './TicketHistoryTab.module.scss';

interface TicketHistoryTabProps {
  className?: string;
  icon?: SVGProps<SVGElement>;
  date?: string;
  personTriggeredName?: string;
  type?: HistoryType;
  message: MessageType;
  statusInfo: Status;
}

const iconStylesMap = {
  [MessageType.CREATE]: styles.ticketHistoryTab__iconWrapper_create,
  [MessageType.APPOINT]: styles.ticketHistoryTab__iconWrapper_appoint,
  [MessageType.IN_WORK]: styles.ticketHistoryTab__iconWrapper_inWork,
  [MessageType.WAITING_TO_CLOSE]:
    styles.ticketHistoryTab__iconWrapper_waitingToClose,
  [MessageType.WAITING_INFO]: styles.ticketHistoryTab__iconWrapper_waitingInfo,
  [MessageType.CLOSED]: styles.ticketHistoryTab__iconWrapper_closed,
  [MessageType.REOPEN]: styles.ticketHistoryTab__iconWrapper_reopen,
};

const getIconStyles = (message: MessageType) => {
  return (
    iconStylesMap[message] ?? styles.ticketHistoryTab__iconWrapper_newAppoint
  );
};

const getStatusText = (type: HistoryType) => {
  return type === HistoryType.CHANGE_STATUS
    ? HistoryType.CHANGE_STATUS
    : 'Назначен новый исполнитель';
};

export const TicketHistoryTab: FC<TicketHistoryTabProps> = ({
  className,
  icon,
  date,
  personTriggeredName,
  type,
  message,
  statusInfo,
}) => {
  const statusChanging = (type === HistoryType.CHANGE_STATUS ||
    type === HistoryType.APPOINT) && (
    <Typography
      variant={TypographyVariants.other}
      className={styles.ticketHistoryTab__subStatus}
    >
      {getStatusText(type)}
    </Typography>
  );

  const customStatusStyle = !statusInfo.defaultStatus
    ? { backgroundColor: `var(--${statusInfo.color})` }
    : undefined;

  return (
    <Card className={cn(styles.ticketHistoryTab, className)}>
      <div className={styles.ticketHistoryTab__leftBlock}>
        <div
          className={cn(
            styles.ticketHistoryTab__iconWrapper,
            getIconStyles(message)
          )}
          style={customStatusStyle}
        >
          {icon}
        </div>
        <div className={styles.ticketHistoryTab__infoBlock}>
          {statusChanging}
          <Typography variant={TypographyVariants.b3}>{message}</Typography>
        </div>
      </div>
      <div className={styles.ticketHistoryTab__container}>
        <Typography
          variant={TypographyVariants.s2}
          className={styles.ticketHistoryTab__date}
        >
          {date}
        </Typography>
        <Typography
          variant={TypographyVariants.other}
          className={styles.ticketHistoryTab__creator}
        >
          {personTriggeredName}
        </Typography>
      </div>
    </Card>
  );
};
