import cn from 'clsx';
import { FC } from 'react';

import { LabelIcon } from 'assets/icons';
import {
  Avatar,
  AvatarColor,
  Size,
  Table,
  Typography,
  TypographyVariants,
} from 'components';
import { User } from 'core/types/user';
import { SLAProgressBar } from 'features/SLA';
import { getDurationTime, getFormatDisplayedTime } from 'utils';

import { TABLE_HEADER_FROM_DESKTOP_EMPLOYEES_FIELDS } from '../../constants';

import styles from './UsersFromDesktopTable.module.scss';

const getDataTable = (users?: User[]) =>
  users?.map((user) => {
    const {
      id,
      lastName,
      firstName,
      work,
      closed,
      expired,
      percentFromMaxDecision,
      avgDecisionSeconds,
    } = user;

    const { hours, minutes, seconds } = getDurationTime(
      avgDecisionSeconds || 0
    );

    return {
      id,
      fio: (
        <div className={styles.usersFromDesktopTable__cell}>
          <Avatar
            size={Size.xxs}
            initialsUser={`${firstName} ${lastName}`}
            color={AvatarColor.cyan}
            className={styles.usersFromDesktopTable__avatar}
          />
          <Typography variant={TypographyVariants.b3}>{`${lastName} ${
            firstName ? firstName[0] : '-'
          }.`}</Typography>
        </div>
      ),
      inWork: (
        <div className={styles.usersFromDesktopTable__cell}>
          <LabelIcon
            className={cn(
              styles.usersFromDesktopTable__label,
              styles.usersFromDesktopTable__label_inWork
            )}
          />
          <Typography variant={TypographyVariants.b3}>{work}</Typography>
        </div>
      ),
      closed: (
        <div className={styles.usersFromDesktopTable__cell}>
          <LabelIcon
            className={cn(
              styles.usersFromDesktopTable__label,
              styles.usersFromDesktopTable__label_closed
            )}
          />
          <Typography variant={TypographyVariants.b3}>{closed}</Typography>
        </div>
      ),
      expired: (
        <div className={styles.usersFromDesktopTable__cell}>
          <LabelIcon
            className={cn(
              styles.usersFromDesktopTable__label,
              styles.usersFromDesktopTable__label_expired
            )}
          />
          <Typography variant={TypographyVariants.b3}>{expired}</Typography>
        </div>
      ),
      timeOfSolve: (
        <div className={styles.usersFromDesktopTable__sla}>
          <SLAProgressBar
            expirePercents={100 - (percentFromMaxDecision || 0)}
            className={styles.usersFromDesktopTable__progressBar}
          />
          <Typography
            variant={TypographyVariants.other}
            className={styles.usersFromDesktopTable__timer}
          >
            {`${getFormatDisplayedTime(hours)} : ${getFormatDisplayedTime(
              minutes
            )} : ${getFormatDisplayedTime(seconds)}`}
          </Typography>
        </div>
      ),
    };
  }) || [];

interface UsersFromDesktopTableProps {
  users?: User[];
  sort?: string;
  handleSort?: (value: string) => void;
  loading?: boolean;
}

export const UsersFromDesktopTable: FC<UsersFromDesktopTableProps> = ({
  users,
  sort,
  handleSort,
  loading,
}) => {
  const data = getDataTable(users);

  return (
    <Table
      data={data}
      columns={TABLE_HEADER_FROM_DESKTOP_EMPLOYEES_FIELDS}
      onSort={handleSort}
      sort={sort}
      dataLoading={loading}
      valueFieldName="id"
      disabledSort={loading}
    />
  );
};
