import { Reducer } from 'redux';

import { DEFAULT_PAGINATION_PARAMS } from 'constants/meta';
import { Contract, TicketTypeSettings } from 'core/types';
import { SLAType } from 'core/types/sla';
import { System } from 'core/types/system';
import { WorkGroup, WorkGroupFilter } from 'core/types/workGroup';
import { ReducerMap } from 'store/types';
import { Attachment } from 'types/models/attachment';
import { Pagination } from 'types/models/meta';

import {
  FetchAttachmentsSuccessSupAgDetailAction,
  FetchSlaBySAIdBySystemIdSuccessSupAgDetailAction,
  FetchSupplementaryAgreementSuccessSupAgDetailAction,
  FetchSystemByIdSuccessSupAgDetailAction,
  FetchSystemsBySAIdSuccessSupAgDetailAction,
  FetchTicketTypesSuccessSupAgDetailAction,
  FetchWorkGroupsBySABySystemSuccessSupAgDetailAction,
  GetNumberForCreateSuccessSupAgDetailAction,
  SetFilterWorkGroupsBySABySystemSupAgDetailAction,
  SetPageWorkGroupsBySABySystemSupAgDetailAction,
  SetSizePageWorkGroupsBySABySystemSupAgDetailAction,
  SetSortWorkGroupsBySABySystemSupAgDetailAction,
  SupAgDetailActionsTypes,
  SupplementaryAgreementDetail,
} from './types';

export interface SupplementaryAgreementDetailReducerState {
  supplementaryAgreement?: Contract;
  supplementaryAgreementLoading?: boolean;

  systems?: System[];
  systemsLoading?: boolean;

  groups?: WorkGroup[];
  groupsLoading?: boolean;
  groupsPagination?: Pagination;
  groupsFilter?: WorkGroupFilter;
  groupsSort?: string;

  attachments?: Attachment[];
  attachmentsLoading?: boolean;

  sla?: SLAType;
  slaLoading?: boolean;

  ticketTypes?: TicketTypeSettings[];

  titleForCreateSupAg?: string;
}

const initialState: SupplementaryAgreementDetailReducerState = {
  supplementaryAgreementLoading: false,

  systems: [],
  systemsLoading: false,

  groups: [],
  groupsLoading: false,
  groupsPagination: DEFAULT_PAGINATION_PARAMS,
  groupsFilter: {},
  groupsSort: 'date_create_DESC',

  attachments: [],
  attachmentsLoading: false,

  slaLoading: false,

  ticketTypes: [],
};

const SADetailReducerMap: ReducerMap<
  SupplementaryAgreementDetailReducerState,
  SupAgDetailActionsTypes
> = {
  [SupplementaryAgreementDetail.SUPPLEMENTARY_AGREEMENT_LOADING_SHOW]: (
    state
  ) => {
    return {
      ...state,
      supplementaryAgreementLoading: true,
    };
  },
  [SupplementaryAgreementDetail.SUPPLEMENTARY_AGREEMENT_LOADING_HIDE]: (
    state
  ) => {
    return {
      ...state,
      supplementaryAgreementLoading: false,
    };
  },
  [SupplementaryAgreementDetail.FETCH_SUP_AG_SUCCESS]: (state, action) => {
    const { payload } =
      action as FetchSupplementaryAgreementSuccessSupAgDetailAction;
    return {
      ...state,
      supplementaryAgreement: payload,
    };
  },

  [SupplementaryAgreementDetail.LOADING_SHOW_SYSTEM_BY_ID]: (state) => {
    return {
      ...state,
      systemsLoading: true,
    };
  },
  [SupplementaryAgreementDetail.LOADING_HIDE_SYSTEM_BY_ID]: (state) => {
    return {
      ...state,
      systemsLoading: false,
    };
  },
  [SupplementaryAgreementDetail.FETCH_SYSTEM_BY_ID_SUCCESS]: (
    state,
    action
  ) => {
    const { payload } = action as FetchSystemByIdSuccessSupAgDetailAction;
    return {
      ...state,
      systems: [payload],
    };
  },
  [SupplementaryAgreementDetail.FETCH_SYSTEMS_BY_SUP_AG_ID_SUCCESS]: (
    state,
    action
  ) => {
    const { payload } = action as FetchSystemsBySAIdSuccessSupAgDetailAction;
    return {
      ...state,
      systems: payload,
    };
  },

  [SupplementaryAgreementDetail.RESET_SUP_AG_DETAIL_STATE]: () => {
    return {
      ...initialState,
    };
  },
  [SupplementaryAgreementDetail.ATTACHMENTS_LOADING_SHOW]: (state) => {
    return {
      ...state,
      attachmentsLoading: true,
    };
  },
  [SupplementaryAgreementDetail.ATTACHMENTS_LOADING_HIDE]: (state) => {
    return {
      ...state,
      attachmentsLoading: false,
    };
  },
  [SupplementaryAgreementDetail.FETCH_SUP_AG_ATTACHMENTS_SUCCESS]: (
    state,
    action
  ) => {
    const { payload } = action as FetchAttachmentsSuccessSupAgDetailAction;
    return {
      ...state,
      attachments: payload,
    };
  },

  [SupplementaryAgreementDetail.LOADING_SHOW_WORK_GROUPS_BY_SUP_AG_BY_SYSTEM]: (
    state
  ) => {
    return {
      ...state,
      groupsLoading: true,
    };
  },
  [SupplementaryAgreementDetail.LOADING_HIDE_WORK_GROUPS_BY_SUP_AG_BY_SYSTEM]: (
    state
  ) => {
    return {
      ...state,
      groupsLoading: false,
    };
  },
  [SupplementaryAgreementDetail.FETCH_WORK_GROUPS_BY_SUP_AG_BY_SYSTEM_SUCCESS]:
    (state, action) => {
      const { payload } =
        action as FetchWorkGroupsBySABySystemSuccessSupAgDetailAction;
      const { content, page, totalElements } = payload;
      return {
        ...state,
        groups: content,
        groupsPagination: {
          ...state?.groupsPagination,
          pageNum: page,
          totalElements,
        },
      };
    },
  [SupplementaryAgreementDetail.SET_WORK_GROUPS_PAGE_BY_SUP_AG_BY_SYSTEM]: (
    state,
    action
  ) => {
    const { payload } =
      action as SetPageWorkGroupsBySABySystemSupAgDetailAction;
    return {
      ...state,
      groupsPagination: {
        ...state?.groupsPagination,
        pageNum: payload,
      },
    };
  },
  [SupplementaryAgreementDetail.SET_SIZE_PAGE_WORK_GROUPS_BY_SUP_AG_BY_SYSTEM]:
    (state, action) => {
      const { payload } =
        action as SetSizePageWorkGroupsBySABySystemSupAgDetailAction;
      return {
        ...state,
        groupsPagination: {
          ...state?.groupsPagination,
          pageSize: payload,
        },
      };
    },
  [SupplementaryAgreementDetail.SET_WORK_GROUPS_FILTER_BY_SUP_AG_BY_SYSTEM]: (
    state,
    action
  ) => {
    const { payload } =
      action as SetFilterWorkGroupsBySABySystemSupAgDetailAction;
    return {
      ...state,
      groupsFilter: payload,
    };
  },
  [SupplementaryAgreementDetail.SET_WORK_GROUPS_SORT_BY_SUP_AG_BY_SYSTEM]: (
    state,
    action
  ) => {
    const { payload } =
      action as SetSortWorkGroupsBySABySystemSupAgDetailAction;
    return {
      ...state,
      groupsSort: payload,
    };
  },
  [SupplementaryAgreementDetail.LOADING_SHOW_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST]:
    (state) => {
      return {
        ...state,
        slaLoading: true,
      };
    },
  [SupplementaryAgreementDetail.LOADING_HIDE_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST]:
    (state) => {
      return {
        ...state,
        slaLoading: false,
      };
    },
  [SupplementaryAgreementDetail.FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_SUCCESS]:
    (state, action) => {
      const { payload } =
        action as FetchSlaBySAIdBySystemIdSuccessSupAgDetailAction;
      return {
        ...state,
        sla: payload,
      };
    },
  [SupplementaryAgreementDetail.FETCH_TICKET_TYPES_SUCCESS]: (
    state,
    action
  ) => {
    const { payload } = action as FetchTicketTypesSuccessSupAgDetailAction;
    return {
      ...state,
      ticketTypes: payload,
    };
  },
  [SupplementaryAgreementDetail.GET_NUMBER_FOR_CREATE_SUCCESS]: (
    state,
    action
  ) => {
    const { payload } = action as GetNumberForCreateSuccessSupAgDetailAction;
    return {
      ...state,
      titleForCreateSupAg: payload,
    };
  },
};

export const supplementaryAgreementDetail: Reducer<
  SupplementaryAgreementDetailReducerState,
  SupAgDetailActionsTypes
> = (state = initialState, action) => {
  const reducer = SADetailReducerMap[action.type];
  if (!reducer) {
    return state;
  }
  return reducer(state, action);
};
