import { Action } from 'core/types/action';
import { ActionFilter } from 'features/Actions/types';
import { ResponseWithMeta } from 'store/types';
import { ApiClient, getEnv } from 'utils';

import { endpoints } from './endpoints';

export const request = {
  fetchCreateActions: (
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    organizationId?: string
  ) =>
    ApiClient.post<ResponseWithMeta<Action>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.fetchCreateActions(organizationId),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: {},
    }),
  fetchActionsByRespId: (
    id: string,
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: ActionFilter
  ) =>
    ApiClient.post<ResponseWithMeta<Action[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getActionsByRespId(id),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: { ...filter },
    }),
};
