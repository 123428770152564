export type AttachmentsFiles = {
  id?: string;
  type?: string;
  path: string;
  fileName: string;
  size: string;
  previewPath: string;
  created?: string;
  attachmentFileId?: string;
  attachmentId?: string;
};

export enum ParentType {
  COMMENTS = 'COMMENTS',
  CONTRACT = 'CONTRACT',
  ORGANIZATION = 'ORGANIZATION',
  TICKET = 'TICKET',
  USERS = 'USERS',
  NOTE = 'NOTE',
}

export type Attachment = {
  id?: string;
  parentId?: string;
  parentType: ParentType;
  ticketId?: string;
  attachmentsFiles: AttachmentsFiles[];
  systemFile: string;
  userFirstName?: string;
  userLastName?: string;
  dateCreated?: string;
};

export interface AttachmentOthersFields
  extends Pick<
    AttachmentsFiles,
    'attachmentId' | 'created' | 'attachmentFileId'
  > {}
