export enum NotificationType {
  ALL_CHANGES = 'ALL_CHANGES',
  STATUS_CHANGE = 'STATUS_CHANGE',
  MY_TICKETS = 'MY_TICKETS',
  NEW_TICKETS = 'NEW_TICKETS',
}

export enum EntityTypes {
  TICKET = 'TICKET',
  SYSTEM = 'SYSTEM',
  STORAGE = 'STORAGE',
}

export type NotificationContent = {
  id?: string;
  createdWhen?: string;
  entityId?: string;
  entityType?: EntityTypes;
  linkName?: string;
  notificationTitle?: string;
  notificationType: NotificationType;
  ticketIds?: string[];
  checked?: boolean;
  notificationMessage?: string;
};
