import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Article } from 'core/types/knowledgeBase';
import { AddArticlesPayload } from 'features/UserAssistance/types';
import { ResponseWithMeta } from 'store/types';

interface UserAssistanceState {
  isModal: boolean;
  articles: Article[];
  articlesLoading: boolean;
  article?: Article;
  articleLoading: boolean;
  articlesAll: Article[];
  articlesAllLoading: boolean;
  selectedArticlesIds: string[];
}

const initialState: UserAssistanceState = {
  isModal: false,
  articles: [],
  articlesLoading: false,
  articleLoading: false,
  articlesAll: [],
  articlesAllLoading: false,
  selectedArticlesIds: [],
};

const userAssistanceSlice = createSlice({
  name: 'userAssistance',
  initialState,
  reducers: {
    setIsModal: (state, action: PayloadAction<boolean>) => {
      state.isModal = action.payload;
    },
    fetchArticlesSuccess: (
      state,
      action: PayloadAction<ResponseWithMeta<Article[]>>
    ) => {
      state.articles = action.payload.content;
    },
    setArticlesLoading: (state, action: PayloadAction<boolean>) => {
      state.articlesLoading = action.payload;
    },
    fetchArticlesRequest: (_state) => {},
    fetchArticleRequest: (_state, _action: PayloadAction<string>) => {},
    fetchArticleSuccess: (
      state,
      action: PayloadAction<Article | undefined>
    ) => {
      state.article = action.payload;
    },
    fetchArticlesAllRequest: (_state, _action: PayloadAction<string>) => {},
    fetchArticlesAllSuccess: (
      state,
      action: PayloadAction<ResponseWithMeta<Article[]>>
    ) => {
      state.articlesAll = action.payload.content;
    },
    setArticlesAllLoading: (state, action: PayloadAction<boolean>) => {
      state.articlesAllLoading = action.payload;
    },
    resetUserAssistanceState: () => initialState,
    resetArticlesAll: (state) => {
      state.articlesAll = initialState.articlesAll;
    },
    resetArticles: (state) => {
      state.articles = initialState.articles;
    },
    selectArticleId: (state, action: PayloadAction<string>) => {
      const isSelected = state?.selectedArticlesIds?.find(
        (id) => id === action.payload
      );

      state.selectedArticlesIds = !isSelected
        ? [...(state?.selectedArticlesIds || []), action.payload]
        : state?.selectedArticlesIds?.filter((id) => id !== action.payload);
    },
    resetSelectedArticlesIds: (state) => {
      state.selectedArticlesIds = initialState.selectedArticlesIds;
    },
    setArticleLoading: (state, action: PayloadAction<boolean>) => {
      state.articleLoading = action.payload;
    },
    addArticlesRequest: (
      _state,
      _action: PayloadAction<AddArticlesPayload>
    ) => {},
    deleteArticleRequest: (_state, _action: PayloadAction<string>) => {},
  },
});

export const {
  setIsModal,
  resetUserAssistanceState,
  fetchArticlesSuccess,
  fetchArticlesRequest,
  setArticlesLoading,
  fetchArticleRequest,
  fetchArticleSuccess,
  fetchArticlesAllRequest,
  fetchArticlesAllSuccess,
  setArticlesAllLoading,
  resetArticlesAll,
  resetArticles,
  selectArticleId,
  resetSelectedArticlesIds,
  setArticleLoading,
  addArticlesRequest,
  deleteArticleRequest,
} = userAssistanceSlice.actions;
export const userAssistance = userAssistanceSlice.reducer;
