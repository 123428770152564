import cn from 'clsx';
import { FC } from 'react';

import { TableContainer } from 'components';
import { entityNames } from 'core/constants';
import { useSystemsTableContainer } from 'features/StatusesBuilder/hooks';

import { SystemsTable } from '../SystemsTable';

import styles from './SystemsTableContainer.module.scss';

interface SystemsTableContainerProps {
  className?: string;
}

export const SystemsTableContainer: FC<SystemsTableContainerProps> = ({
  className,
}) => {
  const { methods, state } = useSystemsTableContainer();

  const {
    toggleFilterRow,
    handleChangePage,
    handleChangePageSize,
    handleSort,
    onRowClick,
  } = methods;

  const {
    pageNum,
    pageSize,
    sortSystems,
    totalElements,
    systems,
    currentSystemId,
    showFilterRow,
    isFilterActive,
    searchDisabled,
    loadingSystem,
  } = state;

  return (
    <TableContainer
      classNameRoot={cn(styles.systemsTableContainer, className)}
      headerProps={{
        title: 'Система',
        searchDisabled,
        toggleSearch: toggleFilterRow,
        isSearchOpen: showFilterRow,
        loading: loadingSystem,
      }}
      contentTable={
        <SystemsTable
          systems={systems}
          handleClickSystem={onRowClick}
          activeRowId={currentSystemId}
          handleSort={handleSort}
          sort={sortSystems}
          showFilterRow={showFilterRow}
          isFilterActive={isFilterActive}
        />
      }
      countRecordsProps={{
        records: totalElements,
      }}
      paginationProps={{
        pageNum,
        pageSize,
        elementsCount: totalElements,
        handleChangePage,
        handleChangePageSize,
        disabled: loadingSystem,
        marginPagesDisplayed: 0,
        entityName: entityNames.STATUSES_BUILDER_SYSTEMS,
      }}
    />
  );
};
