import cn from 'clsx';
import { FC } from 'react';

import styles from './SLAProgressBar.module.scss';

interface SLAProgressBarProps {
  className?: string;
  classNameLoaded?: string;
  expirePercents?: number;
  color?: string;
}

export const SLAProgressBar: FC<SLAProgressBarProps> = ({
  className,
  expirePercents,
  classNameLoaded,
  color,
}) => {
  const colorStyles = color ? { backgroundColor: `var(--${color})` } : {};

  const style = { right: `${expirePercents}%`, ...colorStyles };

  return (
    <div className={cn(styles.slaProgressBar, className)}>
      <div
        className={cn(styles.slaProgressBar__loaded, classNameLoaded)}
        style={style}
      />
    </div>
  );
};
