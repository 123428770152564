import { FC, useEffect } from 'react';

import { ArrowLeftIcon, CloseIcon } from 'assets/icons';
import { IconButtonWrapper } from 'components';

import { SimilarSolutionsContent } from './components';
import styles from './SimilarSolutions.module.scss';

interface SimilarSolutionsProps {
  isOpen: boolean;
  toggleIsOpen(): void;
}

export const SimilarSolutions: FC<SimilarSolutionsProps> = ({
  isOpen,
  toggleIsOpen,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      return;
    }
    document.body.style.overflow = 'visible';
  }, [isOpen]);

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);

  if (!isOpen) {
    return null;
  }

  return (
    <div
      className={styles.similarSolutions}
      onMouseDown={toggleIsOpen}
      aria-hidden="true"
    >
      <div
        className={styles.similarSolutions__container}
        onMouseDown={(e) => e.stopPropagation()}
        aria-hidden="true"
      >
        <div className={styles.similarSolutions__header}>
          <p className={styles.similarSolutions__headerTitle}>Поиск решений</p>
          <IconButtonWrapper
            className={styles.similarSolutions__headerCloseButton}
            onClick={toggleIsOpen}
            icon={
              <CloseIcon className={styles.similarSolutions__headerCloseIcon} />
            }
          />
          <IconButtonWrapper
            className={styles.similarSolutions__headerCloseButtonMobile}
            onClick={toggleIsOpen}
            icon={
              <ArrowLeftIcon
                className={styles.similarSolutions__headerCloseIcon}
              />
            }
          />
        </div>
        <SimilarSolutionsContent toggleModal={toggleIsOpen} />
      </div>
    </div>
  );
};
