import { FC } from 'react';

import { ApproveOrCancel } from 'core/modals';

import { ApproveOrCancelProps } from '../ApproveOrCancel';

import styles from './DeleteApproveOrCancel.module.scss';

interface DeleteApproveOrCancelProps
  extends Omit<ApproveOrCancelProps, 'text'> {
  entityTitle: string;
}

export const DeleteApproveOrCancel: FC<DeleteApproveOrCancelProps> = ({
  toggleModal,
  isModal,
  entityTitle,
  onApprove,
  withHideBodyScroll = true,
}) => {
  return (
    <ApproveOrCancel
      text={`Вы уверены, что хотите удалить ${entityTitle}?`}
      toggleModal={toggleModal}
      isModal={isModal}
      onApprove={onApprove}
      approveTitle="Удалить"
      withHideBodyScroll={withHideBodyScroll}
      approveBtnProps={{
        appearance: 'outline',
        className: styles.deleteApproveOrCancel__iconDelete,
      }}
    />
  );
};
