import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ActionList } from 'core/types/auth';
import { getIsAccessedAction } from 'features/Auth';
import {
  fetchContractsByOrganizationsRequest,
  getLoading as getContractsLoading,
  resetContractsState,
} from 'features/Contracts';
import {
  fetchGroupsByOrganizationId,
  getLoading as getWorkGroupsLoading,
  resetWorkGroupsState,
} from 'features/WorkGroups';
import { RouterHref } from 'routes/routerHref';

import {
  deleteOrganizationRequest,
  fetchCurrentOrganization,
  fetchOrganizationsRequest,
  setCurrentOrganizationId,
  setCurrentOrganizationsPage,
  setSizePage,
  setSortOrganizations,
} from '../ducks/actions';
import {
  getCurrentOrganization,
  getCurrentOrganizationId,
  getFilterValues,
  getOrganizations,
  getPropsOrganizations,
} from '../ducks/selectors';

import { useOrganizationDelete } from './useOrganizationDelete';

export const useOrganizationsTableContainer = () => {
  const {
    pageNum,
    pageSize,
    loadingOrganization,
    sortOrganizations,
    totalElements,
  } = useSelector(getPropsOrganizations);
  const organizations = useSelector(getOrganizations);
  const organizationId = useSelector(getCurrentOrganizationId);
  const isAccessToCreateOrganization = useSelector(
    getIsAccessedAction(ActionList.CreateCompany)
  );
  const isAccessToDeleteOrganization = useSelector(
    getIsAccessedAction(ActionList.DeleteEntries)
  );
  const activeOrganization = useSelector(getCurrentOrganization);
  const filterValues = useSelector(getFilterValues);
  const isWorkGroupsLoading = useSelector(getWorkGroupsLoading);
  const isContractsLoading = useSelector(getContractsLoading);

  const [showFilterRow, setShowFilterRow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const toggleDeleteModal = () => {
    setShowDeleteModal((prev) => !prev);
  };

  const { canDeleteOrg, handleShowModalDelete } = useOrganizationDelete({
    orgId: activeOrganization?.id,
    funcToShowModal: setShowDeleteModal,
  });

  const dispatch = useDispatch();

  const { push } = useHistory();

  const handleChangePage = (page: number) => {
    dispatch(setCurrentOrganizationsPage(page));
    dispatch(fetchOrganizationsRequest());
  };

  const handleChangePageSize = (arg: number) => {
    dispatch(setSizePage(arg));
  };

  const createOrganizationHandler = () => {
    push(RouterHref.AdminCreateOrganization);
  };

  const handleSort = useCallback(
    (value: string) => {
      dispatch(setSortOrganizations(value));
      dispatch(fetchOrganizationsRequest());
    },
    [dispatch]
  );

  const handleClickOrganization = useCallback(
    (value: string) => {
      if (organizationId !== value) {
        dispatch(setCurrentOrganizationId(value));
        dispatch(fetchCurrentOrganization(value));
        dispatch(resetWorkGroupsState());
        dispatch(resetContractsState());
        dispatch(fetchGroupsByOrganizationId());
        dispatch(fetchContractsByOrganizationsRequest());
      }
    },
    [dispatch, organizationId]
  );

  const toggleFilterRow = () => {
    setShowFilterRow((prevState) => !prevState);
  };

  const handleDelete = () => {
    if (activeOrganization?.id) {
      dispatch(deleteOrganizationRequest({ id: activeOrganization.id }));
    }
  };

  return {
    methods: {
      toggleDeleteModal,
      handleChangePage,
      handleChangePageSize,
      createOrganizationHandler,
      handleSort,
      handleClickOrganization,
      toggleFilterRow,
      handleDelete,
      handleShowModalDelete,
    },
    state: {
      pageNum,
      pageSize,
      loadingOrganization,
      sortOrganizations,
      totalElements,
      organizations,
      organizationId,
      isAccessToCreateOrganization,
      isAccessToDeleteOrganization,
      canDeleteOrg,
      filterValues,
      showFilterRow,
      activeOrganization,
      showDeleteModal,
      isWorkGroupsLoading,
      isContractsLoading,
    },
  };
};
