import { Reducer } from 'redux';

import { DEFAULT_PAGINATION_PARAMS } from 'constants/meta';
import { CustomField } from 'core/types';
import { ReducerMap } from 'store/types';
import { Pagination } from 'types/models/meta';

import { CustomFieldsFilter } from '../types';

import {
  CustomFields,
  CustomFieldsActionsTypes,
  FetchCustomFieldsSuccessAction,
  SetCurrentCustomFieldIdAction,
  SetCurrentCustomFieldsPageAction,
  SetCurrentCustomFieldSuccessAction,
  SetCustomFieldsFilterAction,
  SetFilterCustomFieldIdAction,
  SetSizePageAction,
  SetSortCustomFieldsAction,
} from './types';

export interface CustomFieldsReducerState {
  customFields?: CustomField[];
  customField?: CustomField;
  loading?: boolean;
  pagination?: Pagination;
  sort?: string;
  customFieldId?: string;
  filter?: CustomFieldsFilter;
}

const initialState: CustomFieldsReducerState = {
  customFields: [],
  loading: false,
  pagination: DEFAULT_PAGINATION_PARAMS,
  sort: 'CREATED_DESC',
  filter: {},
};

const customFieldsReducerMap: ReducerMap<
  CustomFieldsReducerState,
  CustomFieldsActionsTypes
> = {
  [CustomFields.CUSTOM_FIELDS_LOADING_SHOW]: (state) => {
    return {
      ...state,
      loading: true,
    };
  },
  [CustomFields.CUSTOM_FIELDS_LOADING_HIDE]: (state) => {
    return {
      ...state,
      loading: false,
    };
  },
  [CustomFields.FETCH_CUSTOM_FIELDS_SUCCESS]: (state, action) => {
    const { payload } = action as FetchCustomFieldsSuccessAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      customFields: content,
      pagination: {
        ...state?.pagination,
        pageNum: page,
        totalElements,
      },
    };
  },
  [CustomFields.SET_CURRENT_CUSTOM_FIELDS_PAGE]: (state, action) => {
    const { payload } = action as SetCurrentCustomFieldsPageAction;
    return {
      ...state,
      pagination: {
        ...state?.pagination,
        pageNum: payload,
      },
    };
  },
  [CustomFields.SET_SIZE_PAGE_CUSTOM_FIELDS]: (state, action) => {
    const { payload } = action as SetSizePageAction;
    return {
      ...state,
      pagination: {
        ...state?.pagination,
        pageSize: payload,
      },
    };
  },
  [CustomFields.SET_SORT_CUSTOM_FIELDS]: (state, action) => {
    const { payload } = action as SetSortCustomFieldsAction;
    return {
      ...state,
      sort: payload,
    };
  },
  [CustomFields.SET_CURRENT_CUSTOM_FIELD_SUCCESS]: (state, action) => {
    const { payload } = action as SetCurrentCustomFieldSuccessAction;
    return {
      ...state,
      customField: payload,
    };
  },
  [CustomFields.RESET_CUSTOM_FIELD_STATE]: () => {
    return {
      ...initialState,
    };
  },
  [CustomFields.SET_CURRENT_CUSTOM_FIELD_ID]: (state, action) => {
    const { payload } = action as SetCurrentCustomFieldIdAction;
    return {
      ...state,
      customFieldId: payload,
    };
  },
  [CustomFields.SET_CUSTOM_FIELDS_FILTER]: (state, action) => {
    const { payload } = action as SetCustomFieldsFilterAction;
    return {
      ...state,
      filter: payload,
    };
  },
  [CustomFields.SET_FILTER_CUSTOM_FIELD_ID]: (state, action) => {
    const { payload } = action as SetFilterCustomFieldIdAction;
    return {
      ...state,
      filterCustomFieldId: payload,
    };
  },
  [CustomFields.RESET_CURRENT_CUSTOM_FIELD]: (state) => {
    return {
      ...state,
      customField: initialState.customField,
      customFieldId: initialState.customFieldId,
      pagination: initialState.pagination,
    };
  },
};

export const customFields: Reducer<
  CustomFieldsReducerState,
  FetchCustomFieldsSuccessAction
> = (state = initialState, action) => {
  const reducer = customFieldsReducerMap[action.type];
  if (!reducer) {
    return state;
  }
  return reducer(state, action);
};
