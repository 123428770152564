import { CustomField } from 'core/types';
import { ResponseWithMeta } from 'store/types';
import { UpdateContentType } from 'types/models/meta';

import { CreateCustomFieldData, CustomFieldsFilter } from '../types';

import {
  CheckIsExistRequestAction,
  CheckIsExistSuccessAction,
  CreateCustomFieldAction,
  CustomFields,
  DeleteCustomFieldRequestAction,
  DeleteCustomFieldTypeAction,
  FetchCustomFieldRequestAction,
  FetchCustomFieldsRequestAction,
  FetchCustomFieldsSuccessAction,
  HideCustomFieldsLoadingAction,
  ResetCurrentCustomFieldAction,
  ResetCustomFieldStateAction,
  SetCurrentCustomFieldIdAction,
  SetCurrentCustomFieldsPageAction,
  SetCurrentCustomFieldSuccessAction,
  SetSizePageAction,
  SetSortCustomFieldsAction,
  ShowCustomFieldsLoadingAction,
} from './types';

export const showCustomFieldsLoading = (): ShowCustomFieldsLoadingAction => {
  return {
    type: CustomFields.CUSTOM_FIELDS_LOADING_SHOW,
  };
};

export const hideCustomFieldsLoading = (): HideCustomFieldsLoadingAction => {
  return {
    type: CustomFields.CUSTOM_FIELDS_LOADING_HIDE,
  };
};

export const updateCustomField = (data: CustomField) => {
  return {
    type: CustomFields.UPDATE_CUSTOM_FIELD,
    payload: data,
  };
};

export const fetchCustomFieldsSuccess = (
  data: ResponseWithMeta<CustomField[]>
): FetchCustomFieldsSuccessAction => {
  return {
    type: CustomFields.FETCH_CUSTOM_FIELDS_SUCCESS,
    payload: data,
  };
};

export const setCurrentCustomFieldsPage = (
  page: number
): SetCurrentCustomFieldsPageAction => {
  return {
    type: CustomFields.SET_CURRENT_CUSTOM_FIELDS_PAGE,
    payload: page,
  };
};

export const setSizePage = (size: number): SetSizePageAction => {
  return {
    type: CustomFields.SET_SIZE_PAGE_CUSTOM_FIELDS,
    payload: size,
  };
};

export const setSortCustomFields = (
  sort: string
): SetSortCustomFieldsAction => {
  return {
    type: CustomFields.SET_SORT_CUSTOM_FIELDS,
    payload: sort,
  };
};

export const fetchCurrentCustomField = (
  id: string
): FetchCustomFieldRequestAction => {
  return {
    type: CustomFields.FETCH_CUSTOM_FIELD_REQUEST,
    payload: id,
  };
};

export const setCurrentCustomFieldSuccess = (
  data?: CustomField
): SetCurrentCustomFieldSuccessAction => {
  return {
    type: CustomFields.SET_CURRENT_CUSTOM_FIELD_SUCCESS,
    payload: data,
  };
};

export const createCustomField = (
  data: CreateCustomFieldData
): CreateCustomFieldAction => {
  return {
    type: CustomFields.CREATE_CUSTOM_FIELD,
    payload: data,
  };
};

export const resetCustomFieldState = (): ResetCustomFieldStateAction => {
  return {
    type: CustomFields.RESET_CUSTOM_FIELD_STATE,
  };
};

export const setCurrentCustomFieldId = (
  id?: string
): SetCurrentCustomFieldIdAction => {
  return {
    type: CustomFields.SET_CURRENT_CUSTOM_FIELD_ID,
    payload: id,
  };
};

export const setCustomFieldsFilter = (data: CustomFieldsFilter) => {
  return {
    type: CustomFields.SET_CUSTOM_FIELDS_FILTER,
    payload: data,
  };
};

export const fetchCustomFieldsRequest = (
  joinData?: UpdateContentType
): FetchCustomFieldsRequestAction => {
  return {
    type: CustomFields.FETCH_CUSTOM_FIELDS_REQUEST,
    payload: joinData,
  };
};

export const deleteCustomFieldRequest = (
  params: DeleteCustomFieldTypeAction
): DeleteCustomFieldRequestAction => {
  return {
    type: CustomFields.DELETE_CUSTOM_FIELD_REQUEST,
    payload: params,
  };
};

export const checkIsExistRequest = (
  title: string
): CheckIsExistRequestAction => {
  return {
    type: CustomFields.CHECK_IS_EXIST_REQUEST,
    payload: title,
  };
};

export const checkIsExistSuccess = (
  isExist: boolean
): CheckIsExistSuccessAction => {
  return {
    type: CustomFields.CHECK_IS_EXIST_SUCCESS,
    payload: isExist,
  };
};

export const resetCurrentCustomField = (): ResetCurrentCustomFieldAction => {
  return {
    type: CustomFields.RESET_CURRENT_CUSTOM_FIELD,
  };
};
