import { v4 as uuidv4 } from 'uuid';

import { getConvertedTime } from 'core/utils';

import { CONDITION_MAP } from '../constants';
import { DefaultConditions } from '../types';

export const getNormalizedTriggerConditions = (
  triggerConditions: DefaultConditions[]
) => {
  return triggerConditions.map((conditions) => {
    const { conditionType, minutes } = conditions || {};

    return {
      id: uuidv4(),
      isActive: true,
      condition: {
        title: (conditionType && CONDITION_MAP[conditionType]) || '',
        value: conditionType || '',
      },
      value: getConvertedTime(minutes) || '00:00',
    };
  });
};
