import cn from 'clsx';
import { FC, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { ArrowDownIcon } from 'assets/icons';
import { Typography, TypographyVariants } from 'components';

import styles from './BreadCrumbs.module.scss';

export type BreadCrumb = {
  title?: string;
  path?: string;
};

interface BreadCrumbsProps {
  config?: BreadCrumb[];
  className?: string;
}

export const BreadCrumbs: FC<BreadCrumbsProps> = ({ config, className }) => {
  const breadCrumbsList = config?.map((breadCrumb, index) => {
    if (index < config.length - 1 && breadCrumb.path) {
      return (
        <Fragment key={breadCrumb.path + breadCrumb.title}>
          <Link
            to={breadCrumb.path}
            className={cn(
              styles.breadCrumbs__item,
              styles.breadCrumbs__link_clickable
            )}
          >
            <Typography
              variant={TypographyVariants.b3}
              className={cn(
                styles.breadCrumbs__link,
                styles.breadCrumbs__link_clickable
              )}
            >
              {breadCrumb.title}
            </Typography>
          </Link>
          <ArrowDownIcon className={styles.breadCrumbs__itemBtn} />
        </Fragment>
      );
    }
    return (
      <Typography
        variant={TypographyVariants.b3}
        key={`${breadCrumb.path} ${breadCrumb.title}`}
        className={styles.breadCrumbs__link}
      >
        {breadCrumb.title}
      </Typography>
    );
  });
  return (
    <div className={cn(styles.breadCrumbs, className)}>{breadCrumbsList}</div>
  );
};
