import { CustomField } from 'core/types';
import { AccessedRoute } from 'core/types/auth';
import { RootState } from 'store/rootReducer';

export const getCustomFields = (state: RootState): CustomField[] | undefined =>
  state.customFields.customFields;
export const getLoading = (state: RootState): boolean | undefined =>
  state.customFields.loading;
export const getAccessedRoutes = (
  state: RootState
): AccessedRoute[] | undefined => state.currentUser.accessedRoutes;
export const getCurrentCustomField = (state: RootState) =>
  state.customFields?.customField;
export const getCurrentCustomFieldId = (state: RootState) =>
  state.customFields.customFieldId;
export const getFilterValues = (state: RootState) =>
  state.customFields.filter || {};

export const getPropsCustomFields = (state: RootState) => ({
  pageNum: state.customFields.pagination?.pageNum,
  pageSize: state.customFields.pagination?.pageSize,
  loadingCustomFields: getLoading(state),
  sortCustomFields: state.customFields?.sort,
  totalElements: state.customFields.pagination?.totalElements,
});
