import { AdminsLayout } from 'core/layouts';
import { StatusesBuilder } from 'features/StatusesBuilder';

const StatusesBuilderPage = () => {
  return (
    <AdminsLayout>
      <StatusesBuilder />
    </AdminsLayout>
  );
};

export default StatusesBuilderPage;
