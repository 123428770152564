import cn from 'clsx';
import { FC, memo, ReactElement, SVGProps } from 'react';

import { BreadCrumb, Tab } from 'components';
import { Header, InfoBlockConfig, InfoHeader } from 'core/components';
import { LoaderProps } from 'core/types';

import styles from './MainLayout.module.scss';

export type HeaderType = 'default' | 'withExtraInfo';

interface MainLayoutProps {
  title?: string | JSX.Element;
  headerType?: HeaderType;
  withGoBack?: boolean;
  withCreateTicketButton?: boolean;
  withHeader?: boolean;
  classNameHeader?: string;
  classNameTitleInfoBlock?: string;
  classNameRoot?: string;
  tabs?: Tab[];
  classNameHeaderTitleBlock?: string;
  subTitle?: string;
  titleIcon?: SVGProps<SVGElement>;
  infoBlocksConfig?: InfoBlockConfig[];
  breadCrumbsConfig?: BreadCrumb[];
  onClickSubTitle?(): void;
  dropContent?: JSX.Element;
  dropDisabled?: boolean;
  customHeader?: ReactElement;
  loading?: boolean;
  slideSetting?: JSX.Element;
  customComponent?: JSX.Element;
  loaderProps?: LoaderProps;
  labelComponent?: JSX.Element;
}

export const MainLayout: FC<MainLayoutProps> = memo(
  ({
    title,
    children,
    withGoBack,
    withCreateTicketButton,
    withHeader = true,
    classNameHeader,
    headerType = 'default',
    classNameRoot,
    tabs,
    classNameHeaderTitleBlock,
    subTitle,
    titleIcon,
    infoBlocksConfig,
    breadCrumbsConfig,
    classNameTitleInfoBlock,
    onClickSubTitle,
    dropContent,
    dropDisabled,
    customHeader,
    loading,
    slideSetting,
    customComponent,
    loaderProps,
    labelComponent,
  }) => {
    const headerMap = {
      default: (
        <Header
          title={title}
          rightComponent={customComponent}
          withGoBack={withGoBack}
          className={classNameHeader}
          withCreateTicketButton={withCreateTicketButton}
          tabs={tabs}
          slideSetting={slideSetting}
        />
      ),
      withExtraInfo: (
        <InfoHeader
          title={title}
          subTitle={subTitle}
          className={classNameHeader}
          classNameTitleBlock={classNameHeaderTitleBlock}
          titleIcon={titleIcon}
          infoBlocksConfig={infoBlocksConfig}
          breadCrumbsConfig={breadCrumbsConfig}
          classNameTitleInfoBlock={classNameTitleInfoBlock}
          onClickSubTitle={onClickSubTitle}
          dropContent={dropContent}
          dropDisabled={dropDisabled}
          customComponent={customComponent}
          loaderProps={loaderProps}
          labelComponent={labelComponent}
        />
      ),
    };

    return (
      <div className={cn(styles.mainLayout, classNameRoot)}>
        {!loading && !customHeader && withHeader && headerMap[headerType]}
        {!loading && customHeader}
        {children}
        <div id="mainLayoutSticky" className={styles.mainLayout__stickyBlock} />
      </div>
    );
  }
);
