import { SLATableDataType } from 'core/types/sla';
import { initialCheckedPriority } from 'features/Contract/constants';

export const getInitialCheckedSLA = (tableData?: SLATableDataType) => {
  const result = { ...initialCheckedPriority };
  if (tableData) {
    Object.keys(initialCheckedPriority).forEach((priority) => {
      if (tableData[priority as keyof typeof tableData]) {
        result[priority as keyof typeof result] = true;
        return;
      }
      result[priority as keyof typeof result] = false;
    });
  }
  return result;
};
