import {
  CalendarIcon,
  CheckCircleIcon,
  ListIcon,
  TextSizeIcon,
} from 'assets/icons';
import { CustomFieldType } from 'components';

import styles from './utils.module.scss';

export const getPropsCustomFieldType = (type: CustomFieldType) => {
  const components = {
    [CustomFieldType.FIELD_DATE]: () => ({
      text: 'Дата',
      icon: <CalendarIcon className={styles.utils__icon} />,
    }),
    [CustomFieldType.FIELD_FLAG]: () => ({
      text: 'Флаг',
      icon: <CheckCircleIcon className={styles.utils__icon} />,
    }),
    [CustomFieldType.FIELD_TEXT]: () => ({
      text: 'Текст',
      icon: <TextSizeIcon className={styles.utils__icon} />,
    }),
    [CustomFieldType.FIELD_LIST]: () => ({
      text: 'Лист',
      icon: <ListIcon className={styles.utils__icon} />,
    }),
  };
  return components[type]();
};
