import cn from 'clsx';
import { type FC, type HTMLAttributes } from 'react';

import { createBemClass } from 'components-new/helpers/createBemClass';

import { type Variety } from '../types';

import styles from './Spin.module.scss';
import { type SpinAppearance, type SpinSize } from './types';

export interface SpinProps extends HTMLAttributes<HTMLDivElement> {
  size?: SpinSize;
  variety?: Variety;
  appearance?: SpinAppearance;
}

const rootClassName = createBemClass('spin');

export const Spin: FC<SpinProps> = ({
  size = 's',
  variety = 'primary',
  appearance = 'basic',
  className,
  ...other
}) => {
  return (
    <div {...other} className={cn(styles[rootClassName()], className)}>
      <div
        className={cn(
          styles[rootClassName({ elementName: 'circle' })],
          styles[rootClassName({ elementName: 'circle', modName: size })],
          styles[
            rootClassName({
              elementName: 'circle',
              modName: appearance,
            })
          ],
          {
            [styles[
              rootClassName({
                elementName: 'circle',
                modName: variety,
              })
            ]]: appearance !== 'basic',
          }
        )}
      />
    </div>
  );
};
