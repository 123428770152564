import { FC } from 'react';

import { useTicketTypes } from 'features/Ticket/hooks';

import { TicketTypeEditor } from '../TicketTypeEditor';

export const MobileEditTicketType: FC = () => {
  const { canProcessTicketType } = useTicketTypes();
  return canProcessTicketType ? <TicketTypeEditor /> : null;
};
