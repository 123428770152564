import { SelectOption } from 'components';
import { SELECT_ATTRIBUTES_LIST } from 'core/constants';
import { Attribute } from 'core/types';

export const getAttributesOptions = (attributes: Attribute[]) => {
  const attributesValue = attributes.map(({ attribute }) => {
    const atr = attribute as SelectOption;
    return atr.value;
  });

  return SELECT_ATTRIBUTES_LIST.filter(
    ({ value }) => !attributesValue.includes(value)
  );
};
