import {
  CheckIsExistTagArgs,
  CreateTagData,
  Tag,
  TagsFilter,
} from 'features/Ticket/types';
import { ResponseWithMeta } from 'store/types';
import { UpdateContentType } from 'types/models/meta';

import {
  CreateTagRequestAction,
  DeleteTagRequestAction,
  FetchTagsRequestAction,
  FetchTagsSuccessJoinAction,
  FetchTagsSuccessUpdateAction,
  Tags,
} from './types';

export const fetchTagsRequest = (
  updateType: UpdateContentType
): FetchTagsRequestAction => {
  return {
    type: Tags.FETCH_TAGS_REQUEST,
    payload: updateType,
  };
};

export const loadingTagsShowRequest = () => {
  return {
    type: Tags.TAGS_LOADING_SHOW,
  };
};

export const loadingTagsHideRequest = () => {
  return {
    type: Tags.TAGS_LOADING_HIDE,
  };
};

export const fetchTagsSuccessUpdate = (
  data: ResponseWithMeta<Tag[]>
): FetchTagsSuccessUpdateAction => {
  return {
    type: Tags.FETCH_TAGS_SUCCESS_UPDATE,
    payload: data,
  };
};

export const fetchTagsSuccessJoin = (
  data: ResponseWithMeta<Tag[]>
): FetchTagsSuccessJoinAction => {
  return {
    type: Tags.FETCH_TAGS_SUCCESS_JOIN,
    payload: data,
  };
};

export const createTagRequest = (
  data: CreateTagData
): CreateTagRequestAction => {
  return {
    type: Tags.CREATE_TAG_REQUEST,
    payload: data,
  };
};

export const deleteTagRequest = (id: string): DeleteTagRequestAction => {
  return {
    type: Tags.DELETE_TAG_BY_ID_REQUEST,
    payload: id,
  };
};

export const resetTagsState = () => {
  return {
    type: Tags.RESET_TAGS_STATE,
  };
};

export const setFilterTags = (filter: TagsFilter) => {
  return {
    type: Tags.SET_FILTER_TAGS,
    payload: filter,
  };
};

export const setCurrentPageTags = (page: number) => {
  return {
    type: Tags.SET_CURRENT_PAGE_TAGS,
    payload: page,
  };
};

export const checkIsExistTagRequest = (payload: CheckIsExistTagArgs) => {
  return {
    type: Tags.CHECK_IS_EXIST_TAG_REQUEST,
    payload,
  };
};

export const checkIsExistTagSuccess = (payload: boolean) => {
  return {
    type: Tags.CHECK_IS_EXIST_TAG_SUCCESS,
    payload,
  };
};
