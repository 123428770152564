import cn from 'clsx';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { HelpBookIcon } from 'assets/icons';
import { Button, Typography, TypographyVariants } from 'components';
import { getScreenWidth } from 'core/ducks/selectors';
import { ScreenWidth } from 'core/types';
import {
  TEXT_WITH_CONTENT,
  TEXT_WITHOUT_CONTENT,
  TEXT_WITHOUT_CONTENT_MOBILE,
} from 'features/UserAssistance/constants';
import { RouterHref } from 'routes/routerHref';

import styles from './UserAssistanceStartBlock.module.scss';

interface UserAssistanceStartBlockProps {
  className?: string;
  emptyContent: boolean;
}

export const UserAssistanceStartBlock: FC<UserAssistanceStartBlockProps> = ({
  className,
  emptyContent,
}) => {
  const { push } = useHistory();

  const screenWidth = useSelector(getScreenWidth);

  const isMobile = screenWidth === ScreenWidth.MOBILE;
  const isMobileSmall = screenWidth === ScreenWidth.MOBILE_SMALL;
  const isMobileAll = isMobile || isMobileSmall;

  const contentText = isMobileAll
    ? TEXT_WITHOUT_CONTENT_MOBILE
    : TEXT_WITHOUT_CONTENT;

  const text = emptyContent ? contentText : TEXT_WITH_CONTENT;

  const goToLearningBase = () => {
    push(RouterHref.KnowledgeBase);
  };

  const title = emptyContent && (
    <Typography
      variant={TypographyVariants.h3}
      className={styles.userAssistanceStartBlock__title}
    >
      Здесь пока что пусто
    </Typography>
  );

  return (
    <div className={cn(styles.userAssistanceStartBlock, className)}>
      <HelpBookIcon className={styles.userAssistanceStartBlock__icon} />
      {title}
      <Typography
        variant={TypographyVariants.b2}
        className={styles.userAssistanceStartBlock__text}
      >
        {text}
      </Typography>
      {(isMobileAll || emptyContent) && (
        <Button
          onClick={goToLearningBase}
          appearance="outline"
          className={styles.userAssistanceStartBlock__button}
        >
          База знаний
        </Button>
      )}
    </div>
  );
};
