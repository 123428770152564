import cn from 'clsx';
import { FC, memo, RefObject, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { TabsItem } from './components';
import styles from './Tabs.module.scss';
import { Tab } from './types';

interface TabsProps {
  className?: string;
  classNameTab?: string;
  tabs: Tab[];
  tabsRef?: RefObject<HTMLDivElement>;
  disabled?: boolean;
  fontSize?: 14 | 16;
}

export const Tabs: FC<TabsProps> = memo(
  ({ className, tabs, classNameTab, tabsRef, disabled, fontSize = 16 }) => {
    const { pathname } = useLocation();

    const tabsList = tabs.map((tab) => (
      <TabsItem
        tab={tab}
        key={tab.title}
        disabled={disabled}
        fontSize={fontSize}
        className={classNameTab}
      />
    ));

    useEffect(() => {
      setTimeout(() => {
        if (tabsRef && tabsRef.current) {
          tabsRef.current.querySelector("a[class$='active']")?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'center',
          });
        }
      }, 200);
    }, [pathname]);

    return (
      <div ref={tabsRef} className={cn(styles.tabs, className)}>
        {tabsList}
      </div>
    );
  }
);
