import { Reducer } from 'redux';

import { DEFAULT_PAGINATION_PARAMS } from 'constants/meta';
import { ActionWithCustomFields } from 'core/types/action';
import { ActionFilter } from 'features/Actions/types';
import { ReducerMap } from 'store/types';
import { Pagination } from 'types/models/meta';

import {
  Actions,
  ActionsTypes,
  FetchActionsSuccessAction,
  SetActionsCreateAction,
  SetActionsFilterAction,
  SetCurrentActionsPageAction,
  SetSizePageAction,
  SetSortActionsAction,
} from './types';

export interface ActionsReducerState {
  actions?: ActionWithCustomFields[];
  loading?: boolean;
  pagination?: Pagination;
  sort?: string;
  actionsCreate?: ActionWithCustomFields[];
  actionsFilter?: ActionFilter;
}

const initialState: ActionsReducerState = {
  actions: [],
  loading: false,
  pagination: DEFAULT_PAGINATION_PARAMS,
  sort: 'date_create_DESC',
  actionsFilter: {},
};

const actionsReducerMap: ReducerMap<ActionsReducerState, ActionsTypes> = {
  [Actions.ACTIONS_LOADING_SHOW]: (state) => {
    return {
      ...state,
      loading: true,
    };
  },
  [Actions.ACTIONS_LOADING_HIDE]: (state) => {
    return {
      ...state,
      loading: false,
    };
  },
  [Actions.FETCH_ACTIONS_SUCCESS]: (state, action) => {
    const { payload } = action as FetchActionsSuccessAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      actions: content,
      pagination: {
        ...state?.pagination,
        pageNum: page,
        totalElements,
      },
    };
  },
  [Actions.SET_ACTIONS_CREATE_STATE]: (state, action) => {
    const { payload } = action as SetActionsCreateAction;
    return {
      ...state,
      actionsCreate: payload,
    };
  },
  [Actions.RESET_ACTIONS_STATE]: () => {
    return {
      ...initialState,
    };
  },
  [Actions.SET_ACTIONS_FILTER]: (state, action) => {
    const { payload } = action as SetActionsFilterAction;
    return {
      ...state,
      actionsFilter: payload,
    };
  },
  [Actions.SET_CURRENT_ACTIONS_PAGE]: (state, action) => {
    const { payload } = action as SetCurrentActionsPageAction;
    return {
      ...state,
      pagination: {
        ...state?.pagination,
        pageNum: payload,
      },
    };
  },
  [Actions.SET_SIZE_PAGE_ACTIONS]: (state, action) => {
    const { payload } = action as SetSizePageAction;
    return {
      ...state,
      pagination: {
        ...state?.pagination,
        pageSize: payload,
      },
    };
  },
  [Actions.SET_SORT_ACTIONS]: (state, action) => {
    const { payload } = action as SetSortActionsAction;
    return {
      ...state,
      sort: payload,
    };
  },
  [Actions.RESET_ACTIONS_CREATE]: (state) => {
    return {
      ...state,
      actionsCreate: initialState.actionsCreate,
    };
  },
};

export const actions: Reducer<ActionsReducerState, ActionsTypes> = (
  state = initialState,
  action
) => {
  const reducer = actionsReducerMap[action.type];
  if (!reducer) {
    return state;
  }
  return reducer(state, action);
};
