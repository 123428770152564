import { call, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';

import { setAlert, setRedirectPath } from 'core/ducks/actions';
import { getErrorAlert, getSuccessAlert } from 'core/layouts';
import { ActionForAlertTypes } from 'core/types';
import {
  CreateOrganizationData,
  CreateOrganizationToRequest,
  Organization,
} from 'core/types/organization';
import { getFilterOrganizationToRequest } from 'core/utils';
import { resetContractsState } from 'features/Contracts';
import { resetWorkGroupsState } from 'features/WorkGroups';
import { RouterHref } from 'routes/routerHref';
import { ResponseWithMeta } from 'store/types';
import { createError } from 'utils';

import {
  fetchCurrentOrganization,
  fetchOrganizationsAddJoinSuccess,
  fetchOrganizationsAddRequest,
  fetchOrganizationsAddSuccess,
  fetchOrganizationsForReportSuccess,
  fetchOrganizationsJoinSuccess,
  fetchOrganizationsRequest,
  fetchOrganizationsSuccess,
  hideOrganizationLoading,
  hideOrganizationsAddLoading,
  hideOrganizationsLoading,
  resetCurrentOrganization,
  setCurrentOrganizationSuccess,
  showOrganizationLoading,
  showOrganizationsAddLoading,
  showOrganizationsLoading,
} from './actions';
import { request } from './api/requests';
import {
  getFilterAddValues,
  getFilterValues,
  getPropsOrganizations,
  getPropsOrganizationsAdd,
} from './selectors';
import {
  CreateOrganizationAction,
  DeleteOrganizationRequestAction,
  EditOrganizationAction,
  Organizations,
} from './types';

const ENTITY_ORG_TITLE = 'Организация';

export const getCreateOrganizationDataToRequest = (
  data: CreateOrganizationData
): CreateOrganizationToRequest => {
  const { type } = data;
  return {
    ...data,
    type: type && !Array.isArray(type) ? type.value : undefined,
  };
};

function* organizationsFetch({
  payload,
}: ReturnType<typeof fetchOrganizationsRequest>) {
  try {
    const { updateType = 'update', organizationTypes } = payload || {};

    const {
      pageNum,
      pageSize,
      sortOrganizations,
    }: ReturnType<typeof getPropsOrganizations> = yield select(
      getPropsOrganizations
    );

    const filter: ReturnType<typeof getFilterValues> = yield select(
      getFilterValues
    );

    yield put(showOrganizationsLoading());

    const organizations: ResponseWithMeta<Organization[]> = yield call(
      request.fetchOrganizations,
      pageNum,
      pageSize,
      sortOrganizations,
      getFilterOrganizationToRequest({
        ...filter,
        ...(organizationTypes && { organizationTypes }),
      })
    );
    yield put(
      updateType === 'update'
        ? fetchOrganizationsSuccess(organizations)
        : fetchOrganizationsJoinSuccess(organizations)
    );
    yield put(hideOrganizationsLoading());
  } catch (e) {
    createError(e);
    yield put(hideOrganizationsLoading());
  }
}

function* organizationsAddFetch({
  payload,
}: ReturnType<typeof fetchOrganizationsAddRequest>) {
  try {
    const { updateType = 'update', organizationTypes } = payload || {};
    const {
      pageNum,
      pageSize,
      sortOrganizations,
    }: ReturnType<typeof getPropsOrganizationsAdd> = yield select(
      getPropsOrganizationsAdd
    );
    const filterAdd: ReturnType<typeof getFilterAddValues> = yield select(
      getFilterAddValues
    );

    yield put(showOrganizationsAddLoading());

    const organizations: ResponseWithMeta<Organization[]> = yield call(
      request.fetchOrganizations,
      pageNum,
      pageSize,
      sortOrganizations,
      getFilterOrganizationToRequest({
        ...filterAdd,
        ...(organizationTypes && { organizationTypes }),
      })
    );
    yield put(
      updateType === 'update'
        ? fetchOrganizationsAddSuccess(organizations)
        : fetchOrganizationsAddJoinSuccess(organizations)
    );
    yield put(hideOrganizationsAddLoading());
  } catch (e) {
    createError(e);
    yield put(hideOrganizationsAddLoading());
  }
}

function* currentOrganizationFetch({
  payload,
}: ReturnType<typeof fetchCurrentOrganization>) {
  try {
    yield put(showOrganizationLoading());
    if (payload) {
      const result = (yield call(
        request.fetchCurrentOrganization,
        payload
      )) as Organization;
      yield put(setCurrentOrganizationSuccess(result));
    }
    yield put(hideOrganizationLoading());
  } catch (e) {
    yield put(hideOrganizationLoading());
    createError(e);
  }
}

function* organizationCreate({ payload }: CreateOrganizationAction) {
  try {
    yield put(showOrganizationsLoading());
    const organization: Organization = yield call(
      request.createOrganization,
      getCreateOrganizationDataToRequest(payload)
    );
    yield put(
      setAlert(getSuccessAlert(ENTITY_ORG_TITLE, ActionForAlertTypes.CREATE))
    );
    yield put(hideOrganizationsLoading());
    if (organization?.id) {
      yield put(setRedirectPath(`/admin/organizations/${organization.id}`));
    }
  } catch (e) {
    createError(e);
    yield put(
      setAlert(getErrorAlert(ENTITY_ORG_TITLE, ActionForAlertTypes.CREATE))
    );
    yield put(hideOrganizationsLoading());
  }
}

function* organizationEdit({ payload }: EditOrganizationAction) {
  try {
    yield put(showOrganizationsLoading());

    const organization: Organization = yield call(
      request.editOrganization,
      getCreateOrganizationDataToRequest(payload)
    );
    yield put(
      setAlert(getSuccessAlert(ENTITY_ORG_TITLE, ActionForAlertTypes.EDIT))
    );
    yield put(setCurrentOrganizationSuccess(organization));
    yield put(hideOrganizationsLoading());
  } catch (e) {
    createError(e);
    yield put(
      setAlert(getErrorAlert(ENTITY_ORG_TITLE, ActionForAlertTypes.EDIT))
    );
    yield put(hideOrganizationsLoading());
  }
}

function* deleteOrganization({ payload }: DeleteOrganizationRequestAction) {
  try {
    const { id, withRedirect = false } = payload;
    yield put(showOrganizationsLoading());

    yield call(request.deleteOrganization, id);
    yield put(
      setAlert(getSuccessAlert(ENTITY_ORG_TITLE, ActionForAlertTypes.DELETE))
    );
    if (withRedirect) {
      yield put(setRedirectPath(RouterHref.AdminOrganizations));
      return;
    }
    yield put(resetCurrentOrganization());
    yield put(resetContractsState());
    yield put(resetWorkGroupsState());
    yield call(organizationsFetch, {
      type: Organizations.FETCH_ORGANIZATIONS_REQUEST,
    });
    yield put(hideOrganizationsLoading());
  } catch (e) {
    createError(e);
    yield put(
      setAlert(getErrorAlert(ENTITY_ORG_TITLE, ActionForAlertTypes.DELETE))
    );
    yield put(hideOrganizationsLoading());
  }
}

function* organizationsFetchForReport() {
  try {
    yield put(showOrganizationsLoading());
    const organizations: Organization[] = yield call(
      request.fetchOrganizationsForReport
    );
    yield put(fetchOrganizationsForReportSuccess(organizations));
    yield put(hideOrganizationsLoading());
  } catch (e) {
    createError(e);
    yield put(hideOrganizationsLoading());
  }
}

export function* organizationsSaga(): Generator<StrictEffect> {
  yield takeEvery(
    Organizations.FETCH_ORGANIZATIONS_REQUEST,
    organizationsFetch
  );
  yield takeEvery(
    Organizations.FETCH_ORGANIZATIONS_ADD_REQUEST,
    organizationsAddFetch
  );
  yield takeEvery(
    Organizations.FETCH_ORGANIZATION_REQUEST,
    currentOrganizationFetch
  );
  yield takeEvery(Organizations.CREATE_ORGANIZATION, organizationCreate);
  yield takeEvery(Organizations.EDIT_ORGANIZATION, organizationEdit);
  yield takeEvery(
    Organizations.DELETE_ORGANIZATION_REQUEST,
    deleteOrganization
  );
  yield takeEvery(
    Organizations.FETCH_ORGANIZATIONS_FOR_REPORT_REQUEST,
    organizationsFetchForReport
  );
}
