import { KBLayout } from 'features/KnowledgeBase/components';
import { KBTable } from 'features/KnowledgeBase/views/KBTable';

import styles from './KBTablePage.module.scss';

const KBTablePage = () => {
  return (
    <KBLayout className={styles.KBTablePage}>
      <KBTable />
    </KBLayout>
  );
};

export default KBTablePage;
