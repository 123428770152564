import {
  ChangeEvent,
  Dispatch,
  DragEvent,
  FC,
  MouseEvent,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  SettingField as Field,
  ISettingFields,
  mapFields,
  SettingFieldsList,
  Typography,
  TypographyVariants,
} from 'components';
import { Create } from 'core/modals';
import {
  createTicketsSettingFieldsRequest,
  getIsSettingFieldsOpen,
  openSettingFields,
} from 'features/Tickets';

import styles from './TicketsSettingFields.module.scss';

interface TicketsSettingFieldsProps {
  settingFields?: ISettingFields;
  disabledField?: string;
}

// TODO feature/tsp-1731 вынести в таблицы и объединить с SettingFields

export const TicketsSettingFields: FC<TicketsSettingFieldsProps> = ({
  settingFields,
  disabledField,
}) => {
  const dispatch = useDispatch();

  const [defaultFieldsList, setDefaultFieldsList] = useState<Field[]>();
  const [customFieldsList, setCustomFieldsList] = useState<Field[]>();

  const [currentDefaultField, setCurrentDefaultField] = useState<Field>();
  const [currentCustomField, setCurrentCustomField] = useState<Field>();

  useEffect(() => {
    if (settingFields) {
      setDefaultFieldsList(settingFields?.defaultColumns);
      setCustomFieldsList(settingFields?.customColumns);
    }
  }, [settingFields]);

  const isSettingFieldsOpen = useSelector(getIsSettingFieldsOpen);

  const formSubmitHandler = (event: MouseEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(
      createTicketsSettingFieldsRequest({
        defaultColumns: defaultFieldsList || [],
        customColumns: customFieldsList || [],
      })
    );
    dispatch(openSettingFields(false));
  };

  const toggleSettingFieldsModal = () => {
    dispatch(openSettingFields(!isSettingFieldsOpen));
  };

  const dragDefaultFieldsStartHandler = (
    _: DragEvent<HTMLDivElement>,
    field: Field
  ) => {
    setCurrentDefaultField(field);
    setCurrentCustomField(undefined);
  };

  const dragCustomFieldsStartHandler = (
    _: DragEvent<HTMLDivElement>,
    field: Field
  ) => {
    setCurrentCustomField(field);
    setCurrentDefaultField(undefined);
  };

  const dropDefaultFieldsHandler = (
    event: DragEvent<HTMLDivElement>,
    field: Field
  ) => {
    event.preventDefault();

    if (currentDefaultField) {
      setDefaultFieldsList(
        mapFields({
          fieldsList: defaultFieldsList || [],
          field,
          currentField: currentDefaultField,
        })
      );
    }
  };

  const dropCustomFieldsHandler = (
    event: DragEvent<HTMLDivElement>,
    field: Field
  ) => {
    event.preventDefault();

    if (currentCustomField) {
      setCustomFieldsList(
        mapFields({
          fieldsList: customFieldsList || [],
          field,
          currentField: currentCustomField,
        })
      );
    }
  };

  const checkedAllFields = (
    event: ChangeEvent<HTMLInputElement>,
    setFields: Dispatch<SetStateAction<Field[] | undefined>>
  ) => {
    setFields((prev) =>
      prev?.map((field) => {
        if (field.name === disabledField) {
          return { ...field, hidden: false };
        }
        return { ...field, hidden: !event.target.checked };
      })
    );
  };

  const onChangeFieldsList = (
    event: ChangeEvent<HTMLInputElement>,
    setFields: Dispatch<SetStateAction<Field[] | undefined>>,
    ordinal?: number
  ) => {
    setFields((prev) =>
      prev?.map((field) => {
        if (ordinal === field.ordinal) {
          return { ...field, hidden: !event.target.checked };
        }
        return field;
      })
    );
  };

  return (
    <Create
      toggleModal={toggleSettingFieldsModal}
      isModal={!!isSettingFieldsOpen}
      title="Настройка столбцов таблицы"
      subModalText="настройку столбцов таблицы"
      createTitle="Сохранить"
      onSubmit={formSubmitHandler}
    >
      <>
        <SettingFieldsList
          fieldsList={defaultFieldsList}
          handleChange={onChangeFieldsList}
          setFieldsList={setDefaultFieldsList}
          checkedAllFields={checkedAllFields}
          dragStartHandler={dragDefaultFieldsStartHandler}
          dropHandler={dropDefaultFieldsHandler}
          disabledField={disabledField}
        />
        <Typography
          className={styles.ticketsSettingFields__title}
          variant={TypographyVariants.h5}
        >
          Кастомные поля
        </Typography>
        <SettingFieldsList
          fieldsList={customFieldsList}
          handleChange={onChangeFieldsList}
          setFieldsList={setCustomFieldsList}
          checkedAllFields={checkedAllFields}
          dragStartHandler={dragCustomFieldsStartHandler}
          dropHandler={dropCustomFieldsHandler}
        />
      </>
    </Create>
  );
};
