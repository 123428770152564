import { MainLayout } from 'core/layouts';
import { CreateTicket as CreateTicketContent } from 'features/CreateTicket';

import styles from './CreateTicket.module.scss';

const CreateTicket = () => {
  return (
    <MainLayout
      headerType="default"
      title="Создать тикет"
      classNameHeader={styles.createTicket__header}
      withGoBack
    >
      <CreateTicketContent />
    </MainLayout>
  );
};

export default CreateTicket;
