import { NotFound as NotFoundContent } from 'core/components';
import { MainLayout } from 'core/layouts';

const NotFound = () => {
  return (
    <MainLayout withHeader={false}>
      <NotFoundContent />
    </MainLayout>
  );
};

export default NotFound;
