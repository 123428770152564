import cn from 'clsx';
import { nanoid } from 'nanoid';
import {
  Fragment,
  KeyboardEvent,
  KeyboardEventHandler,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useSelector } from 'react-redux';

import { CloseIcon, PlusIcon, SearchIcon } from 'assets/icons';
import { Tooltip } from 'components';
import { getIsMobile, getIsMobileSmall } from 'core/ducks/selectors';

import { Checkbox } from '../Checkbox';
import { Loader } from '../Loader';
import { OutsideClickHandler } from '../OutsideClickHandler';
import { SelectOption, Size } from '../types';

import {
  Menu,
  MenuItem,
  MobileDropdownWrapper,
  MultiValueContainer,
} from './components';
import { SEARCH_PLACEHOLDER } from './constants';
import { useBooleanValuesSelect, useMethodsSelect } from './hooks';
import styles from './Select.module.scss';
import { SelectProps } from './types';
import { getOptionTitle } from './utils';

export const Select = <T,>({
  size = Size.m,
  value: valueProp,
  inputValue: inputValueProp,
  defaultInputValue = '',
  options = [],
  disabled,
  label,
  labelClassName,
  titleClassName,
  dropdownContentClassName,
  currentOptionClassName,
  classNameMultiContainer,
  className,
  open = false,
  isClearable = false,
  isSearchable = false,
  isMulti = false,
  isChip = false,
  isTooltip = true,
  placeholder = '',
  onChange,
  onChangeInput,
  renderMenuItem,
  renderValue,
  classNameContainer,
  classNameValue,
  maxSelectedOptions,
  readonly,
  infiniteScrollable,
  mobileModalTitle = '',
  currentPage = 0,
  totalPage = 0,
  loading,
  setNextPage,
  showAddComponent = false,
  onClickAddComponent,
}: SelectProps<T>) => { //eslint-disable-line

  const isMobile = useSelector(getIsMobile);
  const isMobileSmall = useSelector(getIsMobileSmall);
  const isMobileAll = isMobile || isMobileSmall;
  const isMultiSearch = isMulti && isSearchable && !isChip;
  const currentPlaceholder = isMultiSearch ? SEARCH_PLACEHOLDER : placeholder;

  const inputRef = useRef<HTMLInputElement | null>(null);

  const { methods, values } = useMethodsSelect<T>({
    isMobileAll,
    isSearchable,
    open,
    defaultInputValue,
    onChangeInput,
    maxSelectedOptions,
    onChange,
    value: valueProp,
    isMulti,
    setNextPage,
    isClearable,
    inputRef,
    onClickAddComponent,
    isMultiSearch,
  });
  const { openDropdown, inputValue, optionSelected } = values;

  const {
    handleToggleDropdown,
    setInputValue,
    handleChangeInput,
    getIsOptionSelected,
    setOptionSelected,
    handleDeleteMultiOption,
    fetchNextData,
    handleCloseDropdown,
    handleClear,
    handleChange,
    handleClickAddEntity,
  } = methods;

  const mobileRef = useRef<HTMLDivElement>(null);

  const {
    selectDisabled,
    inputDisabled,
    isVisiblePlaceholder,
    showClearButton,
    showValueInsideInput,
    showInputComponent,
    showMultiValueInsideInput,
    isNoOption,
    isLabelHidden,
  } = useBooleanValuesSelect<T>({
    isSearchable,
    isMultiSearch,
    disabled,
    showAddComponent,
    options,
    inputValue,
    optionSelected,
    maxSelectedOptions,
    isClearable,
    openDropdown,
    isMulti,
    isChip,
  });

  const onKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e?.key === 'Enter') {
      e.preventDefault();
      handleToggleDropdown();
    }
  };

  useEffect(() => {
    if (isMobileAll && mobileRef.current && isSearchable) {
      const height = mobileRef.current?.scrollHeight - 20;
      mobileRef.current.style.height = `${height.toString()}px`;
      mobileRef.current.style.overflow = 'auto';
    }
  }, [isMobileAll, options, mobileRef, openDropdown]);

  useEffect((): void => {
    const { current: inputElement } = inputRef;
    if (openDropdown) {
      inputElement?.focus();
      return;
    }
    inputElement?.blur();
  }, [openDropdown]);

  useEffect((): void => {
    if (valueProp !== undefined) {
      setOptionSelected(valueProp);
    }
  }, [valueProp]);

  useEffect((): void => {
    if (inputValueProp !== undefined) {
      setInputValue(inputValueProp);
    }
  }, [inputValueProp]);

  const dropdownList = options.map((option: SelectOption<T>): ReactElement => {
    const { title } = option;

    const selected: boolean = getIsOptionSelected(option, optionSelected);
    const onChangeHandler = handleChange(option);

    const onKeyDownMenuItem = (e: KeyboardEvent<HTMLLIElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        onChangeHandler();
      }
    };

    if (renderMenuItem) {
      return (
        <Fragment key={nanoid()}>
          {renderMenuItem({ option, selected, onChange: onChangeHandler })}
        </Fragment>
      );
    }

    const menuItem = (
      <MenuItem
        size={size}
        key={nanoid()}
        onClick={onChangeHandler}
        onKeyDown={onKeyDownMenuItem}
        selected={selected}
        className={currentOptionClassName}
        isMenuOpen={openDropdown}
        disabled={disabled}
      >
        {isMulti && !isChip ? (
          <div className={styles.select__checkboxContainer}>
            <Checkbox
              className={styles.select__checkbox}
              key={nanoid()}
              checked={selected}
              readOnly
              disabled={disabled}
            />
            <span className={styles.select__checkboxText}>{title}</span>
          </div>
        ) : (
          title
        )}
      </MenuItem>
    );

    return isTooltip ? (
      <div data-tip={title} data-for={title}>
        {menuItem}
      </div>
    ) : (
      menuItem
    );
  });

  const tooltipList =
    isTooltip &&
    useMemo(
      () =>
        options.map((option) => {
          return (
            <Tooltip
              id={option.title}
              place="right"
              delayShow={500}
              globalEventOff="mouseout"
            />
          );
        }),
      [options]
    );

  const ClearButton = showClearButton && !isChip && (
    <CloseIcon
      className={cn(styles.select__clearIcon, {
        [styles.select__clearIcon_multiSearch]: isMultiSearch,
      })}
      onClick={handleClear}
      onKeyDown={undefined}
      role="button"
      aria-label="select-icon"
      tabIndex={-1}
    />
  );

  const InputComponent = showInputComponent && (
    <>
      <input
        className={cn(styles[`select__input_${size}`], {
          [styles.select__input_filled]:
            inputValueProp?.length && !isMultiSearch,
          [styles.select__input_isMulti]: isMulti && !isMultiSearch,
          [styles.select__input]: !isMultiSearch,
          [styles.select__input_isMultiSearch]: isMultiSearch,
          [styles.select__input_disabled]: selectDisabled,
        })}
        value={inputValueProp}
        onChange={handleChangeInput}
        ref={inputRef}
        disabled={inputDisabled || selectDisabled}
        type="text"
        readOnly={readonly}
        placeholder={currentPlaceholder}
      />
      {ClearButton}
    </>
  );

  const valueInsideInput = showValueInsideInput && (
    <>
      {isVisiblePlaceholder && !isMultiSearch && (
        <div
          className={cn(
            styles.select__placeholder,
            styles[`select__placeholder_${size}`]
          )}
        >
          {currentPlaceholder}
        </div>
      )}
      {optionSelected && !Array.isArray(optionSelected) && (
        <div
          className={cn(
            styles.select__value,
            styles[`select__value_${size}`],
            {
              [styles.select__value_withPadding]: label,
              [styles[`select__value_withPadding_${size}`]]: label,
            },
            classNameValue,
            titleClassName
          )}
        >
          {optionSelected && renderValue
            ? renderValue(optionSelected)
            : getOptionTitle(optionSelected)}
        </div>
      )}
    </>
  );

  const multiValueInsideInput = showMultiValueInsideInput &&
    Array.isArray(optionSelected) && (
      <span
        className={cn(
          {
            [styles.select__multiContainer_chip]: isChip,
            [styles.select__multiContainer_notChip]: !isChip,
            [styles[`select__multi-container_not-chip_${size}`]]:
              !isChip && !isMultiSearch,
            [styles.select__multiContainer_search]: isMultiSearch,
          },
          classNameMultiContainer
        )}
      >
        {(!!optionSelected.length || label) && (
          <MultiValueContainer<T>
            selected={optionSelected}
            onChange={handleDeleteMultiOption}
            isChip={isChip}
            isMultiSearch={isMultiSearch}
            disabled={selectDisabled}
          />
        )}
        {InputComponent}
      </span>
    );

  const optionsScrollWrapper =
    infiniteScrollable && fetchNextData ? (
      <InfiniteScroll
        hasMore={!loading && totalPage > currentPage + 1}
        loadMore={fetchNextData}
        initialLoad={false}
        useWindow={false}
        loader={
          <Loader
            key={nanoid()}
            classNameRoot={styles.select__loader}
            className={styles.select__loaderInner}
          />
        }
        threshold={20}
      >
        {dropdownList}
      </InfiniteScroll>
    ) : (
      dropdownList
    );

  const addComponent = !!inputValue && showAddComponent && (
    <MenuItem size={size}>
      <button
        className={cn(styles.select__optionAddEntity)}
        onClick={handleClickAddEntity}
      >
        <PlusIcon className={styles.select__optionAddIcon} />
        Добавить {inputValue}
      </button>
    </MenuItem>
  );

  const noResultComponent = inputValue && !showAddComponent && (
    <MenuItem size={size} className={styles.select__option_noResult}>
      Нет результатов
    </MenuItem>
  );

  const Dropdown = (
    <div>
      <Menu
        size={size}
        classNameList={dropdownContentClassName}
        open={openDropdown}
      >
        <>
          {options.length > 0 ? optionsScrollWrapper : noResultComponent}
          {addComponent}
        </>
      </Menu>

      {!!options.length && isTooltip && openDropdown && (
        <div>{tooltipList}</div>
      )}
    </div>
  );

  const mobileOptionsWrapper = openDropdown && (
    <MobileDropdownWrapper
      openDropdown={openDropdown}
      handleCloseDropdown={handleCloseDropdown}
      handleChangeInput={handleChangeInput}
      mobileModalTitle={mobileModalTitle}
      isSearchable={isSearchable}
      inputRef={inputRef}
      mobileRef={mobileRef}
      inputValue={inputValue}
    >
      {optionsScrollWrapper}
    </MobileDropdownWrapper>
  );

  const RightIcon = !loading ? (
    <SearchIcon className={styles.select__searchIcon} />
  ) : (
    <Loader classNameRoot={styles.select__searchIcon} />
  );

  return (
    <OutsideClickHandler
      onClickOutside={handleCloseDropdown}
      className={cn(
        styles.select,
        styles[`select_${size}`],
        {
          [styles.select_isMulti]: isChip,
          [styles.select_isMultiSearch]: isMultiSearch,
        },
        className
      )}
    >
      <div
        className={cn(
          styles.select__container,
          {
            [styles.select__container_withArrow]:
              !isSearchable || isMultiSearch,
            [styles.select__container_open]: openDropdown,
            [styles.select__container_disabled]: selectDisabled,
            [styles.select__container_isMulti]: isChip,
            [styles[`select__container_isMulti_${size}`]]: isMulti,
          },
          classNameContainer
        )}
        onClick={selectDisabled ? undefined : handleToggleDropdown}
        role="button"
        onKeyDown={selectDisabled ? undefined : onKeyDown}
        tabIndex={selectDisabled ? -1 : 0}
      >
        {multiValueInsideInput}
        {!isMulti && !isMultiSearch && InputComponent}
        {valueInsideInput}
        <div
          className={cn(
            styles.select__label,
            labelClassName,
            styles[`select__label_${size}`],
            {
              [styles[`select__label_no-option_${size}`]]: isNoOption,
              [styles.select__label_hidden]: isLabelHidden,
            }
          )}
        >
          {label}
        </div>
        <div className={styles.select__containerIcons}>
          {!isMultiSearch || (!isMulti && ClearButton)}
          {!isMultiSearch && isSearchable && RightIcon}
        </div>
      </div>

      {!isMobileAll && Dropdown}
      {isMobileAll && mobileOptionsWrapper}
    </OutsideClickHandler>
  );
};
