import cn from 'clsx';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography, TypographyVariants } from 'components';
import { Priority } from 'core/types';
import { getPriorityByTitleTitleShortMap } from 'features/Ticket/constants';
import { Languages } from 'i18n/types';

import styles from './PriorityBlock.module.scss';

const priorityStyleMap = {
  [Priority.NONE]: styles.priorityBlock_none,
  [Priority.LOW]: styles.priorityBlock_low,
  [Priority.MIDDLE]: styles.priorityBlock_middle,
  [Priority.HIGH]: styles.priorityBlock_high,
  [Priority.VERY_HIGH]: styles.priorityBlock_veryHigh,
};

interface PriorityBlockProps {
  priority?: Priority | null;
  className?: string;
}

export const PriorityBlock: FC<PriorityBlockProps> = ({
  priority = Priority.NONE,
  className,
}) => {
  const { i18n } = useTranslation();
  return (
    <Typography
      variant={TypographyVariants.b3}
      className={cn(
        styles.priorityBlock,
        priorityStyleMap[priority || Priority.NONE],
        className
      )}
    >
      {
        getPriorityByTitleTitleShortMap(i18n.language as Languages)[
          priority || Priority.NONE
        ]
      }
    </Typography>
  );
};
