import { Environment } from 'core/types';

export const getDataTable = (environments?: Environment[]) =>
  environments?.map((environment) => {
    const { name, id } = environment;
    return {
      id,
      name,
    };
  }) || [];
