import { call, put, select, takeEvery } from 'redux-saga/effects';

import { setAlert } from 'core/ducks/actions';
import { createSuccessAlert } from 'core/layouts';
import { Attributes } from 'core/types';
import {
  createRule,
  deleteRuleRequest,
  fetchAttributesFieldsRequest,
  fetchAttributesFieldsSuccess,
  fetchCurrentRule,
  fetchFiltersFieldsRequest,
  fetchFiltersFieldsSuccess,
  fetchRulesRequest,
  fetchRulesSuccess,
  fetchSpecialistsRequest,
  fetchSpecialistsSuccess,
  fetchWorkGroupsRequest,
  fetchWorkGroupsSuccess,
  hideRulesLoading,
  resetCurrentRule,
  setCurrentRuleId,
  setCurrentRuleSuccess,
  showRulesLoading,
  updateRule,
} from 'features/Rules/ducks/index';
import { ResponseWithMeta } from 'store/types';
import { KeyValueOption } from 'types/models/meta';
import { createError } from 'utils';

import { Rule, RulesFilter, RulesFilterToRequest } from '../types';
import { getKeyFromKeyValue } from '../utils';

import { request } from './api/requests';
import { getCurrentRuleId, getFilterValues, getPropsRules } from './selectors';

export const getFilterRulesRequest = (
  filter: RulesFilter
): RulesFilterToRequest => {
  return filter;
};

function* filterFieldsFetch() {
  try {
    const rulesFields: Attributes = yield call(request.fetchFiltersFields);
    yield put(fetchFiltersFieldsSuccess(rulesFields));
  } catch (error) {
    createError(error);
  }
}

function* attributesFieldsFetch({
  payload,
}: ReturnType<typeof fetchAttributesFieldsRequest>) {
  try {
    const rulesFields: Attributes = yield call(
      request.fetchRulesFields,
      payload
    );
    yield put(fetchAttributesFieldsSuccess(rulesFields));
  } catch (error) {
    createError(error);
  }
}

function* specialistsFetch() {
  try {
    const specialists: KeyValueOption[] = yield call(request.fetchSpecialists);
    yield put(fetchSpecialistsSuccess(specialists));
  } catch (error) {
    createError(error);
  }
}

function* workGroupsFetch() {
  try {
    const workGroups: KeyValueOption[] = yield call(request.fetchWorkGroups);
    yield put(fetchWorkGroupsSuccess(workGroups));
  } catch (error) {
    createError(error);
  }
}

function* rulesFetch() {
  try {
    const { pageNum, pageSize, sortRules }: ReturnType<typeof getPropsRules> =
      yield select(getPropsRules);
    const filter: ReturnType<typeof getFilterValues> = yield select(
      getFilterValues
    );
    yield put(showRulesLoading());

    const rules: ResponseWithMeta<Rule[]> = yield call(
      request.fetchRules,
      pageNum,
      pageSize,
      sortRules,
      filter
    );
    yield put(fetchRulesSuccess(rules));
    yield put(hideRulesLoading());
  } catch (e) {
    yield put(hideRulesLoading());
    createError(e);
  }
}

function* currentRuleFetch({ payload }: ReturnType<typeof fetchCurrentRule>) {
  try {
    const rule: Rule = yield call(request.fetchCurrentRule, payload);

    const attributes = {
      organizations: getKeyFromKeyValue(rule.organizations),
      systems: getKeyFromKeyValue(rule.systems),
      ticketTypes: getKeyFromKeyValue(rule.ticketTypes),
      ticketPriorities: getKeyFromKeyValue(rule.ticketPriorities),
      environments: getKeyFromKeyValue(rule.environments),
      clients: getKeyFromKeyValue(rule.clients),
    };

    yield call(attributesFieldsFetch, {
      type: fetchAttributesFieldsRequest.type,
      payload: attributes,
    });

    yield put(setCurrentRuleSuccess(rule));
  } catch (e) {
    createError(e);
  }
}

function* ruleCreate({ payload }: ReturnType<typeof createRule>) {
  try {
    const { pageSize, sortRules }: ReturnType<typeof getPropsRules> =
      yield select(getPropsRules);
    const filter: ReturnType<typeof getFilterValues> = yield select(
      getFilterValues
    );
    const rule: Rule = yield call(request.createRule, payload);
    yield put(
      setAlert(
        createSuccessAlert(`Успешно выполнено создание правила ${rule.title}.`)
      )
    );
    yield put(showRulesLoading());

    const rules: ResponseWithMeta<Rule[]> = yield call(
      request.fetchRules,
      0,
      pageSize,
      sortRules,
      filter
    );
    yield put(fetchRulesSuccess(rules));
    yield put(hideRulesLoading());
  } catch (e) {
    yield put(hideRulesLoading());
    createError(e);
  }
}

function* ruleUpdate({ payload }: ReturnType<typeof createRule>) {
  try {
    const id: ReturnType<typeof getCurrentRuleId> = yield select(
      getCurrentRuleId
    );
    if (id) {
      const rule: Rule = yield call(request.updateRule, payload, id);
      yield put(setCurrentRuleId(rule.id));
      yield put(setCurrentRuleSuccess(rule));
      yield put(
        setAlert(
          createSuccessAlert(`Правило ${rule.title} успешно отредактировано`)
        )
      );
    }

    yield call(rulesFetch);
  } catch (e) {
    createError(e);
  }
}

function* deleteRule({ payload }: ReturnType<typeof deleteRuleRequest>) {
  try {
    yield call(request.deleteRule, payload);
    yield put(setAlert(createSuccessAlert(`Правило успешно удалено`)));
    yield put(resetCurrentRule());
    yield call(rulesFetch);
  } catch (e) {
    createError(e);
  }
}

export function* rulesSaga() {
  yield takeEvery(fetchFiltersFieldsRequest.type, filterFieldsFetch);
  yield takeEvery(fetchAttributesFieldsRequest.type, attributesFieldsFetch);
  yield takeEvery(fetchSpecialistsRequest.type, specialistsFetch);
  yield takeEvery(fetchWorkGroupsRequest.type, workGroupsFetch);
  yield takeEvery(fetchRulesRequest.type, rulesFetch);
  yield takeEvery(fetchCurrentRule.type, currentRuleFetch);
  yield takeEvery(createRule.type, ruleCreate);
  yield takeEvery(deleteRuleRequest.type, deleteRule);
  yield takeEvery(updateRule.type, ruleUpdate);
}
