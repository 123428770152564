import { KBLayout } from 'features/KnowledgeBase/components';
import { KBMain } from 'features/KnowledgeBase/views/KBMain';

const KBMainPage = () => {
  return (
    <KBLayout>
      <KBMain />
    </KBLayout>
  );
};

export default KBMainPage;
