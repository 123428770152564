import { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import { ArrowLeftIcon, CloseIcon } from 'assets/icons';
import { Drawer, IconButtonWrapper } from 'components';
import { useCreateNewsForm } from 'features/Profile/hooks';

import { getCurrentNews, getIsEditNewsMode } from '../../ducks/selectors';
import { NewsModalContent } from '../NewsModalContent';

import styles from './NewsModal.module.scss';

interface NewsModalProps {
  isModal?: boolean;
  toggleIsModal(): void;
}

export const NewsModal: FC<NewsModalProps> = ({ isModal, toggleIsModal }) => {
  const isEditMode = useSelector(getIsEditNewsMode);
  const currentNews = useSelector(getCurrentNews);

  const [isSubModal, setIsSubModal] = useState<boolean>(false);

  const data = useCreateNewsForm({ news: currentNews });

  const toggleIsSubModal = () => {
    setIsSubModal((prevState) => !prevState);
  };

  const title = !isEditMode ? 'Создать новость' : 'Редактировать новость';

  const subModalText = !isEditMode ? 'создание' : 'редактирование';

  return (
    <Drawer
      isOpen={isModal}
      approveOrCancelProps={{
        onApprove: toggleIsModal,
        isModal: isSubModal,
        toggleModal: toggleIsSubModal,
        text: `Вы уверены, что хотите отменить ${subModalText} новости?`,
      }}
    >
      <div className={styles.newsModal}>
        <div className={styles.newsModal__container}>
          <div className={styles.newsModal__header}>
            <p className={styles.newsModal__headerTitle}>{title}</p>
            <div className={styles.newsModal__buttonsWrapper}>
              <IconButtonWrapper
                className={styles.newsModal__headerCloseButton}
                onClick={toggleIsSubModal}
                icon={
                  <CloseIcon className={styles.newsModal__headerCloseIcon} />
                }
              />
            </div>
            <IconButtonWrapper
              className={styles.newsModal__headerCloseButtonMobile}
              onClick={toggleIsSubModal}
              icon={
                <ArrowLeftIcon className={styles.newsModal__headerCloseIcon} />
              }
            />
          </div>
          <NewsModalContent
            form={data}
            onReset={toggleIsSubModal}
            toggleIsModal={toggleIsModal}
            isModal={isModal}
          />
        </div>
      </div>
    </Drawer>
  );
};
