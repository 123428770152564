import { v4 as uuidv4 } from 'uuid';

import { SelectOption, TabItem, TableColumns } from 'components';
import { SELECT_ATTRIBUTES_LIST } from 'core/constants';
import { Attribute } from 'core/types';

import { ConditionType, DestinationType } from './types';

const ORGANIZATIONS = 'Организация';
const SYSTEMS = 'Система';
const TICKET_TYPES = 'Тип тикета';
const TICKET_PRIORITIES = 'Приоритет';
const ENVIRONMENTS = 'Среда';
const CLIENTS = 'Клиент';

export const RADIO_TABS: TabItem<DestinationType>[] = [
  {
    title: 'Назначить тикет на',
    value: DestinationType.SPECIALIST,
  },
  {
    title: 'Распределить тикет по',
    value: DestinationType.WORK_GROUP,
  },
];

export const SELECT_CONDITION_LIST: SelectOption<ConditionType>[] = [
  {
    title: 'До просрочки по реакции осталось',
    value: ConditionType.REACTION_EXPIRE_BEFORE,
  },
  {
    title: 'Просрочка по реакции составляет',
    value: ConditionType.REACTION_EXPIRE_EQUAL,
  },
];

export const CONDITION_MAP = {
  [ConditionType.REACTION_EXPIRE_BEFORE]: 'До просрочки по реакции осталось',
  [ConditionType.REACTION_EXPIRE_EQUAL]: 'Просрочка по реакции составляет',
};

export const INITIAL_ATTRIBUTES_VALUES = {
  organizations: [],
  environments: [],
  systems: [],
  ticketTypes: [],
  ticketPriorities: [],
  clients: [],
};

export const DEFAULT_VALUES_RULE_FORM = {
  title: '',
  minutes: '00:00',
  isEnable: false,
  executorType: DestinationType.SPECIALIST,
  executor: null,
  conditionType: SELECT_CONDITION_LIST[0],
  ...INITIAL_ATTRIBUTES_VALUES,
};

export const INITIAL_RULES_FILTER = {
  title: '',
  isActive: null,
  ...INITIAL_ATTRIBUTES_VALUES,
};

export const INITIAL_ATTRIBUTES: Attribute[] = [
  {
    id: uuidv4(),
    isActive: true,
    attribute: SELECT_ATTRIBUTES_LIST[0],
    value: [],
  },
  {
    id: uuidv4(),
    isActive: true,
    attribute: SELECT_ATTRIBUTES_LIST[1],
    value: [],
  },
];

export const TARGET_MAP = {
  [DestinationType.SPECIALIST]: 'targetSpecialist',
  [DestinationType.WORK_GROUP]: 'targetWorkgroup',
};

export const TABLE_HEADER: TableColumns = [
  {
    title: 'Название',
    sortField: 'title',
    fieldName: 'title',
  },
  {
    title: 'Активно',
    sortField: 'isActive',
    fieldName: 'isActive',
  },
  {
    title: ORGANIZATIONS,
    sortField: 'organizations',
    fieldName: 'organizations',
  },
  {
    title: SYSTEMS,
    sortField: 'systems',
    fieldName: 'systems',
  },
  {
    title: TICKET_TYPES,
    sortField: 'ticketTypes',
    fieldName: 'ticketTypes',
  },
  {
    title: TICKET_PRIORITIES,
    sortField: 'ticketPriorities',
    fieldName: 'ticketPriorities',
  },
  {
    title: ENVIRONMENTS,
    sortField: 'environments',
    fieldName: 'environments',
  },
  {
    title: CLIENTS,
    sortField: 'clients',
    fieldName: 'clients',
  },
];
