import { useEffect, useState } from 'react';

import { Theme } from 'core/types';

export const useSystemTheme = () => {
  const darkTheme = window.matchMedia(`(prefers-color-scheme: ${Theme.DARK})`);

  const initialTheme = darkTheme.matches ? Theme.DARK : Theme.LIGHT;

  const [systemTheme, setSystemTheme] = useState<Theme>(initialTheme);

  const changeThemeHandler = (event: MediaQueryListEvent) => {
    const colorScheme = event.matches ? Theme.DARK : Theme.LIGHT;
    setSystemTheme(colorScheme);
  };

  useEffect(() => {
    darkTheme.addEventListener('change', changeThemeHandler);
    return () => darkTheme.removeEventListener('change', changeThemeHandler);
  }, []);

  return systemTheme;
};
