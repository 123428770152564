import cn from 'clsx';
import { Children, forwardRef, memo, ReactElement } from 'react';

import { Size } from 'components';

import styles from './Menu.module.scss';

interface MenuProps {
  size?: Size;
  children: ReactElement | Array<ReactElement>;
  open?: boolean;
  className?: string;
  classNameList?: string;
}

const MenuComponent = forwardRef<HTMLDivElement, MenuProps>(
  (props, ref): ReactElement | null => {
    const {
      children,
      open = true,
      className,
      classNameList,
      size = Size.m,
    } = props;

    return (
      <div className={cn(styles.menu, className)} ref={ref}>
        <ul
          className={cn(
            styles.menu__list,
            {
              [styles.menu__list_open]: open,
              [styles[`menu__list_open_${size}`]]: open,
            },
            classNameList
          )}
          role="menu"
        >
          {Children.toArray(children)}
        </ul>
      </div>
    );
  }
);

export const Menu = memo(MenuComponent);
