import cn from 'clsx';
import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { PlusIcon } from 'assets/icons';
import {
  Button,
  Card,
  GoBackButton,
  Tab,
  Tabs,
  Typography,
  TypographyVariants,
} from 'components';
import { ActionList } from 'core/types/auth';
import { getIsAccessedAction } from 'features/Auth/ducks/selectors';
import { RouterHref } from 'routes/routerHref';

import styles from './Header.module.scss';

interface HeaderProps {
  title?: string | JSX.Element;
  withGoBack?: boolean;
  withCreateTicketButton?: boolean;
  className?: string;
  tabs?: Tab[];
  slideSetting?: JSX.Element;
  rightComponent?: JSX.Element;
}

export const Header: FC<HeaderProps> = ({
  title,
  withGoBack,
  withCreateTicketButton,
  className,
  tabs,
  slideSetting,
  rightComponent,
}) => {
  const { t } = useTranslation('global');
  const ref = useRef<HTMLDivElement>(null);

  const isAccessToCreateTicket = useSelector(
    getIsAccessedAction(ActionList.CreateTicket)
  );

  const { push } = useHistory();

  const goToCreateTicketPage = () => {
    push(RouterHref.CreateTicket);
  };

  const createButton = withCreateTicketButton && isAccessToCreateTicket && (
    <Button
      icon={<PlusIcon />}
      onClick={goToCreateTicketPage}
      className={styles.header__createButton}
    >
      {t('createButtonTitle')}
    </Button>
  );

  const goBackButton = withGoBack && (
    <GoBackButton className={styles.header__button} />
  );

  const tabsList = tabs && (
    <Tabs tabs={tabs} className={styles.header__tabs} tabsRef={ref} />
  );

  return (
    <Card
      className={cn(styles.header, {
        [styles.header_withTabs]: tabs,
        className,
      })}
    >
      <div className={styles.header__subWrapper}>
        <div className={styles.header__subWrapperLeft}>
          <Typography
            variant={TypographyVariants.h2}
            className={styles.header__title}
          >
            {title}
          </Typography>
          {slideSetting}
          {createButton}
        </div>
        {rightComponent}
      </div>
      {goBackButton}
      {tabsList}
    </Card>
  );
};
