import { FC, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MailIcon, PhoneIcon, UserIcon } from 'assets/icons';
import { PopoverContainer } from 'components';
import { DeleteButton } from 'core/components';
import { HEADER_TYPE } from 'core/constants';
import { getIsMobile, getIsMobileSmall } from 'core/ducks/selectors';
import { MainLayout } from 'core/layouts';
import { ApproveOrCancel } from 'core/modals';
import { ActionList } from 'core/types/auth';
import { getIsAccessedAction } from 'features/Auth';
import { useUserId } from 'features/User/hooks';
import { deleteUserRequest, getLoadingUser, getUser } from 'features/Users';
import { RouterHref } from 'routes/routerHref';

import styles from './UserLayout.module.scss';

export const UserLayout: FC = ({ children }) => {
  const dispatch = useDispatch();

  const isMobile = useSelector(getIsMobile);
  const isMobileSmall = useSelector(getIsMobileSmall);
  const loading = useSelector(getLoadingUser);
  const user = useSelector(getUser);
  const isAccessToDeleteUser = useSelector(
    getIsAccessedAction(ActionList.DeleteEntries)
  );

  const isMobileAll = isMobile || isMobileSmall;

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const userFullName = `${user?.lastName} ${user?.firstName} ${user?.middleName}`;

  const userId = useUserId();

  const toggleDeleteModal = () => {
    setShowDeleteModal((prev) => !prev);
  };

  const handleDelete = () => {
    if (userId) {
      dispatch(deleteUserRequest({ id: userId, withRedirect: true }));
    }
  };

  const handleShowModalDelete = () => {
    if (userId) {
      setShowDeleteModal(true);
    }
  };
  const infoBlocksConfig = useMemo(
    () => [
      {
        title: 'E-mail',
        info: userId && user?.email ? user.email : 'Нет данных',
        icon: <MailIcon className={styles.userLayout__icon} />,
      },
      {
        title: 'Телефон',
        info: userId && user?.phoneNumber ? user.phoneNumber : 'Нет данных',
        icon: <PhoneIcon className={styles.userLayout__icon} />,
      },
    ],
    [userId, user?.email, user?.phoneNumber]
  );

  const breadCrumbsConfig = useMemo(
    () => [
      { title: 'Пользователи', path: RouterHref.AdminUsers },
      { title: userFullName || '' },
    ],
    [userFullName]
  );

  const dropContent =
    isAccessToDeleteUser && userId ? (
      <PopoverContainer>
        <DeleteButton onClick={handleShowModalDelete} disabled={!user} />
      </PopoverContainer>
    ) : undefined;

  return (
    <>
      <MainLayout
        headerType={HEADER_TYPE}
        title={userId ? userFullName : 'Создать пользователя'}
        titleIcon={<UserIcon className={styles.userLayout__userIcon} />}
        withGoBack={isMobileAll}
        infoBlocksConfig={infoBlocksConfig}
        breadCrumbsConfig={userId ? breadCrumbsConfig : undefined}
        classNameTitleInfoBlock={styles.userLayout__titleInfoBlock}
        dropContent={dropContent}
        loading={loading && !user}
      >
        {children}
      </MainLayout>
      <ApproveOrCancel
        onApprove={handleDelete}
        isModal={showDeleteModal}
        toggleModal={toggleDeleteModal}
        approveTitle="Удалить"
        approveBtnProps={{
          appearance: 'outline',
          className: styles.userLayout__iconDelete,
        }}
        cancelTitle="Отмена"
        text={`Вы уверены, что хотите удалить пользователя ${user?.firstName} ${user?.lastName}?`}
      />
    </>
  );
};
