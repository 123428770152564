import { debounce } from 'lodash';
import { FC, memo, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { Input, Size, TableBodyCell, TableFilter } from 'components';
import { DEFAULT_DEBOUNCE_DELAY } from 'constants/meta';
import { checkObjectIdentity } from 'utils';

import { INITIAL_ACCESSES_FILTER } from '../../constants';
import {
  fetchAccessesByRespIdRequest,
  setAccessesFilter,
  setCurrentAccessesPage,
} from '../../ducks/actions';
import { AccessesFilter as AccessesFilterData } from '../../types';

import styles from './AccessesFilter.module.scss';

export const AccessesFilter: FC = memo(() => {
  const { register, handleSubmit, watch, reset } = useForm<AccessesFilterData>({
    mode: 'onChange',
    defaultValues: INITIAL_ACCESSES_FILTER,
  });

  const filterValues = watch();

  const dispatch = useDispatch();

  const formSubmitHandler = handleSubmit((data) => {
    const { title, nameInDatabase } = data;
    const preparedData = {
      title: title || undefined,
      nameInDatabase: nameInDatabase || undefined,
    };
    dispatch(setAccessesFilter(preparedData));
    dispatch(setCurrentAccessesPage(0));
    dispatch(fetchAccessesByRespIdRequest());
  });

  const resetFilter = () => {
    dispatch(setAccessesFilter({}));
    dispatch(fetchAccessesByRespIdRequest());
    reset();
  };

  const formSubmitHandlerDebounced = useMemo(
    () => debounce(formSubmitHandler, DEFAULT_DEBOUNCE_DELAY),
    []
  );

  const titleToInput = register('title', {
    onChange: formSubmitHandlerDebounced,
  });
  const nameInDatabaseToInput = register('nameInDatabase', {
    onChange: formSubmitHandlerDebounced,
  });

  const tableBodyWrapper = (elements: JSX.Element[]) =>
    elements.map((item) => (
      <TableBodyCell className={styles.accessesFilter__cell} key={item.key}>
        {item}
      </TableBodyCell>
    ));

  const filterElements = [
    <Input size={Size.xs} type="title" key="title" {...titleToInput} />,
    <Input
      size={Size.xs}
      type="nameInDatabase"
      key="nameInDatabase"
      {...nameInDatabaseToInput}
    />,
  ];

  const filter = <>{tableBodyWrapper(filterElements)}</>;

  const disableReset = checkObjectIdentity(filterValues, {});

  return (
    <TableFilter
      filterComponent={filter}
      onReset={resetFilter}
      disableReset={disableReset}
    />
  );
});
