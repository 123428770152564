import { AdminsLayout } from 'core/layouts';
import { Systems } from 'features/Systems';

const AdminSystems = () => {
  return (
    <AdminsLayout>
      <Systems />
    </AdminsLayout>
  );
};

export default AdminSystems;
