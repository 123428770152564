import { TabItem } from 'components';
import { Ticket } from 'core/types/ticket';

export const getMatchedTicketTypeValue = (
  items: TabItem[],
  ticket?: Ticket
) => {
  const matchingTicketType = items.find(
    (item) => item.title === ticket?.ticketType?.value
  );
  return matchingTicketType?.value;
};
