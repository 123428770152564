import { AdminsLayout } from 'core/layouts';
import { WorkGroups } from 'features/WorkGroups';

const AdminGroups = () => {
  return (
    <AdminsLayout>
      <WorkGroups />
    </AdminsLayout>
  );
};

export default AdminGroups;
