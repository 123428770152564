import { createSelector } from 'reselect';

import { getArraySelectOptions, SelectOption } from 'components';
import { TicketType } from 'core/types';
import { Organization } from 'core/types/organization';
import { System } from 'core/types/system';
import { Workflow } from 'core/types/workflow';
import { RootState } from 'store/rootReducer';

export const getSystems = (state: RootState): System[] | undefined =>
  state.statusesBuilder.systems;
export const getLoading = (state: RootState): boolean | undefined =>
  state.statusesBuilder.loading;
export const getPropsSystems = (state: RootState) => ({
  pageNum: state.statusesBuilder.pagination?.pageNum,
  pageSize: state.statusesBuilder.pagination?.pageSize,
  sortSystems: state.statusesBuilder.sort,
  totalElements: state.statusesBuilder.pagination?.totalElements,
  loadingSystem: getLoading(state),
});
export const getSort = (state: RootState) => state.statusesBuilder.sort;
export const getSystemId = (state: RootState) => state.statusesBuilder.systemId;
export const getSystemsFilter = (state: RootState) =>
  state.statusesBuilder.systemsFilter || {};
export const getOrganizations = (
  state: RootState
): Organization[] | undefined => state.statusesBuilder.organizations;
export const getWorkflow = (state: RootState): Workflow | undefined =>
  state.statusesBuilder.workflow;
export const getTicketTypes = (state: RootState) =>
  state.statusesBuilder.ticketTypesForSystem;

export const getTicketTypesSelectList = createSelector<
  RootState,
  TicketType[] | undefined,
  SelectOption[]
>([getTicketTypes], (types): SelectOption[] => {
  return getArraySelectOptions(types);
});

export const getOrganizationsSelectList = createSelector<
  RootState,
  Organization[] | undefined,
  SelectOption[]
>([getOrganizations], (organizations): SelectOption[] => {
  return getArraySelectOptions(organizations);
});

export const getSystemsSelectList = createSelector<
  RootState,
  System[] | undefined,
  SelectOption[]
>([getSystems], (systems): SelectOption[] => {
  return getArraySelectOptions(systems);
});
