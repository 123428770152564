import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CheckMarkIcon, JiraLogoIcon } from 'assets/icons';
import {
  PopoverButton,
  PopoverContainer,
  PopoverItemWrapper,
  PopoverTitle,
} from 'components';
import { DeleteButton } from 'core/components';
import { getIsMobile, getIsMobileSmall } from 'core/ducks/selectors';
import { TicketStatus } from 'core/types';
import { ActionList } from 'core/types/auth';
import { getIsClient } from 'features/Auth';
import {
  MobileEditTicketType,
  SpecialistForm,
} from 'features/Ticket/components';
import { createTicketInJira } from 'features/Ticket/ducks/ticket/actions';
import {
  getIsAccessedTicketAction,
  getTicket,
} from 'features/Ticket/ducks/ticket/selectors';

import styles from './TicketLayoutDropContent.module.scss';

interface TicketLayoutDropContentProps {
  isJiraButtonDisabled: boolean;
  handleShowModalDelete(): void;
  handleShowModalClose(): void;
}

export const TicketLayoutDropContent: FC<TicketLayoutDropContentProps> = ({
  isJiraButtonDisabled,
  handleShowModalDelete,
  handleShowModalClose,
}) => {
  const dispatch = useDispatch();

  const ticket = useSelector(getTicket);
  const isMobile = useSelector(getIsMobile);
  const isMobileSmall = useSelector(getIsMobileSmall);
  const isClient = useSelector(getIsClient);
  const isAccessToDeleteTicket = useSelector(
    getIsAccessedTicketAction(ActionList.DeleteTicket)
  );
  const isAccessToAssigningTicketToSpecialist = useSelector(
    getIsAccessedTicketAction(ActionList.AssigningTicketToSpecialist)
  );
  const isAccessViewingAllTickets = useSelector(
    getIsAccessedTicketAction(ActionList.ViewingAllTickets)
  );

  const isMobileAll = isMobile || isMobileSmall;
  const isShowPopoverTitle =
    !isMobileAll &&
    ((!isClient && isAccessToAssigningTicketToSpecialist) ||
      !isJiraButtonDisabled ||
      isAccessToDeleteTicket);

  const handleCreateTicketInJira = () => {
    if (
      ticket?.systemId &&
      ticket?.id &&
      ticket?.ticketType &&
      ticket?.specialistId
    ) {
      dispatch(
        createTicketInJira({
          systemId: ticket?.systemId.key,
          ticketId: ticket?.id,
          ticketTypeId: ticket?.ticketType?.key,
          userId: ticket?.specialistId.key,
        })
      );
    }
  };

  const actions = ticket?.customStatus?.defaultStatus !== TicketStatus.CLOSE &&
    isAccessViewingAllTickets && (
      <>
        <PopoverTitle title="Действия" />
        <PopoverButton
          icon={
            <CheckMarkIcon
              className={styles.ticketLayoutDropContent__closeIcon}
            />
          }
          onClick={handleShowModalClose}
        >
          Закрыть тикет
        </PopoverButton>
      </>
    );

  return (
    <PopoverContainer>
      {actions}
      {isShowPopoverTitle && <PopoverTitle title="Дополнительно" />}
      {isMobileAll && !isClient && isAccessToAssigningTicketToSpecialist && (
        <PopoverItemWrapper>
          <SpecialistForm
            className={styles.ticketLayoutDropContent__specialist}
            classNameForm={styles.ticketLayoutDropContent__specialistForm}
            classNameSelect={styles.ticketLayoutDropContent__specialistSelect}
            classNameButton={styles.ticketLayoutDropContent__specialistButton}
          />
        </PopoverItemWrapper>
      )}

      {isMobileAll && <MobileEditTicketType />}

      {!isJiraButtonDisabled && (
        <PopoverButton
          icon={
            <JiraLogoIcon
              className={styles.ticketLayoutDropContent__jiraIcon}
            />
          }
          onClick={handleCreateTicketInJira}
          disabled={isJiraButtonDisabled}
        >
          Создать связанную задачу в Jira
        </PopoverButton>
      )}

      {isAccessToDeleteTicket && (
        <DeleteButton onClick={handleShowModalDelete} disabled={!ticket} />
      )}
    </PopoverContainer>
  );
};
