import cn from 'clsx';
import { FC } from 'react';

import styles from './PopoverLine.module.scss';

interface PopoverLineProps {
  className?: string;
}

export const PopoverLine: FC<PopoverLineProps> = ({ className }) => {
  return <div className={cn(styles.popoverLine, className)} />;
};
