import { call, put, select, takeEvery } from 'redux-saga/effects';

import { createError } from 'utils';

import {
  DesktopFilters,
  EmployeesStatistic,
  SlaStatistic,
  TicketsStatistic,
} from '../../types';
import { getDesktopFilters } from '../Desktop/selectors';

import { requests } from './api/requests';
import { getEmployeesPageNumber, getEmployeesPageSize } from './selectors';

import {
  fetchEmployeesStatisticRequest,
  fetchEmployeesStatisticSuccess,
  fetchSlaStatisticRequest,
  fetchSlaStatisticSuccess,
  fetchTicketsStatisticRequest,
  fetchTicketsStatisticSuccess,
  setIsLoading,
} from './index';

function* slaStatisticFetch() {
  try {
    const filter: DesktopFilters = yield select(getDesktopFilters);
    const data: SlaStatistic = yield call(requests.fetchSlaStatistic, filter);
    yield put(fetchSlaStatisticSuccess(data));
  } catch (error) {
    createError(error);
  }
}

function* employeesStatisticFetch() {
  try {
    yield put(setIsLoading(true));
    const pageNum: ReturnType<typeof getEmployeesPageNumber> = yield select(
      getEmployeesPageNumber
    );
    const pageSize: ReturnType<typeof getEmployeesPageSize> = yield select(
      getEmployeesPageSize
    );
    const filter: DesktopFilters = yield select(getDesktopFilters);
    const data: EmployeesStatistic = yield call(
      requests.fetchEmployeesStatistic,
      pageNum,
      pageSize,
      filter
    );
    yield put(fetchEmployeesStatisticSuccess(data));
    yield put(setIsLoading(false));
  } catch (error) {
    createError(error);
  }
}

function* ticketsStatisticFetch() {
  try {
    const filter: DesktopFilters = yield select(getDesktopFilters);
    const data: TicketsStatistic = yield call(
      requests.fetchTicketsStatistic,
      filter
    );
    yield put(fetchTicketsStatisticSuccess(data));
  } catch (error) {
    createError(error);
  }
}

export function* desktopEmployeesSaga() {
  yield takeEvery(fetchSlaStatisticRequest.type, slaStatisticFetch);
  yield takeEvery(fetchEmployeesStatisticRequest.type, employeesStatisticFetch);
  yield takeEvery(fetchTicketsStatisticRequest.type, ticketsStatisticFetch);
}
