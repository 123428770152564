import { FC } from 'react';

import { Loader } from 'components';

import styles from './Loading.module.scss';

export const Loading: FC = () => {
  return (
    <div className={styles.loading__wrapper}>
      <Loader />
    </div>
  );
};
