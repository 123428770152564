import { FC, useLayoutEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setTheme } from 'core/ducks/actions';
import {
  getContrastTheme,
  getSystemTheme,
  getTheme,
} from 'core/ducks/selectors';
import { useSystemTheme } from 'core/hooks';
import { Theme, ThemeActive } from 'core/types';
import { getSystemTheme as getInitialTheme } from 'utils';

export const withTheme = <Props,>(Component: FC<Props>) => {
  // eslint-disable-next-line sonarjs/cognitive-complexity
  return (props: Props) => {
    const theme = useSelector(getTheme);
    const systemTheme = useSelector(getSystemTheme);
    const contrastTheme = useSelector(getContrastTheme);

    const dispatch = useDispatch();

    const currentSystemTheme = useSystemTheme();

    const isSystemTheme = systemTheme === ThemeActive.ON;
    const isContrastTheme = contrastTheme === ThemeActive.ON;
    const lightTheme = !isContrastTheme ? Theme.LIGHT : Theme.LIGHT_CONTRAST;

    const currentSystemThemeWithContrast = useMemo(
      () =>
        currentSystemTheme === Theme.DARK
          ? Theme.DARK_CONTRAST
          : Theme.LIGHT_CONTRAST,
      [currentSystemTheme]
    );

    useLayoutEffect(() => {
      if (isSystemTheme) {
        if (!isContrastTheme) {
          dispatch(setTheme(currentSystemTheme));
          return;
        }
        dispatch(setTheme(currentSystemThemeWithContrast));
        return;
      }

      const currentTheme = localStorage.getItem('theme') as Theme;

      if (!Object.values(Theme).includes(currentTheme)) {
        dispatch(setTheme(lightTheme));
      }
    }, [isSystemTheme, isContrastTheme, currentSystemTheme]);

    useLayoutEffect(() => {
      if (!theme) {
        const initialTheme = getInitialTheme();
        localStorage.setItem('theme', initialTheme);
        document.documentElement.dataset.theme = initialTheme;
        return;
      }

      document.documentElement.dataset.theme = theme;
    }, [theme]);

    useLayoutEffect(() => {
      if (!systemTheme) {
        localStorage.setItem('system-theme', ThemeActive.ON);
      }
    }, [systemTheme]);

    useLayoutEffect(() => {
      if (!contrastTheme) {
        localStorage.setItem('contrast-theme', ThemeActive.OFF);
      }
    }, [contrastTheme]);

    return <Component {...props} />;
  };
};
