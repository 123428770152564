import { Access } from 'features/Accesses/types';
import { RootState } from 'store/rootReducer';

export const getAccesses = (state: RootState): Access[] | undefined =>
  state.accesses.accesses;
export const getLoading = (state: RootState): boolean | undefined =>
  state.accesses.loading;
export const getAccessesFilter = (state: RootState) =>
  state.accesses.accessesFilter || {};
export const getPropsAccesses = (state: RootState) => ({
  pageNum: state.accesses.pagination?.pageNum,
  pageSize: state.accesses.pagination?.pageSize,
  sortAccesses: state.accesses.sort,
  totalElements: state.accesses.pagination?.totalElements,
  loadingAccesses: state.accesses.loading,
});
