import {
  Attachment,
  AttachmentOthersFields,
  AttachmentsFiles,
} from 'types/models/attachment';

export const getFileField = (
  file: AttachmentsFiles,
  attachment: Attachment | undefined
): AttachmentOthersFields => {
  return {
    attachmentId: file?.attachmentId || attachment?.id,
    attachmentFileId: file?.attachmentFileId || file?.id,
    created: file?.created || attachment?.dateCreated,
  };
};
