import { getUrl } from 'utils';

export const endpoints = {
  addAttachment: () => getUrl('attachment'),
  fetchAttachments: () => getUrl('attachment'),
  fetchTicketCommentsAttachments: (ticketId: string) =>
    getUrl(`attachment/ticket/comments/${ticketId}`),
  fetchTicketNotesAttachments: (ticketId: string) => getUrl(`note/${ticketId}`),
  deleteFileAttachmentsTicket: (
    attachmentId?: string,
    attachmentFileId?: string
  ) => getUrl(`attachment/${attachmentId}/${attachmentFileId}`),
};
