import { NotificationContent } from 'core/types/notification';

import {
  AddCurrentNotificationAction,
  DeleteAllCurrentNotificationsAction,
  DeleteCurrentNotificationAction,
  DeleteNotificationByIdAction,
  Notifications,
  SetActiveNotificationIdAction,
  SetAllNotificationsAction,
  SetNotificationsMyTicketsIdsAction,
  SetNotificationsNewTicketsIdsAction,
} from './types';

export const setAllNotifications = (
  notifications: NotificationContent[]
): SetAllNotificationsAction => {
  return {
    type: Notifications.SET_ALL_NOTIFICATIONS,
    payload: notifications,
  };
};

export const addCurrentNotification = (
  notification: NotificationContent
): AddCurrentNotificationAction => {
  return {
    type: Notifications.ADD_CURRENT_NOTIFICATION,
    payload: notification,
  };
};

export const deleteCurrentNotification = (
  entityId: string
): DeleteCurrentNotificationAction => {
  return {
    type: Notifications.DELETE_CURRENT_NOTIFICATION,
    payload: entityId,
  };
};

export const deleteNotificationById = (
  entityId: string
): DeleteNotificationByIdAction => {
  return {
    type: Notifications.DELETE_NOTIFICATION_BY_ID,
    payload: entityId,
  };
};

export const deleteAllCurrentNotifications =
  (): DeleteAllCurrentNotificationsAction => {
    return {
      type: Notifications.DELETE_ALL_CURRENT_NOTIFICATIONS,
    };
  };

export const setNotificationsMyTicketsIds = (
  ticketsIds: string[]
): SetNotificationsMyTicketsIdsAction => {
  return {
    type: Notifications.SET_NOTIFICATIONS_MY_TICKERS_IDS,
    payload: ticketsIds,
  };
};

export const setNotificationsNewTicketsIds = (
  ticketsIds: string[]
): SetNotificationsNewTicketsIdsAction => {
  return {
    type: Notifications.SET_NOTIFICATIONS_NEW_TICKERS_IDS,
    payload: ticketsIds,
  };
};

export const setActiveNotificationId = (
  id: string
): SetActiveNotificationIdAction => {
  return {
    type: Notifications.SET_ACTIVE_NOTIFICATION_ID,
    payload: id,
  };
};
