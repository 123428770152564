import { NotificationContent } from 'core/types/notification';
import { ResponseWithMeta } from 'store/types';
import { KeyValueOption } from 'types/models/meta';

import {
  FetchNewsParams,
  FetchNotificationsParams,
  FilterNotificationsProfile,
  HasUncheckedNotificationsType,
  News,
  NewsCreateData,
  NewsDelete,
  ProfileSettings,
  ProfileTabSettings,
} from '../types';

import {
  CheckHasUnpublishedNewsAction,
  CheckHasUnpublishedNewsSuccessAction,
  CheckNewsRequestAction,
  CheckNewsSuccessAction,
  FetchCurrentNewsRequestAction,
  FetchCurrentNewsSuccessAction,
  FetchIsUncheckedNewsRequestAction,
  FetchIsUncheckedNewsSuccessAction,
  FetchIsUncheckedNotificationsRequestAction,
  FetchNewsJoinSuccessAction,
  FetchNewsRequestAction,
  FetchNewsSuccessAction,
  FetchNotificationSettingsRequestAction,
  FetchNotificationSettingsSuccessAction,
  FetchNotificationsProfileJoinSuccessAction,
  FetchNotificationsProfileRequestAction,
  FetchNotificationsProfileSuccessAction,
  FetchNotificationTabsRequestAction,
  FetchNotificationTabsSuccessAction,
  FetchOrganizationsRequestAction,
  FetchOrganizationsSuccessAction,
  FetchWorkGroupsRequestAction,
  FetchWorkGroupsSuccessAction,
  HideCurrentNewsLoadingAction,
  HideNewsLoadingAction,
  HideNotificationsProfileLoadingAction,
  NewsCreateAction,
  NewsDeleteAction,
  NewsEditAction,
  NotificationsProfile,
  ResetNewsStateAction,
  ResetNotificationsListStateAction,
  ResetNotificationsProfileStateAction,
  SetAllCheckedNotificationsRequestAction,
  SetCheckedNotificationRequestAction,
  SetCheckedNotificationSuccessAction,
  SetCurrentNotificationsProfilePageAction,
  SetFilterNotificationsProfileAction,
  SetIsEditNewsModeAction,
  SetIsNewsModalAction,
  SetIsUncheckedNotificationsSuccessAction,
  SetSizePageNotificationsProfileAction,
  SetSortNotificationsProfileAction,
  ShowCurrentNewsLoadingAction,
  ShowNewsLoadingAction,
  ShowNotificationsProfileLoadingAction,
  UpdateNotificationSettingsRequestAction,
} from './types';

export const showNotificationsProfileLoading =
  (): ShowNotificationsProfileLoadingAction => {
    return {
      type: NotificationsProfile.LOADING_SHOW_NOTIFICATIONS_PROFILE,
    };
  };

export const hideNotificationsProfileLoading =
  (): HideNotificationsProfileLoadingAction => {
    return {
      type: NotificationsProfile.LOADING_HIDE_NOTIFICATIONS_PROFILE,
    };
  };

export const fetchNotificationsProfileRequest = (
  options: FetchNotificationsParams
): FetchNotificationsProfileRequestAction => {
  return {
    type: NotificationsProfile.FETCH_NOTIFICATIONS_PROFILE_REQUEST,
    payload: options,
  };
};

export const fetchNotificationsProfileSuccess = (
  data: ResponseWithMeta<NotificationContent[]>
): FetchNotificationsProfileSuccessAction => {
  return {
    type: NotificationsProfile.FETCH_NOTIFICATIONS_PROFILE_SUCCESS,
    payload: data,
  };
};

export const fetchNotificationsProfileJoinSuccess = (
  data: ResponseWithMeta<NotificationContent[]>
): FetchNotificationsProfileJoinSuccessAction => {
  return {
    type: NotificationsProfile.FETCH_NOTIFICATIONS_PROFILE_SUCCESS_JOIN,
    payload: data,
  };
};

export const setCurrentNotificationsProfilePage = (
  page: number
): SetCurrentNotificationsProfilePageAction => {
  return {
    type: NotificationsProfile.SET_CURRENT_PAGE_NOTIFICATIONS_PROFILE,
    payload: page,
  };
};

export const setSizePageNotificationsProfile = (
  size: number
): SetSizePageNotificationsProfileAction => {
  return {
    type: NotificationsProfile.SET_SIZE_PAGE_NOTIFICATIONS_PROFILE,
    payload: size,
  };
};

export const setSortNotificationsProfile = (
  sort: string
): SetSortNotificationsProfileAction => {
  return {
    type: NotificationsProfile.SET_SORT_NOTIFICATIONS_PROFILE,
    payload: sort,
  };
};

export const resetNotificationsProfileState =
  (): ResetNotificationsProfileStateAction => {
    return {
      type: NotificationsProfile.RESET_STATE_NOTIFICATIONS_PROFILE,
    };
  };

export const setNotificationsProfileFilter = (
  data: FilterNotificationsProfile
): SetFilterNotificationsProfileAction => {
  return {
    type: NotificationsProfile.SET_FILTER_NOTIFICATIONS_PROFILE,
    payload: data,
  };
};

export const setAllCheckedNotifications =
  (): SetAllCheckedNotificationsRequestAction => {
    return {
      type: NotificationsProfile.SET_ALL_CHECKED_NOTIFICATIONS_REQUEST,
    };
  };

export const getIsUncheckedNotifications = (
  data: HasUncheckedNotificationsType[]
): FetchIsUncheckedNotificationsRequestAction => {
  return {
    type: NotificationsProfile.GET_IS_UNCHECKED_STATUSES_REQUEST,
    payload: data,
  };
};

export const setIsUncheckedNotificationsSuccess = (
  data: HasUncheckedNotificationsType[]
): SetIsUncheckedNotificationsSuccessAction => {
  return {
    type: NotificationsProfile.SET_IS_UNCHECKED_STATUSES_SUCCESS,
    payload: data,
  };
};

export const setCheckedNotificationRequest = (
  id: string
): SetCheckedNotificationRequestAction => {
  return {
    type: NotificationsProfile.SET_CHECKED_NOTIFICATION_REQUEST,
    payload: id,
  };
};

export const setCheckedNotificationSuccess = (
  id: string
): SetCheckedNotificationSuccessAction => {
  return {
    type: NotificationsProfile.SET_CHECKED_NOTIFICATION_SUCCESS,
    payload: id,
  };
};

export const fetchNotificationTabsRequest =
  (): FetchNotificationTabsRequestAction => {
    return {
      type: NotificationsProfile.FETCH_NOTIFICATION_TABS_REQUEST,
    };
  };

export const fetchNotificationTabsSuccess = (
  tabs: ProfileTabSettings[]
): FetchNotificationTabsSuccessAction => {
  return {
    type: NotificationsProfile.FETCH_NOTIFICATION_TABS_SUCCESS,
    payload: tabs,
  };
};

export const updateNotificationSettingsRequest = (
  tabs: ProfileSettings
): UpdateNotificationSettingsRequestAction => {
  return {
    type: NotificationsProfile.UPDATE_NOTIFICATION_SETTINGS_REQUEST,
    payload: tabs,
  };
};

export const fetchNotificationSettingsRequest =
  (): FetchNotificationSettingsRequestAction => {
    return {
      type: NotificationsProfile.FETCH_NOTIFICATION_SETTINGS_REQUEST,
    };
  };

export const fetchNotificationSettingsSuccess = (
  settings: ProfileSettings
): FetchNotificationSettingsSuccessAction => {
  return {
    type: NotificationsProfile.FETCH_NOTIFICATION_SETTINGS_SUCCESS,
    payload: settings,
  };
};

export const resetNotificationList = (): ResetNotificationsListStateAction => {
  return {
    type: NotificationsProfile.RESET_NOTIFICATIONS_LIST,
  };
};

export const showNewsLoading = (): ShowNewsLoadingAction => {
  return {
    type: NotificationsProfile.LOADING_SHOW_NEWS,
  };
};

export const hideNewsLoading = (): HideNewsLoadingAction => {
  return {
    type: NotificationsProfile.LOADING_HIDE_NEWS,
  };
};

export const fetchNewsRequest = (
  payload: FetchNewsParams
): FetchNewsRequestAction => {
  return {
    type: NotificationsProfile.FETCH_NEWS_REQUEST,
    payload,
  };
};

export const fetchNewsSuccess = (
  data: ResponseWithMeta<News[]>
): FetchNewsSuccessAction => {
  return {
    type: NotificationsProfile.FETCH_NEWS_SUCCESS,
    payload: data,
  };
};

export const fetchNewsJoinSuccess = (
  data: ResponseWithMeta<News[]>
): FetchNewsJoinSuccessAction => {
  return {
    type: NotificationsProfile.FETCH_NEWS_SUCCESS_JOIN,
    payload: data,
  };
};

export const resetNewsState = (): ResetNewsStateAction => {
  return {
    type: NotificationsProfile.RESET_STATE_NEWS,
  };
};

export const newsCreate = (data: NewsCreateData): NewsCreateAction => {
  return {
    type: NotificationsProfile.NEWS_CREATE,
    payload: data,
  };
};

export const newsEdit = (data: NewsCreateData): NewsEditAction => {
  return {
    type: NotificationsProfile.NEWS_EDIT,
    payload: data,
  };
};

export const fetchCurrentNewsRequest = (
  id: string
): FetchCurrentNewsRequestAction => {
  return {
    type: NotificationsProfile.CURRENT_NEWS_FETCH_REQUEST,
    payload: id,
  };
};

export const fetchCurrentNewsSuccess = (
  payload?: News
): FetchCurrentNewsSuccessAction => {
  return {
    type: NotificationsProfile.CURRENT_NEWS_FETCH_SUCCESS,
    payload,
  };
};

export const showCurrentNewsLoading = (): ShowCurrentNewsLoadingAction => {
  return {
    type: NotificationsProfile.LOADING_SHOW_CURRENT_NEWS,
  };
};

export const hideCurrentNewsLoading = (): HideCurrentNewsLoadingAction => {
  return {
    type: NotificationsProfile.LOADING_HIDE_CURRENT_NEWS,
  };
};

export const setIsEditNewsMode = (
  payload: boolean
): SetIsEditNewsModeAction => {
  return {
    type: NotificationsProfile.SET_IS_EDIT_NEWS_MODE,
    payload,
  };
};

export const setIsNewsModal = (payload: boolean): SetIsNewsModalAction => {
  return {
    type: NotificationsProfile.SET_IS_NEWS_MODAL,
    payload,
  };
};

export const newsDelete = (payload: NewsDelete): NewsDeleteAction => {
  return {
    type: NotificationsProfile.NEWS_DELETE,
    payload,
  };
};

export const fetchIsUncheckedNewsRequest =
  (): FetchIsUncheckedNewsRequestAction => {
    return {
      type: NotificationsProfile.FETCH_IS_UNCHECKED_NEWS_REQUEST,
    };
  };

export const fetchIsUncheckedNewsSuccess = (
  payload: boolean
): FetchIsUncheckedNewsSuccessAction => {
  return {
    type: NotificationsProfile.FETCH_IS_UNCHECKED_NEWS_SUCCESS,
    payload,
  };
};

export const checkNewsRequest = (id: string): CheckNewsRequestAction => {
  return {
    type: NotificationsProfile.CHECK_NEWS_REQUEST,
    payload: id,
  };
};

export const checkNewsSuccess = (payload: string): CheckNewsSuccessAction => {
  return {
    type: NotificationsProfile.CHECK_NEWS_SUCCESS,
    payload,
  };
};

export const fetchOrganizationsRequest =
  (): FetchOrganizationsRequestAction => {
    return {
      type: NotificationsProfile.FETCH_ORGANIZATIONS,
    };
  };

export const fetchOrganizationsSuccess = (
  payload: KeyValueOption[]
): FetchOrganizationsSuccessAction => {
  return {
    type: NotificationsProfile.FETCH_ORGANIZATIONS_SUCCESS,
    payload,
  };
};

export const fetchWorkGroupsRequest = (
  payload: string[]
): FetchWorkGroupsRequestAction => {
  return {
    type: NotificationsProfile.FETCH_WORK_GROUPS,
    payload,
  };
};

export const fetchWorkGroupsSuccess = (
  payload: KeyValueOption[]
): FetchWorkGroupsSuccessAction => {
  return {
    type: NotificationsProfile.FETCH_WORK_GROUPS_SUCCESS,
    payload,
  };
};

export const checkHasUnpublishedNews = (): CheckHasUnpublishedNewsAction => {
  return {
    type: NotificationsProfile.CHECK_HAS_UNPUBLISHED_NEWS_REQUEST,
  };
};

export const checkHasUnpublishedNewsSuccess = (
  payload: boolean
): CheckHasUnpublishedNewsSuccessAction => {
  return {
    type: NotificationsProfile.CHECK_HAS_UNPUBLISHED_NEWS_SUCCESS,
    payload,
  };
};
