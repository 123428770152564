import Highcharts, { SeriesPieOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { FC } from 'react';

import { getCurrentOptions } from './utils';

interface LineChartProps {
  data?: SeriesPieOptions['data'];
  dataAdditional?: SeriesPieOptions['data'];
  lineChartElement?: JSX.Element;
}

export const LineChart: FC<LineChartProps> = ({
  data,
  dataAdditional,
  lineChartElement,
}) => {
  const currentOptions = getCurrentOptions(dataAdditional, data);

  return (
    <>
      {lineChartElement}
      <HighchartsReact highcharts={Highcharts} options={currentOptions} />
    </>
  );
};
