import cn from 'clsx';
import { createContext, FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LazyLoader } from 'core/components';
import { TableContractsTypes } from 'core/types/contract';
import { TableTicketsTypes } from 'core/types/ticket';
import { TicketsFilterForRender } from 'core/types/tickets';
import { TableWorkGroupsTypes } from 'core/types/workGroup';
import {
  ContractsTableContainer,
  fetchContractsByOrganizationsRequest,
  resetContractsState,
} from 'features/Contracts';
import {
  fetchCurrentOrganization,
  getCurrentOrganization,
  getLoadingOrganization,
  resetOrganizationState,
  setCurrentOrganizationId,
} from 'features/Organizations';
import { resetSystemsState } from 'features/Systems';
import {
  fetchTicketsCountByContractIdRequest,
  fetchTicketsRequest,
  getDefaultFilterValues,
  getFilterValues,
  resetTicketsState,
  setTicketsFilter,
  TicketsTableContainer,
} from 'features/Tickets';
import {
  fetchGroupsByOrganizationId,
  resetWorkGroupsState,
  WorkGroupsTableContainer,
} from 'features/WorkGroups';
import { checkObjectIdentity } from 'utils';

import { OrganizationForm } from './components';
import { useOrganizationId } from './hooks';
import styles from './Organization.module.scss';

interface OrganizationContext {
  contractIds: string[];
}

export const OrganizationContext = createContext<OrganizationContext>({
  contractIds: [],
});

interface OrganizationProps {
  className?: string;
}

export const Organization: FC<OrganizationProps> = ({ className }) => {
  const organizationId = useOrganizationId();

  const organization = useSelector(getCurrentOrganization);
  const filterValues = useSelector(getFilterValues);
  const loading = useSelector(getLoadingOrganization);

  const [contractIds, setContractIds] = useState<string[]>([]);

  const defaultFilterValues = getDefaultFilterValues({ contractIds });

  const isFilterActive = !checkObjectIdentity(
    filterValues as Record<string, TicketsFilterForRender>,
    defaultFilterValues as Record<string, TicketsFilterForRender>
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (organization) {
      const organizationContractIds = organization.contractList.map(
        (contract) => {
          return contract.id;
        }
      );
      setContractIds(organizationContractIds);
    }
  }, [organization]);

  useEffect(() => {
    dispatch(setTicketsFilter(defaultFilterValues));
    if (organizationId && contractIds.length) {
      dispatch(fetchTicketsCountByContractIdRequest(contractIds));
      dispatch(fetchTicketsRequest({}));
    }
  }, [organizationId, contractIds]);

  useEffect(() => {
    if (organizationId) {
      dispatch(setCurrentOrganizationId(organizationId));
      dispatch(fetchCurrentOrganization(organizationId));
    }
  }, [organizationId]);

  useEffect(() => {
    if (organizationId) {
      dispatch(fetchContractsByOrganizationsRequest());
      dispatch(fetchGroupsByOrganizationId());
    }
    return () => {
      if (organizationId) {
        dispatch(resetContractsState());
        dispatch(resetWorkGroupsState());
        dispatch(resetOrganizationState());
        dispatch(resetTicketsState());
      }
      dispatch(resetSystemsState());
    };
  }, []);

  if (loading && !organization) {
    return <LazyLoader className={styles.organization__loader} />;
  }

  return (
    <OrganizationContext.Provider value={{ contractIds }}>
      <div className={cn(styles.organization, className)}>
        <div className={styles.organization__leftContainer}>
          <OrganizationForm />
          <ContractsTableContainer
            tableType={TableContractsTypes.FROM_ORGANIZATIONS}
            className={styles.organization__table}
            classNameClose={styles.organization__table_close}
          />
          <WorkGroupsTableContainer
            tableType={TableWorkGroupsTypes.FROM_ORGANIZATIONS}
            className={styles.organization__table}
          />
        </div>
        <div className={styles.organization__rightContainer}>
          <TicketsTableContainer
            tableType={TableTicketsTypes.BY_CONTRACT_IDS}
            title="Тикеты"
            withOutTabs
            isFilterActive={isFilterActive}
            className={styles.organization__ticketsTable}
          />
        </div>
      </div>
    </OrganizationContext.Provider>
  );
};
