import { FC, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UsersGroupIcon } from 'assets/icons';
import { PopoverContainer } from 'components';
import { DeleteButton } from 'core/components';
import { HEADER_TYPE } from 'core/constants';
import { getIsMobile, getIsMobileSmall } from 'core/ducks/selectors';
import { MainLayout } from 'core/layouts';
import { ApproveOrCancel } from 'core/modals';
import { ActionList } from 'core/types/auth';
import { getIsAccessedAction } from 'features/Auth';
import {
  deleteWorkGroupRequest,
  getLoadingWorkGroup,
  getWorkGroup,
  useWorkGroupId,
} from 'features/WorkGroups';
import { RouterHref } from 'routes/routerHref';

import styles from './WorkGroupLayout.module.scss';

export const WorkGroupLayout: FC = ({ children }) => {
  const dispatch = useDispatch();

  const isMobile = useSelector(getIsMobile);
  const isMobileSmall = useSelector(getIsMobileSmall);
  const workGroup = useSelector(getWorkGroup);
  const isAccessToDeleteGroup = useSelector(
    getIsAccessedAction(ActionList.DeleteEntries)
  );
  const loading = useSelector(getLoadingWorkGroup);

  const isMobileAll = isMobile || isMobileSmall;

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const workGroupId = useWorkGroupId();

  const infoBlocksConfig = useMemo(
    () => [
      {
        title: 'Организация',
        info:
          workGroupId && workGroup?.organization?.title
            ? workGroup.organization.title
            : 'Нет данных',
        icon: <UsersGroupIcon className={styles.workGroupLayout__infoIcon} />,
      },
    ],
    [workGroupId, workGroup?.organization?.title]
  );

  const breadCrumbsConfig = useMemo(
    () => [
      { title: 'Группы', path: RouterHref.AdminGroups },
      { title: workGroup?.title || '' },
    ],
    [workGroup?.title]
  );

  const toggleDeleteModal = () => {
    setShowDeleteModal((prev) => !prev);
  };

  const handleDelete = () => {
    if (workGroupId) {
      dispatch(deleteWorkGroupRequest({ id: workGroupId, withRedirect: true }));
    }
  };

  const handleShowModalDelete = () => {
    if (workGroupId) {
      setShowDeleteModal(true);
    }
  };

  const dropContent =
    isAccessToDeleteGroup && workGroupId ? (
      <PopoverContainer>
        <DeleteButton
          onClick={handleShowModalDelete}
          disabled={!workGroup || !isAccessToDeleteGroup}
        />
      </PopoverContainer>
    ) : undefined;

  return (
    <>
      <MainLayout
        headerType={HEADER_TYPE}
        title={workGroupId ? workGroup?.title : 'Создать группу'}
        titleIcon={
          <UsersGroupIcon className={styles.workGroupLayout__titleInfoIcon} />
        }
        withGoBack={isMobileAll}
        infoBlocksConfig={infoBlocksConfig}
        breadCrumbsConfig={workGroupId ? breadCrumbsConfig : undefined}
        classNameTitleInfoBlock={styles.workGroupLayout__titleInfoBlock}
        dropContent={dropContent}
        loading={loading && !workGroup}
      >
        {children}
      </MainLayout>
      <ApproveOrCancel
        onApprove={handleDelete}
        isModal={showDeleteModal}
        toggleModal={toggleDeleteModal}
        approveTitle="Удалить"
        approveBtnProps={{
          appearance: 'outline',
          className: styles.workGroupLayout__iconDelete,
        }}
        cancelTitle="Отмена"
        text={`Вы уверены, что хотите удалить группу ${workGroup?.title}?`}
      />
    </>
  );
};
