import { Improvement, TicketEvaluation } from 'features/Ticket/types';

export enum AssessmentInteraction {
  RESET_ASSESSMENT_INTERACTION_STATE = 'TICKET/ASSESSMENT_INTERACTION/RESET_ASSESSMENT_INTERACTION_STATE',
  FETCH_IMPROVEMENTS_REQUEST = 'TICKET/ASSESSMENT_INTERACTION/FETCH_IMPROVEMENTS_REQUEST',
  FETCH_IMPROVEMENTS_SUCCESS = 'TICKET/ASSESSMENT_INTERACTION/FETCH_IMPROVEMENTS_SUCCESS',
  EVALUATE_SPECIALIST = 'TICKET/ASSESSMENT_INTERACTION/EVALUATE_SPECIALIST',
  FETCH_TICKET_EVALUATION_REQUEST = 'TICKET/ASSESSMENT_INTERACTION/FETCH_TICKET_EVALUATION_REQUEST',
  FETCH_TICKET_EVALUATION_SUCCESS = 'TICKET/ASSESSMENT_INTERACTION/FETCH_TICKET_EVALUATION_SUCCESS',
}

export interface ResetAssessmentInteractionStateAction {
  type: AssessmentInteraction.RESET_ASSESSMENT_INTERACTION_STATE;
}

export interface FetchImprovementsRequestAction {
  type: AssessmentInteraction.FETCH_IMPROVEMENTS_REQUEST;
  payload: number;
}

export interface FetchImprovementsSuccessAction {
  type: AssessmentInteraction.FETCH_IMPROVEMENTS_SUCCESS;
  payload: Improvement[];
}

export interface EvaluateSpecialistPayload {
  rating: number;
  ticketId: string;
  improvementsIds: string[];
}

export interface EvaluateSpecialistAction {
  type: AssessmentInteraction.EVALUATE_SPECIALIST;
  payload: EvaluateSpecialistPayload;
}

export interface FetchTicketEvaluationRequestAction {
  type: AssessmentInteraction.FETCH_TICKET_EVALUATION_REQUEST;
  payload: string;
}

export interface FetchTicketEvaluationSuccessAction {
  type: AssessmentInteraction.FETCH_TICKET_EVALUATION_SUCCESS;
  payload: TicketEvaluation;
}

export type AssessmentInteractionActionsTypes =
  | ResetAssessmentInteractionStateAction
  | FetchImprovementsSuccessAction
  | FetchTicketEvaluationSuccessAction;
