import { Priority } from 'core/types';

import styles from './CreateTicket.module.scss';

export const DEFAULT_FORM_VALUES = {
  systemId: null,
  typeId: null,
  environmentId: null,
  mentionedTickets: [],
  customFields: [],
};

export const DEFAULT_RADIO_TABS = [
  {
    title: 'Без приоритета',
    value: Priority.NONE,
    classNameTab: styles.createTicket__radioTab_none,
  },
  {
    title: 'Низкий',
    value: Priority.LOW,
    classNameTab: styles.createTicket__radioTab_low,
  },
  {
    title: 'Средний',
    value: Priority.MIDDLE,
    classNameTab: styles.createTicket__radioTab_medium,
  },
  {
    title: 'Высокий',
    value: Priority.HIGH,
    classNameTab: styles.createTicket__radioTab_high,
  },
  {
    title: 'Критический',
    value: Priority.VERY_HIGH,
    classNameTab: styles.createTicket__radioTab_critical,
  },
];
