import { Responsibility } from 'core/types/responsibility';
import { ResponseWithMeta } from 'store/types';

import {
  CreateResponsibilityType,
  ResponsibilityFilter,
  UpdateResponsibilityData,
} from '../types';

export enum Responsibilities {
  FETCH_RESPONSIBILITIES_SUCCESS = 'RESPONSIBILITIES/FETCH_RESPONSIBILITIES_SUCCESS',
  FETCH_RESPONSIBILITIES_REQUEST = 'RESPONSIBILITIES/FETCH_RESPONSIBILITIES_REQUEST',
  RESPONSIBILITIES_LOADING_SHOW = 'RESPONSIBILITIES/RESPONSIBILITIES_LOADING_SHOW',
  RESPONSIBILITIES_LOADING_HIDE = 'RESPONSIBILITIES/RESPONSIBILITIES_LOADING_HIDE',
  RESPONSIBILITIES_ADD_LOADING_SHOW = 'RESPONSIBILITIES/RESPONSIBILITIES_ADD_LOADING_SHOW',
  RESPONSIBILITIES_ADD_LOADING_HIDE = 'RESPONSIBILITIES/RESPONSIBILITIES_ADD_LOADING_HIDE',
  SET_CURRENT_RESPONSIBILITIES_PAGE = 'RESPONSIBILITIES/SET_CURRENT_RESPONSIBILITIES_PAGE',
  SET_SORT_RESPONSIBILITIES = 'RESPONSIBILITIES/SET_SORT_RESPONSIBILITIES',
  SET_SIZE_PAGE_RESPONSIBILITIES = 'RESPONSIBILITIES/SET_SIZE_PAGE_RESPONSIBILITIES',
  SET_RESPONSIBILITIES_ADD_PAGE = 'RESPONSIBILITIES/SET_RESPONSIBILITIES_ADD_PAGE',
  SET_SIZE_PAGE_RESPONSIBILITIES_ADD = 'RESPONSIBILITIES/SET_SIZE_PAGE_RESPONSIBILITIES_ADD',
  CREATE_RESPONSIBILITY = 'RESPONSIBILITIES/CREATE_RESPONSIBILITY',
  UPDATE_RESPONSIBILITY = 'RESPONSIBILITIES/UPDATE_RESPONSIBILITY',
  SET_CURRENT_RESPONSIBILITY_ID = 'RESPONSIBILITIES/SET_CURRENT_RESPONSIBILITY_ID',
  RESET_RESPONSIBILITIES_STATE = 'RESPONSIBILITIES/RESET_RESPONSIBILITIES_STATE',
  FETCH_RESPONSIBILITY_BY_POSITION_ID_REQUEST = 'RESPONSIBILITIES/FETCH_RESPONSIBILITY_BY_POSITION_ID_REQUEST',
  FETCH_RESPONSIBILITY_BY_GROUP_ID_REQUEST = 'RESPONSIBILITIES/FETCH_RESPONSIBILITY_BY_GROUP_ID_REQUEST',
  FETCH_RESPONSIBILITY_BY_ROLE_ID_REQUEST = 'RESPONSIBILITIES/FETCH_RESPONSIBILITY_BY_ROLE_ID_REQUEST',
  FETCH_RESPONSIBILITIES_ADD_REQUEST = 'RESPONSIBILITIES/FETCH_RESPONSIBILITIES_ADD_REQUEST',
  FETCH_RESPONSIBILITIES_ADD_SUCCESS = 'RESPONSIBILITIES/FETCH_RESPONSIBILITIES_ADD_SUCCESS',
  SET_RESPONSIBILITIES_ADD_FILTER = 'RESPONSIBILITIES/SET_RESPONSIBILITIES_ADD_FILTER',
  SET_RESPONSIBILITIES_FILTER = 'RESPONSIBILITIES/SET_RESPONSIBILITIES_FILTER',
  RESET_RESPONSIBILITIES_ADD_STATE = 'RESPONSIBILITIES/RESET_RESPONSIBILITIES_ADD_STATE',
  FETCH_RESPONSIBILITY_BY_ID_REQUEST = 'RESPONSIBILITIES/FETCH_RESPONSIBILITY_BY_ID_REQUEST',
  FETCH_RESPONSIBILITY_BY_ID_SUCCESS = 'RESPONSIBILITIES/FETCH_RESPONSIBILITY_BY_ID_SUCCESS',
  DELETE_RESPONSIBILITY_BY_ID_REQUEST = 'RESPONSIBILITIES/DELETE_RESPONSIBILITY_BY_ID_REQUEST',
  RESET_CURRENT_RESPONSIBILITY = 'RESPONSIBILITIES/RESET_CURRENT_RESPONSIBILITY',
}

export interface ShowResponsibilitiesLoadingAction {
  type: Responsibilities.RESPONSIBILITIES_LOADING_SHOW;
}

export interface HideResponsibilitiesLoadingAction {
  type: Responsibilities.RESPONSIBILITIES_LOADING_HIDE;
}

export interface ShowResponsibilitiesAddLoadingAction {
  type: Responsibilities.RESPONSIBILITIES_ADD_LOADING_SHOW;
}

export interface HideResponsibilitiesAddLoadingAction {
  type: Responsibilities.RESPONSIBILITIES_ADD_LOADING_HIDE;
}

export interface FetchResponsibilitiesRequestAction {
  type: Responsibilities.FETCH_RESPONSIBILITIES_REQUEST;
}

export interface FetchResponsibilitiesSuccessAction {
  type: Responsibilities.FETCH_RESPONSIBILITIES_SUCCESS;
  payload: ResponseWithMeta<Responsibility[]>;
}

export interface SetCurrentResponsibilitiesPageAction {
  type: Responsibilities.SET_CURRENT_RESPONSIBILITIES_PAGE;
  payload: number;
}

export interface SetSizePageAction {
  type: Responsibilities.SET_SIZE_PAGE_RESPONSIBILITIES;
  payload: number;
}

export interface SetResponsibilitiesAddPageAction {
  type: Responsibilities.SET_RESPONSIBILITIES_ADD_PAGE;
  payload: number;
}

export interface SetSizePageResponsibilitiesAddAction {
  type: Responsibilities.SET_SIZE_PAGE_RESPONSIBILITIES_ADD;
  payload: number;
}

export interface SetSortResponsibilitiesAction {
  type: Responsibilities.SET_SORT_RESPONSIBILITIES;
  payload: string;
}

export interface CreateResponsibilityAction {
  type: Responsibilities.CREATE_RESPONSIBILITY;
  payload: CreateResponsibilityType;
}

export interface SetCurrentResponsibilityIdAction {
  type: Responsibilities.SET_CURRENT_RESPONSIBILITY_ID;
  payload: string;
}

export interface FetchRespByPosIdRequestAction {
  type: Responsibilities.FETCH_RESPONSIBILITY_BY_POSITION_ID_REQUEST;
}

export interface FetchRespByGroupIdRequestAction {
  type: Responsibilities.FETCH_RESPONSIBILITY_BY_GROUP_ID_REQUEST;
}

export interface FetchRespByRoleIdRequestAction {
  type: Responsibilities.FETCH_RESPONSIBILITY_BY_ROLE_ID_REQUEST;
}

export interface SetResponsibilitiesAddFilterAction {
  type: Responsibilities.SET_RESPONSIBILITIES_ADD_FILTER;
  payload: ResponsibilityFilter;
}

export interface SetResponsibilitiesFilterAction {
  type: Responsibilities.SET_RESPONSIBILITIES_FILTER;
  payload: ResponsibilityFilter;
}

export interface FetchResponsibilitiesAddSuccessAction {
  type: Responsibilities.FETCH_RESPONSIBILITIES_ADD_SUCCESS;
  payload: ResponseWithMeta<Responsibility[]>;
}

export interface UpdateResponsibilityAction {
  type: Responsibilities.UPDATE_RESPONSIBILITY;
  payload: UpdateResponsibilityData;
}
export interface ResetResponsibilitiesAddStateAction {
  type: Responsibilities.RESET_RESPONSIBILITIES_ADD_STATE;
}

export interface FetchResponsibilityByIdRequestAction {
  type: Responsibilities.FETCH_RESPONSIBILITY_BY_ID_REQUEST;
  payload: string;
}

export interface FetchResponsibilityByIdSuccessAction {
  type: Responsibilities.FETCH_RESPONSIBILITY_BY_ID_SUCCESS;
  payload: Responsibility;
}

export interface DeleteResponsibilityRequest {
  type: Responsibilities.DELETE_RESPONSIBILITY_BY_ID_REQUEST;
  payload: string;
}

export interface ResetCurrentResponsibilityAction {
  type: Responsibilities.RESET_CURRENT_RESPONSIBILITY;
}

export type ResponsibilitiesActionsTypes =
  | FetchResponsibilitiesSuccessAction
  | SetCurrentResponsibilitiesPageAction
  | SetSizePageAction
  | SetSortResponsibilitiesAction
  | SetCurrentResponsibilityIdAction
  | CreateResponsibilityAction
  | SetResponsibilitiesAddFilterAction
  | FetchResponsibilitiesAddSuccessAction
  | SetResponsibilitiesFilterAction
  | ResetResponsibilitiesAddStateAction
  | FetchResponsibilityByIdSuccessAction
  | SetResponsibilitiesAddPageAction
  | SetSizePageResponsibilitiesAddAction
  | ResetCurrentResponsibilityAction;
