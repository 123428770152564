import { FC } from 'react';
import { useSelector } from 'react-redux';

import { Table } from 'components';
import { Contract } from 'core/types';
import { TableContractsTypes } from 'core/types/contract';
import { ContractsFilter } from 'features/Contracts/components';
import { getDataTable } from 'features/Contracts/components/ContractsFromSystemsTable/utils';
import { TABLE_FROM_SYSTEMS_FIELDS } from 'features/Contracts/constants';
import { getLoading } from 'features/Contracts/ducks/selectors';

interface ContractsFromSystemsTableProps {
  contracts?: Contract[];
  sort?: string;
  handleSort?: (value: string) => void;
  handleRowClick?: (value: string) => void;
  showFilterRow?: boolean;
  activeRowId?: string;
  isFilterActive?: boolean;
}

export const ContractsFromSystemsTable: FC<ContractsFromSystemsTableProps> = ({
  contracts,
  sort,
  handleSort,
  handleRowClick,
  showFilterRow,
  activeRowId,
  isFilterActive,
}) => {
  const data = getDataTable(contracts);

  const loading = useSelector(getLoading);

  return (
    <Table
      data={data}
      columns={TABLE_FROM_SYSTEMS_FIELDS}
      sort={sort}
      onRowClick={handleRowClick}
      activeRowId={activeRowId}
      onSort={handleSort}
      dataLoading={loading}
      isFilterActive={isFilterActive}
      valueFieldName="id"
      filterComponent={
        showFilterRow && (
          <ContractsFilter tableType={TableContractsTypes.FROM_SYSTEMS} />
        )
      }
      disabledSort={loading}
    />
  );
};
