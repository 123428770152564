import { call, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';

import { setAlert } from 'core/ducks/actions';
import { createErrorAlert, createSuccessAlert } from 'core/layouts';
import { CustomField } from 'core/types';
import { System } from 'core/types/system';
import { ResponseWithMeta } from 'store/types';
import { createError, downloadFileWithToken } from 'utils';

import {
  DownloadRequestData,
  ExportFields,
  TicketsRequestData,
} from '../types';

import {
  customFieldsExportLoadingHide,
  customFieldsExportLoadingShow,
  fetchCustomFieldsExportSuccess,
  fetchSystemsExportSuccess,
  getExportFields,
  getIsDownloadingExcel,
  setTicketsCount,
  systemsExportLoadingHide,
  systemsExportLoadingShow,
} from './actions';
import { requests } from './api/requests';
import { getTicketsRequestData } from './selectors';
import {
  FetchCustomFieldsExportRequestAction,
  FetchSystemsExportRequestAction,
  GetTicketsCountRequestAction,
  TicketsExport,
} from './types';

function* getExportFieldsRequest() {
  try {
    const data: ExportFields = yield call(requests.getExportFields);
    yield put(getExportFields(data));
  } catch (error) {
    createError(error);
  }
}

function* getTicketsCountRequest({ payload }: GetTicketsCountRequestAction) {
  try {
    const data: number = yield call(requests.getTicketsCount, payload);
    yield put(setTicketsCount(data));
  } catch (error) {
    createError(error);
  }
}

function* downloadTicketsRequest() {
  try {
    yield put(getIsDownloadingExcel(true));
    const ticketsRequestData: TicketsRequestData = yield select(
      getTicketsRequestData
    );
    const data: DownloadRequestData = yield call(
      requests.getDownloadExcelUrl,
      ticketsRequestData
    );
    yield call(downloadFileWithToken, data.fileName, data.path);
    yield put(
      setAlert(
        createSuccessAlert(`Запущен процесс выгрузки тикетов в файл Excel`)
      )
    );
    yield put(getIsDownloadingExcel(false));
  } catch (error) {
    yield put(
      setAlert(
        createErrorAlert(
          `Произошла ошибка при попытке выгрузки тикетов в файл Excel`
        )
      )
    );
    yield put(getIsDownloadingExcel(false));
    createError(error);
  }
}

function* systemsFetch({ payload }: FetchSystemsExportRequestAction) {
  try {
    yield put(systemsExportLoadingShow());
    const systems: ResponseWithMeta<System[]> = yield call(
      requests.fetchSystems,
      payload
    );
    yield put(fetchSystemsExportSuccess(systems));
    yield put(systemsExportLoadingHide());
  } catch (e) {
    createError(e);
    yield put(systemsExportLoadingHide());
  }
}

function* customFieldsFetch({ payload }: FetchCustomFieldsExportRequestAction) {
  try {
    yield put(customFieldsExportLoadingShow());
    const customFields: ResponseWithMeta<CustomField[]> = yield call(
      requests.fetchCustomFields,
      payload
    );
    yield put(fetchCustomFieldsExportSuccess(customFields));
    yield put(customFieldsExportLoadingHide());
  } catch (e) {
    createError(e);
    yield put(customFieldsExportLoadingHide());
  }
}

export function* ticketsExportSaga(): Generator<StrictEffect> {
  yield takeEvery(
    TicketsExport.GET_EXPORT_FIELDS_REQUEST,
    getExportFieldsRequest
  );
  yield takeEvery(
    TicketsExport.GET_TICKETS_COUNT_REQUEST,
    getTicketsCountRequest
  );
  yield takeEvery(TicketsExport.DOWNLOAD_EXCEL_REQUEST, downloadTicketsRequest);
  yield takeEvery(TicketsExport.FETCH_SYSTEMS_EXPORT_REQUEST, systemsFetch);
  yield takeEvery(
    TicketsExport.FETCH_CUSTOM_FIELDS_EXPORT_REQUEST,
    customFieldsFetch
  );
}
