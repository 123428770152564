import { FC } from 'react';

import { Card } from 'components';
import { DataEmpty } from 'core/components';
import { SLATableDataType } from 'core/types/sla';
import { getSlaTicketTypesFromSettings } from 'core/utils';
import { SlaTable, SlaTableHeader } from 'features/Contract';

import styles from './SLATableShort.module.scss';

interface SLATableShortProps {
  slaSettings?: SLATableDataType;
}

export const SLATableShort: FC<SLATableShortProps> = ({ slaSettings }) => {
  const slaTicketTypes = getSlaTicketTypesFromSettings(slaSettings);

  return (
    <Card className={styles.SLATableShort}>
      <SlaTableHeader className={styles.SLATableShort__header} />
      {slaTicketTypes.length ? (
        <SlaTable
          isSwitchHeader
          slaSettings={slaSettings}
          slaTicketTypes={slaTicketTypes}
          className={styles.SLATableShort__table}
        />
      ) : (
        <DataEmpty className={styles.SLATableShort__empty} />
      )}
    </Card>
  );
};
