import cn from 'clsx';
import { FC } from 'react';

import { TicketTab as TicketTabType } from 'features/Ticket';

import { TicketTab } from './components';
import styles from './TicketTabsBlock.module.scss';

interface TicketTabsBlockProps {
  tabs?: TicketTabType[];
  rightTab?: TicketTabType;
  className?: string;
}

export const TicketTabsBlock: FC<TicketTabsBlockProps> = ({
  tabs,
  rightTab,
  className,
}) => {
  const rightTabComponent = rightTab && <TicketTab tab={rightTab} />;
  return (
    <>
      <div className={cn(styles.ticketTabsBlock__tabsWrapper, className)}>
        {tabs?.map((tab) => {
          return <TicketTab tab={tab} key={String(tab.title)} />;
        })}
      </div>
      {rightTabComponent}
    </>
  );
};
