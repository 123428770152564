import _, { debounce } from 'lodash';
import { FC, memo, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { Input, Select, Size, TableBodyCell, TableFilter } from 'components';
import { DEFAULT_DEBOUNCE_DELAY } from 'constants/meta';
import { RoleType } from 'core/types';
import { TableRolesTypes } from 'core/types/roles';
import {
  fetchOrganizationsAddRequest,
  OrganizationSelect,
  setCurrentOrganizationsAddPage,
  setOrganizationsAddFilterAction,
} from 'features/Organizations';

import {
  INITIAL_ROLES_FILTER,
  MANAGER_OPTIONS,
  ROLES_OPTIONS,
} from '../../constants';
import { RolesFilter as RolesFilterData } from '../../types';

import styles from './RolesFilter.module.scss';

interface RolesFilterProps {
  tableType: TableRolesTypes;
  onFilter: (data: RolesFilterData) => void;
}

export const RolesFilter: FC<RolesFilterProps> = memo(
  ({ tableType, onFilter }) => {
    const { register, handleSubmit, control, reset, watch } =
      useForm<RolesFilterData>({
        mode: 'onChange',
        defaultValues: INITIAL_ROLES_FILTER,
      });
    const dispatch = useDispatch();

    const filterValues = watch();

    const resetFilter = () => {
      onFilter({});
      if (tableType === TableRolesTypes.FULL) {
        dispatch(setCurrentOrganizationsAddPage(0));
        dispatch(setOrganizationsAddFilterAction({}));
        dispatch(fetchOrganizationsAddRequest({ updateType: 'update' }));
      }
      reset();
    };

    const formSubmitHandler = handleSubmit((data) => {
      const { name, description, type, manager, organizationId } = data;
      const preparedData = {
        name: name || undefined,
        description: description || undefined,
        type: type || undefined,
        manager: manager || undefined,
        organizationId: organizationId || undefined,
      };
      onFilter(preparedData);
    });

    const formSubmitHandlerDebounced = useMemo(
      () => debounce(formSubmitHandler, DEFAULT_DEBOUNCE_DELAY),
      [onFilter]
    );

    const nameToInput = register('name', {
      onChange: formSubmitHandlerDebounced,
    });
    const descriptionToInput = register('description', {
      onChange: formSubmitHandlerDebounced,
    });

    const tableBodyWrapper = (elements: JSX.Element[]) =>
      elements.map((item) => (
        <TableBodyCell className={styles.rolesFilter__cell} key={item.key}>
          {item}
        </TableBodyCell>
      ));

    const filterElements = {
      [TableRolesTypes.FULL]: () => [
        <Input size={Size.xs} type="name" key="name" {...nameToInput} />,
        <Controller
          control={control}
          name="type"
          key="type"
          render={({ field }) => {
            return (
              <Select<RoleType>
                size={Size.xs}
                mobileModalTitle="тип"
                options={ROLES_OPTIONS}
                value={field.value}
                onChange={(value) => {
                  field.onChange(value);
                  formSubmitHandler();
                }}
                isTooltip={false}
              />
            );
          }}
        />,
        <Input
          size={Size.xs}
          type="description"
          key="description"
          {...descriptionToInput}
        />,
        <Controller
          control={control}
          name="manager"
          key="manager"
          render={({ field }) => {
            return (
              <Select<string>
                size={Size.xs}
                mobileModalTitle="руководителя"
                options={MANAGER_OPTIONS}
                value={field.value}
                onChange={(value) => {
                  field.onChange(value);
                  formSubmitHandler();
                }}
                isTooltip={false}
              />
            );
          }}
        />,
        <Controller
          control={control}
          name="organizationId"
          key="organizationId"
          render={({ field }) => {
            return (
              <OrganizationSelect
                size={Size.xs}
                onChange={(value) => {
                  field.onChange(value);
                  formSubmitHandler();
                }}
                value={field.value}
              />
            );
          }}
        />,
      ],
      [TableRolesTypes.FROM_USERS]: () => [
        <Input size={Size.xs} type="name" key="name" {...nameToInput} />,
        <Input
          size={Size.xs}
          type="description"
          key="description"
          {...descriptionToInput}
        />,
      ],
      [TableRolesTypes.FROM_GROUPS]: () => [
        <Input size={Size.xs} type="name" key="nameGroups" {...nameToInput} />,
        <Input
          size={Size.xs}
          type="description"
          key="descriptionGroups"
          {...descriptionToInput}
        />,
      ],
      [TableRolesTypes.ADD_MODAL]: () => [
        <div />,
        <Input size={Size.xs} type="name" key="name" {...nameToInput} />,
        <Input
          size={Size.xs}
          type="description"
          key="description"
          {...descriptionToInput}
        />,
      ],
    };

    const filter = (
      <>
        {tableBodyWrapper(
          filterElements[tableType as keyof typeof filterElements]()
        )}
      </>
    );

    const disableReset = _.isEqual(
      _.omitBy(filterValues, (value, key) =>
        _.isNil(value) || (_.isEmpty(value) && typeof value !== 'boolean')
          ? key
          : false
      ),
      {}
    );

    return (
      <TableFilter
        filterComponent={filter}
        onReset={resetFilter}
        disableReset={disableReset}
      />
    );
  }
);
