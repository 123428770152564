import axios from 'axios';
import { nanoid } from 'nanoid';
import { channel } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';

import { setAlert } from 'core/ducks/actions';
import { createErrorAlert } from 'core/layouts';
import { Storage } from 'core/types/storage';
import { createError, downloadFileWithToken } from 'utils';

import {
  StorageFile,
  StorageFileCard,
  StorageFileCards,
  StorageFileRequest,
  UploadingFile,
  UploadingStatus,
} from '../types';

import { requests } from './api/requests';

import {
  addStorageFileCardRequest,
  changeUploadingFile,
  deleteStorageFileCardRequest,
  downloadStorageFileRequest,
  editStorageFileCardRequest,
  fetchCurrentStorageFileCardRequest,
  fetchCurrentStorageFileCardSuccess,
  fetchStorageFileCardsRequest,
  fetchStorageFileCardsSuccess,
  fetchStorageRequest,
  fetchStorageSuccess,
  setDownloadedStorageFiles,
  setLoading,
  setUploadingFile,
  uploadStorageFileRequest,
} from './index';

function* fetchStorageFileCards(
  action: ReturnType<typeof fetchStorageFileCardsRequest>
) {
  try {
    yield put(setLoading(true));
    const fileCards: StorageFileCards = yield call(
      requests.fetchStorageFileCards,
      action.payload
    );
    yield put(fetchStorageFileCardsSuccess(fileCards));
    yield put(setLoading(false));
  } catch (error) {
    createError(error);
  }
}

function* fetchStorage(action: ReturnType<typeof fetchStorageRequest>) {
  try {
    const storage: Storage = yield call(requests.fetchStorage, action.payload);
    yield put(fetchStorageSuccess(storage));
  } catch (error) {
    createError(error);
  }
}

function* downloadStorageFile(
  action: ReturnType<typeof downloadStorageFileRequest>
) {
  try {
    const file: StorageFileRequest = yield call(
      requests.downloadStorageFile,
      action.payload
    );
    yield call(downloadFileWithToken, file.fileName, file.url);
  } catch (error) {
    createError(error);
  }
}

const uploadStorageFileChannel = channel();

function* watchUploadStorageFileChannel(file: UploadingFile) {
  yield put(changeUploadingFile(file));
}

function* uploadStorageFile(
  action: ReturnType<typeof uploadStorageFileRequest>
) {
  try {
    const source = axios.CancelToken.source();

    const file = action.payload;
    const formData = new FormData();
    formData.append('file', new Blob([file]), encodeURI(file.name));

    const uploadingFile: UploadingFile = {
      title: file.name,
      progress: 0,
      id: nanoid(),
      status: UploadingStatus.QUEUE,
      type: file.type,
      cancelUpload: () => source.cancel(),
    };

    yield put(setUploadingFile(uploadingFile));

    const currentFile: StorageFile = yield call(requests.uploadStorageFile, {
      data: formData,
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader('content-length') ||
            progressEvent.target.getResponseHeader(
              'x-decompressed-content-length'
            );

        const progress = Math.round(
          (progressEvent.loaded * 100) / Number(totalLength)
        );

        const currentUploadFile = {
          ...uploadingFile,
          progress,
          status:
            progress === 100 ? UploadingStatus.LOADED : UploadingStatus.LOADING,
        };

        uploadStorageFileChannel.put(currentUploadFile);
      },
      cancelToken: source.token,
    });

    yield put(setDownloadedStorageFiles(currentFile));
  } catch (error) {
    yield put(
      setAlert(createErrorAlert('Произошла ошибка при загрузке файла'))
    );
    createError(error);
  }
}

function* addStorageFileCard(
  action: ReturnType<typeof addStorageFileCardRequest>
) {
  try {
    yield call(requests.addStorageFileCard, action.payload);
  } catch (error) {
    createError(error);
  }
}

function* deleteStorageFileCard(
  action: ReturnType<typeof deleteStorageFileCardRequest>
) {
  try {
    yield call(requests.deleteStorageFileCard, action.payload);
  } catch (error) {
    createError(error);
  }
}

function* editStorageFileCard(
  action: ReturnType<typeof editStorageFileCardRequest>
) {
  try {
    yield call(requests.editStorageFileCard, action.payload);
  } catch (error) {
    createError(error);
  }
}

function* fetchCurrentFileCard(
  action: ReturnType<typeof fetchCurrentStorageFileCardRequest>
) {
  try {
    const fileCard: StorageFileCard = yield call(
      requests.fetchCurrentStorageFileCard,
      action.payload
    );
    yield put(fetchCurrentStorageFileCardSuccess(fileCard));
  } catch (error) {
    createError(error);
  }
}

export function* storageSaga() {
  yield takeEvery(fetchStorageFileCardsRequest.type, fetchStorageFileCards);
  yield takeEvery(fetchStorageRequest.type, fetchStorage);
  yield takeEvery(
    fetchCurrentStorageFileCardRequest.type,
    fetchCurrentFileCard
  );
  yield takeEvery(downloadStorageFileRequest.type, downloadStorageFile);
  yield takeEvery(addStorageFileCardRequest.type, addStorageFileCard);
  yield takeEvery(editStorageFileCardRequest.type, editStorageFileCard);
  yield takeEvery(deleteStorageFileCardRequest.type, deleteStorageFileCard);
  yield takeEvery(uploadStorageFileRequest.type, uploadStorageFile);
  yield takeEvery(uploadStorageFileChannel, watchUploadStorageFileChannel);
}
