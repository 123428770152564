import { ApiClient, getEnv } from 'utils';

import {
  CurrentStorageFileCardRequest,
  DownloadStorageFileRequest,
  StorageFileCardRequest,
  StorageFileCardsRequest,
  UploadStorageFile,
} from '../../types';

import { endpoints } from './endpoints';

export const requests = {
  fetchStorageFileCards: ({ id, pageNum, pageSize }: StorageFileCardsRequest) =>
    ApiClient.post({
      baseURL: getEnv('REACT_APP_ATTACHMENT_URL'),
      url: endpoints.getStorageFileCards(id),
      params: { pageNum, pageSize, sort: 'MAIN_FILE_UPLOAD_AT_DESC' },
      data: {},
    }),
  fetchStorage: (id?: string) =>
    ApiClient.get<Storage>({
      baseURL: getEnv('REACT_APP_ATTACHMENT_URL'),
      url: endpoints.getStorage(id),
    }),
  downloadStorageFile: (data: DownloadStorageFileRequest) =>
    ApiClient.get({
      baseURL: getEnv('REACT_APP_ATTACHMENT_URL'),
      url: endpoints.downloadStorageFile(data),
    }),
  addStorageFileCard: (data: StorageFileCardRequest) =>
    ApiClient.post({
      baseURL: getEnv('REACT_APP_ATTACHMENT_URL'),
      url: endpoints.addStorageFileCard(),
      data,
    }),
  uploadStorageFile: ({
    data,
    onUploadProgress,
    cancelToken,
  }: UploadStorageFile) =>
    ApiClient.post(
      {
        baseURL: getEnv('REACT_APP_ATTACHMENT_URL'),
        url: endpoints.uploadStorageFile(),
        data,
      },
      { onUploadProgress },
      { cancelToken }
    ),
  deleteStorageFileCard: ({
    storageId,
    fileCardId,
  }: CurrentStorageFileCardRequest) =>
    ApiClient.delete({
      baseURL: getEnv('REACT_APP_ATTACHMENT_URL'),
      url: endpoints.deleteStorageFileCard({ storageId, fileCardId }),
    }),
  editStorageFileCard: ({
    storageId,
    fileCardId,
    fileCard,
  }: CurrentStorageFileCardRequest) =>
    ApiClient.patch({
      baseURL: getEnv('REACT_APP_ATTACHMENT_URL'),
      url: endpoints.editStorageFileCard({ storageId, fileCardId }),
      data: fileCard,
    }),
  fetchCurrentStorageFileCard: ({
    storageId,
    fileCardId,
  }: CurrentStorageFileCardRequest) =>
    ApiClient.get({
      baseURL: getEnv('REACT_APP_ATTACHMENT_URL'),
      url: endpoints.getCurrentStorageFileCard({ storageId, fileCardId }),
    }),
};
