import { RootState } from 'store/rootReducer';

export const getSlaStatistic = (state: RootState) =>
  state.desktopEmployees.slaStatistic;
export const getEmployeesStatistic = (state: RootState) =>
  state.desktopEmployees.employeesStatistic;
export const getTotalEmployees = (state: RootState) =>
  state.desktopEmployees.totalEmployees;
export const getTicketsStatistic = (state: RootState) =>
  state.desktopEmployees.ticketsStatistic;
export const getEmployeesPageNumber = (state: RootState) =>
  state.desktopEmployees.pageNum;
export const getEmployeesPageSize = (state: RootState) =>
  state.desktopEmployees.pageSize;
export const getIsEmployeesLoading = (state: RootState) =>
  state.desktopEmployees.isLoading;
