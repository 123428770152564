import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ClockFat } from 'assets/icons';
import {
  Avatar,
  Size,
  Table,
  Typography,
  TypographyVariants,
} from 'components';
import { StatusBlock } from 'core/components';
import { Ticket } from 'core/types/ticket';
import { RouterHref } from 'routes/routerHref';
import { getDurationTime, getFormatDisplayedTime } from 'utils';

import { TICKETS_FROM_DESKTOP_HISTORY_TABLE_FIELDS } from '../../constants';

import styles from './TicketsFromDesktopHistoryTable.module.scss';

interface DataTableProps {
  tickets?: Ticket[];
  linkTicketTitle?: string;
}

const getDataTable = ({ tickets, linkTicketTitle }: DataTableProps) =>
  tickets?.map((ticket) => {
    const { number, id, specialistId, client, theme, customStatus, slaInfo } =
      ticket;

    const { hours, minutes, seconds } = getDurationTime(
      slaInfo?.expiredSeconds || 0
    );

    return {
      id,
      number: {
        title: `${linkTicketTitle} #${number}`,
        to: `${RouterHref.Tickets}/${id}`,
        withNotification: false,
      },
      sla: (
        <div className={styles.ticketsFromDesktopHistoryTable__slaCell}>
          <ClockFat className={styles.ticketsFromDesktopHistoryTable__clock} />
          <Typography
            variant={TypographyVariants.b3}
          >{`- ${getFormatDisplayedTime(hours)} : ${getFormatDisplayedTime(
            minutes
          )} : ${getFormatDisplayedTime(seconds)}`}</Typography>
        </div>
      ),
      theme,
      specialistId: (
        <div className={styles.ticketsFromDesktopHistoryTable__cell}>
          {specialistId?.value && (
            <Avatar
              size={Size.xxs}
              initialsUser={specialistId?.value}
              className={styles.ticketsFromDesktopHistoryTable__cellItem}
            />
          )}
          {specialistId?.value || '-'}
        </div>
      ),
      client: (
        <div className={styles.ticketsFromDesktopHistoryTable__cell}>
          {client?.value && (
            <Avatar
              size={Size.xxs}
              initialsUser={client?.value}
              className={styles.ticketsFromDesktopHistoryTable__cellItem}
            />
          )}
          {client?.value || '-'}
        </div>
      ),
      status: (
        <StatusBlock
          status={customStatus?.defaultStatus}
          label={customStatus?.name}
          color={customStatus?.color}
        />
      ),
    };
  }) || [];

interface TicketsFromDesktopHistoryTableProps {
  tickets?: Ticket[];
  sort?: string;
  handleSort?: (value: string) => void;
  loading?: boolean;
}

export const TicketsFromDesktopHistoryTable: FC<
  TicketsFromDesktopHistoryTableProps
> = ({ tickets, sort, handleSort, loading }) => {
  const { t } = useTranslation('notifications');

  const data = getDataTable({
    tickets,
    linkTicketTitle: t('notification.ticketLinkTitle'),
  });

  return (
    <Table
      columns={TICKETS_FROM_DESKTOP_HISTORY_TABLE_FIELDS}
      data={data}
      sort={sort}
      onSort={handleSort}
      dataLoading={loading}
      disabledSort={loading}
    />
  );
};
