import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { Table } from 'components';
import { Environment, EnvironmentsFilter } from 'core/types';
import {
  fetchEnvironmentsBySystemRequest,
  setPageEnvironmentsBySystem,
} from 'features/Contract/ducks/actions';

import { ENVIRONMENT_FIELDS } from '../../constants';
import { EnvironmentFilter } from '../EnvironmentFilter';

import { getDataTable } from './utils';

interface EnvironmentTableProps {
  environments?: Environment[];
  sort?: string;
  handleSort?: (value: string) => void;
  handleRowClick?: (value: string) => void;
  showFilterRow?: boolean;
  isFilterActive?: boolean;
  activeRowId?: string;
  customFilter?: JSX.Element;
}

export const EnvironmentTable: FC<EnvironmentTableProps> = ({
  environments,
  sort,
  handleSort,
  handleRowClick,
  showFilterRow,
  isFilterActive,
  activeRowId,
  customFilter,
}) => {
  const dispatch = useDispatch();

  const data = getDataTable(environments);

  const handleFilter = (filterData: EnvironmentsFilter) => {
    dispatch(setPageEnvironmentsBySystem(0));
    dispatch(fetchEnvironmentsBySystemRequest(filterData));
  };

  const filterComponent = customFilter || (
    <EnvironmentFilter onFilter={handleFilter} />
  );

  return (
    <Table
      data={data}
      columns={ENVIRONMENT_FIELDS}
      onRowClick={handleRowClick}
      sort={sort}
      onSort={handleSort}
      isFilterActive={isFilterActive}
      activeRowId={activeRowId}
      valueFieldName="id"
      filterComponent={showFilterRow && filterComponent}
    />
  );
};
