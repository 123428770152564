import { Improvement, TicketEvaluation } from 'features/Ticket/types';

import {
  AssessmentInteraction,
  EvaluateSpecialistAction,
  EvaluateSpecialistPayload,
  FetchImprovementsRequestAction,
  FetchImprovementsSuccessAction,
  FetchTicketEvaluationRequestAction,
  FetchTicketEvaluationSuccessAction,
  ResetAssessmentInteractionStateAction,
} from './types';

export const fetchImprovementsRequest = (
  payload: number
): FetchImprovementsRequestAction => {
  return {
    type: AssessmentInteraction.FETCH_IMPROVEMENTS_REQUEST,
    payload,
  };
};

export const fetchImprovementsSuccess = (
  payload: Improvement[]
): FetchImprovementsSuccessAction => {
  return {
    type: AssessmentInteraction.FETCH_IMPROVEMENTS_SUCCESS,
    payload,
  };
};

export const resetAssessmentInteractionState =
  (): ResetAssessmentInteractionStateAction => {
    return {
      type: AssessmentInteraction.RESET_ASSESSMENT_INTERACTION_STATE,
    };
  };

export const evaluateSpecialist = (
  payload: EvaluateSpecialistPayload
): EvaluateSpecialistAction => {
  return {
    type: AssessmentInteraction.EVALUATE_SPECIALIST,
    payload,
  };
};

export const fetchTicketEvaluationRequest = (
  payload: string
): FetchTicketEvaluationRequestAction => {
  return {
    type: AssessmentInteraction.FETCH_TICKET_EVALUATION_REQUEST,
    payload,
  };
};

export const fetchTicketEvaluationSuccess = (
  payload: TicketEvaluation
): FetchTicketEvaluationSuccessAction => {
  return {
    type: AssessmentInteraction.FETCH_TICKET_EVALUATION_SUCCESS,
    payload,
  };
};
