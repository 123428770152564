import { RoleType } from 'core/types';
import { Role } from 'core/types/roles';
import { ResponseWithMeta } from 'store/types';
import { ApiClient, getEnv } from 'utils';

import { RoleCreateToRequest, RolesFilterToRequest } from '../../types';

import { endpoints } from './endpoints';

export const request = {
  fetchRoles: (
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: RolesFilterToRequest
  ) =>
    ApiClient.post<ResponseWithMeta<Role[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getRoles(),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: {
        ...filter,
      },
    }),
  fetchRolesByUserId: (
    userId: string,
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: RolesFilterToRequest
  ) =>
    ApiClient.post<ResponseWithMeta<Role[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getRolesByUserId(userId),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: { ...filter, type: RoleType.ROLE_IN_SYSTEM },
    }),
  createRole: (body: RoleCreateToRequest) =>
    ApiClient.post<Role>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.createRole(),
      data: body,
    }),
  fetchUserRolesByGroupId: (
    groupId: string,
    userId: string,
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: RolesFilterToRequest
  ) =>
    ApiClient.post<ResponseWithMeta<Role[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getUserRolesByGroupId(groupId, userId),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: { ...filter, type: RoleType.ROLE_IN_GROUP },
    }),
  fetchRolesByGroupId: (
    groupId?: string,
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: RolesFilterToRequest
  ) =>
    ApiClient.post<ResponseWithMeta<Role[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getRolesByGroupId(groupId),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: { ...filter, type: RoleType.ROLE_IN_GROUP },
    }),
  updateRole: (body: Role) =>
    ApiClient.put<Role>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.createRole(),
      data: body,
    }),
  fetchRoleById: (id: string) =>
    ApiClient.get<Role>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getRoleById(id),
      params: {},
      data: {},
    }),
  checkIsExist: (title: string) =>
    ApiClient.get<boolean>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getCheckIsExist(title),
    }),
  deleteRole: (id: string) =>
    ApiClient.delete<boolean>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.deleteRole(id),
    }),
};
