import { forwardRef } from 'react';
import { useDispatch } from 'react-redux';

import { Card } from 'components';
import { Ticket } from 'core/types/ticket';
import { SLA } from 'features/SLA';

import { fetchTicketRequest } from '../../ducks/ticket/actions';

import styles from './TicketSla.module.scss';

interface TicketSLAProps {
  ticket: Ticket;
}

export const TicketSLA = forwardRef<HTMLDivElement, TicketSLAProps>(
  ({ ticket }, ref) => {
    const dispatch = useDispatch();

    const updateTicket = () => {
      if (ticket.id) {
        dispatch(fetchTicketRequest({ ticketId: ticket.id }));
      }
    };

    return (
      <Card ref={ref} className={styles.ticketSla}>
        <SLA
          ticket={ticket}
          updateTicket={updateTicket}
          size="small"
          isTicketPage
        />
      </Card>
    );
  }
);
