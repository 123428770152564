import DOMPurify from 'dompurify';
import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';

import {
  Button,
  Card,
  getMultiOptionValues,
  getValueFromValueType,
  Input,
} from 'components';
import { setRedirectPath } from 'core/ducks/actions';
import { ApproveOrCancel } from 'core/modals';
import { EditorRefType } from 'core/types';
import { useCreateArticleForm } from 'features/KnowledgeBase/views/KBCreateArticle/hooks';
import { RouterHref } from 'routes/routerHref';
import { AuthService, getEditorConfig, getEnv } from 'utils';

import {
  createArticleRequestArticleCreate,
  editArticleRequestArticleCreate,
} from './ducks/actions';
import styles from './KBCreateArticle.module.scss';

interface KBCreateArticleProps {
  form: ReturnType<typeof useCreateArticleForm>;
}

export const KBCreateArticle: FC<KBCreateArticleProps> = ({ form }) => {
  const dispatch = useDispatch();
  const token = AuthService.getToken();

  const editorConfig = getEditorConfig(
    `${getEnv('REACT_APP_ATTACHMENT_URL')}/api/attachment/article/image`,
    token
  );

  const { methods, state } = form;
  const { reset, handleSubmit } = methods;
  const {
    titleInputOptions,
    text: defaultText,
    isEditMode,
    isValid,
    id,
  } = state;

  const [text, setText] = useState('');
  const [isSubModal, setIsSubModal] = useState<boolean>(false);
  const [editorLoaded, setEditorLoaded] = useState(false);

  const editorRef = useRef<EditorRefType>();

  const { CKEditor, Editor } = editorRef.current || {};
  const createDisabled = !isEditMode ? !(isValid && text) : false;

  const toggleSubModal = () => {
    setIsSubModal(!isSubModal);
  };

  const clearFormData = () => {
    reset();
    setText('');
  };

  const onApproveSubModal = () => {
    clearFormData();
    toggleSubModal();
    dispatch(setRedirectPath(RouterHref.KnowledgeBase));
  };

  useEffect(() => {
    setText(DOMPurify.sanitize(defaultText || ''));
  }, [defaultText]);

  useEffect(() => {
    editorRef.current = {
      // eslint-disable-next-line
      CKEditor: require('@ckeditor/ckeditor5-react').CKEditor,
      // eslint-disable-next-line
      Editor: require('ckeditor5-custom-build/build/ckeditor'),
    };
    setEditorLoaded(true);
  }, []);

  const formSubmitHandler = handleSubmit((data) => {
    const { category, parentCategory, ...other } = data;
    const categoryDeep = parentCategory && category ? category : parentCategory;
    const organizationIds = getMultiOptionValues(data.organizationIds);
    const workgroupIds = getMultiOptionValues(data.workgroupIds);
    const categoryId = getValueFromValueType(categoryDeep);
    const accessType = getValueFromValueType(data.accessType);

    const prepareData = {
      ...other,
      organizationIds,
      workgroupIds,
      categoryId,
      accessType,
      text: DOMPurify.sanitize(text || ' '),
    };

    if (!isEditMode) {
      dispatch(createArticleRequestArticleCreate(prepareData));
      clearFormData();
      return;
    }

    dispatch(editArticleRequestArticleCreate({ ...prepareData, id }));
    clearFormData();
  });

  const onChangeEditor = useCallback(
    (event: ChangeEvent<HTMLInputElement>, editor) => {
      const data = editor.getData();
      setText(data);
    },
    [setText]
  );

  return (
    <>
      <Card className={styles.KBCreateArticle}>
        <Input
          {...titleInputOptions}
          label="Название статьи"
          className={styles.KBCreateArticle__input}
        />
        {editorLoaded && Editor && CKEditor && (
          <div className={styles.KBCreateArticle__editor}>
            <CKEditor
              editor={Editor}
              config={editorConfig}
              data={text}
              onReady={() => null}
              onChange={onChangeEditor}
            />
          </div>
        )}
        <div className={styles.KBCreateArticle__buttonsBlock}>
          <Button appearance="flat" onClick={toggleSubModal} type="button">
            Отмена
          </Button>
          <Button onClick={formSubmitHandler} disabled={createDisabled}>
            {isEditMode ? 'Изменить' : 'Создать'}
          </Button>
        </div>
      </Card>
      <ApproveOrCancel
        onApprove={onApproveSubModal}
        isModal={isSubModal}
        toggleModal={toggleSubModal}
        text={`Вы уверены, что хотите отменить ${
          isEditMode ? 'изменение' : 'создание'
        } статьи?`}
      />
    </>
  );
};
