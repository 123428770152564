import cn from 'clsx';
import { FC, useEffect, useRef, useState } from 'react';

import { BackArrowIcon } from 'assets/icons';

import { ControlsProps, DropdownType } from '../../types';
import { Dropdown } from '../Dropdown';

import styles from './Controls.module.scss';

export const Controls: FC<ControlsProps> = ({
  className,
  rangeType,
  selectedDate,
  setSelectedDate,
}) => {
  const [activeDropdown, setActiveDropdown] = useState<DropdownType>('');

  const monthsRef = useRef(null);
  const yearsRef = useRef(null);

  const fromRange = rangeType === 'from';
  const toRange = rangeType === 'to';

  const months: number[] = [];
  const years: number[] = [];

  for (let i = 0; i <= 11; i++) {
    months.push(i);
  }

  const selectedMonth = selectedDate.getMonth();

  const selectedYear = selectedDate.getFullYear();

  for (let i = selectedYear; i <= selectedYear + 11; i++) {
    years.push(i);
  }

  const handleSetActiveDropdown = (dropdownName: DropdownType) => {
    return activeDropdown === dropdownName
      ? setActiveDropdown('')
      : setActiveDropdown(dropdownName);
  };

  const decreaseSelectedMonth = () => {
    setSelectedDate(
      new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth() - 1,
        selectedDate.getDate()
      )
    );
  };

  const increaseSelectedMonth = () => {
    setSelectedDate(
      new Date(
        selectedDate.getFullYear(),
        toRange ? selectedDate.getMonth() : selectedDate.getMonth() + 1,
        selectedDate.getDate()
      )
    );
  };

  const changeSelectedYear = (value: number) => {
    return () => {
      const year = selectedDate.getFullYear();
      const newSelectedDate = new Date(selectedDate.setFullYear(year + value));
      setSelectedDate(new Date(newSelectedDate));
    };
  };

  const selectMonth = (item: number) => {
    setSelectedDate(
      new Date(selectedDate.getFullYear(), item, selectedDate.getDate())
    );
  };
  const selectYear = (item: number) => {
    setSelectedDate(
      new Date(item, selectedDate.getMonth(), selectedDate.getDate())
    );
  };

  useEffect(() => {
    const handleClickOutOfDropdown = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      const monthsEl = monthsRef.current as unknown as HTMLElement;
      const yearsEl = yearsRef.current as unknown as HTMLElement;

      if (
        (activeDropdown === 'months' && !monthsEl.contains(target)) ||
        (activeDropdown === 'years' && !yearsEl.contains(target))
      ) {
        setActiveDropdown('');
      }
    };

    document.addEventListener('click', handleClickOutOfDropdown);

    return () => {
      document.removeEventListener('click', handleClickOutOfDropdown);
    };
  }, [monthsRef.current, yearsRef.current, activeDropdown]);

  return (
    <div className={cn(styles.controls, className)}>
      <div className={cn(styles.controls__btnWrapper)}>
        <button
          type="button"
          className={cn(styles.controls__btn, {
            [styles.controls__btn_hide]: toRange,
          })}
          onClick={decreaseSelectedMonth}
        >
          <BackArrowIcon className={styles.controls__icon} />
        </button>
      </div>

      <Dropdown
        ref={monthsRef}
        isActive={activeDropdown === 'months'}
        setActive={() => handleSetActiveDropdown('months')}
        items={months}
        selectItem={selectMonth}
        selectedItem={selectedMonth}
        increaseSelectedYear={changeSelectedYear(1)}
        decreaseSelectedYear={changeSelectedYear(-1)}
      />
      <div className={styles.controls__divider} />
      <Dropdown
        ref={yearsRef}
        isActive={activeDropdown === 'years'}
        setActive={() => handleSetActiveDropdown('years')}
        items={years}
        selectItem={selectYear}
        selectedItem={selectedYear}
        increaseSelectedYear={changeSelectedYear(1)}
        decreaseSelectedYear={changeSelectedYear(-1)}
      />

      <div className={styles.controls__btnWrapper}>
        <button
          type="button"
          className={cn(styles.controls__btn, {
            [styles.controls__btn_hide]: fromRange,
          })}
          onClick={increaseSelectedMonth}
        >
          <BackArrowIcon className={styles.controls__icon_rotated} />
        </button>
      </div>
    </div>
  );
};
