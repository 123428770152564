import { AdminsLayout } from 'core/layouts';
import { Responsibilities } from 'features/Responsibilities';

const AdminPowers = () => {
  return (
    <AdminsLayout>
      <Responsibilities />
    </AdminsLayout>
  );
};

export default AdminPowers;
