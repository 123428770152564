import cn from 'clsx';
import { forwardRef, InputHTMLAttributes } from 'react';

import { CheckMarkIcon } from 'assets/icons';
import { Typography, TypographyVariants } from 'components';

import styles from './Checkbox.module.scss';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  classNameLabel?: string;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (props, ref) => {
    const { className, disabled, label, classNameLabel, ...otherProps } = props;

    return (
      <label
        className={cn(
          styles.checkbox,
          { [styles.checkbox_disabled]: disabled },
          className
        )}
      >
        <input
          ref={ref}
          type="checkbox"
          className={styles.checkbox__input}
          disabled={disabled}
          {...otherProps}
        />
        <CheckMarkIcon
          className={cn(styles.checkbox__icon, {
            [styles.checkbox__icon_disabled]: disabled,
          })}
        />
        {label && (
          <Typography
            variant={TypographyVariants.b3}
            className={cn(styles.checkbox__label, classNameLabel)}
          >
            {label}
          </Typography>
        )}
      </label>
    );
  }
);
