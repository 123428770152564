import cn from 'clsx';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LazyLoader } from 'core/components';
import { TableResponsibilitiesTypes } from 'core/types/responsibility';
import { TableRolesTypes } from 'core/types/roles';
import { TableUsersTypes } from 'core/types/user';
import { resetFilterOrganizationIdState } from 'features/Organizations';
import {
  fetchRespByGroupIdRequest,
  resetResponsibilitiesState,
  ResponsibilitiesTableContainer,
} from 'features/Responsibilities';
import {
  fetchRolesByGroupId,
  resetRolesState,
  RolesTableContainer,
} from 'features/Roles';
import {
  fetchUsersByGroupId,
  resetUsersState,
  UsersTableContainer,
} from 'features/Users';
import {
  fetchCurrentWorkGroup,
  getLoadingWorkGroup,
  getWorkGroup,
  resetWorkGroupsState,
  setWorkGroupId,
  useWorkGroupId,
} from 'features/WorkGroups';

import { WorkGroupForm } from './components';
import styles from './WorkGroup.module.scss';

interface WorkGroupProps {
  className?: string;
}

export const WorkGroup: FC<WorkGroupProps> = ({ className }) => {
  const dispatch = useDispatch();

  const workGroupId = useWorkGroupId();

  const workGroup = useSelector(getWorkGroup);
  const loading = useSelector(getLoadingWorkGroup);

  useEffect(() => {
    if (workGroupId) {
      dispatch(setWorkGroupId(workGroupId));
      dispatch(fetchRespByGroupIdRequest());
      dispatch(fetchUsersByGroupId());
      dispatch(fetchRolesByGroupId());
      dispatch(fetchCurrentWorkGroup(workGroupId));
    }
  }, [workGroupId]);

  useEffect(
    () => () => {
      dispatch(resetWorkGroupsState());
      dispatch(resetUsersState());
      dispatch(resetResponsibilitiesState());
      dispatch(resetFilterOrganizationIdState());
      dispatch(resetRolesState());
    },
    []
  );

  if (loading && !workGroup) {
    return <LazyLoader className={styles.workGroup__loader} />;
  }

  return (
    <div className={cn(styles.workGroup, className)}>
      <div className={styles.workGroup__leftContainer}>
        <WorkGroupForm />
        <UsersTableContainer tableType={TableUsersTypes.FROM_GROUPS} />
      </div>
      <div className={styles.workGroup__rightContainer}>
        <ResponsibilitiesTableContainer
          tableType={TableResponsibilitiesTypes.FROM_GROUPS}
        />
        <RolesTableContainer tableType={TableRolesTypes.FROM_GROUPS} />
      </div>
    </div>
  );
};
