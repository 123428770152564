import { AdminsLayout } from 'core/layouts';
import { Contracts } from 'features/Contracts';

const AdminContracts = () => {
  return (
    <AdminsLayout>
      <Contracts />
    </AdminsLayout>
  );
};

export default AdminContracts;
