export const checkDatesEqualityWithoutTime = (
  firstDate?: Date,
  secondDate?: Date
) => {
  if (!firstDate || !secondDate) {
    return false;
  }
  return (
    firstDate.getFullYear() === secondDate.getFullYear() &&
    firstDate.getMonth() === secondDate.getMonth() &&
    firstDate.getDate() === secondDate.getDate()
  );
};
