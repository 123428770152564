import { CategoryArticle } from 'core/types/knowledgeBase';
import { Organization } from 'core/types/organization';
import { WorkGroup } from 'core/types/workGroup';
import { ApiClient, getEnv } from 'utils';

import { FetchWorkGroupPayload } from '../../types';

import { endpoints } from './endpoints';

export const request = {
  organizationsArticleRequest: (filter?: { organizationTitle: string }) =>
    ApiClient.post<Organization[]>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.getOrganizationsForArticleCreate(),
      data: filter,
    }),
  fetchWorkGroups: (data: FetchWorkGroupPayload) =>
    ApiClient.post<WorkGroup[]>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.getWorkGroupsForArticleCreate(),
      data,
    }),
  categoriesArticleRequest: (data: string[]) =>
    ApiClient.post<CategoryArticle[]>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.getCategoriesByOrganizationIds(),
      data,
    }),
  fetchAllCategories: () =>
    ApiClient.get<CategoryArticle[]>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.getAllCategories(),
    }),
};
