import { FC, useState } from 'react';

import { Popover } from 'components';

import { SetCurrentFilterTitleRequest } from '../../types';
import { TicketsPopoverInner } from '../TicketsPopoverInner';

interface TicketsPopoverProps {
  value: string;
  title?: string;
  onChangePopover?: (title: string) => void;
  handleSubmitPopover?: (data: SetCurrentFilterTitleRequest) => void;
  className: string;
}

export const TicketsPopover: FC<TicketsPopoverProps> = (props) => {
  const { title, value, onChangePopover, handleSubmitPopover, className } =
    props;

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleToggle = () => {
    setIsPopoverOpen((prev) => !prev);
  };

  const handleChange = (popoverTitle: string) => {
    if (onChangePopover) {
      onChangePopover(popoverTitle);
    }
  };

  const handleSubmit = (currentTitle: string) => {
    if (handleSubmitPopover) {
      handleSubmitPopover({ id: value, title: currentTitle });
    }
  };

  return (
    <Popover
      className={className}
      positions={['right']}
      align="start"
      isOpen={isPopoverOpen}
      togglePopover={handleToggle}
      content={
        <TicketsPopoverInner
          defaultTitle={title}
          formSubmitHandler={handleSubmit}
          onChange={handleChange}
          value={value}
          closePopoverWithReset={setIsPopoverOpen}
        />
      }
    />
  );
};
