import { createBrowserHistory } from 'history';
import { FC } from 'react';
import { Router } from 'react-router-dom';
import { compose } from 'redux';

import { withRedirectPath, withScrollWindow } from 'core/hocs';
import { AlertsLayout, SidebarLayout } from 'core/layouts';
import { NotificationsLayout } from 'features/Notifications/layouts';
import { SwitchElements } from 'routes/SwitchElements';

const history = createBrowserHistory();

const withRouterHistoryData = (Component: FC): FC =>
  compose<FC>(withRedirectPath, withScrollWindow)(Component);

const SwitchComponent = withRouterHistoryData(SwitchElements);

export const RouterHistory: FC = () => (
  <Router history={history}>
    <SidebarLayout>
      <NotificationsLayout>
        <AlertsLayout>
          <SwitchComponent />
        </AlertsLayout>
      </NotificationsLayout>
    </SidebarLayout>
  </Router>
);
