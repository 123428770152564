import { getTimeList } from './utils';

export const DAYS = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];
export const MONTHS = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

export const SIDEBAR_OPTIONS = [
  'Текущая неделя',
  'Последние 7 дней',
  'Предыдущая неделя',
  'Текущий месяц',
  'Последние 30 дней',
  'Предыдущий месяц',
  'Текущий квартал',
];

export const DATE_FORMATS = [
  'dd.mm.yyyy',
  'dd.mm.yyyy  hh:mm',
  'dd.mm.yyyy - dd.mm.yyyy',
  'dd.mm.yyyy  hh:mm - dd.mm.yyyy  hh:mm',
];

export const regExpDate =
  /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;

export const HOURS = getTimeList(24);
export const MINUTES = getTimeList(60);
