import { omit } from 'lodash';

import { CustomFieldType, getReverseDateForRequest } from 'components';

import {
  CreateTicketCustomField,
  CreateTicketCustomFieldRequest,
} from '../types';

import { getPreparedListValue } from './getPreparedListValue';

export const getPreparedCustomFields = (
  customFields?: CreateTicketCustomField[]
): CreateTicketCustomFieldRequest[] => {
  if (!customFields) return [];

  return customFields.map((customField) => {
    const { type, date, listValue } = customField;

    const baseField = {
      ...omit(customField, ['title', 'values']),
      listValue: null,
    };

    if (type === CustomFieldType.FIELD_DATE && date) {
      return {
        ...baseField,
        date: getReverseDateForRequest(date),
      };
    }

    if (type === CustomFieldType.FIELD_LIST && listValue) {
      return {
        ...baseField,
        listValue: getPreparedListValue(listValue),
      };
    }

    return baseField;
  });
};
