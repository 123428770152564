import cn from 'clsx';
import { FC, useRef, useState } from 'react';

import { PlusIcon, SearchIcon } from 'assets/icons';
import {
  Button,
  Popover,
  RollUpButton,
  Size,
  Tab,
  Tabs,
  ToggleButton,
} from 'components';

import { CellContent } from '../../types';

import styles from './TableHeader.module.scss';

export interface TableHeaderProps {
  toggleSearch?: () => void;
  isSearchOpen?: boolean;
  tabs?: Tab[];
  dropContent?: JSX.Element;
  title?: string | JSX.Element;
  createHandler?: () => void;
  createTitle?: CellContent;
  tableOpened?: boolean;
  handleTableOpen?: (value: boolean) => void;
  additionalComponent?: JSX.Element;
  endComponent?: JSX.Element;
  icon?: JSX.Element;
  createDisabled?: boolean;
  searchDisabled?: boolean;
  dropDisabled?: boolean;
  headerElement?: JSX.Element;
  className?: string;
  classNameTitle?: string;
  loading?: boolean;
  tabsDisabled?: boolean;
  isWithOutsideClick?: boolean;
}

export const TableHeader: FC<Omit<TableHeaderProps, 'loading'>> = ({
  toggleSearch,
  isSearchOpen,
  tabs,
  dropContent,
  title,
  createHandler,
  createTitle = 'Создать',
  tableOpened = true,
  handleTableOpen,
  additionalComponent,
  icon,
  createDisabled = false,
  searchDisabled,
  dropDisabled,
  className,
  classNameTitle,
  endComponent,
  headerElement,
  tabsDisabled,
  isWithOutsideClick,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleOpenTable = () => {
    if (handleTableOpen) {
      handleTableOpen(!tableOpened);
    }
  };

  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  const togglePopover = () => {
    setIsPopoverOpen((prevState) => !prevState);
  };

  const popover = dropContent && (
    <Popover
      disabled={dropDisabled}
      className={styles.tableHeader__drop}
      isOpen={isPopoverOpen}
      togglePopover={togglePopover}
      content={dropContent}
      positions={['left', 'bottom']}
      align="start"
      withOutsideClick={!isWithOutsideClick}
    />
  );

  return (
    <div
      className={cn(styles.tableHeader, className, {
        [styles.tableHeader_noTabs]: !tabs && !title,
        [styles.tableHeader_withBoldLine]: tabs,
        [styles.tableHeader_withTitle]: title,
        [styles.tableHeader_close]: !tableOpened,
      })}
    >
      {title && (
        <div className={styles.tableHeader__titleBlockContainer}>
          <div className={styles.tableHeader__titleBlock}>
            <RollUpButton
              isOpen={tableOpened}
              toggleButton={handleOpenTable}
              className={styles.tableHeader__titleBtn}
            />
            <h2 className={cn(styles.tableHeader__tableTitle, classNameTitle)}>
              {title}
            </h2>
            <div className={styles.tableHeader__additionalComponent}>
              {additionalComponent}
            </div>
          </div>
          {headerElement}
        </div>
      )}
      {tabs && (
        <Tabs
          tabs={tabs}
          className={styles.tableHeader__tabs}
          tabsRef={ref}
          disabled={tabsDisabled}
        />
      )}
      <div className={styles.tableHeader__tools}>
        {createHandler && (
          <Button
            appearance="flat"
            size={Size.xs}
            onClick={createHandler}
            icon={icon || <PlusIcon className={styles.tableHeader__plusIcon} />}
            disabled={createDisabled}
            className={styles.tableHeader__createButton}
          >
            {createTitle}
          </Button>
        )}
        {toggleSearch && (
          <ToggleButton
            icon={<SearchIcon />}
            onClick={toggleSearch}
            isActive={isSearchOpen}
            disabled={searchDisabled}
            className={cn(styles.tableHeader__search, {
              [styles.tableHeader__search_mr]: !dropContent,
            })}
          />
        )}
        {popover}
        {endComponent}
      </div>
    </div>
  );
};
