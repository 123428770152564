import cn from 'clsx';
import { FC, useEffect } from 'react';
import useSound from 'use-sound';

import { Notification, NotificationProps } from '../Notification';

import notificationSound from './notification-sound.mp3';
import styles from './NotificationToast.module.scss';

interface NotificationToastProps extends NotificationProps {
  className?: string;
}

export const NotificationToast: FC<NotificationToastProps> = ({
  className,
  entities,
  ...notificationProps
}) => {
  const [play] = useSound(notificationSound);

  useEffect(() => {
    play();
  }, [play, entities?.length]);

  return (
    <div className={cn(styles.notificationToast, className)}>
      <Notification
        type="toast"
        entities={entities}
        {...notificationProps}
        classNameText={styles.notificationToast__text}
        classNameTitle={styles.notificationToast__text}
      />
    </div>
  );
};
