import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getMultiOptionValues, getValueFromValueType } from 'components';
import { RouterHref } from 'routes/routerHref';
import { checkPathnameWithTrailingSlash } from 'utils';

import { INITIAL_KPI_FORM_VALUES } from '../constants';
import { resetDesktopState } from '../ducks/Desktop';
import { fetchKpiRequest, resetKpiState } from '../ducks/Kpi';
import {
  getOrganizationsOptions,
  getSystemsOptions,
  getTicketTypesOptions,
  getWorkGroupsOptions,
} from '../ducks/Kpi/selectors';
import { KpiFormData } from '../types';

export const useKpiForm = () => {
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const workGroupsOptions = useSelector(getWorkGroupsOptions);
  const organizationsOptions = useSelector(getOrganizationsOptions);
  const systemsOptions = useSelector(getSystemsOptions);
  const ticketTypesOptions = useSelector(getTicketTypesOptions);

  const isDesktopKpiPage = checkPathnameWithTrailingSlash(
    pathname,
    RouterHref.DesktopKpi
  );

  const { control, resetField, handleSubmit, setValue, watch } =
    useForm<KpiFormData>({
      mode: 'onChange',
      defaultValues: INITIAL_KPI_FORM_VALUES,
    });

  const { workGroupsIds, organizationsIds, systemsIds } = watch();

  useEffect(() => {
    return () => {
      dispatch(resetKpiState());
      dispatch(resetDesktopState());

      resetField('period');
      resetField('workGroupsIds');
      resetField('organizationsIds');
      resetField('systemsIds');
      resetField('ticketTypesIds');
    };
  }, []);

  const fetchKpiHandler = handleSubmit((data) => {
    dispatch(
      fetchKpiRequest({
        workgroupList: getMultiOptionValues(data.workGroupsIds),
        organizationList: getMultiOptionValues(data.organizationsIds),
        systemList: getMultiOptionValues(data.systemsIds),
        ticketTypeList: getMultiOptionValues(data.ticketTypesIds),
        period: getValueFromValueType(data.period),
      })
    );
  });

  useEffect(() => {
    if (isDesktopKpiPage) {
      fetchKpiHandler();
    }
  }, [isDesktopKpiPage]);

  return {
    state: {
      kpiWorkGroupsIds: workGroupsIds,
      kpiOrganizationsIds: organizationsIds,
      kpiSystemsIds: systemsIds,
      kpiWorkGroupsOptions: workGroupsOptions,
      kpiOrganizationsOptions: organizationsOptions,
      kpiSystemsOptions: systemsOptions,
      kpiTicketTypesOptions: ticketTypesOptions,
    },
    methods: { kpiControl: control, fetchKpiHandler, setValue },
  };
};
