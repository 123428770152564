import { isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { getValueFromValueType, ValueType } from 'components';
import { Priority } from 'core/types';

import { DEFAULT_JIRA_CURRENT_FORM_VALUES } from '../constants';
import {
  editJiraIntegration,
  fetchInfoForJiraIntegration,
  fetchJiraFieldsRequest,
  fetchJiraPrioritiesByIntegrationIdRequest,
  fetchJiraTicketTypeByIntegrationIdRequest,
  fetchJiraTicketTypesByIntegrationIdRequest,
  fetchSpFieldsRequest,
  fetchSystemPrioritiesRequest,
  setCurrentTypeId,
} from '../ducks/actions';
import {
  getCurrentJiraIntegrationId,
  getCurrentSystemId,
  getFieldsIntegrations,
  getJiraIntegration,
  getJiraPrioritiesBySystemId,
  getJiraPrioritiesForCurrent,
  getJiraPrioritiesOptions,
  getJiraStatusesBySystemId,
  getJiraStatusesOptions,
  getJiraTicketType,
  getJiraTicketTypesForCurrent,
  getJiraTicketTypesForCurrentOptions,
  getLoadingJiraIntegrationInfo,
  getSpStatusesForCurrent,
  getSystemPriorities,
  getTicketTypesOptions,
} from '../ducks/selectors';
import { JiraIntegrationEditData, JiraPriority, JiraStatus } from '../types';
import {
  getPreparedDataForEdit,
  getPreparedPriorities,
  getStatusesByOrder,
} from '../utils';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const useJiraIntegrationsCurrent = () => {
  const dispatch = useDispatch();

  const systemId = useSelector(getCurrentSystemId);
  const jiraIntegrationId = useSelector(getCurrentJiraIntegrationId);
  const loadingInfo = useSelector(getLoadingJiraIntegrationInfo);
  const jiraIntegration = useSelector(getJiraIntegration);
  const jiraTicketType = useSelector(getJiraTicketType);
  const systemPriorities = useSelector(getSystemPriorities);
  const jiraPrioritiesBySystemId = useSelector(getJiraPrioritiesBySystemId);
  const jiraPriorities = useSelector(getJiraPrioritiesForCurrent);
  const jiraPrioritiesOptions = useSelector(() =>
    getJiraPrioritiesOptions(jiraPriorities)
  );
  const jiraTicketTypes = useSelector(getJiraTicketTypesForCurrent);
  const jiraTicketTypeOptions = useSelector(() =>
    getJiraTicketTypesForCurrentOptions(jiraTicketType)
  );
  const jiraTicketTypesForCurrentOptions = useSelector(() =>
    getJiraTicketTypesForCurrentOptions(jiraTicketTypes)
  );
  const jiraStatusesBySystemId = useSelector(getJiraStatusesBySystemId);
  const jiraStatusesBySystemIdOptions = useSelector(() =>
    getJiraStatusesOptions(jiraStatusesBySystemId)
  );
  const statusMapOptions = useSelector(getSpStatusesForCurrent);
  const fieldsIntegrations = useSelector(getFieldsIntegrations);
  const ticketTypesOptions = useSelector(getTicketTypesOptions);
  const statusesByOrder = getStatusesByOrder({
    statusMapOptions,
    jiraStatusesBySystemId,
  });

  const [isTableOpen, setIsTableOpen] = useState<boolean>(true);
  const [isAddDisabled, setIsAddDisabled] = useState<boolean>(false);
  const [alertIsOpen, setAlertIsOpen] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [statuses, setStatuses] = useState<JiraStatus[]>([]);
  const [priorities, setPriorities] = useState<JiraPriority[]>([]);

  const {
    control,
    formState: { dirtyFields },
    watch,
    resetField,
    handleSubmit,
    setValue,
  } = useForm<JiraIntegrationEditData>({
    mode: 'onChange',
    defaultValues: DEFAULT_JIRA_CURRENT_FORM_VALUES,
  });

  const { ticketTypeId } = watch();

  const typeId = getValueFromValueType(ticketTypeId);

  const statusesDisabled = statuses.length
    ? isEqual(statusesByOrder, statuses)
    : true;
  const prioritiesDisabled = priorities.length
    ? isEqual(getPreparedPriorities(jiraPrioritiesBySystemId), priorities)
    : true;
  const jiraTicketTypeDisabled = !dirtyFields.jiraTicketType;

  const disabledSubmit =
    statusesDisabled && prioritiesDisabled && jiraTicketTypeDisabled;

  const getIsPriorityAvailable = useCallback(
    (priority?: Priority[]) => {
      if (!priority) {
        return false;
      }

      return !!systemPriorities?.find((el) => el.value === priority[0]);
    },
    [systemPriorities]
  );

  const toggleIsTableOpen = () => {
    setIsTableOpen((prev) => !prev);
  };

  const onCloseAlert = () => {
    setAlertIsOpen(false);
  };

  const toggleIsEditMode = () => {
    setIsEditMode((prev) => !prev);
    resetField('ticketTypeId');
  };

  const setTypeId = (value?: string) => {
    dispatch(setCurrentTypeId(value));
  };

  const resetState = () => {
    setStatuses([]);
    setPriorities([]);
  };

  const onChangeStatuses = useCallback(
    (id: string) => {
      return (value: ValueType<number>) => {
        setStatuses((prevState) => {
          const result = jiraStatusesBySystemId?.find(
            (item) => item.idStatus === getValueFromValueType(value)
          );
          const existingTicketStatusId = prevState?.find((item) =>
            item?.ticketStatusIds?.find((el) => el.id === id)
          );

          return prevState.map((status) => {
            const idMatched = status?.ticketStatusIds?.find(
              (el) => el.id === id
            );

            if (idMatched) {
              return {
                ...result,
                ticketStatusIds: existingTicketStatusId?.ticketStatusIds,
              };
            }
            return status;
          });
        });
      };
    },
    [setStatuses, jiraStatusesBySystemId]
  );

  const onChangePriorities = useCallback(
    (priority?: Priority[]) => {
      return (value: ValueType) => {
        if (priority) {
          setPriorities((prevState) => {
            const result = jiraPriorities?.find(
              (item) => item.idPriority === getValueFromValueType(value)
            );

            const existingTicketPriorities = prevState?.find((item) =>
              item?.ticketPriorities?.find((el) => el.includes(priority[0]))
            );

            return prevState.map((item) => {
              const priorityMatched = item?.ticketPriorities?.find((el) =>
                el.includes(priority[0])
              );

              if (priorityMatched) {
                return {
                  ...result,
                  ticketPriorities: existingTicketPriorities?.ticketPriorities,
                };
              }
              return item;
            });
          });
        }
      };
    },
    [setPriorities, jiraPriorities]
  );

  useEffect(() => {
    if (systemId && jiraIntegrationId && typeId) {
      dispatch(
        fetchInfoForJiraIntegration({
          systemId,
          jiraIntegrationId,
          typeId,
        })
      );
      dispatch(fetchSpFieldsRequest({ systemId, typeId }));
      dispatch(fetchJiraFieldsRequest({ systemId, typeId }));
      dispatch(fetchJiraPrioritiesByIntegrationIdRequest({ systemId, typeId }));
    }
  }, [systemId, jiraIntegrationId, typeId]);

  useEffect(() => {
    if (systemId) {
      dispatch(fetchSystemPrioritiesRequest(systemId));
    }
  }, [systemId]);

  useEffect(() => {
    if (jiraIntegrationId) {
      dispatch(fetchJiraTicketTypesByIntegrationIdRequest(jiraIntegrationId));
      dispatch(fetchJiraTicketTypeByIntegrationIdRequest(jiraIntegrationId));
    }
    setAlertIsOpen(true);
    setIsEditMode(false);
    resetField('ticketTypeId');
  }, [jiraIntegrationId]);

  useEffect(() => {
    if (jiraPrioritiesBySystemId) {
      setPriorities(getPreparedPriorities(jiraPrioritiesBySystemId));
      setValue('jiraTicketType', jiraTicketTypeOptions[0]);
    }
  }, [jiraPrioritiesBySystemId]);

  useEffect(() => {
    setStatuses(statusesByOrder);
  }, [statusMapOptions, jiraStatusesBySystemId]);

  const editJiraIntegrationHandler = handleSubmit((data) => {
    const prepareData = getPreparedDataForEdit({
      data,
      statuses,
      priorities,
      jiraTicketTypes,
      jiraIntegration,
      systemId,
      statusesDisabled,
      prioritiesDisabled,
      jiraTicketTypeDisabled,
    });
    dispatch(editJiraIntegration(prepareData));
  });

  return {
    state: {
      jiraIntegrationId,
      isTableOpen,
      isAddDisabled,
      alertIsOpen,
      systemId,
      isEditMode,
      loadingInfo,
      control,
      ticketTypeId,
      jiraPrioritiesOptions,
      priorities,
      jiraTicketTypesForCurrentOptions,
      jiraStatusesBySystemIdOptions,
      statusMapOptions,
      statuses,
      disabledSubmit,
      fieldsIntegrations,
      ticketTypesOptions,
    },
    methods: {
      setIsAddDisabled,
      toggleIsTableOpen,
      onCloseAlert,
      toggleIsEditMode,
      setTypeId,
      editJiraIntegrationHandler,
      getIsPriorityAvailable,
      onChangePriorities,
      onChangeStatuses,
      resetState,
    },
  };
};
