interface Options {
  elementName?: string;
  modName?: string;
}

export const createBemClass =
  (blockName: string) =>
  (options: Options = {}) => {
    const { elementName, modName } = options;

    // eslint-disable-next-line sonarjs/no-nested-template-literals
    return `${blockName}${elementName ? `__${elementName}` : ''}${
      // eslint-disable-next-line sonarjs/no-nested-template-literals
      modName ? `_${modName}` : ''
    }`;
  };
