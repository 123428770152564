import { FC } from 'react';

import {
  Accordion,
  PriorityBlock,
  Select,
  SelectOption,
  Size,
  Typography,
  TypographyVariants,
  ValueType,
} from 'components';
import { Priority } from 'core/types';

import { JiraPriority } from '../../types';
import { getJiraPriority } from '../../utils';
import { JiraIntegrationsListElement } from '../JiraIntegrationsListElement';

import styles from './JiraIntegrationsPriorities.module.scss';

interface JiraIntegrationsPrioritiesProps {
  className?: string;
  isEditMode: boolean;
  getIsPriorityAvailable: (priority?: Priority[]) => boolean;
  jiraPrioritiesOptions: SelectOption<number>[];
  priorities?: JiraPriority[];
  onChangePriorities: (priority?: Priority[]) => (value: ValueType) => void;
}

export const JiraIntegrationsPriorities: FC<
  JiraIntegrationsPrioritiesProps
> = ({
  className,
  isEditMode,
  getIsPriorityAvailable,
  jiraPrioritiesOptions,
  priorities,
  onChangePriorities,
}) => {
  const prioritiesConfig = Object.values(Priority).map((priority) => {
    return {
      spPriority: priority,
      jiraPriority: getJiraPriority({ priorities, priority }),
    };
  });

  const prioritiesList = prioritiesConfig.map(
    ({ spPriority, jiraPriority }) => {
      const isPriorityAvailable = getIsPriorityAvailable(
        jiraPriority?.ticketPriorities
      );

      const { idPriority, name } =
        priorities?.find(
          (item) => item.idPriority === jiraPriority?.idPriority
        ) || {};

      const value = { title: name || '', value: idPriority || 0 };

      const rightElement = isEditMode ? (
        <Select<number>
          size={Size.xs}
          disabled={!isPriorityAvailable}
          options={jiraPrioritiesOptions}
          onChange={onChangePriorities(jiraPriority?.ticketPriorities)}
          mobileModalTitle="приоритет"
          value={value}
          isTooltip={false}
        />
      ) : (
        <Typography
          variant={TypographyVariants.b3}
          className={styles.jiraIntegrationsPriorities__text}
        >
          {jiraPriority?.name || '-'}
        </Typography>
      );

      return (
        <JiraIntegrationsListElement
          key={spPriority}
          leftElement={
            <PriorityBlock
              priority={spPriority}
              className={styles.jiraIntegrationsPriorities__priorityBlock}
            />
          }
          rightElement={rightElement}
        />
      );
    }
  );

  return (
    <Accordion title="Приоритет" defaultHidden className={className}>
      <div className={styles.jiraIntegrationsPriorities__content}>
        {prioritiesList}
      </div>
    </Accordion>
  );
};
