import { CreateUserData } from 'core/types/user';

export const DEFAULT_USER_FORM_VALUES: CreateUserData = {
  id: '',
  firstName: '',
  lastName: '',
  middleName: '',
  description: '',
  login: '',
  password: '',
  email: '',
  phoneNumber: '',
  requireUpdatePassword: false,
};
