import { Responsibility } from 'core/types/responsibility';

export const getDataTable = (responsibilities?: Responsibility[]) =>
  responsibilities?.map((responsibility) => {
    const { title, description, id } = responsibility;
    return {
      id,
      title,
      description,
    };
  }) || [];
