import { Organization as OrganizationContent } from 'features/Organization';
import { OrganizationLayout } from 'features/Organization/layouts';

import styles from './Organization.module.scss';

const Organization = () => {
  return (
    <OrganizationLayout>
      <OrganizationContent className={styles.organization__content} />
    </OrganizationLayout>
  );
};

export default Organization;
