import { getValueFromValueType, SelectOption } from 'components';
import { Attribute } from 'core/types';

export const prepareAttributeForRequest = (attributes: Attribute[]) => {
  return attributes.reduce<Record<string, string[]>>((result, attribute) => {
    const title = getValueFromValueType(attribute.attribute) || '';
    const value = attribute.value as SelectOption[];
    const ids = value.map((item) => item.value);
    // eslint-disable-next-line no-param-reassign
    result[title] = ids;

    return result;
  }, {});
};
