import { createSelector } from 'reselect';

import { getArraySelectOptions, SelectOption } from 'components';
import { WorkGroup } from 'core/types/workGroup';
import { RootState } from 'store/rootReducer';

export const getWorkGroups = (state: RootState): WorkGroup[] | undefined =>
  state.workGroups.workGroups;
export const getWorkGroup = (state: RootState): WorkGroup | undefined =>
  state.workGroups.workGroup;
export const getLoading = (state: RootState): boolean | undefined =>
  state.workGroups.loading;
export const getLoadingWorkGroup = (state: RootState): boolean | undefined =>
  state.workGroups.loadingWorkGroup;
export const getLoadingAdd = (state: RootState): boolean | undefined =>
  state.workGroups.loadingAdd;
export const getCurrentGroupId = (state: RootState) =>
  state.workGroups.workGroupId;
export const getPropsGroups = (state: RootState) => ({
  pageNum: state.workGroups.pagination?.pageNum,
  pageSize: state.workGroups.pagination?.pageSize,
  sortGroups: state.workGroups.sort,
  loadingGroups: state.workGroups.loading,
  totalElements: state.workGroups.pagination?.totalElements,
});
export const getPropsGroupsAdd = (state: RootState) => ({
  pageNum: state.workGroups.paginationAdd?.pageNum,
  pageSize: state.workGroups.paginationAdd?.pageSize,
  loadingGroupsAdd: state.workGroups.loadingAdd,
  totalElements: state.workGroups.paginationAdd?.totalElements,
});
export const getSort = (state: RootState) => state.workGroups.sort;
export const getGroupTitle = (state: RootState) =>
  state.workGroups.workGroupTitle;
export const getWorkGroupFilter = (state: RootState) =>
  state.workGroups.workGroupFilter || {};
export const getWorkGroupsAdd = (state: RootState) =>
  state.workGroups.workGroupsAdd;
export const getWorkGroupsAddFilter = (state: RootState) =>
  state.workGroups.workGroupsAddFilter || {};
export const getWorkGroupsFromMyOrg = (state: RootState) =>
  state.workGroups.workGroupsFromMyOrg;

export const getWorkGroupsFromMyOrgSelectList = createSelector<
  RootState,
  WorkGroup[] | undefined,
  SelectOption[]
>([getWorkGroupsFromMyOrg], (workGroups): SelectOption[] => {
  return getArraySelectOptions(workGroups);
});
