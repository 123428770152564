import cn from 'clsx';
import {
  FC,
  MouseEvent,
  MouseEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';

import { ArrowDownIcon } from 'assets/icons';
import {
  CountRecords,
  IconButton,
  Pagination,
  PaginationProps,
  Table,
  TableProps,
  Typography,
  TypographyVariants,
} from 'components';
import { AddModalComponent } from 'core/modals';

import styles from './AddModal.module.scss';

interface AddModalProps {
  leftTableProps: TableProps;
  rightTableProps: TableProps;
  isModal: boolean;
  toggleModal: () => void;
  mainTitle: string;
  leftTableTitle?: string;
  onArrowClick: (event: MouseEvent<HTMLButtonElement>) => void;
  onDelClick: (event: MouseEvent<HTMLButtonElement>) => void;
  disabledAddBtn?: boolean;
  disabledDeleteBtn?: boolean;
  onSubmit: MouseEventHandler<HTMLButtonElement>;
  filterComponent?: JSX.Element | boolean;
  subModalText: string;
  disabledSubmit?: boolean;
  loadingData?: boolean;
  isFilterActive?: boolean;
  saveTitle?: string;
  paginationLeftTableProps: PaginationProps;
}

export const AddModal: FC<AddModalProps> = ({
  leftTableProps,
  rightTableProps,
  isModal,
  toggleModal,
  mainTitle,
  leftTableTitle,
  onArrowClick,
  disabledAddBtn,
  disabledDeleteBtn,
  onDelClick,
  onSubmit,
  filterComponent,
  subModalText,
  disabledSubmit = false,
  loadingData,
  isFilterActive,
  saveTitle,
  paginationLeftTableProps,
}) => {
  const leftTitle = `${
    rightTableProps.data.length ? 'Редактировать' : 'Добавить'
  } ${mainTitle}`;

  const ref = useRef<HTMLDivElement>(null);

  const [isMobilePagination, setIsMobilePagination] = useState(false);

  useEffect(() => {
    if (ref.current && ref.current?.clientWidth < 655 && !isMobilePagination) {
      setIsMobilePagination(true);
      return;
    }
    if (ref.current && ref.current?.clientWidth >= 655 && isMobilePagination) {
      setIsMobilePagination(false);
    }
  }, [ref.current?.clientWidth]);

  return (
    <AddModalComponent
      isModal={isModal}
      toggleModal={toggleModal}
      title={leftTitle}
      subModalText={subModalText}
      disabledSubmit={disabledSubmit}
      className={styles.modal__root}
      classNameForm={styles.modal__form}
      onSubmit={onSubmit}
      saveTitle={saveTitle}
    >
      <div className={styles.modal}>
        <div className={cn(styles.modal__table, leftTableProps.className)}>
          <Typography
            variant={TypographyVariants.h4}
            className={styles.modal__tableTitle}
          >
            {leftTableTitle}
          </Typography>
          <Table
            {...leftTableProps}
            filterComponent={filterComponent}
            className={styles.modal__tableContent}
            isFilterActive={isFilterActive}
            dataLoading={loadingData}
            ref={ref}
          />
          <div className={styles.modal__tableInfo}>
            <CountRecords
              records={paginationLeftTableProps.elementsCount}
              className={styles.modal__count}
            />
            <Pagination
              {...paginationLeftTableProps}
              isMobilePagination={isMobilePagination}
              className={styles.modal__pagination}
            />
          </div>
        </div>
        <div className={styles.modal__buttons}>
          <IconButton
            icon={<ArrowDownIcon className={styles.modal__arrow_right} />}
            onClick={onArrowClick}
            disabled={disabledAddBtn}
            className={styles.modal__button}
          />
          <IconButton
            icon={<ArrowDownIcon className={styles.modal__arrow_left} />}
            appearance="outline"
            onClick={onDelClick}
            disabled={disabledDeleteBtn}
            className={styles.modal__button}
          />
        </div>
        <div className={styles.modal__table}>
          <Typography
            variant={TypographyVariants.h4}
            className={styles.modal__tableTitle}
          >
            Выбрано
          </Typography>
          <Table {...rightTableProps} className={styles.modal__tableContent} />
          <div className={styles.modal__tableInfo}>
            <CountRecords
              records={rightTableProps.data.length}
              className={styles.modal__count}
            />
          </div>
        </div>
      </div>
    </AddModalComponent>
  );
};
