import cn from 'clsx';
import { ButtonHTMLAttributes, FC, MouseEvent, SVGProps } from 'react';

import styles from './ToggleButton.module.scss';

interface ToggleButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: SVGProps<SVGElement>;
  isActive?: boolean;
}

export const ToggleButton: FC<ToggleButtonProps> = ({
  icon,
  className,
  isActive,
  onMouseDown,
  ...otherProps
}) => {
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (onMouseDown) {
      onMouseDown(event);
    }
  };

  return (
    <button
      className={cn(styles.toggleButton, className, {
        [styles.toggleButton_active]: isActive,
      })}
      onMouseDown={handleClick}
      {...otherProps}
    >
      {icon}
    </button>
  );
};
