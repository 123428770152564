import { FC } from 'react';
import { Control, Controller } from 'react-hook-form';

import { InputDatePicker, Select, SelectOption } from 'components';

import { EmployeesFormData } from '../../types';

import styles from './EmployeesForm.module.scss';

interface EmployeesFormProps {
  control: Control<EmployeesFormData>;
  managerWorkGroupsList: SelectOption[];
}

export const EmployeesForm: FC<EmployeesFormProps> = ({
  control,
  managerWorkGroupsList,
}) => {
  return (
    <>
      <Controller
        control={control}
        name="workGroupId"
        render={({ field }) => {
          return (
            <Select<string>
              label="Группа исполнителей"
              mobileModalTitle="группу исполнителей"
              value={field.value}
              className={styles.employeesForm__select}
              options={managerWorkGroupsList}
              onChange={field.onChange}
            />
          );
        }}
      />
      <Controller
        control={control}
        name="date"
        render={({ field }) => {
          return (
            <InputDatePicker
              type="datePicker"
              placeholder="Период"
              value={field.value}
              onChange={field.onChange}
              className={styles.employeesForm__select}
              showRange
            />
          );
        }}
      />
    </>
  );
};
