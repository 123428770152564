import { MainLayout } from 'core/layouts';
import { Header } from 'features/Desktop/components';
import { DesktopCurrentState as DesktopCurrentStateContent } from 'features/Desktop/views';

import styles from './DesktopCurrentState.module.scss';

const DesktopCurrentState = () => {
  return (
    <MainLayout customHeader={<Header />}>
      <DesktopCurrentStateContent className={styles.desktop__content} />
    </MainLayout>
  );
};

export default DesktopCurrentState;
