import { ChangeEvent, FC } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import { ClockFat } from 'assets/icons';
import {
  Input,
  ToggleSwitch,
  Typography,
  TypographyVariants,
} from 'components';
import { SlaTable, SlaTableProps } from 'features/Contract/components';

import { SlaAutoClosing, SlaAutoClosingProps } from '../SlaAutoClosing';

import styles from './SLA.module.scss';

interface SLAProps {
  workingHoursInput?: UseFormRegisterReturn;
  workingHoursToInput?: UseFormRegisterReturn;
  popover?: JSX.Element;
  autoClothingProps: SlaAutoClosingProps;
  slaTableProps: SlaTableProps;
  handleActiveTicketType: (
    id: string
  ) => (event: ChangeEvent<HTMLInputElement>) => void;
}

export const SLA: FC<SLAProps> = ({
  workingHoursInput,
  workingHoursToInput,
  popover,
  autoClothingProps,
  slaTableProps,
  handleActiveTicketType,
}) => {
  const ticketTypes = slaTableProps.slaTicketTypes;

  const ticketTypeList = ticketTypes?.map((ticketType) => (
    <ToggleSwitch
      key={ticketType.ticketTypeId}
      label={ticketType.title}
      checked={ticketType.active}
      onChange={handleActiveTicketType(ticketType.ticketTypeId)}
    />
  ));

  return (
    <div>
      <div className={styles.sla}>
        <div className={styles.sla__container}>
          <div className={styles.sla__openingHoursBlockInfo}>
            <ClockFat className={styles.sla__openingHoursBlockIcon} />
            <Typography variant={TypographyVariants.b3}>
              Часы работы:
            </Typography>
          </div>
          <div className={styles.sla__inputsBlock}>
            <Input
              {...workingHoursInput}
              type="time"
              className={styles.sla__inputsBlockItem}
              label="От"
            />
            <Input
              {...workingHoursToInput}
              type="time"
              className={styles.sla__inputsBlockItem}
              label="До"
            />
          </div>
          {popover}
        </div>
        {!!ticketTypes?.length && (
          <div className={styles.sla__ticketType}>
            <Typography variant={TypographyVariants.h5}>Тип тикета</Typography>
            <div className={styles.sla__switches}>{ticketTypeList}</div>
          </div>
        )}
      </div>
      <SlaTable {...slaTableProps} />
      <SlaAutoClosing {...autoClothingProps} />
    </div>
  );
};
