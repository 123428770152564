import { Ticket } from 'features/Ticket';
import { TicketLayout } from 'features/Ticket/layouts';

const TicketPage = () => {
  return (
    <TicketLayout>
      <Ticket />
    </TicketLayout>
  );
};

export default TicketPage;
