import { FC, MouseEvent } from 'react';
import { useDispatch } from 'react-redux';

import { Pen3Icon } from 'assets/icons';
import { PopoverButton, PopoverContainer } from 'components';
import { DeleteButton } from 'core/components';

import { setIsEditNewsMode, setIsNewsModal } from '../../ducks/actions';

import styles from './NewsPopoverContent.module.scss';

interface NewsPopoverContentProps {
  onDelete(): void;
  toggleIsPopoverOpen(): void;
}

export const NewsPopoverContent: FC<NewsPopoverContentProps> = ({
  onDelete,
  toggleIsPopoverOpen,
}) => {
  const dispatch = useDispatch();

  const onEdit = () => {
    toggleIsPopoverOpen();
    dispatch(setIsEditNewsMode(true));
    dispatch(setIsNewsModal(true));
  };

  const onDeleteHandler = (event?: MouseEvent<HTMLButtonElement>) => {
    event?.stopPropagation();
    toggleIsPopoverOpen();
    onDelete();
  };

  return (
    <>
      <PopoverContainer>
        <PopoverButton
          onClick={onEdit}
          icon={<Pen3Icon className={styles.newsPopover__icon} />}
        >
          Редактировать
        </PopoverButton>
        <DeleteButton onClick={onDeleteHandler} />
      </PopoverContainer>
    </>
  );
};
