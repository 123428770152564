import { debounce } from 'lodash';
import { FC, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { Input, Size } from 'components';
import { DEFAULT_DEBOUNCE_DELAY } from 'constants/meta';
import { TicketTypeSettings } from 'core/types';
import { SLAType } from 'core/types/sla';
import { getSlaTicketTypesFromSettings } from 'core/utils';
import { SlaTable } from 'features/Contract/components';
import {
  fetchSLAPatternsRequestContractDetail,
  resetSLAPatternsContractDetail,
} from 'features/Contract/ducks/actions';
import {
  getPatternActive,
  getTicketTypes,
} from 'features/Contract/ducks/selectors';
import { CreateSystemDataAdd } from 'features/Contract/types';
import { getPreparedTicketTypes } from 'features/Contract/utils';

import { SlaAutoClosing, SlaAutoClosingProps } from '../SlaAutoClosing';
import { SLAPatternsList } from '../SLAPatternsList/SLAPatternsList';

import styles from './SavedPatternsSla.module.scss';

interface SavedPatternsSlaProps {
  copyPatternToCreateMode?: (data: SLAType) => void;
}

export const SavedPatternsSla: FC<SavedPatternsSlaProps> = ({
  copyPatternToCreateMode,
}) => {
  const dispatch = useDispatch();

  const activeSLA = useSelector(getPatternActive);
  const ticketTypes = useSelector(getTicketTypes);

  const [slaTicketTypes, setSlaTicketTypes] = useState<TicketTypeSettings[]>();

  useEffect(() => {
    if (activeSLA?.id) {
      setSlaTicketTypes(getSlaTicketTypesFromSettings(activeSLA?.settings));
      return;
    }
    setSlaTicketTypes(getPreparedTicketTypes(ticketTypes));
  }, [activeSLA]);

  const { register, handleSubmit, reset, watch } = useForm<{ title?: string }>({
    mode: 'onChange',
  });

  const {
    control,
    setValue,
    watch: watchAutoClosing,
  } = useForm<CreateSystemDataAdd>({
    mode: 'onChange',
    defaultValues: {
      expireReactionTimeInDecisionTime: false,
      autoClosableTickets: false,
    },
  });

  const { closeAfterHours, autoClosableTickets } = watchAutoClosing();

  const autoClothingProps: SlaAutoClosingProps = {
    control,
    setValue,
    isEditMode: false,
    closeAfterHours,
    autoClosableTickets,
    className: styles.savedPatternsSla__autoClosing,
  };

  useEffect(() => {
    if (activeSLA) {
      setValue('autoClosableTickets', activeSLA.autoClosableTickets);
      setValue('closeAfterHours', activeSLA.closeAfterHours);
      setValue(
        'expireReactionTimeInDecisionTime',
        activeSLA.expireReactionTimeInDecisionTime
      );
    }
  }, [activeSLA]);

  const { title: searchValue } = watch();

  const formSubmitHandler = handleSubmit((data) => {
    const { title } = data;
    dispatch(fetchSLAPatternsRequestContractDetail(title));
  });

  const formSubmitHandlerDebounced = useMemo(
    () => debounce(formSubmitHandler, DEFAULT_DEBOUNCE_DELAY),
    []
  );

  const titleInputOptions = register('title', {
    onChange: formSubmitHandlerDebounced,
  });

  useEffect(() => {
    dispatch(fetchSLAPatternsRequestContractDetail());
    return () => {
      reset();
      dispatch(resetSLAPatternsContractDetail());
    };
  }, []);

  return (
    <div>
      <Input
        {...titleInputOptions}
        size={Size.s}
        label="Поиск"
        className={styles.savedPatternsSla__input}
      />
      <div className={styles.savedPatternsSla__patterns}>
        <SLAPatternsList
          hasSearchValue={Boolean(searchValue)}
          copyPatternToCreateMode={copyPatternToCreateMode}
        />
      </div>
      <SlaTable
        slaSettings={activeSLA?.settings}
        slaTicketTypes={slaTicketTypes}
      />
      <SlaAutoClosing {...autoClothingProps} />
    </div>
  );
};
