import cn from 'clsx';
import { FC } from 'react';

import { TicketTab as TicketTabType } from 'features/Ticket';

import styles from './TicketTab.module.scss';

interface TicketTabProps {
  tab: TicketTabType;
}

export const TicketTab: FC<TicketTabProps> = ({ tab }) => {
  const { title, className, onTabClick, isTabActive } = tab;
  return (
    <div
      key={String(title)}
      onClick={onTabClick}
      tabIndex={0}
      role="button"
      onKeyDown={undefined}
      className={cn(styles.ticketTab__tab, className, {
        [styles.ticketTab__tab_active]: isTabActive,
      })}
    >
      {title}
    </div>
  );
};
