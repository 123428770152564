import cn from 'clsx';
import { FC } from 'react';
import ReactTooltip, { TooltipProps } from 'react-tooltip';

import styles from './Tooltip.module.scss';

interface TooltipComponentProps extends TooltipProps {}

export const Tooltip: FC<TooltipComponentProps> = (props) => {
  const {
    children,
    place = 'bottom',
    className,
    arrowColor = 'transparent',
    ...otherProps
  } = props;
  return (
    <ReactTooltip
      place={place}
      effect="solid"
      backgroundColor="var(--transparent2)"
      arrowColor={arrowColor}
      className={cn(styles.tooltip, className)}
      {...otherProps}
    >
      {children}
    </ReactTooltip>
  );
};
