import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { TablesLayout } from 'core/layouts';
import { resetOrganizationState } from 'features/Organizations';
import { resetSystemsState } from 'features/Systems';

import {
  JiraIntegrationsCurrent,
  JiraIntegrationsTableContainer,
} from './components';
import { fetchJiraIntegrationsRequest, resetJiraState } from './ducks/actions';
import styles from './JiraIntegrations.module.scss';
import { TableJiraIntegrationsTypes } from './types';

const MainTable = () => {
  return (
    <JiraIntegrationsTableContainer
      tableType={TableJiraIntegrationsTypes.FULL}
    />
  );
};

export const JiraIntegrations = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchJiraIntegrationsRequest());
    return () => {
      dispatch(resetJiraState());
      dispatch(resetSystemsState());
      dispatch(resetOrganizationState());
    };
  }, []);

  return (
    <TablesLayout
      MainTable={[MainTable]}
      SubTable={[JiraIntegrationsCurrent]}
      classNameRoot={styles.jiraIntegrations}
      classNameWrapperSubTable={styles.jiraIntegrations__subTablesWrapper}
    />
  );
};
