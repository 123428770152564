import { useEffect } from 'react';

export const useAutosizeTextArea = (
  textAreaRef: HTMLTextAreaElement | null,
  buttonRef: HTMLButtonElement | null,
  value?: string
) => {
  useEffect(() => {
    if (textAreaRef && buttonRef) {
      // eslint-disable-next-line no-param-reassign
      textAreaRef.style.height = '0px';
      const { scrollHeight } = textAreaRef;

      // eslint-disable-next-line no-param-reassign
      textAreaRef.style.height = `${scrollHeight}px`;
      // eslint-disable-next-line no-param-reassign
      buttonRef.style.height = `${scrollHeight + 16}px`;
    }
  }, [textAreaRef, value]);
};
