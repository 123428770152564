import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ActionList } from 'core/types/auth';
import { getIsClient, getUserId } from 'features/Auth';
import {
  getIsAccessedTicketAction,
  getTicket,
} from 'features/Ticket/ducks/ticket/selectors';

export const useIsAccessToChangeStatus = () => {
  const currentUserId = useSelector(getUserId);
  const isClient = useSelector(getIsClient);
  const ticket = useSelector(getTicket);
  const isAccessToWorkingWithMyGroupsTicketCards = useSelector(
    getIsAccessedTicketAction(ActionList.WorkingWithMyGroupsTicketCards)
  );

  const getIsMyTicket = useCallback(() => {
    if (isClient) {
      return currentUserId === ticket?.clientId?.key;
    }
    return currentUserId === ticket?.specialistId?.key;
  }, [
    isClient,
    currentUserId,
    ticket?.clientId?.key,
    ticket?.specialistId?.key,
  ]);

  return useMemo(
    () =>
      getIsMyTicket() ||
      (!getIsMyTicket() && isAccessToWorkingWithMyGroupsTicketCards),
    [getIsMyTicket, isAccessToWorkingWithMyGroupsTicketCards]
  );
};
