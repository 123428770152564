import cn from 'clsx';
import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { FolderIcon } from 'assets/icons';
import { IconBlock, Popover, RollUpButton, Tooltip } from 'components';
import { DeleteApproveOrCancel } from 'core/modals';
import { Article, CategoryArticle } from 'core/types/knowledgeBase';
import { useKBCategory } from 'features/KnowledgeBase/components/KBCategory/hooks';
import { useIsAccessEditCategoryOrArticle } from 'features/KnowledgeBase/hooks';
import { RouterHref } from 'routes/routerHref';

import { KBArticleDropProps } from '../KBArticleDrop';
import { KBArticleTitle } from '../KBArticleTitle';
import { KBCategoryDrop, KBCategoryDropProps } from '../KBCategoryDrop';

import { KBSubCategory } from './components';
import styles from './KBCategory.module.scss';

export interface KBCategoryProps {
  category: CategoryArticle;
  classNameSubCategory?: string;
  withDrop?: boolean;
  dropProps?: KBCategoryDropProps;
  articleDropProps?: KBArticleDropProps;
  showFullCategory?: boolean;
  isSubCategory?: boolean;
}

export const KBCategory: FC<KBCategoryProps> = ({
  category,
  classNameSubCategory,
  withDrop,
  dropProps,
  showFullCategory = false,
  articleDropProps,
  isSubCategory,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const { push } = useHistory();

  const { id, title, articles = [], children = [], organizations } = category;

  const { methods, state } = useKBCategory();
  const {
    togglePopover,
    onMouseEnter,
    onMouseLeave,
    toggleCategoryOpen,
    getListArticlesAndSubCategories,
  } = methods;
  const { isMobileAll, isDropOpen, isMouseOnTitle, isCategoryOpen } = state;

  const [isModal, setIsModal] = useState(false);

  const toggleModal = () => {
    setIsModal((prevState) => !prevState);
  };

  const canEditCategory = useIsAccessEditCategoryOrArticle(organizations);

  const { articlesList, categoriesList } = getListArticlesAndSubCategories(
    showFullCategory,
    children,
    articles
  );

  const needDrop = !isMobileAll && withDrop && canEditCategory;
  const showPopover = needDrop && (isMouseOnTitle || isDropOpen);
  const showAllButton =
    !isSubCategory &&
    (articles || children) &&
    !showFullCategory &&
    articles?.length + children?.length > 4;

  const onCategoryClick = () => {
    push(`${RouterHref.KnowledgeBase}/list/${id}`);
  };

  const handleDeleteCategory = () => {
    if (category?.id && dropProps?.onDeleteCategory) {
      dropProps.onDeleteCategory(category.id);
    }
  };

  const articlesListComponent = articlesList?.map((article: Article) => {
    return (
      <KBArticleTitle
        article={article}
        key={article?.id}
        withTooltip
        withDrop
        dropProps={articleDropProps}
        className={styles.KBCategory__articlesItem}
      />
    );
  });

  const subCategoriesListComponent = categoriesList?.map(
    (subCategory: CategoryArticle) => (
      <KBSubCategory
        key={subCategory?.id}
        category={subCategory}
        showFullCategory={showFullCategory}
        articleDropProps={articleDropProps}
        classNameSubCategory={classNameSubCategory}
        withDrop={withDrop}
        dropProps={dropProps}
      />
    )
  );

  const showAllButtonComponent = showAllButton && (
    <button
      onClick={onCategoryClick}
      className={styles.KBCategory__showAllButton}
    >
      Показать все
    </button>
  );

  const tooltipId = `${id}-${title}`;

  const tooltip = !isMobileAll && (
    <Tooltip id={tooltipId} className={styles.KBCategory__tooltip}>
      {title}
    </Tooltip>
  );

  const categoryTitle = (
    <>
      <IconBlock
        icon={<FolderIcon className={styles.KBCategory__icon} />}
        text={title}
        className={cn(styles.KBCategory__title, {
          [styles.KBCategory__title_full]: showFullCategory,
          [styles.KBCategory__title_subCategory]: isSubCategory,
        })}
        classNameText={styles.KBCategory__titleText}
        data-for={tooltipId}
        data-tip
      />
      {tooltip}
    </>
  );

  const categoryDrop = showPopover && (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      role="button"
      tabIndex={0}
      onKeyDown={undefined}
      className={styles.KBCategory__popover}
    >
      <Popover
        content={
          <KBCategoryDrop
            {...dropProps}
            category={category}
            toggleModal={toggleModal}
          />
        }
        isOpen={isDropOpen}
        withOutsideClick={false}
        togglePopover={togglePopover}
        positions={['right', 'left', 'top', 'bottom']}
        className={styles.KBCategory__popoverButton}
      />
    </div>
  );

  const showRollUpButton = Boolean(
    (showFullCategory && !isSubCategory) || (isSubCategory && articles.length)
  );

  const rollUpButton = showRollUpButton && (
    <RollUpButton isOpen={isCategoryOpen} toggleButton={toggleCategoryOpen} />
  );

  return (
    <>
      <div
        className={cn(styles.KBCategory, {
          [styles.KBCategory_isSubCategory]: isSubCategory,
          [classNameSubCategory || '']: isSubCategory,
          [styles.KBCategory_empty]: isSubCategory && !articles.length,
          [styles.KBCategory__rollUp]: !isCategoryOpen,
        })}
      >
        <div
          onClick={isSubCategory ? undefined : onCategoryClick}
          className={cn(styles.KBCategory__categoryButton, {
            [styles.KBCategory__categoryButton_withPadding]: needDrop,
          })}
          onMouseEnter={needDrop ? onMouseEnter : undefined}
          onMouseLeave={needDrop ? onMouseLeave : undefined}
          onKeyDown={undefined}
          role="button"
          tabIndex={0}
        >
          {rollUpButton}
          {categoryTitle}
          {categoryDrop}
        </div>
        <div
          className={cn(styles.KBCategory__articles, {
            [styles.KBCategory__articles_withMargin]: showFullCategory,
            [styles.KBCategory__articles_hidden]: !isCategoryOpen,
          })}
        >
          {articlesListComponent}
          {subCategoriesListComponent}
        </div>
        {showAllButtonComponent}
      </div>
      <DeleteApproveOrCancel
        isModal={isModal}
        toggleModal={toggleModal}
        entityTitle={isSubCategory ? 'подкатегорию' : 'категорию'}
        onApprove={handleDeleteCategory}
      />
    </>
  );
};
