import { FC } from 'react';

import { CrossIcon as CloseFilter } from 'assets/icons';
import { IconButton } from 'components';

import { TableBodyCell } from '../TableBodyCell';
import { TableRow } from '../TableRow';

import styles from './TableFilter.module.scss';

interface TableFilterProps {
  filterComponent: JSX.Element;
  onReset?: () => void;
  disableReset?: boolean;
  withCheckbox?: boolean;
}

export const TableFilter: FC<TableFilterProps> = ({
  filterComponent,
  onReset,
  disableReset,
  withCheckbox,
}) => {
  return (
    <form className={styles.filter}>
      <TableRow className={styles.filter__row} isFilter>
        {withCheckbox && <div className={styles.filter__emptyCell} />}
        {filterComponent}
        <TableBodyCell className={styles.filter__closeButtonCell}>
          <IconButton
            icon={<CloseFilter className={styles.filter__icon} />}
            appearance="flat"
            onClick={onReset}
            disabled={disableReset}
            className={styles.filter__closeButton}
          />
        </TableBodyCell>
      </TableRow>
    </form>
  );
};
