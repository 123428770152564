import { TicketStatus } from 'core/types';

export const getStandartTicketStatuses = () => {
  return new Set([
    TicketStatus.NEW,
    TicketStatus.WORK,
    TicketStatus.CLOSE,
    TicketStatus.PENDING_CLOSURE,
  ]);
};
