import cn from 'clsx';
import DOMPurify from 'dompurify';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  CalendarIcon,
  CheckMarkIcon,
  LabelIcon,
  ListRoundIcon,
} from 'assets/icons';
import {
  Card,
  ConditionBlock,
  InfoBlock,
  Loader,
  Solution,
  Typography,
  TypographyVariants,
} from 'components';
import { getIsTablet, getIsTabletSmall } from 'core/ducks/selectors';
import { TicketStatus } from 'core/types';
import { Ticket } from 'core/types/ticket';
import { getTicketStatusTitleMap } from 'core/utils';
import { getIsClient, getIsOrgTypePartner } from 'features/Auth';
import { SLA } from 'features/SLA';
import { fetchTicketRequest } from 'features/Tickets';
import { Languages } from 'i18n/types';
import { getFormatDateWithYear } from 'utils';

import {
  getClientInfoOrgTypeFromTickets,
  getCurrentTicket,
  getIsHideSLA,
  getTicketLoading,
} from '../../ducks/selectors';

import { CurrentTicketHeader } from './components';
import styles from './CurrentTicket.module.scss';

interface CurrentTicketProps {
  className?: string;
  ticket?: Ticket;
}

export const CurrentTicket: FC<CurrentTicketProps> = ({
  className,
  ticket,
}) => {
  const { i18n, t } = useTranslation('tickets');
  const { t: tGlobal } = useTranslation('global');

  const ticketCurrent = useSelector(getCurrentTicket);
  const loading = useSelector(getTicketLoading);
  const isTablet = useSelector(getIsTablet);
  const isTabletSmall = useSelector(getIsTabletSmall);
  const isClient = useSelector(getIsClient);
  const isPartner = useSelector(getIsOrgTypePartner);
  const clientInfo = useSelector(getClientInfoOrgTypeFromTickets);
  const isHideSLA = useSelector(getIsHideSLA(clientInfo, ticketCurrent));

  const dispatch = useDispatch();

  const ticketStatusStylesMap = useMemo(() => {
    const color = {
      [TicketStatus.CLOSE]: styles.currentTicket__ticketInfo_closed,
      [TicketStatus.PENDING_CLOSURE]:
        styles.currentTicket__ticketInfo_waitingToClose,
      [TicketStatus.NEW]: styles.currentTicket__ticketInfo_new,
      [TicketStatus.WORK]: styles.currentTicket__ticketInfo_inProcess,
      [TicketStatus.WAITING_INFO]: styles.currentTicket__ticketInfo_waitingInfo,
      [TicketStatus.REOPEN]: styles.currentTicket__ticketInfo_reopen,
    };
    return (
      ticket?.customStatus?.defaultStatus &&
      color[ticket.customStatus?.defaultStatus]
    );
  }, [ticket?.customStatus]);

  const isTickedClosed =
    ticket?.customStatus?.defaultStatus === TicketStatus.CLOSE;

  const isShowSLA = isPartner ? !isHideSLA : !isClient;

  const updateTicket = () => {
    if (ticket?.id) {
      dispatch(fetchTicketRequest(ticket.id));
    }
  };

  const solution = isTickedClosed && ticket.solution?.textSolution && (
    <Solution
      text={ticket.solution?.textSolution}
      classNameText={styles.currentTicket__solution}
    />
  );

  const sla = !isTickedClosed && ticket && isShowSLA && (
    <SLA
      ticket={ticket}
      updateTicket={updateTicket}
      size={isTablet || isTabletSmall ? 'small' : 'default'}
    />
  );

  if (!ticket && !loading) {
    return (
      <Card className={styles.currentTicket_empty}>
        <ListRoundIcon className={styles.currentTicket__emptyIcon} />
        <Typography
          variant={TypographyVariants.b2}
          className={styles.currentTicket_empty__message}
        >
          Нет данных
        </Typography>
      </Card>
    );
  }

  if (loading) {
    return (
      <Card className={cn(styles.currentTicket_empty, className)}>
        <Loader />
      </Card>
    );
  }

  return (
    <div className={cn(styles.currentTicket, className)}>
      <Card className={styles.currentTicket__header}>
        <CurrentTicketHeader ticket={ticket} />
      </Card>
      <Card className={styles.currentTicket__body}>
        <div className={styles.currentTicket__infoWrapper}>
          <ConditionBlock text={ticket?.ticketType?.value} />
          <Typography
            variant={TypographyVariants.h3}
            className={styles.currentTicket__subtitle}
          >
            {ticket?.theme}
          </Typography>
          <p
            className={cn('ck-content', styles.currentTicket__description)}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(ticket?.description || ''),
            }}
          />
        </div>
        <div className={styles.currentTicket__ticketInfo}>
          <InfoBlock
            mainTitle={t('status')}
            info={
              ticket?.customStatus?.defaultStatus
                ? getTicketStatusTitleMap(i18n.language as Languages)[
                    ticket?.customStatus.defaultStatus
                  ]
                : ticket?.customStatus?.name
            }
            icon={
              !isTickedClosed ? (
                <LabelIcon className={styles.currentTicket__icon} />
              ) : (
                <CheckMarkIcon className={styles.currentTicket__icon} />
              )
            }
            className={styles.currentTicket__ticketInfoItem}
            classNameInfo={styles.currentTicket__ticketInfoItemText}
            classNameIconWrapper={ticketStatusStylesMap}
            backgroundColor={
              !ticket?.customStatus?.defaultStatus
                ? ticket?.customStatus?.color
                : ''
            }
          />
          <InfoBlock
            mainTitle={tGlobal('created')}
            info={
              ticket?.dateCreate
                ? getFormatDateWithYear(ticket.dateCreate)
                : '-'
            }
            icon={<CalendarIcon style={{ color: 'var(--grey100)' }} />}
            className={styles.currentTicket__ticketInfoItem}
            classNameInfo={styles.currentTicket__ticketInfoItemText}
          />
        </div>
      </Card>
      <Card className={styles.currentTicket__footer}>
        {solution}
        {sla}
      </Card>
    </div>
  );
};
