import { UploadingStatus } from './types';

export const STORAGE_TABLE_FIELDS = [
  { title: 'Название', sortField: 'title', fieldName: 'title' },
  { title: 'Размер', sortField: 'size', fieldName: 'size' },
  { title: 'Загружено', sortField: 'date', fieldName: 'date' },
  { title: 'Загрузил', sortField: 'user', fieldName: 'user' },
  {
    title: 'Дополнительные файлы',
    sortField: 'additionalFiles',
    fieldName: 'additionalFiles',
  },
  {
    title: 'Описание',
    sortField: 'description',
    fieldName: 'description',
  },
];

export const MAX_FILE_SIZE = 50000000;

export const MODAL_TYPE = {
  add: true,
  edit: false,
};

export const UPLOADING_STATUS_TEXT = {
  [UploadingStatus.LOADED]: 'ЗАГРУЖЕН',
  [UploadingStatus.LOADING]: 'ЗАГРУЗКА',
  [UploadingStatus.QUEUE]: 'В ОЧЕРЕДИ',
};

export const radius = 19;
export const circumference = 2 * Math.PI * radius;
