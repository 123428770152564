import DOMPurify from 'dompurify';
import { FC } from 'react';

import { Card } from 'components';
import { Article } from 'core/types/knowledgeBase';

import { KBArticleViewingSearch } from './components';
import styles from './KBArticle.module.scss';

interface KBArticleProps {
  article?: Article;
}

export const KBArticle: FC<KBArticleProps> = ({ article }) => {
  return (
    <div className={styles.KBArticle__wrapper}>
      <Card className={styles.KBArticle}>
        <div
          className="ck-content"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(article?.text || ''),
          }}
        />
      </Card>
      <KBArticleViewingSearch className={styles.KBArticle__search} />
    </div>
  );
};
