import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { getSelectOption, getValueFromValueType, TabItem } from 'components';
import { ActionList } from 'core/types/auth';
import {
  getIsAccessedAction,
  getIsGroupSpecialist,
  getIsManagerClients,
  getUserOrganization,
} from 'features/Auth';

import {
  SLIDE_RADIO_TABS_FOR_ALL,
  SLIDE_RADIO_TABS_FOR_MANAGER,
  SLIDE_RADIO_TABS_FOR_ORG,
} from '../constants';
import {
  fetchTicketsRequest,
  fetchTicketsStatisticRequest,
  resetCurrentState,
} from '../ducks/CurrentState';
import {
  getCurrentStateTickets,
  getTicketsStatistic,
} from '../ducks/CurrentState/selectors';
import {
  downloadTicketsRequest,
  fetchOrganizationsRequest,
  fetchSystemsRequest,
  resetDesktopState,
  setDesktopFilters,
} from '../ducks/Desktop';
import { getIsDesktopTicketsLoading } from '../ducks/Desktop/selectors';
import { CurrentStateFormData } from '../types';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const useCurrentStateForm = () => {
  const dispatch = useDispatch();

  const [radioTabs, setRadioTabs] = useState<TabItem[]>([]);

  const isGroupSpecialist = useSelector(getIsGroupSpecialist);
  const isAccessToCreateReportByOrg = useSelector(
    getIsAccessedAction(ActionList.CreateReportByOrganization)
  );
  const isAccessToCreateReportByMyOrg = useSelector(
    getIsAccessedAction(ActionList.CreateReportByMyOrg)
  );

  const tickets = useSelector(getCurrentStateTickets);
  const ticketsStatistic = useSelector(getTicketsStatistic);
  const isDownload = useSelector(getIsDesktopTicketsLoading);
  const userOrganization = useSelector(getUserOrganization);
  const isManagerClients = useSelector(getIsManagerClients);

  const downloadTicketsData = ticketsStatistic?.downloadInfo;

  const { watch, control, resetField, setValue } =
    useForm<CurrentStateFormData>({
      mode: 'onChange',
      defaultValues: {
        infoType: 'workGroup',
      },
    });

  const { infoType, organizationId, systemId, workGroupId } = watch();

  const isOrganizationType = infoType === 'organization';

  const isReportClients = isAccessToCreateReportByMyOrg && isManagerClients;

  const isFetchDesktopFilters = isReportClients
    ? !!(organizationId && systemId)
    : !!(organizationId || systemId || workGroupId);

  useEffect(() => {
    if (
      (isGroupSpecialist && !isAccessToCreateReportByOrg) ||
      !isAccessToCreateReportByMyOrg
    ) {
      setRadioTabs(SLIDE_RADIO_TABS_FOR_MANAGER);
      setValue('infoType', 'workGroup');
    }
    if (
      (!isGroupSpecialist && isAccessToCreateReportByOrg) ||
      isAccessToCreateReportByMyOrg
    ) {
      setRadioTabs(SLIDE_RADIO_TABS_FOR_ORG);
      setValue('infoType', 'organization');
      if (isAccessToCreateReportByMyOrg && userOrganization) {
        setValue('organizationId', getSelectOption(userOrganization));
      }
    }
    if (isGroupSpecialist && isAccessToCreateReportByOrg) {
      setRadioTabs(SLIDE_RADIO_TABS_FOR_ALL);
      setValue('infoType', 'workGroup');
    }
  }, [
    isGroupSpecialist,
    isAccessToCreateReportByOrg,
    isAccessToCreateReportByMyOrg,
    userOrganization,
  ]);

  useEffect(() => {
    if (isOrganizationType) {
      dispatch(fetchOrganizationsRequest());
    }
  }, [isOrganizationType]);

  useEffect(() => {
    if (organizationId && !Array.isArray(organizationId)) {
      dispatch(fetchSystemsRequest(organizationId?.value));
    }
  }, [organizationId]);

  useEffect(() => {
    if (!organizationId && isReportClients && userOrganization) {
      setValue('organizationId', getSelectOption(userOrganization));
    }
  }, [organizationId, isReportClients, userOrganization]);

  useEffect(() => {
    if (isFetchDesktopFilters) {
      dispatch(
        setDesktopFilters({
          workgroupId: getValueFromValueType(workGroupId),
          organizationId: getValueFromValueType(organizationId),
          systemId: getValueFromValueType(systemId),
          onlyActualTickets: true,
        })
      );
      dispatch(fetchTicketsRequest());
      dispatch(fetchTicketsStatisticRequest());
    }
  }, [isFetchDesktopFilters, organizationId, systemId, workGroupId]);

  useEffect(() => {
    return () => {
      dispatch(resetCurrentState());
      dispatch(resetDesktopState());

      resetField('workGroupId');
      resetField('organizationId');
      resetField('systemId');
      resetField('infoType');
    };
  }, []);

  useEffect(() => {
    dispatch(resetCurrentState());
    dispatch(resetDesktopState());

    resetField('workGroupId');
    resetField('organizationId');
    resetField('systemId');
  }, [infoType]);

  const handleDownload = useCallback(() => {
    if (downloadTicketsData) {
      dispatch(
        downloadTicketsRequest({
          path: downloadTicketsData?.path,
          fileName: downloadTicketsData?.fileName,
        })
      );
    }
  }, [tickets, downloadTicketsData]);

  return {
    state: {
      radioTabs,
      isOrganizationType,
      tickets,
      ticketsStatistic,
      isDownload,
      isReportClients,
    },
    methods: {
      currentStateControl: control,
      handleDownload,
      setCurrentStateValue: setValue,
    },
  };
};
