import { CreateNoteData, Note } from 'features/Ticket/types';

import {
  DeleteNoteRequestAction,
  FetchNotesByTicketIdRequestAction,
  FetchNotesByTicketIdSuccessAction,
  NoteCreateRequestAction,
  NoteLoadingHideAction,
  NoteLoadingShowAction,
  Notes,
  ResetNotesStateAction,
  UpdateNoteRequestAction,
} from './types';

export const createNoteRequest = (
  note: CreateNoteData
): NoteCreateRequestAction => {
  return {
    type: Notes.NOTE_CREATE_REQUEST,
    payload: note,
  };
};

export const updateNoteRequest = (note: Note): UpdateNoteRequestAction => {
  return {
    type: Notes.NOTE_UPDATE_REQUEST,
    payload: note,
  };
};

export const deleteNoteRequest = (noteId: string): DeleteNoteRequestAction => {
  return {
    type: Notes.NOTE_DELETE_REQUEST,
    payload: noteId,
  };
};

export const noteLoadingShow = (): NoteLoadingShowAction => {
  return {
    type: Notes.NOTE_LOADING_SHOW,
  };
};

export const noteLoadingHide = (): NoteLoadingHideAction => {
  return {
    type: Notes.NOTE_LOADING_HIDE,
  };
};

export const fetchNotesByTicketIdRequest = (
  ticketId: string
): FetchNotesByTicketIdRequestAction => {
  return {
    type: Notes.FETCH_NOTES_BY_TICKET_ID_REQUEST,
    payload: ticketId,
  };
};

export const fetchNotesByTicketIdSuccess = (
  notes: Note[]
): FetchNotesByTicketIdSuccessAction => {
  return {
    type: Notes.FETCH_NOTES_BY_TICKET_ID_SUCCESS,
    payload: notes,
  };
};

export const resetNotesState = (): ResetNotesStateAction => {
  return {
    type: Notes.RESET_NOTES_STATE,
  };
};
