import cn from 'clsx';
import { FC } from 'react';

import {
  KBCategory,
  KBCategoryProps,
} from 'features/KnowledgeBase/components/KBCategory';

import styles from './KBSubCategory.module.scss';

interface KBSubCategoryProps extends KBCategoryProps {}

export const KBSubCategory: FC<KBSubCategoryProps> = ({
  category,
  dropProps,
  ...other
}) => {
  const { articles } = category;
  const hasArticles = Boolean(articles?.length);

  return (
    <div
      className={cn(styles.KBSubCategory, {
        [styles.KBSubCategory_withPadding]: !hasArticles,
      })}
    >
      <KBCategory
        category={category}
        dropProps={{ ...dropProps, isSubCategory: true }}
        {...other}
        isSubCategory
      />
    </div>
  );
};
