import { FC } from 'react';

import { T1Icon } from 'assets/icons';
import { Loader, Typography, TypographyVariants } from 'components';

import styles from './AuthLoading.module.scss';

export const AuthLoading: FC = () => (
  <div className={styles.authLoading}>
    <div className={styles.authLoading__header}>
      <T1Icon width={44} height={32} className={styles.authLoading__icon} />
      <Typography
        variant={TypographyVariants.d2}
        className={styles.authLoading__title}
      >
        SupPortal
      </Typography>
    </div>
    <Loader />
  </div>
);
