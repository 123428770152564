import { MainLayout } from 'core/layouts';
import { Header } from 'features/Desktop/components';
import { DesktopKpi as DesktopKpiContent } from 'features/Desktop/views';

const DesktopKpi = () => {
  return (
    <MainLayout customHeader={<Header />}>
      <DesktopKpiContent />
    </MainLayout>
  );
};

export default DesktopKpi;
