export const convertedTimeToMinutes = (time?: string | number) => {
  if (!time) {
    return 0;
  }
  const newTime = String(time);

  const convertedStr = newTime.split(':');
  const [hours, minutes] = convertedStr;

  return Number(hours) * 60 + Number(minutes);
};
