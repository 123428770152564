import { Contract } from 'core/types';
import { SLAType } from 'core/types/sla';
import { System } from 'core/types/system';
import { WorkGroup, WorkGroupFilterToRequest } from 'core/types/workGroup';
import { CreateSupplementaryAgreementBodyType } from 'features/SupplementaryAgreement/types';
import { ResponseWithMeta } from 'store/types';
import { Attachment, ParentType } from 'types/models/attachment';
import { ApiClient, getEnv } from 'utils';

import { endpoints } from './endpoints';

export const request = {
  fetchCurrentSupplementaryAgreement: (id: string) =>
    ApiClient.get<Contract>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getCurrentContract(id),
      data: {},
    }),
  deleteSupplementaryAgreement: (id: string) =>
    ApiClient.delete<boolean>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.deleteContract(id),
      data: {},
    }),
  fetchSystemById: (id: string) =>
    ApiClient.get<System>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getSystemByIdUrl(id),
    }),
  fetchSystemBySupAgId: (id: string) =>
    ApiClient.get<System[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getSystemBySupAgIdUrl(id),
    }),
  fetchSupAgAttachments: (contractId: string) =>
    ApiClient.get<Attachment[]>({
      baseURL: getEnv('REACT_APP_ATTACHMENT_URL'),
      url: endpoints.fetchAttachments(),
      params: {
        parent: ParentType.CONTRACT,
        id: contractId,
      },
    }),

  fetchGroupsByContractIdBySystemId: (
    contractId: string,
    systemId: string,
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: WorkGroupFilterToRequest
  ) =>
    ApiClient.post<ResponseWithMeta<WorkGroup[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getGroupsByContractIdBySystemId(contractId, systemId),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: { ...filter },
    }),
  fetchSLAByContractIdBySystemId: (contractId: string, systemId: string) =>
    ApiClient.get<SLAType>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getSLAByContractIdBySystemIdUrl(),
      params: { contract_id: contractId, system_id: systemId },
    }),
  getCreateSupAgNumber: (contractId: string) =>
    ApiClient.get<string>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getNumberSupAgUrl(contractId),
    }),
  createSupAg: (
    contractId: string,
    systemId: string,
    data: CreateSupplementaryAgreementBodyType
  ) =>
    ApiClient.post<Contract>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getCreateSupAgUrl(contractId, systemId),
      data,
    }),
};
