import { AccessedRoute } from 'core/types/auth';
import { User } from 'core/types/user';
import { WorkGroup } from 'core/types/workGroup';
import { ResponseWithMeta } from 'store/types';
import { ApiClient, getEnv } from 'utils';

import { endpoints } from './endpoints';

export const request = {
  fetchAccessedRoutes: () =>
    ApiClient.get<AccessedRoute[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getAccessedRoutes(),
    }),
  fetchActionList: () =>
    ApiClient.get<string[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getActionList(),
    }),
  fetchUsersWorkGroups: (userId: string) =>
    ApiClient.post<ResponseWithMeta<WorkGroup[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getUsersWorkGroups(userId),
      data: {},
    }),
  changePassword: (password: string) =>
    ApiClient.put<User>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getChangePasswordPath(),
      data: password,
      headers: {
        'Content-Type': 'text/plain',
      },
    }),
  fetchUser: () =>
    ApiClient.get<User>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getUser(),
    }),
  fetchManagerWorkGroups: (userId: string) =>
    ApiClient.get<string[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getUserManager(userId),
    }),
};
