import { Improvement, TicketEvaluation } from 'features/Ticket/types';
import { ApiClient, getEnv } from 'utils';

import { EvaluateSpecialistPayload } from '../types';

import { endpoints } from './endpoints';

export const request = {
  fetchImprovements: (rating: number) =>
    ApiClient.get<Improvement[]>({
      baseURL: getEnv('REACT_APP_KPI_URL'),
      url: endpoints.getImprovements(rating),
    }),
  evaluateSpecialist: (payload: EvaluateSpecialistPayload) =>
    ApiClient.post<Improvement[]>({
      baseURL: getEnv('REACT_APP_KPI_URL'),
      url: endpoints.evaluateSpecialist(payload),
      data: payload.improvementsIds,
    }),
  fetchTicketEvaluation: (ticketId: string) =>
    ApiClient.get<TicketEvaluation>({
      baseURL: getEnv('REACT_APP_KPI_URL'),
      url: endpoints.getTicketEvaluation(ticketId),
    }),
};
