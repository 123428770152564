import { FC } from 'react';

import {
  Avatar,
  FileChip,
  Size,
  Typography,
  TypographyVariants,
} from 'components';
import { Attachment } from 'types/models/attachment';
import { getAttachmentDate, getShortName } from 'utils';

import styles from './AttachmentDetail.module.scss';

interface AttachmentDetailProps {
  attachment?: Attachment;
}

export const AttachmentDetail: FC<AttachmentDetailProps> = ({ attachment }) => {
  const files = attachment?.attachmentsFiles?.map((item) => (
    <FileChip key={item.id} title={item.fileName} filePath={item.path} />
  ));

  return (
    <div className={styles.attachmentDetail}>
      <div className={styles.attachmentDetail__inner}>
        <div className={styles.attachmentDetail__data}>
          <div className={styles.attachmentDetail__userData}>
            <Avatar
              size={Size.xs}
              initialsUser={`${attachment?.userFirstName} ${attachment?.userLastName}`}
            />
            <Typography
              variant={TypographyVariants.b3}
              className={styles.attachmentDetail__shortName}
            >
              {getShortName(
                attachment?.userFirstName,
                attachment?.userLastName
              )}
            </Typography>
          </div>
          <div className={styles.attachmentDetail__files}>{files}</div>
        </div>
        <div className={styles.attachmentDetail__date}>
          <Typography
            variant={TypographyVariants.b3}
            className={styles.attachmentDetail__dateText}
          >
            {getAttachmentDate(attachment?.dateCreated || '')}
          </Typography>
        </div>
      </div>
    </div>
  );
};
