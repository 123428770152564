import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TablesLayout } from 'core/layouts';
import { TableContractsTypes } from 'core/types/contract';
import { TableSystemsTypes } from 'core/types/system';
import { fetchContractsRequest, resetContractsState } from 'features/Contracts';
import { resetOrganizationState } from 'features/Organizations';
import { SLATableShort } from 'features/SLA';
import { resetSystemsState, SystemsTableContainer } from 'features/Systems';

import { ContractsTableContainer } from './components';
import styles from './Contracts.module.scss';
import { getSLAContracts } from './ducks/selectors';

const MainTable = () => {
  return <ContractsTableContainer tableType={TableContractsTypes.FULL} />;
};

const SubTable = () => {
  return <SystemsTableContainer tableType={TableSystemsTypes.FROM_CONTRACTS} />;
};

const SLA = () => {
  const sla = useSelector(getSLAContracts);
  return <SLATableShort slaSettings={sla?.settings} />;
};

export const Contracts: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchContractsRequest());
    return () => {
      dispatch(resetContractsState());
      dispatch(resetSystemsState());
      dispatch(resetOrganizationState());
    };
  }, []);

  return (
    <TablesLayout
      MainTable={[MainTable]}
      classNameRoot={styles.contracts}
      SubTable={[SubTable, SLA]}
    />
  );
};
