import cn from 'clsx';
import {
  ButtonHTMLAttributes,
  FC,
  MouseEvent,
  SVGProps,
  useState,
} from 'react';

import { ArrowUpIcon } from 'assets/icons';

import { IconButton } from '../IconButton';
import { Popover } from '../Popover';
import { Ripple } from '../Ripple';
import { ButtonType, Size } from '../types';

import styles from './Button.module.scss';

const buttonStyles = {
  default: styles.button_default,
  flat: styles.button_flat,
  outline: styles.button_outline,
};

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  appearance?: ButtonType;
  icon?: SVGProps<SVGElement>;
  extraActionContent?: JSX.Element;
  size?: Size;
}

export const Button: FC<ButtonProps> = ({
  appearance = 'default',
  children,
  className,
  icon,
  disabled,
  extraActionContent,
  size = Size.s,
  onMouseDown,
  ...otherProps
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  const togglePopover = () => {
    setIsPopoverOpen((prevState) => !prevState);
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (onMouseDown) {
      onMouseDown(event);
    }
  };

  const button = (
    <button
      className={cn(
        styles.button,
        buttonStyles[appearance],
        styles[`button_${size}`],
        {
          [styles.button_withExtraAction]: extraActionContent,
          [`${className}`]: !extraActionContent,
        }
      )}
      onMouseDown={handleClick}
      disabled={disabled}
      {...otherProps}
    >
      {icon}
      <span>{children}</span>
      {!disabled && <Ripple appearance={appearance} />}
    </button>
  );

  if (extraActionContent) {
    return (
      <div
        className={cn(styles.wrapper, { [`${className}`]: extraActionContent })}
      >
        {button}
        <Popover
          togglePopover={togglePopover}
          isOpen={isPopoverOpen}
          content={extraActionContent}
          positions={['bottom', 'top', 'left', 'right']}
        >
          <IconButton
            onClick={togglePopover}
            className={styles.wrapper__iconButton}
            disabled={disabled}
            icon={<ArrowUpIcon />}
            size={Size.xxs}
          />
        </Popover>
      </div>
    );
  }

  return button;
};
