import cn from 'clsx';
import { ChangeEvent, FC, useEffect } from 'react';
import { Control, Controller, useForm, UseFormSetValue } from 'react-hook-form';

import { Clock12, QuestionIcon } from 'assets/icons';
import { Input, RadioSlide, Size, ToggleSwitch, Tooltip } from 'components';
import { CreateSystemDataAdd } from 'features/Contract/types';

import { AUTO_CLOSING_INFO, TIMES_SLIDE } from '../../constants';
import { checkIsTimeInSlide } from '../../utils';

import styles from './SlaAutoClosing.module.scss';

export interface SlaAutoClosingProps {
  isEditMode?: boolean;
  control: Control<CreateSystemDataAdd>;
  setValue: UseFormSetValue<CreateSystemDataAdd>;
  autoClosableTickets?: boolean;
  closeAfterHours?: number;
  className?: string;
  classNameToggleContainer?: string;
}

export const SlaAutoClosing: FC<SlaAutoClosingProps> = ({
  isEditMode,
  control,
  setValue,
  autoClosableTickets,
  closeAfterHours,
  className,
  classNameToggleContainer,
}) => {
  const {
    reset,
    setValue: setLocalValue,
    control: localControl,
  } = useForm<{
    closeAfterHoursInput?: number | string;
    closeAfterHoursSlide?: number;
  }>({
    mode: 'onChange',
    defaultValues: {
      closeAfterHoursInput: '',
      closeAfterHoursSlide: undefined,
    },
  });

  const setTimes = (time: number): void => {
    if (time !== 0) {
      const slideValue = checkIsTimeInSlide(time) ? time : undefined;
      const localValue = time.toString();

      setLocalValue('closeAfterHoursSlide', slideValue);
      setLocalValue('closeAfterHoursInput', localValue);
      setValue('closeAfterHours', time);
    } else {
      setLocalValue('closeAfterHoursInput', 0);
      setValue('closeAfterHours', 0);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTimes(+event.target.value);
  };

  useEffect(() => {
    if (autoClosableTickets) {
      const currentCloseAfterHours = closeAfterHours || 12;
      setValue('closeAfterHours', currentCloseAfterHours);
      setLocalValue('closeAfterHoursInput', currentCloseAfterHours);
      setLocalValue('closeAfterHoursSlide', currentCloseAfterHours);
    } else {
      setValue('closeAfterHours', 0);
      reset();
    }
  }, [autoClosableTickets, setValue, setLocalValue, reset]);

  return (
    <div className={cn(styles.slaAutoClosing, className)}>
      <div className={styles.slaAutoClosing__toggle}>
        <Controller
          control={control}
          name="expireReactionTimeInDecisionTime"
          render={({ field }) => {
            return (
              <ToggleSwitch
                label="Вычет времени"
                checked={!!field.value}
                id="showExpireSeconds"
                disabled={!isEditMode}
                onChange={field.onChange}
              />
            );
          }}
        />
        <Tooltip id="question">
          Вычет просрочки по реакции из времени решения
        </Tooltip>
        <QuestionIcon
          data-tip
          data-for="question"
          className={styles.slaAutoClosing__toggleIcon}
        />
      </div>
      <div
        className={cn(
          styles.slaAutoClosing__toggleContainer,
          classNameToggleContainer
        )}
      >
        <div className={styles.slaAutoClosing__toggle}>
          <Controller
            control={control}
            name="autoClosableTickets"
            render={({ field }) => {
              return (
                <ToggleSwitch
                  label="Автозакрытие"
                  checked={!!field.value}
                  onChange={field.onChange}
                  id="autoClosing"
                  disabled={!isEditMode}
                />
              );
            }}
          />
          <Tooltip id="autoClosing">{AUTO_CLOSING_INFO}</Tooltip>
          <QuestionIcon
            data-tip
            data-for="autoClosing"
            className={styles.slaAutoClosing__toggleIcon}
          />
        </div>
        <div
          className={cn(styles.slaAutoClosing__time, {
            [styles.slaAutoClosing__time_visible]: autoClosableTickets,
          })}
        >
          <Controller
            control={localControl}
            name="closeAfterHoursSlide"
            render={({ field }) => {
              return (
                <RadioSlide
                  size={Size.s}
                  items={TIMES_SLIDE}
                  value={field.value}
                  onChange={handleChange}
                  disabled={!isEditMode || !autoClosableTickets}
                  className={styles.slaAutoClosing__slide}
                />
              );
            }}
          />
          <div className={styles.slaAutoClosing__input}>
            <Controller
              control={localControl}
              name="closeAfterHoursInput"
              render={({ field }) => {
                return (
                  <Input
                    size={Size.s}
                    label="Вручную"
                    type="number"
                    value={field.value}
                    onChange={handleChange}
                    rightIcon={<Clock12 />}
                    disabled={!isEditMode}
                  />
                );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
