import { AdminsLayout } from 'core/layouts';
import { Users } from 'features/Users';

const AdminUsers = () => {
  return (
    <AdminsLayout>
      <Users />
    </AdminsLayout>
  );
};

export default AdminUsers;
