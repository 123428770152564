import { FC, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { BackArrowIcon, CloseIcon } from 'assets/icons';
import { Button, Drawer, Typography, TypographyVariants } from 'components';
import {
  useEnableFields,
  useFormDefaultValues,
} from 'features/TicketsExport/hooks';

import { ExportFields, ExportSubmit } from './components';
import {
  closeExportPanel,
  downloadExcelRequest,
  getTicketsCountRequest,
  resetTicketsExportState,
  setTicketsCount,
} from './ducks/actions';
import { getExtraExportFields, getTicketsCount } from './ducks/selectors';
import styles from './TicketsExport.module.scss';
import { UseFormExportData } from './types';
import { getCountFields, getTicketsRequestData } from './utils';

interface TicketsExportProps {
  isOpen?: boolean;
  toggleDrawer: () => void;
}

export const TicketsExport: FC<TicketsExportProps> = ({
  isOpen,
  toggleDrawer,
}) => {
  const dispatch = useDispatch();

  const [openCancelModal, setOpenCancelModal] = useState(false);

  const ticketsCount = useSelector(getTicketsCount);
  const extraExportFields = useSelector(getExtraExportFields);

  const {
    methods: { defaultClient, defaultPerformer },
  } = useFormDefaultValues();

  const { control, handleSubmit, reset, watch } = useForm<UseFormExportData>({
    mode: 'onChange',
    defaultValues: {
      status: [],
      priority: [],
      performer: defaultPerformer,
      client: defaultClient,
      dateSolve: '',
      dateCreation: '',
    },
  });

  const {
    state: { enableFields },
    methods: { handleChangeEnable },
  } = useEnableFields();

  const handleExportTickets = () => {
    dispatch(downloadExcelRequest());
  };

  const customFieldsIds = useMemo(
    () =>
      extraExportFields?.map((extraExportField) => extraExportField.id) || [],
    [extraExportFields]
  );

  const handleSearchCountTickets = handleSubmit((data) => {
    const ticketsRequestData = getTicketsRequestData({
      enableFields,
      data,
      customFields: customFieldsIds,
    });

    dispatch(getTicketsCountRequest(ticketsRequestData));
  });

  useEffect(() => {
    dispatch(setTicketsCount());
  }, [extraExportFields]);

  useEffect(() => {
    return () => {
      dispatch(resetTicketsExportState());
    };
  }, []);

  const handleClosePanel = () => {
    if (ticketsCount === undefined) {
      dispatch(closeExportPanel());
    } else {
      dispatch(setTicketsCount());
    }
    reset();
  };

  const handleOpenCancelModal = () => {
    if (ticketsCount === undefined) {
      dispatch(closeExportPanel());
      dispatch(setTicketsCount());
      reset();
    } else {
      setOpenCancelModal(true);
    }
  };

  const toggleCancelModal = () => {
    setOpenCancelModal((prev) => !prev);
  };

  return (
    <Drawer
      elevated
      isOpen={isOpen}
      onClose={toggleDrawer}
      approveOrCancelProps={{
        onApprove: handleClosePanel,
        isModal: openCancelModal,
        toggleModal: toggleCancelModal,
        text: 'Вы уверены, что хотите отменить экспорт данных?',
        description: 'Введенные правила фильтрации не сохранятся',
        withHideBodyScroll: false,
      }}
    >
      <div className={styles.ticketsExport}>
        <div className={styles.ticketsExport__container}>
          <div className={styles.ticketsExport__header}>
            <Typography
              variant={TypographyVariants.h3}
              className={styles.ticketsExport__headerTitle}
            >
              Экспорт данных Excel
            </Typography>
            <Button
              className={styles.ticketsExport__headerCloseButton}
              onClick={handleOpenCancelModal}
              icon={
                <CloseIcon className={styles.ticketsExport__headerCloseIcon} />
              }
            />
            <Button
              className={styles.ticketsExport__headerCloseButtonMobile}
              onClick={handleOpenCancelModal}
              icon={
                <BackArrowIcon
                  className={styles.ticketsExport__headerCloseIconMobile}
                />
              }
            />
          </div>
          <div className={styles.ticketsExport__content}>
            <ExportFields
              control={control}
              handleChange={handleChangeEnable}
              enableFields={enableFields}
            />
            <ExportSubmit
              countFields={getCountFields(watch())}
              handleSearch={handleSearchCountTickets}
              handleExport={handleExportTickets}
              handleClose={handleOpenCancelModal}
            />
          </div>
        </div>
      </div>
    </Drawer>
  );
};
