import {
  ConditionBlock,
  ConditionBlockProps,
  IconBlock,
  IconBlockProps,
  NotificationLink,
  NotificationLinkProps,
} from 'components';
import { RenderTypes } from 'components/Table/types';

const components = {
  [RenderTypes.NOTIFICATION_LINK]: (props: NotificationLinkProps) => (
    <NotificationLink {...props} />
  ),
  [RenderTypes.CONDITION]: (props: ConditionBlockProps) => (
    <ConditionBlock {...props} />
  ),
  [RenderTypes.ICON_BLOCK]: (props: IconBlockProps) => <IconBlock {...props} />,
};

export const getTableComponent = (componentType: RenderTypes) => {
  return components[componentType];
};
