import { Reducer } from 'redux';

import { ReducerMap } from 'store/types';
import { Attachment, AttachmentsFiles } from 'types/models/attachment';

import {
  Attachments,
  AttachmentsActionsTypes,
  FetchContractAttachmentsSuccessAction,
  FetchTicketAttachmentsSuccessAction,
  FetchTicketCommentsAttachmentsSuccessAction,
} from './types';

export interface AttachmentsReducerState {
  contractAttachments?: Attachment[];
  ticketAttachments?: Attachment[];
  ticketCommentsAttachments?: AttachmentsFiles[];
  loading?: boolean;
}

const initialState: AttachmentsReducerState = {
  ticketCommentsAttachments: [],
  loading: false,
};

const attachmentsReducerMap: ReducerMap<
  AttachmentsReducerState,
  AttachmentsActionsTypes
> = {
  [Attachments.ATTACHMENTS_LOADING_SHOW]: (state) => {
    return {
      ...state,
      loading: true,
    };
  },
  [Attachments.ATTACHMENTS_LOADING_HIDE]: (state) => {
    return {
      ...state,
      loading: false,
    };
  },
  [Attachments.FETCH_TICKET_ATTACHMENTS_SUCCESS]: (state, action) => {
    const { payload } = action as FetchTicketAttachmentsSuccessAction;
    return {
      ...state,
      ticketAttachments: payload,
    };
  },
  [Attachments.FETCH_CONTRACT_ATTACHMENTS_SUCCESS]: (state, action) => {
    const { payload } = action as FetchContractAttachmentsSuccessAction;
    return {
      ...state,
      contractAttachments: payload,
    };
  },
  [Attachments.FETCH_TICKET_COMMENTS_ATTACHMENTS_SUCCESS]: (state, action) => {
    const { payload } = action as FetchTicketCommentsAttachmentsSuccessAction;
    return {
      ...state,
      ticketCommentsAttachments: payload,
    };
  },
  [Attachments.RESET_ATTACHMENTS_STATE]: () => {
    return {
      ...initialState,
    };
  },
};

export const attachments: Reducer<
  AttachmentsReducerState,
  AttachmentsActionsTypes
> = (state = initialState, action) => {
  const reducer = attachmentsReducerMap[action.type];
  if (!reducer) {
    return state;
  }
  return reducer(state, action);
};
