import { getUrl } from 'utils';

export const endpoints = {
  getFiltersFields: () => getUrl('assigning/filter/form'),
  getRulesFields: () => getUrl('assigning/form'),
  getSpecialists: () => getUrl('assigning/specialists'),
  getWorkGroups: () => getUrl('assigning/workgroups'),
  getRules: () => getUrl('assigning/filter'),
  getCurrentRule: (id: string) => getUrl(`assigning/${id}`),
  createRule: () => getUrl('assigning'),
  editRule: (id: string) => getUrl(`assigning/${id}`),
  deleteRule: (id: string) => getUrl(`assigning/${id}`),
};
