import { PickedTime } from '../types';

import { addZeroIfOneDigit } from './addZeroIfOneDigit';

export const getTimeList = (listLength: number) => {
  return Array.from<unknown, PickedTime>(
    { length: listLength },
    (_, value) => ({
      value: addZeroIfOneDigit(value),
      valueForList: value,
      isActive: false,
    })
  );
};
