import { MainLayout } from 'core/layouts';
import { Storage } from 'features/Storage';
import { StorageHeader } from 'features/Storage/components';

const StoragePage = () => {
  return (
    <MainLayout customHeader={<StorageHeader />}>
      <Storage />
    </MainLayout>
  );
};

export default StoragePage;
