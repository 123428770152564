import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  deleteStorageFileCardRequest,
  downloadStorageFileRequest,
  fetchStorageFileCardsRequest,
  resetStorage,
  setPageNumStorage,
  setPageSizeStorage,
} from '../ducks';
import {
  getCustomFields,
  getLoading,
  getStorage,
  getStorageFiles,
  getStoragePageNum,
  getStoragePageSize,
} from '../ducks/selectors';
import { DownloadStorageFileRequest } from '../types';

import { useStorageId } from './useStorageId';

export const useStorageTable = () => {
  const dispatch = useDispatch();

  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [fileCardId, setFileCardId] = useState<string | undefined>();
  const [mainFileName, setMainFileName] = useState<string | undefined>('');
  const [modalType, setModalType] = useState({
    add: false,
    edit: false,
  });
  const [isCancelModal, setIsCancelModal] = useState(false);

  const storageId = useStorageId();

  const storageFiles = useSelector(getStorageFiles);
  const loading = useSelector(getLoading);
  const storage = useSelector(getStorage);
  const customFields = useSelector(getCustomFields);

  const pageSize = useSelector(getStoragePageSize);
  const pageNum = useSelector(getStoragePageNum);
  const elementsCount = storageFiles?.totalElements;

  const toggleAddModal = () => {
    setModalType({ add: true, edit: false });
    setIsOpenAddModal((prevState) => !prevState);
  };

  const toggleEditModal = () => {
    setModalType({ add: false, edit: true });
    setIsOpenAddModal((prevState) => !prevState);
  };

  const handleChangePage = (page: number) => {
    dispatch(setPageNumStorage(page));
  };

  const handleChangePageSize = (arg: number) => {
    dispatch(setPageSizeStorage(arg));
  };

  const downloadFile = (data: DownloadStorageFileRequest) => {
    dispatch(downloadStorageFileRequest(data));
  };

  const handleClickFileCard = (id: string) => {
    setFileCardId(id);

    const currentFileCard = storageFiles?.content.filter((el) => el.id === id);
    if (currentFileCard) {
      setMainFileName(currentFileCard[0].mainFile.fileName);
    }
  };

  const deleteStorage = () => {
    dispatch(
      deleteStorageFileCardRequest({
        storageId,
        fileCardId,
      })
    );

    dispatch(
      fetchStorageFileCardsRequest({
        id: storageId,
        pageNum,
        pageSize,
      })
    );
  };

  const toggleCancelModal = () => {
    setIsCancelModal(!isCancelModal);
  };

  const onApproveCancelModal = () => {
    deleteStorage();
    toggleCancelModal();
  };

  useEffect(() => {
    dispatch(
      fetchStorageFileCardsRequest({
        id: storageId,
        pageNum,
        pageSize,
      })
    );
  }, [storageId, pageNum, pageSize]);

  useEffect(() => {
    return () => {
      dispatch(resetStorage());
    };
  }, []);

  return {
    state: {
      isOpenAddModal,
      storageFiles,
      modalType,
      fileCardId,
      loading,
      isCancelModal,
      mainFileName,
      pageSize,
      pageNum,
      elementsCount,
      currentStorage: storage,
      customFields,
    },
    methods: {
      toggleAddModal,
      toggleEditModal,
      downloadFile,
      handleClickFileCard,
      toggleCancelModal,
      onApproveCancelModal,
      handleChangePage,
      handleChangePageSize,
    },
  };
};
