import { FC, useEffect } from 'react';

interface PageTitleLayoutProps {
  pageTitle?: string;
}

export const PageTitleLayout: FC<PageTitleLayoutProps> = ({
  children,
  pageTitle,
}) => {
  useEffect(() => {
    document.title = pageTitle || '';
  }, [pageTitle]);

  return <>{children}</>;
};
