import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AddLineIcon, CloseIcon } from 'assets/icons';
import { Popover, PopoverButton, PopoverContainer } from 'components';
import {
  checkIsExistPatternTitleRequestContractDetail,
  checkIsExistPatternTitleSuccessContractDetail,
} from 'features/Contract/ducks/actions';
import { getIsPatternTitleExist } from 'features/Contract/ducks/selectors';

import styles from './CreateSLAPopover.module.scss';
import { PopoverInner } from './PopoverInner';

interface CreateSLAPopoverProps {
  resetForm: () => void;
  onCreatePattern: (title: string) => void;
  isDataSLAValid?: boolean;
}

export const CreateSLAPopover: FC<CreateSLAPopoverProps> = ({
  resetForm,
  onCreatePattern,
  isDataSLAValid,
}) => {
  const dispatch = useDispatch();

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const isExistPatternTitle = useSelector(getIsPatternTitleExist);
  const isValidDataForPattern = !isExistPatternTitle && isDataSLAValid;

  useEffect(() => {
    if (!isPopoverOpen) {
      dispatch(checkIsExistPatternTitleSuccessContractDetail(false));
    }
  }, [isPopoverOpen]);

  const onChangePatternTitle = (title?: string) => {
    if (title) {
      dispatch(checkIsExistPatternTitleRequestContractDetail(title));
    }
  };

  const handleCreatePattern = (title: string) => {
    if (isValidDataForPattern && title) {
      onCreatePattern(title);
    }
    setIsPopoverOpen(false);
  };

  const createPatternInnerPopover = (
    <PopoverInner
      disable={!isValidDataForPattern}
      formSubmitHandler={handleCreatePattern}
      onChange={onChangePatternTitle}
      isError={isExistPatternTitle}
      errorText="Шаблон с таким названием уже существует"
      label="Название шаблона"
    />
  );

  return (
    <PopoverContainer className={styles.popoverDownContent}>
      <Popover
        togglePopover={() => setIsPopoverOpen((prev) => !prev)}
        isOpen={isPopoverOpen}
        content={createPatternInnerPopover}
        positions={['left']}
        align="start"
        elevated
      >
        <PopoverButton
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          icon={<AddLineIcon className={styles.popoverDownContent__iconPlus} />}
        >
          Сохранить как шаблон
        </PopoverButton>
      </Popover>
      <PopoverButton
        onClick={resetForm}
        icon={<CloseIcon className={styles.popoverDownContent__iconClose} />}
      >
        Очистить все
      </PopoverButton>
    </PopoverContainer>
  );
};
