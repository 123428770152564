import cn from 'clsx';
import { DragEvent, FC, InputHTMLAttributes } from 'react';

import { DragIcon } from 'assets/icons';
import { Checkbox } from 'components';

import { SettingField as Field } from '../../types';

import styles from './SettingField.module.scss';

export interface SettingFieldProps
  extends InputHTMLAttributes<HTMLInputElement> {
  field: Field;
  dragStartHandler: (event: DragEvent<HTMLDivElement>, card: Field) => void;
  dropHandler: (event: DragEvent<HTMLDivElement>, field: Field) => void;
  disabledField?: string;
}

export const SettingField: FC<SettingFieldProps> = ({
  field,
  dragStartHandler,
  dropHandler,
  onChange,
  disabledField,
}) => {
  const isDisabled = field.name === disabledField;

  return (
    <div
      className={cn(styles.settingField, {
        [styles.settingField_disabled]: isDisabled,
      })}
      draggable
      onDragStart={(event) => dragStartHandler(event, field)}
      onDragOver={(event) => event.preventDefault()}
      onDrop={(event) => dropHandler(event, field)}
    >
      {!isDisabled && <DragIcon className={styles.settingField__drag} />}
      <Checkbox
        onChange={onChange}
        label={field.name}
        checked={!field.hidden}
        disabled={isDisabled}
      />
    </div>
  );
};
