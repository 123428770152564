import cn from 'clsx';
import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import {
  Select,
  ToggleSwitch,
  Typography,
  TypographyVariants,
} from 'components';
import { getManagerWorkGroupsList } from 'features/Auth';

import { DEFAULT_VALUES_WORK_GROUPS_FORM } from '../../constants';
import { updateNotificationSettingsRequest } from '../../ducks/actions';
import { getNotificationSettingsLocale } from '../../ducks/selectors';
import { WorkGroupsData } from '../../types';

import styles from './GroupSpecialistForm.module.scss';

interface GroupSpecialistFormProps {
  className?: string;
}

export const GroupSpecialistForm: FC<GroupSpecialistFormProps> = ({
  className,
}) => {
  const managerWorkGroupsOptions = useSelector(getManagerWorkGroupsList);
  const notificationsSettingsLocale = useSelector(
    getNotificationSettingsLocale
  );

  const dispatch = useDispatch();

  const { control, watch, setValue } = useForm<WorkGroupsData>({
    mode: 'onChange',
    defaultValues: DEFAULT_VALUES_WORK_GROUPS_FORM,
  });

  const { withNotification } = watch();

  useEffect(() => {
    setValue(
      'withNotification',
      !!notificationsSettingsLocale?.workgroupsNotificationIsEnable
    );
    setValue(
      'workGroupsIds',
      notificationsSettingsLocale?.workgroupsNotifications?.map(
        (workGroup) => ({ title: workGroup.value, value: workGroup.key })
      ) || []
    );
  }, [
    notificationsSettingsLocale?.workgroupsNotificationIsEnable,
    notificationsSettingsLocale?.workgroupsNotifications,
  ]);

  const workGroupsSelect = withNotification && (
    <>
      <Typography
        variant={TypographyVariants.b3}
        className={styles.groupSpecialistForm__subTitle}
      >
        Вы будете получать уведомления рабочих групп указанных ниже
      </Typography>
      <Controller
        control={control}
        name="workGroupsIds"
        render={({ field }) => {
          return (
            <Select<string>
              options={managerWorkGroupsOptions}
              value={field.value}
              onChange={(event) => {
                field.onChange(event);
                if (notificationsSettingsLocale) {
                  dispatch(
                    updateNotificationSettingsRequest({
                      ...notificationsSettingsLocale,
                      workgroupsNotifications: Array.isArray(event)
                        ? event.map((workGroup) => ({
                            key: workGroup.value,
                            value: workGroup.title,
                          }))
                        : [],
                    })
                  );
                }
              }}
              mobileModalTitle="рабочие группы"
              isMulti
              isChip
              label="Рабочие группы"
              dropdownContentClassName={styles.groupSpecialistForm__selectMenu}
            />
          );
        }}
      />
    </>
  );

  return (
    <div className={cn(styles.groupSpecialistForm, className)}>
      <div className={styles.groupSpecialistForm__switchWrapper}>
        <Controller
          control={control}
          name="withNotification"
          render={({ field }) => {
            return (
              <ToggleSwitch
                label="Получать уведомления рабочих групп"
                checked={field.value}
                onChange={(event) => {
                  field.onChange(event);
                  if (notificationsSettingsLocale) {
                    dispatch(
                      updateNotificationSettingsRequest({
                        ...notificationsSettingsLocale,
                        workgroupsNotificationIsEnable: event.target.checked,
                      })
                    );
                  }
                }}
                classNameLabel={styles.groupSpecialistForm__switchText}
              />
            );
          }}
        />
      </div>
      {workGroupsSelect}
    </div>
  );
};
