import { format } from 'date-fns';

import { News } from 'features/Profile/types';

export const splitByDateNews = (news: Array<News>) => {
  const result: { [key: string]: Array<News> } = {};
  news.forEach((item) => {
    const objKey = `${format(new Date(item?.created || ''), 'yyyy/MM/dd')}`;
    if (result[objKey]) {
      result[objKey] = [...result[objKey], item];
      return;
    }
    if (objKey) {
      result[objKey] = [item];
    }
  });
  return result;
};
