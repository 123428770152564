import cn from 'clsx';
import { ButtonHTMLAttributes, FC, SVGProps } from 'react';

import styles from './IconButtonWrapper.module.scss';

interface IconButtonWrapperProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: SVGProps<SVGElement>;
}

export const IconButtonWrapper: FC<IconButtonWrapperProps> = ({
  className,
  type,
  icon,
  ...otherProps
}) => {
  return (
    <button
      type={type}
      className={cn(styles.iconButton, className)}
      {...otherProps}
    >
      {icon}
    </button>
  );
};
