import { debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Select, Size } from 'components';
import {
  DEFAULT_DEBOUNCE_DELAY,
  ON_FILTER_DEBOUNCE_DELAY,
  PAGE_SIZE_SEARCH_SELECT,
} from 'constants/meta';
import { useInfiniteScroll } from 'hooks';
import { ObjectType } from 'types/models/meta';

import { SearchSelectProps } from './types';

export const SearchSelect = <T extends ObjectType, K = string>({
  size = Size.xs,
  dataPagination,
  resetState,
  fetchRequest,
  filter,
  triggerFetchField = '',
  ...other
}: SearchSelectProps<T, K>) => {
  const dispatch = useDispatch();
  const isInitialRender = useRef(true);
  const [currentValueFilter, setCurrentValueFilter] = useState('');
  const { totalElements, pageNum, pageSize } = dataPagination;

  const { totalPage, infiniteScrollable, setInfiniteScrollable } =
    useInfiniteScroll({
      pageNum,
      pageSize,
      totalElements,
    });

  const fetchData = debounce(({ value, updateType, page }) => {
    dispatch(
      fetchRequest({
        updateType,
        pageNum: page,
        pageSize: pageSize || PAGE_SIZE_SEARCH_SELECT,
        searchValue: value,
        filter,
      })
    );
    setInfiniteScrollable(true);
  }, DEFAULT_DEBOUNCE_DELAY);

  useEffect(() => {
    if (isInitialRender.current || filter?.[triggerFetchField]) {
      fetchData({
        page: pageNum,
        value: '',
        updateType: 'update',
      });
    }

    return () => {
      resetState();
    };
  }, [filter?.[triggerFetchField]]);

  useEffect(() => {
    isInitialRender.current = false;
  }, []);

  const setNextPage = (page: number) => {
    fetchData({ value: '', updateType: 'join', page });
  };

  const onFilter = debounce((valueFilter: string) => {
    setCurrentValueFilter(valueFilter);
    fetchData({
      page: pageNum,
      value: valueFilter,
      updateType: 'update',
    });
  }, ON_FILTER_DEBOUNCE_DELAY);

  return (
    <Select<K>
      {...other}
      size={size}
      onChangeInput={onFilter}
      currentPage={pageNum}
      totalPage={totalPage}
      setNextPage={currentValueFilter ? undefined : setNextPage}
      infiniteScrollable={infiniteScrollable}
      isSearchable
      isMulti
      isClearable
    />
  );
};
