import { JiraStatus, Status } from '../types';

import { getJiraStatus } from './getJiraStatus';

type Args = {
  statusMapOptions: Status[];
  jiraStatusesBySystemId: JiraStatus[];
};

export const getStatusesByOrder = ({
  statusMapOptions,
  jiraStatusesBySystemId,
}: Args) => {
  const statusesByOrder = statusMapOptions?.map((status) => {
    return {
      ...jiraStatusesBySystemId.find((item) =>
        item.ticketStatusIds?.find((element) => element.id === status.id)
      ),
    };
  });

  return statusMapOptions?.map((status) => {
    return {
      ...getJiraStatus(statusesByOrder, status),
      ticketStatusIds: [status],
    };
  });
};
