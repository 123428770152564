import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { getScreenWidth } from 'core/ducks/selectors';
import { ScreenWidth } from 'core/types';
import { ActionList } from 'core/types/auth';
import { getIsAccessedAction } from 'features/Auth';
import {
  fetchArticlesRequest,
  resetArticlesAll,
  resetSelectedArticlesIds,
  setIsModal,
} from 'features/UserAssistance/ducks';
import {
  getArticles,
  getArticlesAllLoading,
  getArticlesAllUniq,
  getArticlesLoading,
  getIsModal,
  getSelectedArticlesIds,
} from 'features/UserAssistance/ducks/selectors';
import { SearchFormData } from 'features/UserAssistance/types';

export const useUserAssistance = () => {
  const dispatch = useDispatch();

  const [isHidden, setIsHidden] = useState<boolean>(false);
  const [isAddMode, setIsAddMode] = useState<boolean>(false);
  const [isDetailModal, setIsDetailModal] = useState<boolean>(false);

  const isModal = useSelector(getIsModal);
  const articles = useSelector(getArticles);
  const articlesAll = useSelector(getArticlesAllUniq);
  const articlesLoading = useSelector(getArticlesLoading);
  const articlesAllLoading = useSelector(getArticlesAllLoading);
  const isAccessToAddArticleToTheHelp = useSelector(
    getIsAccessedAction(ActionList.AddingArticleToTheHelp)
  );
  const selectedArticlesIds = useSelector(getSelectedArticlesIds);
  const screenWidth = useSelector(getScreenWidth);

  const isMobile = screenWidth === ScreenWidth.MOBILE;
  const isMobileSmall = screenWidth === ScreenWidth.MOBILE_SMALL;
  const isMobileAll = isMobile || isMobileSmall;

  const toggleIsModal = () => {
    dispatch(setIsModal(!isModal));
  };

  const toggleIsDetailModal = () => {
    setIsDetailModal((prevState) => !prevState);
  };

  const toggleIsHidden = () => {
    setIsHidden((prevState) => !prevState);
  };

  const toggleIsAddMode = () => {
    setIsAddMode((prevState) => !prevState);
    if (isHidden) {
      toggleIsHidden();
    }
  };

  const { register, handleSubmit, reset } = useForm<SearchFormData>({
    mode: 'onChange',
    defaultValues: {
      text: '',
    },
  });

  const onReset = () => {
    reset();
    dispatch(resetArticlesAll());
    dispatch(resetSelectedArticlesIds());
  };

  useEffect(() => {
    if (isModal && !isHidden) {
      document.body.style.overflow = 'hidden';
      return;
    }
    document.body.style.overflow = 'visible';
  }, [isModal, isHidden]);

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);

  useEffect(() => {
    if (!isModal) {
      if (isHidden) {
        setIsHidden(false);
      }
      if (isAddMode) {
        setIsAddMode(false);
      }
    }
  }, [isModal, isHidden]);

  useEffect(() => {
    if (isModal && !isAddMode) {
      dispatch(fetchArticlesRequest());
    }
  }, [isModal, isAddMode]);

  useEffect(() => {
    if (isModal) {
      setIsDetailModal(false);
    }
  }, [isModal]);

  return {
    methods: {
      toggleIsAddMode,
      onReset,
      toggleIsModal,
      register,
      handleSubmit,
      toggleIsHidden,
      toggleIsDetailModal,
    },
    state: {
      articles,
      articlesAll,
      articlesLoading,
      articlesAllLoading,
      isHidden,
      isMobileAll,
      isAccessToAddArticleToTheHelp,
      selectedArticlesIds,
      isAddMode,
      isModal,
      isDetailModal,
    },
  };
};
