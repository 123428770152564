import { debounce } from 'lodash';
import { FC, useEffect, useMemo } from 'react';
import { UseFormHandleSubmit, UseFormRegister } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { BackArrowIcon, SearchIcon } from 'assets/icons';
import { Input, Typography, TypographyVariants } from 'components';
import { DEFAULT_DEBOUNCE_DELAY } from 'constants/meta';
import {
  fetchArticlesAllRequest,
  resetArticlesAll,
  resetSelectedArticlesIds,
} from 'features/UserAssistance/ducks';
import { SearchFormData } from 'features/UserAssistance/types';

import styles from './UserAssistanceSearch.module.scss';

interface UserAssistanceSearchProps {
  toggleIsAddMode(): void;
  register: UseFormRegister<SearchFormData>;
  handleSubmit: UseFormHandleSubmit<SearchFormData>;
  onReset(): void;
}

export const UserAssistanceSearch: FC<UserAssistanceSearchProps> = ({
  toggleIsAddMode,
  register,
  handleSubmit,
  onReset,
}) => {
  const dispatch = useDispatch();

  const formSubmitHandler = handleSubmit(({ text }) => {
    if (text.length >= 3) {
      dispatch(fetchArticlesAllRequest(text));
    }
    dispatch(resetArticlesAll());
  });

  const formSubmitHandlerDebounced = useMemo(
    () => debounce(formSubmitHandler, DEFAULT_DEBOUNCE_DELAY),
    []
  );

  const textInputOptions = register('text', {
    onChange: formSubmitHandlerDebounced,
  });

  const goBack = () => {
    toggleIsAddMode();
    onReset();
  };

  useEffect(() => {
    return () => {
      dispatch(resetArticlesAll());
      dispatch(resetSelectedArticlesIds());
    };
  }, []);

  return (
    <div className={styles.userAssistanceSearch}>
      <Typography
        variant={TypographyVariants.h3}
        className={styles.userAssistanceSearch__title}
      >
        Добавить статью
      </Typography>
      <button
        onClick={goBack}
        className={styles.userAssistanceSearch__arrowButton}
      >
        <BackArrowIcon />
        <Typography variant={TypographyVariants.b2}>Назад</Typography>
      </button>
      <Input
        {...textInputOptions}
        label="Поиск"
        className={styles.userAssistanceSearch__searchInput}
        rightIcon={<SearchIcon />}
      />
    </div>
  );
};
