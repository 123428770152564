import { FC } from 'react';
import { UseFormRegister } from 'react-hook-form';

import { Delete2Icon } from 'assets/icons';
import { Button, IconButton, Input, Size } from 'components';
import { CreateCustomFieldData } from 'features/CustomFields/types';

import styles from './CustomFieldsList.module.scss';

interface CustomFieldsListProps {
  register: UseFormRegister<CreateCustomFieldData>;
  values?: string[];
  add: () => void;
  remove: (index: number) => void;
  addDisabled?: boolean;
  deleteDisabled?: boolean;
}

export const CustomFieldsList: FC<CustomFieldsListProps> = ({
  register,
  values,
  add,
  remove,
  addDisabled,
  deleteDisabled,
}) => {
  return (
    <div className={styles.customFieldsList}>
      {values?.map((_, index) => {
        const label = `Значение ${index + 1}`;

        const deleteField = () => {
          remove(index);
        };

        return (
          <div key={label} className={styles.customFieldsList__inputs}>
            <Input
              label={label}
              {...register(`values.${index}`, {
                required: true,
                minLength: 1,
              })}
            />
            <IconButton
              appearance="flat"
              onClick={deleteField}
              icon={<Delete2Icon />}
              disabled={deleteDisabled}
              className={styles.customFieldsList__button_delete}
              size={Size.xxs}
            />
          </div>
        );
      })}
      <Button
        type="button"
        onClick={add}
        size={Size.xs}
        appearance="flat"
        disabled={addDisabled}
      >
        Добавить значение
      </Button>
    </div>
  );
};
