import { Priority } from 'core/types';
import { SLARowType, SLASettings, SLATableDataType } from 'core/types/sla';
import { convertedTimeToMinutes } from 'core/utils';

const prepareTime = (value?: string | number) => {
  if (value) {
    return Number.isNaN(Number(value)) ? convertedTimeToMinutes(value) : value;
  }
  return 0;
};

const prepareSettings = (data: SLASettings): SLASettings => {
  return {
    ...data,
    decisionTime: prepareTime(data?.decisionTime),
    reactionTime: prepareTime(data?.reactionTime),
  };
};

const prepareRow = (row: SLARowType) => {
  const newRow: SLARowType = {};
  Object.keys(row).forEach((ticketType) => {
    newRow[ticketType] = prepareSettings(row[ticketType]);
  });
  return newRow;
};

export const prepareSLATableForRequest = (sla: SLATableDataType) => {
  const result = {} as SLATableDataType;
  Object.keys(sla).forEach((priority) => {
    result[priority as Priority] = prepareRow(sla[priority as Priority]);
  });
  return result;
};
