import cn from 'clsx';
import { FC, Fragment } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Link } from 'react-router-dom';

import { BookIcon } from 'assets/icons';
import { Card, Loader, Typography, TypographyVariants } from 'components';
import { DataEmpty } from 'core/components';
import { EmptyDataType } from 'core/components/DataEmpty/types';
import { Article } from 'core/types/knowledgeBase';
import {
  KBArticlesSearch,
  KBCategoriesHeader,
  KBCategoriesList,
  KBCategory,
  renderArticleFunction,
} from 'features/KnowledgeBase/components';
import { useKBTableArticlesSearch } from 'features/KnowledgeBase/views/KBTable/hooks';
import { RouterHref } from 'routes/routerHref';

import styles from './KBTableArticlesSearch.module.scss';

interface KBTableArticlesSearchProps {
  className?: string;
}

export const KBTableArticlesSearch: FC<KBTableArticlesSearchProps> = ({
  className,
}) => {
  const { methods, state } = useKBTableArticlesSearch();
  const { setNextPageSystem, onCreateCategory } = methods;
  const {
    actionsSearchProps,
    selectorsSearchProps,
    actionsOrganizationsProps,
    selectOrganizationsProps,
    categoryDropProps,
    valuesProps,
    articleDropProps,
    widthDesktop,
    articles,
    categories,
    loadingCategories,
    hasMore,
    organization,
    searchValue,
    loadingArticles,
  } = state;

  const categoriesList = categories?.map((category) => (
    <KBCategory
      category={category}
      classNameSubCategory={styles.KBTableArticlesSearch__category}
      key={category?.id}
      showFullCategory
      dropProps={categoryDropProps}
      articleDropProps={articleDropProps}
      withDrop
    />
  ));

  const emptyComponent = (
    <DataEmpty
      type={EmptyDataType.NO_DATA_WITH_FILTER}
      className={styles.KBTableArticlesSearch__noData}
    />
  );

  const showEmptyContent =
    organization || !searchValue
      ? !categories?.length && !loadingCategories
      : !articles.length && !loadingArticles;

  const showLoader =
    !showEmptyContent &&
    ((loadingArticles && !articles.length) || loadingCategories);

  const categoriesComponent = (
    <KBCategoriesList
      classNameList={styles.KBTableArticlesSearch__categoriesList}
      categories={categoriesList}
      loading={loadingCategories}
    />
  );

  const articlesList = (
    <div className={styles.KBTableArticlesSearch__articles}>
      <InfiniteScroll
        hasMore={hasMore}
        loadMore={setNextPageSystem}
        initialLoad={false}
        useWindow={!widthDesktop}
        loader={
          <Loader className={styles.KBTableArticlesSearch__articlesLoader} />
        }
        threshold={20}
      >
        {articles.map((article: Article) => (
          <Fragment key={article.id}>{renderArticleFunction(article)}</Fragment>
        ))}
      </InfiniteScroll>
    </div>
  );

  const searchContent =
    organization || !searchValue ? categoriesComponent : articlesList;

  return (
    <Card className={cn(styles.KBTableArticlesSearch, className)}>
      <KBCategoriesHeader
        clearWhenIsInputValue
        valuesProps={valuesProps}
        selectorProps={selectOrganizationsProps}
        actionsProps={actionsOrganizationsProps}
        onCreateCategory={onCreateCategory}
      />
      <div className={styles.KBTableArticlesSearch__inner}>
        <Link
          to={RouterHref.KnowledgeBase}
          className={styles.KBTableArticlesSearch__link}
        >
          <BookIcon />
          <Typography
            variant={TypographyVariants.h4}
            className={styles.KBTableArticlesSearch__startTitle}
          >
            Начало
          </Typography>
        </Link>
        <KBArticlesSearch
          withDropDown={false}
          clearWhenOrganizationSelected
          needInitialOptions={false}
          selectorsProps={selectorsSearchProps}
          actionsProps={actionsSearchProps}
        />
      </div>
      {showEmptyContent && emptyComponent}
      {showLoader && (
        <Loader classNameRoot={styles.KBTableArticlesSearch__loader} />
      )}
      {!showLoader && !showEmptyContent && searchContent}
    </Card>
  );
};
