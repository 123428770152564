import { useCallback, useLayoutEffect, useRef } from 'react';

import { VoidFunc } from 'components-new/types';

export const useEvent = <T extends VoidFunc<Parameters<T>>>(fn: T) => {
  const fnRef = useRef<T>();

  useLayoutEffect(() => {
    fnRef.current = fn;
  }, [fn]);

  return useCallback(
    (...args: Parameters<T>) => {
      return fnRef.current?.apply(null, args);
    },
    [fnRef]
  );
};
