import cn from 'clsx';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  ArrowHorizontalIcon,
  CheckMarkIcon,
  CloseIcon,
  DeleteIcon,
  Pen2Icon,
} from 'assets/icons';
import { Button, OutsideClickHandler, RoundButton } from 'components';
import { getIsMobile, getIsMobileSmall } from 'core/ducks/selectors';

import styles from './JiraIntegrationsListElement.module.scss';

interface JiraIntegrationsListElementProps {
  leftElement?: JSX.Element;
  leftElementEdit?: JSX.Element;
  rightElement?: JSX.Element;
  rightElementEdit?: JSX.Element;
  isEditable?: boolean;
  initialIsEdit?: boolean;
  onCloseEdit?(): void;
  onSuccessEdit?(): void;
  onDelete?(): void;
  isSuccessEditDisabled?: boolean;
  onEditModeOn?(): void;
}

export const JiraIntegrationsListElement: FC<
  JiraIntegrationsListElementProps
> = ({
  leftElement,
  rightElement,
  leftElementEdit,
  rightElementEdit,
  isEditable,
  initialIsEdit,
  onCloseEdit,
  onSuccessEdit,
  onDelete,
  isSuccessEditDisabled,
  onEditModeOn,
}) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(!!initialIsEdit);

  const isMobile = useSelector(getIsMobile);
  const isMobileSmall = useSelector(getIsMobileSmall);
  const isMobileAll = isMobile || isMobileSmall;

  const editHandler = () => {
    setIsEditMode(true);
  };

  const closeEditHandler = () => {
    if (onCloseEdit) {
      onCloseEdit();
    }
    setIsEditMode(false);
  };

  const successEditHandler = () => {
    if (onSuccessEdit) {
      onSuccessEdit();
    }
    setIsEditMode(false);
  };

  const deleteHandler = () => {
    if (onDelete) {
      onDelete();
    }
    setIsEditMode(false);
  };

  useEffect(() => {
    if (isEditMode && onEditModeOn) {
      onEditModeOn();
    }
  }, [isEditMode]);

  const buttons = !isEditMode ? (
    <>
      <RoundButton
        icon={<DeleteIcon />}
        onClick={deleteHandler}
        className={styles.jiraIntegrationsListElement__button_delete}
      />
      <RoundButton
        icon={<Pen2Icon />}
        onClick={editHandler}
        className={styles.jiraIntegrationsListElement__button_edit}
      />
    </>
  ) : (
    <>
      <RoundButton
        icon={<CloseIcon />}
        onClick={closeEditHandler}
        className={styles.jiraIntegrationsListElement__button_close}
      />
      <RoundButton
        onClick={successEditHandler}
        icon={<CheckMarkIcon />}
        disabled={isSuccessEditDisabled}
      />
    </>
  );

  const buttonsWrapper = !isMobileAll && isEditable && (
    <div
      className={cn(styles.jiraIntegrationsListElement__buttons, {
        [styles.jiraIntegrationsListElement__buttons_edit]: isEditMode,
      })}
    >
      {buttons}
    </div>
  );

  const mobileButtons = !isEditMode ? (
    <>
      <Button
        onClick={deleteHandler}
        appearance="flat"
        className={cn(
          styles.jiraIntegrationsListElement__mobileButton,
          styles.jiraIntegrationsListElement__mobileButton_delete
        )}
      >
        Удалить
      </Button>
      <Button
        onClick={editHandler}
        className={styles.jiraIntegrationsListElement__mobileButton}
      >
        Редактировать
      </Button>
    </>
  ) : (
    <>
      <Button
        onClick={closeEditHandler}
        appearance="flat"
        className={styles.jiraIntegrationsListElement__mobileButton}
      >
        Отмена
      </Button>
      <Button
        onClick={successEditHandler}
        disabled={isSuccessEditDisabled}
        className={styles.jiraIntegrationsListElement__mobileButton}
      >
        Добавить
      </Button>
    </>
  );

  const buttonsWrapperMobile = isMobileAll && isEditable && (
    <div
      className={cn(styles.jiraIntegrationsListElement__mobileButtons, {
        [styles.jiraIntegrationsListElement__mobileButtons_edit]: isEditMode,
      })}
    >
      {mobileButtons}
    </div>
  );

  return (
    <OutsideClickHandler
      onClickOutside={closeEditHandler}
      className={cn(styles.jiraIntegrationsListElement, {
        [styles.jiraIntegrationsListElement_edit]: isEditMode,
      })}
    >
      <div className={styles.jiraIntegrationsListElement__wrapper}>
        {isEditMode ? leftElementEdit : leftElement}
        <div className={styles.jiraIntegrationsListElement__rightWrapper}>
          <div
            className={styles.jiraIntegrationsListElement__rightContentWrapper}
          >
            <ArrowHorizontalIcon
              className={styles.jiraIntegrationsListElement__arrowIcon}
            />
            {isEditMode ? rightElementEdit : rightElement}
          </div>
          {buttonsWrapper}
        </div>
      </div>
      {buttonsWrapperMobile}
    </OutsideClickHandler>
  );
};
