import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Organization } from 'core/types/organization';
import { System } from 'core/types/system';

import { DesktopFilters, TicketsDownloadInfo } from '../../types';

interface DesktopReducedState {
  filters?: DesktopFilters;
  organizations?: Organization[];
  systems?: System[];
  isTicketsLoading?: boolean;
}

const initialState: DesktopReducedState = {
  isTicketsLoading: false,
  organizations: [],
  systems: [],
};

const desktopSlice = createSlice({
  name: 'desktopHeader',
  initialState,
  reducers: {
    setDesktopFilters: (state, action: PayloadAction<DesktopFilters>) => {
      state.filters = action.payload;
    },
    fetchOrganizationsSuccess: (
      state,
      action: PayloadAction<Organization[]>
    ) => {
      state.organizations = action.payload;
    },
    fetchOrganizationsRequest: (_state) => {},
    fetchSystemsSuccess: (state, action: PayloadAction<System[]>) => {
      state.systems = action.payload;
    },
    fetchSystemsRequest: (_state, _action: PayloadAction<string>) => {},
    downloadTicketsRequest: (
      _state,
      _action: PayloadAction<TicketsDownloadInfo>
    ) => {},
    setIsTicketsLoading: (state, action: PayloadAction<boolean>) => {
      state.isTicketsLoading = action.payload;
    },
    resetDesktopState: () => initialState,
  },
});

export const {
  setDesktopFilters,
  fetchOrganizationsRequest,
  fetchOrganizationsSuccess,
  fetchSystemsRequest,
  fetchSystemsSuccess,
  downloadTicketsRequest,
  setIsTicketsLoading,
  resetDesktopState,
} = desktopSlice.actions;
export const desktop = desktopSlice.reducer;
