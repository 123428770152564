import { ChangeEvent } from 'react';

import { Checkbox } from 'components';
import { Responsibility } from 'core/types/responsibility';

export const getDataTable = (
  changeCheckbox: (
    item: Responsibility
  ) => (event: ChangeEvent<HTMLInputElement>) => void,
  selected: string[],
  data?: Responsibility[]
) =>
  data?.map((responsibility) => {
    const { title, description, id } = responsibility;
    return {
      checkbox: (
        <Checkbox
          name="responsibilityCheckbox"
          onChange={changeCheckbox(responsibility)}
          checked={selected.includes(id)}
        />
      ),
      id,
      title,
      description,
    };
  }) || [];
