import { Priority } from 'core/types';
import { SLASettingsPriority } from 'features/Contract/types';

export const DEFAULT_SLA_SETTINGS = {
  decisionTime: 0,
  reactionTime: 0,
  workMode: false,
};

export const DEFAULT_SLA_TIME = '000:00';

export const INITIAL_SLA_SETTINGS: SLASettingsPriority[] = [
  {
    name: Priority.NONE,
    checked: false,
  },
  {
    name: Priority.LOW,
    checked: false,
  },
  {
    name: Priority.MIDDLE,
    checked: false,
  },
  {
    name: Priority.HIGH,
    checked: false,
  },
  {
    name: Priority.VERY_HIGH,
    checked: false,
  },
];
