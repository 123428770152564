import cn from 'clsx';
import { FC } from 'react';

import {
  getChangeButtonProps,
  PopoverContainer,
  TableContainer,
} from 'components';
import { DeleteButton } from 'core/components';
import { PAGINATION_RANGE_MAP } from 'core/constants';
import { TableWorkGroupsTypes } from 'core/types/workGroup';
import { useWorkGroupsTableContainer } from 'features/WorkGroups/hooks';

import { AddGroups } from '../AddGroups';
import { WorkGroupsFullTable } from '../WorkGroupsFullTable';
import { WorkGroupsShortTable } from '../WorkGroupsShortTable';

import styles from './WorkGroupsTableContainer.module.scss';

interface WorkGroupsTableContainerProps {
  tableType: TableWorkGroupsTypes;
  className?: string;
}

export const WorkGroupsTableContainer: FC<WorkGroupsTableContainerProps> = ({
  tableType,
  className,
}) => {
  const { methods, state } = useWorkGroupsTableContainer(tableType);
  const {
    toggleDeleteModal,
    toggleFilterRow,
    handleSort,
    handleGroupClick,
    prepareHandler,
    handleChangePage,
    handleChangePageSize,
    handleShowModalDelete,
    handleDelete,
    toggleModal,
  } = methods;
  const {
    isAccessToDeleteGroup,
    showDeleteModal,
    searchDisabled,
    prepareCreateTitle,
    prepareDisabled,
    pageNum,
    pageSize,
    sortGroups,
    workGroups,
    groupId,
    showFilterRow,
    isFilterActive,
    workGroup,
    isModal,
    totalElements,
    loadingGroups,
  } = state;

  const getTableComponent: Record<TableWorkGroupsTypes, () => JSX.Element> = {
    [TableWorkGroupsTypes.FULL]: () => (
      <WorkGroupsFullTable
        workGroups={workGroups}
        handleSort={handleSort}
        handleRowClick={handleGroupClick}
        activeRowId={groupId}
        sort={sortGroups}
        showFilterRow={showFilterRow}
        isFilterActive={isFilterActive}
      />
    ),
    [TableWorkGroupsTypes.FROM_ORGANIZATIONS]: () => (
      <WorkGroupsShortTable
        workGroups={workGroups}
        handleSort={handleSort}
        showFilterRow={showFilterRow}
        activeRowId={workGroup?.id}
        sort={sortGroups}
        isFilterActive={isFilterActive}
        tableType={tableType}
      />
    ),
    [TableWorkGroupsTypes.ADD_GROUPS]: () => (
      <WorkGroupsShortTable
        showFilterRow={showFilterRow}
        workGroups={workGroups}
        handleSort={handleSort}
        sort={sortGroups}
        isFilterActive={isFilterActive}
        tableType={tableType}
      />
    ),
    [TableWorkGroupsTypes.FROM_USERS]: () => (
      <WorkGroupsShortTable
        workGroups={workGroups}
        showFilterRow={showFilterRow}
        sort={sortGroups}
        handleSort={handleSort}
        activeRowId={workGroup?.id}
        isFilterActive={isFilterActive}
        tableType={tableType}
      />
    ),
  };

  const getContent = () => {
    return getTableComponent[tableType]();
  };

  const addGroupsModal = isModal && (
    <AddGroups isModal={isModal} toggleModal={toggleModal} />
  );

  const dropContent =
    tableType === TableWorkGroupsTypes.FULL && isAccessToDeleteGroup ? (
      <PopoverContainer>
        <DeleteButton onClick={handleShowModalDelete} disabled={!workGroup} />
      </PopoverContainer>
    ) : undefined;

  return (
    <TableContainer
      classNameRoot={cn(
        {
          [styles.workGroupsTableContainer_fromUsers]:
            tableType === TableWorkGroupsTypes.FROM_USERS,
          [styles.workGroupsTableContainer_full]:
            tableType === TableWorkGroupsTypes.FULL,
          [styles.workGroupsTableContainer_fromOrganizations]:
            tableType === TableWorkGroupsTypes.FROM_ORGANIZATIONS,
          [styles.workGroupsTableContainer_addGroups]:
            tableType === TableWorkGroupsTypes.ADD_GROUPS,
        },
        className
      )}
      contentTable={
        <>
          {getContent()}
          {addGroupsModal}
        </>
      }
      headerProps={{
        title: 'Группы',
        createHandler: prepareHandler(),
        createDisabled: prepareDisabled(),
        createTitle: prepareCreateTitle(),
        searchDisabled,
        toggleSearch: toggleFilterRow,
        dropContent,
        icon:
          tableType === TableWorkGroupsTypes.ADD_GROUPS
            ? getChangeButtonProps(totalElements || 0).icon
            : undefined,
        isSearchOpen: showFilterRow,
        loading: loadingGroups,
      }}
      countRecordsProps={{
        records: totalElements,
      }}
      paginationProps={{
        pageNum,
        elementsCount: totalElements,
        pageSize,
        marginPagesDisplayed: PAGINATION_RANGE_MAP[tableType],
        handleChangePage,
        handleChangePageSize,
        disabled: loadingGroups,
        entityName: `GROUPS_${tableType}`,
      }}
      approveOrCancelProps={{
        onApprove: handleDelete,
        isModal: showDeleteModal,
        toggleModal: toggleDeleteModal,
        approveTitle: 'Удалить',
        approveBtnProps: {
          appearance: 'outline',
          className: styles.workGroupsTableContainer__iconDelete,
        },
        cancelTitle: 'Отмена',
        text: `Вы уверены, что хотите удалить рабочую группу ${workGroup?.title}?`,
      }}
    />
  );
};
