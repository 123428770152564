import { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import {
  ACTIVE_SELECT_OPTIONS,
  CustomFieldType,
  CustomFieldTypeForFilter,
  getValueCustomFieldsForFilter,
  getValueFromFieldDate,
  getValueFromFieldFlag,
  getValueFromFieldText,
  Input,
  InputDatePicker,
  SearchSelect,
  Select,
  Size,
  TableBodyCell,
  TableFilter,
} from 'components';
import {
  fetchFiltersSystemsRequest,
  getPropsSystemsTicket,
  getTicketSystemsSelectList,
  resetSystemsState,
} from 'features/Systems';
import {
  fetchStatusesRequest,
  fetchTicketTypesRequest,
  resetTicketStatusState,
  resetTicketTypeState,
} from 'features/Tickets/ducks/actions';
import { getPropsStatus, getPropsType } from 'features/Tickets/ducks/selectors';
import { useTicketsFilter } from 'features/Tickets/hooks';
import { getPreparedIdsForTable } from 'features/Tickets/utils';
import {
  fetchUsersClientsRequest,
  fetchUsersSpecialistsRequest,
  getPropsUsersClients,
  getPropsUsersSpecialists,
  getUsersClientsSelectList,
  getUsersSpecialistsSelectList,
  resetUsersState,
} from 'features/Users';
import { mapListToSelectOptions } from 'utils';

import { PRIORITY_OPTIONS, SOURCE_TICKET_TYPE_OPTIONS } from '../../constants';

import styles from './TicketsFilter.module.scss';

export const TicketsFilter: FC = memo(() => {
  const {
    state: {
      filterValues,
      statusesOptions,
      isOrganizationCardPage,
      isMyTicketsPage,
      isClient,
      isSpecialistColumn,
      isWorkGroupSpecialist,
      defaultColumns,
      customColumns,
      customFieldType,
      ticketTypesOptions,
      ticketTab,
    },
    methods: {
      handleChangeInputFilter,
      handleChangeSelectFilter,
      resetFilterHandler,
      disableReset,
      handleChangeSelectCustomField,
      handleChangeInputCustomField,
    },
  } = useTicketsFilter();

  const defaultFilterMap: Record<string, JSX.Element> = {
    number: (
      <TableBodyCell className={styles.ticketsFilter__cell}>
        <Input
          size={Size.xs}
          key="number"
          value={filterValues.number}
          onChange={handleChangeInputFilter('number')}
        />
      </TableBodyCell>
    ),
    ...(!isOrganizationCardPage && {
      statuses: (
        <TableBodyCell className={styles.ticketsFilter__cell}>
          <SearchSelect
            key="statuses"
            onChange={handleChangeSelectFilter('statuses')}
            mobileModalTitle="статус"
            value={filterValues.statuses}
            options={statusesOptions}
            dataPagination={useSelector(getPropsStatus)}
            resetState={resetTicketStatusState}
            fetchRequest={fetchStatusesRequest}
            filter={{ ticketTab }}
          />
        </TableBodyCell>
      ),
    }),
    priorities: (
      <TableBodyCell className={styles.ticketsFilter__cell}>
        <Select<string>
          size={Size.xs}
          key="priorities"
          onChange={handleChangeSelectFilter('priorities')}
          options={PRIORITY_OPTIONS}
          value={filterValues.priorities}
          mobileModalTitle="приоритет"
          isMulti
          isTooltip={false}
        />
      </TableBodyCell>
    ),
    theme: (
      <TableBodyCell className={styles.ticketsFilter__cell}>
        <Input
          size={Size.xs}
          key="theme"
          value={filterValues.theme}
          onChange={handleChangeInputFilter('theme')}
        />
      </TableBodyCell>
    ),
    ...(!(isClient && isMyTicketsPage) &&
      !isOrganizationCardPage && {
        clientIds: (
          <TableBodyCell className={styles.ticketsFilter__cell}>
            <SearchSelect
              key="clientIds"
              onChange={handleChangeSelectFilter('clientIds')}
              mobileModalTitle="клиента"
              value={filterValues.clientIds}
              options={useSelector(getUsersClientsSelectList)}
              dataPagination={useSelector(getPropsUsersClients)}
              resetState={resetUsersState}
              fetchRequest={fetchUsersClientsRequest}
              filter={{ ticketTab }}
            />
          </TableBodyCell>
        ),
      }),
    ...(isSpecialistColumn &&
      !isOrganizationCardPage && {
        specialistIds: (
          <TableBodyCell className={styles.ticketsFilter__cell}>
            <SearchSelect
              key="specialistIds"
              onChange={handleChangeSelectFilter('specialistIds')}
              mobileModalTitle="исполнителя"
              value={filterValues.specialistIds}
              options={useSelector(getUsersSpecialistsSelectList)}
              dataPagination={useSelector(getPropsUsersSpecialists)}
              resetState={resetSystemsState}
              fetchRequest={fetchUsersSpecialistsRequest}
              filter={{ ticketTab }}
            />
          </TableBodyCell>
        ),
      }),
    ...(!isOrganizationCardPage && {
      dateSolve: (
        <TableBodyCell className={styles.ticketsFilter__cell}>
          <InputDatePicker
            size={Size.xs}
            key="dateSolve"
            type="datePicker"
            value={filterValues.dateSolve}
            onChange={handleChangeSelectFilter('dateSolve')}
            showRange
            alwaysShowMask
          />
        </TableBodyCell>
      ),
    }),
    ...(!isOrganizationCardPage && {
      systemIds: (
        <TableBodyCell className={styles.ticketsFilter__cell}>
          <SearchSelect
            key="systemIds"
            onChange={handleChangeSelectFilter('systemIds')}
            mobileModalTitle="систему"
            value={filterValues.systemIds}
            options={useSelector(getTicketSystemsSelectList)}
            dataPagination={useSelector(getPropsSystemsTicket)}
            resetState={resetSystemsState}
            fetchRequest={fetchFiltersSystemsRequest}
            filter={{ ticketTab }}
          />
        </TableBodyCell>
      ),
    }),
    ...((isWorkGroupSpecialist || !isClient) &&
      !isOrganizationCardPage && {
        tagName: (
          <TableBodyCell className={styles.ticketsFilter__cell}>
            <Input
              size={Size.xs}
              key="tagName"
              value={filterValues.tagName}
              onChange={handleChangeInputFilter('tagName')}
            />
          </TableBodyCell>
        ),
      }),
    dateCreate: (
      <TableBodyCell className={styles.ticketsFilter__cell}>
        <InputDatePicker
          size={Size.xs}
          key="dateCreate"
          type="datePicker"
          value={filterValues.dateCreate}
          onChange={handleChangeSelectFilter('dateCreate')}
          showRange
          alwaysShowMask
        />
      </TableBodyCell>
    ),
    ticketType: (
      <TableBodyCell className={styles.ticketsFilter__cell}>
        <SearchSelect
          size={Size.xs}
          key="typeIds"
          options={ticketTypesOptions}
          onChange={handleChangeSelectFilter('typeIds')}
          value={filterValues.typeIds}
          mobileModalTitle="Тип тикета"
          resetState={resetTicketTypeState}
          dataPagination={useSelector(getPropsType)}
          fetchRequest={fetchTicketTypesRequest}
          filter={{ ticketTab }}
        />
      </TableBodyCell>
    ),
    sources: (
      <TableBodyCell className={styles.ticketsFilter__cell}>
        <Select<string>
          size={Size.xs}
          key="sources"
          options={SOURCE_TICKET_TYPE_OPTIONS}
          value={filterValues.sources}
          onChange={handleChangeSelectFilter('sources')}
          mobileModalTitle="Тип источника"
          isMulti
          isTooltip={false}
        />
      </TableBodyCell>
    ),
  };

  const getDefaultDataTable = (columns: string[]) => {
    return columns
      .map((column) => {
        const preparedId = getPreparedIdsForTable(column);
        return defaultFilterMap[preparedId];
      })
      .filter(Boolean);
  };

  const getCustomTable = (column: CustomFieldTypeForFilter) => {
    const { fieldId, type, values } = column;
    const value = getValueCustomFieldsForFilter(filterValues.customFields)[
      fieldId
    ];

    const customTableMap: Record<CustomFieldType, JSX.Element> = {
      [CustomFieldType.FIELD_TEXT]: (
        <TableBodyCell className={styles.ticketsFilter__cell}>
          <Input
            size={Size.xs}
            key={fieldId}
            value={getValueFromFieldText(value)}
            onChange={handleChangeInputCustomField({ fieldId, type })}
          />
        </TableBodyCell>
      ),
      [CustomFieldType.FIELD_FLAG]: (
        <TableBodyCell className={styles.ticketsFilter__cell}>
          <Select<boolean>
            size={Size.xs}
            key={fieldId}
            options={ACTIVE_SELECT_OPTIONS}
            value={getValueFromFieldFlag(value)}
            onChange={handleChangeSelectCustomField({ fieldId, type })}
            mobileModalTitle="тип"
          />
        </TableBodyCell>
      ),
      [CustomFieldType.FIELD_DATE]: (
        <TableBodyCell className={styles.ticketsFilter__cell}>
          <InputDatePicker
            size={Size.xs}
            key={fieldId}
            type="datePicker"
            value={getValueFromFieldDate(value)}
            onChange={handleChangeSelectCustomField({ fieldId, type })}
            alwaysShowMask
          />
        </TableBodyCell>
      ),
      [CustomFieldType.FIELD_LIST]: (
        <TableBodyCell className={styles.ticketsFilter__cell}>
          <Select<string>
            size={Size.xs}
            key={fieldId}
            options={mapListToSelectOptions(values ?? [])}
            onChange={handleChangeSelectCustomField({ fieldId, type })}
            mobileModalTitle="тип"
          />
        </TableBodyCell>
      ),
    };

    return customTableMap[type];
  };

  const getCustomDataTable = (columns: string[]) => {
    return columns.map((column) => getCustomTable(customFieldType[column]));
  };

  const defaultFilter = getDefaultDataTable(defaultColumns);
  const customFilter = getCustomDataTable(customColumns);

  const filter = <>{[...defaultFilter, ...customFilter]}</>;

  return (
    <TableFilter
      filterComponent={filter}
      onReset={resetFilterHandler}
      disableReset={disableReset}
    />
  );
});
