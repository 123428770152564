import { Reducer } from 'redux';

import { BreadCrumb } from 'components';
import {
  Core,
  CoreActionsTypes,
  DeleteAlertAction,
  SetAlertAction,
  SetBreadCrumbsConfigAction,
  SetHrefAction,
  SetIsContrastThemeAction,
  SetIsSystemThemeAction,
  SetRedirectPathAction,
  SetScreenWidthAction,
  SetThemeAction,
} from 'core/ducks/types';
import { Alert, ScreenWidth, Theme, ThemeActive } from 'core/types';
import { ReducerMap } from 'store/types';

export interface CoreReducerState {
  screenWidth?: ScreenWidth;
  redirectPath?: string | null;
  theme?: Theme;
  systemTheme?: ThemeActive;
  contrastTheme?: ThemeActive;
  alerts?: Alert[];
  breadCrumbsConfig?: BreadCrumb[];
  href?: string;
  prevHref?: string;
}

const initialState: CoreReducerState = {
  screenWidth: ScreenWidth.DESKTOP,
  redirectPath: null,
  theme: localStorage.getItem('theme') as Theme,
  systemTheme: localStorage.getItem('system-theme') as ThemeActive,
  contrastTheme: localStorage.getItem('contrast-theme') as ThemeActive,
  alerts: [],
  breadCrumbsConfig: [],
};

const coreReducerMap: ReducerMap<CoreReducerState, CoreActionsTypes> = {
  [Core.SET_SCREEN_WIDTH]: (state, action) => {
    const { payload } = action as SetScreenWidthAction;
    return {
      ...state,
      screenWidth: payload,
    };
  },
  [Core.SET_REDIRECT_PATH]: (state, action) => {
    const { payload } = action as SetRedirectPathAction;
    return {
      ...state,
      redirectPath: payload,
    };
  },
  [Core.SET_THEME]: (state, action) => {
    const { payload } = action as SetThemeAction;
    return {
      ...state,
      theme: payload,
    };
  },
  [Core.SET_IS_SYSTEM_THEME]: (state, action) => {
    const { payload } = action as SetIsSystemThemeAction;
    return {
      ...state,
      systemTheme: payload,
    };
  },
  [Core.SET_IS_CONTRAST_THEME]: (state, action) => {
    const { payload } = action as SetIsContrastThemeAction;
    return {
      ...state,
      contrastTheme: payload,
    };
  },
  [Core.SET_ALERT]: (state, action) => {
    const { payload } = action as SetAlertAction;
    return {
      ...state,
      alerts: [...(state?.alerts || []), payload],
    };
  },
  [Core.DELETE_ALERT]: (state, action) => {
    const { payload } = action as DeleteAlertAction;
    return {
      ...state,
      alerts: [
        ...(state?.alerts?.filter((alert) => alert.id !== payload) || []),
      ],
    };
  },
  [Core.SET_BREAD_CRUMBS_CONFIG]: (state, action) => {
    const { payload } = action as SetBreadCrumbsConfigAction;
    return {
      ...state,
      breadCrumbsConfig: payload,
    };
  },
  [Core.SET_HREF]: (state, action) => {
    const { payload } = action as SetHrefAction;
    return {
      ...state,
      prevHref: state?.href,
      href: payload,
    };
  },
};

export const core: Reducer<CoreReducerState, CoreActionsTypes> = (
  state = initialState,
  action
) => {
  const reducer = coreReducerMap[action.type];
  if (!reducer) {
    return state;
  }
  return reducer(state, action);
};
