import { Action, AnyAction, Reducer } from 'redux';

export enum Root {
  RESET_STORE = 'RESET_STORE',
}

export interface ResetStoreAction {
  type: Root.RESET_STORE;
}

export type ReducerMap<State, Actions extends Action = AnyAction> = Record<
  string,
  Reducer<State, Actions>
>;

export interface ResponseWithMeta<Content> {
  elements: number;
  page: number;
  totalElements: number;
  totalPages: number;
  content: Content;
  size?: number;
}
