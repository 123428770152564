import { SelectOption, ValueType } from 'components';
import { Priority } from 'core/types';
import { User } from 'core/types/user';
import { KeyValueOption } from 'types/models/meta';

export enum OrganizationsType {
  IN_STATUS_CLOSE = 'inStatusClose',
  IN_STATUS_NEW = 'inStatusNew',
  IN_ACTIVE_STATUS = 'inActiveStatus',
}

export interface RadioTabs {
  infoType: OrganizationsType;
}

export interface TicketsDownloadInfo {
  fileName: string;
  path?: string;
}

interface StatusEntity {
  name: string;
  color: string;
  count: string;
}

export interface OrganizationItem {
  key: string;
  value: string;
}

export type ActiveStatusEntity = {
  titleStatus: string;
  quantity: string;
  color: string;
};

export interface KpiFormData {
  period?: ValueType<string>;
  workGroupsIds?: SelectOption[];
  organizationsIds?: SelectOption[];
  systemsIds?: SelectOption[];
  ticketTypesIds?: SelectOption[];
}

export interface KpiDataRequest {
  period?: string;
  workgroupList?: string[];
  organizationList?: string[];
  systemList?: string[];
  ticketTypeList?: string[];
}

export interface KpiData {
  filters: {
    workgroups: OrganizationItem[];
    organizations: OrganizationItem[];
    systems: OrganizationItem[];
    types: OrganizationItem[];
  };
  info: {
    activeTickets: {
      totalCount: number;
      expiredCount: number;
      expiredInWork: number;
      expiredNew: number;
      statusDetails: ActiveStatusEntity[];
    };
    closedInfo: {
      totalCountClosed: number;
      firstTimeClosed: number;
      totalCountDeclined: number;
      totalCountClosedWithExpire: number;
      rating: number;
      problems: Array<string>;
      closedWithDecisionExpire: number;
      closedWithReactionExpire: number;
    };
    organizations: {
      inStatusClose: OrganizationItem[];
      inActiveStatus: OrganizationItem[];
      inStatusNew: OrganizationItem[];
    };
    sla: {
      totalSla: number;
      days: number[];
      slaInfo: number[];
    };
  };
}

export interface CurrentStateFormData {
  infoType?: 'organization' | 'workGroup';
  workGroupId?: ValueType<string>;
  organizationId?: ValueType<string>;
  systemId?: ValueType<string>;
}

export interface EmployeesFormData {
  workGroupId?: ValueType<string>;
  date?: string;
  dateFrom?: string;
  dateTo?: string;
  onlyActualTickets: boolean;
}

export interface HistoryFormData {
  organizationId?: ValueType<string>;
  systemId?: ValueType<string>;
  date?: string;
  dateFrom?: string;
  dateTo?: string;
  onlyActualTickets: boolean;
}

export interface DesktopFilters {
  workgroupId?: string;
  organizationId?: string;
  systemId?: string;
  from?: Date;
  to?: Date;
  date?: string;
  onlyActualTickets?: boolean;
}

export interface SlaStatistic {
  avgInWorkSeconds?: number;
  avgExpireDecisionSeconds?: number;
  avgReactionSeconds?: number;
  avgReactionExpireSeconds?: number;
}

export interface TicketsStatistic {
  priority?: KeyValueOption<string, Priority>[];
  status?: StatusEntity[];
  types?: KeyValueOption[];
  countActive?: number;
  countExpired?: number;
  slaPercent?: number;
  total?: number;
  closed?: number;
  downloadInfo?: TicketsDownloadInfo;
}

export interface EmployeesStatistic {
  content?: User[];
  elements: number;
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
}
