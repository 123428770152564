import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TicketTypeSettings } from 'core/types';
import { System } from 'core/types/system';

import { CreateSystem, EnvironmentResponse } from '../types';

interface SystemReducerState {
  system?: System;
  systemIndexList?: string[];
  loading?: boolean;
}

const initialState: SystemReducerState = {
  loading: false,
};

const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    fetchSystem: (_state, _action: PayloadAction<string>) => {},
    setSystemSuccess: (state, { payload }: PayloadAction<System>) => {
      state.system = payload;
    },
    createSystem: (_state, _action: PayloadAction<CreateSystem>) => {},
    editSystem: (_state, _action: PayloadAction<CreateSystem>) => {},
    fetchSystemIndexList: () => {},
    setSystemIndexList: (state, { payload }: PayloadAction<string[]>) => {
      state.systemIndexList = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    addTicketTypes: (_state, _action: PayloadAction<string[]>) => {},
    setTicketTypes: (
      state,
      { payload }: PayloadAction<TicketTypeSettings[]>
    ) => {
      const list = payload.map((item) => ({
        id: item.ticketTypeId,
        title: item.title,
        value: item.title,
      }));

      if (state.system) {
        state.system.typeList = list;
      }
    },
    addEnvironments: (_state, _action: PayloadAction<string[]>) => {},
    setEnvironments: (
      state,
      { payload }: PayloadAction<EnvironmentResponse[]>
    ) => {
      const list = payload.map((item) => ({
        id: item.environmentId,
        name: item.name,
        description: '',
      }));

      if (state.system) {
        state.system.environmentList = list;
      }
    },
    resetSystemState: () => initialState,
  },
});

export const {
  fetchSystem,
  setSystemSuccess,
  createSystem,
  editSystem,
  fetchSystemIndexList,
  setSystemIndexList,
  addTicketTypes,
  setTicketTypes,
  addEnvironments,
  setEnvironments,
  setLoading,
  resetSystemState,
} = systemSlice.actions;

export const system = systemSlice.reducer;
