import cn from 'clsx';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import {
  ConditionBlock,
  Input,
  RadioSlide,
  TextArea,
  ToggleSwitch,
} from 'components';
import { Create } from 'core/modals';
import { RoleType } from 'core/types';
import {
  getIsClient,
  getIsServiceAdmin,
  getUserOrganization,
} from 'features/Auth';
import { OrganizationSelect } from 'features/Organizations';
import { ROLE_TYPE_TITLE } from 'features/Roles';
import { checkObjectIdentity } from 'utils';

import {
  checkIsExistRequest,
  checkIsExistSuccess,
  createRole,
  updateRole,
} from '../../ducks/actions';
import { getCurrentRole, getIsExistTitle } from '../../ducks/selectors';
import { CreateRoleData } from '../../types';

import styles from './RolesCreate.module.scss';
import { getRoleTabs } from './utils';

interface RolesCreateProps {
  isModal: boolean;
  toggleModal(): void;
  isEditMode?: boolean;
}

export const RolesCreate: FC<RolesCreateProps> = ({
  isModal,
  toggleModal,
  isEditMode,
}) => {
  const [isManager, setIsManager] = useState(false);

  const isExistTitle = useSelector(getIsExistTitle);
  const organization = useSelector(getUserOrganization);
  const isClient = useSelector(getIsClient);
  const role = useSelector(getCurrentRole);
  const isServiceAdmin = useSelector(getIsServiceAdmin);

  const canEdit = !!role && isEditMode;

  const getInitialOrganizationOption = () => {
    if (!isEditMode && isClient && organization) {
      return {
        title: organization?.title || '',
        value: organization?.id || '',
      };
    }
    if (canEdit) {
      return {
        title: role.organization?.title || '',
        value: role.organization?.id || '',
      };
    }
    return null;
  };

  const isExistTitleErrorMessage = isExistTitle
    ? 'Роль с таким названием уже существует'
    : '';

  const dispatch = useDispatch();

  const closeModal = () => {
    toggleModal();
  };
  const toggleManager = () => {
    setIsManager((prevState) => !prevState);
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
    watch,
    setValue,
    control,
  } = useForm<CreateRoleData>({
    mode: 'onChange',
    defaultValues: {
      type: RoleType.ROLE_IN_GROUP,
    },
  });

  const formValues = watch();

  const getSubmitDisabled = () => {
    if (isEditMode) {
      const currentValues = {
        ...role,
        ...formValues,
        manager: isManager,
      };
      delete currentValues.organizationId;

      return (
        checkObjectIdentity({ ...role }, currentValues) ||
        (!isValid && isDirty) ||
        !!isExistTitle
      );
    }
    return !isValid || !!isExistTitle;
  };

  const nameInputOptions = register('name', {
    required: true,
    maxLength: {
      value: 100,
      message: 'Название роли не может быть длиннее 100 символов.',
    },
    onChange: (e: ChangeEvent<HTMLInputElement>) => {
      const title = e.target.value;
      if (title) {
        dispatch(checkIsExistRequest(title));
      }
    },
  });

  const descriptionTextAreaOptions = register('description', {
    required: true,
    maxLength: {
      value: 255,
      message: 'Описание роли не может быть длиннее 255-ти символов.',
    },
  });

  const formSubmitHandler = handleSubmit((data) => {
    const createData = {
      ...data,
    };
    if (data.type === RoleType.ROLE_IN_GROUP) {
      createData.manager = isManager;
    }
    dispatch(createRole(createData));
    closeModal();
    reset();
  });

  const formEditRolesSubmit = handleSubmit((data) => {
    if (role) {
      const editData = {
        ...role,
        ...data,
        manager: isManager,
      };
      dispatch(updateRole(editData));
    }
    closeModal();
    reset();
  });

  const renderManagerSwitch =
    (canEdit && role.type === RoleType.ROLE_IN_GROUP) ||
    formValues.type === RoleType.ROLE_IN_GROUP;

  useEffect(() => {
    setValue('organizationId', getInitialOrganizationOption());
  }, []);

  useEffect(() => {
    if (role && isEditMode) {
      setValue('name', role.name);
      setValue('description', role?.description);
      setValue('type', role.type);
      setValue('organizationId', getInitialOrganizationOption());
      setIsManager(role?.manager || false);
    }
  }, [role, isEditMode]);

  useEffect(() => {
    return () => {
      dispatch(checkIsExistSuccess(false));
    };
  }, []);

  const managerSwitch = renderManagerSwitch && (
    <div className={styles.rolesCreate__isManager}>
      <ToggleSwitch
        label="Руководящая роль"
        id="manager"
        checked={isManager}
        onChange={toggleManager}
      />
    </div>
  );

  return (
    <Create
      toggleModal={toggleModal}
      isModal={isModal}
      title={`${canEdit ? 'Редактировать' : 'Создать'} роль`}
      onSubmit={canEdit ? formEditRolesSubmit : formSubmitHandler}
      createTitle={isEditMode ? 'Сохранить' : 'Создать'}
      disabledSubmit={getSubmitDisabled()}
      subModalText={`${canEdit ? 'редактирование' : 'создание'} роли`}
    >
      <div className={styles.rolesCreate__form}>
        {!isEditMode && (
          <Controller
            control={control}
            name="type"
            rules={{
              required: true,
            }}
            render={({ field }) => {
              return (
                <RadioSlide
                  items={getRoleTabs(isServiceAdmin)}
                  value={field.value}
                  onChange={field.onChange}
                />
              );
            }}
          />
        )}
        {isEditMode && (
          <ConditionBlock
            text={role?.type ? ROLE_TYPE_TITLE[role.type] : ''}
            className={cn(
              styles.rolesCreate__type,
              role?.type === RoleType.ROLE_IN_GROUP
                ? styles.rolesCreate__type_inGroup
                : styles.rolesCreate__type_inSystem
            )}
          />
        )}
        <Input
          {...nameInputOptions}
          label="Название"
          error={!!errors.name || isExistTitle}
          errorMessage={errors.name?.message || isExistTitleErrorMessage}
        />
        <Controller
          control={control}
          name="organizationId"
          rules={{
            required: true,
          }}
          render={({ field }) => {
            return (
              <OrganizationSelect
                placeholder="Организация"
                value={field?.value}
                onChange={field.onChange}
                addOrgState={false}
                disabled={isClient || canEdit}
              />
            );
          }}
        />
        <TextArea
          {...descriptionTextAreaOptions}
          label="Описание"
          error={!!errors.description}
          errorMessage={errors.description?.message}
          className={styles.rolesCreate__textArea}
        />
        {managerSwitch}
      </div>
    </Create>
  );
};
