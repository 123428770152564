import {
  Contract,
  Environment,
  EnvironmentSetting,
  EnvironmentsFilter,
  OrganizationType,
  TicketType,
  TicketTypeSettings,
} from 'core/types';
import {
  ContractDetailPayload,
  ContractIdSystemIdArgs,
  SystemsByContractIdPayload,
} from 'core/types/contract';
import {
  FetchOrganizations,
  Organization,
  OrganizationsFilter,
} from 'core/types/organization';
import { SLAType } from 'core/types/sla';
import { System, SystemsFilter } from 'core/types/system';
import { User, UsersFilter } from 'core/types/user';
import { WorkGroup, WorkGroupFilter } from 'core/types/workGroup';
import {
  ProlongationContractPayload,
  UpdateEnvironmentSettings,
} from 'features/Contract/types';
import { ResponseWithMeta } from 'store/types';
import { Attachment } from 'types/models/attachment';

import { ProgressStatus } from '../types';

import {
  AttachSystemAndSLAToContractType,
  ContractDetail,
  CreateContractDetailAction,
  DeleteContractDetailRequestAction,
  EditContractDetailAction,
  FetchContractDetailRequestAction,
  FetchContractDetailSuccessAction,
  FetchEnvironmentsBySystemRequestAction,
  FetchEnvironmentsBySystemSuccessAction,
  FetchEnvironmentSettingsBySystemRequestAction,
  FetchEnvironmentSettingsBySystemSuccessAction,
  FetchOrganizationsJoinSuccessContractDetailAction,
  FetchOrganizationsRequestContractDetailAction,
  FetchOrganizationsSuccessUpdateContractDetailAction,
  FetchPartnerOrganizationsRequestContractDetailAction,
  FetchPartnerOrganizationsSuccessContractDetailAction,
  FetchSupplementaryAgreementsSuccessAction,
  FetchSystemsForAddingToContractRequestContractDetailAction,
  FetchSystemsForAddingToContractSuccessContractDetailAction,
  FetchUpdateEnvironmentSettingsRequestAction,
  HideOrganizationsLoadingContractDetailAction,
  HidePartnerOrganizationsLoadingContractDetailAction,
  LoadingHideSystemsForAddingToContractContractDetailAction,
  LoadingShowSystemsForAddingToContractContractDetailAction,
  ResetOrganizationOnlyStateContractDetailAction,
  SetContractPermissionAction,
  SetContractPermissionPayload,
  SetCurrentOrganizationIdContractDetailAction,
  SetFilterEnvironmentsBySystemAction,
  SetOrganizationsPageContractDetailAction,
  SetOrganizationsSortContractDetailAction,
  SetPageEnvironmentsBySystemAction,
  SetPageSizeSupplementaryAgreementsContractDetailAction,
  SetPageSupplementaryAgreementsContractDetailAction,
  SetSelectedSystemIdContractDetailAction,
  SetSelectSystemByContractAction,
  SetSizeEnvironmentsBySystemAction,
  SetSortEnvironmentsBySystemAction,
  SetSortSupplementaryAgreementsContractDetailAction,
  ShowOrganizationsLoadingContractDetailAction,
  ShowPartnerOrganizationsLoadingContractDetailAction,
  SystemTransferAction,
  UpdateContractDetailAction,
  UpdateContractNotificationsAction,
  UpdateContractNotificationsPayload,
  UpdateContractNotificationsSuccessAction,
  UpdateContractPermissionsAction,
  UpdateContractPermissionsPayload,
  UpdateContractPermissionsSuccessAction,
} from './types';

export interface EditContractPayload {
  contract: Contract;
  systemId?: string;
}

export const showContractDetailLoading = () => {
  return {
    type: ContractDetail.CONTRACT_LOADING_SHOW,
  };
};

export const hideContractDetailLoading = () => {
  return {
    type: ContractDetail.CONTRACT_LOADING_HIDE,
  };
};

export const fetchContractDetailRequest = (
  id: string
): FetchContractDetailRequestAction => {
  return {
    type: ContractDetail.FETCH_CONTRACT_REQUEST,
    payload: id,
  };
};

export const fetchContractDetailSuccess = (
  contract: Contract
): FetchContractDetailSuccessAction => {
  return {
    type: ContractDetail.FETCH_CONTRACT_SUCCESS,
    payload: contract,
  };
};

export const contractSignRequestContractDetail = (id: string) => {
  return {
    type: ContractDetail.CONTRACT_SIGN_REQUEST,
    payload: id,
  };
};

export const showSupplementaryAgreementsLoading = () => {
  return {
    type: ContractDetail.SUPPLEMENTARY_AGREEMENTS_LOADING_SHOW,
  };
};

export const hideSupplementaryAgreementsLoading = () => {
  return {
    type: ContractDetail.SUPPLEMENTARY_AGREEMENTS_LOADING_HIDE,
  };
};

export const fetchSupplementaryAgreementsSuccess = (
  data: ResponseWithMeta<Contract[]>
): FetchSupplementaryAgreementsSuccessAction => {
  return {
    type: ContractDetail.FETCH_SUPPLEMENTARY_AGREEMENTS_SUCCESS,
    payload: data,
  };
};

export const fetchSupplementaryAgreementsRequest = (id: string) => {
  return {
    type: ContractDetail.FETCH_SUPPLEMENTARY_AGREEMENTS_REQUEST,
    payload: id,
  };
};

export const setPageSupplementaryAgreementsContractDetail = (
  page: number
): SetPageSupplementaryAgreementsContractDetailAction => {
  return {
    type: ContractDetail.SET_SUPPLEMENTARY_AGREEMENTS_PAGE,
    payload: page,
  };
};

export const setPageSizeSupplementaryAgreementsContractDetail = (
  size: number
): SetPageSizeSupplementaryAgreementsContractDetailAction => {
  return {
    type: ContractDetail.SET_SIZE_PAGE_SUPPLEMENTARY_AGREEMENTS,
    payload: size,
  };
};

export const setSortSupplementaryAgreementsContractDetail = (
  sort: string
): SetSortSupplementaryAgreementsContractDetailAction => {
  return {
    type: ContractDetail.SET_SORT_SUPPLEMENTARY_AGREEMENTS,
    payload: sort,
  };
};

export const createContractDetail = (
  data: Contract
): CreateContractDetailAction => {
  return {
    type: ContractDetail.CREATE_CONTRACT,
    payload: data,
  };
};

export const setContractPermission = (
  data: SetContractPermissionPayload
): SetContractPermissionAction => {
  return {
    type: ContractDetail.SET_CONTRACT_PERMISSION,
    payload: data,
  };
};

export const updateNotificationsExpireContract = (
  data: UpdateContractNotificationsPayload
): UpdateContractNotificationsAction => {
  return {
    type: ContractDetail.UPDATE_CONTRACT_NOTIFICATIONS,
    payload: data,
  };
};

export const updateNotificationsExpireContractSuccess = (
  data: Contract
): UpdateContractNotificationsSuccessAction => {
  return {
    type: ContractDetail.UPDATE_CONTRACT_NOTIFICATIONS_SUCCESS,
    payload: data,
  };
};

export const setProlongationContract = (payload?: ProgressStatus) => {
  return {
    type: ContractDetail.SET_PROLONGATION_CONTRACT,
    payload,
  };
};

export const prolongationContract = (payload: ProlongationContractPayload) => {
  return {
    type: ContractDetail.PROLONGATION_CONTRACT,
    payload,
  };
};

export const resetPartlyContractDetailStateForProlongation = () => {
  return {
    type: ContractDetail.RESET_PARTLY_CONTRACT_DETAIL_STATE_FOR_PROLONGATION,
  };
};

export const updateContractPermission = (
  data: UpdateContractPermissionsPayload
): UpdateContractPermissionsAction => {
  return {
    type: ContractDetail.UPDATE_CONTRACT_PERMISSION,
    payload: data,
  };
};

export const updateContractPermissionSuccess = (
  data: Contract
): UpdateContractPermissionsSuccessAction => {
  return {
    type: ContractDetail.UPDATE_CONTRACT_PERMISSION_SUCCESS,
    payload: data,
  };
};

export const setSelectedSystemIdContractDetail = (
  id?: string
): SetSelectedSystemIdContractDetailAction => {
  return {
    type: ContractDetail.SET_SELECTED_SYSTEM_ID,
    payload: id,
  };
};

export const editContractDetail = (
  data: EditContractPayload
): EditContractDetailAction => {
  return {
    type: ContractDetail.EDIT_CONTRACT,
    payload: data,
  };
};

export const updateContractDetail = (
  contract: Contract
): UpdateContractDetailAction => {
  return {
    type: ContractDetail.UPDATE_CONTRACT,
    payload: contract,
  };
};

export const deleteContractRequest = (
  id: string
): DeleteContractDetailRequestAction => {
  return {
    type: ContractDetail.DELETE_CONTRACT_REQUEST,
    payload: id,
  };
};

export const fetchSystemsByContractIdRequestContractDetail = (
  params: SystemsByContractIdPayload
) => {
  return {
    type: ContractDetail.FETCH_SYSTEMS_BY_CONTRACT_ID_REQUEST,
    payload: params,
  };
};

export const fetchSystemsByContractIdSuccessContractDetail = (
  systems: ResponseWithMeta<System[]>
) => {
  return {
    type: ContractDetail.FETCH_SYSTEMS_BY_CONTRACT_ID_SUCCESS,
    payload: systems,
  };
};

export const loadingShowSystemsByContractIdContractDetail = () => {
  return {
    type: ContractDetail.LOADING_SHOW_SYSTEMS_BY_CONTRACT_ID,
  };
};

export const loadingHideSystemsByContractIdContractDetail = () => {
  return {
    type: ContractDetail.LOADING_HIDE_SYSTEMS_BY_CONTRACT_ID,
  };
};

export const setPageSystemsByContractIdContractDetail = (page: number) => {
  return {
    type: ContractDetail.SET_SYSTEMS_BY_CONTRACT_ID_PAGE,
    payload: page,
  };
};

export const setPageSizeSystemsByContractIdContractDetail = (
  pageSize: number
) => {
  return {
    type: ContractDetail.SET_SIZE_PAGE_SYSTEMS_BY_CONTRACT_ID,
    payload: pageSize,
  };
};

export const setFilterSystemsByContractIdContractDetail = (
  filter: SystemsFilter
) => {
  return {
    type: ContractDetail.SET_SYSTEMS_BY_CONTRACT_ID_FILTER,
    payload: filter,
  };
};

export const setActiveSystemByContractIdContractDetail = (id?: string) => {
  return {
    type: ContractDetail.SET_ACTIVE_SYSTEM_BY_CONTRACT_ID,
    payload: id,
  };
};

export const setSortSystemsByContractIdContractDetail = (sort: string) => {
  return {
    type: ContractDetail.SET_SYSTEMS_BY_CONTRACT_ID_SORT,
    payload: sort,
  };
};

export const resetSystemsByContractIdOnlyContractDetail = () => {
  return {
    type: ContractDetail.RESET_ONLY_SYSTEMS_BY_CONTRACT_ID,
  };
};

export const systemTransfer = (contract: Contract): SystemTransferAction => {
  return {
    type: ContractDetail.SYSTEM_TRANSFER,
    payload: contract,
  };
};

export const setCheckedSystemForTransfer = (system: System) => {
  return {
    type: ContractDetail.SET_CHECKED_SYSTEM_FOR_TRANSFER,
    payload: system,
  };
};

export const setAllCheckedSystemsForTransfer = (systems: System[]) => {
  return {
    type: ContractDetail.SET_ALL_CHECKED_SYSTEMS_FOR_TRANSFER,
    payload: systems,
  };
};

export const setTransferStatus = (payload: ProgressStatus) => {
  return {
    type: ContractDetail.SET_TRANSFER_STATUS,
    payload,
  };
};

export const setContractIdForTransfer = (id?: string) => {
  return {
    type: ContractDetail.SET_CONTRACT_ID_FOR_TRANSFER,
    payload: id,
  };
};

export const resetPartlyContractDetailStateForTransfer = () => {
  return {
    type: ContractDetail.RESET_PARTLY_CONTRACT_DETAIL_STATE_FOR_TRANSFER,
  };
};

export const fetchSystemsForAddingToContractRequestContractDetail = (
  filter?: string
): FetchSystemsForAddingToContractRequestContractDetailAction => {
  return {
    type: ContractDetail.FETCH_SYSTEMS_REQUEST,
    payload: filter,
  };
};

export const fetchSystemsForAddingToContractSuccessContractDetail = (
  systems: System[]
): FetchSystemsForAddingToContractSuccessContractDetailAction => {
  return {
    type: ContractDetail.FETCH_SYSTEMS_SUCCESS,
    payload: systems,
  };
};

export const loadingShowSystemsForAddingToContractContractDetail =
  (): LoadingShowSystemsForAddingToContractContractDetailAction => {
    return {
      type: ContractDetail.LOADING_SHOW_SYSTEMS,
    };
  };

export const loadingHideSystemsForAddingToContractContractDetail =
  (): LoadingHideSystemsForAddingToContractContractDetailAction => {
    return {
      type: ContractDetail.LOADING_HIDE_SYSTEMS,
    };
  };

export const fetchSystemsForAddingSupplementaryAgreementRequestContractDetail =
  (filter?: string) => {
    return {
      type: ContractDetail.FETCH_SYSTEMS_FOR_ADDING_SUPPLEMENTARY_AGREEMENT_REQUEST,
      payload: filter,
    };
  };

export const fetchSystemsForAddingSupplementaryAgreementSuccessContractDetail =
  (data: System[]) => {
    return {
      type: ContractDetail.FETCH_SYSTEMS_FOR_ADDING_SUPPLEMENTARY_AGREEMENT_SUCCESS,
      payload: data,
    };
  };

export const loadingShowSystemsForAddingSupplementaryAgreementContractDetail =
  () => {
    return {
      type: ContractDetail.LOADING_SHOW_SYSTEMS_FOR_ADDING_SUPPLEMENTARY_AGREEMENT,
    };
  };

export const loadingHideSystemsForAddingSupplementaryAgreementContractDetail =
  () => {
    return {
      type: ContractDetail.LOADING_HIDE_SYSTEMS_FOR_ADDING_SUPPLEMENTARY_AGREEMENT,
    };
  };

export const resetContractDetailState = () => {
  return {
    type: ContractDetail.RESET_CONTRACT_DETAIL_STATE,
  };
};

export const showOrganizationsLoadingContractDetail =
  (): ShowOrganizationsLoadingContractDetailAction => {
    return {
      type: ContractDetail.ORGANIZATIONS_LOADING_SHOW,
    };
  };

export const hideOrganizationsLoadingContractDetail =
  (): HideOrganizationsLoadingContractDetailAction => {
    return {
      type: ContractDetail.ORGANIZATIONS_LOADING_HIDE,
    };
  };

export const fetchOrganizationsSuccessUpdateContractDetail = (
  data: ResponseWithMeta<Organization[]>
): FetchOrganizationsSuccessUpdateContractDetailAction => {
  return {
    type: ContractDetail.FETCH_ORGANIZATIONS_UPDATE_SUCCESS,
    payload: data,
  };
};

export const fetchOrganizationsJoinSuccessContractDetail = (
  data: ResponseWithMeta<Organization[]>
): FetchOrganizationsJoinSuccessContractDetailAction => {
  return {
    type: ContractDetail.FETCH_ORGANIZATIONS_JOIN_SUCCESS,
    payload: data,
  };
};

export const setCurrentOrganizationsPageContractDetail = (
  page: number
): SetOrganizationsPageContractDetailAction => {
  return {
    type: ContractDetail.SET_CURRENT_ORGANIZATIONS_PAGE,
    payload: page,
  };
};

export const setSelectedOrganizationIdContractDetail = (
  id?: string
): SetCurrentOrganizationIdContractDetailAction => {
  return {
    type: ContractDetail.SET_ORGANIZATION_SELECTED,
    payload: id,
  };
};

export const setOrganizationsFilterContractDetail = (
  data: OrganizationsFilter
) => {
  return {
    type: ContractDetail.SET_ORGANIZATIONS_FILTER,
    payload: data,
  };
};

export const fetchOrganizationsRequestContractDetail = (
  payload?: FetchOrganizations
): FetchOrganizationsRequestContractDetailAction => {
  return {
    type: ContractDetail.FETCH_ORGANIZATIONS_REQUEST,
    payload,
  };
};

export const showPartnerOrganizationsLoadingContractDetail =
  (): ShowPartnerOrganizationsLoadingContractDetailAction => {
    return {
      type: ContractDetail.PARTNER_ORGANIZATIONS_LOADING_SHOW,
    };
  };

export const hidePartnerOrganizationsLoadingContractDetail =
  (): HidePartnerOrganizationsLoadingContractDetailAction => {
    return {
      type: ContractDetail.PARTNER_ORGANIZATIONS_LOADING_HIDE,
    };
  };

export const fetchPartnerOrganizationsRequestContractDetail = (
  payload: OrganizationType[]
): FetchPartnerOrganizationsRequestContractDetailAction => {
  return {
    type: ContractDetail.FETCH_PARTNER_ORGANIZATIONS_REQUEST,
    payload,
  };
};

export const fetchPartnerOrganizationsSuccessContractDetail = (
  payload: ResponseWithMeta<Organization[]>
): FetchPartnerOrganizationsSuccessContractDetailAction => {
  return {
    type: ContractDetail.FETCH_PARTNER_ORGANIZATIONS_SUCCESS,
    payload,
  };
};

export const setOrganizationsSortContractDetail = (
  sort: string
): SetOrganizationsSortContractDetailAction => {
  return {
    type: ContractDetail.SET_ORGANIZATIONS_SORT,
    payload: sort,
  };
};

export const resetOrganizationOnlyStateContractDetail =
  (): ResetOrganizationOnlyStateContractDetailAction => {
    return {
      type: ContractDetail.RESET_ORGANIZATION_ONLY_STATE,
    };
  };

export const showUsersAllowedToAddToContractDetailLoading = () => {
  return {
    type: ContractDetail.USERS_LOADING_SHOW,
  };
};

export const hideUsersAllowedToAddToContractDetailLoading = () => {
  return {
    type: ContractDetail.USERS_LOADING_HIDE,
  };
};

export const fetchUsersAllowedToAddToContractDetailRequest = () => {
  return {
    type: ContractDetail.FETCH_USERS_ALLOWED_TO_ADD_TO_CONTRACT_REQUEST,
  };
};

export const fetchUsersAllowedToAddToContractDetailSuccess = (
  users: ResponseWithMeta<User[]>
) => {
  return {
    type: ContractDetail.FETCH_USERS_ALLOWED_TO_ADD_TO_SUCCESS_REQUEST,
    payload: users,
  };
};

export const setUsersPageContractDetail = (page: number) => {
  return {
    type: ContractDetail.SET_USERS_PAGE,
    payload: page,
  };
};

export const setUsersFilterContractDetail = (filter: UsersFilter) => {
  return {
    type: ContractDetail.SET_USERS_FILTER,
    payload: filter,
  };
};

export const resetUsersOnlyStateContractDetail = () => {
  return {
    type: ContractDetail.RESET_USERS_ALLOWED_TO_ADD_TO_CONTRACT_ONLY_STATE,
  };
};

// attachments
export const showAttachmentsLoadingContractDetail = () => {
  return {
    type: ContractDetail.ATTACHMENTS_LOADING_SHOW,
  };
};

export const hideAttachmentsLoadingContractDetail = () => {
  return {
    type: ContractDetail.ATTACHMENTS_LOADING_HIDE,
  };
};

export const fetchContractAttachmentsRequestContractDetail = (id: string) => {
  return {
    type: ContractDetail.FETCH_CONTRACT_ATTACHMENTS_REQUEST,
    payload: id,
  };
};

export const fetchContractAttachmentsSuccessContractDetail = (
  attachments: Attachment[]
) => {
  return {
    type: ContractDetail.FETCH_CONTRACT_ATTACHMENTS_SUCCESS,
    payload: attachments,
  };
};

export const addAttachmentToContractDetailRequest = (data: FormData) => {
  return {
    type: ContractDetail.ADD_ATTACHMENT_TO_CONTRACT_REQUEST,
    payload: data,
  };
};

export const resetOnlyAttachmentsStateContractDetail = () => {
  return {
    type: ContractDetail.RESET_ONLY_ATTACHMENTS_STATE,
  };
};

export const fetchEnvironmentsBySystemRequest = (
  data: ContractDetailPayload
): FetchEnvironmentsBySystemRequestAction => {
  return {
    type: ContractDetail.FETCH_ENVIRONMENTS_BY_SYSTEM_REQUEST,
    payload: data,
  };
};

export const fetchEnvironmentsBySystemSuccess = (
  environments: ResponseWithMeta<Environment[]>
): FetchEnvironmentsBySystemSuccessAction => {
  return {
    type: ContractDetail.FETCH_ENVIRONMENTS_BY_SYSTEM_SUCCESS,
    payload: environments,
  };
};

export const setPageEnvironmentsBySystem = (
  page: number
): SetPageEnvironmentsBySystemAction => {
  return {
    type: ContractDetail.SET_PAGE_ENVIRONMENTS_BY_SYSTEM,
    payload: page,
  };
};

export const setSizeEnvironmentsBySystem = (
  page: number
): SetSizeEnvironmentsBySystemAction => {
  return {
    type: ContractDetail.SET_SIZE_ENVIRONMENTS_BY_SYSTEM,
    payload: page,
  };
};

export const setFilterEnvironmentsBySystem = (
  filter: EnvironmentsFilter
): SetFilterEnvironmentsBySystemAction => {
  return {
    type: ContractDetail.SET_ENVIRONMENTS_BY_SYSTEM_FILTER,
    payload: filter,
  };
};

export const setSortEnvironmentsBySystem = (
  sort: string
): SetSortEnvironmentsBySystemAction => {
  return {
    type: ContractDetail.SET_SORT_ENVIRONMENTS_BY_SYSTEM,
    payload: sort,
  };
};

export const loadingShowEnvironmentsBySystem = () => {
  return {
    type: ContractDetail.LOADING_SHOW_ENVIRONMENTS_BY_SYSTEM,
  };
};

export const loadingHideEnvironmentsBySystem = () => {
  return {
    type: ContractDetail.LOADING_HIDE_ENVIRONMENTS_BY_SYSTEM,
  };
};

export const setSystemSelectedByContract = (
  payload?: System
): SetSelectSystemByContractAction => {
  return {
    type: ContractDetail.SET_SELECT_SYSTEM_BY_CONTRACT,
    payload,
  };
};

export const fetchEnvironmentSettingsBySystemRequest = (
  payload?: ContractIdSystemIdArgs
): FetchEnvironmentSettingsBySystemRequestAction => {
  return {
    type: ContractDetail.FETCH_ENVIRONMENT_SETTINGS_BY_SYSTEM_REQUEST,
    payload,
  };
};

export const fetchEnvironmentSettingsBySystemSuccess = (
  environmentSettings: EnvironmentSetting[]
): FetchEnvironmentSettingsBySystemSuccessAction => {
  return {
    type: ContractDetail.FETCH_ENVIRONMENT_SETTINGS_BY_SYSTEM_SUCCESS,
    payload: environmentSettings,
  };
};

export const fetchUpdateEnvironmentSettingsRequest = (
  payload: UpdateEnvironmentSettings
): FetchUpdateEnvironmentSettingsRequestAction => {
  return {
    type: ContractDetail.FETCH_UPDATE_ENVIRONMENT_SETTINGS_REQUEST,
    payload,
  };
};

export const fetchWorkGroupsByContractBySystemRequestContractDetail = (
  data: ContractDetailPayload
) => {
  return {
    type: ContractDetail.FETCH_WORK_GROUPS_BY_CONTRACT_BY_SYSTEM_REQUEST,
    payload: data,
  };
};

export const fetchWorkGroupsByContractBySystemSuccessContractDetail = (
  workGroups: ResponseWithMeta<WorkGroup[]>
) => {
  return {
    type: ContractDetail.FETCH_WORK_GROUPS_BY_CONTRACT_BY_SYSTEM_SUCCESS,
    payload: workGroups,
  };
};

export const loadingShowWorkGroupsByContractBySystemContractDetail = () => {
  return {
    type: ContractDetail.LOADING_SHOW_WORK_GROUPS_BY_CONTRACT_BY_SYSTEM,
  };
};

export const loadingHideWorkGroupsByContractBySystemContractDetail = () => {
  return {
    type: ContractDetail.LOADING_HIDE_WORK_GROUPS_BY_CONTRACT_BY_SYSTEM,
  };
};

export const setPageWorkGroupsByContractBySystemContractDetail = (
  page: number
) => {
  return {
    type: ContractDetail.SET_WORK_GROUPS_PAGE_BY_CONTRACT_BY_SYSTEM,
    payload: page,
  };
};

export const setFilterWorkGroupsByContractBySystemContractDetail = (
  filter: WorkGroupFilter
) => {
  return {
    type: ContractDetail.SET_WORK_GROUPS_FILTER_BY_CONTRACT_BY_SYSTEM,
    payload: filter,
  };
};

export const setSizePageWorkGroupsByContractBySystemContractDetail = (
  pageSize: number
) => {
  return {
    type: ContractDetail.SET_SIZE_PAGE_WORK_GROUPS_BY_CONTRACT_BY_SYSTEM,
    payload: pageSize,
  };
};

export const setSortWorkGroupsByContractBySystemContractDetail = (
  sort: string
) => {
  return {
    type: ContractDetail.SET_WORK_GROUPS_SORT_BY_CONTRACT_BY_SYSTEM,
    payload: sort,
  };
};

export const resetWorkGroupsByContractBySystemContractDetail = () => {
  return {
    type: ContractDetail.RESET_ONLY_WORK_GROUPS_BY_CONTRACT_BY_SYSTEM,
  };
};

export const fetchWorkGroupsByContractRequestContractDetail = (
  data: string
) => {
  return {
    type: ContractDetail.FETCH_WORK_GROUPS_BY_CONTRACT_REQUEST,
    payload: data,
  };
};

export const fetchWorkGroupsByContractSuccessContractDetail = (
  workGroups: ResponseWithMeta<WorkGroup[]>
) => {
  return {
    type: ContractDetail.FETCH_WORK_GROUPS_BY_CONTRACT_SUCCESS,
    payload: workGroups,
  };
};

export const loadingShowWorkGroupsByContractContractDetail = () => {
  return {
    type: ContractDetail.LOADING_SHOW_WORK_GROUPS_BY_CONTRACT,
  };
};

export const loadingHideWorkGroupsByContractContractDetail = () => {
  return {
    type: ContractDetail.LOADING_HIDE_WORK_GROUPS_BY_CONTRACT,
  };
};

export const setPageWorkGroupsByContractContractDetail = (page: number) => {
  return {
    type: ContractDetail.SET_WORK_GROUPS_PAGE_BY_CONTRACT,
    payload: page,
  };
};

export const setSizePageWorkGroupsByContractContractDetail = (size: number) => {
  return {
    type: ContractDetail.SET_WORK_GROUPS_SIZE_PAGE_BY_CONTRACT,
    payload: size,
  };
};

export const setFilterWorkGroupsByContractContractDetail = (
  filter: WorkGroupFilter
) => {
  return {
    type: ContractDetail.SET_WORK_GROUPS_FILTER_BY_CONTRACT,
    payload: filter,
  };
};

export const resetWorkGroupsByContractContractDetail = () => {
  return {
    type: ContractDetail.RESET_ONLY_WORK_GROUPS_BY_CONTRACT,
  };
};

export const updateWorkGroupsInContractInSystemContractDetail = (
  data: string[]
) => {
  return {
    type: ContractDetail.UPDATE_WORK_GROUPS_IN_CONTRACT_IN_SYSTEM,
    payload: data,
  };
};

export const loadingShowSLAPatternsContractDetail = () => {
  return {
    type: ContractDetail.LOADING_SHOW_SLA_PATTERNS_REQUEST,
  };
};

export const loadingHideSLAPatternsContractDetail = () => {
  return {
    type: ContractDetail.LOADING_HIDE_SLA_PATTERNS_REQUEST,
  };
};

export const fetchSLAPatternsRequestContractDetail = (filter?: string) => {
  return {
    type: ContractDetail.FETCH_SLA_PATTERNS_REQUEST,
    payload: filter,
  };
};

export const fetchSLAPatternsSuccessContractDetail = (data: SLAType[]) => {
  return {
    type: ContractDetail.FETCH_SLA_PATTERNS_SUCCESS,
    payload: data,
  };
};

export const createSLAPatternRequestContractDetail = (sla: SLAType) => {
  return {
    type: ContractDetail.CREATE_SLA_PATTERN_REQUEST,
    payload: sla,
  };
};

export const resetSLAPatternsContractDetail = () => {
  return {
    type: ContractDetail.RESET_SLA_PATTERNS,
  };
};

export const checkIsExistPatternTitleRequestContractDetail = (
  title: string
) => {
  return {
    type: ContractDetail.CHECK_IS_EXIST_PATTERN_TITLE_REQUEST,
    payload: title,
  };
};

export const checkIsExistPatternTitleSuccessContractDetail = (
  isExist: boolean
) => {
  return {
    type: ContractDetail.CHECK_IS_EXIST_PATTERN_TITLE_SUCCESS,
    payload: isExist,
  };
};

export const fetchPatternByIdRequestContractDetail = (id: string) => {
  return {
    type: ContractDetail.FETCH_PATTERN_BY_ID_REQUEST,
    payload: id,
  };
};

export const fetchPatternByIdSuccessContractDetail = (sla: SLAType) => {
  return {
    type: ContractDetail.FETCH_PATTERN_BY_ID_SUCCESS,
    payload: sla,
  };
};

export const fetchSlaByContractIdBySystemIdRequestContractDetail = (
  data: ContractDetailPayload
) => {
  return {
    type: ContractDetail.FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST,
    payload: data,
  };
};

export const fetchSlaByContractIdBySystemIdSuccessContractDetail = (
  sla: SLAType
) => {
  return {
    type: ContractDetail.FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_SUCCESS,
    payload: sla,
  };
};

export const fetchTicketTypeSettingsRequestContractDetail = (
  payload: ContractIdSystemIdArgs
) => {
  return {
    type: ContractDetail.FETCH_TICKET_TYPE_SETTINGS_REQUEST,
    payload,
  };
};

export const fetchTicketTypeSettingsSuccessContractDetail = (
  payload: TicketTypeSettings[]
) => {
  return {
    type: ContractDetail.FETCH_TICKET_TYPE_SETTINGS_SUCCESS,
    payload,
  };
};

export const setLoadingTicketTypeSettingsContractDetail = (
  payload: boolean
) => {
  return {
    type: ContractDetail.SET_LOADING_TICKET_TYPE_SETTINGS,
    payload,
  };
};

export const fetchTicketTypesRequestContractDetail = (payload: string) => {
  return {
    type: ContractDetail.FETCH_TICKET_TYPES_REQUEST,
    payload,
  };
};

export const fetchTicketTypesSuccessContractDetail = (
  payload: TicketType[]
) => {
  return {
    type: ContractDetail.FETCH_TICKET_TYPES_SUCCESS,
    payload,
  };
};

export const setSelectedSystemSlaContractDetail = (payload?: string) => {
  return {
    type: ContractDetail.SET_SELECTED_SYSTEM_SLA,
    payload,
  };
};

export const loadingShowSlaByContractIdBySystemIdRequestContractDetail = () => {
  return {
    type: ContractDetail.LOADING_SHOW_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST,
  };
};

export const loadingHideSlaByContractIdBySystemIdRequestContractDetail = () => {
  return {
    type: ContractDetail.LOADING_HIDE_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST,
  };
};

export const attachSystemAndSLAToContractDetail = (
  data: AttachSystemAndSLAToContractType
) => {
  return {
    type: ContractDetail.ATTACH_SYSTEM_AND_SLA_TO_CONTRACT,
    payload: data,
  };
};

export const updateContractSLARequestContractDetail = (
  data: AttachSystemAndSLAToContractType
) => {
  return {
    type: ContractDetail.UPDATE_CONTRACT_SLA_REQUEST,
    payload: data,
  };
};
