import { SVGProps } from 'react';

import { SelectOption, Tab, ValueType } from 'components';
import {
  KeyValueOption,
  Pagination,
  UpdateContentType,
} from 'types/models/meta';

export type UserTabType = {
  title: string | JSX.Element;
  icon: SVGProps<SVGElement>;
  classNameIconWrapper: string;
  path?: string;
  onClick: () => void;
  hasCircle?: boolean;
  isHide?: boolean;
  circleClassName?: string;
};

export type FetchParams = {
  updateType: UpdateContentType;
} & Pick<Pagination, 'pageNum'>;

export type FetchNotificationsParams = FetchParams;

export type FetchNewsParams = {
  published?: boolean;
} & FetchParams;

export type FilterNotificationsProfile = {
  dateFrom?: Date;
  dateTo?: Date;
  notificationType?: string | null;
  notChecked?: boolean;
};

export type HasUncheckedNotificationsType = {
  notificationType?: string | null;
  hasUnchecked?: boolean;
  to?: string | Record<string, never>;
};

export type ProfileTabSettings = {
  userId?: string;
  titleNotification?: string | null;
  tabGroup?: string;
  description?: string;
  notifyByEmail?: boolean;
  notifyByPush?: boolean;
};

export type ProfileSettings = {
  mainNotificationConfigs: ProfileTabSettings[];
  workgroupsNotificationIsEnable?: boolean;
  workgroupsNotifications?: KeyValueOption[];
};

export type News = {
  id?: string;
  title: string;
  text: string;
  userId: string;
  created: string;
  checked: boolean;
  organizations?: KeyValueOption[];
  workgroups?: KeyValueOption[];
  publicationDate?: string;
  published: boolean;
  publishNow: boolean;
};

export type NewsCreateData = {
  organizationId?: string[];
  workGroupId?: string[];
} & Pick<News, 'id' | 'title' | 'text'> &
  Pick<Partial<News>, 'published'>;

export interface NewsCreateForm
  extends Omit<NewsCreateData, 'organizationId' | 'workGroupId'> {
  recipient: ValueType<RecipientType>;
  publicationType: ValueType<PublicationType>;
  organizationsIds?: SelectOption[];
  workgroupsIds?: SelectOption[];
  publicationDate?: string;
}

export type FetchNews = {
  sort?: string;
} & Pick<NewsCreateData, 'published'> &
  Pick<Pagination, 'pageNum' | 'pageSize'>;

export type NewsDelete = {
  id: string;
} & Pick<NewsCreateData, 'published'>;

export interface WorkGroupsData {
  workGroupsIds: ValueType<string>;
  withNotification: boolean;
}

export interface SettingsData {
  password: string;
  passwordSecond: string;
}

export type ProfileTab = Partial<Tab> & Partial<ProfileTabSettings>;

export type NotifyType = 'push' | 'email';

interface ElementConfig {
  checked: boolean;
  notifyType: NotifyType;
}

export interface SwitchSection extends ElementConfig {
  tabGroup: string;
}

export interface ClickElem extends ElementConfig {
  titleNotification: string;
}

export enum RecipientType {
  ALL = 'ALL',
  SELECTIVELY = 'SELECTIVELY',
}

export enum PublicationType {
  CURRENTLY = 'CURRENTLY',
  BY_TIME = 'BY_TIME',
}
