import cn from 'clsx';
import { FC, MouseEvent } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { NotificationCircle } from '../NotificationCircle';

import styles from './NotificationLink.module.scss';

export interface NotificationLinkProps extends LinkProps {
  withNotification?: boolean;
  newTicketNotification?: boolean;
}

export const NotificationLink: FC<NotificationLinkProps> = ({
  to,
  title,
  withNotification,
  newTicketNotification,
  onMouseDown,
  ...otherProps
}) => {
  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    if (onMouseDown) {
      onMouseDown(event);
    }
  };
  return (
    <Link
      to={to}
      onMouseDown={handleClick}
      onClick={(e) => e.stopPropagation()}
      className={styles.notificationLink}
      tabIndex={-1}
      {...otherProps}
    >
      {title}
      <NotificationCircle
        newTicketNotification={newTicketNotification}
        className={cn(styles.notificationLink__circle, {
          [styles.notificationLink__circle_hide]: !withNotification,
        })}
      />
    </Link>
  );
};
