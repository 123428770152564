import { createSelector } from 'reselect';

import { getArraySelectOptions, SelectOption } from 'components';
import { Storage } from 'core/types/storage';
import { RootState } from 'store/rootReducer';
import { KeyValueOption } from 'types/models/meta';

export const getOrganizations = (state: RootState) =>
  state.storages.organizations;
export const getOrganizationsOptions = createSelector<
  RootState,
  KeyValueOption[] | undefined,
  SelectOption[]
>([getOrganizations], (organizations): SelectOption[] => {
  if (organizations) {
    return organizations.map(
      (organization): SelectOption => ({
        title: organization.value,
        value: organization.key,
      })
    );
  }
  return [];
});

export const getWorkGroupsViewers = (state: RootState) =>
  state.storages.workGroupsViewers;
export const getWorkGroupsViewersOptions = createSelector<
  RootState,
  KeyValueOption[] | undefined,
  SelectOption[]
>([getWorkGroupsViewers], (viewers): SelectOption[] => {
  if (viewers) {
    return viewers.map(
      (viewer): SelectOption => ({
        title: viewer.value,
        value: viewer.key,
      })
    );
  }
  return [];
});

export const getWorkGroupsEditors = (state: RootState) =>
  state.storages.workGroupsEditors;
export const getWorkGroupsEditorsOptions = createSelector<
  RootState,
  KeyValueOption[] | undefined,
  SelectOption[]
>([getWorkGroupsEditors], (editors): SelectOption[] => {
  if (editors) {
    return editors.map(
      (editor): SelectOption => ({
        title: editor.value,
        value: editor.key,
      })
    );
  }
  return [];
});

export const getOrganizationsForCreate = (state: RootState) =>
  state.storages.organizationsForCreate;
export const getOrganizationsForCreateOptions = createSelector<
  RootState,
  KeyValueOption[] | undefined,
  SelectOption[]
>([getOrganizationsForCreate], (organizationsForCreate): SelectOption[] => {
  if (organizationsForCreate) {
    return organizationsForCreate.map(
      (organization): SelectOption => ({
        title: organization.value,
        value: organization.key,
      })
    );
  }
  return [];
});

export const getOrganizationIdsViewers = (state: RootState) =>
  state.storages.organizationIdsViewers;

export const getOrganizationIdsEditors = (state: RootState) =>
  state.storages.organizationIdsEditors;

export const getStorages = (state: RootState) => state.storages.storages;
export const getStoragesContent = (state: RootState) =>
  state.storages.storages?.content;
export const getStoragesOptions = createSelector<
  RootState,
  Storage[] | undefined,
  SelectOption[]
>([getStoragesContent], (storages): SelectOption[] => {
  return getArraySelectOptions(storages);
});

export const getAdminOrganizationId = (state: RootState) =>
  state.storages.adminOrganizationId;

export const getPaginationParams = (state: RootState) => ({
  totalPages: state.storages.storages?.totalPages,
  pageNum: state.storages.storages?.page,
  loading: state.storages.loadingStorages,
});

export const getPropsOrganization = (state: RootState) => ({
  pageNum: state.storages?.paginationOrganization?.pageNum,
  pageSize: state.storages?.paginationOrganization?.pageSize,
  totalElements: state.storages.paginationOrganization?.totalElements,
  sort: state.storages?.sortSelect,
  loading: state.storages.loadingOrganization,
});

export const getPropsViewersGroups = (state: RootState) => ({
  pageNum: state.storages?.paginationGroupsViewers?.pageNum,
  pageSize: state.storages?.paginationGroupsViewers?.pageSize,
  totalElements: state.storages.paginationGroupsViewers?.totalElements,
  sort: state.storages?.sortSelect,
  loading: state.storages.loadingGroupsViewers,
});

export const getPropsEditorsGroups = (state: RootState) => ({
  pageNum: state.storages?.paginationGroupsEditors?.pageNum,
  pageSize: state.storages?.paginationGroupsEditors?.pageSize,
  totalElements: state.storages.paginationGroupsEditors?.totalElements,
  sort: state.storages?.sortSelect,
  loadingEditorsGroups: state.storages.loadingEditorsGroups,
});
