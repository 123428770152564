import { SystemPriority } from 'core/types/system';
import { ResponseWithMeta } from 'store/types';

import {
  CheckConnectionToJiraData,
  FetchArgs,
  FetchInfoForJiraIntegrationPayload,
  Field,
  FieldsIntegration,
  JiraIntegration,
  JiraIntegrationCreateDataToRequest,
  JiraIntegrationEditDataToRequest,
  JiraIntegrationsFilter,
  JiraPriority,
  JiraStatus,
  JiraStatusesRequest,
  JiraTicketType,
  Status,
  TicketTypes,
} from '../types';

import {
  CreateFieldsIntegrationAction,
  CreateJiraIntegrationAction,
  DeleteFieldsIntegrationAction,
  DeleteJiraIntegrationAction,
  EditFieldsIntegrationAction,
  EditJiraIntegrationAction,
  EditJiraIntegrationsActiveAction,
  EditJiraIntegrationsActivePayload,
  FetchFieldsIntegrationsRequestAction,
  FetchFieldsIntegrationsSuccessAction,
  FetchInfoForJiraIntegrationAction,
  FetchJiraFieldsRequestAction,
  FetchJiraFieldsSuccessAction,
  FetchJiraIntegrationRequestAction,
  FetchJiraIntegrationsRequestAction,
  FetchJiraIntegrationsSuccessAction,
  FetchJiraIntegrationSuccessAction,
  FetchJiraPrioritiesByIdAction,
  FetchJiraPrioritiesByIdSuccessAction,
  FetchJiraPrioritiesByIntegrationIdRequestAction,
  FetchJiraPrioritiesByIntegrationIdSuccessAction,
  FetchJiraPrioritiesRequestAction,
  FetchJiraPrioritiesSuccessAction,
  FetchJiraSPStatusesForCurrentSuccessAction,
  FetchJiraSPStatusesRequestAction,
  FetchJiraSPStatusesSuccessAction,
  FetchJiraStatusesByIdAction,
  FetchJiraStatusesByIdSuccessAction,
  FetchJiraStatusesRequestAction,
  FetchJiraStatusesSuccessAction,
  FetchJiraTicketTypeByIntegrationIdRequestAction,
  FetchJiraTicketTypeByIntegrationIdSuccessAction,
  FetchJiraTicketTypesByIntegrationIdRequestAction,
  FetchJiraTicketTypesByIntegrationIdSuccessAction,
  FetchJiraTicketTypesRequestAction,
  FetchJiraTicketTypesSuccessAction,
  FetchSpFieldsRequestAction,
  FetchSpFieldsSuccessAction,
  FetchSystemPrioritiesRequestAction,
  FetchSystemPrioritiesSuccessAction,
  FetchTicketTypesRequestAction,
  FetchTicketTypesSuccessAction,
  HideJiraIntegrationInfoLoadingAction,
  HideJiraIntegrationsLoadingAction,
  HidePrioritiesLoadingAction,
  HideStatusesLoadingAction,
  Jira,
  ResetJiraPrioritiesAction,
  ResetJiraStateAction,
  ResetJiraStatusesAction,
  ResetSPStatusesSuccessAction,
  ResetSystemPrioritiesAction,
  SetCurrentJiraIntegrationIdAction,
  SetCurrentJiraIntegrationsPageAction,
  SetCurrentSystemIdAction,
  SetCurrentTypeIdAction,
  SetJiraIntegrationsFilterAction,
  SetSizeJiraIntegrationsPageAction,
  SetSortJiraIntegrationsAction,
  ShowJiraIntegrationInfoLoadingAction,
  ShowJiraIntegrationsLoadingAction,
  ShowPrioritiesLoadingAction,
  ShowStatusesLoadingAction,
  UpdateJiraIntegrationByIdAction,
} from './types';

export const showJiraIntegrationsLoading =
  (): ShowJiraIntegrationsLoadingAction => {
    return {
      type: Jira.JIRA_INTEGRATIONS_LOADING_SHOW,
    };
  };

export const hideJiraIntegrationsLoading =
  (): HideJiraIntegrationsLoadingAction => {
    return {
      type: Jira.JIRA_INTEGRATIONS_LOADING_HIDE,
    };
  };

export const fetchJiraIntegrationsRequest =
  (): FetchJiraIntegrationsRequestAction => {
    return {
      type: Jira.FETCH_JIRA_INTEGRATIONS_REQUEST,
    };
  };

export const fetchJiraIntegrationsSuccess = (
  data: ResponseWithMeta<JiraIntegration[]>
): FetchJiraIntegrationsSuccessAction => {
  return {
    type: Jira.FETCH_JIRA_INTEGRATIONS_SUCCESS,
    payload: data,
  };
};

export const setCurrentJiraIntegrationsPage = (
  page: number
): SetCurrentJiraIntegrationsPageAction => {
  return {
    type: Jira.SET_CURRENT_JIRA_INTEGRATIONS_PAGE,
    payload: page,
  };
};

export const setSizeJiraIntegrationsPage = (
  size: number
): SetSizeJiraIntegrationsPageAction => {
  return {
    type: Jira.SET_SIZE_JIRA_INTEGRATIONS_PAGE,
    payload: size,
  };
};

export const setSortJiraIntegrations = (
  sort: string
): SetSortJiraIntegrationsAction => {
  return {
    type: Jira.SET_SORT_JIRA_INTEGRATIONS,
    payload: sort,
  };
};

export const setJiraIntegrationsFilter = (
  filter?: JiraIntegrationsFilter
): SetJiraIntegrationsFilterAction => {
  return {
    type: Jira.SET_FILTER_JIRA_INTEGRATIONS,
    payload: filter,
  };
};

export const resetJiraState = (): ResetJiraStateAction => {
  return {
    type: Jira.RESET_JIRA_STATE,
  };
};

export const fetchJiraStatusesRequest = (
  payload: JiraStatusesRequest
): FetchJiraStatusesRequestAction => {
  return {
    type: Jira.FETCH_JIRA_STATUSES_REQUEST,
    payload,
  };
};

export const fetchJiraStatusesSuccess = (
  payload: JiraStatus[]
): FetchJiraStatusesSuccessAction => {
  return {
    type: Jira.FETCH_JIRA_STATUSES_SUCCESS,
    payload,
  };
};

export const fetchJiraPrioritiesRequest = (
  payload: CheckConnectionToJiraData
): FetchJiraPrioritiesRequestAction => {
  return {
    type: Jira.FETCH_JIRA_PRIORITIES_REQUEST,
    payload,
  };
};

export const fetchJiraPrioritiesSuccess = (
  payload: JiraPriority[]
): FetchJiraPrioritiesSuccessAction => {
  return {
    type: Jira.FETCH_JIRA_PRIORITIES_SUCCESS,
    payload,
  };
};

export const fetchJiraPrioritiesByIntegrationIdRequest = (
  payload: FetchArgs
): FetchJiraPrioritiesByIntegrationIdRequestAction => {
  return {
    type: Jira.FETCH_JIRA_PRIORITIES_BY_INTEGRATION_ID_REQUEST,
    payload,
  };
};

export const fetchJiraPrioritiesByIntegrationIdSuccess = (
  payload: JiraPriority[]
): FetchJiraPrioritiesByIntegrationIdSuccessAction => {
  return {
    type: Jira.FETCH_JIRA_PRIORITIES_BY_INTEGRATION_ID_SUCCESS,
    payload,
  };
};

export const setCurrentJiraIntegrationId = (
  payload?: string
): SetCurrentJiraIntegrationIdAction => {
  return {
    type: Jira.SET_CURRENT_JIRA_INTEGRATION_ID,
    payload,
  };
};

export const setCurrentSystemId = (
  payload?: string
): SetCurrentSystemIdAction => {
  return {
    type: Jira.SET_CURRENT_SYSTEM_ID,
    payload,
  };
};

export const createJiraIntegration = (
  payload: JiraIntegrationCreateDataToRequest
): CreateJiraIntegrationAction => {
  return {
    type: Jira.CREATE_JIRA_INTEGRATION,
    payload,
  };
};

export const editJiraIntegration = (
  payload: JiraIntegrationEditDataToRequest
): EditJiraIntegrationAction => {
  return {
    type: Jira.EDIT_JIRA_INTEGRATION,
    payload,
  };
};

export const resetJiraStatuses = (): ResetJiraStatusesAction => {
  return {
    type: Jira.RESET_JIRA_STATUSES,
  };
};

export const resetJiraPriorities = (): ResetJiraPrioritiesAction => {
  return {
    type: Jira.RESET_JIRA_PRIORITIES,
  };
};

export const fetchJiraStatusesById = (
  payload: FetchArgs
): FetchJiraStatusesByIdAction => {
  return {
    type: Jira.FETCH_JIRA_STATUSES_BY_ID_REQUEST,
    payload,
  };
};

export const fetchJiraPrioritiesById = (
  payload: FetchArgs
): FetchJiraPrioritiesByIdAction => {
  return {
    type: Jira.FETCH_JIRA_PRIORITIES_BY_ID_REQUEST,
    payload,
  };
};

export const showStatusesLoading = (): ShowStatusesLoadingAction => {
  return {
    type: Jira.STATUSES_LOADING_SHOW,
  };
};

export const hideStatusesLoading = (): HideStatusesLoadingAction => {
  return {
    type: Jira.STATUSES_LOADING_HIDE,
  };
};

export const showPrioritiesLoading = (): ShowPrioritiesLoadingAction => {
  return {
    type: Jira.PRIORITIES_LOADING_SHOW,
  };
};

export const hidePrioritiesLoading = (): HidePrioritiesLoadingAction => {
  return {
    type: Jira.PRIORITIES_LOADING_HIDE,
  };
};

export const fetchJiraPrioritiesByIdSuccess = (
  payload: JiraPriority[]
): FetchJiraPrioritiesByIdSuccessAction => {
  return {
    type: Jira.FETCH_JIRA_PRIORITIES_BY_ID_SUCCESS,
    payload,
  };
};

export const fetchJiraStatusesByIdSuccess = (
  payload: JiraStatus[]
): FetchJiraStatusesByIdSuccessAction => {
  return {
    type: Jira.FETCH_JIRA_STATUSES_BY_ID_SUCCESS,
    payload,
  };
};

export const deleteJiraIntegration = (
  id: string
): DeleteJiraIntegrationAction => {
  return {
    type: Jira.DELETE_JIRA_INTEGRATION,
    payload: id,
  };
};

export const fetchJiraIntegrationRequest = (
  id: string
): FetchJiraIntegrationRequestAction => {
  return {
    type: Jira.FETCH_JIRA_INTEGRATION_REQUEST,
    payload: id,
  };
};

export const fetchJiraIntegrationSuccess = (
  data?: JiraIntegration
): FetchJiraIntegrationSuccessAction => {
  return {
    type: Jira.FETCH_JIRA_INTEGRATION_SUCCESS,
    payload: data,
  };
};

export const editJiraIntegrationsActive = (
  payload: EditJiraIntegrationsActivePayload
): EditJiraIntegrationsActiveAction => {
  return {
    type: Jira.EDIT_JIRA_INTEGRATION_ACTIVE,
    payload,
  };
};

export const updateJiraIntegrationById = (
  payload: JiraIntegration
): UpdateJiraIntegrationByIdAction => {
  return {
    type: Jira.UPDATE_JIRA_INTEGRATION_BY_ID,
    payload,
  };
};

export const fetchSpFieldsRequest = (
  payload: FetchArgs
): FetchSpFieldsRequestAction => {
  return {
    type: Jira.FETCH_SP_FIELDS_REQUEST,
    payload,
  };
};

export const fetchSpFieldsSuccess = (
  payload: Field[]
): FetchSpFieldsSuccessAction => {
  return {
    type: Jira.FETCH_SP_FIELDS_SUCCESS,
    payload,
  };
};

export const fetchJiraFieldsRequest = (
  payload: FetchArgs
): FetchJiraFieldsRequestAction => {
  return {
    type: Jira.FETCH_JIRA_FIELDS_REQUEST,
    payload,
  };
};

export const fetchJiraFieldsSuccess = (
  payload: Field[]
): FetchJiraFieldsSuccessAction => {
  return {
    type: Jira.FETCH_JIRA_FIELDS_SUCCESS,
    payload,
  };
};

export const fetchInfoForJiraIntegration = (
  payload: FetchInfoForJiraIntegrationPayload
): FetchInfoForJiraIntegrationAction => {
  return {
    type: Jira.FETCH_INFO_FOR_JIRA_INTEGRATION,
    payload,
  };
};

export const showJiraIntegrationInfoLoading =
  (): ShowJiraIntegrationInfoLoadingAction => {
    return {
      type: Jira.JIRA_INTEGRATION_INFO_LOADING_SHOW,
    };
  };

export const hideJiraIntegrationInfoLoading =
  (): HideJiraIntegrationInfoLoadingAction => {
    return {
      type: Jira.JIRA_INTEGRATION_INFO_LOADING_HIDE,
    };
  };

export const fetchFieldsIntegrationsRequest = (
  payload: FetchArgs
): FetchFieldsIntegrationsRequestAction => {
  return {
    type: Jira.FETCH_FIELDS_INTEGRATIONS_REQUEST,
    payload,
  };
};

export const fetchFieldsIntegrationsSuccess = (
  payload: FieldsIntegration[]
): FetchFieldsIntegrationsSuccessAction => {
  return {
    type: Jira.FETCH_FIELDS_INTEGRATIONS_SUCCESS,
    payload,
  };
};

export const createFieldsIntegration = (
  data: FieldsIntegration
): CreateFieldsIntegrationAction => {
  return {
    type: Jira.CREATE_FIELDS_INTEGRATION,
    payload: data,
  };
};

export const deleteFieldsIntegration = (
  id: string
): DeleteFieldsIntegrationAction => {
  return {
    type: Jira.DELETE_FIELDS_INTEGRATION,
    payload: id,
  };
};

export const editFieldsIntegration = (
  data: FieldsIntegration
): EditFieldsIntegrationAction => {
  return {
    type: Jira.EDIT_FIELDS_INTEGRATION,
    payload: data,
  };
};

export const fetchSystemPrioritiesRequest = (
  systemId: string
): FetchSystemPrioritiesRequestAction => {
  return {
    type: Jira.FETCH_SYSTEM_PRIORITIES_REQUEST,
    payload: systemId,
  };
};

export const fetchSystemPrioritiesSuccess = (
  priorities: SystemPriority[]
): FetchSystemPrioritiesSuccessAction => {
  return {
    type: Jira.FETCH_SYSTEM_PRIORITIES_SUCCESS,
    payload: priorities,
  };
};

export const fetchSPStatusesRequest = (
  payload: FetchArgs
): FetchJiraSPStatusesRequestAction => {
  return {
    type: Jira.FETCH_JIRA_SP_STATUSES_REQUEST,
    payload,
  };
};

export const fetchSPStatusesSuccess = (
  payload: Status[]
): FetchJiraSPStatusesSuccessAction => {
  return {
    type: Jira.FETCH_JIRA_SP_STATUSES_SUCCESS,
    payload,
  };
};

export const resetSPStatuses = (): ResetSPStatusesSuccessAction => {
  return {
    type: Jira.RESET_JIRA_SP_STATUSES,
  };
};

export const fetchSPStatusesForCurrentSuccess = (
  payload: Status[]
): FetchJiraSPStatusesForCurrentSuccessAction => {
  return {
    type: Jira.FETCH_JIRA_SP_STATUSES_FOR_CURRENT_SUCCESS,
    payload,
  };
};

export const resetSystemPriorities = (): ResetSystemPrioritiesAction => {
  return {
    type: Jira.RESET_SYSTEM_PRIORITIES,
  };
};

export const fetchTicketTypeRequest = (
  payload: string
): FetchTicketTypesRequestAction => {
  return {
    type: Jira.FETCH_TICKET_TYPES_REQUEST,
    payload,
  };
};

export const fetchTicketTypeSuccess = (
  payload: TicketTypes[]
): FetchTicketTypesSuccessAction => {
  return {
    type: Jira.FETCH_TICKET_TYPES_SUCCESS,
    payload,
  };
};

export const fetchJiraTicketTypeRequest = (
  payload: CheckConnectionToJiraData
): FetchJiraTicketTypesRequestAction => {
  return {
    type: Jira.FETCH_JIRA_TICKET_TYPES_REQUEST,
    payload,
  };
};

export const fetchJiraTicketTypeSuccess = (
  payload: JiraTicketType[]
): FetchJiraTicketTypesSuccessAction => {
  return {
    type: Jira.FETCH_JIRA_TICKET_TYPES_SUCCESS,
    payload,
  };
};

export const fetchJiraTicketTypesByIntegrationIdRequest = (
  payload: string
): FetchJiraTicketTypesByIntegrationIdRequestAction => {
  return {
    type: Jira.FETCH_JIRA_TICKET_TYPES_BY_INTEGRATION_ID_REQUEST,
    payload,
  };
};

export const fetchJiraTicketTypesByIntegrationIdSuccess = (
  payload: JiraTicketType[]
): FetchJiraTicketTypesByIntegrationIdSuccessAction => {
  return {
    type: Jira.FETCH_JIRA_TICKET_TYPES_BY_INTEGRATION_ID_SUCCESS,
    payload,
  };
};

export const fetchJiraTicketTypeByIntegrationIdRequest = (
  payload: string
): FetchJiraTicketTypeByIntegrationIdRequestAction => {
  return {
    type: Jira.FETCH_JIRA_TICKET_TYPE_BY_INTEGRATION_ID_REQUEST,
    payload,
  };
};

export const fetchJiraTicketTypeByIntegrationIdSuccess = (
  payload: JiraTicketType[]
): FetchJiraTicketTypeByIntegrationIdSuccessAction => {
  return {
    type: Jira.FETCH_JIRA_TICKET_TYPE_BY_INTEGRATION_ID_SUCCESS,
    payload,
  };
};

export const setCurrentTypeId = (payload?: string): SetCurrentTypeIdAction => {
  return {
    type: Jira.SET_CURRENT_TYPE_ID,
    payload,
  };
};
