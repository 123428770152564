import { Priority } from 'core/types';
import { isNonNil } from 'utils';

import { JiraPriority } from '../types';

export const getPreparedPriorities = (jiraPriority?: JiraPriority[]) => {
  return Object.values(Priority)
    .map((priority) => {
      const existingTicketPriority = jiraPriority?.find((item) =>
        item?.ticketPriorities?.find((el) => el.includes(priority))
      );

      if (!existingTicketPriority) {
        return undefined;
      }

      return {
        ...existingTicketPriority,
        ticketPriorities: [priority],
      };
    })
    .filter(isNonNil);
};
