import { createSelector } from 'reselect';

import { getArraySelectOptions, SelectOption } from 'components';
import { CategoryArticle } from 'core/types/knowledgeBase';
import { Organization } from 'core/types/organization';
import { WorkGroup } from 'core/types/workGroup';
import { RootState } from 'store/rootReducer';

export const getArticleCreateOrganizations = (state: RootState) =>
  state.articleCreate.organizations;
export const getArticleCreateCategories = (state: RootState) =>
  state.articleCreate.categories;
export const getSubCategoriesArticleCreate = (state: RootState) =>
  state.articleCreate.subCategories;
export const getCurrentArticle = (state: RootState) =>
  state.articleCreate.currentArticle;
export const getCurrentArticleLoading = (state: RootState) =>
  state.articleCreate.currentArticleLoading;
export const getWorkGroups = (state: RootState) =>
  state.articleCreate.workGroups;

export const getArticleOrganizationsSelectList = createSelector<
  RootState,
  Organization[] | undefined,
  SelectOption[]
>([getArticleCreateOrganizations], (organizations): SelectOption[] => {
  return getArraySelectOptions(organizations);
});

export const getArticleWorkGroupsSelectList = createSelector<
  RootState,
  WorkGroup[] | undefined,
  SelectOption[]
>([getWorkGroups], (workGroups): SelectOption[] => {
  return getArraySelectOptions(workGroups);
});

export const getArticleCategoriesSelectList = createSelector<
  RootState,
  CategoryArticle[] | undefined,
  SelectOption[]
>([getArticleCreateCategories], (categories): SelectOption[] => {
  return getArraySelectOptions(categories);
});

export const getSubCategoriesSelectList = createSelector<
  RootState,
  CategoryArticle[] | undefined,
  SelectOption[]
>([getSubCategoriesArticleCreate], (subCategories): SelectOption[] => {
  return getArraySelectOptions(subCategories);
});
