import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects';

import { Improvement, TicketEvaluation } from 'features/Ticket/types';
import { createError } from 'utils';

import {
  fetchImprovementsSuccess,
  fetchTicketEvaluationSuccess,
} from './actions';
import { request } from './api/requests';
import {
  AssessmentInteraction,
  EvaluateSpecialistAction,
  FetchImprovementsRequestAction,
  FetchTicketEvaluationRequestAction,
} from './types';

function* ticketEvaluationFetch({
  payload,
}: FetchTicketEvaluationRequestAction) {
  try {
    const ticketEvaluation: TicketEvaluation = yield call(
      request.fetchTicketEvaluation,
      payload
    );
    yield put(fetchTicketEvaluationSuccess(ticketEvaluation));
  } catch (e) {
    createError(e);
  }
}

function* improvementsFetch({ payload }: FetchImprovementsRequestAction) {
  try {
    const improvements: Improvement[] = yield call(
      request.fetchImprovements,
      payload
    );
    yield put(fetchImprovementsSuccess(improvements));
  } catch (e) {
    createError(e);
  }
}

function* evaluateSpecialist({ payload }: EvaluateSpecialistAction) {
  try {
    yield call(request.evaluateSpecialist, payload);
    yield call(ticketEvaluationFetch, {
      type: AssessmentInteraction.FETCH_TICKET_EVALUATION_REQUEST,
      payload: payload.ticketId,
    });
  } catch (e) {
    createError(e);
  }
}

export function* assessmentInteractionSaga(): Generator<StrictEffect> {
  yield takeEvery(
    AssessmentInteraction.FETCH_IMPROVEMENTS_REQUEST,
    improvementsFetch
  );
  yield takeEvery(
    AssessmentInteraction.EVALUATE_SPECIALIST,
    evaluateSpecialist
  );
  yield takeEvery(
    AssessmentInteraction.FETCH_TICKET_EVALUATION_REQUEST,
    ticketEvaluationFetch
  );
}
