import cn from 'clsx';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LazyLoader } from 'core/components';
import { TableRolesTypes } from 'core/types/roles';
import { TableWorkGroupsTypes } from 'core/types/workGroup';
import {
  fetchRolesByUserIdRequest,
  resetRolesState,
  RolesTableContainer,
} from 'features/Roles';
import {
  fetchCurrentUser,
  getLoadingUser,
  getUser,
  resetUsersState,
  setUserId,
} from 'features/Users';
import {
  fetchGroupsByUserIdRequest,
  resetWorkGroupsState,
  WorkGroupsTableContainer,
} from 'features/WorkGroups';

import { UserForm } from './components';
import { useUserId } from './hooks';
import styles from './User.module.scss';

interface UserProps {
  className?: string;
}

export const User: FC<UserProps> = ({ className }) => {
  const userId = useUserId();

  const loading = useSelector(getLoadingUser);
  const user = useSelector(getUser);

  const dispatch = useDispatch();

  useEffect(() => {
    if (userId) {
      dispatch(setUserId(userId));
      dispatch(fetchGroupsByUserIdRequest());
      dispatch(fetchRolesByUserIdRequest());
      dispatch(fetchCurrentUser(userId));
    }
  }, [userId]);

  useEffect(
    () => () => {
      dispatch(resetWorkGroupsState());
      dispatch(resetUsersState());
      dispatch(resetRolesState());
    },
    []
  );

  if (loading && !user) {
    return <LazyLoader className={styles.user__loader} />;
  }

  return (
    <div className={cn(styles.user, className)}>
      <div className={styles.user__leftContainer}>
        <UserForm />
      </div>
      <div className={styles.user__rightContainer}>
        <WorkGroupsTableContainer
          tableType={TableWorkGroupsTypes.FROM_USERS}
          className={styles.user__tableContainer}
        />
        <RolesTableContainer
          tableType={TableRolesTypes.FROM_USERS}
          className={styles.user__tableContainer}
        />
      </div>
    </div>
  );
};
