import { getValueFromValueType, ValueType } from 'components';
import { convertKeyValueOptionToArraySelectOption } from 'core/utils';

import { TriggerAttributes } from '../types';

interface Args {
  attributesFields: TriggerAttributes;
  attribute: ValueType<string>;
}

export const getAttributesValueOptions = ({
  attributesFields,
  attribute,
}: Args) => {
  const optionValue = getValueFromValueType(attribute) || '';
  const fields = attributesFields[optionValue as keyof TriggerAttributes];

  return convertKeyValueOptionToArraySelectOption(fields) || [];
};
