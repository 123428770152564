import cn from 'clsx';
import { FC, HTMLAttributes } from 'react';

import styles from './PopoverContainer.module.scss';

interface PopoverContainerProps extends HTMLAttributes<HTMLDivElement> {}

export const PopoverContainer: FC<PopoverContainerProps> = ({
  className,
  children,
  ...otherProps
}) => {
  return (
    <div className={cn(styles.popoverContainer, className)} {...otherProps}>
      {children}
    </div>
  );
};
