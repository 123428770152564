export const convertToDateFormat = (date?: string) => {
  if (date) {
    return new Date(
      `${date
        .split(' - ')
        .slice(1)
        .join()
        .replace(/(\d+).(\d+).(\d+)/, '$3/$2/$1')}, 23:59`
    );
  }
  return undefined;
};
