import { Contract } from 'core/types';
import { RootState } from 'store/rootReducer';

export const getLoadingSupAgDetail = (state: RootState): boolean | undefined =>
  state.supplementaryAgreementDetail.supplementaryAgreementLoading;

export const getSupAgDetail = (state: RootState): Contract | undefined =>
  state.supplementaryAgreementDetail.supplementaryAgreement;

export const getSystemsSupAgDetail = (state: RootState) =>
  state.supplementaryAgreementDetail.systems;
export const getSystemIdForSupAg = (state: RootState) => {
  const systems = getSystemsSupAgDetail(state);
  return systems?.length ? systems[0]?.id : undefined;
};
export const getSystemsLoadingSupAgDetail = (state: RootState) =>
  state.supplementaryAgreementDetail.systemsLoading;

export const getAttachmentsSupAgDetail = (state: RootState) =>
  state.supplementaryAgreementDetail.attachments;
export const getLoadingAttachmentsSupAgDetail = (state: RootState) =>
  state.supplementaryAgreementDetail.attachmentsLoading;

export const getGroupsSupAgDetail = (state: RootState) =>
  state.supplementaryAgreementDetail.groups;
export const getGroupsLoadingSupAgDetail = (state: RootState) =>
  state.supplementaryAgreementDetail.groupsLoading;
export const getGroupsFilterSupAgDetail = (state: RootState) =>
  state.supplementaryAgreementDetail.groupsFilter || {};
export const getPropsGroupsSupAgDetail = (state: RootState) => ({
  pageNum: state.supplementaryAgreementDetail.groupsPagination?.pageNum,
  pageSize: state.supplementaryAgreementDetail.groupsPagination?.pageSize,
  totalElements:
    state.supplementaryAgreementDetail.groupsPagination?.totalElements,
  sort: state.supplementaryAgreementDetail.groupsSort,
  loading: getGroupsLoadingSupAgDetail(state),
});

export const getSLASupAgDetail = (state: RootState) =>
  state.supplementaryAgreementDetail.sla;
export const getTicketTypesSupAgDetail = (state: RootState) =>
  state.supplementaryAgreementDetail.ticketTypes || [];
export const getSLALoadingSupAgDetail = (state: RootState) =>
  state.supplementaryAgreementDetail.slaLoading;

export const getTitleForCreateSupAg = (state: RootState) =>
  state.supplementaryAgreementDetail.titleForCreateSupAg;
