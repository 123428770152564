import { getUrl } from 'utils';

export const endpoints = {
  getWorkGroups: () => getUrl('work-group/filterAll'),
  getWorkGroup: (id: string) => getUrl(`work-group/${id}`),
  createUser: () => getUrl('work-group'),
  editUser: () => getUrl('work-group'),
  getWorkGroupsByUserId: (id: string) => getUrl(`work-group/user/${id}`),
  getWorkGroupsContractId: (id: string) =>
    getUrl(`work-group/contract-post/${id}`),
  getWorkGroupsContractIdSystemId: (contractId: string, systemId: string) =>
    getUrl(`work-group/contract-system/${contractId}/${systemId}`),
  getWorkGroupsContractIdOrgId: (contractId: string) =>
    getUrl(`work-group/contract-groups/${contractId}`),
  getWorkGroupsFromMyOrg: () => getUrl('work-group/auth-user'),
  deleteWorkGroup: (id: string) => getUrl(`work-group/${id}`),
};
