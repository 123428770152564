import { EntityTypes, NotificationContent } from 'core/types/notification';

export const splitEntitiesByType = (entities: NotificationContent[]) => {
  const result: Partial<Record<EntityTypes, NotificationContent[]>> = {};
  entities.forEach((entity) => {
    const { entityType = EntityTypes.TICKET } = entity;
    result[entityType] = [...(result[entityType] || []), entity];
  });
  return result;
};
