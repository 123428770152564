import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { Checkbox, Input } from 'components';
import { Create } from 'core/modals';
import { EnvironmentSettingUpdate } from 'core/types';
import { fetchUpdateEnvironmentSettingsRequest } from 'features/Contract/ducks/actions';
import {
  getEnvironmentsSettingsBySystem,
  getSystemSelected,
} from 'features/Contract/ducks/selectors';
import { useContractId } from 'features/Contract/hooks';
import { sortedEnvironmentSettings } from 'features/Contract/utils';

import styles from './Environment.module.scss';

interface EnvironmentProps {
  isModal: boolean;
  toggleModal(): void;
}

export const Environment: FC<EnvironmentProps> = ({ isModal, toggleModal }) => {
  const contractId = useContractId();

  const dispatch = useDispatch();

  const systemSelected = useSelector(getSystemSelected);
  const environmentSettings = useSelector(getEnvironmentsSettingsBySystem);

  const [currentEnvironments, setCurrentEnvironments] = useState<
    EnvironmentSettingUpdate[]
  >([]);

  const { handleSubmit } = useForm<EnvironmentSettingUpdate>({
    mode: 'onChange',
  });

  useEffect(() => {
    if (environmentSettings) {
      setCurrentEnvironments(
        environmentSettings?.map(({ environmentId, active }) => {
          return {
            environmentId,
            active,
          };
        })
      );
    }
  }, [environmentSettings]);

  const formSubmitHandler = handleSubmit(() => {
    dispatch(
      fetchUpdateEnvironmentSettingsRequest({
        environmentsWithStatus: currentEnvironments,
        contractId: contractId || '',
        systemId: systemSelected?.id || '',
      })
    );
    toggleModal();
  });

  const onChangeCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    const newEnvironments = currentEnvironments?.map(
      (setting: EnvironmentSettingUpdate) => {
        if (event.target.value === setting.environmentId) {
          return {
            ...setting,
            active: event.target.checked,
          };
        }
        return {
          ...setting,
          active: setting.active,
        };
      }
    );
    setCurrentEnvironments(newEnvironments);
  };

  const sectionInputList = sortedEnvironmentSettings(environmentSettings)?.map(
    (environment) => {
      return (
        <div
          className={styles.environment__environments}
          key={environment?.environmentId}
        >
          <Checkbox
            className={styles.environment__environmentsCheckbox}
            value={environment?.environmentId}
            onChange={onChangeCheckbox}
            defaultChecked={environment?.active}
          />
          <Input value={environment?.name} label="Среда" readOnly />
        </div>
      );
    }
  );

  return (
    <Create
      toggleModal={toggleModal}
      isModal={isModal}
      title="Добавить среду"
      createTitle="Добавить"
      onSubmit={formSubmitHandler}
      subModalText="добавление сред"
    >
      <div className={styles.environment__inputSystem}>
        <Input value={systemSelected?.title} label="Система" disabled />
      </div>
      <div className={styles.environment__separator} />
      {sectionInputList}
    </Create>
  );
};
