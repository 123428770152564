import DOMPurify from 'dompurify';
import { omit } from 'lodash';
import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  getMultiOptionValues,
  Input,
  InputDatePicker,
  Select,
} from 'components';
import { getIsMobile, getIsMobileSmall } from 'core/ducks/selectors';
import { EditorRefType } from 'core/types';
import {
  SELECT_PUBLICATION_DATE_LIST,
  SELECT_RECIPIENT_LIST,
} from 'features/Profile/constants';
import { useCreateNewsForm } from 'features/Profile/hooks';
import { PublicationType, RecipientType } from 'features/Profile/types';
import { AuthService, checkIsDateValid, getEditorConfig, getEnv } from 'utils';

import {
  fetchCurrentNewsSuccess,
  fetchWorkGroupsRequest,
  newsCreate,
  newsEdit,
  setIsEditNewsMode,
} from '../../ducks/actions';
import { getDateForNews, prepareIdsForRequest } from '../../utils';

import styles from './NewsModalContent.module.scss';

interface NewsModalContentProps {
  form: ReturnType<typeof useCreateNewsForm>;
  onReset(): void;
  toggleIsModal(): void;
  isModal?: boolean;
}

export const NewsModalContent: FC<NewsModalContentProps> = ({
  form,
  onReset,
  toggleIsModal,
  isModal,
}) => {
  const isMobile = useSelector(getIsMobile);
  const isMobileSmall = useSelector(getIsMobileSmall);

  const isMobileAll = isMobile || isMobileSmall;

  const token = AuthService.getToken();

  const dispatch = useDispatch();

  const [text, setText] = useState('');
  const [editorLoaded, setEditorLoaded] = useState(false);

  const editorRef = useRef<EditorRefType>();

  const { methods, state } = form;
  const { reset, resetField, handleSubmit, setValue } = methods;
  const {
    errors,
    control,
    titleInputOptions,
    text: defaultText,
    isEditMode,
    isValid,
    id,
    isEmptyOrganizationsIds,
    isLoadingOptions,
    isAdmin,
    isSelectivelyRecipients,
    isSelectByTime,
    isSelectByCurrently,
    isDateValid,
    organizationsOptions,
    workGroupsOptions,
    publicationType,
    published,
    isDisabledPublicationDate,
  } = state;

  const submitDisabled = useMemo(
    () =>
      !(
        isValid &&
        text &&
        (isSelectByCurrently || (isSelectByTime && isDateValid)) &&
        !(isEmptyOrganizationsIds && isSelectivelyRecipients)
      ),
    [
      isValid,
      text,
      isSelectByTime,
      isSelectByCurrently,
      isDateValid,
      isEmptyOrganizationsIds,
      isSelectivelyRecipients,
    ]
  );

  const editTitle = isEditMode ? 'Сохранить' : 'Создать';

  const { CKEditor, Editor } = editorRef.current || {};

  const editorConfig = getEditorConfig(
    `${getEnv('REACT_APP_ATTACHMENT_URL')}/api/attachment/news/image`,
    token
  );

  const clearFormData = () => {
    reset();
    setText('');
  };

  const newsCreateSubmit = handleSubmit((data) => {
    const prepareData = omit(
      {
        ...data,
        organizationsIds: getMultiOptionValues(data.organizationsIds),
        workgroupsIds: getMultiOptionValues(data.workgroupsIds),
        text: DOMPurify.sanitize(text || ' '),
        published,
        publicationDate: isSelectByTime
          ? getDateForNews(data?.publicationDate)
          : null,
      },
      ['publicationType', 'recipient']
    );

    dispatch(newsCreate(prepareData));
    clearFormData();
    toggleIsModal();
  });

  const newsEditSubmit = handleSubmit((data) => {
    const prepareData = omit(
      {
        ...data,
        organizationsIds: getMultiOptionValues(data.organizationsIds),
        workgroupsIds: getMultiOptionValues(data.workgroupsIds),
        text: DOMPurify.sanitize(text || ' '),
        id,
        published,
        publicationDate: isSelectByTime
          ? getDateForNews(data.publicationDate)
          : null,
      },
      ['publicationType', 'recipient']
    );

    dispatch(newsEdit(prepareData));
    clearFormData();
    toggleIsModal();
  });

  const onSubmitHandler = isEditMode ? newsEditSubmit : newsCreateSubmit;

  const onChangeEditor = useCallback(
    (event: ChangeEvent<HTMLInputElement>, editor) => {
      const data = editor.getData();
      setText(data);
    },
    [setText]
  );

  useEffect(() => {
    if (isEditMode) {
      setText(DOMPurify.sanitize(defaultText || ''));
    }
  }, [defaultText, isEditMode]);

  useEffect(() => {
    editorRef.current = {
      // eslint-disable-next-line
      CKEditor: require('@ckeditor/ckeditor5-react').CKEditor,
      // eslint-disable-next-line
      Editor: require('ckeditor5-custom-build/build/ckeditor'),
    };
    setEditorLoaded(true);
  }, []);

  useEffect(() => {
    if (!isModal) {
      dispatch(setIsEditNewsMode(false));
      clearFormData();
      if (isMobileAll) {
        dispatch(fetchCurrentNewsSuccess(undefined));
      }
    }
    if (!isAdmin) {
      setValue('recipient', SELECT_RECIPIENT_LIST[1]);
    }
  }, [isModal, isMobileAll, isAdmin]);

  return (
    <div className={styles.newsModalContent}>
      <div className={styles.newsModalContent__container}>
        <div className={styles.newsModalContent__header}>
          <Input {...titleInputOptions} label="Название новости" />
        </div>
        <div className={styles.newsModalContent__organizationSelects}>
          <Controller
            control={control}
            name="recipient"
            key="recipient"
            render={({ field }) => (
              <Select<RecipientType>
                className={styles.newsModalContent__select}
                label="Кому отправить"
                mobileModalTitle="кому отправить"
                value={field.value}
                onChange={(value) => {
                  field.onChange(value);
                  resetField('organizationsIds');
                  resetField('workgroupsIds');
                }}
                options={SELECT_RECIPIENT_LIST}
                disabled={!isAdmin}
              />
            )}
          />

          <Controller
            control={control}
            name="organizationsIds"
            key="organizationsIds"
            rules={{
              required: isSelectivelyRecipients,
            }}
            render={({ field }) => {
              return isSelectivelyRecipients ? (
                <Select<string>
                  label="Организации"
                  mobileModalTitle="организации"
                  options={organizationsOptions}
                  value={field.value}
                  onChange={(value) => {
                    field.onChange(value);
                    const preparedIds = prepareIdsForRequest(value);
                    dispatch(fetchWorkGroupsRequest(preparedIds));
                    resetField('workgroupsIds');
                  }}
                  isMulti
                />
              ) : (
                <></>
              );
            }}
          />
          <Controller
            control={control}
            name="workgroupsIds"
            key="workgroupsIds"
            render={({ field }) => {
              return isSelectivelyRecipients ? (
                <Select<string>
                  label="Группы"
                  mobileModalTitle="группы"
                  options={workGroupsOptions}
                  value={field.value}
                  onChange={field.onChange}
                  disabled={isEmptyOrganizationsIds || isLoadingOptions}
                  isMulti
                />
              ) : (
                <></>
              );
            }}
          />
        </div>
        <div className={styles.newsModalContent__timeSelects}>
          <Controller
            control={control}
            name="publicationType"
            key="publicationType"
            render={({ field }) => (
              <Select<PublicationType>
                className={styles.newsModalContent__select}
                label="Когда отправить"
                mobileModalTitle="когда отправить"
                value={publicationType}
                onChange={(value) => {
                  field.onChange(value);
                  resetField('publicationDate');
                }}
                options={SELECT_PUBLICATION_DATE_LIST}
                disabled={!isAdmin || isDisabledPublicationDate}
              />
            )}
          />
          <Controller
            control={control}
            rules={{
              required: isSelectByTime,
              validate: (value) => {
                if (!isSelectByTime) {
                  return true;
                }
                return checkIsDateValid(value) || 'Некорректный формат даты';
              },
            }}
            name="publicationDate"
            key="publicationDate"
            render={({ field }) => {
              return isSelectByTime ? (
                <InputDatePicker
                  type="datePicker"
                  placeholder="Дата и время"
                  showTime
                  disabled={isDisabledPublicationDate}
                  value={field.value}
                  onChange={field.onChange}
                  error={errors.publicationDate}
                  errorMessage={errors.publicationDate?.message}
                />
              ) : (
                <></>
              );
            }}
          />
        </div>
      </div>
      <div className={styles.newsModalContent__content}>
        {editorLoaded && Editor && CKEditor && (
          <CKEditor
            editor={Editor}
            config={editorConfig}
            data={text}
            onReady={() => null}
            onChange={onChangeEditor}
          />
        )}
        <div className={styles.newsModalContent__buttons}>
          <Button
            onClick={onSubmitHandler}
            disabled={submitDisabled}
            className={styles.newsModalContent__button}
          >
            {editTitle}
          </Button>
          <Button
            onClick={onReset}
            appearance="flat"
            type="button"
            className={styles.newsModalContent__button}
          >
            Отмена
          </Button>
        </div>
      </div>
    </div>
  );
};
