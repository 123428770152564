import { RouterHref } from 'routes/routerHref';

export const checkPathnameWithTrailingSlash = (
  pathname: string,
  route: RouterHref
): boolean => {
  if (pathname.endsWith('/')) {
    return pathname === `${route}/`;
  }
  return pathname === route;
};
