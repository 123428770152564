import { ChangeEvent, FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LockIcon } from 'assets/icons';
import { ToggleSwitch, Typography } from 'components';
import { ContractStatus } from 'core/types';
import {
  setContractPermission,
  updateContractPermission,
} from 'features/Contract/ducks/actions';
import { getContractDetail } from 'features/Contract/ducks/selectors';
import { useContractId } from 'features/Contract/hooks';

import styles from './AccessToSections.module.scss';

export const AccessToSections: FC = () => {
  const dispatch = useDispatch();

  const contractId = useContractId();
  const contract = useSelector(getContractDetail);

  const contractPermission = contract?.contractPermission;

  const [isStoragePermission, setIsStoragePermission] = useState(
    contractPermission?.storageAccessible || false
  );

  const isDisabled = !(
    !contractId || contract?.status !== ContractStatus.CLOSED
  );

  const handleSwitch = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    setIsStoragePermission(checked);
    if (contract && contractId) {
      dispatch(
        updateContractPermission({
          contractId,
          isEnable: checked,
        })
      );
      return;
    }
    dispatch(setContractPermission({ isEnable: checked }));
  };

  return (
    <div className={styles.accessToSections}>
      <LockIcon className={styles.accessToSections__icon} />
      <Typography className={styles.accessToSections__description}>
        Здесь вы можете настроить доступ к<br />
        дополнительным разделам
      </Typography>
      <ToggleSwitch
        label="Хранилище"
        disabled={isDisabled}
        checked={isStoragePermission}
        onChange={handleSwitch}
        classNameLabel={styles.accessToSections__switchLabel}
      />
    </div>
  );
};
