import {
  SLIDE_RADIO_TABS_DEFAULT,
  SLIDE_RADIO_TABS_SERVICE_ADMIN,
} from '../constants';

export const getRoleTabs = (admin: boolean) => {
  if (admin) {
    return SLIDE_RADIO_TABS_SERVICE_ADMIN;
  }
  return SLIDE_RADIO_TABS_DEFAULT;
};
