import cn from 'clsx';
import { FC } from 'react';

import { Size } from 'components';

import { NotificationCircle } from '../NotificationCircle';

import styles from './Avatar.module.scss';

export enum AvatarColor {
  purple = 'purple',
  blue = 'blue',
  cyan = 'cyan',
  green = 'green',
  yellow = 'yellow',
  red = 'red',
  grey = 'grey',
}

interface AvatarProps {
  color?: AvatarColor;
  size?: Size;
  initialsUser?: string;
  className?: string;
  classNameInitials?: string;
  showNotification?: boolean;
}

export const Avatar: FC<AvatarProps> = ({
  color = AvatarColor.cyan,
  size = Size.m,
  initialsUser,
  className,
  classNameInitials,
  showNotification,
}) => {
  const preparedInitialsUser = initialsUser?.split(' ');

  const initials = `${
    (preparedInitialsUser &&
      preparedInitialsUser[0] &&
      preparedInitialsUser[0][0]?.toUpperCase()) ||
    ''
  }${
    (preparedInitialsUser &&
      preparedInitialsUser[1] &&
      preparedInitialsUser[1][0]?.toUpperCase()) ||
    ''
  }`;

  const notification = showNotification && (
    <NotificationCircle className={styles.avatar__circle} />
  );

  return (
    <div
      className={cn(
        styles.avatar,
        styles[`avatar_${size}`],
        styles[`avatar_${color}`],
        className
      )}
    >
      <span className={cn(styles.avatar__text, classNameInitials)}>
        {initials}
      </span>
      {notification}
    </div>
  );
};
