import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ActionList } from 'core/types/auth';
import {
  getIsAccessedAction,
  getIsClient,
  getIsSpecialist,
} from 'features/Auth';

import { fetchTicketTypesRequest } from '../ducks/ticket/actions';
import { getTicket, getTicketTypes } from '../ducks/ticket/selectors';
import { checkIsTicketStatusStandart } from '../utils';

export const useTicketTypes = () => {
  const dispatch = useDispatch();

  const ticket = useSelector(getTicket);
  const ticketTypes = useSelector(getTicketTypes);
  const isClient = useSelector(getIsClient);
  const isSpecialist = useSelector(getIsSpecialist);
  const isAccessChangeTypeTicket = useSelector(
    getIsAccessedAction(ActionList.EditTicketType)
  );

  const hasProcessRoles = isClient || isSpecialist;

  const isStandartStatus = checkIsTicketStatusStandart(ticket);

  const systemId = ticket?.systemId?.key;

  const canProcessTicketType =
    isAccessChangeTypeTicket &&
    hasProcessRoles &&
    ticketTypes.length > 1 &&
    isStandartStatus;

  useEffect(() => {
    if (systemId) {
      dispatch(fetchTicketTypesRequest(systemId));
    }
  }, [dispatch, systemId]);

  return { canProcessTicketType, ticket };
};
