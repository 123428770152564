import { InterfaceSettings } from '../InterfaceSettings';
import { NotificationsSettings } from '../NotificationsSettings';
import { PasswordChange } from '../PasswordChange';

import styles from './ProfileSettings.module.scss';

export const ProfileSettings = () => {
  return (
    <div className={styles.profileSettings}>
      <div className={styles.profileSettings__left}>
        <PasswordChange />
        <InterfaceSettings />
      </div>
      <NotificationsSettings />
    </div>
  );
};
