import cn from 'clsx';
import { FC, KeyboardEvent, MouseEvent } from 'react';

import styles from './RollUpButton.module.scss';

interface RollUpButtonProps {
  isOpen: boolean;
  toggleButton: () => void;
  className?: string;
  onKeyDown?: (event: KeyboardEvent<HTMLElement>) => void;
}

export const RollUpButton: FC<RollUpButtonProps> = ({
  isOpen,
  toggleButton,
  className,
  onKeyDown,
}) => {
  const onClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    toggleButton();
  };

  const onMouseDown = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <button
      onClick={onClick}
      onMouseDown={onMouseDown}
      onKeyDown={onKeyDown}
      className={cn(styles.rollUpButton, className, {
        [styles.rollUpButton_up]: isOpen,
      })}
    />
  );
};
