import { CustomField } from 'components';
import { Priority, Status } from 'core/types';
import { Solution, Tag, TicketClient, TicketSLA } from 'features/Ticket/types';
import { KeyValueOption } from 'types/models/meta';

import { WorkGroup } from './workGroup';

export enum ProcedureType {
  CONSULTATION = 'CONSULTATION',
  INCIDENT = 'INCIDENT',
}

export type Ticket = {
  id?: string;
  customStatus?: Status;
  clientId?: KeyValueOption;
  clientInfo?: TicketClient;
  client: KeyValueOption;
  contractId?: KeyValueOption;
  specialistId?: KeyValueOption;
  workGroups: WorkGroup[];
  priority?: Priority | null;
  environmentId: KeyValueOption;
  systemId?: KeyValueOption;
  versionId?: string;
  sla?: TicketSLA;
  number?: string;
  theme?: string;
  description?: string;
  solution?: Solution;
  attachments?: string[];
  dateCreate?: string;
  dateUpdate?: string;
  dateSolve?: string;
  dateClose?: string;
  expired?: boolean;
  tags?: Tag[];
  slaInfo?: TicketSLA;
  systemName?: string;
  systemVersion?: string;
  ticketType?: KeyValueOption;
  mentionedTickets?: KeyValueOption[];
  customTicketFields: CustomField[];
  isDeleted?: boolean;
  source?: SourceTicketType;
};

export enum TicketSLAType {
  DECISION = 'DECISION',
  REACTION = 'REACTION',
}

export enum TableTicketsTypes {
  FULL = 'FULL',
  BY_CONTRACT_IDS = 'BY_CONTRACT_IDS',
  FROM_DESKTOP = 'FROM_DESKTOP',
  FROM_DESKTOP_HISTORY = 'FROM_DESKTOP_HISTORY',
}

export enum AttachmentDeleteType {
  INFO = 'INFO',
  COMMENTS = 'COMMENTS',
  NOTES = 'NOTES',
}

export enum SourceTicketType {
  SUP_PORTAL = 'SUP_PORTAL',
  JIRA = 'JIRA',
  TELEGRAM = 'TELEGRAM',
  MAIL = 'MAIL',
  EXTERNAL_INTEGRATION = 'EXTERNAL_INTEGRATION',
}
