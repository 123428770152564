import { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { ConditionBlock, Tooltip, Typography } from 'components';
import { KeyValueOption } from 'types/models/meta';

import styles from './MoreTag.module.scss';

interface MoreTagProps {
  data: KeyValueOption[];
}

export const MoreTag: FC<MoreTagProps> = ({ data }) => {
  const id = uuidv4();

  const dataTip = data.length >= 2 && (
    <ConditionBlock text={`+${data ? data?.length - 1 : 0}`} />
  );

  const tooltip = data
    ?.slice(1)
    // eslint-disable-next-line react/no-array-index-key
    .map((item, index) => <Typography key={index}>{item.value}</Typography>);

  return (
    <div className={styles.moreTag__container}>
      <Typography className={styles.moreTag__data}>{data[0]?.value}</Typography>
      <div data-tip data-for={id}>
        {dataTip}
        <Tooltip id={id} place="right">
          {tooltip}
        </Tooltip>
      </div>
    </div>
  );
};
