import cn from 'clsx';
import _ from 'lodash';
import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import {
  Card,
  Input,
  TextArea,
  Typography,
  TypographyVariants,
} from 'components';
import { BottomButtonsBlock } from 'core/components';
import { getIsMobile, getIsMobileSmall } from 'core/ducks/selectors';
import { ApproveOrCancel } from 'core/modals';
import { ActionList } from 'core/types/auth';
import { CreateWorkGroupData, WorkGroup } from 'core/types/workGroup';
import {
  getIsAccessedAction,
  getIsClient,
  getUserOrganization,
} from 'features/Auth';
import { OrganizationSelect } from 'features/Organizations';
import {
  createWorkGroup,
  editWorkGroup,
  getWorkGroup,
  useWorkGroupId,
} from 'features/WorkGroups';
import { RouterHref } from 'routes/routerHref';
import { LocationState } from 'routes/types';
import { resetActiveElementFocus } from 'utils';

import styles from './WorkGroupForm.module.scss';

interface WorkGroupFormProps {
  className?: string;
}

export const WorkGroupForm: FC<WorkGroupFormProps> = ({ className }) => {
  const workGroup = useSelector(getWorkGroup);
  const isAccessToCreateWorkGroup = useSelector(
    getIsAccessedAction(ActionList.CreateWorkGroup)
  );
  const isMobile = useSelector(getIsMobile);
  const isMobileSmall = useSelector(getIsMobileSmall);
  const isClient = useSelector(getIsClient);
  const organization = useSelector(getUserOrganization);

  const isMobileAll = isMobile || isMobileSmall;
  const workGroupId = useWorkGroupId();
  const mainLayoutSticky = document.getElementById('mainLayoutSticky');

  const isEditMode = !!workGroup?.id;

  const dispatch = useDispatch();

  const [isModal, setIsModal] = useState<boolean>(false);
  const [isIdenticalData, setIsIdenticalData] = useState<boolean>(false);

  const toggleModal = () => setIsModal(!isModal);

  const { push } = useHistory();
  const { state } = useLocation<LocationState>();

  const organizationId = state?.organizationId;
  const organizationTitle = state?.organizationTitle;

  const getInitialOrgOption = () => {
    if (organizationId && organizationTitle) {
      return {
        title: organizationTitle,
        value: organizationId,
      };
    }
    if (!isClient && workGroupId) {
      return {
        value: (workGroupId && workGroup?.organization?.id) || '',
        title: (workGroupId && workGroup?.organization?.title) || '',
      };
    }
    if (organization && isClient) {
      return {
        title: organization?.title || '',
        value: organization?.id || '',
      };
    }
    return null;
  };

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors, isDirty, isValid },
  } = useForm<CreateWorkGroupData>({
    mode: 'onChange',
  });

  const currentFormValues = watch();

  useEffect(() => {
    if (workGroup && workGroupId) {
      setValue('title', workGroup?.title);
      setValue('description', workGroup?.description);
    }
    setValue('organizationId', getInitialOrgOption());
  }, [workGroup, workGroupId]);

  const normalizeData = (data?: WorkGroup) => {
    const newWorkGroupData = {
      ...data,
      organizationId: getInitialOrgOption(),
    };
    delete newWorkGroupData.id;
    delete newWorkGroupData.organization;
    delete newWorkGroupData.responsibilityList;
    delete newWorkGroupData.userList;
    delete newWorkGroupData.dateCreate;
    delete newWorkGroupData.deleted;
    return newWorkGroupData;
  };

  const initialFormValues = normalizeData(workGroup);

  useEffect(() => {
    if (_.isEqual(currentFormValues, initialFormValues) && workGroupId) {
      return setIsIdenticalData(true);
    }
    return setIsIdenticalData(false);
  }, [currentFormValues, initialFormValues, workGroupId]);

  const titleInputOptions = register('title', {
    required: true,
    maxLength: {
      value: 100,
      message: 'Название группы не может быть длиннее 100 символов.',
    },
  });

  const descriptionTextAreaOptions = register('description', {
    required: true,
    maxLength: {
      value: 255,
      message: 'Описание группы не может быть длиннее 255-ти символов.',
    },
  });

  const formSubmitHandler = handleSubmit((data) => {
    const newWorkGroupData = {
      ...workGroup,
      ...data,
    };
    if (!workGroupId) {
      dispatch(createWorkGroup(newWorkGroupData));
      return;
    }
    dispatch(editWorkGroup({ ...newWorkGroupData, id: workGroupId }));
    resetActiveElementFocus();
  });

  const onApproveModal = () => {
    push(RouterHref.AdminGroups);
    reset();
  };
  return (
    <div className={cn(styles.workGroupForm, className)}>
      <Card className={styles.workGroupForm__formHeader}>
        <Typography
          variant={TypographyVariants.h4}
          className={styles.workGroupForm__formHeaderTab}
        >
          Информация
        </Typography>
      </Card>
      <Card className={styles.workGroupForm__formContent}>
        <form className={styles.workGroupForm__form}>
          <Input
            {...titleInputOptions}
            label="Название группы"
            disabled={!isAccessToCreateWorkGroup}
            error={!!errors.title}
            errorMessage={errors.title?.message}
            className={cn(
              styles.workGroupForm__input,
              styles.workGroupForm__input_oneOfThree,
              { [styles.workGroupForm__input_mr]: !isMobileAll }
            )}
          />
          <Controller
            control={control}
            name="organizationId"
            key="organizationId"
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <OrganizationSelect
                  onChange={field.onChange}
                  disabled={
                    !isAccessToCreateWorkGroup || isEditMode || isClient
                  }
                  placeholder="Организация"
                  value={field.value}
                  className={cn(
                    styles.workGroupForm__input,
                    styles.workGroupForm__input_oneOfThree
                  )}
                />
              );
            }}
          />
          <TextArea
            {...descriptionTextAreaOptions}
            label="Описание"
            disabled={!isAccessToCreateWorkGroup}
            error={!!errors.description}
            errorMessage={errors.description?.message}
          />
          <BottomButtonsBlock
            isOpen={isDirty && isValid && !isIdenticalData}
            onCancel={toggleModal}
            parentNode={mainLayoutSticky}
            onSave={formSubmitHandler}
            disabledSubmit={
              !isDirty ||
              !isValid ||
              isIdenticalData ||
              !isAccessToCreateWorkGroup
            }
          />
        </form>
      </Card>
      <ApproveOrCancel
        onApprove={onApproveModal}
        isModal={isModal}
        toggleModal={toggleModal}
        text={`Вы уверены, что хотите отменить ${
          workGroupId ? 'редактирование' : 'создание'
        } рабочей группы?`}
      />
    </div>
  );
};
