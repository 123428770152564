import { ActionForAlertTypes } from 'core/types';

import { createErrorAlert, createSuccessAlert } from './createAlert';

const ALERT_TYPE_TITLE = {
  [ActionForAlertTypes.DELETE]: 'удалени',
  [ActionForAlertTypes.CREATE]: 'создани',
  [ActionForAlertTypes.EDIT]: 'редактировани',
};

export const getErrorAlert = (
  entityName: string,
  actionType: ActionForAlertTypes
) => {
  return createErrorAlert(
    `Произошла ошибка при ${ALERT_TYPE_TITLE[actionType]}и сущности "${entityName}"`
  );
};

export const getSuccessAlert = (
  entityName: string,
  actionType: ActionForAlertTypes
) => {
  return createSuccessAlert(
    `Успешно выполнено ${ALERT_TYPE_TITLE[actionType]}е сущности "${entityName}"`
  );
};
