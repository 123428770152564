import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setHref } from 'core/ducks/actions';

interface HrefLayoutProps {
  href: string;
}

export const HrefLayout: FC<HrefLayoutProps> = ({ children, href }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setHref(href));
  }, [href]);

  return <>{children}</>;
};
