import { all, AllEffect, StrictEffect } from 'redux-saga/effects';

import { accessesSaga } from 'features/Accesses/ducks/saga';
import { actionsSaga } from 'features/Actions/ducks/saga';
import { currentUserSaga } from 'features/Auth/ducks/saga';
import { contractDetailSaga } from 'features/Contract/ducks/saga';
import { contractsSaga } from 'features/Contracts/ducks/saga';
import { createTicketSaga } from 'features/CreateTicket/ducks/saga';
import { customFieldsSaga } from 'features/CustomFields/ducks/saga';
import { desktopCurrentStateSaga } from 'features/Desktop/ducks/CurrentState/saga';
import { desktopSaga } from 'features/Desktop/ducks/Desktop/saga';
import { desktopEmployeesSaga } from 'features/Desktop/ducks/Employees/saga';
import { desktopHistorySaga } from 'features/Desktop/ducks/History/saga';
import { desktopKpiSaga } from 'features/Desktop/ducks/Kpi/saga';
import { jiraSaga } from 'features/JiraIntegrations/ducks/saga';
import { articleViewingSaga } from 'features/KnowledgeBase/views/KBArticle/ducks/saga';
import { articleCreateSaga } from 'features/KnowledgeBase/views/KBCreateArticle/ducks/saga';
import { articlesMainSaga } from 'features/KnowledgeBase/views/KBMain/ducks/saga';
import { articlesTableSaga } from 'features/KnowledgeBase/views/KBTable/ducks/saga';
import { organizationsSaga } from 'features/Organizations/ducks/saga';
import { notificationsProfileSaga } from 'features/Profile/ducks/saga';
import { responsibilitiesSaga } from 'features/Responsibilities/ducks/saga';
import { rolesSaga } from 'features/Roles/ducks/saga';
import { rulesSaga } from 'features/Rules/ducks/saga';
import { similarSolutionsSaga } from 'features/SimilarSolutions/ducks/saga';
import { statusesBuilderSaga } from 'features/StatusesBuilder/ducks/saga';
import { storageSaga } from 'features/Storage/ducks/saga';
import { storagesSaga } from 'features/Storages/ducks/saga';
import { supplementaryAgreementDetailSaga } from 'features/SupplementaryAgreement/ducks/saga';
import { systemSaga } from 'features/System/ducks/saga';
import { systemsSaga } from 'features/Systems/ducks/saga';
import { assessmentInteractionSaga } from 'features/Ticket/ducks/assessmentInteraction/saga';
import { attachmentsSaga } from 'features/Ticket/ducks/attachments/saga';
import { notesSaga } from 'features/Ticket/ducks/notes/saga';
import { tagsSaga } from 'features/Ticket/ducks/tags/saga';
import { ticketSaga } from 'features/Ticket/ducks/ticket/saga';
import { ticketsSaga } from 'features/Tickets/ducks/saga';
import { ticketsExportSaga } from 'features/TicketsExport/ducks/saga';
import { triggersSaga } from 'features/Triggers/ducks/saga';
import { userAssistanceSaga } from 'features/UserAssistance/ducks/saga';
import { usersSaga } from 'features/Users/ducks/saga';
import { workGroupsSaga } from 'features/WorkGroups/ducks/saga';

export function* rootSaga(): Generator<AllEffect<Generator<StrictEffect>>> {
  yield all([
    currentUserSaga(),
    ticketsSaga(),
    usersSaga(),
    attachmentsSaga(),
    systemsSaga(),
    systemSaga(),
    createTicketSaga(),
    organizationsSaga(),
    contractsSaga(),
    workGroupsSaga(),
    responsibilitiesSaga(),
    rolesSaga(),
    accessesSaga(),
    actionsSaga(),
    notificationsProfileSaga(),
    tagsSaga(),
    notesSaga(),
    customFieldsSaga(),
    ticketsExportSaga(),
    jiraSaga(),
    articleCreateSaga(),
    articlesTableSaga(),
    articleViewingSaga(),
    articlesMainSaga(),
    similarSolutionsSaga(),
    assessmentInteractionSaga(),
    ticketSaga(),
    contractDetailSaga(),
    supplementaryAgreementDetailSaga(),
    statusesBuilderSaga(),
    desktopSaga(),
    desktopKpiSaga(),
    desktopCurrentStateSaga(),
    desktopHistorySaga(),
    desktopEmployeesSaga(),
    userAssistanceSaga(),
    rulesSaga(),
    triggersSaga(),
    storagesSaga(),
    storageSaga(),
  ]);
}
