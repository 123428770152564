import { System } from 'features/System';
import { SystemLayout } from 'features/System/layouts';

const SystemPage = () => {
  return (
    <SystemLayout>
      <System />
    </SystemLayout>
  );
};
export default SystemPage;
