import { call, put, takeEvery } from 'redux-saga/effects';

import { createError } from 'utils';

import { KpiData } from '../../types';

import { requests } from './api/requests';

import { fetchKpiRequest, fetchKpiSuccess } from './index';

function* fetchKpi(action: ReturnType<typeof fetchKpiRequest>) {
  try {
    const data: KpiData = yield call(requests.getKpiData, action.payload);

    yield put(fetchKpiSuccess(data));
  } catch (error) {
    createError(error);
  }
}

export function* desktopKpiSaga() {
  yield takeEvery(fetchKpiRequest.type, fetchKpi);
}
