import { StorageCustomField } from 'core/types/storage';

export const getCustomFieldsForRequest = (
  customFields?: StorageCustomField[]
) => {
  return customFields?.map((field) => {
    const { id, text = null, flag = null, date = null } = field;
    return {
      customFieldId: id,
      text,
      flag,
      date,
    };
  });
};
