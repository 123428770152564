export const getCountFields = (data: object) => {
  return Object.values(data).filter((el) => {
    if (Array.isArray(el)) {
      return el.length > 0;
    }
    if (el instanceof Object) {
      return !!(el.title && el.value);
    }
    return el !== undefined;
  }).length;
};
