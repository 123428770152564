import { ReactNode } from 'react';

export type ButtonType = 'default' | 'flat' | 'outline';

export type TabItem<Value = string> = {
  title?: string;
  value: Value;
  checked?: boolean;
  defaultChecked?: boolean;
  classNameTab?: string;
  disabled?: boolean;
  onClick?: () => void;
  icon?: ReactNode;
  readOnly?: boolean;
};

export enum Size {
  xxs = 'xxs',
  xs = 'xs',
  s = 's',
  m = 'm',
  l = 'l',
}

export type Orientation = 'vertical' | 'horizontal';

export enum CustomFieldType {
  FIELD_TEXT = 'TEXT',
  FIELD_DATE = 'DATE',
  FIELD_FLAG = 'FLAG',
  FIELD_LIST = 'LIST',
}

export type Nullable<T> = T | null;

export type NString = Nullable<string>;

export type NNumber = Nullable<number>;

export type NBoolean = Nullable<boolean>;

export type SelectOption<V = string, L = string, M = string, S = boolean> = {
  readonly value: V;
  readonly title: L;
  readonly meta?: M;
  readonly visible?: S;
};

export type ValueType<T = string | number> =
  | SelectOption<T>
  | Array<SelectOption<T>>
  | null;

// CustomField

export type ValueCustomField = {
  text: NString;
  date: NString;
  flag: NBoolean;
  listValue?: NString;
  values?: Nullable<string[]>;
};

export type CustomFieldListOption<T> = Omit<T, 'listValue'> & {
  listValue?: ValueType<string>;
};
