import { call, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';

import { Action, ActionWithCustomFields } from 'core/types/action';
import { getAccesses } from 'features/Accesses';
import { getIsServiceAdmin } from 'features/Auth';
import {
  getCurrentResponsibilityId,
  getResponsibilities,
} from 'features/Responsibilities';
import { ResponseWithMeta } from 'store/types';
import { createError } from 'utils';

import { addCustomFieldsToAction } from '../utils';

import {
  fetchActionsSuccess,
  hideActionsLoading,
  setActionsCreate,
  showActionsLoading,
} from './actions';
import { request } from './api/requests';
import { getActionsFilter, getPropsActions } from './selectors';
import { Actions, FetchActionsCreateRequestAction } from './types';

function* actionsCreateFetch({ payload }: FetchActionsCreateRequestAction) {
  try {
    const isServiceAdmin: ReturnType<typeof getIsServiceAdmin> = yield select(
      getIsServiceAdmin
    );
    yield put(showActionsLoading());
    const { pageNum, sortActions }: ReturnType<typeof getPropsActions> =
      yield select(getPropsActions);
    const actions: ResponseWithMeta<Action[]> = yield call(
      request.fetchCreateActions,
      pageNum,
      1000,
      sortActions,
      payload
    );
    yield put(
      setActionsCreate(addCustomFieldsToAction(actions.content, isServiceAdmin))
    );
    yield put(hideActionsLoading());
  } catch (e) {
    createError(e);
    yield put(hideActionsLoading());
  }
}

function* actionsFetchByRespId() {
  try {
    const isServiceAdmin: ReturnType<typeof getIsServiceAdmin> = yield select(
      getIsServiceAdmin
    );
    const {
      pageNum,
      pageSize,
      sortActions,
    }: ReturnType<typeof getPropsActions> = yield select(getPropsActions);
    const filter: ReturnType<typeof getActionsFilter> = yield select(
      getActionsFilter
    );
    const respId: ReturnType<typeof getCurrentResponsibilityId> = yield select(
      getCurrentResponsibilityId
    );
    if (respId) {
      yield put(showActionsLoading());
      const actions: ResponseWithMeta<Action[]> = yield call(
        request.fetchActionsByRespId,
        respId,
        pageNum,
        pageSize,
        sortActions,
        filter
      );
      yield put(
        fetchActionsSuccess({
          ...actions,
          content: addCustomFieldsToAction(actions.content, isServiceAdmin),
        })
      );
    }

    yield put(hideActionsLoading());
  } catch (e) {
    createError(e);
    yield put(hideActionsLoading());
  }
}

function* actionsForEdit() {
  try {
    const isServiceAdmin: ReturnType<typeof getIsServiceAdmin> = yield select(
      getIsServiceAdmin
    );
    const access: ReturnType<typeof getAccesses> = yield select(getAccesses);
    const respId: ReturnType<typeof getCurrentResponsibilityId> = yield select(
      getCurrentResponsibilityId
    );
    const responsibilities: ReturnType<typeof getResponsibilities> =
      yield select(getResponsibilities);
    const currentResponsibility = responsibilities?.find(
      (responsibility) => responsibility.id === respId
    );

    const accessIds = access?.map((item) => item.id);

    if (respId) {
      const actions: ResponseWithMeta<Action[]> = yield call(
        request.fetchActionsByRespId,
        respId,
        undefined,
        1000,
        undefined,
        {}
      );
      const actionsIds = actions.content?.map((item) => item.id);
      const actionsAll: ResponseWithMeta<Action[]> = yield call(
        request.fetchCreateActions,
        undefined,
        undefined,
        undefined,
        currentResponsibility?.organization?.id
      );
      const actionsWithStatus: ActionWithCustomFields[] = yield call(
        addCustomFieldsToAction,
        actionsAll.content,
        isServiceAdmin
      );
      const resultActions = actionsWithStatus.map((item) => ({
        ...item,
        status:
          !!accessIds?.includes(item.id) || !!actionsIds?.includes(item.id),
      }));
      yield put(setActionsCreate(resultActions));
    }
  } catch (e) {
    createError(e);
  }
}

export function* actionsSaga(): Generator<StrictEffect> {
  yield takeEvery(
    Actions.FETCH_ACTION_BY_RESP_ID_REQUEST,
    actionsFetchByRespId
  );
  yield takeEvery(Actions.FETCH_ACTIONS_CREATE_REQUEST, actionsCreateFetch);
  yield takeEvery(Actions.SET_ACTIONS_FOR_EDIT_RESPONSIBILITY, actionsForEdit);
}
