import { ToggleSwitch } from 'components';
import { Action } from 'core/types/action';

export const getDataTable = (actions?: Action[]) =>
  actions?.map((action) => {
    const { title, nameInDatabase, id } = action;
    return {
      id,
      title,
      nameInDatabase,
      status: <ToggleSwitch checked disabled readOnly />,
    };
  }) || [];
