import { PopoverContainer, TableContainer } from 'components';
import { DeleteButton } from 'core/components';
import { entityNames } from 'core/constants';
import { useTriggersTableContainer } from 'features/Triggers/hooks';

import { TriggerModal } from '../TriggerModal';
import { TriggersFullTable } from '../TriggersFullTable';

import styles from './TriggersTableContainer.module.scss';

export const TriggersTableContainer = () => {
  const { methods, state } = useTriggersTableContainer();

  const {
    handleDelete,
    toggleDeleteModal,
    toggleFilterRow,
    toggleIsOpenChangeModals,
    handleEditClick,
    handleChangePage,
    handleChangePageSize,
    handleSort,
    handleCreateClick,
    handleShowModalDelete,
    onRowClick,
  } = methods;

  const {
    pageNum,
    pageSize,
    sortTriggers,
    totalElements,
    triggers,
    changeModals,
    showFilterRow,
    searchDisabled,
    showDeleteModal,
    currentTrigger,
    currentTriggerId,
    loadingTriggers,
  } = state;

  const dropContent = (
    <PopoverContainer>
      <DeleteButton
        onClick={handleShowModalDelete}
        disabled={!currentTrigger}
      />
    </PopoverContainer>
  );

  return (
    <>
      <TableContainer
        classNameRoot={styles.triggersTableContainer}
        headerProps={{
          title: 'Триггер',
          createHandler: handleCreateClick,
          createTitle: 'Создать',
          searchDisabled,
          toggleSearch: toggleFilterRow,
          isSearchOpen: showFilterRow,
          dropContent,
          loading: loadingTriggers,
        }}
        contentTable={
          <TriggersFullTable
            triggers={triggers}
            handleSort={handleSort}
            sort={sortTriggers}
            handleClickTrigger={onRowClick}
            activeRowId={currentTriggerId}
            showFilterRow={showFilterRow}
            handleClickTriggerEdit={handleEditClick}
          />
        }
        countRecordsProps={{
          records: totalElements,
        }}
        paginationProps={{
          pageNum,
          pageSize,
          elementsCount: totalElements,
          handleChangePage,
          handleChangePageSize,
          disabled: loadingTriggers,
          entityName: entityNames.TRIGGERS,
        }}
        approveOrCancelProps={{
          onApprove: handleDelete,
          isModal: showDeleteModal,
          toggleModal: toggleDeleteModal,
          approveTitle: 'Удалить',
          approveBtnProps: {
            appearance: 'outline',
            className: styles.triggers__iconDelete,
          },
          cancelTitle: 'Отмена',
          text: `Вы уверены, что хотите удалить триггер ${currentTrigger?.title}?`,
        }}
      />
      <TriggerModal
        isModal={changeModals.isOpenModal}
        isEditMode={changeModals.isEditMode}
        toggleIsModal={toggleIsOpenChangeModals}
      />
    </>
  );
};
