import { Reducer } from 'redux';

import { DEFAULT_PAGINATION_PARAMS } from 'constants/meta';
import { CustomField } from 'core/types';
import { System } from 'core/types/system';
import { ReducerMap } from 'store/types';
import { Pagination } from 'types/models/meta';

import { ExportFields, TicketsRequestData } from '../types';

import {
  DownloadingExcel,
  FetchCustomFieldsExportSuccessAction,
  FetchSystemsExportSuccessAction,
  GetExportFieldsAction,
  GetTicketsCountRequestAction,
  SelectCustomFieldAction,
  SetExtraExportFields,
  SetTicketsCountAction,
  TicketsExport,
  TicketsExportActionsTypes,
} from './types';

export interface TicketsExportReducerState {
  isExportPanelOpen?: boolean;
  exportFields?: ExportFields;
  ticketsCount?: number;
  ticketsRequestData?: TicketsRequestData;
  isDownloadingExcel?: boolean;
  systems?: System[];
  systemsPagination?: Pagination;
  systemsLoading?: boolean;
  customFields?: CustomField[];
  customFieldsPagination?: Pagination;
  customFieldsLoading?: boolean;
  selectedCustomFields?: CustomField[];
  extraExportFields?: CustomField[];
}

const initialState: TicketsExportReducerState = {
  isExportPanelOpen: false,
  isDownloadingExcel: false,
  systems: [],
  systemsPagination: DEFAULT_PAGINATION_PARAMS,
  systemsLoading: false,
  customFields: [],
  customFieldsPagination: DEFAULT_PAGINATION_PARAMS,
  customFieldsLoading: false,
  selectedCustomFields: [],
  extraExportFields: [],
};

const ticketsExportReducerMap: ReducerMap<
  TicketsExportReducerState,
  TicketsExportActionsTypes
> = {
  [TicketsExport.OPEN_EXPORT_PANEL]: (state) => {
    return {
      ...state,
      isExportPanelOpen: true,
    };
  },
  [TicketsExport.CLOSE_EXPORT_PANEL]: (state) => {
    return {
      ...state,
      isExportPanelOpen: false,
    };
  },
  [TicketsExport.GET_EXPORT_FIELDS]: (state, action) => {
    const { payload } = action as GetExportFieldsAction;
    return {
      ...state,
      exportFields: payload,
    };
  },
  [TicketsExport.SET_TICKETS_COUNT]: (state, action) => {
    const { payload } = action as SetTicketsCountAction;
    return {
      ...state,
      ticketsCount: payload,
    };
  },
  [TicketsExport.GET_TICKETS_COUNT_REQUEST]: (state, action) => {
    const { payload } = action as GetTicketsCountRequestAction;
    return {
      ...state,
      ticketsRequestData: payload,
    };
  },
  [TicketsExport.DOWNLOADING_EXCEL]: (state, action) => {
    const { payload } = action as DownloadingExcel;
    return {
      ...state,
      isDownloadingExcel: payload,
    };
  },
  [TicketsExport.FETCH_SYSTEMS_EXPORT_SUCCESS]: (state, action) => {
    const { payload } = action as FetchSystemsExportSuccessAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      systems: content,
      pagination: {
        ...state?.systemsPagination,
        pageNum: page,
        totalElements,
      },
    };
  },
  [TicketsExport.SYSTEMS_EXPORT_LOADING_SHOW]: (state) => {
    return {
      ...state,
      systemsLoading: true,
    };
  },
  [TicketsExport.SYSTEMS_EXPORT_LOADING_HIDE]: (state) => {
    return {
      ...state,
      systemsLoading: false,
    };
  },
  [TicketsExport.FETCH_CUSTOM_FIELDS_EXPORT_SUCCESS]: (state, action) => {
    const { payload } = action as FetchCustomFieldsExportSuccessAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      customFields: content,
      pagination: {
        ...state?.customFieldsPagination,
        pageNum: page,
        totalElements,
      },
    };
  },
  [TicketsExport.CUSTOM_FIELDS_EXPORT_LOADING_SHOW]: (state) => {
    return {
      ...state,
      customFieldsLoading: true,
    };
  },
  [TicketsExport.CUSTOM_FIELDS_EXPORT_LOADING_HIDE]: (state) => {
    return {
      ...state,
      customFieldsLoading: false,
    };
  },
  [TicketsExport.RESET_CUSTOM_FIELDS_EXPORT]: (state) => {
    return {
      ...state,
      customFields: [],
    };
  },
  [TicketsExport.SELECT_CUSTOM_FIELD]: (state, action) => {
    const { payload } = action as SelectCustomFieldAction;

    const isCustomField = state?.selectedCustomFields?.find(
      (customField) => customField.id === payload.id
    );

    return {
      ...state,
      selectedCustomFields: !isCustomField
        ? [...(state?.selectedCustomFields || []), payload]
        : state?.selectedCustomFields?.filter(
            (selectedCustomField) => selectedCustomField.id !== payload.id
          ),
    };
  },
  [TicketsExport.SELECT_ALL_CUSTOM_FIELDS]: (state) => {
    return {
      ...state,
      selectedCustomFields: state?.selectedCustomFields?.length
        ? []
        : state?.customFields,
    };
  },
  [TicketsExport.RESET_SELECTED_CUSTOM_FIELDS]: (state) => {
    return {
      ...state,
      selectedCustomFields: [],
    };
  },
  [TicketsExport.SET_EXTRA_EXPORT_FIELDS]: (state, action) => {
    const { payload } = action as SetExtraExportFields;
    return {
      ...state,
      extraExportFields: payload,
    };
  },
  [TicketsExport.RESET_TICKETS_EXPORT_STATE]: () => initialState,
};

export const ticketsExport: Reducer<
  TicketsExportReducerState,
  TicketsExportActionsTypes
> = (state = initialState, action) => {
  const reducer = ticketsExportReducerMap[action.type];

  if (!reducer) {
    return state;
  }

  return reducer(state, action);
};
