import axios, { AxiosRequestConfig, ResponseType } from 'axios';

import { AuthService } from 'utils';
import { getEnv } from 'utils/getEnv';

export enum Method {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RequestData = Record<string, any>;

export interface RequestParams {
  baseURL?: string;
  url: string;
  data?: RequestData | string;
  params?: RequestData;
  headers?: RequestData;
  responseType?: ResponseType;
}

export interface SendParams extends RequestParams {
  method: Method;
}

export interface OnUploadProgress
  extends Pick<AxiosRequestConfig, 'onUploadProgress'> {}

export interface CancelToken extends Pick<AxiosRequestConfig, 'cancelToken'> {}

export const getInitialHeaders = (token?: string) => {
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
};

export class ApiClient {
  private static cancelTokenSource = axios.CancelToken.source();

  private static axiosInstance = axios.create({
    ...(getEnv('REACT_APP_TICKET_URL') && {
      baseURL: getEnv('REACT_APP_TICKET_URL'),
    }),
    withCredentials: true,
  });

  private static async sendAndHandleResponse<ResponseType>(
    requestParams: SendParams,
    onUploadProgress?: OnUploadProgress,
    cancelToken?: CancelToken
  ): Promise<ResponseType> {
    const { url, method, data, params, baseURL, responseType, headers } =
      requestParams;
    const { status, data: currentData } =
      await ApiClient.axiosInstance.request<ResponseType>({
        url,
        method,
        data,
        params,
        baseURL,
        responseType,
        headers: {
          ...getInitialHeaders(AuthService.getToken()),
          ...headers,
        },
        validateStatus: (resStatus) => {
          return (resStatus >= 200 && resStatus < 300) || resStatus === 401;
        },
        cancelToken: ApiClient.cancelTokenSource.token,
        ...onUploadProgress,
        ...cancelToken,
      });

    const isTokenExpired = status === 401;

    if (isTokenExpired) {
      AuthService.kc.logout();
    }

    return currentData as ResponseType;
  }

  public static async get<ResponseType>(
    requestParams: RequestParams
  ): Promise<ResponseType> {
    return ApiClient.sendAndHandleResponse<ResponseType>({
      ...requestParams,
      method: Method.GET,
    });
  }

  public static post<ResponseType>(
    requestParams: RequestParams,
    onUploadProgress?: OnUploadProgress,
    cancelToken?: CancelToken
  ): Promise<ResponseType> {
    return ApiClient.sendAndHandleResponse<ResponseType>(
      {
        ...requestParams,
        method: Method.POST,
      },
      onUploadProgress,
      cancelToken
    );
  }

  public static put<ResponseType>(
    requestParams: RequestParams
  ): Promise<ResponseType> {
    return ApiClient.sendAndHandleResponse<ResponseType>({
      ...requestParams,
      method: Method.PUT,
    });
  }

  public static patch<ResponseType>(
    requestParams: RequestParams
  ): Promise<ResponseType> {
    return ApiClient.sendAndHandleResponse<ResponseType>({
      ...requestParams,
      method: Method.PATCH,
    });
  }

  public static delete<ResponseType>(
    requestParams: RequestParams
  ): Promise<ResponseType> {
    return ApiClient.sendAndHandleResponse<ResponseType>({
      ...requestParams,
      method: Method.DELETE,
    });
  }

  public static finishPendingRequests() {
    ApiClient.cancelTokenSource.cancel('Unmount');
    ApiClient.cancelTokenSource = axios.CancelToken.source();
  }
}
