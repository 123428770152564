import { FC, memo } from 'react';

import { Navigation, TopBar } from 'core/components';
import { UserAssistance } from 'features/UserAssistance';

import styles from './SidebarLayout.module.scss';

interface SidebarLayoutProps {
  authLoading?: boolean;
}

export const SidebarLayout: FC<SidebarLayoutProps> = memo(
  ({ children, authLoading }) => {
    const topBar = !authLoading && <TopBar />;

    return (
      <div className={styles.sidebarLayout}>
        <Navigation authLoading={authLoading} />
        <div className={styles.sidebarLayout__wrapper}>
          {topBar}
          {children}
        </div>
        <UserAssistance />
      </div>
    );
  }
);
