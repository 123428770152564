import { getUrl } from 'utils';

export const endpoints = {
  getOrganizations: () => getUrl('storages/organizations'),
  getAdminOrganizations: () => getUrl('organization/find-service-organization'),
  getOrganizationsForCreate: () => getUrl('organization/storage-creating-form'),
  getWorkGroups: () => getUrl('work-group/storage-creating-form'),
  getStorages: () => getUrl('storages/filter'),
  createStorage: () => getUrl('storages/manage'),
  editStorage: (id?: string) => getUrl(`storages/manage/${id}`),
  deleteStorage: (id: string) => getUrl(`storages/manage/${id}`),
  checkExistStorageTitle: (title: string) =>
    getUrl(`storages/manage/title-status?title=${title}`),
};
