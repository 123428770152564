import cn from 'clsx';
import Lottie from 'lottie-react';
import { FC } from 'react';

import { Size } from 'components';

import loader from './loader.json';
import styles from './Loader.module.scss';

interface LoaderProps {
  size?: Size;
  className?: string;
  classNameRoot?: string;
}

export const Loader: FC<LoaderProps> = ({
  size = Size.m,
  className,
  classNameRoot,
}) => {
  return (
    <div className={cn(styles.loader, styles[`loader_${size}`], classNameRoot)}>
      <Lottie className={className} animationData={loader} loop />
    </div>
  );
};
