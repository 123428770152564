import { FC, useState } from 'react';

import { Size } from 'components';
import { AttachmentDeleteType } from 'core/types/ticket';
import { AttachmentsFiles } from 'types/models/attachment';

import { PreviewImageModal } from '../PreviewImageModal';
import { TicketAttachments } from '../TicketInfo/components';
import { getAttachmentFilesComment } from '../TicketInfo/utils';

interface TicketNoteAttachmentsProps {
  files?: AttachmentsFiles[];
}

export const TicketNoteAttachments: FC<TicketNoteAttachmentsProps> = ({
  files,
}) => {
  const { imageFiles, documentFiles } = getAttachmentFilesComment(files);
  const [isImageModal, setIsImageModal] = useState<boolean>(false);
  const [initialImageIndex, setInitialImageIndex] = useState(0);

  const toggleIsImageModal = () => {
    setIsImageModal((prevState) => !prevState);
  };

  const handleInitialImageIndex = (index: number) => {
    setInitialImageIndex(index);
  };

  const imageModal = isImageModal && (
    <PreviewImageModal
      toggleModal={toggleIsImageModal}
      initialImageIndex={initialImageIndex}
      imageFiles={imageFiles}
    />
  );

  return (
    <>
      <TicketAttachments
        toggleIsModal={toggleIsImageModal}
        handleInitialImageIndex={handleInitialImageIndex}
        imageFiles={imageFiles}
        documentFiles={documentFiles}
        size={Size.m}
        attachmentDeleteType={AttachmentDeleteType.NOTES}
      />
      {imageModal}
    </>
  );
};
