import { AuthLoading as AuthLoadingContent } from 'core/components';
import { MainLayout, SidebarLayout } from 'core/layouts';

import styles from './Auth.module.scss';

export const AuthLoading = () => {
  return (
    <SidebarLayout authLoading>
      <MainLayout
        withHeader={false}
        classNameRoot={styles.authLoading__content}
      >
        <AuthLoadingContent />
      </MainLayout>
    </SidebarLayout>
  );
};
