import { JiraStatus, Status } from '../types';

export const getJiraStatus = (
  jiraStatuses?: JiraStatus[],
  ticketStatus?: Status
) => {
  return jiraStatuses?.find((jiraStatus) =>
    jiraStatus.ticketStatusIds?.find((item) => item.id === ticketStatus?.id)
  );
};
