import _ from 'lodash';

import { CustomFieldType, getValueFromValueType, ValueType } from 'components';

import {
  CustomFieldTypeForFilter,
  CustomFieldTypeForFilterRequest,
} from '../types';

import { getReverseDateForRequest } from './getReverseDateForRequest';

export const getPreparedCustomFieldsForSelect = <T>(
  valueName: CustomFieldTypeForFilter,
  value: ValueType<T> | string
): CustomFieldTypeForFilterRequest => {
  const isString = _.isString(value);

  const baseField: CustomFieldTypeForFilterRequest = {
    ...valueName,
    listValue: null,
    customFields: valueName.customFields,
  };

  if (valueName.type === CustomFieldType.FIELD_DATE && isString) {
    return { ...baseField, date: getReverseDateForRequest(value) };
  }
  if (valueName.type === CustomFieldType.FIELD_TEXT && isString) {
    return { ...baseField, text: value };
  }
  if (valueName.type === CustomFieldType.FIELD_FLAG && !isString) {
    return { ...baseField, flag: !!getValueFromValueType(value) };
  }
  if (valueName.type === CustomFieldType.FIELD_LIST && !isString) {
    const listOption = getValueFromValueType(value);
    const listFilterValue = listOption ? String(listOption) : null;

    return { ...baseField, listValue: listFilterValue };
  }
  return baseField;
};
