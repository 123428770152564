import { createSelector } from 'reselect';

import { getArraySelectOptions, SelectOption } from 'components';
import { Organization } from 'core/types/organization';
import { RootState } from 'store/rootReducer';

import { mapOrganizationsToSelectOptions } from '../utils';

export const getOrganizations = (
  state: RootState
): Organization[] | undefined => state.organizations.organizations;
export const getOrganizationsAdd = (
  state: RootState
): Organization[] | undefined => state.organizations.organizationsAdd;
export const getLoading = (state: RootState): boolean | undefined =>
  state.organizations.loading;
export const getLoadingOrganization = (state: RootState): boolean | undefined =>
  state.organizations.loadingOrganization;
export const getCurrentOrganization = (state: RootState) =>
  state.organizations?.organization;
export const getCurrentOrganizationId = (state: RootState) =>
  state.organizations.organizationId;
export const getFilterValues = (state: RootState) => state.organizations.filter;
export const getFilterOrganizationId = (state: RootState) =>
  state.organizations.filterOrganizationId;
export const getOrganizationsSelectList = createSelector<
  RootState,
  Organization[] | undefined,
  SelectOption[]
>([getOrganizations], (organizations): SelectOption[] => {
  return getArraySelectOptions(organizations);
});

export const getOrganizationsAddSelectList = createSelector<
  RootState,
  Organization[] | undefined,
  SelectOption[]
>([getOrganizationsAdd], (organizationsAdd): SelectOption[] => {
  return getArraySelectOptions(organizationsAdd);
});

export const getPropsOrganizations = (state: RootState) => ({
  pageNum: state.organizations.pagination?.pageNum,
  pageSize: state.organizations.pagination?.pageSize,
  loadingOrganization: getLoading(state),
  sortOrganizations: state.organizations?.sort,
  totalElements: state.organizations.pagination?.totalElements,
});
export const getPropsOrganizationsAdd = (state: RootState) => ({
  pageNum: state.organizations.paginationAdd?.pageNum,
  pageSize: state.organizations.paginationAdd?.pageSize,
  sortOrganizations: state.organizations.sortAdd,
  totalElements: state.organizations.paginationAdd?.totalElements,
  loadingOrganization: state.organizations.loadingAdd,
});
export const getFilterAddValues = (state: RootState) =>
  state.organizations.filterAdd;

export const createOrganizationsSelectListSelector = (
  selector: (state: RootState) => Organization[]
): ((state: RootState) => SelectOption[]) => {
  return createSelector(selector, (organizations) => {
    return mapOrganizationsToSelectOptions(organizations);
  });
};
