import { Reducer } from 'redux';

import { Improvement, TicketEvaluation } from 'features/Ticket/types';
import { ReducerMap } from 'store/types';

import {
  AssessmentInteraction,
  AssessmentInteractionActionsTypes,
  FetchImprovementsSuccessAction,
  FetchTicketEvaluationSuccessAction,
} from './types';

export interface AssessmentInteractionReducerState {
  improvements?: Improvement[];
  ticketEvaluation?: TicketEvaluation;
}

const initialState: AssessmentInteractionReducerState = {
  improvements: [],
};

const assessmentInteractionReducerMap: ReducerMap<
  AssessmentInteractionReducerState,
  AssessmentInteractionActionsTypes
> = {
  [AssessmentInteraction.RESET_ASSESSMENT_INTERACTION_STATE]: () => {
    return {
      ...initialState,
    };
  },
  [AssessmentInteraction.FETCH_IMPROVEMENTS_SUCCESS]: (state, action) => {
    const { payload } = action as FetchImprovementsSuccessAction;
    return {
      ...state,
      improvements: payload,
    };
  },
  [AssessmentInteraction.FETCH_TICKET_EVALUATION_SUCCESS]: (state, action) => {
    const { payload } = action as FetchTicketEvaluationSuccessAction;
    return {
      ...state,
      ticketEvaluation: payload,
    };
  },
};

export const assessmentInteraction: Reducer<
  AssessmentInteractionReducerState,
  AssessmentInteractionActionsTypes
> = (state = initialState, action) => {
  const reducer = assessmentInteractionReducerMap[action.type];
  if (!reducer) {
    return state;
  }
  return reducer(state, action);
};
