import { useTranslation } from 'react-i18next';

import { MainLayout } from 'core/layouts';
import { Tickets } from 'features/Tickets';

import styles from './Tickets.module.scss';

const TicketsPage = () => {
  const { t } = useTranslation('tickets');

  return (
    <MainLayout
      title={t('headerTitle')}
      withCreateTicketButton
      headerType="default"
      classNameRoot={styles.tickets}
    >
      <Tickets />
    </MainLayout>
  );
};

export default TicketsPage;
