import { getFormatDate } from 'utils';

import { News } from '../types';

type Args = {
  published: boolean;
  news: News;
};

export const getPublicationDate = ({ published, news }: Args) => {
  if (!published && news?.created) {
    return getFormatDate(news.created);
  }
  if (news?.publicationDate) {
    return getFormatDate(news.publicationDate);
  }
  return '';
};
