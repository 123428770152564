import { AdminsLayout } from 'core/layouts';
import { JiraIntegrations } from 'features/JiraIntegrations';

const AdminJiraIntegrations = () => {
  return (
    <AdminsLayout>
      <JiraIntegrations />
    </AdminsLayout>
  );
};

export default AdminJiraIntegrations;
