import cn from 'clsx';
import { FC, useCallback, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';

import { Loader } from 'components';
import { DataEmpty } from 'core/components';
import { getScreenWidth } from 'core/ducks/selectors';
import { ScreenWidth } from 'core/types';
import { useTabType } from 'features/Profile/hooks';

import {
  checkHasUnpublishedNews,
  checkNewsRequest,
  fetchCurrentNewsRequest,
  fetchNewsRequest,
} from '../../ducks/actions';
import { getCurrentNews, getLoadingCurrentNews } from '../../ducks/selectors';
import { News as NewsType } from '../../types';
import { formatDateTitle, splitByDateNews } from '../../utils';
import { News } from '../News';

import styles from './NewsList.module.scss';

interface NewsListProps {
  news: NewsType[];
  onLoadMore: (page: number) => void;
  hasMore: boolean;
  loading?: boolean;
  totalPages: number;
  published: boolean;
}

export const NewsList: FC<NewsListProps> = ({
  news,
  onLoadMore,
  hasMore,
  loading,
  totalPages,
  published,
}) => {
  const dispatch = useDispatch();
  const tabType = useTabType();

  const currentNews = useSelector(getCurrentNews);
  const screenWidth = useSelector(getScreenWidth);
  const loadingCurrentNews = useSelector(getLoadingCurrentNews);

  const isMobile = screenWidth === ScreenWidth.MOBILE;
  const isMobileSmall = screenWidth === ScreenWidth.MOBILE_SMALL;
  const isMobileAll = isMobile || isMobileSmall;

  const newsAll = splitByDateNews(news);

  const handleNewsClick = (id?: string, checked?: boolean) => {
    return () => {
      if (id) {
        dispatch(fetchCurrentNewsRequest(id));
        if (!checked) {
          dispatch(checkNewsRequest(id));
        }
      }
    };
  };

  const handleLoadMore = useCallback(
    (page: number) => {
      if (page < totalPages && !loading) {
        onLoadMore(page);
      }
    },
    [totalPages, loading, onLoadMore]
  );

  useEffect(() => {
    dispatch(checkHasUnpublishedNews());
    if (tabType) {
      dispatch(
        fetchNewsRequest({ updateType: 'update', pageNum: 0, published })
      );
    }
  }, [tabType]);

  const loader = loading && <Loader />;

  const emptyMessage = !loading && !news.length && (
    <>
      <DataEmpty />
    </>
  );

  if (!Object.keys(newsAll).length) {
    return (
      <div className={styles.newsList__noData}>
        {loader}
        {emptyMessage}
      </div>
    );
  }

  const newsList = Object.keys(newsAll).map((key) => {
    return (
      <div key={key}>
        {formatDateTitle(key)}
        {newsAll[key].map((item) => {
          const isActiveId = item?.id === currentNews?.id;

          const isDisabled = loadingCurrentNews || isActiveId;

          return (
            <div
              key={item.id}
              className={cn(styles.newsList__itemWrapper, {
                [styles.newsList__itemWrapper_active]: isActiveId,
              })}
            >
              <News
                news={item}
                className={cn(styles.newsList__item, {
                  [styles.newsList__item_disabled]: isDisabled,
                })}
                onClick={handleNewsClick(item.id, item.checked)}
                disabled={isDisabled}
              />
            </div>
          );
        })}
      </div>
    );
  });

  return (
    <div className={styles.newsList}>
      <InfiniteScroll
        loadMore={handleLoadMore}
        hasMore={hasMore}
        useWindow={isMobileAll}
        className={styles.newsList__content}
        threshold={10}
      >
        {newsList}
      </InfiniteScroll>
    </div>
  );
};
