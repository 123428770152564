import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { AdminsLayout, TablesLayout } from 'core/layouts';

import { RulesTableContainer } from './components';
import { INITIAL_ATTRIBUTES_VALUES } from './constants';
import {
  fetchAttributesFieldsRequest,
  fetchFiltersFieldsRequest,
  fetchRulesRequest,
  fetchSpecialistsRequest,
  fetchWorkGroupsRequest,
  resetRulesState,
} from './ducks';
import styles from './Rules.module.scss';

const MainTable = () => {
  return <RulesTableContainer />;
};

export const Rules = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRulesRequest());
    dispatch(fetchFiltersFieldsRequest());
    dispatch(fetchAttributesFieldsRequest(INITIAL_ATTRIBUTES_VALUES));
    dispatch(fetchSpecialistsRequest());
    dispatch(fetchWorkGroupsRequest());
    return () => {
      dispatch(resetRulesState());
    };
  }, []);

  return (
    <AdminsLayout>
      <TablesLayout
        MainTable={[MainTable]}
        classNameRoot={styles.rules}
        classNameSubTables={styles.rules__subTable}
        classNameMainTables={styles.rules__mainTable}
        SubTable={[]}
      />
    </AdminsLayout>
  );
};

export default Rules;
