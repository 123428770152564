import cn from 'clsx';
import { ChangeEvent, FC } from 'react';

import { Checkbox } from 'components';

import { TableColumn, TableColumns } from '../../types';
import { TableHeadCell } from '../TableHeadCell';
import { TableRow } from '../TableRow';

import styles from './TableHead.module.scss';

interface TableHeadProps {
  className?: string;
  fields: TableColumns;
  sort?: string;
  onSort?: (value: string) => void;
  disabledSort?: boolean;
  withCheckbox?: boolean;
  checked?: boolean;
  handleAllCheckbox?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const TableHead: FC<TableHeadProps> = ({
  children,
  className,
  fields,
  sort,
  onSort,
  disabledSort,
  withCheckbox,
  checked,
  handleAllCheckbox,
}) => {
  const headComponents = fields.map((item: TableColumn) => {
    if (typeof item.title === 'string') {
      return (
        <TableHeadCell
          title={item.title}
          sortField={item?.sortField}
          handleSort={onSort}
          sort={sort}
          key={`${item.fieldName}string`}
          disabled={disabledSort}
          className={styles.tableHead__cell}
        />
      );
    }
    return (
      <TableHeadCell
        sortField={item?.sortField}
        handleSort={onSort}
        sort={sort}
        key={item.title.key}
        disabled={disabledSort}
        className={styles.tableHead__cell}
      >
        {item.title}
      </TableHeadCell>
    );
  });
  return (
    <div className={cn(styles.tableHead, className)}>
      <TableRow className={styles.tableHead__row}>
        {withCheckbox && (
          <Checkbox
            checked={checked}
            onChange={handleAllCheckbox}
            className={styles.tableHead__checkbox}
          />
        )}
        {headComponents}
      </TableRow>
      {children}
    </div>
  );
};
