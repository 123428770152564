import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import {
  Button,
  Input,
  Size,
  Typography,
  TypographyVariants,
} from 'components';
import { changePassword } from 'features/Auth';

import { SettingsData } from '../../types';

import styles from './PasswordChange.module.scss';

export const PasswordChange = () => {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isValid },
  } = useForm<SettingsData>({
    mode: 'onChange',
  });

  const values = watch();

  const formSubmitHandler = handleSubmit((data) => {
    dispatch(changePassword(data.password));
    reset();
  });

  const passwordInputOptions = register('password', {
    required: true,
    minLength: {
      value: 8,
      message: 'Пароль пользователя должен содержать как минимум 8 символов.',
    },
    maxLength: {
      value: 128,
      message: 'Пароль пользователя не может быть длиннее 128 символов.',
    },
    pattern: {
      value:
        /(?=.*[0-9])(?=.*[-._!"`'#%&,:;<>=@{}~$()*+/\\?[\]^|])(?=.*[a-z])(?=.*[A-Z])^[0-9a-zA-Z-._!"`'#%&,:;<>=@{}~$()*+/\\?[\]^|]*$/g,
      message:
        'Пароль пользователя должен содержать хотя бы один спецсимвол, одну латинскую букву в верхнем и нижнем регистрах, одно число и не содержать пробелов',
    },
  });

  const passwordSecondInputOptions = register('passwordSecond', {
    required: true,
  });

  return (
    <form className={styles.passwordChange} onSubmit={formSubmitHandler}>
      <Typography
        variant={TypographyVariants.h4}
        className={styles.passwordChange__title}
      >
        Пароль
      </Typography>
      <Input
        {...passwordInputOptions}
        label="Пароль"
        className={styles.passwordChange__input}
        error={!!errors.password}
        errorMessage={errors.password?.message}
        isPassword
      />
      <Input
        {...passwordSecondInputOptions}
        label="Ещё раз"
        className={styles.passwordChange__input}
        error={!!values.password && values.password !== values.passwordSecond}
        errorMessage={
          !!values.password && values.password !== values.passwordSecond
            ? 'Пароли не совпадают'
            : ''
        }
        isPassword
      />
      <Button
        size={Size.m}
        type="submit"
        className={styles.passwordChange__btn}
        disabled={
          !isValid ||
          !(values.password && values.password === values.passwordSecond)
        }
      >
        Изменить пароль
      </Button>
    </form>
  );
};
