export enum RouterHref {
  Companies = '/',
  DesktopKpi = '/desktop',
  DesktopCurrentState = '/desktop/current-state',
  DesktopHistory = '/desktop/history',
  DesktopEmployees = '/desktop/employees',
  Tickets = '/tickets',
  TicketsAll = '/tickets/all',
  TicketsGroup = '/tickets/group',
  CreateTicket = '/tickets/create',
  TicketCard = '/tickets/:ticketId',
  AdminOrganizations = '/admin/list',
  Admin = '/admin',
  AdminCustomFields = '/admin/settings',
  AdminJiraIntegrations = '/admin/settings/jira-integrations',
  AdminStatusesBuilder = '/admin/settings/statuses-builder',
  AdminAssigningRules = '/admin/settings/assigning-rules',
  AdminTriggeredRules = '/admin/settings/triggered-rules',
  AdminContracts = '/admin/list/contracts',
  AdminSystems = '/admin/list/systems',
  AdminUsers = '/admin/list/users',
  AdminGroups = '/admin/list/work-groups',
  AdminRoles = '/admin/list/roles',
  AdminPowers = '/admin/list/powers',
  AdminCreateOrganization = '/admin/organizations/create',
  AdminCreateSupplementaryAgreement = '/admin/supplementary-agreement/create/:contractId/:systemId',
  AdminSupplementaryAgreementCard = '/admin/supplementary-agreement/:supplementaryAgreementId',
  AdminCreateContract = '/admin/contracts/create',
  AdminContractCard = '/admin/contracts/:contractId',
  AdminCreateUser = '/admin/users/create',
  AdminUserCard = '/admin/users/:userId',
  AdminCreateGroup = '/admin/work-groups/create',
  AdminOrganizationCard = '/admin/organizations/:organizationId',
  AdminWorkGroupCard = '/admin/work-groups/:workGroupId',
  AdminCreateSystem = '/admin/systems/create',
  AdminSystemCard = '/admin/systems/:systemId',
  Profile = '/profile',
  ProfileNotifications = '/profile/notifications',
  ProfileTelegram = '/profile/telegram',
  ProfileNews = '/profile/news',
  ProfileSettings = '/profile/settings',
  KnowledgeBase = '/knowledge-base',
  KnowledgeBaseCategoryTable = '/knowledge-base/list/:categoryId',
  KnowledgeBaseArticleCreate = '/knowledge-base/create',
  KnowledgeBaseArticle = '/knowledge-base/:articleId',
  KnowledgeBaseArticleEdit = '/knowledge-base/edit/:articleId',
  Storages = '/storages',
  Storage = '/storages/:storageId',
  NotFound = '/*',
}
