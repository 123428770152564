import { Reducer } from 'redux';

import { DEFAULT_PAGINATION_PARAMS } from 'constants/meta';
import {
  CustomField,
  Environment,
  EnvironmentSetting,
  TicketType,
} from 'core/types';
import { Ticket } from 'core/types/ticket';
import { ReducerMap } from 'store/types';
import { Pagination } from 'types/models/meta';

import { TicketsFilter } from '../types';

import {
  CreateTicket,
  CreateTicketActionsTypes,
  FetchEnvironmentsSuccessAction,
  FetchRequiredCustomFieldsSuccessAction,
  FetchTicketsSuccessJoinAction,
  FetchTicketsSuccessUpdateAction,
  FetchTicketTypesSuccessAction,
  SetCurrentPageTicketsAction,
  SetFilterTicketsAction,
} from './types';

export interface CreateTicketReducerState {
  environments?: Environment[];
  loading?: boolean;
  pagination?: Pagination;
  loadingCreate?: boolean;
  ticketTypes?: TicketType[];
  tickets?: Ticket[];
  loadingTickets?: boolean;
  paginationTickets?: Pagination;
  filterTickets?: TicketsFilter;
  customFields?: CustomField[];
  loadingCustomFields?: boolean;
  environmentsPagination?: Pagination;
  environmentsSort?: string;
  environmentsLoading?: boolean;
  environmentSettings?: EnvironmentSetting[];
}

const initialState: CreateTicketReducerState = {
  environments: [],
  loading: false,
  pagination: DEFAULT_PAGINATION_PARAMS,
  loadingCreate: false,
  ticketTypes: [],
  tickets: [],
  loadingTickets: false,
  paginationTickets: DEFAULT_PAGINATION_PARAMS,
  filterTickets: {},
  customFields: [],
  loadingCustomFields: false,
  environmentsSort: 'NAME_ASC',
  environmentsLoading: false,
};

const environmentsReducerMap: ReducerMap<
  CreateTicketReducerState,
  CreateTicketActionsTypes
> = {
  [CreateTicket.ENVIRONMENTS_LOADING_SHOW]: (state) => {
    return {
      ...state,
      loading: true,
    };
  },
  [CreateTicket.ENVIRONMENTS_LOADING_HIDE]: (state) => {
    return {
      ...state,
      loading: false,
    };
  },
  [CreateTicket.FETCH_ENVIRONMENTS_SUCCESS]: (state, action) => {
    const { payload } = action as FetchEnvironmentsSuccessAction;
    return {
      ...state,
      environments: payload.content,
    };
  },
  [CreateTicket.CREATE_LOADING_SHOW]: (state) => {
    return {
      ...state,
      loadingCreate: true,
    };
  },
  [CreateTicket.CREATE_LOADING_HIDE]: (state) => {
    return {
      ...state,
      loadingCreate: false,
    };
  },
  [CreateTicket.FETCH_CREATE_TICKET_TYPES_SUCCESS]: (state, action) => {
    const { payload } = action as FetchTicketTypesSuccessAction;
    return {
      ...state,
      ticketTypes: payload,
    };
  },
  [CreateTicket.RESET_CREATE_TICKET_STATE]: () => initialState,
  [CreateTicket.TICKETS_LOADING_SHOW]: (state) => {
    return {
      ...state,
      loadingTickets: true,
    };
  },
  [CreateTicket.TICKETS_LOADING_HIDE]: (state) => {
    return {
      ...state,
      loadingTickets: false,
    };
  },
  [CreateTicket.FETCH_TICKETS_SUCCESS_JOIN]: (state, action) => {
    const { payload } = action as FetchTicketsSuccessJoinAction;
    const { content, totalElements } = payload;
    return {
      ...state,
      tickets: [...(state?.tickets || []), ...content],
      paginationTickets: {
        ...state?.paginationTickets,
        totalElements,
      },
    };
  },
  [CreateTicket.FETCH_TICKETS_SUCCESS_UPDATE]: (state, action) => {
    const { payload } = action as FetchTicketsSuccessUpdateAction;
    const { content, totalElements } = payload;
    return {
      ...state,
      tickets: content,
      paginationTickets: {
        ...state?.paginationTickets,
        totalElements,
      },
    };
  },
  [CreateTicket.RESET_TICKETS_STATE]: (state) => {
    const { tickets, loadingTickets, paginationTickets, filterTickets } =
      initialState;
    return {
      ...state,
      tickets,
      loadingTickets,
      paginationTickets,
      filterTickets,
    };
  },
  [CreateTicket.SET_FILTER_TICKETS]: (state, action) => {
    const { payload } = action as SetFilterTicketsAction;
    return {
      ...state,
      filterTickets: payload,
    };
  },
  [CreateTicket.SET_CURRENT_PAGE_TICKETS]: (state, action) => {
    const { payload } = action as SetCurrentPageTicketsAction;
    return {
      ...state,
      paginationTickets: {
        ...state?.paginationTickets,
        pageNum: payload,
      },
    };
  },
  [CreateTicket.FETCH_REQUIRED_CUSTOM_FIELDS_REQUEST]: (state) => {
    return {
      ...state,
      loadingCustomFields: true,
    };
  },
  [CreateTicket.FETCH_REQUIRED_CUSTOM_FIELDS_SUCCESS]: (state, action) => {
    const { payload } = action as FetchRequiredCustomFieldsSuccessAction;
    return {
      ...state,
      customFields: payload,
      loadingCustomFields: false,
    };
  },
};

export const createTicket: Reducer<
  CreateTicketReducerState,
  CreateTicketActionsTypes
> = (state = initialState, action) => {
  const reducer = environmentsReducerMap[action.type];
  if (!reducer) {
    return state;
  }
  return reducer(state, action);
};
