import { CustomField } from 'core/types';
import { System } from 'core/types/system';
import { ResponseWithMeta } from 'store/types';

import { ExportFields, TicketsRequestData } from '../types';

import {
  CloseExportPanelAction,
  CustomFieldsExportLoadingHideAction,
  CustomFieldsExportLoadingShowAction,
  DownloadExcelRequestAction,
  DownloadingExcel,
  FetchCustomFieldsExportRequestAction,
  FetchCustomFieldsExportSuccessAction,
  FetchSystemsExportRequestAction,
  FetchSystemsExportSuccessAction,
  GetExportFieldsAction,
  GetExportFieldsRequestAction,
  GetTicketsCountRequestAction,
  OpenExportPanelAction,
  ResetCustomFieldsExportAction,
  ResetSelectedCustomFieldsAction,
  ResetTicketsExportStateAction,
  SelectAllCustomFieldsAction,
  SelectCustomFieldAction,
  SetExtraExportFields,
  SetTicketsCountAction,
  SystemsExportLoadingHideAction,
  SystemsExportLoadingShowAction,
  TicketsExport,
} from './types';

export const openExportPanel = (): OpenExportPanelAction => {
  return {
    type: TicketsExport.OPEN_EXPORT_PANEL,
  };
};

export const closeExportPanel = (): CloseExportPanelAction => {
  return {
    type: TicketsExport.CLOSE_EXPORT_PANEL,
  };
};

export const getExportFields = (
  payload: ExportFields
): GetExportFieldsAction => {
  return {
    type: TicketsExport.GET_EXPORT_FIELDS,
    payload,
  };
};

export const getExportFieldsRequest = (): GetExportFieldsRequestAction => {
  return {
    type: TicketsExport.GET_EXPORT_FIELDS_REQUEST,
  };
};

export const setTicketsCount = (payload?: number): SetTicketsCountAction => {
  return {
    type: TicketsExport.SET_TICKETS_COUNT,
    payload,
  };
};

export const getTicketsCountRequest = (
  payload: TicketsRequestData
): GetTicketsCountRequestAction => {
  return {
    type: TicketsExport.GET_TICKETS_COUNT_REQUEST,
    payload,
  };
};

export const downloadExcelRequest = (): DownloadExcelRequestAction => {
  return {
    type: TicketsExport.DOWNLOAD_EXCEL_REQUEST,
  };
};

export const getIsDownloadingExcel = (payload: boolean): DownloadingExcel => {
  return {
    type: TicketsExport.DOWNLOADING_EXCEL,
    payload,
  };
};

export const fetchSystemsExportRequest = (
  title?: string
): FetchSystemsExportRequestAction => {
  return {
    type: TicketsExport.FETCH_SYSTEMS_EXPORT_REQUEST,
    payload: title,
  };
};

export const fetchSystemsExportSuccess = (
  payload: ResponseWithMeta<System[]>
): FetchSystemsExportSuccessAction => {
  return {
    type: TicketsExport.FETCH_SYSTEMS_EXPORT_SUCCESS,
    payload,
  };
};

export const systemsExportLoadingShow = (): SystemsExportLoadingShowAction => {
  return {
    type: TicketsExport.SYSTEMS_EXPORT_LOADING_SHOW,
  };
};

export const systemsExportLoadingHide = (): SystemsExportLoadingHideAction => {
  return {
    type: TicketsExport.SYSTEMS_EXPORT_LOADING_HIDE,
  };
};

export const resetTicketsExportState = (): ResetTicketsExportStateAction => {
  return {
    type: TicketsExport.RESET_TICKETS_EXPORT_STATE,
  };
};

export const fetchCustomFieldsExportRequest = (
  systemId: string
): FetchCustomFieldsExportRequestAction => {
  return {
    type: TicketsExport.FETCH_CUSTOM_FIELDS_EXPORT_REQUEST,
    payload: systemId,
  };
};

export const fetchCustomFieldsExportSuccess = (
  payload: ResponseWithMeta<CustomField[]>
): FetchCustomFieldsExportSuccessAction => {
  return {
    type: TicketsExport.FETCH_CUSTOM_FIELDS_EXPORT_SUCCESS,
    payload,
  };
};

export const customFieldsExportLoadingShow =
  (): CustomFieldsExportLoadingShowAction => {
    return {
      type: TicketsExport.CUSTOM_FIELDS_EXPORT_LOADING_SHOW,
    };
  };

export const customFieldsExportLoadingHide =
  (): CustomFieldsExportLoadingHideAction => {
    return {
      type: TicketsExport.CUSTOM_FIELDS_EXPORT_LOADING_HIDE,
    };
  };

export const resetCustomFieldsExport = (): ResetCustomFieldsExportAction => {
  return {
    type: TicketsExport.RESET_CUSTOM_FIELDS_EXPORT,
  };
};

export const selectCustomField = (
  payload: CustomField
): SelectCustomFieldAction => {
  return {
    type: TicketsExport.SELECT_CUSTOM_FIELD,
    payload,
  };
};

export const selectAllCustomFields = (): SelectAllCustomFieldsAction => {
  return {
    type: TicketsExport.SELECT_ALL_CUSTOM_FIELDS,
  };
};

export const resetSelectedCustomFields =
  (): ResetSelectedCustomFieldsAction => {
    return {
      type: TicketsExport.RESET_SELECTED_CUSTOM_FIELDS,
    };
  };

export const setExtraExportFields = (
  payload?: CustomField[]
): SetExtraExportFields => {
  return {
    type: TicketsExport.SET_EXTRA_EXPORT_FIELDS,
    payload,
  };
};
