import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { TablesLayout } from 'core/layouts';
import { TableResponsibilitiesTypes } from 'core/types/responsibility';
import { AccessesTableContainer, resetAccessesState } from 'features/Accesses';
import { ActionsTableContainer, resetActionsState } from 'features/Actions';

import { ResponsibilitiesTableContainer } from './components';
import {
  fetchResponsibilitiesRequest,
  resetResponsibilitiesState,
} from './ducks/actions';
import styles from './Responsibilities.module.scss';

const SubTableFirst = () => {
  return <AccessesTableContainer />;
};

const SubTableSecond = () => {
  return <ActionsTableContainer />;
};

const MainTable = () => {
  return (
    <ResponsibilitiesTableContainer
      tableType={TableResponsibilitiesTypes.FULL}
    />
  );
};

export const Responsibilities = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchResponsibilitiesRequest());
    return () => {
      dispatch(resetResponsibilitiesState());
      dispatch(resetAccessesState());
      dispatch(resetActionsState());
    };
  }, []);

  return (
    <TablesLayout
      MainTable={[MainTable]}
      SubTable={[SubTableFirst, SubTableSecond]}
      classNameRoot={styles.responsibilities}
    />
  );
};
