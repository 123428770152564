import { NotificationContent } from 'core/types/notification';
import { ResponseWithMeta } from 'store/types';
import { KeyValueOption } from 'types/models/meta';

import {
  FetchNewsParams,
  FetchNotificationsParams,
  FilterNotificationsProfile,
  HasUncheckedNotificationsType,
  News,
  NewsCreateData,
  NewsDelete,
  ProfileSettings,
  ProfileTabSettings,
} from '../types';

export enum NotificationsProfile {
  FETCH_NOTIFICATIONS_PROFILE_REQUEST = 'PROFILE/FETCH_NOTIFICATIONS_PROFILE_REQUEST',
  FETCH_NOTIFICATIONS_PROFILE_SUCCESS = 'PROFILE/FETCH_NOTIFICATIONS_PROFILE_SUCCESS',
  FETCH_NOTIFICATIONS_PROFILE_SUCCESS_JOIN = 'PROFILE/FETCH_NOTIFICATIONS_PROFILE_SUCCESS_JOIN',
  SET_CURRENT_PAGE_NOTIFICATIONS_PROFILE = 'PROFILE/SET_CURRENT_PAGE_NOTIFICATIONS_PROFILE',
  SET_SIZE_PAGE_NOTIFICATIONS_PROFILE = 'PROFILE/SET_SIZE_PAGE_NOTIFICATIONS_PROFILE',
  SET_SORT_NOTIFICATIONS_PROFILE = 'PROFILE/SET_SORT_NOTIFICATIONS_PROFILE',
  SET_FILTER_NOTIFICATIONS_PROFILE = 'PROFILE/SET_FILTER_NOTIFICATIONS_PROFILE',
  RESET_STATE_NOTIFICATIONS_PROFILE = 'PROFILE/RESET_STATE_NOTIFICATIONS_PROFILE',
  RESET_NOTIFICATIONS_LIST = 'PROFILE/RESET_NOTIFICATIONS_LIST',
  LOADING_SHOW_NOTIFICATIONS_PROFILE = 'PROFILE/LOADING_SHOW_NOTIFICATIONS_PROFILE',
  LOADING_HIDE_NOTIFICATIONS_PROFILE = 'PROFILE/LOADING_HIDE_NOTIFICATIONS_PROFILE',
  SET_ALL_CHECKED_NOTIFICATIONS_REQUEST = 'PROFILE/SET_ALL_CHECKED_NOTIFICATIONS_REQUEST',
  GET_IS_UNCHECKED_STATUSES_REQUEST = 'PROFILE/GET_IS_UNCHECKED_STATUSES_REQUEST',
  SET_IS_UNCHECKED_STATUSES_SUCCESS = 'PROFILE/SET_IS_UNCHECKED_STATUSES_SUCCESS',
  SET_CHECKED_NOTIFICATION_REQUEST = 'PROFILE/SET_CHECKED_NOTIFICATION_REQUEST',
  SET_CHECKED_NOTIFICATION_SUCCESS = 'PROFILE/SET_CHECKED_NOTIFICATION_SUCCESS',
  FETCH_NOTIFICATION_TABS_REQUEST = 'PROFILE/FETCH_NOTIFICATION_TABS_REQUEST',
  FETCH_NOTIFICATION_TABS_SUCCESS = 'PROFILE/FETCH_NOTIFICATION_TABS_SUCCESS',
  FETCH_NOTIFICATION_SETTINGS_REQUEST = 'PROFILE/FETCH_NOTIFICATION_SETTINGS_REQUEST',
  FETCH_NOTIFICATION_SETTINGS_SUCCESS = 'PROFILE/FETCH_NOTIFICATION_SETTINGS_SUCCESS',
  UPDATE_NOTIFICATION_SETTINGS_REQUEST = 'PROFILE/UPDATE_NOTIFICATION_SETTINGS_REQUEST',
  FETCH_NEWS_REQUEST = 'PROFILE/FETCH_NEWS_REQUEST',
  FETCH_NEWS_SUCCESS = 'PROFILE/FETCH_NEWS_SUCCESS',
  FETCH_NEWS_SUCCESS_JOIN = 'PROFILE/FETCH_NEWS_SUCCESS_JOIN',
  SET_CURRENT_PAGE_NEWS = 'PROFILE/SET_CURRENT_PAGE_NEWS',
  SET_SIZE_PAGE_NEWS = 'PROFILE/SET_SIZE_PAGE_NEWS',
  SET_SORT_NEWS = 'PROFILE/SET_SORT_NEWS',
  RESET_STATE_NEWS = 'PROFILE/RESET_STATE_NEWS',
  LOADING_SHOW_NEWS = 'PROFILE/LOADING_SHOW_NEWS',
  LOADING_HIDE_NEWS = 'PROFILE/LOADING_HIDE_NEWS',
  NEWS_CREATE = 'PROFILE/NEWS_CREATE',
  NEWS_EDIT = 'PROFILE/NEWS_EDIT',
  CURRENT_NEWS_FETCH_REQUEST = 'PROFILE/CURRENT_NEWS_FETCH_REQUEST',
  CURRENT_NEWS_FETCH_SUCCESS = 'PROFILE/CURRENT_NEWS_FETCH_SUCCESS',
  LOADING_SHOW_CURRENT_NEWS = 'PROFILE/LOADING_SHOW_CURRENT_NEWS',
  LOADING_HIDE_CURRENT_NEWS = 'PROFILE/LOADING_HIDE_CURRENT_NEWS',
  SET_IS_EDIT_NEWS_MODE = 'PROFILE/SET_IS_EDIT_NEWS_MODE',
  SET_IS_NEWS_MODAL = 'PROFILE/SET_IS_NEWS_MODAL',
  NEWS_DELETE = 'PROFILE/NEWS_DELETE',
  FETCH_IS_UNCHECKED_NEWS_REQUEST = 'PROFILE/FETCH_IS_UNCHECKED_NEWS_REQUEST',
  FETCH_IS_UNCHECKED_NEWS_SUCCESS = 'PROFILE/FETCH_IS_UNCHECKED_NEWS_SUCCESS',
  CHECK_NEWS_REQUEST = 'PROFILE/CHECK_NEWS_REQUEST',
  CHECK_NEWS_SUCCESS = 'PROFILE/CHECK_NEWS_SUCCESS',
  FETCH_ORGANIZATIONS = 'PROFILE/FETCH_ORGANIZATIONS',
  FETCH_ORGANIZATIONS_SUCCESS = 'PROFILE/FETCH_ORGANIZATIONS_SUCCESS',
  FETCH_WORK_GROUPS = 'PROFILE/FETCH_WORK_GROUPS',
  FETCH_WORK_GROUPS_SUCCESS = 'PROFILE/FETCH_WORK_GROUPS_SUCCESS',
  CHECK_HAS_UNPUBLISHED_NEWS_REQUEST = 'PROFILE/CHECK_HAS_UNPUBLISHED_NEWS_REQUEST',
  CHECK_HAS_UNPUBLISHED_NEWS_SUCCESS = 'PROFILE/CHECK_HAS_UNPUBLISHED_NEWS_SUCCESS',
}

export interface ShowNotificationsProfileLoadingAction {
  type: NotificationsProfile.LOADING_SHOW_NOTIFICATIONS_PROFILE;
}

export interface HideNotificationsProfileLoadingAction {
  type: NotificationsProfile.LOADING_HIDE_NOTIFICATIONS_PROFILE;
}

export interface FetchNotificationsProfileSuccessAction {
  type: NotificationsProfile.FETCH_NOTIFICATIONS_PROFILE_SUCCESS;
  payload: ResponseWithMeta<NotificationContent[]>;
}

export interface FetchNotificationsProfileRequestAction {
  type: NotificationsProfile.FETCH_NOTIFICATIONS_PROFILE_REQUEST;
  payload: FetchNotificationsParams;
}

export interface SetCurrentNotificationsProfilePageAction {
  type: NotificationsProfile.SET_CURRENT_PAGE_NOTIFICATIONS_PROFILE;
  payload: number;
}

export interface SetSizePageNotificationsProfileAction {
  type: NotificationsProfile.SET_SIZE_PAGE_NOTIFICATIONS_PROFILE;
  payload: number;
}

export interface SetSortNotificationsProfileAction {
  type: NotificationsProfile.SET_SORT_NOTIFICATIONS_PROFILE;
  payload: string;
}

export interface FetchNotificationsProfileJoinSuccessAction {
  type: NotificationsProfile.FETCH_NOTIFICATIONS_PROFILE_SUCCESS_JOIN;
  payload: ResponseWithMeta<NotificationContent[]>;
}

export interface ResetNotificationsProfileStateAction {
  type: NotificationsProfile.RESET_STATE_NOTIFICATIONS_PROFILE;
}

export interface ResetNotificationsListStateAction {
  type: NotificationsProfile.RESET_NOTIFICATIONS_LIST;
}

export interface SetFilterNotificationsProfileAction {
  type: NotificationsProfile.SET_FILTER_NOTIFICATIONS_PROFILE;
  payload: FilterNotificationsProfile;
}

export interface SetAllCheckedNotificationsRequestAction {
  type: NotificationsProfile.SET_ALL_CHECKED_NOTIFICATIONS_REQUEST;
}

export interface FetchIsUncheckedNotificationsRequestAction {
  type: NotificationsProfile.GET_IS_UNCHECKED_STATUSES_REQUEST;
  payload: HasUncheckedNotificationsType[];
}

export interface SetIsUncheckedNotificationsSuccessAction {
  type: NotificationsProfile.SET_IS_UNCHECKED_STATUSES_SUCCESS;
  payload: HasUncheckedNotificationsType[];
}

export interface SetCheckedNotificationRequestAction {
  type: NotificationsProfile.SET_CHECKED_NOTIFICATION_REQUEST;
  payload: string;
}
export interface SetCheckedNotificationSuccessAction {
  type: NotificationsProfile.SET_CHECKED_NOTIFICATION_SUCCESS;
  payload: string;
}

export interface FetchNotificationTabsRequestAction {
  type: NotificationsProfile.FETCH_NOTIFICATION_TABS_REQUEST;
}

export interface FetchNotificationTabsSuccessAction {
  type: NotificationsProfile.FETCH_NOTIFICATION_TABS_SUCCESS;
  payload: ProfileTabSettings[];
}

export interface UpdateNotificationSettingsRequestAction {
  type: NotificationsProfile.UPDATE_NOTIFICATION_SETTINGS_REQUEST;
  payload: ProfileSettings;
}

export interface FetchNotificationSettingsSuccessAction {
  type: NotificationsProfile.FETCH_NOTIFICATION_SETTINGS_SUCCESS;
  payload: ProfileSettings;
}

export interface FetchNotificationSettingsRequestAction {
  type: NotificationsProfile.FETCH_NOTIFICATION_SETTINGS_REQUEST;
}

export interface ShowNewsLoadingAction {
  type: NotificationsProfile.LOADING_SHOW_NEWS;
}

export interface HideNewsLoadingAction {
  type: NotificationsProfile.LOADING_HIDE_NEWS;
}

export interface FetchNewsSuccessAction {
  type: NotificationsProfile.FETCH_NEWS_SUCCESS;
  payload: ResponseWithMeta<News[]>;
}

export interface FetchNewsRequestAction {
  type: NotificationsProfile.FETCH_NEWS_REQUEST;
  payload: FetchNewsParams;
}

export interface SetCurrentNewsPageAction {
  type: NotificationsProfile.SET_CURRENT_PAGE_NEWS;
  payload: number;
}

export interface SetSizePageNewsAction {
  type: NotificationsProfile.SET_SIZE_PAGE_NEWS;
  payload: number;
}

export interface SetSortNewsAction {
  type: NotificationsProfile.SET_SORT_NEWS;
  payload: string;
}

export interface FetchNewsJoinSuccessAction {
  type: NotificationsProfile.FETCH_NEWS_SUCCESS_JOIN;
  payload: ResponseWithMeta<News[]>;
}

export interface ResetNewsStateAction {
  type: NotificationsProfile.RESET_STATE_NEWS;
}

export interface NewsCreateAction {
  type: NotificationsProfile.NEWS_CREATE;
  payload: NewsCreateData;
}

export interface NewsEditAction {
  type: NotificationsProfile.NEWS_EDIT;
  payload: NewsCreateData;
}

export interface FetchCurrentNewsRequestAction {
  type: NotificationsProfile.CURRENT_NEWS_FETCH_REQUEST;
  payload: string;
}

export interface FetchCurrentNewsSuccessAction {
  type: NotificationsProfile.CURRENT_NEWS_FETCH_SUCCESS;
  payload?: News;
}

export interface ShowCurrentNewsLoadingAction {
  type: NotificationsProfile.LOADING_SHOW_CURRENT_NEWS;
}

export interface HideCurrentNewsLoadingAction {
  type: NotificationsProfile.LOADING_HIDE_CURRENT_NEWS;
}

export interface SetIsEditNewsModeAction {
  type: NotificationsProfile.SET_IS_EDIT_NEWS_MODE;
  payload: boolean;
}

export interface SetIsNewsModalAction {
  type: NotificationsProfile.SET_IS_NEWS_MODAL;
  payload: boolean;
}

export interface NewsDeleteAction {
  type: NotificationsProfile.NEWS_DELETE;
  payload: NewsDelete;
}

export interface FetchIsUncheckedNewsRequestAction {
  type: NotificationsProfile.FETCH_IS_UNCHECKED_NEWS_REQUEST;
}

export interface FetchIsUncheckedNewsSuccessAction {
  type: NotificationsProfile.FETCH_IS_UNCHECKED_NEWS_SUCCESS;
  payload: boolean;
}

export interface CheckNewsRequestAction {
  type: NotificationsProfile.CHECK_NEWS_REQUEST;
  payload: string;
}

export interface CheckNewsSuccessAction {
  type: NotificationsProfile.CHECK_NEWS_SUCCESS;
  payload: string;
}

export interface FetchOrganizationsRequestAction {
  type: NotificationsProfile.FETCH_ORGANIZATIONS;
}

export interface FetchOrganizationsSuccessAction {
  type: NotificationsProfile.FETCH_ORGANIZATIONS_SUCCESS;
  payload: KeyValueOption[];
}

export interface FetchWorkGroupsRequestAction {
  type: NotificationsProfile.FETCH_WORK_GROUPS;
  payload: string[];
}

export interface FetchWorkGroupsSuccessAction {
  type: NotificationsProfile.FETCH_WORK_GROUPS_SUCCESS;
  payload: KeyValueOption[];
}

export interface CheckHasUnpublishedNewsAction {
  type: NotificationsProfile.CHECK_HAS_UNPUBLISHED_NEWS_REQUEST;
}

export interface CheckHasUnpublishedNewsSuccessAction {
  type: NotificationsProfile.CHECK_HAS_UNPUBLISHED_NEWS_SUCCESS;
  payload: boolean;
}

export type NotificationsProfileActionsTypes =
  | ShowNotificationsProfileLoadingAction
  | HideNotificationsProfileLoadingAction
  | FetchNotificationsProfileSuccessAction
  | FetchNotificationsProfileRequestAction
  | SetCurrentNotificationsProfilePageAction
  | SetSizePageNotificationsProfileAction
  | SetSortNotificationsProfileAction
  | FetchNotificationsProfileJoinSuccessAction
  | ResetNotificationsProfileStateAction
  | SetFilterNotificationsProfileAction
  | SetAllCheckedNotificationsRequestAction
  | SetIsUncheckedNotificationsSuccessAction
  | SetCheckedNotificationSuccessAction
  | FetchNotificationTabsSuccessAction
  | FetchNotificationSettingsSuccessAction
  | ResetNotificationsListStateAction
  | ShowNewsLoadingAction
  | HideNewsLoadingAction
  | FetchNewsSuccessAction
  | FetchNewsRequestAction
  | SetCurrentNewsPageAction
  | SetSizePageNewsAction
  | SetSortNewsAction
  | FetchNewsJoinSuccessAction
  | ResetNewsStateAction
  | NewsCreateAction
  | FetchCurrentNewsSuccessAction
  | ShowCurrentNewsLoadingAction
  | HideCurrentNewsLoadingAction
  | SetIsEditNewsModeAction
  | SetIsNewsModalAction
  | FetchIsUncheckedNewsSuccessAction
  | CheckNewsSuccessAction
  | FetchOrganizationsSuccessAction
  | FetchWorkGroupsSuccessAction
  | CheckHasUnpublishedNewsSuccessAction;
