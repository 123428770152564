import { useSelector } from 'react-redux';

import {
  KpiActiveTickets,
  KpiAllTickets,
  KpiSlaLineChart,
  KpiTicketsPieChart,
} from 'features/Desktop/components';

import { getKpiData } from '../../ducks/Kpi/selectors';

import styles from './DesktopKpi.module.scss';

export const DesktopKpi = () => {
  const kpiData = useSelector(getKpiData);

  return (
    <div className={styles.kpi}>
      <div className={styles.kpi__container}>
        <KpiActiveTickets kpiData={kpiData} />
        <KpiAllTickets kpiData={kpiData} />
      </div>
      <div className={styles.kpi__container}>
        <KpiSlaLineChart kpiData={kpiData} />
        <KpiTicketsPieChart kpiData={kpiData} />
      </div>
    </div>
  );
};
