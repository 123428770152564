import cn from 'clsx';
import { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { BuildingIcon, SettingsIcon } from 'assets/icons';
import { RadioSlide, Size, Tab } from 'components';
import { MainLayout } from 'core/layouts';
import { ActionList } from 'core/types/auth';
import { getIsAccessedAction } from 'features/Auth/ducks/selectors';
import { RouterHref } from 'routes/routerHref';

import styles from './AdminsLayout.module.scss';

const COMPANY_TABS = [
  {
    to: RouterHref.AdminOrganizations,
    exact: true,
    title: 'Организации',
    className: styles.admins__tab,
  },
  {
    to: RouterHref.AdminContracts,
    exact: false,
    title: 'Контракты',
    className: styles.admins__tab,
  },
  {
    to: RouterHref.AdminSystems,
    exact: false,
    title: 'Системы',
    className: styles.admins__tab,
  },
];

const USER_TABS = [
  {
    to: RouterHref.AdminUsers,
    exact: false,
    title: 'Пользователи',
    className: styles.admins__tab,
  },
  {
    to: RouterHref.AdminGroups,
    exact: false,
    title: 'Группы',
    className: styles.admins__tab,
  },
  {
    to: RouterHref.AdminRoles,
    exact: false,
    title: 'Роли',
    className: styles.admins__tab,
  },
  {
    to: RouterHref.AdminPowers,
    exact: false,
    title: 'Полномочия',
    className: styles.admins__tab,
  },
];

export const AdminsLayout: FC = ({ children }) => {
  const [tabs, setTabs] = useState<Tab[]>();

  const { push } = useHistory();
  const { pathname } = useLocation();

  const isAccessToViewingCompany = useSelector(
    getIsAccessedAction(ActionList.ViewingCompanyAdministration)
  );
  const isAccessToViewingUser = useSelector(
    getIsAccessedAction(ActionList.ViewingUserAdministration)
  );
  const isAccessToViewingSettings = useSelector(
    getIsAccessedAction(ActionList.ViewingSettings)
  );
  const isAccessToCreateRules = useSelector(
    getIsAccessedAction(ActionList.CreateRules)
  );

  const isAccessToCreateRulesForPartner =
    !isAccessToViewingSettings && isAccessToCreateRules;

  const RULE_TAB = {
    to: RouterHref.AdminAssigningRules,
    title: 'Правила',
    className: cn(styles.admins__tab, {
      [styles.admins__tab_hidden]: !isAccessToCreateRules,
    }),
  };

  const SettingsTabs = useMemo(
    () => [
      {
        to: RouterHref.AdminCustomFields,
        exact: true,
        title: 'Кастомные поля',
        className: styles.admins__tab,
      },
      {
        to: RouterHref.AdminJiraIntegrations,
        title: 'Интеграция c Jira',
        className: styles.admins__tab,
      },
      {
        to: RouterHref.AdminStatusesBuilder,
        title: 'Настройка статусов',
        className: styles.admins__tab,
      },
      RULE_TAB,
      {
        to: RouterHref.AdminTriggeredRules,
        title: 'Триггеры',
        className: cn(styles.admins__tab),
      },
    ],
    [isAccessToCreateRules]
  );

  const isStatusesBuilderPage = pathname === RouterHref.AdminStatusesBuilder;
  const isAdminCustomFieldsPage = pathname.includes(
    RouterHref.AdminCustomFields
  );

  const showTabsSettings = useMemo(
    () =>
      ((isAccessToViewingCompany || isAccessToViewingUser) &&
        isAccessToViewingSettings) ||
      isAccessToCreateRules,
    [
      isAccessToViewingCompany,
      isAccessToViewingUser,
      isAccessToViewingSettings,
      isAccessToCreateRules,
    ]
  );

  const slideTabsSetting = useMemo(
    () => [
      {
        title: 'Структура',
        value: 'Структура',
        icon: <BuildingIcon />,
        readOnly: true,
        checked: pathname.includes(RouterHref.AdminOrganizations),
        onClick: () => {
          push(
            isAccessToCreateRulesForPartner
              ? RouterHref.AdminUsers
              : RouterHref.AdminOrganizations
          );
        },
      },
      {
        title: 'Настройки',
        readOnly: true,
        value: 'Настройки',
        icon: <SettingsIcon />,
        checked: pathname.includes(RouterHref.AdminCustomFields),
        onClick: () => {
          push(
            isAccessToCreateRulesForPartner
              ? RouterHref.AdminAssigningRules
              : RouterHref.AdminCustomFields
          );
        },
      },
    ],
    [pathname, isAccessToCreateRulesForPartner]
  );

  const tabsSettings = (
    <RadioSlide
      size={Size.xs}
      name="settings"
      readOnly
      items={slideTabsSetting}
      className={styles.admins__tabSettings}
      classNameTitleLabel={styles.admins__text}
      classNameIconLabel={styles.admins__icon}
      classNameLabelBlock={styles.admins__block}
    />
  );

  useEffect(() => {
    if (isAccessToViewingCompany) {
      setTabs(COMPANY_TABS);
    }
    if (isAccessToViewingUser) {
      setTabs(USER_TABS);
    }
    if (isAccessToViewingCompany && isAccessToViewingUser) {
      setTabs([...COMPANY_TABS, ...USER_TABS]);
    }
    if (isAdminCustomFieldsPage && isAccessToViewingSettings) {
      setTabs(SettingsTabs);
      return;
    }
    if (isAdminCustomFieldsPage && isAccessToCreateRulesForPartner) {
      setTabs([RULE_TAB]);
    }
  }, [
    isAccessToViewingCompany,
    isAccessToViewingUser,
    isAccessToViewingSettings,
    isAccessToCreateRulesForPartner,
    isAdminCustomFieldsPage,
  ]);

  return (
    <MainLayout
      headerType="default"
      title="Администрирование"
      tabs={tabs}
      classNameRoot={cn(styles.admins, {
        [styles.admins_builder]: isStatusesBuilderPage,
      })}
      slideSetting={showTabsSettings ? tabsSettings : undefined}
    >
      {children}
    </MainLayout>
  );
};
