import { FC, ReactNode } from 'react';
import { createPortal } from 'react-dom';

export interface PortalProps {
  children: ReactNode;
  parentNode?: HTMLElement | null;
  key?: string;
}

export const Portal: FC<PortalProps> = ({ children, parentNode, key }) => {
  const container = parentNode ?? document.body;

  return createPortal(children, container, key);
};
