import { CalendarIcon } from 'assets/icons';
import { ConditionBlock } from 'components';
import { Contract } from 'core/types';
import { getFormatDateWithoutTime } from 'utils';

import styles from './getDataTable.module.scss';

export const getDataTable = (supplementaryAgreements?: Contract[]) => {
  return (
    supplementaryAgreements?.map((supplementaryAgreement) => {
      const { title, systemTitle, id, openDate, endDate } =
        supplementaryAgreement;
      return {
        id,
        title: {
          title,
          to: `/admin/supplementary-agreement/${id}`,
        },

        systemTitle: <ConditionBlock text={systemTitle} />,
        supportPeriod: (
          <div className={styles.dateContent}>
            <CalendarIcon className={styles.dateContent__icon} />
            {getFormatDateWithoutTime(openDate)}
            {' - '}
            {getFormatDateWithoutTime(endDate)}
          </div>
        ),
      };
    }) || []
  );
};
