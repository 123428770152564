import cn from 'clsx';
import {
  type ButtonHTMLAttributes,
  type FC,
  type MouseEvent,
  type ReactNode,
} from 'react';

import { createBemClass } from 'components-new/helpers/createBemClass';

import { type ColorScheme, type Size } from '../types';

import styles from './IconButtonAlt.module.scss';

export interface IconButtonAltProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children'> {
  colorScheme?: ColorScheme;
  size?: Exclude<Size, 's' | 'm' | 'l'> | '3xs';
  icon: ReactNode;
}

const rootClassName = createBemClass('icon-button-alt');

export const IconButtonAlt: FC<IconButtonAltProps> = ({
  colorScheme = 'blue',
  size = 'xs',
  className,
  icon,
  disabled,
  onMouseDown,
  type = 'button',
  ...other
}) => {
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (onMouseDown) {
      onMouseDown(event);
    }
  };

  return (
    <button
      {...other}
      className={cn(
        styles[rootClassName()],
        styles[rootClassName({ modName: size })],
        styles[rootClassName({ modName: colorScheme })],
        className
      )}
      disabled={disabled}
      onMouseDown={handleClick}
      type={type}
    >
      {icon}
    </button>
  );
};
