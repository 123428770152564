import { FC } from 'react';
import { useSelector } from 'react-redux';

import { PopoverContainer } from 'components';
import { DeleteButton } from 'core/components';
import { getCurrentContract } from 'features/Contracts/ducks/selectors';

import styles from './ContractsDropContent.module.scss';

interface ContractsDropContentProps {
  handleDelete: () => void;
}

export const ContractsDropContent: FC<ContractsDropContentProps> = ({
  handleDelete,
}) => {
  const contract = useSelector(getCurrentContract);

  return (
    <div className={styles.contractsDropContent}>
      <div className={styles.contractsDropContent__buttonWrapper}>
        <PopoverContainer>
          <DeleteButton onClick={handleDelete} disabled={!contract} />
        </PopoverContainer>
      </div>
    </div>
  );
};
