import cn from 'clsx';
import { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Card, Loader, SelectOption } from 'components';
import { DataEmpty } from 'core/components';
import { EmptyDataType } from 'core/components/DataEmpty/types';
import { CategoryArticle } from 'core/types/knowledgeBase';
import { getUserOrganizationId } from 'features/Auth';
import {
  KBArticleDropProps,
  KBCategoriesHeader,
  KBCategoriesList,
  KBCategory,
  KBCategoryDropProps,
  SelectActionsProps,
  SelectorProps,
} from 'features/KnowledgeBase/components';

import { ValuesProps } from '../../../../components/KBOrganizationSelect/KBOrganizationSelect';
import {
  createCategoryRequestArticlesMain,
  deleteArticleRequestArticlesMain,
  deleteCategoryRequestArticlesMain,
  editArticleRequestArticlesMain,
  editCategoryRequestArticlesMain,
  fetchCategoriesRequestArticlesMain,
  fetchOrganizationsArticlesMainRequest,
  resetCategoriesStateMain,
  setSelectedOrganizationArticlesMain,
} from '../../ducks/actions';
import {
  getCategoriesArticlesMain,
  getLoadingCategoriesArticlesMain,
  getOrganizationsArticlesMainSelectList,
  getSelectedOrganizationArticlesMain,
} from '../../ducks/selectors';

import styles from './KBCategoryMainBlock.module.scss';

interface KBCategoryMainBlockProps {
  className?: string;
  classNameList?: string;
  withHeader?: boolean;
}

export const KBCategoryMainBlock: FC<KBCategoryMainBlockProps> = ({
  className,
  classNameList,
  withHeader = false,
}) => {
  const dispatch = useDispatch();
  const categories = useSelector(getCategoriesArticlesMain);
  const loading = useSelector(getLoadingCategoriesArticlesMain);
  const organizationSelected = useSelector(getSelectedOrganizationArticlesMain);
  const userOrgId = useSelector(getUserOrganizationId);

  const onChangeTitle = (id: string, newTitle = '') => {
    dispatch(editCategoryRequestArticlesMain({ id, title: newTitle }));
  };

  const onDeleteCategory = (id?: string) => {
    if (id) {
      dispatch(deleteCategoryRequestArticlesMain(id));
    }
  };

  const onSubCategoryCreate = (data: Partial<CategoryArticle>) => {
    dispatch(createCategoryRequestArticlesMain(data));
  };

  const onChangeTitleArticle = (id: string, newTitle = '') => {
    dispatch(editArticleRequestArticlesMain({ id, title: newTitle }));
  };

  const onArticleDelete = (id: string) => {
    dispatch(deleteArticleRequestArticlesMain(id));
  };

  const onFilterOrganization = (valueFilter: string) => {
    dispatch(
      fetchOrganizationsArticlesMainRequest({ organizationTitle: valueFilter })
    );
  };

  const fetchCategories = () => {
    if (userOrgId) {
      dispatch(fetchCategoriesRequestArticlesMain([userOrgId]));
    }
  };

  const fetchOrganizations = () => {
    dispatch(fetchOrganizationsArticlesMainRequest({}));
  };

  const resetCategories = () => {
    dispatch(resetCategoriesStateMain());
  };

  const onChangeOrganization = (value: SelectOption) => {
    dispatch(setSelectedOrganizationArticlesMain(value));
    dispatch(fetchCategoriesRequestArticlesMain([value?.value]));
  };

  const onCreateCategory = useCallback(
    (data?: Partial<CategoryArticle>) => {
      if (data) {
        dispatch(createCategoryRequestArticlesMain(data));
      }
    },
    [dispatch]
  );

  const articleDropProps: KBArticleDropProps = {
    onChangeTitle: onChangeTitleArticle,
    onDelete: onArticleDelete,
    withChangeArticleTitle: true,
    withDeleteArticle: true,
  };

  const categoryDropProps: KBCategoryDropProps = {
    onChangeTitle,
    onDeleteCategory,
    onSubCategoryCreate,
    withArticleCreate: true,
  };

  const actionsProps: SelectActionsProps = {
    onInputFilter: onFilterOrganization,
    fetchCategories,
    fetchOrganizations,
    resetCategories,
    onChangeOrganization,
  };

  const valuesProps: ValuesProps = {
    organizationSelected,
  };

  const selectProps: SelectorProps = {
    getSelectList: getOrganizationsArticlesMainSelectList,
  };

  const categoriesList = categories?.map((category) => (
    <KBCategory
      category={category}
      key={category?.id}
      withDrop
      dropProps={categoryDropProps}
      articleDropProps={articleDropProps}
      showFullCategory
    />
  ));

  return (
    <Card className={cn(styles.KBCategoryMainBlock, className)}>
      {withHeader && (
        <KBCategoriesHeader
          actionsProps={actionsProps}
          selectorProps={selectProps}
          valuesProps={valuesProps}
          onCreateCategory={onCreateCategory}
        />
      )}
      {loading && <Loader classNameRoot={styles.KBCategoryMainBlock__loader} />}
      {!loading && !categoriesList?.length && (
        <DataEmpty
          type={EmptyDataType.NO_DATA_WITH_FILTER}
          className={styles.KBCategoryMainBlock__noData}
        />
      )}
      {Boolean(categoriesList?.length) && !loading && (
        <KBCategoriesList
          categories={categoriesList}
          loading={loading}
          classNameList={classNameList}
        />
      )}
    </Card>
  );
};
