import cn from 'clsx';
import { FC } from 'react';

import styles from './UserAssistanceContentWrapper.module.scss';

interface UserAssistanceContentWrapperProps {
  className?: string;
}

export const UserAssistanceContentWrapper: FC<
  UserAssistanceContentWrapperProps
> = ({ children, className }) => {
  return (
    <div className={cn(styles.userAssistanceContentWrapper, className)}>
      {children}
    </div>
  );
};
