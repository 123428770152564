import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PopoverContainer, PopoverItemWrapper, ToggleSwitch } from 'components';
import { DeleteButton } from 'core/components';

import { editJiraIntegrationsActive } from '../../ducks/actions';
import {
  getCurrentJiraIntegrationId,
  getJiraIntegration,
} from '../../ducks/selectors';

import styles from './JiraIntegrationsDropContent.module.scss';

interface JiraIntegrationsDropContentProps {
  handleDelete: () => void;
}

export const JiraIntegrationsDropContent: FC<
  JiraIntegrationsDropContentProps
> = ({ handleDelete }) => {
  const currentJiraIntegrationId = useSelector(getCurrentJiraIntegrationId);
  const jiraIntegration = useSelector(getJiraIntegration);

  const dispatch = useDispatch();

  const handleJiraIntegrationActive = () => {
    if (jiraIntegration) {
      dispatch(
        editJiraIntegrationsActive({
          id: currentJiraIntegrationId,
          isActive: !jiraIntegration.isActive,
        })
      );
    }
  };

  return (
    <PopoverContainer>
      <PopoverItemWrapper
        className={styles.jiraIntegrationsDropContent__switchBlock}
      >
        <ToggleSwitch
          label="Активно"
          checked={!!jiraIntegration?.isActive}
          onChange={handleJiraIntegrationActive}
          disabled={!currentJiraIntegrationId}
        />
      </PopoverItemWrapper>
      <DeleteButton
        onClick={handleDelete}
        disabled={!currentJiraIntegrationId}
      />
    </PopoverContainer>
  );
};
