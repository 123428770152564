import cn from 'clsx';
import { FC, Fragment } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Link } from 'react-router-dom';

import { BookIcon } from 'assets/icons';
import { Card, Loader, Typography, TypographyVariants } from 'components';
import { DataEmpty } from 'core/components';
import { EmptyDataType } from 'core/components/DataEmpty/types';
import {
  KBCategoriesHeader,
  KBCategoriesList,
  KBCategory,
} from 'features/KnowledgeBase/components';
import {
  KBArticlesSearch,
  renderArticleFunction,
} from 'features/KnowledgeBase/components/KBArticlesSearch';
import { useKBArticleViewingSearch } from 'features/KnowledgeBase/views/KBArticle/hooks';
import { RouterHref } from 'routes/routerHref';

import styles from './KBArticleViewingSearch.module.scss';

interface KBArticleViewingSearchProps {
  className?: string;
}

export const KBArticleViewingSearch: FC<KBArticleViewingSearchProps> = ({
  className,
}) => {
  const { state, methods } = useKBArticleViewingSearch();
  const {
    categoryDropProps,
    articleDropProps,
    selectOrganizationsProps,
    valuesProps,
    actionsOrganizationsProps,
    selectorsSearchProps,
    actionsSearchProps,
    categories,
    hasMore,
    widthDesktop,
    articles,
    organization,
    searchValue,
    loadingCategories,
    loadingArticles,
  } = state;

  const { onCreateCategory, setNextPageSystem } = methods;

  const showCategories = organization || !searchValue;

  const showEmptyContent = showCategories
    ? !categories?.length && !loadingCategories
    : !articles.length && !loadingArticles;

  const showLoader =
    !showEmptyContent &&
    ((loadingArticles && !articles.length) || loadingCategories);

  const categoriesList = categories?.map((category) => (
    <KBCategory
      category={category}
      key={category?.id}
      classNameSubCategory={styles.KBArticleViewingSearch__category}
      showFullCategory
      withDrop
      dropProps={categoryDropProps}
      articleDropProps={articleDropProps}
    />
  ));

  const emptyComponent = (
    <DataEmpty
      type={EmptyDataType.NO_DATA_WITH_FILTER}
      className={styles.KBArticleViewingSearch__noData}
    />
  );

  const articlesList = (
    <div className={styles.KBArticleViewingSearch__articles}>
      <InfiniteScroll
        hasMore={hasMore}
        loadMore={setNextPageSystem}
        initialLoad={false}
        useWindow={!widthDesktop}
        loader={<Loader />}
        threshold={20}
      >
        {articles.map((article) => (
          <Fragment key={article.id}>{renderArticleFunction(article)}</Fragment>
        ))}
      </InfiniteScroll>
    </div>
  );

  const searchContent = showCategories ? (
    <KBCategoriesList
      classNameList={styles.KBArticleViewingSearch__categoriesList}
      categories={categoriesList}
      loading={loadingCategories}
    />
  ) : (
    articlesList
  );

  return (
    <Card className={cn(styles.KBArticleViewingSearch, className)}>
      <KBCategoriesHeader
        clearWhenIsInputValue
        valuesProps={valuesProps}
        selectorProps={selectOrganizationsProps}
        actionsProps={actionsOrganizationsProps}
        onCreateCategory={onCreateCategory}
      />
      <div className={styles.KBArticleViewingSearch__inner}>
        <Link
          to={RouterHref.KnowledgeBase}
          className={styles.KBArticleViewingSearch__link}
        >
          <BookIcon />
          <Typography
            variant={TypographyVariants.h4}
            className={styles.KBArticleViewingSearch__startTitle}
          >
            Начало
          </Typography>
        </Link>
        <KBArticlesSearch
          withDropDown={false}
          clearWhenOrganizationSelected
          needInitialOptions={false}
          selectorsProps={selectorsSearchProps}
          actionsProps={actionsSearchProps}
        />
      </div>
      {showEmptyContent && emptyComponent}
      {showLoader && (
        <Loader classNameRoot={styles.KBArticleViewingSearch__loader} />
      )}
      {!showLoader && !showEmptyContent && searchContent}
    </Card>
  );
};
