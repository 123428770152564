import cn from 'clsx';
import { debounce } from 'lodash';
import { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { CheckMarkIcon, CloseIcon } from 'assets/icons';
import {
  Input,
  PopoverButton,
  PopoverContainer,
  PopoverLine,
  Size,
} from 'components';
import { DEFAULT_DEBOUNCE_DELAY } from 'constants/meta';
import {
  setValueTabItem,
  toggleModalPopoverInner,
} from 'features/Tickets/ducks/actions';

import styles from './TicketsPopoverInner.module.scss';

interface TicketsPopoverInnerProps {
  formSubmitHandler: (title: string) => void;
  onChange: (title: string) => void;
  label?: string;
  defaultTitle?: string;
  resetTitle?: string;
  closePopoverWithReset?: (data: boolean) => void;
  value?: string;
}

type CreateType = {
  title: string;
};

export const TicketsPopoverInner: FC<TicketsPopoverInnerProps> = ({
  formSubmitHandler,
  onChange,
  label = 'Название',
  defaultTitle,
  resetTitle = 'Удалить',
  closePopoverWithReset,
  value,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<CreateType>({
    mode: 'onChange',
    defaultValues: {
      title: defaultTitle,
    },
  });

  const dispatch = useDispatch();

  const { title: titleData } = watch();

  const onSaveButton = handleSubmit((data: CreateType) => {
    const { title } = data;

    formSubmitHandler(title);
    reset();

    closePopoverWithReset?.(false);
  });

  const handleChangeTitle = useMemo(
    () =>
      debounce((e) => {
        if (onChange) {
          onChange(String(e.target.value));
        }
      }, DEFAULT_DEBOUNCE_DELAY),
    [onChange]
  );

  const titleInput = register('title', {
    required: true,
    maxLength: {
      value: 100,
      message: 'Название может быть длиннее 100 символов.',
    },
    minLength: {
      value: 3,
      message: 'Название должно содержать не менее трех символов',
    },
    onChange: handleChangeTitle,
  });

  const toggleModal = () => {
    dispatch(setValueTabItem(value));
    dispatch(toggleModalPopoverInner());
  };

  return (
    <PopoverContainer className={styles.popoverContent}>
      <div className={styles.popoverContent__input}>
        <Input
          {...titleInput}
          size={Size.xs}
          label={label}
          error={!!errors.title?.message}
          errorMessage={errors.title?.message}
        />
      </div>
      <PopoverLine className={styles.popoverContent__line} />
      <div className={styles.popoverContent__buttons}>
        <PopoverButton
          className={styles.popoverContent__buttonCheckMark}
          onClick={onSaveButton}
          disabled={!titleData}
          type="submit"
          icon={
            <CheckMarkIcon
              className={cn(styles.popoverContent__iconCheckMark, {
                [styles.popoverContent__iconCheckMark_disabled]: !titleData,
              })}
            />
          }
        >
          Сохранить
        </PopoverButton>
        <PopoverButton
          type="button"
          onClick={toggleModal}
          icon={<CloseIcon className={styles.popoverContent__iconClose} />}
        >
          {resetTitle}
        </PopoverButton>
      </div>
    </PopoverContainer>
  );
};
