import { Reducer } from 'redux';

import { NotificationContent } from 'core/types/notification';
import { ReducerMap } from 'store/types';

import {
  AddCurrentNotificationAction,
  DeleteCurrentNotificationAction,
  DeleteNotificationByIdAction,
  Notifications,
  NotificationsActionsTypes,
  SetActiveNotificationIdAction,
  SetAllNotificationsAction,
  SetNotificationsMyTicketsIdsAction,
  SetNotificationsNewTicketsIdsAction,
} from './types';

export interface NotificationsReducerState {
  allNotifications?: NotificationContent[];
  currentNotifications?: NotificationContent[];
  notificationsMyTicketsIds?: string[];
  notificationsNewTicketsIds?: string[];
  activeNotificationId?: string;
}

const initialState: NotificationsReducerState = {
  allNotifications: [],
  currentNotifications: [],
  notificationsMyTicketsIds: [],
  notificationsNewTicketsIds: [],
};

const coreReducerMap: ReducerMap<
  NotificationsReducerState,
  NotificationsActionsTypes
> = {
  [Notifications.SET_ALL_NOTIFICATIONS]: (state, action) => {
    const { payload } = action as SetAllNotificationsAction;
    return {
      ...state,
      allNotifications: Array.isArray(payload) ? payload : [],
    };
  },
  [Notifications.ADD_CURRENT_NOTIFICATION]: (state, action) => {
    const { payload } = action as AddCurrentNotificationAction;
    const prevNotifications = state?.currentNotifications;

    return {
      ...state,
      currentNotifications: prevNotifications
        ? [payload, ...prevNotifications]
        : [payload],
    };
  },
  [Notifications.DELETE_CURRENT_NOTIFICATION]: (state, action) => {
    const { payload } = action as DeleteCurrentNotificationAction;
    const updatedNotifications = state?.currentNotifications?.filter(
      (notification) => notification.entityId !== payload
    );

    return {
      ...state,
      currentNotifications: updatedNotifications,
    };
  },
  [Notifications.DELETE_NOTIFICATION_BY_ID]: (state, action) => {
    const { payload } = action as DeleteNotificationByIdAction;
    const updatedNotifications = state?.currentNotifications?.filter(
      (notification) => notification.id !== payload
    );

    return {
      ...state,
      currentNotifications: updatedNotifications,
    };
  },
  [Notifications.DELETE_ALL_CURRENT_NOTIFICATIONS]: (state) => {
    return {
      ...state,
      currentNotifications: initialState.currentNotifications,
    };
  },
  [Notifications.SET_NOTIFICATIONS_MY_TICKERS_IDS]: (state, action) => {
    const { payload } = action as SetNotificationsMyTicketsIdsAction;
    return {
      ...state,
      notificationsMyTicketsIds: payload,
    };
  },
  [Notifications.SET_NOTIFICATIONS_NEW_TICKERS_IDS]: (state, action) => {
    const { payload } = action as SetNotificationsNewTicketsIdsAction;
    return {
      ...state,
      notificationsNewTicketsIds: payload,
    };
  },
  [Notifications.SET_ACTIVE_NOTIFICATION_ID]: (state, action) => {
    const { payload } = action as SetActiveNotificationIdAction;
    return {
      ...state,
      activeNotificationId: payload,
    };
  },
};

export const notifications: Reducer<
  NotificationsReducerState,
  NotificationsActionsTypes
> = (state = initialState, action) => {
  const reducer = coreReducerMap[action.type];
  if (!reducer) {
    return state;
  }
  return reducer(state, action);
};
