import { FC } from 'react';

import {
  Accordion,
  Select,
  SelectOption,
  Size,
  Typography,
  TypographyVariants,
  ValueType,
} from 'components';
import { StatusBlock } from 'core/components';

import { JiraStatus, Status } from '../../types';
import { getJiraStatus } from '../../utils';
import { JiraIntegrationsListElement } from '../JiraIntegrationsListElement';

import styles from './JiraIntegrationsStatuses.module.scss';

interface JiraIntegrationsStatusesProps {
  className?: string;
  jiraStatusesOptions: SelectOption<number>[];
  isEditMode: boolean;
  onChangeStatuses: (idStatus: string) => (value: ValueType<number>) => void;
  statusMapOptions: Status[];
  statuses: JiraStatus[];
}

export const JiraIntegrationsStatuses: FC<JiraIntegrationsStatusesProps> = ({
  className,
  jiraStatusesOptions,
  isEditMode,
  onChangeStatuses,
  statusMapOptions,
  statuses,
}) => {
  const statusesConfig = statusMapOptions?.map((status) => {
    return {
      spStatus: status,
      jiraStatus: getJiraStatus(statuses, status),
    };
  });

  const statusesList = statusesConfig?.map(({ spStatus, jiraStatus }) => {
    const { id, name, color, defaultStatus } = spStatus;

    const value = {
      title: jiraStatus?.name || '',
      value: jiraStatus?.idStatus || 0,
    };

    const rightElement = isEditMode ? (
      <Select<number>
        size={Size.xs}
        options={jiraStatusesOptions}
        onChange={onChangeStatuses(id)}
        mobileModalTitle="статус"
        value={value}
        isTooltip={false}
      />
    ) : (
      <Typography
        variant={TypographyVariants.b3}
        className={styles.jiraIntegrationsStatuses__text}
      >
        {jiraStatus?.name || '-'}
      </Typography>
    );

    return (
      <JiraIntegrationsListElement
        key={id}
        leftElement={
          <StatusBlock
            label={name}
            color={color}
            status={defaultStatus}
            className={styles.jiraIntegrationsStatuses__statusBlock}
          />
        }
        rightElement={rightElement}
      />
    );
  });

  return (
    <Accordion title="Статус" defaultHidden className={className}>
      <div className={styles.jiraIntegrationsStatuses__content}>
        {statusesList}
      </div>
    </Accordion>
  );
};
