import { Reducer } from 'redux';

import { DEFAULT_PAGINATION_PARAMS } from 'constants/meta';
import { Access, AccessesFilter } from 'features/Accesses/types';
import { ReducerMap } from 'store/types';
import { Pagination } from 'types/models/meta';

import {
  AccessesActionsTypes,
  AccessesCategories,
  FetchAccessesSuccessAction,
  SetAccessesFilterAction,
  SetCurrentAccessesPageAction,
  SetSizePageAction,
  SetSortAccessesAction,
} from './types';

export interface AccessesReducerState {
  accesses?: Access[];
  loading?: boolean;
  pagination?: Pagination;
  sort?: string;
  accessesFilter?: AccessesFilter;
}

const initialState: AccessesReducerState = {
  accesses: [],
  loading: false,
  pagination: DEFAULT_PAGINATION_PARAMS,
  sort: 'date_create_DESC',
  accessesFilter: {},
};

const accessesReducerMap: ReducerMap<
  AccessesReducerState,
  AccessesActionsTypes
> = {
  [AccessesCategories.ACCESSES_LOADING_SHOW]: (state) => {
    return {
      ...state,
      loading: true,
    };
  },
  [AccessesCategories.ACCESSES_LOADING_HIDE]: (state) => {
    return {
      ...state,
      loading: false,
    };
  },
  [AccessesCategories.FETCH_ACCESSES_SUCCESS]: (state, action) => {
    const { payload } = action as FetchAccessesSuccessAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      accesses: content,
      pagination: {
        ...state?.pagination,
        pageNum: page,
        totalElements,
      },
    };
  },
  [AccessesCategories.RESET_ACCESSES_STATE]: () => {
    return {
      ...initialState,
    };
  },
  [AccessesCategories.SET_ACCESSES_FILTER]: (state, action) => {
    const { payload } = action as SetAccessesFilterAction;
    return {
      ...state,
      accessesFilter: payload,
    };
  },
  [AccessesCategories.SET_CURRENT_ACCESSES_PAGE]: (state, action) => {
    const { payload } = action as SetCurrentAccessesPageAction;
    return {
      ...state,
      pagination: {
        ...state?.pagination,
        pageNum: payload,
      },
    };
  },
  [AccessesCategories.SET_SIZE_PAGE_ACCESSES]: (state, action) => {
    const { payload } = action as SetSizePageAction;
    return {
      ...state,
      pagination: {
        ...state?.pagination,
        pageSize: payload,
      },
    };
  },
  [AccessesCategories.SET_SORT_ACCESSES]: (state, action) => {
    const { payload } = action as SetSortAccessesAction;
    return {
      ...state,
      sort: payload,
    };
  },
};

export const accesses: Reducer<AccessesReducerState, AccessesActionsTypes> = (
  state = initialState,
  action
) => {
  const reducer = accessesReducerMap[action.type];
  if (!reducer) {
    return state;
  }
  return reducer(state, action);
};
