import cn from 'clsx';
import { FC, Fragment, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { Clock12, LabelIcon, WarningIcon } from 'assets/icons';
import { Typography, TypographyVariants } from 'components';
import { SLAProgressBar } from 'features/SLA';
import { RouterHref } from 'routes/routerHref';
import { checkPathnameWithTrailingSlash } from 'utils';

import { TicketsStatistic } from '../../types';

import styles from './TicketsDashboard.module.scss';

interface TicketsDashboardProps {
  className?: string;
  classNameInfoBlock?: string;
  classNameVl?: string;
  classNameInfoProgressBars?: string;
  ticketsStatistic?: TicketsStatistic;
  reportClients?: boolean;
}

export const TicketsDashboard: FC<TicketsDashboardProps> = ({
  className,
  classNameInfoBlock,
  classNameInfoProgressBars,
  classNameVl,
  ticketsStatistic,
  reportClients,
}) => {
  const { pathname } = useLocation();

  const infoBlocksDefaultConfig = useMemo(
    () => [
      {
        title: 'Активных',
        value: ticketsStatistic?.countActive ?? 0,
        icon: (
          <LabelIcon
            className={styles.ticketsDashboard__infoBlockIcon_active}
          />
        ),
        bgClassName: styles.ticketsDashboard__infoBlock_active,
      },
      {
        title: 'Просрочено',
        value: ticketsStatistic?.countExpired ?? 0,
        icon: (
          <LabelIcon
            className={styles.ticketsDashboard__infoBlockIcon_expired}
          />
        ),
        bgClassName: styles.ticketsDashboard__infoBlock_expired,
      },
      {
        title: 'SLA',
        value: (
          <>
            {ticketsStatistic?.slaPercent ?? 0}
            <Typography
              variant={TypographyVariants.h1}
              className={styles.ticketsDashboard__percent}
            >
              %
            </Typography>
          </>
        ),
        icon: (
          <Clock12 className={styles.ticketsDashboard__infoBlockIcon_sla} />
        ),
        bgClassName: styles.ticketsDashboard__infoBlock_sla,
      },
    ],
    [ticketsStatistic]
  );

  const infoBlocksSecondaryConfig = useMemo(
    () => [
      {
        title: 'Всего',
        value: ticketsStatistic?.total ?? 0,
        icon: (
          <LabelIcon
            className={styles.ticketsDashboard__infoBlockIcon_active}
          />
        ),
        bgClassName: styles.ticketsDashboard__infoBlock_active,
      },
      {
        title: 'Решено',
        value: ticketsStatistic?.closed ?? 0,
        icon: (
          <LabelIcon
            className={styles.ticketsDashboard__infoBlockIcon_solved}
          />
        ),
        bgClassName: styles.ticketsDashboard__infoBlock_solved,
      },
      {
        title: 'Просрочено',
        value: ticketsStatistic?.countExpired ?? 0,
        icon: (
          <LabelIcon
            className={styles.ticketsDashboard__infoBlockIcon_expired}
          />
        ),
        bgClassName: styles.ticketsDashboard__infoBlock_expired,
      },
    ],
    [ticketsStatistic]
  );

  const isDesktopPage = checkPathnameWithTrailingSlash(
    pathname,
    RouterHref.DesktopCurrentState
  );
  const isDesktopHistoryPage = checkPathnameWithTrailingSlash(
    pathname,
    RouterHref.DesktopHistory
  );

  const infoBlocksConfig = isDesktopPage
    ? infoBlocksDefaultConfig
    : infoBlocksSecondaryConfig;

  const allCount = useMemo(
    () =>
      ticketsStatistic?.types?.reduce(
        (accumulator, type) => accumulator + Number(type.value),
        0
      ) || 0,
    [ticketsStatistic?.types]
  );

  const getWarningText = () => {
    if (reportClients) {
      return 'Выберите систему для отображения показателей';
    }
    if (isDesktopPage) {
      return 'Выберите рабочую группу или организацию';
    }
    if (isDesktopHistoryPage) {
      return 'Выберите организацию или систему';
    }
    return 'Выберите группу исполнителей';
  };

  const progressBars = ticketsStatistic?.types
    ?.filter((type) => type.value)
    .sort((a, b) => {
      const valueDifference = Number(b.value) - Number(a.value);
      if (valueDifference > 0 || valueDifference < 0) {
        return valueDifference;
      }
      const nameA = a.key.toLowerCase();
      const nameB = b.key.toLowerCase();

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    })
    .map(({ key, value }) => {
      const expired = ((allCount - Number(value)) / allCount) * 100;

      return (
        <div className={styles.ticketsDashboard__slaBlock} key={key}>
          <Typography
            variant={TypographyVariants.o}
            className={styles.ticketsDashboard__slaTitle}
          >
            {key}
          </Typography>
          <div className={styles.ticketsDashboard__slaProgressBarWrapper}>
            <Typography
              variant={TypographyVariants.h2}
              className={styles.ticketsDashboard__slaProgressBarValue}
            >
              {value || 0}
            </Typography>
            <SLAProgressBar
              expirePercents={expired || 100}
              className={styles.ticketsDashboard__slaProgressBar}
              classNameLoaded={styles.ticketsDashboard__slaProgressBar_loaded}
            />
          </div>
        </div>
      );
    });

  const infoBlocks = infoBlocksConfig.map(
    ({ title, value, icon, bgClassName }) => (
      <Fragment key={`${title} + ${value}`}>
        <div
          className={cn(
            styles.ticketsDashboard__infoBlockWrapper,
            classNameInfoBlock
          )}
        >
          <div className={cn(styles.ticketsDashboard__infoBlock, bgClassName)}>
            {icon}
          </div>
          <Typography
            variant={TypographyVariants.h5}
            className={styles.ticketsDashboard__infoBlockTitle}
          >
            {title}
          </Typography>
          <Typography
            variant={TypographyVariants.d2}
            component="div"
            className={styles.ticketsDashboard__infoBlockSubTitle}
          >
            {value}
          </Typography>
        </div>
        <div className={cn(styles.ticketsDashboard__infoVl, classNameVl)} />
      </Fragment>
    )
  );

  return (
    <div className={cn(styles.ticketsDashboard, className)}>
      <div className={styles.ticketsDashboard__titleContent}>
        <Typography
          variant={TypographyVariants.h2}
          className={styles.ticketsDashboard__title}
        >
          Тикеты
        </Typography>
        {!ticketsStatistic && (
          <Typography
            variant={TypographyVariants.b3}
            component="div"
            className={styles.ticketsDashboard__warning}
          >
            <WarningIcon className={styles.ticketsDashboard__warningIcon} />
            {getWarningText()}
          </Typography>
        )}
      </div>
      <div className={styles.ticketsDashboard__infoWrapper}>
        {infoBlocks}
        <div
          className={cn(
            styles.ticketsDashboard__progressBarWrapper,
            classNameInfoProgressBars
          )}
        >
          {progressBars}
        </div>
      </div>
    </div>
  );
};
