import cn from 'clsx';
import { FC, MouseEvent, useState } from 'react';

import { AddLineIcon } from 'assets/icons';
import {
  IconButton,
  Popover,
  Typography,
  TypographyVariants,
} from 'components';
import { CategoryArticle } from 'core/types/knowledgeBase';
import { useCanCreateArticleOrCategory } from 'features/KnowledgeBase/hooks';

import { KBCreateCategory } from '../KBCategoryDrop/components';
import {
  KBOrganizationSelect,
  SelectActionsProps,
  SelectorProps,
  ValuesProps,
} from '../KBOrganizationSelect';

import styles from './KBCategoriesHeader.module.scss';

interface KBCategoriesHeaderProps {
  clearWhenIsInputValue?: boolean;
  valuesProps: ValuesProps;
  selectorProps: SelectorProps;
  actionsProps: SelectActionsProps;
  onCreateCategory: (data: Partial<CategoryArticle>) => void;
}

export const KBCategoriesHeader: FC<KBCategoriesHeaderProps> = ({
  clearWhenIsInputValue = false,
  onCreateCategory,
  ...selectProps
}) => {
  const [isDropOpen, setIsDropOpen] = useState(false);
  const showCreateCategory = useCanCreateArticleOrCategory();
  const togglePopover = (event?: MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault();
    setIsDropOpen((prev) => !prev);
  };

  const dropContent = (
    <KBCreateCategory
      onClick={onCreateCategory}
      togglePopover={togglePopover}
    />
  );

  return (
    <form className={styles.KBCategoriesHeader}>
      <div
        className={cn(styles.KBCategoriesHeader__header, {
          [styles.KBCategoriesHeader__header_withDrop]: showCreateCategory,
        })}
      >
        <Typography variant={TypographyVariants.h4}>Категории</Typography>
        <KBOrganizationSelect
          clearWhenIsInputValue={clearWhenIsInputValue}
          className={styles.KBCategoriesHeader__select}
          {...selectProps}
        />
      </div>
      {showCreateCategory && (
        <Popover
          content={dropContent}
          isOpen={isDropOpen}
          togglePopover={togglePopover}
          withOutsideClick={false}
          positions={['left', 'bottom', 'top', 'right']}
          popoverClassName={styles.KBCategoriesHeader__popover}
        >
          <IconButton
            icon={<AddLineIcon />}
            onClick={togglePopover}
            appearance="flat"
            className={styles.KBCategoriesHeader__popover_adaptive}
          />
        </Popover>
      )}
    </form>
  );
};
