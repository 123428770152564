import { Contract, EnvironmentsWithStatus } from 'core/types';
import { ContractIdSystemIdArgs } from 'core/types/contract';
import { SLAType } from 'core/types/sla';
import { System } from 'core/types/system';

export type ContractSystem = {
  contractId: string;
  systemId?: string;
  slaId?: string;
  id?: string;
};

export enum SLAChoiceType {
  CREATE_NEW = 'CREATE_NEW',
  SELECT_FROM_LIST = 'SELECT_FROM_LIST',
}

export type SLASettingsPriority = {
  name: string;
  checked: boolean;
};

export type UpdateEnvironmentSettings = EnvironmentsWithStatus &
  ContractIdSystemIdArgs;

export interface CreateSystemDataAdd
  extends Pick<
    SLAType,
    | 'title'
    | 'workingHoursFrom'
    | 'workingHoursTo'
    | 'autoClosableTickets'
    | 'closeAfterHours'
    | 'expireReactionTimeInDecisionTime'
  > {
  systemId?: string;
  createType?: SLAChoiceType;
}

export type SystemsTransfer = {
  contractId?: string;
  transferStatus?: ProgressStatus;
  systems?: System[];
};

export type ProlongationContractPayload = {
  contractId: string;
  contract: Contract;
};

export enum ProgressStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}
