import { Contract } from 'core/types';
import { RootState } from 'store/rootReducer';

export const getContracts = (state: RootState): Contract[] | undefined =>
  state.contracts.contracts;
export const getLoading = (state: RootState): boolean | undefined =>
  state.contracts.loading;
export const getLoadingContract = (state: RootState): boolean | undefined =>
  state.contracts.loadingContract;
export const getCurrentContract = (state: RootState): Contract | undefined =>
  state.contracts.contract;
export const getCurrentContractId = (state: RootState): string | undefined =>
  state.contracts.contractId;
export const getPropsContracts = (state: RootState) => ({
  pageNum: state.contracts.pagination?.pageNum,
  pageSize: state.contracts.pagination?.pageSize,
  sortContracts: state.contracts?.sort,
  loadingContracts: getLoading(state),
  totalElements: state.contracts.pagination?.totalElements,
});
export const getContractFilter = (state: RootState) =>
  state.contracts.contractsFilter || {};
export const getSystemsForContract = (state: RootState) =>
  state.contracts.systemsForContract;
export const getSLAContracts = (state: RootState) => state.contracts.sla;
