export const getDay = (date: Date) => {
  const day = date.getDay() - 1;
  return day === -1 ? 6 : day;
};

export const getDates = (selectedDate: Date) => {
  const selectedYear = selectedDate.getFullYear();
  const selectedMonth = selectedDate.getMonth();

  const currentMonthLastDate = new Date(
    selectedYear,
    selectedMonth + 1,
    0
  ).getDate();
  const currentMonthDates: Date[] = [];

  for (let i = 1; i <= currentMonthLastDate; i++) {
    currentMonthDates.push(new Date(selectedYear, selectedMonth, i));
  }

  const prevMonth = selectedMonth - 1;
  const prevMonthLastDate = new Date(selectedYear, selectedMonth, 0).getDate();
  const prevMonthLastDay = getDay(
    new Date(selectedYear, prevMonth, prevMonthLastDate)
  );
  const prevMonthDates: Date[] = [];

  for (let i = prevMonthLastDay, j = 0; i >= 0; i--, j++) {
    prevMonthDates.push(
      new Date(selectedYear, prevMonth, prevMonthLastDate - i)
    );
  }

  const nextMonth = selectedMonth + 1;
  const nextMonthFirstDay = getDay(new Date(selectedYear, nextMonth, 1));
  const nextMonthDates: Date[] = [];

  for (let i = nextMonthFirstDay, j = 1; i <= 6; i++, j++) {
    nextMonthDates.push(new Date(selectedYear, nextMonth, j));
  }

  const dates = [...prevMonthDates, ...currentMonthDates, ...nextMonthDates];

  return [dates, prevMonthDates, currentMonthDates, nextMonthDates];
};
