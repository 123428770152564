import cn from 'clsx';
import { FC } from 'react';

import { ConditionBlock, PopoverContainer, TableContainer } from 'components';
import { DeleteButton } from 'core/components';
import { TableRolesTypes } from 'core/types/roles';
import { useRolesTableContainer } from 'features/Roles/hooks';

import { PAGINATION_RANGE_MAP, TABLE_TITLES } from '../../constants';
import { AddRolesInGroupToUser } from '../AddRolesInGroupToUser';
import { AddRolesInSystemToUser } from '../AddRolesInSystemToUser';
import { RolesCreate } from '../RolesCreate';
import { RolesFromGroupsTable } from '../RolesFromGroupsTable';
import { RolesFromUsersTable } from '../RolesFromUsersTable';
import { RolesFullTable } from '../RolesFullTable';

import styles from './RolesTableContainer.module.scss';

interface RolesTableContainerProps {
  tableType: TableRolesTypes;
  className?: string;
}

export const RolesTableContainer: FC<RolesTableContainerProps> = ({
  tableType,
  className,
}) => {
  const { methods, state } = useRolesTableContainer(tableType);

  const {
    toggleIsOpenChangeModals,
    handleEditClick,
    toggleDeleteModal,
    toggleFilterRow,
    toggleModal,
    handleSort,
    getCreateFunction,
    handleChangePage,
    handleChangePageSize,
    handleDelete,
    handleShowModalDelete,
    onRowClick,
  } = methods;

  const {
    pageNum,
    pageSize,
    sortRoles,
    totalElements,
    roles,
    role,
    isAccessToCreateRole,
    isAccessToDeleteRole,
    showFilterRow,
    isModal,
    showDeleteModal,
    changeModals,
    disableCreateBtn,
    isFilterActive,
    searchDisabled,
    createTitle,
    icon,
    getAdditionalComponentTitle,
    currentGroupTitle,
    currentRoleId,
    loadingRoles,
  } = state;

  const getTableComponent: Record<TableRolesTypes, () => JSX.Element | null> = {
    [TableRolesTypes.FULL]: () => (
      <RolesFullTable
        roles={roles}
        handleSort={handleSort}
        sort={sortRoles}
        handleRowClick={onRowClick}
        activeRowId={currentRoleId}
        showFilterRow={showFilterRow}
        isFilterActive={isFilterActive}
        handleClickRoleEdit={handleEditClick}
      />
    ),
    [TableRolesTypes.FROM_USERS]: () => (
      <RolesFromUsersTable
        roles={roles}
        handleSort={handleSort}
        sort={sortRoles}
        showFilterRow={showFilterRow}
        isFilterActive={isFilterActive}
      />
    ),
    [TableRolesTypes.FROM_GROUPS]: () => (
      <RolesFromGroupsTable
        roles={roles}
        showFilterRow={showFilterRow}
        handleSort={handleSort}
        sort={sortRoles}
        isFilterActive={isFilterActive}
      />
    ),
    [TableRolesTypes.ADD_MODAL]: () => null,
  };

  const getModalComponent = {
    [TableRolesTypes.FULL]: () =>
      changeModals.isOpenModal && (
        <RolesCreate
          isModal={changeModals.isOpenModal}
          toggleModal={toggleIsOpenChangeModals}
          isEditMode={changeModals.isEditMode}
        />
      ),
    [TableRolesTypes.FROM_USERS]: () =>
      isModal && (
        <AddRolesInSystemToUser isModal={isModal} toggleModal={toggleModal} />
      ),
    [TableRolesTypes.FROM_GROUPS]: () =>
      isModal && (
        <AddRolesInGroupToUser isModal={isModal} toggleModal={toggleModal} />
      ),
  };

  const getContent = () => {
    return getTableComponent[tableType]();
  };

  const dropContent =
    tableType === TableRolesTypes.FULL && isAccessToDeleteRole ? (
      <PopoverContainer>
        <DeleteButton onClick={handleShowModalDelete} disabled={!role} />
      </PopoverContainer>
    ) : undefined;

  const additionalHeaderComponent =
    tableType === TableRolesTypes.FROM_GROUPS && currentGroupTitle ? (
      <ConditionBlock text={getAdditionalComponentTitle} />
    ) : undefined;

  return (
    <TableContainer
      classNameRoot={cn(
        styles.rolesTableContainer,
        {
          [styles.rolesTableContainer_full]: tableType === TableRolesTypes.FULL,
          [styles.rolesTableContainer_fromGroups]:
            tableType === TableRolesTypes.FROM_GROUPS,
          [styles.rolesTableContainer_fromUsers]:
            tableType === TableRolesTypes.FROM_USERS,
        },
        className
      )}
      headerProps={{
        title: TABLE_TITLES[tableType],
        createHandler: getCreateFunction(),
        createTitle,
        dropContent,
        additionalComponent: additionalHeaderComponent,
        createDisabled:
          disableCreateBtn || (TableRolesTypes.FULL && !isAccessToCreateRole),
        icon,
        searchDisabled,
        isSearchOpen: showFilterRow,
        toggleSearch: toggleFilterRow,
        loading: loadingRoles,
      }}
      contentTable={
        <>
          {getContent()}
          {getModalComponent[tableType as keyof typeof getModalComponent]()}
        </>
      }
      countRecordsProps={{
        records: totalElements,
      }}
      paginationProps={{
        pageNum,
        elementsCount: totalElements,
        pageSize,
        marginPagesDisplayed: PAGINATION_RANGE_MAP[tableType],
        handleChangePage,
        handleChangePageSize,
        disabled: loadingRoles,
        entityName: `ROLES_${tableType}`,
      }}
      approveOrCancelProps={{
        onApprove: handleDelete,
        isModal: showDeleteModal,
        toggleModal: toggleDeleteModal,
        approveTitle: 'Удалить',
        approveBtnProps: {
          appearance: 'outline',
          className: styles.rolesTableContainer__iconDelete,
        },
        cancelTitle: 'Отмена',
        text: `Вы уверены, что хотите удалить роль ${role?.name}?`,
      }}
    />
  );
};
