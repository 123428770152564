import cn from 'clsx';
import { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SearchThinIcon } from 'assets/icons';
import { Loader, Typography, TypographyVariants } from 'components';

import {
  fetchSimilarArticleRequest,
  fetchSimilarTicketRequest,
} from '../../ducks/actions';
import { getArticles, getLoading, getTickets } from '../../ducks/selectors';
import { SolutionType, Tab } from '../../types';
import { SimilarSolutionsListElement } from '../SimilarSolutionsListElement';

import styles from './SimilarSolutionsList.module.scss';

interface SimilarSolutionsListProps {
  tabs: Tab[];
  activeTab: Tab;
  keyWord?: string;
  isOnlyTitles?: boolean;
  onTabChangeHandler(tab: Tab): void;
  toggleIsDetailModal(): void;
}

export const SimilarSolutionsList: FC<SimilarSolutionsListProps> = ({
  tabs,
  activeTab,
  keyWord,
  isOnlyTitles,
  onTabChangeHandler,
  toggleIsDetailModal,
}) => {
  const similarTickets = useSelector(getTickets);
  const similarArticles = useSelector(getArticles);
  const loading = useSelector(getLoading);

  const dispatch = useDispatch();

  const isTicketsTab = activeTab === 'Тикеты';

  const isKeyWord = keyWord?.length && keyWord.length >= 3;

  const startContent = (
    <div className={styles.similarSolutionsList__startContent}>
      <SearchThinIcon
        className={styles.similarSolutionsList__startContentIcon}
      />
      <Typography
        variant={TypographyVariants.b2}
        className={styles.similarSolutionsList__startContentText}
      >
        Находите решения по закрытым тикетам <br /> и статьям из базы знаний
      </Typography>
    </div>
  );

  const emptyDataContent = (
    <div className={styles.similarSolutionsList__startContent}>
      <SearchThinIcon
        className={styles.similarSolutionsList__startContentIcon}
      />
      <Typography
        variant={TypographyVariants.h3}
        className={styles.similarSolutionsList__emptyTitle}
      >
        Ничего не найдено
      </Typography>
      <Typography
        variant={TypographyVariants.b3}
        className={styles.similarSolutionsList__startContentText}
      >
        Попробуйте изменить запрос
      </Typography>
    </div>
  );

  const getSolutionsLength = () => {
    if (isTicketsTab) {
      return similarTickets?.length;
    }
    return similarArticles?.length;
  };

  const fetchSimilarSolution = useCallback(
    (id: string) => {
      toggleIsDetailModal();
      dispatch(
        isTicketsTab
          ? fetchSimilarTicketRequest(id)
          : fetchSimilarArticleRequest(id)
      );
    },
    [toggleIsDetailModal, isTicketsTab]
  );

  const getSimilarSolutionsList = () => {
    if (isTicketsTab) {
      return similarTickets?.map((ticket) => (
        <SimilarSolutionsListElement
          key={ticket.id}
          type={SolutionType.TICKET}
          title={ticket.theme}
          subTitle={`${ticket.systemName} - ${ticket.systemVersion}`}
          description={ticket.description}
          className={styles.similarSolutionsList__element}
          keyWord={keyWord}
          isOnlyTitles={isOnlyTitles}
          fetchSimilarSolution={() =>
            ticket.id && fetchSimilarSolution(ticket.id)
          }
        />
      ));
    }
    return similarArticles?.map((article) => (
      <SimilarSolutionsListElement
        key={article.id}
        type={SolutionType.ARTICLE}
        title={article.title}
        subTitle={
          article?.systemTitle && article?.systemVersion
            ? `${article.systemTitle} - ${article.systemVersion}`
            : null
        }
        description={article.text}
        className={styles.similarSolutionsList__element}
        keyWord={keyWord}
        isOnlyTitles={isOnlyTitles}
        fetchSimilarSolution={() =>
          article?.id && fetchSimilarSolution(article.id)
        }
      />
    ));
  };

  const content = (
    <div className={styles.similarSolutionsList__content}>
      <div className={styles.similarSolutionsList__header}>
        {tabs.map((title) => {
          const isActiveTab = title === activeTab;

          return (
            <button
              key={title}
              onClick={() => onTabChangeHandler(title)}
              className={cn(styles.similarSolutionsList__tab, {
                [styles.similarSolutionsList__tab_active]: isActiveTab,
              })}
            >
              {title}
            </button>
          );
        })}
      </div>
      <div
        className={cn(styles.similarSolutionsList__listWrapper, {
          [styles.similarSolutionsList__listWrapper_loading]: loading,
        })}
      >
        {!loading && !getSolutionsLength() && !isKeyWord && startContent}
        {loading && <Loader />}
        {!loading && !!getSolutionsLength() && getSimilarSolutionsList()}
        {!loading && !getSolutionsLength() && !!isKeyWord && emptyDataContent}
      </div>
    </div>
  );

  return <div className={styles.similarSolutionsList}>{content}</div>;
};
