export const getPreparedIdsForTable = (value: string): string => {
  const idsMap: Record<string, string> = {
    specialistId: 'specialistIds',
    systemId: 'systemIds',
    status: 'statuses',
    priority: 'priorities',
    clientId: 'clientIds',
    tags: 'tagName',
    source: 'sources',
  };

  return idsMap[value] ?? value;
};
