import { AdminsLayout } from 'core/layouts';
import { Roles } from 'features/Roles';

const AdminRoles = () => {
  return (
    <AdminsLayout>
      <Roles />
    </AdminsLayout>
  );
};

export default AdminRoles;
