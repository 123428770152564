import { debounce } from 'lodash';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { getMultiOptionValues, getValueFromValueType } from 'components';
import { DEFAULT_DEBOUNCE_DELAY } from 'constants/meta';
import { getAttributesForFilter } from 'features/Rules/ducks/selectors';

import { INITIAL_RULES_FILTER } from '../constants';
import {
  fetchRulesRequest,
  setCurrentRulesPage,
  setRulesFilter,
} from '../ducks';
import { RulesFilterData } from '../types';

export const useRulesFilter = () => {
  const dispatch = useDispatch();

  const attributesForFilter = useSelector(getAttributesForFilter);

  const organizationOptions = attributesForFilter.organizations;
  const systemsOptions = attributesForFilter.systems;
  const ticketTypesOptions = attributesForFilter.ticketTypes;
  const ticketPrioritiesOptions = attributesForFilter.ticketPriorities;
  const environmentsOptions = attributesForFilter.environments;
  const clientsOptions = attributesForFilter.clients;

  const { register, handleSubmit, control, reset, watch } =
    useForm<RulesFilterData>({
      mode: 'onChange',
    });

  const filterValues = watch();

  const resetFilter = () => {
    dispatch(setRulesFilter(INITIAL_RULES_FILTER));
    dispatch(setCurrentRulesPage(0));
    dispatch(fetchRulesRequest());
    reset();
  };

  const formSubmitHandler = handleSubmit((data) => {
    const {
      title,
      organizations,
      systems,
      ticketTypes,
      ticketPriorities,
      environments,
      clients,
    } = data;

    const isActive = getValueFromValueType(data.isActive);
    const organizationsList = getMultiOptionValues(organizations) || [];
    const systemsList = getMultiOptionValues(systems) || [];
    const ticketTypesList = getMultiOptionValues(ticketTypes) || [];
    const ticketPrioritiesList = getMultiOptionValues(ticketPriorities) || [];
    const environmentsList = getMultiOptionValues(environments) || [];
    const clientsList = getMultiOptionValues(clients) || [];

    const preparedData = {
      title: title || undefined,
      isActive,
      organizations: organizationsList,
      systems: systemsList,
      ticketTypes: ticketTypesList,
      ticketPriorities: ticketPrioritiesList,
      environments: environmentsList,
      clients: clientsList,
    };

    dispatch(setRulesFilter(preparedData));
    dispatch(setCurrentRulesPage(0));
    dispatch(fetchRulesRequest());
  });

  const formSubmitHandlerDebounced = useMemo(
    () => debounce(formSubmitHandler, DEFAULT_DEBOUNCE_DELAY),
    []
  );

  const titleToInput = register('title', {
    onChange: formSubmitHandlerDebounced,
  });

  return {
    state: {
      filterValues,
      titleToInput,
      organizationOptions,
      systemsOptions,
      ticketTypesOptions,
      ticketPrioritiesOptions,
      environmentsOptions,
      clientsOptions,
    },
    methods: {
      control,
      resetFilter,
      formSubmitHandler,
    },
  };
};
