import { FC, MouseEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { CloseIcon } from 'assets/icons';
import { DeleteApproveOrCancel } from 'core/modals';
import { RouterHref } from 'routes/routerHref';
import { LocationState } from 'routes/types';

import { Tag } from '../../../../types';

import styles from './TicketTag.module.scss';

interface TicketTagProps {
  tag: Tag;
  onDeleteTag?: (id: string) => void;
}

export const TicketTag: FC<TicketTagProps> = ({ tag, onDeleteTag }) => {
  const { push } = useHistory<LocationState>();

  const [isModal, setIsModal] = useState(false);

  const toggleModal = () => {
    setIsModal((prevState) => !prevState);
  };

  const { id, name } = tag;

  const onDeleteTagFromTicket = () => {
    if (onDeleteTag) {
      onDeleteTag(id);
    }
  };

  const handleClickTag = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    push(RouterHref.TicketsAll, {
      tagName: name,
    });
  };

  const onClickDeleteHandler = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    toggleModal();
  };

  const deleteButton = onDeleteTag && (
    <button onClick={onClickDeleteHandler} className={styles.ticketTag__close}>
      <CloseIcon className={styles.ticketTag__closeIcon} />
    </button>
  );

  return (
    <>
      <div
        className={styles.ticketTag}
        tabIndex={0}
        role="button"
        onKeyDown={undefined}
        onClick={handleClickTag}
      >
        <span className={styles.ticketTag__title}>{name}</span>
        {deleteButton}
      </div>
      <DeleteApproveOrCancel
        isModal={isModal}
        toggleModal={toggleModal}
        entityTitle="тег"
        onApprove={onDeleteTagFromTicket}
        withHideBodyScroll={false}
      />
    </>
  );
};
