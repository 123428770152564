import { NotificationLink } from 'components';
import { System } from 'core/types/system';

export const getDataTable = (
  systems: System[],
  isAccessToViewingCompany: boolean
) =>
  systems?.map((system) => {
    const { title, id, organization } = system;
    return {
      id,
      system: { text: title },
      organization: isAccessToViewingCompany ? (
        <NotificationLink
          to={`/admin/organizations/${organization?.id}`}
          title={organization?.title}
        />
      ) : (
        organization?.title
      ),
    };
  }) || [];
