import { Redirect } from 'react-router-dom';

import { RouterHref } from 'routes/routerHref';
import { RouterConfig } from 'routes/types';
import { getProfileTitle } from 'routes/utils';

import ProfileNews from './components/ProfileNews';
import ProfileNotifications from './components/ProfileNotifications';
import ProfileSettingsPage from './pages/ProfileSettings';
import ProfileTelegramPage from './pages/ProfileTelegram';

export const RedirectComponent = () => {
  return <Redirect to={RouterHref.ProfileNotifications} />;
};

export const PROFILE_TITLE = 'Уведомления';

export const profileRoutingConfig: RouterConfig[] = [
  {
    href: RouterHref.Profile,
    component: RedirectComponent,
    getPageTitle: getProfileTitle,
    withPermission: false,
    breadCrumbTitle: 'Профиль',
  },
  {
    href: RouterHref.ProfileNotifications,
    component: ProfileNotifications,
    getPageTitle: getProfileTitle,
    exact: false,
    withPermission: false,
    breadCrumbTitle: PROFILE_TITLE,
  },
  {
    href: RouterHref.ProfileNews,
    component: ProfileNews,
    getPageTitle: getProfileTitle,
    exact: false,
    withPermission: false,
    breadCrumbTitle: 'Новости',
  },
  {
    href: RouterHref.ProfileSettings,
    component: ProfileSettingsPage,
    getPageTitle: getProfileTitle,
    exact: false,
    withPermission: false,
    breadCrumbTitle: 'Настройки',
  },
  {
    href: RouterHref.ProfileTelegram,
    component: ProfileTelegramPage,
    getPageTitle: getProfileTitle,
    exact: false,
    withPermission: false,
    breadCrumbTitle: 'Чат бот',
  },
];
