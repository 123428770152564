import { TableColumns } from 'components';

export const ACCESS_FROM_RESP_FIELDS: TableColumns = [
  {
    title: 'Название',
    sortField: 'title',
    fieldName: 'title',
  },
  { title: 'Название в базе', fieldName: 'nameInDatabase' },
];

export const MODAL_TITLE = 'Изменить категории доступа';

export const INITIAL_ACCESSES_FILTER = {
  title: '',
  nameInDatabase: '',
};
