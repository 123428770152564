import { AttachmentsFiles } from 'types/models/attachment';

import { getIsImage } from './getIsImage';

export const getAttachmentFilesComment = (
  files: AttachmentsFiles[] | undefined
) => {
  if (!files?.length) {
    return { imageFiles: [], documentFiles: [] };
  }

  const imageFiles = files.filter((item) => getIsImage(item.path));

  const documentFiles = files.filter((item) => !getIsImage(item.path));

  return { imageFiles, documentFiles };
};
