import { isNil } from 'lodash';
import { createSelector } from 'reselect';

import { SelectOption } from 'components';
import { Tag } from 'features/Ticket/types';
import { RootState } from 'store/rootReducer';

export const getTags = (state: RootState) => state.tags.tags;
export const getTagsSelectList = createSelector<
  RootState,
  Tag[] | undefined,
  SelectOption[]
>([getTags], (tags): SelectOption[] => {
  if (tags) {
    return tags.map(
      (tag: Tag): SelectOption => ({
        title: tag.name || '',
        value: tag.id,
      })
    );
  }
  return [];
});
export const getPropsTags = (state: RootState) => {
  const totalElements = state.tags.pagination?.totalElements;
  const size = state.tags.pagination?.pageSize;
  return {
    pageNum: state.tags.pagination?.pageNum,
    pageSize: state.tags.pagination?.pageSize,
    totalElementsTags: totalElements,
    loadingTags: state.tags.loading,
    totalPagesTags:
      !isNil(totalElements) && !isNil(size)
        ? Math.ceil(totalElements / size)
        : 0,
  };
};
export const getFilterTags = (state: RootState) => state.tags.filter;
export const getIsExistTag = (state: RootState) => state.tags.isExistTag;
