import cn from 'clsx';
import { FC } from 'react';
import ReactFlow, { Background } from 'reactflow';

import { EyeIcon, LinkCrossedIcon, Pen3Icon, PlusIcon } from 'assets/icons';
import {
  Button,
  EllipseIconButton,
  IconButton,
  Select,
  Size,
} from 'components';
import { TicketType } from 'core/types';
import { useBuilder } from 'features/StatusesBuilder/hooks';
import { getCustomEdges, getCustomNodes } from 'features/StatusesBuilder/utils';

import { nodeTypes } from '../../config';
import { StatusDetail } from '../StatusDetail';

import styles from './Builder.module.scss';
import './styles.scss';

interface BuilderProps {
  isSmallWindowWidth: boolean | null;
}

export const Builder: FC<BuilderProps> = ({ isSmallWindowWidth }) => {
  const { state, methods } = useBuilder({ isSmallWindowWidth });

  const {
    theme,
    isDetailStatusOpen,
    selectedNode,
    selectedEdge,
    nodes,
    edges,
    isChanges,
    systemsOptions,
    systemOption,
    systemId,
    ticketTypeOption,
    isCreateDisabled,
    disabledSave,
    ticketTypes,
  } = state;

  const {
    onNodesChange,
    onEdgesChange,
    onConnect,
    onEdgeUpdate,
    onEdgeDelete,
    onCreateNode,
    onCreateEdge,
    toggleIsDetailStatusOpen,
    onResetSelectedNode,
    onCloseHandler,
    onEditNodeHandler,
    onDeleteNodeHandler,
    onSetBuilderValues,
    setSystemOption,
    setTicketTypeOption,
    onSaveBuilderValues,
  } = methods;

  const statusDetail = isDetailStatusOpen && (
    <StatusDetail
      selectedNode={selectedNode}
      nodes={nodes}
      onCreateNodeHandler={onCreateNode}
      onCreateEdgeHandler={onCreateEdge}
      onClose={onCloseHandler}
      onResetSelectedNode={onResetSelectedNode}
      onEditNodeHandler={onEditNodeHandler}
      onDeleteNodeHandler={onDeleteNodeHandler}
    />
  );

  const ticketTypeOptions = (
    <Select
      size={Size.xs}
      label="Выберите тип тикета"
      onChange={(type: TicketType) => setTicketTypeOption(type)}
      options={ticketTypes}
      value={ticketTypeOption}
      loading={false}
      className={cn(styles.builder__select, {
        [styles.builder__select_notActive]: !systemId,
      })}
      disabled={!systemId}
    />
  );

  return (
    <div className={styles.builder}>
      <div
        className={cn(
          styles.builder__buttonsWrapper,
          styles.builder__buttonsWrapper_left,
          { [styles.builder__selectWrapper_hidden]: isDetailStatusOpen }
        )}
      >
        <Select<string>
          size={Size.xs}
          label="Выберите систему"
          options={systemsOptions}
          value={systemOption}
          onChange={(value) => setSystemOption(value)}
          className={cn(styles.builder__select, {
            [styles.builder__select_notActive]: !systemsOptions.length,
          })}
        />
        <div
          className={cn(styles.builder__selectWrapper_left, {
            [styles.builder__selectWrapper_hidden]: isDetailStatusOpen,
          })}
        >
          {ticketTypeOptions}
        </div>
      </div>
      <ReactFlow
        nodes={getCustomNodes(nodes)}
        edges={getCustomEdges({ edges, theme })}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onEdgeUpdate={onEdgeUpdate}
        nodeTypes={nodeTypes}
        deleteKeyCode={null}
      >
        <Background className={styles.builder__background} />
        <div className={styles.builder__selectWrapper}>{ticketTypeOptions}</div>
        <div className={styles.builder__buttonsWrapper}>
          <IconButton
            appearance="flat"
            icon={
              <LinkCrossedIcon className={styles.builder__deleteButtonIcon} />
            }
            onClick={onEdgeDelete}
            disabled={!selectedEdge}
            className={cn(styles.builder__deleteButton, {
              [styles.builder__deleteButton_visible]:
                !!selectedEdge && !selectedEdge.data?.protected,
            })}
          />
          <Button
            appearance="flat"
            icon={<PlusIcon className={styles.builder__createButtonIcon} />}
            onClick={toggleIsDetailStatusOpen}
            disabled={isCreateDisabled}
            className={styles.builder__createButton}
          >
            Создать статус
          </Button>
          <IconButton
            appearance="flat"
            icon={<PlusIcon className={styles.builder__createButtonIcon} />}
            onClick={toggleIsDetailStatusOpen}
            disabled={isCreateDisabled}
            className={cn(
              styles.builder__createButton,
              styles.builder__createButton_mobile
            )}
          />
        </div>
        <div className={styles.builder__footer}>
          <div
            className={cn(styles.builder__wrapperSaveButtons, {
              [styles.builder__wrapperSaveButtons_hidden]:
                !isChanges || isDetailStatusOpen,
            })}
          >
            <Button
              disabled={disabledSave}
              className={styles.builder__wrapperSaveButtonsItem}
              onClick={onSaveBuilderValues}
            >
              Сохранить
            </Button>
            <Button
              onClick={onSetBuilderValues}
              disabled={!isChanges}
              className={styles.builder__wrapperSaveButtonsItem}
              appearance="outline"
            >
              Отмена
            </Button>
          </div>
          <EllipseIconButton
            onClick={toggleIsDetailStatusOpen}
            className={cn(styles.builder__editButton, {
              [styles.builder__editButton_visible]:
                selectedNode && !isDetailStatusOpen,
            })}
            icon={
              selectedNode?.data.modified || selectedNode?.data.deletable ? (
                <Pen3Icon />
              ) : (
                <EyeIcon />
              )
            }
          />
        </div>
      </ReactFlow>
      {statusDetail}
    </div>
  );
};
