import { FC, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Address, SuitCaseIcon, TicketSmall } from 'assets/icons';
import { ReactComponent as DomainIcon } from 'assets/icons/svg/domain.svg';
import { PopoverContainer } from 'components';
import { DeleteButton } from 'core/components';
import { GREY_100, HEADER_TYPE } from 'core/constants';
import { getIsMobile, getIsMobileSmall, getTheme } from 'core/ducks/selectors';
import { MainLayout } from 'core/layouts';
import { ApproveOrCancel, Info } from 'core/modals';
import { Theme } from 'core/types';
import { ActionList } from 'core/types/auth';
import { getIsAccessedAction } from 'features/Auth';
import { useOrganizationId } from 'features/Organization/hooks';
import {
  deleteOrganizationRequest,
  getCurrentOrganization,
  getLoadingOrganization,
  useOrganizationDelete,
} from 'features/Organizations';
import { getOpenTicketsCount } from 'features/Tickets';
import { RouterHref } from 'routes/routerHref';

import styles from './OrganizationLayout.module.scss';

export const deleteOrganizationTitle = (
  <>
    <p className={styles.organizationLayout__modalDeleteText}>
      В случае удаления организации из системы также будут удалены все связанные
      с ней сущности в том числе пользователи, контракты, системы и т.д.
    </p>
    <p>Вы уверены, что хотите удалить организацию?</p>
  </>
);

export const OrganizationLayout: FC = ({ children }) => {
  const dispatch = useDispatch();

  const isMobile = useSelector(getIsMobile);
  const isMobileSmall = useSelector(getIsMobileSmall);
  const theme = useSelector(getTheme);
  const organization = useSelector(getCurrentOrganization);
  const openTicketsCount = useSelector(getOpenTicketsCount);
  const isAccessToDeleteOrganization = useSelector(
    getIsAccessedAction(ActionList.DeleteEntries)
  );
  const loading = useSelector(getLoadingOrganization);

  const isDarkTheme = theme === Theme.DARK || theme === Theme.DARK_CONTRAST;

  const isMobileAll = isMobile || isMobileSmall;

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const organizationId = useOrganizationId();

  const toggleDeleteModal = () => {
    setShowDeleteModal((prev) => !prev);
  };

  const { canDeleteOrg, handleShowModalDelete } = useOrganizationDelete({
    orgId: organizationId,
    funcToShowModal: setShowDeleteModal,
  });

  const handleDelete = () => {
    if (organizationId) {
      dispatch(
        deleteOrganizationRequest({ id: organizationId, withRedirect: true })
      );
    }
  };

  const infoBlocksConfig = useMemo(
    () => [
      {
        title: 'Открытых тикетов',
        info: `${openTicketsCount}`,
        icon: (
          <TicketSmall
            style={{
              width: '20px',
              height: '20px',
              color: GREY_100,
            }}
          />
        ),
      },
      {
        title: 'Домен',
        info:
          organizationId && organization?.domain
            ? organization.domain
            : 'Нет данных',
        icon: (
          <DomainIcon
            style={{
              width: '20px',
              height: '20px',
              color: GREY_100,
            }}
          />
        ),
      },
      {
        title: 'Адрес',
        info:
          organizationId && organization?.address
            ? organization.address
            : 'Нет данных',
        icon: (
          <Address
            style={{
              width: '20px',
              height: '20px',
              color: GREY_100,
            }}
          />
        ),
      },
    ],
    [
      openTicketsCount,
      organizationId,
      organization?.domain,
      organization?.address,
    ]
  );

  const breadCrumbsConfig = useMemo(
    () => [
      { title: 'Организации', path: RouterHref.AdminOrganizations },
      { title: organization?.title || '' },
    ],
    [organization?.title]
  );

  const dropContent =
    isAccessToDeleteOrganization && organizationId ? (
      <PopoverContainer>
        <DeleteButton
          onClick={handleShowModalDelete}
          disabled={!organization}
        />
      </PopoverContainer>
    ) : undefined;

  return (
    <>
      <MainLayout
        headerType={HEADER_TYPE}
        title={organizationId ? organization?.title : 'Создать организациию'}
        titleIcon={
          <SuitCaseIcon
            style={{
              color: isDarkTheme ? 'var(--grey180)' : 'var(--common2)',
            }}
          />
        }
        withGoBack={isMobileAll}
        infoBlocksConfig={infoBlocksConfig}
        breadCrumbsConfig={organizationId ? breadCrumbsConfig : undefined}
        dropContent={dropContent}
        loading={loading && !organization}
      >
        {children}
      </MainLayout>
      {canDeleteOrg ? (
        <ApproveOrCancel
          onApprove={handleDelete}
          isModal={showDeleteModal}
          toggleModal={toggleDeleteModal}
          approveTitle="Удалить"
          approveBtnProps={{
            appearance: 'outline',
            className: styles.organizationLayout__iconDelete,
          }}
          cancelTitle="Отмена"
          text={deleteOrganizationTitle}
          className={styles.organizationLayout__modalDelete}
        />
      ) : (
        <Info isModal={showDeleteModal} toggleModal={toggleDeleteModal}>
          <p className={styles.organizationLayout__modalText}>
            Организация не может быть удалена, есть активные контракты
          </p>
        </Info>
      )}
    </>
  );
};
