import { SelectOption } from 'components';

import { KpiFormData, OrganizationsType } from './types';

export const NO_DATA_GRAPH = [
  {
    name: 'Нет данных',
  },
];

export const radioTabs = [
  {
    title: 'Закрыты',
    value: OrganizationsType.IN_STATUS_CLOSE,
  },
  {
    title: 'Новые',
    value: OrganizationsType.IN_STATUS_NEW,
  },
  {
    title: 'В работе',
    value: OrganizationsType.IN_ACTIVE_STATUS,
  },
];

export const SLIDE_RADIO_TABS_FOR_MANAGER: SelectOption<string>[] = [
  {
    title: 'Рабочая группа',
    value: 'workGroup',
  },
];

export const SLIDE_RADIO_TABS_FOR_ORG: SelectOption<string>[] = [
  {
    title: 'Организация',
    value: 'organization',
  },
];

export const SLIDE_RADIO_TABS_FOR_ALL: SelectOption<string>[] = [
  ...SLIDE_RADIO_TABS_FOR_MANAGER,
  ...SLIDE_RADIO_TABS_FOR_ORG,
];

export const KPI_PERIOD_OPTIONS = [
  {
    title: 'Неделя',
    value: 'WEEK',
  },
  {
    title: 'Месяц',
    value: 'MONTH',
  },
];

export const INITIAL_KPI_FORM_VALUES: KpiFormData = {
  period: KPI_PERIOD_OPTIONS[0],
  workGroupsIds: [],
  organizationsIds: [],
  systemsIds: [],
  ticketTypesIds: [],
};
