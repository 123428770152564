import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getIsMobile, getIsMobileSmall } from 'core/ducks/selectors';
import { ActionList } from 'core/types/auth';
import {
  getIsAccessedAction,
  getIsClientPartner,
  getIsGroupSpecialist,
  getIsManagerClients,
  getIsOrgTypePartner,
  getManagerWorkGroupsList,
} from 'features/Auth';
import { RouterHref } from 'routes/routerHref';
import { checkPathnameWithTrailingSlash } from 'utils';

import {
  getOrganizationsOptions,
  getSystemsOptions,
} from '../ducks/Desktop/selectors';

export const useHeaderForm = () => {
  const { pathname } = useLocation();

  const isMobile = useSelector(getIsMobile);
  const isMobileSmall = useSelector(getIsMobileSmall);
  const isGroupSpecialist = useSelector(getIsGroupSpecialist);
  const isPartner = useSelector(getIsOrgTypePartner);
  const isClientPartner = useSelector(getIsClientPartner);
  const isAccessToTakeTicketToWork = useSelector(
    getIsAccessedAction(ActionList.TakeTicketToWork)
  );
  const isAccessToCreateReportByOrg = useSelector(
    getIsAccessedAction(ActionList.CreateReportByOrganization)
  );
  const isAccessToCreateReportByMyOrg = useSelector(
    getIsAccessedAction(ActionList.CreateReportByMyOrg)
  );
  const isAccessToCreateTicket = useSelector(
    getIsAccessedAction(ActionList.CreateTicket)
  );

  const managerWorkGroupsList = useSelector(getManagerWorkGroupsList);
  const organizationsOptions = useSelector(getOrganizationsOptions);
  const systemsOptions = useSelector(getSystemsOptions);
  const isManagerClients = useSelector(getIsManagerClients);

  const isDesktopKpiPage = checkPathnameWithTrailingSlash(
    pathname,
    RouterHref.DesktopKpi
  );
  const isDesktopPage = checkPathnameWithTrailingSlash(
    pathname,
    RouterHref.DesktopCurrentState
  );
  const isDesktopHistoryPage = checkPathnameWithTrailingSlash(
    pathname,
    RouterHref.DesktopHistory
  );

  const isMobileAll = isMobile || isMobileSmall;

  const hideDesktopKpi = isPartner
    ? isClientPartner && isAccessToCreateTicket && !isAccessToTakeTicketToWork
    : !isAccessToTakeTicketToWork;

  const hideCurrentState = !isAccessToCreateReportByOrg && !isGroupSpecialist;

  const hideHistory = isManagerClients
    ? !isAccessToCreateReportByMyOrg
    : !isAccessToCreateReportByOrg;

  const hideEmployees = !isGroupSpecialist || isAccessToCreateReportByMyOrg;

  return {
    state: {
      isDesktopKpiPage,
      isDesktopPage,
      isDesktopHistoryPage,
      isMobileAll,
      managerWorkGroupsList,
      organizationsOptions,
      systemsOptions,
      hideDesktopKpi,
      hideCurrentState,
      hideHistory,
      hideEmployees,
    },
  };
};
