import { addZeroIfOneDigit } from './addZeroIfOneDigit';

export const convertDateToString = (date: Date, time: boolean) => {
  const hours = addZeroIfOneDigit(new Date(date).getHours()) || '';
  const minutes = addZeroIfOneDigit(new Date(date).getMinutes()) || '';

  const timePart = time && (hours || minutes) ? `${hours}:${minutes}` : '';

  const dateDate = addZeroIfOneDigit(new Date(date).getDate());
  const month = addZeroIfOneDigit(new Date(date).getMonth() + 1);
  const year = new Date(date).getFullYear();

  return ` ${dateDate}.${month}.${year} ${timePart}`;
};
