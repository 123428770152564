import cn from 'clsx';
import { FC, MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { BackArrowIcon } from 'assets/icons';
import { getScreenWidth } from 'core/ducks/selectors';
import { ScreenWidth } from 'core/types';

import styles from './GoBackButton.module.scss';

interface GoBackButtonProps {
  className?: string;
  withLabel?: boolean;
}

export const GoBackButton: FC<GoBackButtonProps> = ({
  className,
  withLabel = true,
}) => {
  const { goBack } = useHistory();

  const screenWidth = useSelector(getScreenWidth);

  const isMobile = screenWidth === ScreenWidth.MOBILE;
  const isMobileSmall = screenWidth === ScreenWidth.MOBILE_SMALL;
  const isMobileAll = isMobile || isMobileSmall;

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <button
      onClick={goBack}
      className={cn(styles.goBackButton, className)}
      onMouseDown={handleClick}
    >
      <BackArrowIcon className={styles.goBackButton__icon} />
      {!isMobileAll && withLabel && (
        <span className={styles.goBackButton__text}>Назад</span>
      )}
    </button>
  );
};
