import { Responsibility } from 'core/types/responsibility';
import { ResponseWithMeta } from 'store/types';

import {
  CreateResponsibilityType,
  ResponsibilityFilter,
  UpdateResponsibilityData,
} from '../types';

import {
  CreateResponsibilityAction,
  FetchRespByGroupIdRequestAction,
  FetchRespByPosIdRequestAction,
  FetchRespByRoleIdRequestAction,
  FetchResponsibilitiesRequestAction,
  FetchResponsibilitiesSuccessAction,
  HideResponsibilitiesAddLoadingAction,
  HideResponsibilitiesLoadingAction,
  ResetCurrentResponsibilityAction,
  Responsibilities,
  SetCurrentResponsibilitiesPageAction,
  SetCurrentResponsibilityIdAction,
  SetResponsibilitiesAddPageAction,
  SetSizePageAction,
  SetSizePageResponsibilitiesAddAction,
  SetSortResponsibilitiesAction,
  ShowResponsibilitiesAddLoadingAction,
  ShowResponsibilitiesLoadingAction,
} from './types';

export const showResponsibilitiesLoading =
  (): ShowResponsibilitiesLoadingAction => {
    return {
      type: Responsibilities.RESPONSIBILITIES_LOADING_SHOW,
    };
  };

export const hideResponsibilitiesLoading =
  (): HideResponsibilitiesLoadingAction => {
    return {
      type: Responsibilities.RESPONSIBILITIES_LOADING_HIDE,
    };
  };

export const showResponsibilitiesAddLoading =
  (): ShowResponsibilitiesAddLoadingAction => {
    return {
      type: Responsibilities.RESPONSIBILITIES_ADD_LOADING_SHOW,
    };
  };

export const hideResponsibilitiesAddLoading =
  (): HideResponsibilitiesAddLoadingAction => {
    return {
      type: Responsibilities.RESPONSIBILITIES_ADD_LOADING_HIDE,
    };
  };

export const fetchResponsibilitiesRequest =
  (): FetchResponsibilitiesRequestAction => {
    return {
      type: Responsibilities.FETCH_RESPONSIBILITIES_REQUEST,
    };
  };

export const fetchResponsibilitiesSuccess = (
  data: ResponseWithMeta<Responsibility[]>
): FetchResponsibilitiesSuccessAction => {
  return {
    type: Responsibilities.FETCH_RESPONSIBILITIES_SUCCESS,
    payload: data,
  };
};

export const setCurrentResponsibilitiesPage = (
  page: number
): SetCurrentResponsibilitiesPageAction => {
  return {
    type: Responsibilities.SET_CURRENT_RESPONSIBILITIES_PAGE,
    payload: page,
  };
};

export const setSizePage = (size: number): SetSizePageAction => {
  return {
    type: Responsibilities.SET_SIZE_PAGE_RESPONSIBILITIES,
    payload: size,
  };
};

export const setResponsibilitiesAddPage = (
  page: number
): SetResponsibilitiesAddPageAction => {
  return {
    type: Responsibilities.SET_RESPONSIBILITIES_ADD_PAGE,
    payload: page,
  };
};

export const setSizePageResponsibilitiesAdd = (
  size: number
): SetSizePageResponsibilitiesAddAction => {
  return {
    type: Responsibilities.SET_SIZE_PAGE_RESPONSIBILITIES_ADD,
    payload: size,
  };
};

export const setSortResponsibilities = (
  sort: string
): SetSortResponsibilitiesAction => {
  return {
    type: Responsibilities.SET_SORT_RESPONSIBILITIES,
    payload: sort,
  };
};

export const setCurrentResponsibilityId = (
  id: string
): SetCurrentResponsibilityIdAction => {
  return {
    type: Responsibilities.SET_CURRENT_RESPONSIBILITY_ID,
    payload: id,
  };
};

export const fetchRespByPosIdRequest = (): FetchRespByPosIdRequestAction => {
  return {
    type: Responsibilities.FETCH_RESPONSIBILITY_BY_POSITION_ID_REQUEST,
  };
};

export const resetResponsibilitiesState = () => {
  return {
    type: Responsibilities.RESET_RESPONSIBILITIES_STATE,
  };
};

export const fetchRespByGroupIdRequest =
  (): FetchRespByGroupIdRequestAction => {
    return {
      type: Responsibilities.FETCH_RESPONSIBILITY_BY_GROUP_ID_REQUEST,
    };
  };

export const fetchRespByRoleIdRequest = (): FetchRespByRoleIdRequestAction => {
  return {
    type: Responsibilities.FETCH_RESPONSIBILITY_BY_ROLE_ID_REQUEST,
  };
};

export const createResponsibility = (
  data: CreateResponsibilityType
): CreateResponsibilityAction => {
  return {
    type: Responsibilities.CREATE_RESPONSIBILITY,
    payload: data,
  };
};

export const setResponsibilitiesAddFilter = (data: ResponsibilityFilter) => {
  return {
    type: Responsibilities.SET_RESPONSIBILITIES_ADD_FILTER,
    payload: data,
  };
};

export const setResponsibilitiesFilter = (data: ResponsibilityFilter) => {
  return {
    type: Responsibilities.SET_RESPONSIBILITIES_FILTER,
    payload: data,
  };
};

export const fetchResponsibilitiesAddRequest = () => {
  return {
    type: Responsibilities.FETCH_RESPONSIBILITIES_ADD_REQUEST,
  };
};

export const fetchResponsibilitiesAddSuccess = (
  data: ResponseWithMeta<Responsibility[]>
) => {
  return {
    type: Responsibilities.FETCH_RESPONSIBILITIES_ADD_SUCCESS,
    payload: data,
  };
};

export const updateResponsibility = (data: UpdateResponsibilityData) => {
  return {
    type: Responsibilities.UPDATE_RESPONSIBILITY,
    payload: data,
  };
};

export const resetResponsibilitiesAddState = () => {
  return {
    type: Responsibilities.RESET_RESPONSIBILITIES_ADD_STATE,
  };
};

export const fetchResponsibilityByIdRequest = (id: string) => {
  return {
    type: Responsibilities.FETCH_RESPONSIBILITY_BY_ID_REQUEST,
    payload: id,
  };
};

export const fetchResponsibilityByIdSuccess = (data: Responsibility) => {
  return {
    type: Responsibilities.FETCH_RESPONSIBILITY_BY_ID_SUCCESS,
    payload: data,
  };
};

export const deleteResponsibilityRequest = (id: string) => {
  return {
    type: Responsibilities.DELETE_RESPONSIBILITY_BY_ID_REQUEST,
    payload: id,
  };
};

export const resetCurrentResponsibility =
  (): ResetCurrentResponsibilityAction => {
    return {
      type: Responsibilities.RESET_CURRENT_RESPONSIBILITY,
    };
  };
