import { ProfileTab } from 'features/Profile/types';

export const splitProfileTabsByGroup = (arr: ProfileTab[]) => {
  const result: Record<string, ProfileTab[]> = {};
  arr?.forEach((item) => {
    if (item?.tabGroup && !(item?.tabGroup in result)) {
      result[item?.tabGroup] = [];
    }
    if (item?.tabGroup && item?.tabGroup in result) {
      result[item?.tabGroup]?.push({ ...item });
    }
  });
  return result;
};
