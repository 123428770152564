export const getConvertedTimeToHours = (time: number) => {
  const allMinutes = time;
  const newTime = String(time / 60);

  const convertedHours = newTime.split('.');
  const [hours] = convertedHours;

  const convertedMinutes = allMinutes - Number(hours) * 60;
  const prepareHours = Number(hours) < 10 ? `0${hours}` : `${hours}`;
  const prepareMinutes =
    convertedMinutes < 10 ? `0${convertedMinutes}` : `${convertedMinutes}`;

  return `${prepareHours}:${prepareMinutes}`;
};

export const getConvertedTime = (time?: string | number) =>
  Number.isNaN(Number(time)) ? time : getConvertedTimeToHours(Number(time));
