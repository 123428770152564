import { MainLayout } from 'core/layouts';
import { Header } from 'features/Desktop/components';
import { DesktopEmployees as DesktopEmployeesContent } from 'features/Desktop/views';

import styles from './DesktopEmployees.module.scss';

const DesktopEmployees = () => {
  return (
    <MainLayout customHeader={<Header />}>
      <DesktopEmployeesContent className={styles.desktopEmployees__content} />
    </MainLayout>
  );
};

export default DesktopEmployees;
