import { RenderTypes, TabItem, TableColumns } from 'components';
import { Theme, TicketStatus } from 'core/types';
import { Accessibility, NodeType } from 'core/types/workflow';

export const SYSTEM_TABLE_FIELDS: TableColumns = [
  {
    title: 'Система',
    fieldName: 'system',
    sortField: 'title',
    renderType: RenderTypes.CONDITION,
  },
  {
    title: 'Организация',
    fieldName: 'organization',
    sortField: 'organization',
  },
];

export const INITIAL_SYSTEMS_FILTER = {
  title: '',
  organizationId: null,
};

export const MARKER_COLOR_MAP = {
  [Theme.LIGHT]: '#c0c8d0',
  [Theme.LIGHT_CONTRAST]: '#6a7a88',
  [Theme.DARK]: '#515255',
  [Theme.DARK_CONTRAST]: '#929294',
};

export const SELECTED_MARKER_COLOR_MAP = {
  [Theme.LIGHT]: '#0075db',
  [Theme.LIGHT_CONTRAST]: '#0062b8',
  [Theme.DARK]: '#1673c5',
  [Theme.DARK_CONTRAST]: '#1673c5',
};

export const ACCESSIBILITY_TABS: TabItem[] = [
  {
    title: 'Специалист',
    value: Accessibility.SPECIALIST,
  },
  {
    title: 'Клиент',
    value: Accessibility.CLIENT,
  },
  {
    title: 'Все',
    value: Accessibility.ALL,
  },
];

export const COLORS_TABS: TabItem[] = [
  {
    value: 'purple120',
  },
  {
    value: 'purple70',
  },
  {
    value: 'red120',
  },
  {
    value: 'red70',
  },
  {
    value: 'yellow120',
  },
  {
    value: 'green120',
  },
  {
    value: 'green70',
  },
];

export const DEFAULT_STATUS_FORM_VALUES = {
  color: COLORS_TABS[0].value,
  accessibility: Accessibility.SPECIALIST,
  isNotifications: false,
  isSLA: false,
  isComment: false,
};

export const WAITING_INFO_SETTINGS: NodeType = {
  label: 'В ожидании информации',
  color: 'green70',
  accessibility: Accessibility.SPECIALIST,
  isNotifications: true,
  isSLA: false,
  isComment: true,
  defaultStatus: TicketStatus.WAITING_INFO,
  deletable: true,
  modified: false,
  hideRightHandle: true,
  hideLeftHandle: true,
};
