import { Dispatch, RefObject, SetStateAction } from 'react';

export enum RangeTypesEnum {
  'from' = 'from',
  'to' = 'to',
}

export type RangeType = keyof typeof RangeTypesEnum;

export type RangeDateType = {
  from?: Date;
  to?: Date;
};

type PickedDateType = RangeDateType | Date;
export type SelectedDateType = PickedDateType;

export type DatePickerProps = {
  className?: string;
  showTime?: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  onReset?: () => void;
} & ConditionalDatePickerProps;

type ConditionalDatePickerProps =
  | {
      showRange?: true;
      pickedDate?: RangeDateType;
      setPickedDate: (date?: RangeDateType) => void;
    }
  | {
      showRange?: false;
      pickedDate?: Date;
      setPickedDate: (date?: Date) => void;
    };

export type DatesProps = {
  className?: string;
  onDateClick: (date: Date) => void;
  pickedDate?: Date;
  pickedDateRange?: RangeDateType;
  selectedDate: Date;
  approximateToDate?: Date;
  setApproximateToDate?: Dispatch<SetStateAction<Date | undefined>>;
};

export type DropdownType = 'months' | 'years' | '';

export type ControlsProps = {
  className?: string;
  rangeType?: RangeType;
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
};

export type TimePickerProps = {
  pickedDate?: Date;
  changeDate: (date: Date) => void;
  changeDateByKey?: (date: Date) => void;
};

export type PickedTime = {
  isActive: boolean;
  valueForList: number;
  value: string;
};

export type CalendarProps = {
  className?: string;
  showTime?: boolean;
  changeDate: (date: Date) => void;
  pickedDate?: PickedDateType | Date;
} & Omit<DatesProps, 'onDateClick'> &
  ControlsProps &
  TimePickerProps;

export type DropdownProps = {
  containerRef: RefObject<HTMLElement>;
  isActive?: boolean;
  setIsActive: () => void;
  onReset?: () => void;
  visibleItemsCount?: number;
  isTableFilter?: boolean;
  showCloseIcon: boolean;
  icon?: JSX.Element;
} & ClassNamesProps;

type ClassNamesProps = {
  itemsClassName?: string;
};
