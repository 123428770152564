import cn from 'clsx';
import { ButtonHTMLAttributes, FC, MouseEvent, SVGProps } from 'react';

import { Ripple } from 'components';

import styles from './PopoverButton.module.scss';

export interface PopoverButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: SVGProps<SVGElement>;
}

export const PopoverButton: FC<PopoverButtonProps> = ({
  className,
  type,
  icon,
  disabled,
  onClick,
  children,
}) => {
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const onButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onClick?.(event);
  };

  return (
    <button
      disabled={disabled}
      type={type}
      onClick={onButtonClick}
      onMouseDown={handleClick}
      className={cn(
        styles.popoverButton,
        { [styles.popoverButton_disabled]: disabled },
        className
      )}
    >
      <div className={styles.popoverButtonIcon}>{icon}</div>
      {children}
      {!disabled && <Ripple appearance="grey" />}
    </button>
  );
};
