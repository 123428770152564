import { Reducer } from 'redux';

import { DEFAULT_PAGINATION_PARAMS } from 'constants/meta';
import { Role } from 'core/types/roles';
import { ReducerMap } from 'store/types';
import { Pagination } from 'types/models/meta';

import { RolesFilter } from '../types';

import {
  CheckIsExistSuccessAction,
  FetchRoleByIdSuccessAction,
  FetchRolesAddSuccessAction,
  FetchRolesSuccessAction,
  Roles,
  RolesActionsTypes,
  SetCurrentRoleIdAction,
  SetCurrentRolesAddPageAction,
  SetCurrentRolesPageAction,
  SetRolesAddFilterAction,
  SetRolesFilterAction,
  SetSizePageAction,
  SetSizePageRolesAddAction,
  SetSortRolesAction,
} from './types';

export interface RolesReducerState {
  roles?: Role[];
  loading?: boolean;
  pagination?: Pagination;
  paginationAdd?: Pagination;
  sort?: string;
  roleId?: string;
  rolesAdd?: Role[];
  rolesAddFilter?: RolesFilter;
  rolesFilter?: RolesFilter;
  currentRole?: Role;
  loadingAdd?: boolean;
  isExistTitle?: boolean;
}

const initialState: RolesReducerState = {
  roles: [],
  loading: false,
  pagination: DEFAULT_PAGINATION_PARAMS,
  paginationAdd: DEFAULT_PAGINATION_PARAMS,
  sort: 'date_create_DESC',
  rolesFilter: {},
  rolesAddFilter: {},
  isExistTitle: false,
};

const roleReducerMap: ReducerMap<RolesReducerState, RolesActionsTypes> = {
  [Roles.ROLES_LOADING_SHOW]: (state) => {
    return {
      ...state,
      loading: true,
    };
  },
  [Roles.ROLES_LOADING_HIDE]: (state) => {
    return {
      ...state,
      loading: false,
    };
  },
  [Roles.ROLES_ADD_LOADING_SHOW]: (state) => {
    return {
      ...state,
      loadingAdd: true,
    };
  },
  [Roles.ROLES_ADD_LOADING_HIDE]: (state) => {
    return {
      ...state,
      loadingAdd: false,
    };
  },
  [Roles.FETCH_ROLES_SUCCESS]: (state, action) => {
    const { payload } = action as FetchRolesSuccessAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      roles: content,
      pagination: {
        ...state?.pagination,
        pageNum: page,
        totalElements,
      },
    };
  },
  [Roles.SET_CURRENT_ROLES_PAGE]: (state, action) => {
    const { payload } = action as SetCurrentRolesPageAction;
    return {
      ...state,
      pagination: {
        ...state?.pagination,
        pageNum: payload,
      },
    };
  },
  [Roles.SET_SIZE_PAGE_ROLES]: (state, action) => {
    const { payload } = action as SetSizePageAction;
    return {
      ...state,
      pagination: {
        ...state?.pagination,
        pageSize: payload,
      },
    };
  },
  [Roles.SET_CURRENT_ROLES_ADD_PAGE]: (state, action) => {
    const { payload } = action as SetCurrentRolesAddPageAction;
    return {
      ...state,
      paginationAdd: {
        ...state?.paginationAdd,
        pageNum: payload,
      },
    };
  },
  [Roles.SET_SIZE_PAGE_ROLES_ADD]: (state, action) => {
    const { payload } = action as SetSizePageRolesAddAction;
    return {
      ...state,
      paginationAdd: {
        ...state?.paginationAdd,
        pageSize: payload,
      },
    };
  },
  [Roles.SET_SORT_ROLES]: (state, action) => {
    const { payload } = action as SetSortRolesAction;
    return {
      ...state,
      sort: payload,
    };
  },
  [Roles.RESET_ROLES_STATE]: () => {
    return {
      ...initialState,
    };
  },
  [Roles.SET_CURRENT_ROLE_ID]: (state, action) => {
    const { payload } = action as SetCurrentRoleIdAction;
    return {
      ...state,
      roleId: payload,
    };
  },
  [Roles.SET_ROLES_ADD_FILTER]: (state, action) => {
    const { payload } = action as SetRolesAddFilterAction;
    return {
      ...state,
      rolesAddFilter: { ...payload },
    };
  },
  [Roles.FETCH_ROLES_ADD_SUCCESS]: (state, action) => {
    const { payload } = action as FetchRolesAddSuccessAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      rolesAdd: content,
      paginationAdd: {
        ...state?.paginationAdd,
        pageNum: page,
        totalElements,
      },
    };
  },
  [Roles.FETCH_ROLE_BY_ID_SUCCESS]: (state, action) => {
    const { payload } = action as FetchRoleByIdSuccessAction;
    return {
      ...state,
      currentRole: payload,
    };
  },
  [Roles.SET_ROLES_FILTER]: (state, action) => {
    const { payload } = action as SetRolesFilterAction;
    return {
      ...state,
      rolesFilter: payload,
    };
  },
  [Roles.RESET_ROLES_ADD_STATE]: (state) => {
    const { rolesAdd, rolesAddFilter } = initialState;
    return {
      ...state,
      rolesAdd,
      rolesAddFilter,
    };
  },
  [Roles.CHECK_IS_EXIST_SUCCESS]: (state, action) => {
    const { payload } = action as CheckIsExistSuccessAction;
    return {
      ...state,
      isExistTitle: payload,
    };
  },
  [Roles.RESET_CURRENT_ROLE]: (state) => {
    return {
      ...state,
      roleId: initialState.roleId,
      currentRole: initialState.currentRole,
      pagination: initialState.pagination,
    };
  },
};

export const roles: Reducer<RolesReducerState, RolesActionsTypes> = (
  state = initialState,
  action
) => {
  const reducer = roleReducerMap[action.type];
  if (!reducer) {
    return state;
  }
  return reducer(state, action);
};
