import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { DEFAULT_PAGINATION_PARAMS } from 'constants/meta';

import { Builder, SystemsTableContainer } from './components';
import {
  fetchSystemsRequest,
  resetStatusesBuilderState,
  setCurrentSystemId,
  setCurrentSystemsPage,
  setPageSize,
} from './ducks/actions';
import styles from './StatusesBuilder.module.scss';

export const StatusesBuilder = () => {
  const [isSmallWindowWidth, setIsSmallWindowWidth] = useState<boolean | null>(
    null
  );

  const dispatch = useDispatch();

  const changeScreenWidth = useCallback(() => {
    const { innerWidth } = window;
    if (innerWidth > 1600) {
      return setIsSmallWindowWidth(false);
    }
    return setIsSmallWindowWidth(true);
  }, [setIsSmallWindowWidth]);

  useLayoutEffect(() => {
    changeScreenWidth();
    window.addEventListener('resize', changeScreenWidth);
    return () => {
      window.removeEventListener('resize', changeScreenWidth);
    };
  }, [changeScreenWidth]);

  useEffect(() => {
    if (isSmallWindowWidth) {
      dispatch(setPageSize(500));
      dispatch(setCurrentSystemsPage(0));
    } else {
      dispatch(setPageSize(DEFAULT_PAGINATION_PARAMS.pageSize));
      dispatch(setCurrentSystemsPage(DEFAULT_PAGINATION_PARAMS.pageNum));
    }
    dispatch(setCurrentSystemId(undefined));
    if (isSmallWindowWidth !== null) {
      dispatch(fetchSystemsRequest());
    }
  }, [isSmallWindowWidth]);

  useEffect(() => {
    return () => {
      dispatch(resetStatusesBuilderState());
    };
  }, []);

  return (
    <div className={styles.statusesBuilder}>
      <div className={styles.statusesBuilder__table}>
        <SystemsTableContainer />
      </div>
      <div className={styles.statusesBuilder__builder}>
        <Builder isSmallWindowWidth={isSmallWindowWidth} />
      </div>
    </div>
  );
};
