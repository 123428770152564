import { ProfileSettings } from 'features/Profile/components';
import { ProfileLayout } from 'features/Profile/layouts';

const ProfileSettingsPage = () => (
  <ProfileLayout>
    <ProfileSettings />
  </ProfileLayout>
);

export default ProfileSettingsPage;
