import { useEffect, useRef } from 'react';
import { Controller } from 'react-hook-form';

import { CloseIcon, TicketNavIcon } from 'assets/icons';
import {
  Button,
  Card,
  FileInput,
  Input,
  MenuItem,
  PriorityBlock,
  RadioGroup,
  RenderMenuItemProps,
  RoundButton,
  Select,
  Size,
  Typography,
  TypographyVariants,
} from 'components';
import { MAX_FILE_SIZE } from 'core/constants';
import { ApproveOrCancel } from 'core/modals';
import { EditorRefType } from 'core/types';
import { getPriorityByTitle } from 'core/utils';
import { AuthService, getEditorConfig, getEnv } from 'utils';

import styles from './CreateTicket.module.scss';
import { useCreateTicket } from './hooks';
import { getCustomFieldComponent } from './utils/getCustomFieldComponent';

const renderMenuItem = ({
  onChange,
  option,
  selected,
}: RenderMenuItemProps) => {
  return (
    <MenuItem
      onClick={onChange}
      selected={selected}
      className={styles.createTicket__option}
    >
      <span className={styles.createTicket__optionTitle}>#{option.title}</span>
      <div className={styles.createTicket__optionTheme}>{option.meta}</div>
    </MenuItem>
  );
};

export const CreateTicket = () => {
  const { methods, state } = useCreateTicket();

  const {
    setNextPage,
    hideInputRow,
    handleInput,
    onErrorMaxFileSize,
    onApproveModal,
    formSubmitHandler,
    toggleIsTicketsIds,
    setFileHandler,
    toggleModal,
    setMyFiles,
    setEditorLoaded,
    onChangeEditor,
    fetchRequiredCustomFields,
  } = methods;

  const {
    titleInputOptions,
    priorityRadioOptions,
    versionSelectOptions,
    errors,
    control,
    isDisabledSubmit,
    systemOptions,
    environmentOptions,
    ticketTypesOptions,
    radioPriorityTabs,
    myFiles,
    ticketsOptions,
    pageNum,
    totalPagesTickets,
    loadingTickets,
    inputValue,
    description,
    isMobileAll,
    isDesktopSmall,
    isDesktop,
    isModal,
    isTicketsIds,
    editorLoaded,
    fields,
  } = state;

  const token = AuthService.getToken();

  const editorRef = useRef<EditorRefType>();
  const { CKEditor, Editor } = editorRef.current || {};

  const editorConfig = getEditorConfig(
    `${getEnv('REACT_APP_ATTACHMENT_URL')}/api/attachment/tickets/image`,
    token
  );

  useEffect(() => {
    editorRef.current = {
      // eslint-disable-next-line
      CKEditor: require('@ckeditor/ckeditor5-react').CKEditor,
      // eslint-disable-next-line
      Editor: require('ckeditor5-custom-build/build/ckeditor'),
    };
    setEditorLoaded(true);
  }, []);

  const customFieldsComponent = fields?.map(
    ({ customFieldId, title, type, values }, index) => {
      return getCustomFieldComponent({
        customFieldId,
        title,
        type,
        index,
        control,
        values,
      });
    }
  );

  const renderRadioGroupComponent = (title?: string) => {
    return (
      <PriorityBlock
        priority={getPriorityByTitle(title)}
        className={styles.createTicket__priorityBlock}
      />
    );
  };

  return (
    <Card className={styles.createTicket}>
      <form className={styles.createTicket__form} onSubmit={formSubmitHandler}>
        <Card className={styles.createTicket__inputsCard}>
          {!isMobileAll && (
            <Typography
              variant={TypographyVariants.b2}
              className={styles.createTicket__formTitle}
            >
              Общая информация
            </Typography>
          )}
          <div className={styles.createTicket__wrapper}>
            <div className={styles.createTicket__inputsWrapper}>
              <Input
                label="Тема тикета"
                {...titleInputOptions}
                error={!!errors.theme}
                errorMessage={errors.theme?.message}
              />
              <Controller
                control={control}
                name="typeId"
                rules={{ required: true }}
                render={({ field }) => {
                  return (
                    <Select<string>
                      label="Тип тикета"
                      mobileModalTitle="тип тикета"
                      options={ticketTypesOptions}
                      onChange={field.onChange}
                      value={field.value}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="systemId"
                rules={{ required: true }}
                render={({ field }) => {
                  return (
                    <Select<string>
                      label="Система"
                      mobileModalTitle="систему"
                      options={systemOptions}
                      onChange={(value) => {
                        field.onChange(value);
                        fetchRequiredCustomFields(value);
                      }}
                      value={field.value}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="environmentId"
                rules={{ required: true }}
                render={({ field }) => {
                  return (
                    <Select<string>
                      label="Среда"
                      mobileModalTitle="среду"
                      options={environmentOptions}
                      onChange={field.onChange}
                      value={field.value}
                    />
                  );
                }}
              />
              <Input label="Версия" disabled {...versionSelectOptions} />
            </div>

            {!!fields.length && (
              <div className={styles.createTicket__customFields}>
                {customFieldsComponent}
              </div>
            )}

            {editorLoaded && Editor && CKEditor && (
              <div className={styles.createTicket__editor}>
                <CKEditor
                  editor={Editor}
                  config={editorConfig}
                  data={description}
                  onReady={() => null}
                  onChange={onChangeEditor}
                />
              </div>
            )}
            {!isTicketsIds && (
              <Button
                size={Size.xs}
                icon={<TicketNavIcon />}
                appearance="flat"
                onClick={toggleIsTicketsIds}
              >
                Указать тикет
              </Button>
            )}
            {isTicketsIds && (
              <div className={styles.createTicket__multiSelectWrapper}>
                <Controller
                  control={control}
                  name="mentionedTickets"
                  render={({ field }) => {
                    return (
                      <Select<string>
                        options={ticketsOptions}
                        onChangeInput={handleInput}
                        currentPage={pageNum}
                        totalPage={totalPagesTickets}
                        setNextPage={setNextPage}
                        inputValue={inputValue}
                        value={field.value}
                        onChange={field.onChange}
                        mobileModalTitle="тикеты"
                        loading={loadingTickets}
                        isSearchable
                        infiniteScrollable
                        isMulti
                        isChip
                        renderMenuItem={renderMenuItem}
                        label="Тикеты"
                      />
                    );
                  }}
                />
                <div className={styles.createTicket__editButtons}>
                  <RoundButton
                    icon={<CloseIcon />}
                    onClick={hideInputRow}
                    type="button"
                    className={styles.createTicket__editClose}
                  />
                </div>
              </div>
            )}
            <FileInput
              fileHandler={setFileHandler}
              myFiles={myFiles}
              setMyFiles={setMyFiles}
              maxFileSize={MAX_FILE_SIZE}
              onErrorMaxFileSize={onErrorMaxFileSize}
              classNameFiles={styles.createTicket__fileNames}
            />
          </div>
        </Card>
        <Card className={styles.createTicket__radioCard}>
          {(isDesktopSmall || isDesktop) && (
            <span className={styles.createTicket__radioTitle}>Приоритет</span>
          )}
          <RadioGroup
            {...priorityRadioOptions}
            items={radioPriorityTabs}
            renderComponent={renderRadioGroupComponent}
            className={styles.createTicket__priorityRadio}
          />
        </Card>
        <div />
        <div className={styles.createTicket__buttonsBlock}>
          <Button appearance="flat" type="button" onClick={toggleModal}>
            Отмена
          </Button>
          <Button
            disabled={isDisabledSubmit}
            className={styles.createTicket__submitButton}
          >
            Создать
          </Button>
        </div>
      </form>
      <ApproveOrCancel
        onApprove={onApproveModal}
        isModal={isModal}
        toggleModal={toggleModal}
        text="Вы уверены, что хотите отменить создание тикета?"
      />
    </Card>
  );
};
